
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { UsersComponent } from './users.component';

import { TableHeaderModule} from 'app/components/tableheader/tableheader.module';
import { TableModule } from 'app/components/table/table.module';

import { MaterialModule } from 'app/main/material.module';
import { RemoveDialogModule } from 'app/components/dialogs/removedialog/remove-dialog.module';

import { DriverDialogModule } from 'app/main/admin/users/dialogs/driver/driver-dialog.module';
import { DispatcherDialog } from 'app/main/admin/users/dialogs/dispatcher/dispatcher-dialog.component';
import { ManagerDialog } from 'app/main/admin/users/dialogs/manager/manager-dialog.component';
import { IntermediaryCreateDialog } from 'app/main/admin/users/dialogs/intermediary/create/intermediary-create-dialog.component';
import { LogisticianDialog } from 'app/main/admin/users/dialogs/logistician/logistician-dialog.component';

import { SupplierDialogModule } from 'app/main/admin/users/dialogs/supplier/supplier-dialog.module';
import { CustomerDialogsModule } from 'app/main/admin/users/dialogs/customer/customer-dialogs.module';
import { CredentialsModule } from 'app/main/admin/users/dialogs/credentials/credentials-dialog.module';
import { CarrierDialogsModule } from 'app/main/admin/users/dialogs/carrier/carrier-dialog.module';

import { DialogPresenterService } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import { DialogHeaderModule } from 'app/components/dialogs/dialog-header/dialog-header.module';
import { ActionBarModule } from 'app/components/action-bar/action-bar.module';

import { RemoveUserDialogModule } from './dialogs/removedialog/remove-user-dialog.module';

@NgModule({
    declarations: [
        UsersComponent,
        DispatcherDialog,
        ManagerDialog,
        IntermediaryCreateDialog,
        LogisticianDialog,
    ],
    providers: [
        DialogPresenterService,
    ],
    imports: [
        TranslateModule,
        FuseSharedModule,
        MaterialModule,
        TableHeaderModule,
        TableModule,
        RemoveDialogModule,
        SupplierDialogModule,
        CustomerDialogsModule,
        DriverDialogModule,
        CredentialsModule,
        CarrierDialogsModule,
        DialogHeaderModule,
        ActionBarModule,
        RemoveUserDialogModule,
    ],
    exports: [
        UsersComponent,
        DispatcherDialog,
        ManagerDialog,
        IntermediaryCreateDialog,
        LogisticianDialog,
    ]
})
export class UsersModule {
}
