
import { TableData, TableSort } from 'app/components/table/tabledata';

import { PFQuery } from 'app/components/data/core/models/base/query';
import { CurrentUser } from 'app/components/data/core/models/base/user';
import * as AccessQueries from 'app/components/access/query';

import { TranslateService } from '@ngx-translate/core';
import { ServerTableDataSource } from 'app/components/table/data_sources/server-data-source';
import { TransportColumns } from '../helpers/transport-columns-factory';
import { TransportQuerySearchFilter } from '../helpers/transport-query-search-filter';

export class TrailersTableData extends TableData {

  constructor(
    translate: TranslateService
  ) {
    super();
    let columns = [
      TransportColumns.number(),
      TransportColumns.tonnage(),
      TransportColumns.approved(translate),
      TransportColumns.inspection(),
    ];

    if (CurrentUser.isAdministrator() || CurrentUser.isLogistician() || CurrentUser.isManager()) {
      columns.push(TransportColumns.carrier());
    }

    this.setColumns(columns);

    let sort: TableSort = new TableSort('number', 'asc');
    let tableDataSource = new ServerTableDataSource('Trailer', ['carrier']);
    tableDataSource.setDefaultSort(sort);
    tableDataSource.queryFilter = (filter, defaultQuery) => {
      TransportQuerySearchFilter.addNumberFilter(defaultQuery, filter);
      let trailerCarrierQuery = TransportQuerySearchFilter.carrierName('Trailer', filter);
      return PFQuery.or(defaultQuery, trailerCarrierQuery);
    };
    tableDataSource.queryModify = function (query: PFQuery): PFQuery {
      if (CurrentUser.isDispatcher()) {
        AccessQueries.matchesCurrentCarrier(query);
      }
      query.ascending('approved');
      return AccessQueries.filterDeleted(query);
    };
    this.setProvider(tableDataSource);
  }

  canSortColumn(column) {
    if (column.key === 'inspection' ) {
      return false;
    }

    return super.canSortColumn(column);
  }
}
