export const locale = {
  'HOME': {
    'TITLE': 'CRD Logistic',
    'DESCRIPTION': 'Комплексные поставки строительных материалов',
    'DOWNLOAD': 'Скачать приложение',
    'BUTTON': {
      'ENTER': 'ВХОД',
    }
  },

};
