import { TableData, TableRowData, TableSort } from 'app/components/table/tabledata';

import { Carrier } from 'app/components/data/core/models/persons/carrier';
import { TranslateService } from '@ngx-translate/core';
import { ServerTableDataSource } from 'app/components/table/data_sources/server-data-source';
import { PFQuery } from 'app/components/data/core/models/base/query';
import { filterDeleted } from 'app/components/access/query';

export class CarriersTableData extends TableData {

  constructor(
    private translate: TranslateService
  ) {
    super();
    let handler = this;
    let columns = [
      new TableRowData({
        key: 'name',
        header: 'USERS.TABLEROW.NAME',
        cell: (carrier: Carrier) => `${ carrier.getName() }`
      }),
      new TableRowData({
        key: 'order_management',
        header: 'USERS.TABS.CARRIERS.TABLEROW.ORDER_MANAGEMENT',
        cell: (carrier: Carrier) => `${ handler.getOrdersManagementStatus(carrier) }`
      }),
    ];
    this.setColumns(columns);

    let sort: TableSort = new TableSort('name','asc');
    let tableDataSource = new ServerTableDataSource('Carrier');
    tableDataSource.setDefaultSort(sort);
    tableDataSource.queryModify = function (query: PFQuery): PFQuery {
      return filterDeleted(query);
    };
    tableDataSource.queryFilter = (filter, query) => {
      query.contains("name", filter);
      return query;
    };
    this.setProvider(tableDataSource);
  }

  getOrdersManagementStatus(carrier: Carrier): string {
    let permissions = carrier.getOrderPermissions();
    let createOrdersStatus = this.translate.instant("USERS.TABS.CARRIERS.STATUS." + (permissions.create ? 'ALLOWED' : 'DENIED'));
    let editOrdersStatus = this.translate.instant("USERS.TABS.CARRIERS.STATUS." + (permissions.update ? 'ALLOWED' : 'DENIED'));
    let reserveOrderStatus = this.translate.instant("USERS.TABS.CARRIERS.STATUS." + (permissions.reserveOrder ? 'ALLOWED' : 'DENIED'));
    return this.translate.instant("USERS.TABS.CARRIERS.STATUS.MESSAGE", {
      createOrdersStatusValue: createOrdersStatus,
      updateOrdersStatus: editOrdersStatus,
      reserveOrderStatus: reserveOrderStatus});
  }

  canSortColumn(column) {
    if (column.key === 'order_management') {
      return false;
    }
    return super.canSortColumn(column);
  }
}
