import {
    Order,
    OrderAgreeStatus,
    OrderHistoryAction,
    OrderStatus,
    Type
} from 'app/components/data/core/models/order/order';
import {Offer} from 'app/components/data/core/models/offer';
import {Trip, TripStage} from 'app/components/data/core/models/trip/trip';
import {CurrentUser} from 'app/components/data/core/models/base/user';
import {TranslateService} from '@ngx-translate/core';
import {CarrierOffer} from '../data/core/models/carrier-offer';

export namespace OrderHelper {
    export function customerOrderStatus(order: Order, translate: TranslateService) {
        debugger
        var status = 'ORDER.STATUS.CUSTOMER.PROCESSING';
        let offers = order.offers();
        if (offers.length > 0) {
            let offer = offers[0];
            if (!offer.declined()) {
                status = 'ORDER.STATUS.CUSTOMER.HANDED_TO_DRIVER';
                let transportUnit = offer.transportUnit();
                if (transportUnit.hasApp()) {
                    let trip = offer.trip();
                    if (trip) {
                        let stage = trip.stage();
                        status = Trip.tripStageName(stage);
                    }
                }
            }
        } else {
            let carrierOffers = order.getCarrierOffers();
            if (carrierOffers && carrierOffers.length > 0) {
                let offer = carrierOffers[0];
                if (offer.isAccepted()) {
                    status = 'ORDER.STATUS.CUSTOMER.ACCEPTED_BY_CARRIER';
                }
            }
        }
        return translate.instant(status);
    }

    export function orderStatus(order: Order, translate: TranslateService): string {
        if (order.status() == OrderStatus.IN_WORK) {
            var statusStr;
            let records = order.historyRecords();
            let intoWorkRecord = records.find(record => record.action() === OrderHistoryAction.TAKE_INTO_WORK);
            if (intoWorkRecord) {
                let user = intoWorkRecord.user();
                statusStr = translate.instant('ORDER.STATUS.IN_WORK_BY_USER', {user: user.name()}); 
            } else {
                statusStr = translate.instant('ORDER.STATUS.IN_WORK');
            }
            return statusStr;
        } else {
            let offers = order.offers();
            if (offers.length > 0) {
                let offer = offers[0];
                let status = offerStatus(offer, translate);
                if (!status) {
                    let name = unitName(offer);
                    if (order.unfinishedTonnage() == 0) {
                        status = translate.instant(Trip.tripStageName(TripStage.Unloaded));
                    } else {
                        status = translate.instant('UNITS.STATUS.WITHOUT_APP');
                    }
                    return `${name} / ${status}`;
                } else {
                    return status;
                }
            } else if (!CurrentUser.isDispatcher()) {
                let carrierOffers = order.getCarrierOffers();
                if (carrierOffers && carrierOffers.length > 0) {
                    let offer = carrierOffers[0];
                    return carrierStatus(offer, translate);
                }
            }
            if(order.getAgreeStatus()==OrderAgreeStatus.NOT_AGREE){
                return translate.instant('ORDER.STATUS.NOT_AGREE');
            }
            if(order.getAgreeStatus()==OrderAgreeStatus.AGREE){
                return translate.instant('ORDER.STATUS.AGREE');
            }
            return "";
        }
    }
    export function carrierStatus(offer: CarrierOffer, translate: TranslateService): string {
        let status = 'ORDER.STATUS.CARRIER.';
        if (offer.isAccepted() == undefined) {
            status += 'UNACCEPTED';
        } else if (offer.isAccepted()) {
            status += 'ACCEPTED';
        } else {
            status += 'DECLINED';
        }
        return `${offer.carrier().getName()}<br>${translate.instant(status)}`;
    }
    export function offerStatus(offer: Offer, translate: TranslateService): string {
        let name = unitName(offer);
        let transportUnit = offer.transportUnit();
        
        if (transportUnit.hasApp()) {
            let status = '';
            let trip = offer.trip();
            if (trip && !offer.declined()) {
                let stage = trip.stage();
                let stageStr = Trip.tripStageName(stage);
                status = translate.instant(stageStr);
            } else {
                status = translate.instant(offer.getStatusString());
            }
            return `${name} / ${status}`;
        } 
        return null;
    }

    function unitName(offer: Offer) {
        let name = '';
        let transportUnit = offer.transportUnit();
        if (transportUnit) {
            name = transportUnit.driver().getName();
        }
        return name;
    }

    export function isLoaded(order: Order) {
        if (order) {
            let offers = order.offers();
            for (let offer of offers) {
                let trip = offer.trip();
                if (trip) {
                    let records = trip.historyRecords();
                    if (records) {
                        let loadRecord = records.find(historyRecord => historyRecord.stage() === TripStage.Loaded)
                        if (loadRecord) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    export function isReserved(order: Order) {
        let carriers = order.getCarriers();
        return carriers && carriers.length > 0;
    }

    export function nameForOrderType(type: Type) {
        var name = '';
        switch (type) {
            case Type.Carriage:
                name = 'ORDER.TYPE.CARRIAGE';
                break;
            default:
                name = 'ORDER.TYPE.DEFAULT';
        }
        return name;
    }
}
