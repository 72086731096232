<div fxFlex
     fxLayout="row"
     fxLayoutAlign="none center">
    <button mat-icon-button 
            (click)="prevDay()">
        <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <div fxLayout="row"
         fxLayoutAlign="space-between center">
        <button mat-button 
                (click)="datepicker.open()">
                {{selectedDate | date: 'dd/MM/yyyy'}}
        </button>
        <input [matDatepicker]="datepicker" 
                type="hidden" 
                (dateChange)="selectDate($event.value)">
        <mat-datepicker #datepicker touchUi></mat-datepicker>
    </div>
    <button mat-icon-button
            (click)="nextDay()">
        <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
</div>