export const locale = {
    'PROPOSALS': {
        'TITLE': {
            'LIST': 'Заявки',
            'BY_CARRIER': 'Заявки по перевозчикам',
            'BY_SUPPLIER': 'Заявки по поставщикам',
            'FOR_CARRIER': 'Заявки перевозчика',
            'FROM_CUSTOMERS': 'Заявки от покупателей',
        },
        'STOCK': 'Запас',
        'LOADING': 'Заявки загружаются',
        'SUPPLIER': 'Поставщик',
        'NO_CARRIER': 'Без перевозчика',
        'HEADER': {
            'PROPOSALS': 'Заявки',
            'COUNT': 'Тоннаж',
            'PERCENTAGE': 'От общего числа заявок, %',
            'REMAINS': 'Остатки',
            'SUMMARY': 'Сумма',
        },
        'FOOTER': {
            'TOTAL': 'Всего заявок',
            'TOTAL_PRICE': 'Общая стоимость',
        },
        'TABLEROW': {
            'TIME': 'Время',
            'COUNT': 'Кол-во',
            'CUSTOMER': 'Покупатель',
            'UNLOADING_ADDRESS': 'Адрес разгрузки',
            'LOADING_ADDRESS': 'Адрес загрузки',
            'COMMENT': 'Комментарий',
            'CARRIER': 'Перевозчик',
            'INFO': 'Инфо',
            'BRAND': 'Товар',
            'RESERVE': 'Бронь',
            'DISTANCE': 'Километраж',
        },
        'ROWOPERATION': {
            'DECLINE': 'Отказаться',
            'MAKE_ORDER': 'Создать заказ',
            'RESERVATION': 'Бронирование',
            'PROCESS': 'Обработать',
            'TO_WORK': 'В работу',
        },
        'ORDER_NUMBER': 'Заказ №{{value}}',
        'PREORDER_NUMBER': 'Предзаказ #{{value}}',
        'ALERT' : {
            'TITLE': {
                'WARNING': 'Внимание',
            },
            'MESSAGE': {
                'ORDER_READY': 'Для этой заявки уже создан заказ. Вы можете внести изменения в разделе Заказы.',
                'ORDER_ALREADY_CREATED': 'Для этой заявки уже создан заказ',
                'ORDER_ALREADY_LOADED': {
                    'REMOVE': 'Заявки, которые уже были загружены, нельзя удалить',
                    'RESERVE': 'Для заявок, которые уже были загружены, нельзя изменить бронь',
                },
                'NO_RESERVED_TO_DECLINE': 'Нельзя отклонить заказы, которые не были забронированы',
                'CANT_CHANGE_STATUS': 'Заявка уже в работе или как-то обработана',
            }
        },
        'RESERVE_STATUS': {
            'RESERVED': 'Забронирован',
            'NOT_RESERVED': 'Не забронирован',
        },
    },
};
