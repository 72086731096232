
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { MaterialModule } from 'app/main/material.module';

import { TableHeaderModule} from 'app/components/tableheader/tableheader.module'
import { TableModule } from 'app/components/table/table.module';

import { ExportQueueComponent } from './queue.component';

@NgModule({
  declarations: [
    ExportQueueComponent,
  ],
  imports     : [
    TranslateModule,
    FuseSharedModule,

    MaterialModule,
    TableHeaderModule,
    TableModule,
  ],
  exports     : [
    ExportQueueComponent,
  ],
})
export class ExportQueueModule {
}
