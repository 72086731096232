
<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="primary" 
                 title="{{'DIALOG.CUSTOMER.TITLE.CHECK' | translate}}"
                 subtitle="{{subtitle | translate}}">
  </dialog-header>
  
  <div class="pt-8 px-16">
    <form class="check-form" [formGroup]="form">
      <div>
        <mat-form-field class="no-padding info-input" fxFlex appearance="outline">
          <mat-label>{{'DIALOG.CUSTOMER.CONTACT_NAME' | translate}}</mat-label>
          <input matInput formControlName="contactName">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="no-padding info-input" fxFlex appearance="outline">
          <mat-label>{{'DIALOG.CUSTOMER.CONTACT_PHONE' | translate}}</mat-label>
          <input matInput formControlName="contactPhone">
        </mat-form-field>
      </div>

      <div *ngIf='executor.isProcessing()' class='py-20' fxLayout="column" fxLayoutAlign="center center">
        <mat-spinner diameter="30" color="accent"></mat-spinner>
      </div>

      <div *ngIf="customerCheckResult">
        <div fxLayout="column" fxLayoutAlign="center center"
             fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">
          <mat-form-field class='no-padding info-input' fxFlex='49' appearance="outline">
            <mat-label>{{'DIALOG.CUSTOMER.RECEIVABLES' | translate}}</mat-label>
            <input matInput formControlName="receivables">
          </mat-form-field>

          <mat-form-field class='no-padding info-input' fxFlex='49' appearance="outline">
            <mat-label>{{'DIALOG.CUSTOMER.CREDIT' | translate}}</mat-label>
            <input matInput formControlName="credit">
          </mat-form-field>
        </div>

        <div>
          <mat-form-field class='no-padding info-input' fxFlex appearance="outline">
            <mat-label>{{'DIALOG.CUSTOMER.LAST_PAYMENT' | translate}}</mat-label>
            <input matInput formControlName="lastPayment">
          </mat-form-field>
        </div>

        <div *ngIf="customerCheckResult.block" class="customer-check warn-400-bg mt-16 mb-10">
          <mat-label class='check-label' fxLayoutAlign="center center">{{customerCheckResult.block}}</mat-label>
        </div>
      </div>
      <div class="my-8" fxLayout="row" fxLayoutAlign="end end">
        <button mat-raised-button
                color="accent"
                (click)='dialogRef.close()'>{{'DIALOG.CUSTOMER.CONFIRMBUTTON.CLOSE' | translate}}
        </button>
      </div>
    </form>
  </div>
</div>
