import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { UserRoleGuard, Role } from 'app/guards/user-role-guard.service';

import { AutoOnMapModule } from 'app/main/auto/subcat/onmap/onmap.module';
import { AutoListModule } from 'app/main/auto/subcat/list/list.module';

import { AutoListComponent } from './subcat/list/list.component';
import { AutoOnMapComponent } from './subcat/onmap/onmap.component';

const routes: Routes = [
  {
    path      : 'auto',
    canActivateChild: [UserRoleGuard],
    data: {roles: [Role.Administrator, Role.Logistician, Role.Manager, Role.Dispatcher]},
    children: [
      {
        path     : 'list',
        component: AutoListComponent
      },
      {
        path     : 'onmap',
        component: AutoOnMapComponent
      },
      {
        path     : '',
        redirectTo: 'list',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  providers: [
    UserRoleGuard
  ],
  imports     : [
    RouterModule.forChild(routes),

    TranslateModule,

    FuseSharedModule,
    AutoOnMapModule,
    AutoListModule,
  ],
})

export class AutoModule
{

}
