export const locale = {
  'USERS' : {
    'ROLE': {
      'Administrator': 'Administrator',
      'Logistician': 'Logistician',
      'Manager': 'Manager',
      'Dispatcher': 'Dispatcher',
      'Customer': 'Customer',
      'Driver': 'Driver',
      'Supplier': 'Supplier',
    },
  }
};
