import { Parse } from 'parse';

export class PFObject extends Parse.Object {
    get id() { return super._getId(); }
    constructor(name) { super(name); }

    set(field: string, value: any) { super.set(field, value); }
    unset(field: string) { super.unset(field); }
    get(field: string) { return super.get(field); }
    destroy() { return super.destroy(); }
    save() { return super.save(); }

    fetch(options = null) { return super.fetch(options); }
    fetchWithInclude(include: string | string[]) { return super.fetchWithInclude(include); }

    add(field: string, value: any) { super.add(field, value); }
    remove(field: string, value: any) { super.remove(field, value); }

    static valueAtPath(object: PFObject, path: string) {
      if (!path) return null;
      let value = object;
      let pathComponents = path.split('.');
      while (pathComponents.length) {
        let property = pathComponents[0];
        pathComponents.shift();
        if (value) {
          value = value.get(property);
        }
      }
      return value;
    }
  
    static destroyObject(object: PFObject) {
      return object.destroy();
    }
  
    static priorValue(objects: PFObject[], defaultValue: Parse.Object) {
      if (!objects) return null;
      if (!PFObject.objectsContainsObject(objects, defaultValue)) {
        if (objects && objects.length) {
          defaultValue = objects[0];
        }
      }
      return defaultValue;
    }
    
    static indexOfObject(arr, obj): number {
      return arr.findIndex(o => {
        return PFObject.compareFn(o, obj);
      });
    }
    
    static compareFn(o1: any, o2: any): boolean {
      if (o1 == null && o2 == null) {
        return true;
      }
      if (o1 == null || o2 == null) {
        return false;
      }
      return o1==o2 || o1.id === o2.id;
    }
  
    static objectsContainsObject(objects: PFObject[], obj) {
      if (!objects) return false;
      let index = objects.findIndex(o => PFObject.compareFn(o, obj));
      return index != -1;
    }
  
    static sort(objects: any[], likeObjects) {
      return objects.sort((o1,o2) => {
        let o1Index = likeObjects.findIndex(s => {
          return PFObject.compareFn(o1, s);
        });
        let o2Index = likeObjects.findIndex(s => {
          return PFObject.compareFn(o2, s);
        });
        if (o1Index > o2Index) return 1;
        if (o1Index < o2Index) return -1;
        return 0;
      });
    }
  }