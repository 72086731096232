import { UnloadingPoint } from 'app/components/data/core/models/points/points';
import { Vehicle } from 'app/components/data/core/models/transport/transport';
import { TranslateService } from '@ngx-translate/core';

export namespace EquipmentHelper {
    const EquipmentList = [
        "compressor"
    ]

    export function localizedEquipmentRequirementsList(point: UnloadingPoint, translate: TranslateService) {
        if (!point) return noEquipment(translate);
        let requiredEquipment = point.requiredEquipment();
        return localizedEquipmentList(requiredEquipment, translate);
    }

    export function localizedAvailableEquipmentList(vehicle: Vehicle, translate: TranslateService) {
        let requiredEquipment = vehicle.equipment();
        return localizedEquipmentList(requiredEquipment, translate);
    }

    export function localizedEquipmentList(equipment, translate: TranslateService) {
        let localizedEquipmentList = "";
        if (!equipment) return noEquipment(translate);
        for (let e of EquipmentList) {
            if (localizedEquipmentList.length > 0) {
                localizedEquipmentList += ",<br>";
            }
            let availability = equipment[e];
            if (availability) {
                localizedEquipmentList = translate.instant('EQUIPMENT.' + e);
            }
        }
        if (localizedEquipmentList.length == 0) return noEquipment(translate);
        return localizedEquipmentList;
    }

    export function noEquipment(translate: TranslateService) {
        return translate.instant('EQUIPMENT.NONE');
    }
}