import { Component, ViewEncapsulation, Inject, ViewChild, Input } from '@angular/core';

import { Order } from 'app/components/data/core/models/order/order';
import { TransportUnit } from 'app/components/data/core/models/transport/transport';

import { UnitInfoTableData } from './units-info-tabledata';
import { TableComponent, FieldFilter, TableOptions } from 'app/components/table/table.component';

import { DialogPresenterService } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import { DriverCreateDialog } from './create-driver/driver-create-dialog.component';

import { DispatcherCloudExecutor } from 'app/components/data/cloud-executors/dispatcher-cloud-executor';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'app/components/locale/locale.service';
import { transfer, order_fields, } from '../../../locale/locale';
import { units } from 'app/main/locale/locale';
import { UnitCreateDialog } from './create-unit/unit-create-dialog.component';

let locales = [
  transfer,
  order_fields,
  units
];

@Component({
  selector: 'unit-select',
  templateUrl: './unit-select.component.html',
  styleUrls: ['./unit-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class UnitSelectComponent {
  tableData: UnitInfoTableData;
  tableOptions: TableOptions = {};
  private statusFilter: number;

  public executor = new DispatcherCloudExecutor;
  public lat;
  public lon;

  @Input()
  order: Order;

  @Input()
  extended: boolean;

  @ViewChild(TableComponent) private table: TableComponent;
  @ViewChild('searchbar') searchBar;

  constructor(
    private translate: TranslateService,
    private localeService: LocaleService,
    private dialogPresenterService: DialogPresenterService
  ) {
    
    this.localeService.loadLocales(locales);

    this.tableData = new UnitInfoTableData(this.translate);
    this.tableOptions = {selectable: true, singleSelection: true}
  }

  ngOnInit() {
    this.lat = this.order.loadingEntrance().getCoordinate().latitude;
    this.lon = this.order.loadingEntrance().getCoordinate().longitude;
  }

  public getSelectedUnits(): TransportUnit[] {
    return this.table.selectedValues();
  }

  filterState(checked: boolean) {
    if (checked) {
      this.statusFilter = 1;
    } else {
      this.statusFilter = undefined;
    }
    this.applyFilter();
  }

  searchStringChanged(str) {
    this.table.search(str);
  }

  applyFilter() {
    let filters = [];
    if (this.statusFilter) {
      filters.push(new FieldFilter('status', this.statusFilter));
    }
    this.table.setFilters(filters);
  }

  clickMarker(unit) {
    this.table.deselectAll();
    this.table.selection.toggle(unit);
  }

  isSelected(unit) {
    return this.table.selection.isSelected(unit);
  }

  iconNameForUnit(unit) {
    if (this.isSelected(unit)) {
      return 'assets/images/markers/marker_truck_highlight.png';
    }
    return 'assets/images/markers/marker_truck.png';
  }

  tableSelectedData(): any[] {
    let selectedValues = this.table.selectedValues();
    return selectedValues;
  }

  reload() {
    this.table.reloadData();
  }

  units() {
    let units = this.tableData.provider.getData();
    return units.filter((unit: TransportUnit) => unit.coordinate());
  }

  createDriver() {
    let handler = this;
    this.dialogPresenterService.showDialog({
      dialog: DriverCreateDialog,
      panel: 'driver-create-dialog-container',
      data: {
        disableClose: false,
      },
    }, function (success) {
      if (success) {
        handler.table.reloadData();
      }
    });
  }

  createUnit() {
    let handler = this;
    this.dialogPresenterService.showDialog({
      dialog: UnitCreateDialog,
      panel: 'unit-create-dialog-container',
      data: {
        disableClose: false,
      },
    }, function (success) {
      if (success) {
        handler.table.reloadData();
      }
    });
  }
}
