
export const locale = {
  'DIALOG' : {
    'CUSTOMER' : {
      'TITLE' : {
        'CREATE' : 'Создать покупателя',
        'EDIT' : 'Изменить покупателя',
        'CHECK' : 'Проверка покупателя',
        'POINTS' : 'Адреса разгрузки',
        'ENTRANCES': 'Точки разгрузки',
      },
      'CONFIRMBUTTON' : {
        'CREATE' : 'Создать',
        'SAVE' : 'Сохранить',
        'CLOSE' : 'Закрыть',
      },
      'ERROR' : {
        'ITN_LENGTH' : 'Должно быть 12 цифр',
        'ITN_ONLY_NUMBERS': 'ИНН может содержать только цифры',
        'MUST_BE_FILLED' : 'Поле должно быть заполнено',
      },
      'HINT' : {
        'ITN' : '12 цифр',
      },
      'NAME' : 'Название покупателя',
      'CONTACT_NAME' : 'Имя контакта',
      'CONTACT_PHONE' : 'Телефон контакта',
      'ITN' : 'ИНН',
      'CUSTOMER' : 'Покупатель',
      'ADDRESS' : 'Адрес',
      'UNLOADING_POINTS' : 'Адреса разгрузки',
      'DEFAULT_POINT' : 'Точка разгрузки 1',
      'DEFAULT_PRICE_TYPE' : 'Тип цены по умолчанию',
      'RECEIVABLES' : 'Задолженность',
      'CREDIT' : 'Предоплата',
      'LAST_PAYMENT' : 'Последний платеж',
      'PERMISSIONS' : {
        'TITLE': 'Ограничения',
        'MESSAGE': "Изменить ограничения для '{{value}}'?",
        'SAVE': 'Сохранить',
        'DRIVERS_PHONE': 'Может видеть номер водителя',
      },
    },
  },
};
