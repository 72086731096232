import {locale as unloading_points_en} from "./en/locale.unloading-points";
import {locale as unloading_points_ru} from "./ru/locale.unloading-points";

export const unloading_points = [
  {
    lang: "en",
    data: unloading_points_en
  },
  {
    lang: "ru",
    data: unloading_points_ru
  }
];