import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { AutoOnMapComponent } from './onmap.component';
import { AgmCoreModule } from '@agm/core';

import { MaterialModule } from 'app/main/material.module';

import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { TableHeaderModule} from 'app/components/tableheader/tableheader.module';

@NgModule({
    declarations: [
        AutoOnMapComponent
    ],
    imports     : [
        TranslateModule,
        MaterialModule,
        FuseSharedModule,
        AgmCoreModule,
        AgmJsMarkerClustererModule,
        TableHeaderModule,
    ],
    exports     : [
        AutoOnMapComponent
    ],
})

export class AutoOnMapModule
{
}
