
import { FuseNavigation } from '@fuse/types';

export const users: FuseNavigation = {
  id       : 'users',
  title    : 'Users',
  translate: 'NAV.ADMIN.USERS',
  type     : 'item',
  icon     : 'people',
  url      : '/admin/users',
};

export const vehicles: FuseNavigation = {
  id       : 'vehicles',
  title    : 'Vehicles and Trailers',
  translate: 'NAV.ADMIN.VEHICLES',
  type     : 'item',
  icon     : 'commute',
  url      : '/admin/vehicles',
};

export const articles: FuseNavigation = {
  id       : 'articles',
  title    : 'Articles',
  translate: 'NAV.ADMIN.ARTICLES',
  type     : 'item',
  icon     : 'local_mall',
  url      : '/admin/articles',
};

export const exportQueue: FuseNavigation = {
  id       : 'export-queue',
  title    : 'ExportQueue',
  translate: 'NAV.ADMIN.EXPORT_QUEUE',
  type     : 'item',
  icon     : 'hourglass_bottom',
  url      : '/admin/export-queue',
};

export const settings: FuseNavigation = {
  id       : 'settings',
  title    : 'Settings',
  translate: 'NAV.ADMIN.SETTINGS',
  type     : 'item',
  icon     : 'settings',
  url      : '/admin/settings',
};

export const messagesList: FuseNavigation = {
  id       : 'messages-list',
  title    : 'MessagesList',
  translate: 'NAV.ADMIN.MESSAGES_LIST',
  type     : 'item',
  icon     : 'message',
  url      : '/admin/messages-list',
};

export const kilometers: FuseNavigation = {
  id       : 'kilometers',
  title    : 'Kilometers',
  translate: 'NAV.ADMIN.KILOMETERS',
  type     : 'item',
  icon     : 'moving',
  url      : '/admin/kilometers',
};
