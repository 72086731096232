
<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="primary" 
                 title="{{title | translate}}">
  </dialog-header>
  
  <div class="amber" *ngIf="!isEditingEnabled()" fxLayout="row" fxLayoutAlign="center center">
    <mat-label>{{ 'DIALOG.VEHICLE.APPROVED_LOCK' | translate }}</mat-label>
  </div>

  <div class="pt-8 px-16">
    <form [formGroup]="form">

      <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">
        <mat-form-field fxFlex>
          <mat-select placeholder="{{'DIALOG.VEHICLE.MODEL_SELECT' | translate}}"
                      formControlName="model"
                      [compareWith]="compareFunction"
                      required
                      cdkFocusInitial>
            <mat-option *ngFor='let model of modelsLoader.getLoadedItems()' [value]="model">{{model.fullName()}}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('model').invalid">{{'DIALOG.VEHICLE.ERROR.NO_MODEL' | translate}}</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">
        <mat-form-field fxFlex='45'>
          <input matInput
                 placeholder="{{'DIALOG.VEHICLE.NUMBER' | translate}}"
                 formControlName="number"
                 required>
          <mat-error *ngIf="form.get('number').hasError('noMatchToRegEx') || form.get('number').invalid">
            {{'DIALOG.VEHICLE.ERROR.INVALID_NUMBER' | translate}}
          </mat-error>
          <mat-hint *ngIf="!form.get('number').hasError('noMatchToRegEx')" align="end">
            {{'DIALOG.VEHICLE.HINT.VALID_NUMBER' | translate}}
          </mat-hint>
        </mat-form-field>

        <mat-form-field fxFlex='45'>
          <input matInput
                 type="number"
                 class="no-spinners"
                 placeholder="{{'DIALOG.VEHICLE.TONNAGE' | translate}}"
                 formControlName="tonnage"
                 required>
          <mat-error *ngIf="(form.get('tonnage').hasError('min') || form.get('tonnage').hasError('max')) || form.get('tonnage').invalid">
            {{'DIALOG.VEHICLE.ERROR.INVALID_TONNAGE' | translate}}
          </mat-error>
          <mat-hint *ngIf="!(form.get('tonnage').hasError('min') || form.get('tonnage').hasError('max'))" align="end">
            1-99
          </mat-hint>
        </mat-form-field>
      </div>

      <div *ngIf="isCarrierVisible()">
        <mat-form-field fxFlex>
          <mat-select placeholder="{{'DIALOG.VEHICLE.CARRIER_SELECT' | translate}}"
                      formControlName="carrier"
                      [compareWith]="compareFunction"
                      required>
            <mat-option *ngFor='let carrier of carriersLoader.getLoadedItems()' [value]="carrier">{{carrier.getName()}}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('carrier').invalid">{{'DIALOG.VEHICLE.ERROR.NO_CARRIER' | translate}}</mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field fxFlex>
          <input matInput
                 type="text"
                 [matDatepicker]="inspectionDatePicker"
                 placeholder="{{'DIALOG.VEHICLE.INSPECTION_DATE' | translate}}"
                 formControlName="inspectionDate">
          <mat-datepicker-toggle matSuffix [for]="inspectionDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #inspectionDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field fxFlex>
          <input matInput
                 type="text"
                 [matDatepicker]="insuranceDatePicker"
                 placeholder="{{'DIALOG.VEHICLE.INSURANCE_DATE' | translate}}"
                 formControlName="insuranceDate">
          <mat-datepicker-toggle matSuffix [for]="insuranceDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #insuranceDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="pt-8">
        <attachment #attachment 
                    [files]="attachments" 
                    [editingEnabled]="isEditingEnabled()"
                    (onRemoveCallback)="onAttachmentRemove($event.value)">
        </attachment>
      </div>

      <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
       <button mat-raised-button
               color="accent"
               [disabled] = "dataSaver.isProcessing()"
               (click)='checkAndSave()'>{{buttonName | translate}}
       </button>
      </div>
    </form>
  </div>
</div>
