import {locale as home_en} from "./en/locale.home";
import {locale as home_ru} from "./ru/locale.home";

export const home = [
  {
    lang: 'en',
    data: home_en
  },
  {
    lang: 'ru',
    data: home_ru
  },
];

