import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { AutoListComponent } from './list.component';

import { MaterialModule } from 'app/main/material.module';

import { TableHeaderModule} from 'app/components/tableheader/tableheader.module';
import { TableModule } from 'app/components/table/table.module';
import { ShowOnMapModule } from 'app/main/auto/dialog/showonmap/show-on-map-dialog.module';
import { DisbandUnitModule } from 'app/main/auto/dialog/disbandconfirmation/disband-unit-dialog.module';
import { DownloadModule } from 'app/components/download/download.module';
import { TrackDownloadDialog } from 'app/components/track-download/track-download.dialog';
import { DialogHeaderModule } from 'app/components/dialogs/dialog-header/dialog-header.module';
import { ActionBarModule } from 'app/components/action-bar/action-bar.module';

@NgModule({
    declarations: [
        AutoListComponent,
    ],
    providers: [
      TrackDownloadDialog
    ],
    imports     : [
        TranslateModule,

        FuseSharedModule,
        MaterialModule,
        TableHeaderModule,
        TableModule,
        ShowOnMapModule,
        DisbandUnitModule,
        DownloadModule,
        DialogHeaderModule,
        ActionBarModule,
    ],
    exports     : [
        AutoListComponent,
    ],
})

export class AutoListModule
{
}
