<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="primary" 
                 title="{{'ORDERINFO.DIALOG.FIELD_EDIT.TITLE' | translate}}">
  </dialog-header>
  <div class="p-16">
    <form>
      <div>
        <mat-form-field fxFlex>
          <input matInput 
                 [type]="fieldType" 
                 placeholder="{{fieldName | translate}}"
                 [formControl]="fieldControl"
                 min="0" 
                 required>
          <mat-error *ngIf="fieldControl.hasError('min')">
            {{'ORDERINFO.DIALOG.FIELD_EDIT.ERROR.LIMIT' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button
                color="accent"
                [disabled] = "dataSaver.isProcessing()"
                (click)='checkAndSave()'>{{'ORDERINFO.DIALOG.FIELD_EDIT.SAVE' | translate}}
        </button>
      </div>
    </form>
  </div>
</div>
  