export const locale = {
    'PROPOSALS': {
        'DIALOG': {
            'RESERVATION': {
                'TITLE': 'Бронирование',
                'CONFIRMBUTTON': 'Забронировать',
                'BY_CARRIER_MESSAGE': 'Вы действительно хотите забронировать эту заявку?',
                'REMOVE': 'Снять бронь',
                'CONTROLS': {
                    'CARRIER_SELECTOR': 'Перевозчик',
                },
                'ERROR': 'Не удалось забронирровать',
            },
        },
    },
};
