
import { CurrentUser } from 'app/components/data/core/models/base/user';
import { OrderLimitation } from 'app/components/data/core/models/persons/carrier';

export namespace DispatcherOrderWriteAccess {
  export function isLoaded(): boolean {
    if (!CurrentUser.isDispatcher()) {
      return true;
    }
    return CurrentUser.carrier().getOrderPermissions() !== undefined;
  }

  export function load(): Promise<any> {
    if (!CurrentUser.isDispatcher()) {
      return Promise.resolve();
    }
    return CurrentUser.carrier().fetch().then(() => {
      return Promise.resolve();
    });
  }

  export function orderPermissions(): OrderLimitation {
    if (CurrentUser.isAdministrator() || CurrentUser.isLogistician() || CurrentUser.isManager()) {
      return { create: true, update: true, salePrice: true, reserveOrder: true };
    } else if (CurrentUser.isCustomer()) {
      return { create: true, update: true, salePrice: false, reserveOrder: false };
    } else if (!CurrentUser.isDispatcher() || !isLoaded()) {
      return { create: false, update: false, salePrice: false, reserveOrder: false };
    }
    return CurrentUser.carrier().getOrderPermissions();
  }

  export interface TableOperationUpdating {
    updateOperations(): void;
  }

  export function checkTableOperations(component: TableOperationUpdating): void {
    if (!isLoaded()) {
      reloadTableOperations(component);
    }
  }

  export function reloadTableOperations(component: TableOperationUpdating): void {
    if (!CurrentUser.isDispatcher()) {
      return;
    }
    load().then(() => {
      component.updateOperations();
    });
  }

}
