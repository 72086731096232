export const locale = {
    'DIALOG': {
        'EDIT_PASSES': {
            'TITLE': 'Edit passes',
            'CONFIRMBUTTON': 'Close',
            
            'TABLEROW': {
                'ZONE': 'Pass type',
                'TIME_OF_ACTION': 'Time of action',
                'DATES': 'Dates',
                'NUMBER': 'Number',
                'STATUS': 'Status',
            }
        },
    },
};
