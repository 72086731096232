
export const locale = {
  'VEHICLES': {
    'ERROR': {
      'UNDEFINED': 'Undefined error has occured',
      'RELATION': {
        'UNIT': "Can't remove object '{{value}}'. Because there is some relations with other objects",
        'VEHICLE': "Can't remove object '{{value}}'. Because there is some relations with other objects",
        'MODEL': "Can't remove object '{{value}}'. Because there is some relations with other objects",
      }
    },
    'ROWOPERATION': {
      'APPROVE': 'Approve',
      'EDIT_PASSES': 'Edit passes',
    },
    'TABLEROW': {
      'GROUPNAME': 'Group name',
      'MODELNAME': 'Model name',
      'BRANDNAME': 'Brand name',
      'CARRIER': 'Carrier',
      'NUMBER': 'Number',
      'TONNAGE': 'Tonnage',
      'APPROVE_STATUS': 'Approval status',
      'EQUIPMENT': 'Equipment',
      'PASS': 'Pass',
      'INSPECTION': 'Inspection date',
      'INSURANCE': 'Insurance end date',
    },
    'TITLE': 'Vehicles and Trailers',
    'TABS': {
      'VEHICLES': 'Vehicle',
      'TRAILERS': 'Trailers',
      'BRANDS': 'Brands',
      'MODELS': 'Models',
      'TYPES': 'Groups',
    },
  }
};
