import { Component, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { LocaleService } from 'app/components/locale/locale.service';
import { trailer } from '../../../locale/locale';

import { PFQuery } from 'app/components/data/core/models/base/query';
import { PFObject } from 'app/components/data/core/models/base/object';

import { CurrentUser } from 'app/components/data/core/models/base/user';
import { Trailer } from 'app/components/data/core/models/transport/transport';

import * as CustomValidators from 'app/components/validators/validators';
import * as FormHelper from 'app/components/form-helper/form-helper';
import { CloudExecutor } from 'app/components/data/cloud-executors/cloud-executor';
import { DataLoader } from 'app/components/data/data-loader';
import { LocalNotificationService } from 'app/components/local-notifications-service/local-notifications-service';
import { TranslateService } from '@ngx-translate/core';
import { AttachmentComponent } from 'app/components/attachment/attachment.component';

@Component({
  selector: 'trailer-dialog',
  templateUrl: './trailer-dialog.component.html',
  styleUrls: ['./trailer-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TrailerDialog {
  compareFunction = PFObject.compareFn;

  form: UntypedFormGroup;
  private object: Trailer;

  title: string;
  buttonName: string;
  carriersLoader: DataLoader;
  dataSaver = new CloudExecutor;

  attachments;
  private attachmentsToRemove = [];
  @ViewChild('attachment') attachment: AttachmentComponent;

  constructor(
    private localeService: LocaleService,
    public dialogRef: MatDialogRef<TrailerDialog>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private notifications: LocalNotificationService,
    private translate: TranslateService,
    private _formBuilder: UntypedFormBuilder
  ) {
    this.carriersLoader = new DataLoader('Carrier');
    this.localeService.loadLocale(trailer);
    this.object = this._data.object;
    if (!this.object) {
      this.object = new Trailer();
    }

    let actionName;
    if (this._data.action === 'create') {
      actionName = 'CREATE';
    } else if (this._data.action === 'edit') {
      actionName = 'EDIT';
    }

    this.title = 'DIALOG.TRAILER.' + actionName + ".TITLE";
    this.buttonName = 'DIALOG.TRAILER.' + actionName + ".CONFIRMBUTTON";

    this.form = this.createContactForm();
  }

  createContactForm(): UntypedFormGroup {
    let number = this.object.number();
    if (this._data.action === 'create') {
      number = null;
    }
    
    let tonnageValidators = [Validators.min(1), Validators.max(99)];
    let disabled = !this.isEditingEnabled();

    let inspectionDate = this.object.inspectionDate();

    return this._formBuilder.group({
      number: [{ value: number, disabled: disabled }],
      tonnage: [{ value: this.object.tonnage(), disabled: disabled }, tonnageValidators],
      carrier: [{ value: this.object.carrier(), disabled: disabled }],
      inspectionDate: [{ value: inspectionDate, disabled: false }],
    });
  }

  ngOnInit() {
    if (this.isCarrierVisible) {
      this.carriersLoader.loadAll();
    }
    this.loadAttachments();
  }

  isCarrierVisible(): boolean {
    return CurrentUser.isAdministrator() || CurrentUser.isLogistician() || CurrentUser.isManager();
  }

  isEditingEnabled(): boolean {
    return !this.object.approved();
  }

  checkAndSave() {
    let verified = this.checkForm();

    if (verified) {
      let number = this.form.controls.number.value;
      let carrier = this.form.controls.carrier.value;

      var needToCheck = this.object.number() != number ||
        !PFObject.compareFn(this.object.carrier(), carrier);

      if (this.isEditingEnabled()) {
        this.object.setNumber(number);
        this.object.setTonnage(this.form.controls.tonnage.value);
      }
      let inspectionDate = this.form.controls.inspectionDate.value;
      if (inspectionDate) {
        this.object.setInspectionDate(inspectionDate);
      }

      if (this._data.action === 'create') {
        if (CurrentUser.isDispatcher()) {
          this.object.setCarrier(CurrentUser.carrier());
        }
      }
      if (this.isCarrierVisible()) {
        this.object.setCarrier(carrier);
      }
      if (needToCheck) {
        this.checkVehicle()
          .then(() => this.save())
          .catch((error) => {
            var msg = 'DIALOG.TRAILER.ERROR.';
            if (!error) {
              msg += 'TRAILER_EXISTS';
            } else {
              msg += 'UNDEFINED'
            }
            this.notifications.showErrorNotification(this.translate.instant(msg));
          })
      } else {
        this.save();
      }
    }
  }

  private checkVehicle() {
    let query = new PFQuery('Trailer');
    query.contains('number', this.object.number());
    query.equalTo('carrier', this.object.carrier());
    return query.first().then((obj) => {
      if (obj) {
        return Promise.reject();
      } else {
        return Promise.resolve();
      }
    });
  }

  private save() {
    this.updateAttachments()
      .then(() => {
        this.dataSaver.saveObject(this.object).then(obj => {
          this.dialogRef.close(obj);
        });
      })
      .catch((error) => {
        console.log(error);
        let msg = 'DIALOG.TRAILER.ERROR.UNDEFINED';
        this.notifications.showErrorNotification(this.translate.instant(msg));
      });
  }

  private checkForm(): boolean {
    return FormHelper.checkForm(this.form);
  }

  private uploadAttachments() {
    return this.attachment.uploadAttachments();
  }

  private addAttachments(vehicle, attachments) {
    if (attachments) {
      attachments.forEach(obj => {
        vehicle.attachDocument(obj);
      });
    }
  }

  private removeAttachments(vehicle, attachments) {
    if (attachments.length > 0) {
      attachments.forEach(obj => {
        vehicle.removeDocument(obj.file);
      });
    }
  }

  onAttachmentRemove(attachment) {
    if (!(attachment.file instanceof File)) {
      this.attachmentsToRemove.push(attachment);
    }
  }

  private loadAttachments() {
    let attachedFiles = [];
    let files = this.object.getAttachedDocuments();
    if (!files) {
      attachedFiles = [];
    } else {
      files.forEach(file => {
        let name = file.name();
        name = name.substr(name.indexOf('_') + 1);
        let doc = {
          name: name,
          file: file,
          content: file.url(),
        }
        attachedFiles.push(doc);
      });
    }
    this.attachments = attachedFiles;
  }

  private updateAttachments() {
    if (this._data.action === 'create') {
      return this.uploadAttachments().then((attachedFiles) => {
        this.addAttachments(this.object, attachedFiles);
        return Promise.resolve();
      });
    } else {
      this.removeAttachments(this.object, this.attachmentsToRemove);
      return this.uploadAttachments().then((attachedFiles) => {
        this.addAttachments(this.object, attachedFiles);
        return Promise.resolve();
      });
    }
  }
}
