
import { Component, ViewChild, ElementRef, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as en } from './locale/en';
import { locale as ru } from './locale/ru';

import { AuthenticationService } from 'app/authentication/authentication.service';
import { CurrentUser } from 'app/components/data/core/models/base/user';

@Component({
    selector   : 'login',
    templateUrl: './login.component.html',
    styleUrls  : ['./login.component.scss'],
    animations : fuseAnimations
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit
{
    loginForm: UntypedFormGroup;
    loginFormErrors: any;
    error: boolean;

    // Private
    private _unsubscribeAll: Subject<any>;

    @ViewChild('nameInput') nameInput: ElementRef;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _formBuilder: UntypedFormBuilder,
        private _authenticationService: AuthenticationService
    )
    {
      this._fuseTranslationLoaderService.loadTranslations(en, ru);

      this._fuseConfigService.config = {
        layout: {
          navbar : {hidden: true},
          toolbar: {hidden: true},
          footer : {hidden: true}
        }
      };

      this.loginFormErrors = {
        name   : {},
        password: {}
      };

      this.error = false;

      this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void
    {
        this.loginForm = this._formBuilder.group({
            name   : ['', Validators.required],
            password: ['', Validators.required]
        });

        this.loginForm.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.onLoginFormValuesChanged();
            });
    }

    ngOnDestroy(): void
    {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngAfterViewInit(): void {
      setTimeout(() => {
        this.nameInput.nativeElement.focus();
      });
    }

    onLoginFormValuesChanged(): void {
      for (let field in this.loginFormErrors) {
        if (!this.loginFormErrors.hasOwnProperty(field)) {
            continue;
        }

        this.loginFormErrors[field] = {};

        const control = this.loginForm.get(field);

        if (control && !control.disabled && control.dirty && !control.valid) {
          this.loginFormErrors[field] = control.errors;
        }
      }
    }

    onLoginClicked(): void {
      this.error = false;
      this.loginForm.disable();

      let name = this.loginForm.controls.name.value;
      let password = this.loginForm.controls.password.value;

      CurrentUser.logIn(name, password).then((user) => {
        this._authenticationService.finishLogIn();
      }, (error) => {
        this.error = true;
        this.loginForm.enable();
      });
    }
}
