
export const locale = {
    'DIALOG' : {
      'CREATEPOINT' : {
        'TITLE' : {
          'CREATE' : 'Создать точку',
          'EDIT' : 'Изменить точку',
        },
        'CONFIRMBUTTON' : {
          'CREATE' : 'Создать',
          'SAVE' : 'Сохранить',
        },
        'NAME' : 'Название точки',
        'ADDRESS' : 'Адрес',
        'CONTACT_NAME': 'Имя контакта',
        'CONTACT_PHONE': 'Телефон контакта',
        'LATITUDE' : 'Широта',
        'LONGITUDE' : 'Долгота',
        'FINDADDRESS': 'Узнать адрес',
        'FINDCOORDINATE': 'Узнать координаты',
        'ERROR' : {
          'MIN' : 'Минимальное значение {{min}}',
          'MAX' : 'Максимальное значение {{max}}',
        },
      },
    },
};
