import { Injectable } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class LocalNotificationService {
    constructor(private snackBar: MatSnackBar) {}

    showErrorNotification(error) {
        this.snackBar.open(error, 'OK', {
            panelClass: ['error-snackbar'],
            duration: 10000,
          });
    }

    showSuccessNotification(text) {
        this.snackBar.open(text, 'OK', {
          panelClass: ['success-snackbar'],
          duration: 5000,
        });
      };
}