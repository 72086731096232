import { ArticleBrand } from 'app/components/data/core/models/articles/article';
import { DialogPresenterService } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import { TableOperationActionType, TableOperationsHelper } from 'app/components/helpers/table-operations.helper';
import { TableOperationsFactory } from 'app/components/table-operations-controller/table-operations-controller';
import { SupplierBrandAddDialog } from './dialog/add/add-brands.dialog.component';

export interface SuppliersBrandsDataSource {
    getBrands(): ArticleBrand[]
    setBrands(brands: ArticleBrand[])
}

export class SupplierBrandTableOperationsFactory implements TableOperationsFactory {

    constructor(
        private dialogPresenter: DialogPresenterService,
        private source: SuppliersBrandsDataSource,
        private completion,
    ) { }

    makeOperations(selected) {
        return this.makeOperationsWithSelected(selected);
    }

    private makeOperationsWithSelected(selected) {
        let addOperation = TableOperationsHelper.makeOperationWithType(
            TableOperationActionType.Add,
            this.dialogPresenter,
            () => this.makeDialogData(),
            null,
            (added) => {
                if (added && added.length > 0) {
                    let brands = this.source.getBrands().concat(added);
                    this.source.setBrands(brands);
                    this.completion();
                }
            }
        )
        return [
            addOperation,
            TableOperationsHelper.makeRemoveOperation(() => this.removeMessage(selected), selected)
        ];
    }

    private makeDialogData() {
        return {
            dialog: SupplierBrandAddDialog,
            panel: 'add-supplier-brands-dialog-container',
            data: {
                alreadyAdded: this.source.getBrands()
            }
        }
    }

    private removeMessage(selected: ArticleBrand[]) {
        this.dialogPresenter.askRemoveItems(selected, (result) => {
            if (result) {
                let ids = result.map(brand => brand.id)
                let brands = this.source.getBrands().filter(brand => ids.indexOf(brand.id) == -1)
                this.source.setBrands(brands);
                this.completion()
            }
        }, false, true);
    }
}