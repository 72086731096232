
import { Component, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';

import { TableComponent } from 'app/components/table/table.component';

import { ArticleBrandsTableData } from './tabledata/article-brands-tabledata';
import { ArticleTypesTableData } from './tabledata/article-types-tabledata';

import { DialogPresenterService } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';

import { LocaleService } from 'app/components/locale/locale.service';
import { articles } from './locale/locale';
import { default_row_operations, common } from "app/main/locale/locale";

import { ArticlesTypeOperationsFactory } from './operations-factory/article-type-table.operations-factory';
import { ArticlesBrandOperationsFactory } from './operations-factory/article-brand-table.operations-factory';
import { TabInfo } from 'app/components/tab/tab-info';

const locales = [
  articles,
  default_row_operations,
  common,
];

@Component({
  selector: 'articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent {
  public tabsList: TabInfo[];
  headerButtons = [];

  @ViewChildren(TableComponent) tables: QueryList<TableComponent>;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  constructor(
    private localeService: LocaleService,
    private dialogPresenterService: DialogPresenterService
  ) {
    this.localeService.loadLocales(locales);
    this.tabsList = this.createTabsList();
    this.headerButtons = this.makeHeaderButtons();
  }

  tabChanged() {
    this.reloadCurrentTableData();
  }

  search(searchString: string) {
    this.currentTable().search(searchString);
  }

  private createTabsList() {
    return [
      new TabInfo({
        title: 'ARTICLE.TABS.TYPES',
        tableData: new ArticleTypesTableData(),
        operationsFactory: new ArticlesTypeOperationsFactory(this.dialogPresenterService, () => { this.reloadCurrentTableData() }),
      }),
      new TabInfo({
        title: 'ARTICLE.TABS.BRANDS',
        tableData: new ArticleBrandsTableData(),
        operationsFactory: new ArticlesBrandOperationsFactory(this.dialogPresenterService, () => { this.reloadCurrentTableData() }),
      }),
    ];
  }

  private currentTable() {
    return this.tables.toArray()[this.tabGroup.selectedIndex];
  }

  private reloadCurrentTableData() {
    this.currentTable().reloadData();
  }

  private makeHeaderButtons() {
    return [
      {
        'title': 'COMMON.BUTTONS.RELOAD',
        'action': () => this.reloadCurrentTableData()
      },
    ];
  }
}





