export const locale = {
    'DIALOG' : {
      'AGREEMENT' : {
        'TITLE' : {
          'CREATE' : 'Create address',
          'EDIT' : 'Edit address',
        },
        'CONFIRMBUTTON' : {
          'CREATE' : 'Create',
          'SAVE' : 'Save',
          'CLOSE' : 'Close',
        },
        'ERROR' : {
          'MUST_BE_FILLED' : 'Must be filled',
          'ZERO_RESULTS' : "Can't get coordinate for address '{{value}}'. Verify that the address is correct."
        },
        
        'NAME' : 'Agreement name',
        'ADDRESS' : 'Address',
        'DEFAULT_POINT' : 'Entrance 1',
      },
    },
  };