import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { LocaleService } from 'app/components/locale/locale.service';

import { home } from './locale/locale'

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['home.component.scss']
})

export class HomeComponent {

  constructor(
    _fuseConfigService: FuseConfigService,
    localeService: LocaleService,
    private router: Router
  ) {
    _fuseConfigService.config = {
      layout: {
        navbar: { hidden: true },
        toolbar: { hidden: true },
        footer: { hidden: true }
      }
    };
    localeService.loadLocale(home);
  }

  enter() {
    this.router.navigateByUrl("/login")
  }

  goToAppStore() {
    window.open('https://apps.apple.com/ru/app/id1561781393', "_blank");
  }

  goToPlayMarket() {
    window.open('https://play.google.com/store/apps/details?id=com.macsoftex.cargodeal_manager', "_blank");
  }
}