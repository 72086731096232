import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PFObject } from 'app/components/data/core/models/base/object';
import { Supplier } from 'app/components/data/core/models/persons/supplier';
import { SupplierBrandsTableData } from './supplier-brands.tabledata';
import { LocaleService } from 'app/components/locale/locale.service';
import { supplier } from '../../../locale/locale';
import { defaultTableOptions, TableComponent, TableOptions } from 'app/components/table/table.component';
import { CloudExecutor } from 'app/components/data/cloud-executors/cloud-executor';
import { TableOperationsController } from 'app/components/table-operations-controller/table-operations-controller';
import { SupplierBrandTableOperationsFactory, SuppliersBrandsDataSource } from './supplier-brands.operations-factory';
import { DialogPresenterService } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import { ArticleBrand } from 'app/components/data/core/models/articles/article';

let locales = [
  supplier,
];

@Component({
  selector: 'supplier-brands-dialog',
  templateUrl: './supplier-brands-dialog.component.html',
  styleUrls: ['./supplier-brands-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SupplierBrandPriorityDialog implements SuppliersBrandsDataSource {
  compareFunction = PFObject.compareFn;

  public supplier: Supplier;

  private brandsList: ArticleBrand[]

  tableData: SupplierBrandsTableData;
  tableOptions: TableOptions;
  operationsController: TableOperationsController;

  private dataSaver = new CloudExecutor();

  constructor(
    private localeService: LocaleService,
    dialogPresenter: DialogPresenterService,
    private dialogRef: MatDialogRef<SupplierBrandPriorityDialog>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
  ) {
    this.localeService.loadLocales(locales);
    this.supplier = this._data.object;
    this.brandsList = this.supplier.getBrands();
    this.tableOptions = defaultTableOptions();
    this.tableOptions.dragable = true;

    let operationsFactory = new SupplierBrandTableOperationsFactory(
      dialogPresenter,
      this,
      () => this.updateTableData()
    );
    this.operationsController = new TableOperationsController(operationsFactory);
    this.updateTableData();
  }

  private updateTableData() {
    this.tableData = new SupplierBrandsTableData(this.brandsList);
  }

  getBrands(): ArticleBrand[] {
    return this.brandsList;
  }

  setBrands(brands: ArticleBrand[]) {
    this.brandsList = brands;
  }

  updateOperations(table: TableComponent) {
    this.operationsController.updateOperations(table);
  }

  isProcessing() {
    return this.dataSaver.isProcessing();
  }

  save() {
    let brands = this.tableData.provider.getData();
    this.supplier.setBrands(brands);
    this.dataSaver.saveObject(this.supplier).then(() => {
      this.dialogRef.close();
    });
  }
}
