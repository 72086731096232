import { Response, CloudExecutor } from './cloud-executor';
import { Order, OrderStatus } from 'app/components/data/core/models/order/order';
import { Carrier } from 'app/components/data/core/models/persons/carrier';
import { TransportUnit } from "app/components/data/core/models/transport/transport";

import { Vehicle, Trailer } from 'app/components/data/core/models/transport/transport';
import { Driver } from 'app/components/data/core/models/persons/driver';
import { Customer } from 'app/components/data/core/models/persons/customer';

export type DispatcherCloudExecutorProgress = (current: number, total: number) => any;

export interface VerifyDriverResponse extends Response {
    powerOfAttorney?: boolean
}

export interface VerifyCustomerResponse extends Response {
    allowed?: boolean
    accountsReceivable?: string
    accountsPayable?: string
    lastPaymentDate?: number
    stopFactor?: string
}

export class DispatcherCloudExecutor extends CloudExecutor {
    assignCarriers(orders: Order[], carriers: Carrier[]) {
        let params = {
            orderNumbers: orders.map(order => order.number()),
            carrierIds: carriers ? carriers.map(carrier => carrier.id) : null,
        };
        return this.run('Dispatcher_assignCarriers', params)
            .then((response: Response) => {
                if (response.result) {
                    return Promise.resolve(response);
                } else {
                    return Promise.reject(response.error);
                }
            });
    }

    assignTransportUnit(order: Order, unit: TransportUnit) {
        let params = {
            orderNumber: order.number(),
            transportUnitId: unit.id,
        };
        return this.run('Dispatcher_assignTransportUnit', params)
            .then((response: Response) => {
                if (response.result) {
                    return Promise.resolve(response);
                } else {
                    return Promise.reject(response.error);
                }
            });
    }

    cancelAndDeclineOrder(order: Order) {
        return this.cancelOrder(order).then((response) => {
            if (response.result) return this.declineOrder(order);
            return Promise.reject(response.error);
        });
    }

    cancelAndDeclineOrders(orders: Order[], progress: DispatcherCloudExecutorProgress = null) {
        let promise = Promise.resolve();
        orders.forEach((order) => {
            promise = promise.then(() => {
                if (this.isCanceled()) return Promise.reject("canceled");
                if (progress) progress(orders.indexOf(order), orders.length);
                return this.cancelAndDeclineOrder(order);
            });
        });
        return promise;
    }

    cancelAndDeleteOrder(order: Order) {
        return this.cancelOrder(order).then((response) => {
            if (response.result) return this.deleteOrder(order);
            return Promise.reject(response.error);
        }, (error) => {
            return Promise.reject(error);
        })
    }

    cancelAndDeleteOrders(orders: Order[], progress: DispatcherCloudExecutorProgress = null) {
        let promise = Promise.resolve();
        orders.forEach((order) => {
            promise = promise.then(() => {
                if (this.isCanceled()) return Promise.reject("canceled");
                if (progress) progress(orders.indexOf(order), orders.length);
                return this.cancelAndDeleteOrder(order);
            })
        });
        return promise;
    }

    cancelOrder(order: Order) {
        let params = {
            orderNumber: order.number()
        }
        return this.run('Dispatcher_cancelOrder', params)
            .then((response: Response) => {
                if (response.result) {
                    return Promise.resolve(response);
                } else {
                    return Promise.reject(response.error);
                }
            });
    }

    cancelOrders(orders: Order[], progress: DispatcherCloudExecutorProgress = null) {
        let promise = Promise.resolve();
        orders.forEach((order) => {
            promise = promise.then(() => {
                if (this.isCanceled()) return Promise.reject("canceled");
                if (progress) progress(orders.indexOf(order), orders.length);
                return this.cancelOrder(order);
            }).then((response) => {
                if (response.result) return Promise.resolve();
                return Promise.reject(response.error);
            }, (error) => {
                return Promise.reject(error);
            });
        });
        return promise;
    }

    declineOrder(order: Order) {
        let params = {
            orderNumber: order.number()
        }
        return this.run('Dispatcher_declineOrder', params)
            .then((response: Response) => {
                if (response.result) {
                    return Promise.resolve();
                } else {
                    return Promise.reject(response.error);
                }
            });
    }

    declineOrders(orders: Order[], progress: DispatcherCloudExecutorProgress = null) {
        let promise = Promise.resolve();
        orders.forEach((order) => {
            promise = promise.then(() => {
                if (this.isCanceled()) return Promise.reject("canceled");
                if (progress) progress(orders.indexOf(order), orders.length);
                return this.declineOrder(order);
            });
        });
        return promise;
    }

    deleteOrder(order: Order) {
        return order.markDeleted();
    }

    deleteOrders(orders: Order[], progress: DispatcherCloudExecutorProgress = null) {
        let promise = Promise.resolve();
        orders.forEach((order) => {
            promise = promise.then(() => {
                if (this.isCanceled()) return Promise.reject("canceled");
                if (progress) progress(orders.indexOf(order), orders.length);
                return this.deleteOrder(order);
            });
        });
        return promise;
    }

    deleteUser(role: string, personId: string) {
        let params = {
            role: role,
            personId: personId,
        };
        return this.run('Dispatcher_deleteUser', params)
            .then((response: Response) => {
                if (response.result) {
                    return Promise.resolve(response);
                } else {
                    return Promise.reject(response.error);
                }
            });
    }

    disbandTransportUnit(unit: TransportUnit) {
        let params = {
            transportUnitId: unit.id
        }
        return this.run('Dispatcher_disbandTransportUnit', params)
            .then((response: Response) => {
                if (response.result) {
                    return Promise.resolve();
                } else {
                    return Promise.reject(response.error);
                }
            });
    }

    finishOrder(
        order: Order,
        loadedDate: Date,
        loadedTonnage: number,
        distance: number,
        unloadedDate: Date,
        unloadedTonnage: number,
        loadedPhoto,
        unloadedPhoto) {
        let params = {
            orderNumber: order.number(),
            loadedDate: loadedDate.getTime(),
            loadedTonnage: loadedTonnage,
            unloadedDate: unloadedDate.getTime(),
            unloadedTonnage: unloadedTonnage,
            loadedPhoto: loadedPhoto,
            unloadedPhoto: unloadedPhoto
        }
        if (distance) {
            Object.assign(params, {
                loadedAdditionalData: {
                    kilometers: distance
                }
            })
        }
        return this.run('Dispatcher_finishOrder', params)
            .then((response: Response) => {
                if (response.result) {
                    return Promise.resolve(response);
                } else {
                    return Promise.reject(response.error);
                }
            });
    }

    makeTransportUnit(driver: Driver, vehicle: Vehicle, trailer: Trailer = null) {
        let params = {
            driverId: driver.id,
            vehicleId: vehicle.id,
        };
        if (trailer) {
            params['trailerId'] = trailer.id;
        }
        return this.run('Dispatcher_makeTransportUnit', params)
            .then((response: Response) => {
                if (response.result) {
                    return Promise.resolve(response);
                } else {
                    return Promise.reject(response.error);
                }
            });

    }

    makeUser(
        role: string,
        personId: string,
        username: string,
        password: string) {
        let params = {
            role: role,
            personId: personId,
            username: username,
            password: password,
        };
        return this.run('Dispatcher_makeUser', params)
            .then((response: Response) => {
                if (response.result) {
                    return Promise.resolve(response);
                } else {
                    return Promise.reject(response.error);
                }
            });
    }

    reserveOrder(order: Order) {
        let params = {
            orderNumber: order.number(),
        }
        return this.run('Dispatcher_reserveOrder', params)
            .then((response: Response) => {
                if (response.result) {
                    return Promise.resolve(response);
                } else {
                    return Promise.reject(response.error);
                }
            });
    }

    sendMessage(role: string, userId: string, title: string, body: string) {
        let params = {
            title: title,
            body: body,
        }

        if (role) {
            params['role'] = role;
        } else if (userId) {
            params['userId'] = userId;
        } else {
            params['all'] = true;
        }
        return this.run('Dispatcher_sendMessage', params)
            .then((response: Response) => {
                if (response.result) {
                    return Promise.resolve(response);
                } else {
                    return Promise.reject(response.error);
                }
            });
    }

    sendOffers(orders: Order[], units: TransportUnit[]) {
        let params = {
            orderNumbers: orders.map(order => order.number()),
            transportUnitIds: units.map(unit => unit.id),
        }
        return this.run('Dispatcher_sendOffers', params)
            .then((response: Response) => {
                if (response.result) {
                    return Promise.resolve(response);
                } else {
                    return Promise.reject(response.error);
                }
            });
    }

    setOrderStatus(order: Order, status: OrderStatus) {
        let params = {
            orderNumber: order.number(),
            status: status
        }
        return this.run('Dispatcher_setOrderStatus', params)
            .then((response: Response) => {
                if (response.result) {
                    return Promise.resolve(response);
                } else {
                    return Promise.reject(response.error);
                }
            });
    }

    setUserPassword(userId: string, password: string) {
        let params = {
            userId: userId,
            password: password,
        };
        return this.run('Dispatcher_setUserPassword', params)
            .then((response: Response) => {
                if (response.result) {
                    return Promise.resolve(response);
                } else {
                    return Promise.reject(response.error);
                }
            });
    }

    verifyCustomer(customer: Customer) {
        let params = {
            customerId: customer.id
        }
        return this.run('Dispatcher_verifyCustomer', params)
            .then((response: VerifyCustomerResponse) => {
                if (response.result) {
                    return Promise.resolve(response);
                } else {
                    return Promise.reject(response.error);
                }
            });
    }

    verifyDriver(driver: Driver) {
        let params = {
            driverId: driver.id,
        }
        return this.run('Dispatcher_verifyDriver', params)
            .then((response: VerifyDriverResponse) => {
                if (response.result) {
                    return Promise.resolve(response);
                } else {
                    return Promise.reject(response.error);
                }
            });
    }
}