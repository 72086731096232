import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { TableModule } from 'app/components/table/table.module';
import { MaterialModule } from 'app/main/material.module';

import { CustomerCreateDialog } from './create/customer-create-dialog.component';
import { CustomerCheckDialog } from './check/customer-check-dialog.component';
import { CustomerEditEntrancesDialogComponent } from './edit-entrances/customer-edit-entrances-dialog.component';
import { CustomerEditAddressDialogComponent } from './edit-address/customer-edit-address-dialog.component';
import { CustomerPermissionsDialog } from './permissions/customer-permissions-dialog.component';

import { AttachmentModule } from 'app/components/attachment/attachment.module';
import { AgmCoreModule } from '@agm/core';
import { UnloadingPointDialog } from './edit-address/unloading-points/unloading-points-dialog.component';
import { DialogHeaderModule } from 'app/components/dialogs/dialog-header/dialog-header.module';
import { ActionBarModule } from 'app/components/action-bar/action-bar.module';

@NgModule({
    declarations: [
        CustomerCreateDialog,
        CustomerEditEntrancesDialogComponent,
        CustomerCheckDialog,
        CustomerEditAddressDialogComponent,
        UnloadingPointDialog,
        CustomerPermissionsDialog,
    ],
    imports: [
        MaterialModule,
        TranslateModule,
        FuseSharedModule,
        TableModule,
        AttachmentModule,
        AgmCoreModule,
        DialogHeaderModule,
        ActionBarModule,
    ],
    exports: [
        CustomerCreateDialog,
        CustomerEditEntrancesDialogComponent,
        CustomerCheckDialog,
        CustomerEditAddressDialogComponent,
        UnloadingPointDialog,
        CustomerPermissionsDialog,
    ]
})

export class CustomerDialogsModule
{
}
