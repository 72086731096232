import { PFDeletableObject } from "../base/deletableObject";
import { PFObject } from "../base/object";

export class Article extends PFDeletableObject {
  constructor(name) { super(name); }

  name() : string { return super.get('name'); }
  setName(name: string) { super.set('name', name); }
}

export class ArticleBrand extends Article {
  constructor() { super('ArticleBrand'); }

  type() : ArticleType { return super.get('type'); }
  setType(type: ArticleType) { super.set('type', type); }

  tonnagePerTruck(): number { return super.get('tonnagePerTruck'); }
  setTonnagePerTruck(value: number) { super.set('tonnagePerTruck', value); }

  copy(): ArticleBrand {
    let copy = new ArticleBrand();
    copy.setName(this.name());
    copy.setType(this.type());
    copy.setTonnagePerTruck(this.tonnagePerTruck());
    return copy;
  }
}

export class ArticleShape extends Article {
  constructor() { super('ArticleShape'); }

  name() : string { return this.get('name'); }
  copy(): ArticleShape {
    let copy = new ArticleShape();
    copy.setName(this.name());
    return copy;
  }
}

export class ArticleType extends Article {
  constructor() { super('ArticleType'); }

  name() : string { return this.get('name'); }
  copy(): ArticleType {
    let copy = new ArticleType();
    copy.setName(this.name());
    return copy;
  }
}

export namespace ArticlesFilter {
  export function collectBrandsTypes(brands: ArticleBrand[]) {
    let types: ArticleType[] = [];
    brands.forEach(brand => {
      let type = brand.type();
      if (type) {
        let index = types.findIndex(t => {
          return PFObject.compareFn(t, type);
        });
        if (index == -1) {
          types.push(type);
        }
      }
    });
    return types;
  }

  export function filterBrandsWithType(brands: ArticleBrand[], type: ArticleType) {
    if (!type) return [];
    return brands.filter(brand => {
      return PFObject.compareFn(brand.type(), type);
    });
  }
}
