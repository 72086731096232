
export const locale = {
  'DIALOG' : {
    'CHANGEPASSWORD': {
      'TITLE': 'Change password',
      'CONFIRMBUTTON': 'Change',
      'PASSWORD' : 'Password',
    },
  },
};
