
<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="primary" 
                 title="{{title | translate}}">
  </dialog-header>
  
  <div class="pt-8 px-16">
    <form [formGroup]="form">

      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-form-field fxFlex>
          <mat-select placeholder="{{'DIALOG.CREATEMODEL.BRAND' | translate}}"
                      formControlName="brand"
                      [compareWith]="compareFunction"
                      required
                      cdkFocusInitial>
            <mat-option *ngFor='let brand of dataLoader.getLoadedItems()' [value]="brand">{{brand.get('name')}}</mat-option>
          </mat-select>
        </mat-form-field>
        <button type="button" mat-icon-button (click)="showDialog()"><mat-icon>add</mat-icon></button>
      </div>

      <div>
        <mat-form-field fxFlex>
          <input matInput
                 placeholder="{{'DIALOG.CREATEMODEL.NAME' | translate}}"
                 formControlName="name"
                 required>
        </mat-form-field>
      </div>
      <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button
                color="accent"
                [disabled] = "dataSaver.isProcessing()"
                class="submit-button"
                (click)='checkAndSave()'>{{buttonName | translate}}
        </button>
      </div>
    </form>
  </div>
</div>
