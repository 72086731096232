import {locale as users_en} from "./en/locale.users";
import {locale as users_ru} from "./ru/locale.users";

import {locale as change_password_en} from "./en/locale.change-password";
import {locale as change_password_ru} from "./ru/locale.change-password";

import {locale as driver_en} from "./en/locale.driver";
import {locale as driver_ru} from "./ru/locale.driver";

import {locale as dispatcher_en} from "./en/locale.dispatcher";
import {locale as dispatcher_ru} from "./ru/locale.dispatcher";

import {locale as manager_en} from "./en/locale.manager";
import {locale as manager_ru} from "./ru/locale.manager";

import {locale as supplier_en} from "./en/locale.supplier";
import {locale as supplier_ru} from "./ru/locale.supplier";

import {locale as points_en} from "./en/locale.points";
import {locale as points_ru} from "./ru/locale.points";

import {locale as customer_en} from "./en/locale.customer";
import {locale as customer_ru} from "./ru/locale.customer";

import {locale as entrance_en} from "./en/locale.entrance";
import {locale as entrance_ru} from "./ru/locale.entrance";

import {locale as credentials_en} from "./en/locale.credentials";
import {locale as credentials_ru} from "./ru/locale.credentials";

import {locale as intermediary_en} from "./en/locale.intermediary";
import {locale as intermediary_ru} from "./ru/locale.intermediary";

import {locale as agreement_en} from "./en/locale.agreement";
import {locale as agreement_ru} from "./ru/locale.agreement";

import {locale as carrier_en} from "./en/locale.carrier";
import {locale as carrier_ru} from "./ru/locale.carrier";

import {locale as create_unit_en} from "./en/locale.create-unit";
import {locale as create_unit_ru} from "./ru/locale.create-unit";

import {locale as logistician_en} from "./en/locale.logistician";
import {locale as logistician_ru} from "./ru/locale.logistician";



export const credentials = [
  {
    lang: "en",
    data: credentials_en
  },
  {
    lang: "ru",
    data: credentials_ru
  }
];

export const entrance = [
  {
    lang: "en",
    data: entrance_en
  },
  {
    lang: "ru",
    data: entrance_ru
  }
];

export const users = [
  {
    lang: "en",
    data: users_en
  },
  {
    lang: "ru",
    data: users_ru
  }
];

export const supplier = [
  {
    lang: "en",
    data: supplier_en
  },
  {
    lang: "ru",
    data: supplier_ru
  }
];

export const points = [
  {
    lang: "en",
    data: points_en
  },
  {
    lang: "ru",
    data: points_ru
  }
];

export const customer = [
  {
    lang: "en",
    data: customer_en
  },
  {
    lang: "ru",
    data: customer_ru
  }
];

export const create_driver = [
  {
    lang: "en",
    data: driver_en
  },
  {
    lang: "ru",
    data: driver_ru
  }
];

export const create_dispatcher = [
  {
    lang: "en",
    data: dispatcher_en
  },
  {
    lang: "ru",
    data: dispatcher_ru
  }
];

export const create_manager = [
  {
    lang: "en",
    data: manager_en
  },
  {
    lang: "ru",
    data: manager_ru
  }
];

export const change_password = [
  {
    lang: "en",
    data: change_password_en
  },
  {
    lang: "ru",
    data: change_password_ru
  }
];

export const intermediary = [
  {
    lang: "en",
    data: intermediary_en
  },
  {
    lang: "ru",
    data: intermediary_ru
  }
];

export const agreement = [
  {
    lang: "en",
    data: agreement_en
  },
  {
    lang: "ru",
    data: agreement_ru
  }
];

export const carrier = [
  {
    lang: "en",
    data: carrier_en
  },
  {
    lang: "ru",
    data: carrier_ru
  }
];

export const create_unit = [
  {
    lang: "en",
    data: create_unit_en
  },
  {
    lang: "ru",
    data: create_unit_ru
  }
];

export const create_logistician = [
  {
    lang: "en",
    data: logistician_en
  },
  {
    lang: "ru",
    data: logistician_ru
  }
];

