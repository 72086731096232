
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { MaterialModule } from 'app/main/material.module';

import { TableHeaderModule} from 'app/components/tableheader/tableheader.module'
import { TableModule } from 'app/components/table/table.module';

import { MessagesListComponent } from './messages-list.component';

import { MessagesDialogsModule } from './dialog/messages.dialog.module';
import { ActionBarModule } from 'app/components/action-bar/action-bar.module';

@NgModule({
  declarations: [
    MessagesListComponent,
  ],
  imports     : [
    TranslateModule,
    FuseSharedModule,

    MaterialModule,
    TableHeaderModule,
    TableModule,

    MessagesDialogsModule,
    ActionBarModule
  ],
  exports     : [
    MessagesListComponent,
  ],
})
export class MessagesModule {
}
