import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, Validators } from '@angular/forms';

import { CloudExecutor } from 'app/components/data/cloud-executors/cloud-executor';

@Component({
    selector: 'edit-field-dialog',
    templateUrl: './edit-field-dialog.component.html',
    styleUrls: ['./edit-field-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class EditFieldDialog {

    fieldControl: UntypedFormControl;

    public fieldType;
    public fieldName;

    public get fieldValue() {
        return this.fieldControl.value;
    };

    public set fieldValue(value) {
        this.fieldControl.setValue(value);
    };

    private changeValueHandler;

    public dataSaver = new CloudExecutor();

    constructor(
        public dialogRef: MatDialogRef<EditFieldDialog>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
    ) {
        this.fieldType = this._data.fieldType;
        this.fieldName = this._data.fieldName;
        this.changeValueHandler = this._data.changeValueHandler;

        var validators = [
            Validators.required,
        ];
        switch (this.fieldType) {
            case 'number': validators.push(Validators.min(1)); break;
        }
        this.fieldControl = new UntypedFormControl(this._data.fieldValue, validators);
    }

    checkAndSave() {
        if (this.fieldControl.valid) {
            let object = this.changeValueHandler(this.fieldValue);
            this.dataSaver.saveObject(object, { cascadeSave: false }).then(obj => {
                this.close(obj);
            });
        }
    }

    private close(obj) {
        this.dialogRef.close(obj);
    }
}
