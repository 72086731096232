import {locale as cancel_order_en} from "./en/locale.order-cancel";
import {locale as cancel_order_ru} from "./ru/locale.order-cancel";

export const cancel_customer_order_dialog = [
  {
    lang: 'en',
    data: cancel_order_en
  },
  {
    lang: 'ru',
    data: cancel_order_ru
  },
];

