
<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="primary" 
                 title="{{'DIALOG.TRAILER.APPROVE.TITLE' | translate}}">
  </dialog-header>
  
  <div class="pt-8 px-16">
    <form [formGroup]="form">

      <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">
        <mat-form-field fxFlex='45'>
          <input matInput
                 placeholder="{{'DIALOG.TRAILER.NUMBER' | translate}}"
                 formControlName="number"
                 required>
        </mat-form-field>

        <mat-form-field fxFlex='45'>
          <input matInput type="number" class="no-spinners"
                 placeholder="{{'DIALOG.TRAILER.TONNAGE' | translate}}"
                 formControlName="tonnage"
                 required>
        </mat-form-field>

      </div>
      <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button
                color="{{isApproved() ? 'warn' : 'accent'}}"
                [disabled] = "dataSaver.isProcessing()"
                class="submit-button"
                (click)='toggleApprovement()'>{{ 
                  ('DIALOG.TRAILER.APPROVE.' + (isApproved() ? 'DECLINEBUTTON' : 'CONFIRMBUTTON')) | translate 
                }}
        </button>
      </div>
    </form>
  </div>
</div>
