
export const locale = {
  'DIALOG' : {
    'AGREEMENT' : {
      'TITLE' : {
        'CREATE' : 'Добавить адрес загрузки',
        'EDIT' : 'Изменить адрес загрузки',
      },
      'CONFIRMBUTTON' : {
        'CREATE' : 'Создать',
        'SAVE' : 'Сохранить',
        'CLOSE' : 'Закрыть',
      },
      'ERROR' : {
        'MUST_BE_FILLED' : 'Поле должно быть заполнено',
        'ZERO_RESULTS' : "Не удалось определить координаты для адреса '{{value}}'. Проверьте правильность ввода адреса.",
      },
      'ADDRESS' : 'Адрес',
      'DEFAULT_POINT' : 'Точка загрузки 1',
    },
  },
};
