<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="warn" 
                 title="{{'CUSTOMER_ORDER.DIALOG.CANCEL.TITLE' | translate}}">
  </dialog-header>
  
  <div class="pt-8 px-16">
    <p class="h3">{{'CUSTOMER_ORDER.DIALOG.CANCEL.MESSAGE' | translate}}</p>
    <p>{{'CUSTOMER_ORDER.DIALOG.CANCEL.ORDERNUMBER' | translate}} {{order.number()}}</p>
    <p>{{ order.articleBrand().name() }}</p>
    <p translate [translateParams]="{value: order.tonnage()}">CUSTOMER_ORDER.DIALOG.CANCEL.TONNAGE</p>
    <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button
              color="warn"
              (click)="execute()"
              [disabled]="isExecuting()"
              cdkFocusInitial>{{'CUSTOMER_ORDER.DIALOG.CANCEL.CONFIRM' | translate}}
      </button>
    </div>
  </div>
</div>
