import { DialogPresenterService, DialogData } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import { TableOperationActionType, TableOperationsHelper } from 'app/components/helpers/table-operations.helper';
import { TableOperationsFactory } from 'app/components/table-operations-controller/table-operations-controller';
import { CreateKilometersDialog } from './dialog/create/create-kilometers-dialog.component';
import { EditKilometersDialog } from './dialog/edit/edit-kilometers-dialog.component';

export class KilometersTableOperationsFactory implements TableOperationsFactory {

    constructor(
        private dialogPresenter: DialogPresenterService,
        private completion,
    ) { }

    makeOperations(selected) {
        return this.makeOperationsWithSelected(selected);
    }

    private makeOperationsWithSelected(selected) {
        return [
            TableOperationsHelper.makeOperationWithType(
                TableOperationActionType.Add,
                this.dialogPresenter,
                () => this.makeCreateDialogData(),
                selected,
                this.completion
            ),
            TableOperationsHelper.makeOperationWithType(
                TableOperationActionType.Edit,
                this.dialogPresenter,
                () => this.makeEditDialogData(),
                selected,
                this.completion
            ),
            TableOperationsHelper.makeRemoveOperation(
                () => this.remove(selected),
                selected
            )
        ]
    }

    private makeCreateDialogData(): DialogData {
        return {
            dialog: CreateKilometersDialog,
            panel: 'create-kilometers-dialog-container',
            data: {}
        }
    }

    private makeEditDialogData(): DialogData {
        return {
            dialog: EditKilometersDialog,
            panel: 'edit-kilometers-dialog-container',
            data: {}
        }
    }

    private remove(selected) {
        this.dialogPresenter.askRemoveItems(selected, this.completion, true);
    }
}