
export const locale = {
  'DIALOG': {
    'SUPPLIER': {
      'TITLE': {
        'CREATE': 'Create supplier',
        'EDIT': 'Edit supplier',
        'POINTS': 'Loading points',
        'AGREEMENTS': 'Agreements',
        'BRANDS_LIST': 'Brand management',
        'BRANDS_LIST_ADD': 'Add brands',
      },
      'CONFIRMBUTTON': {
        'CREATE': 'Create',
        'SAVE': 'Save',
        'CLOSE': 'Close',
        'ADD': 'Add',
      },
      'ERROR': {
        'ITN_LENGTH': 'Must be 12 digits',
        'ITN_ONLY_NUMBERS': 'ITN may contain only numbers',
        'MUST_BE_FILLED': 'Must be filled',
        'ZERO_RESULTS': "Can't get coordinate for address '{{value}}'. Verify that the address is correct.",
      },
      'HINT': {
        'ITN': '12 digits',
      },
      'NAME': 'Name',
      'ITN': 'ITN',
      'AGREEMENT': 'Agreement',
      'ADDRESS': 'Address',
      'DEFAULT_POINT': 'Entrance 1',
      'BRAND_NAME': 'Brand name',
      'BRAND_TYPE': 'Brand type',
      'TYPE_FILTER_ALL': 'All',
    },
    'TRANSFER_POINT': {
      'TITLE': {
        'CREATE': 'Create transfer point',
        'EDIT': 'Edit transfer point',
      },
    },
  },
};
