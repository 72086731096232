export const locale = {
  'DIALOG' : {
    'CREATEMODEL' : {
      'TITLE' : {
        'CREATE' : 'Создать модель',
        'EDIT' : 'Изменить модель',
      },
      'CONFIRMBUTTON' : {
        'CREATE' : 'Создать',
        'SAVE' : 'Сохранить',
      },
      'NAME' : 'Название модели',
      'BRAND' : 'Выберите марку',
    },
  },
};
