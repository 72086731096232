<dialog-header headerClass="primary" 
               title="{{'USER.SETTINGS.TITLE' | translate}}"
               hideCloseButton="true">
</dialog-header>

<mat-list *ngIf="settings">
    <mat-list-item>
        <mat-slide-toggle fxFlex 
                          [(ngModel)]="settings.notifications"
                          (change)="save()"
                          labelPosition="before">
            <h3>{{'USER.SETTINGS.NOTIFICATIONS' | translate}}</h3>
        </mat-slide-toggle>
    </mat-list-item>

    <mat-list-item>
        <mat-slide-toggle fxFlex 
                          [(ngModel)]="settings.displayDeleted"
                          (change)="save()"
                          labelPosition="before">
                          <h3>{{'USER.SETTINGS.DISPLAY_DELETED' | translate}}</h3>
        </mat-slide-toggle>
    </mat-list-item>
</mat-list>
