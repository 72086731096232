export const locale = {
  'PASSES': {
    'TYPE': {
      '0': 'СК',
      '1': 'ТТК',
      '2': 'МКАД',
      'INVALID': "Неизвестная область",
    },
    'TIME_OF_ACTION': {
      'DAY': "Дневной",
      'NIGHT': "Ночной",
      'INVALID': "Неверное время действия",
    },
    'INVALID': 'ануллирован',
    'INTERVAL': {
      'FORMAT': 'с {{from}} по {{to}}',
      'INVALID': 'Неверные даты',
    },
    'STATE': {
      'VALID': 'Действителен',
      'INVALID': 'Недействителен',
      'CANCELED': 'Отменен',
      'NOT_VALID_NOW': 'Пока не действителен',
    },
  },
}
