export const locale = {
    'MAIN': {
        'TITLE' : 'Home',
        'TAB' : {
          'UNITS' : 'Units',
          'ORDERS' : 'Orders in progress',
        },
        'WIDGET' : {
          'PROBLEMTRIPS' : 'Problem vehicles',
          'READYTRANSPORTUNITS' : 'Ready to work',
          'UNDISTRIBUTEDORDERS' : 'Undistributed',
        },
    },
    'ORDER': {
      'DIALOG' : {
        'CANCELCONFIRM' : {
          'TITLE' : 'Cancel order',
          'MESSAGE' : 'Are you sure you want to cancel this orders?',
          'CONFIRM' : 'Yes, cancel',
          'DECLINE' : 'Close',
          'ORDERNUMBER' : 'Order #',
        },
      },
    },
};
