import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { PFQuery } from 'app/components/data/core/models/base/query';
import { PFObject } from 'app/components/data/core/models/base/object';
import { order_create_dialog } from './locale/locale';

import { DataLoader } from 'app/components/data/data-loader';
import * as FormHelper from 'app/components/form-helper/form-helper';
import { Order, OrderStatus } from 'app/components/data/core/models/order/order';
import { ArticleBrand, ArticlesFilter, ArticleShape, ArticleType } from 'app/components/data/core/models/articles/article';
import { UnloadingPoint } from 'app/components/data/core/models/points/points';
import { DateHelper } from 'app/components/helpers/date.helper';
import { LocaleService } from 'app/components/locale/locale.service';

import { QueryResultFilter } from 'app/components/access/queryResultFilter';
import { CurrentUser } from 'app/components/data/core/models/base/user';
import { CustomerCloudExecutor } from 'app/components/data/cloud-executors/customer-cloud-executor';
import { DialogPresenterService } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import { UnloadingPointDialog } from 'app/main/admin/users/dialogs/customer/edit-address/unloading-points/unloading-points-dialog.component';
import { Customer } from 'app/components/data/core/models/persons/customer';
import { LocalNotificationService } from 'app/components/local-notifications-service/local-notifications-service';
import { TranslateService } from '@ngx-translate/core';
import { PFDeletableObject } from 'app/components/data/core/models/base/deletableObject';

@Component({
  selector: 'customer-order-dialog',
  templateUrl: './customer-order-dialog.component.html',
  styleUrls: ['./customer-order-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CustomerOrderDialog {
  compareFunction = PFObject.compareFn;

  public form: UntypedFormGroup;
  public title: string;
  public buttonName: string;

  public executor = new CustomerCloudExecutor();

  private customer: Customer;
  private brands: ArticleBrand[] = [];
  private shapes: ArticleShape[] = [];

  availableBrands: ArticleBrand[];
  availableTypes: ArticleType[];

  availableUnloadingPoints: UnloadingPoint[];

  private order: Order;

  private get selectedBrand(): ArticleBrand {
    return this.form.controls.articleBrand.value;
  }
  private set selectedBrand(value: ArticleBrand) {
    this.form.controls.articleBrand.setValue(value);
  }

  private get selectedType(): ArticleType {
    return this.form.controls.articleType.value;
  }
  private set selectedType(value: ArticleType) {
    this.form.controls.articleType.setValue(value);
  }

  private get selectedUnloadingPoint(): UnloadingPoint {
    return this.form.controls.unloadingPoint.value;
  }
  private set selectedUnloadingPoint(value: UnloadingPoint) {
    this.form.controls.unloadingPoint.setValue(value);
  }

  private get unloadingDate(): Date {
    return this.form.controls.unloadingDate.value;
  }

  private get unloadingTimeFrom(): number {
    return this.form.controls.unloadingTimeFrom.value;
  }

  private get unloadingTimeTo(): number {
    return this.form.controls.unloadingTimeTo.value;
  }

  private set unloadingTimeTo(value: number) {
    this.form.controls.unloadingTimeTo.setValue(value);
  }

  private get comment(): string {
    return this.form.controls.comment.value;
  };

  private get tonnage(): number {
    return this.form.controls.tonnage.value;
  };

  private set tonnage(value: number) {
    this.form.controls.tonnage.setValue(value);
  };

  constructor(
    private dialogPresenter: DialogPresenterService,
    private dialogRef: MatDialogRef<CustomerOrderDialog>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private localeService: LocaleService,
    private _formBuilder: UntypedFormBuilder,
    private notificationService: LocalNotificationService,
    private translate: TranslateService,
  ) {
    this.localeService.loadLocale(order_create_dialog);

    if (this._data.action === 'create') {
      if (this._data.object) {
        this.order = this._data.object;
      } else {
        this.order = new Order();
      }
      this.order.setStatus(OrderStatus.CUSTOMER_REQUEST);
      this.title = 'CUSTOMER_ORDER.DIALOG.CREATE.TITLE.CREATE';
      this.buttonName = 'CUSTOMER_ORDER.DIALOG.CREATE.CONFIRMBUTTON.CREATE';
    } else if (this._data.action === 'edit') {
      this.order = this._data.object;
      this.title = 'CUSTOMER_ORDER.DIALOG.CREATE.TITLE.EDIT';
      this.buttonName = 'CUSTOMER_ORDER.DIALOG.CREATE.CONFIRMBUTTON.SAVE';
    }

    this.form = this.createContactForm();
    this.loadData();
  }

  typeChanged() {
    this.updateAvailableBrandsFor(this.selectedType);
    this.updateSelectedBrand();
  }

  addUnloadingPoint() {
    let dialogData = {
      dialog: UnloadingPointDialog,
      panel: 'unloading-points-dialog',
      data: { action: 'create' }
    };
    this.dialogPresenter.showDialog(dialogData,
      point => {
        this.customer.addUnloadingPoint(point);
        this.customer.save().then(() => {
          this.updateAvailableUnloadingPoints();
          this.selectedUnloadingPoint = point;
        });
      }
    );
  }

  checkAndSave() {
    if (this.checkForm()) {
      this.order.setArticleBrand(this.selectedBrand);
      this.order.setArticleShape(this.shapes[0]);

      if (this.unloadingTimeFrom) {
        let unloadingTimeFrom = DateHelper.setDayBegin(new Date(this.unloadingDate));
        unloadingTimeFrom.setHours(this.unloadingTimeFrom);
        this.order.setUnloadingBeginDate(unloadingTimeFrom);
      }

      if (this.unloadingTimeTo) {
        let unloadingTimeTo = DateHelper.setDayBegin(new Date(this.unloadingDate));
        unloadingTimeTo.setHours(this.unloadingTimeTo);
        this.order.setUnloadingEndDate(unloadingTimeTo);
      }

      this.order.setTonnage(this.tonnage);
      this.order.setCustomer(this.customer);
      this.order.setUnloadingPoint(this.selectedUnloadingPoint);
      let entrances = this.selectedUnloadingPoint.getEntrances();
      if (entrances && entrances.length > 0) {
        this.order.setUnloadingEntrance(entrances[0]);
      }
      this.order.setComment(this.comment);

      if (this._data.action === 'edit') {
        this.executor.saveObject(this.order).then(() => {
          this.dialogRef.close(true);
        }).catch(() => {
          let localizedMessage = this.translate.instant('CUSTOMER_ORDER.DIALOG.CREATE.ERROR.SAVE_ERROR')
          this.notificationService.showErrorNotification(localizedMessage);
        });
      } else {
        this.executor.makeOrder(this.order)
          .then(() => {
            this.dialogRef.close(true);
          })
          .catch((error) => {
            let localizedMessage = this.translate.instant('CUSTOMER_ORDER.DIALOG.CREATE.ERROR.' + error)
            this.notificationService.showErrorNotification(localizedMessage);
          })
      }
    }
  }

  private async loadData() {
    let brandsLoader = this.makeBrandsLoader();
    let shapesLoader = new DataLoader('ArticleShape');

    this.customer = await CurrentUser.customer().fetchUnloadingPoints();
    this.brands = await brandsLoader.loadAll();
    this.shapes = await shapesLoader.loadAll();
    this.updateAvailableTypes();
    this.updateSelectedType();
    this.updateAvailableUnloadingPoints();
    this.updateSelectedUnloadingPoint();
  }

  private makeBrandsLoader() {
    let loader = new DataLoader('ArticleBrand');
    loader.setQueryModificationBlock((query: PFQuery) => {
      query.include('type');
      return query;
    });
    return loader;
  }

  private createContactForm(): UntypedFormGroup {
    let brand = this.order.articleBrand();
    let type;
    if (brand) {
      type = brand.type();
    }

    let unloadingBeginDate = this.order.unloadingBeginDate();
    let unloadingEndDate = this.order.unloadingEndDate();
    let unloadingTimeFrom;
    if (unloadingBeginDate) {
      unloadingTimeFrom = unloadingBeginDate.getHours();
    }
    let unloadingTimeTo;
    if (unloadingEndDate) {
      unloadingTimeTo = unloadingEndDate.getHours();
    }
    let tonnage = this.order.tonnage();

    return this._formBuilder.group({
      articleType: [type, Validators.required],
      articleBrand: [brand, Validators.required],
      unloadingDate: [unloadingBeginDate || DateHelper.nextDay(new Date()), Validators.required],
      unloadingTimeFrom: [unloadingTimeFrom, [Validators.min(0), Validators.max(23)]],
      unloadingTimeTo: [unloadingTimeTo, [Validators.min(0), Validators.max(23)]],
      tonnage: [tonnage, Validators.min(0)],
      unloadingPoint: this.order.unloadingPoint(),
      comment: this.order.comment(),
    });
  }

  private updateSelectedUnloadingPoint() {
    let defaultValue = this.order.unloadingPoint();
    this.selectedUnloadingPoint = PFObject.priorValue(this.availableUnloadingPoints, defaultValue);
  }

  private updateAvailableTypes() {
    let brands = QueryResultFilter.filterDeleted(this.brands);
    this.availableTypes = ArticlesFilter.collectBrandsTypes(brands);
  }

  private updateSelectedType() {
    let defaultValue;
    let brand = this.order.articleBrand();
    if (brand) {
      defaultValue = brand.type();
    }
    defaultValue = PFObject.priorValue(this.availableTypes, defaultValue);
    if (this.selectedType != defaultValue) {
      this.selectedType = defaultValue;
      this.typeChanged();
    }
  }

  private updateAvailableBrandsFor(type: ArticleType) {
    if (!type) return;
    let brands = QueryResultFilter.filterDeleted(this.brands);
    this.availableBrands = ArticlesFilter.filterBrandsWithType(brands, type);
  }

  private updateSelectedBrand() {
    let brands = this.availableBrands;
    let defaultValue = this.order.articleBrand();
    if (!PFObject.objectsContainsObject(brands, defaultValue)) {
      if (brands && brands.length > 0) {
        defaultValue = brands[0];
      }
    }
    this.selectedBrand = defaultValue;
  }

  private updateAvailableUnloadingPoints() {
    this.availableUnloadingPoints = PFDeletableObject.filterDeleted(this.customer.getUnloadingPoints());
  }

  private checkForm(): boolean {
    this.checkInterval();
    return FormHelper.checkForm(this.form);
  }

  private checkInterval() {
    let error = null;
    if (this.unloadingTimeTo - this.unloadingTimeFrom < 0) {
      error = { invalidInterval: true }
    }
    this.form.controls.unloadingTimeTo.setErrors(error);
  }
}
