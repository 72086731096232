import { DataLoader } from 'app/components/data/data-loader';
import { PFQuery } from 'app/components/data/core/models/base/query';
import { PFObject } from 'app/components/data/core/models/base/object';
import { Order } from 'app/components/data/core/models/order/order';
import { LiveQueryModifier, LiveQuerySubscriber } from 'app/components/table/data_sources/live-query-subscriber';
import { BehaviorSubject } from 'rxjs';
import * as AccessQueries from 'app/components/access/query';

let proposalFields = [
    'carriers',
    'loadingPoint',
    'unloadingPoint',
    'supplier.loadingPoints',
    'customer.unloadingPoints',
    'articleBrand.type',
    'offers.transportUnit.driver',
    'offers.trip.historyRecords',
    'carrierOffers.driver',
    'historyRecords.user.manager',
    'historyRecords.user.logistician',
    'historyRecords.user.dispatcher',
];

export class ProposalsLoader {

    items = new BehaviorSubject<Order[]>(null);

    private loader;
    private subscriber: LiveQuerySubscriber;

    constructor() {
        this.loader = new DataLoader('Order');
        this.loader.delayBeforeFinish = 500;
        this.subscriber = new LiveQuerySubscriber('Order', proposalFields);
    }

    setModifyBlock(modify: (query: PFQuery) => PFQuery) {
        let block = (query: PFQuery) => {
            query.includes(proposalFields);
            query = AccessQueries.filterDeleted(query);
            query.ascending("customer");
            return modify(query);
        }
        this.loader.setQueryModificationBlock(block);
        this.subscriber.liveQueryModifier = new LiveQueryModifier(block, null);
    }

    async reloadData() {
        this.items.next([]);
        this.subscriber.unsubscribe();
        let orders = await this.loader.loadAll();
        this.items.next(orders);
        this.subscriber.subscribe((oldObject, newObject) => {
            if (PFObject.compareFn(oldObject, newObject)) {
                this.refresh();
            } else {
                let orders = this.items.getValue();
                if (oldObject != null) {
                    orders = this.removeObject(orders, oldObject);
                }
                if (newObject != null) {
                    orders = this.addObject(orders, newObject);
                }
                this.commitChanges(orders);
            }
        });
    }

    isLoading() {
        return this.loader.isLoading();
    }

    private refresh() {
        this.items.next(this.items.getValue());
    }

    private removeObject(fromOrders: Order[], order: Order) {
        let index = fromOrders.findIndex(o => {
            return PFObject.compareFn(o, order);
        });
        if (index != -1) {
            fromOrders.splice(index, 1);
        }
        return fromOrders;
    }

    private addObject(toOrders: Order[], order: Order) {
        let index = toOrders.findIndex(o => {
            return PFObject.compareFn(o, order);
        });
        if (index == -1) {
            toOrders.push(order);
        }
        return toOrders;
    }

    private commitChanges(orders: Order[]) {
        this.items.next(orders);
    }
}