import { UntypedFormGroup } from '@angular/forms';

export function getControl(form: UntypedFormGroup, controlName: string) {
  return form.get(controlName);
}

export function resetControl(form: UntypedFormGroup, controlName: string) {
  let control = getControl(form,controlName);
  control.setValue(null);
}

export function setControlValue(form: UntypedFormGroup, controlName: string, value: any) {
  let control = getControl(form,controlName);
  control.setValue(value);
}

export function checkForm(form: UntypedFormGroup): boolean {
  if (form.valid) {
    return true;
  }

  var invalid = [];
  Object.keys(form.controls).forEach(field => {
    let control = getControl(form, field);
    if (control.invalid) {
      invalid.push(field);
    }
    control.markAsTouched({ onlySelf: true });
  });
  console.log('Invalid form controls: ' + invalid);
  return false;
}
