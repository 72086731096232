import {Component, ViewChild, ElementRef, Inject, OnInit, AfterViewInit} from '@angular/core';
import { DateRange, DefaultMatCalendarRangeStrategy, MatCalendar, MatRangeDateSelectionModel } from '@angular/material/datepicker';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-custom-date-range-picker',
    templateUrl: './custom-date-range-picker.component.html',
    styleUrls: ['./custom-date-range-picker.component.scss'],
    providers: [DefaultMatCalendarRangeStrategy, MatRangeDateSelectionModel],
})
export class CustomDateRangePickerComponent implements OnInit, AfterViewInit{
    @ViewChild('calendar') calendar: MatCalendar<Date> | undefined;
    @ViewChild('calendar', { read: ElementRef }) calendarEl!: ElementRef;

    selectedDateRange: DateRange<Date> | undefined;
    activeDate: Date = new Date(); // Дата, на которой открыт календарь
    previewEndDate: Date | null = null;
    isAcceptDisabled = true;

    constructor(
        private readonly selectionModel: MatRangeDateSelectionModel<Date>,
        private readonly selectionStrategy: DefaultMatCalendarRangeStrategy<Date>,
        public dialogRef: MatDialogRef<CustomDateRangePickerComponent>,
        @Inject(MAT_DIALOG_DATA) public data?: {
            elementRef: HTMLElement;
            selectedDates?: Date[];
        },
    ) {
        this.dialogRef.backdropClick().subscribe(() => {
            this.dialogRef.close([this.selectedDateRange.start, this.selectedDateRange.end]); // Закрываем модальное окно без передачи данных
        });
    }

    ngOnInit() {
        if (this.data?.elementRef) {
            const rect = this.data.elementRef.getBoundingClientRect();
            const dialogElement = document.querySelector('.mat-dialog-container') as HTMLElement;

            if (dialogElement) {
                dialogElement.style.position = 'absolute';
                let leftPosition = rect.left < window.innerWidth / 2 ? rect.left : rect.left - 360;

                dialogElement.style.top = `${rect.bottom}px`;
                dialogElement.style.left = `${leftPosition}px`;
                dialogElement.style.padding = `0px`;
                dialogElement.style.width = `auto`;
                dialogElement.style.height = `auto`;
                dialogElement.style.background = 'none';
                dialogElement.style.boxShadow = 'none';
            }
        }

        // Устанавливаем переданный диапазон дат, если он есть
        if (this.data?.selectedDates?.length === 2) {
            this.selectedDateRange = new DateRange<Date>(this.data.selectedDates[0], this.data.selectedDates[1]);
            this.selectionModel.updateSelection(this.selectedDateRange, this);
            this.isAcceptDisabled = false; // Делаем кнопку "ACCEPT" активной

            // Устанавливаем начальную дату как `activeDate`
            this.activeDate = this.selectedDateRange.start!;
        }
    }

    ngAfterViewInit() {
        if (this.calendar && this.selectedDateRange?.start) {
            this.calendar.activeDate = this.selectedDateRange.start; // Устанавливаем начальный месяц
            this.calendar.updateTodaysDate(); // Принудительно обновляем отображение
        }
    }

    rangeChanged(selectedDate: Date) {
        console.log(selectedDate, selectedDate);
        const selection = this.selectionModel.selection;
        let newSelection = this.selectionStrategy.selectionFinished(selectedDate, selection);

        this.selectionModel.updateSelection(newSelection, this);
        this.selectedDateRange = new DateRange<Date>(newSelection.start, newSelection.end);
        this.isAcceptDisabled = !this.selectedDateRange.start; // Активируем кнопку при выборе начальной даты
        this.previewEndDate = null; // Сбрасываем предпросмотр после выбора второй даты
    }

    resetSelection() {
        this.selectedDateRange = undefined;
        this.selectionModel.updateSelection(new DateRange<Date>(null, null), this);
        this.isAcceptDisabled = true; // Делаем кнопку "ACCEPT" неактивной
        this.calendar?.updateTodaysDate(); // Обновляем календарь
        this.dialogRef.close(undefined); // Передаем выбранный диапазон
    }

    acceptSelection() {
        if (this.selectedDateRange) {
            this.dialogRef.close([this.selectedDateRange.start, this.selectedDateRange.end]); // Передаем выбранный диапазон
        }
    }
}
