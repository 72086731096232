<div fxLayout="column" fxLayoutAlign="center start">
  <div class="attachment-zone" fxFlex>
      <ngx-file-drop #dropzone
                    (onFileDrop)="dropped($event)"
                    (click)="openFileBrowser($event)"
                    accept="image/*"
                    [disabled]="files.length >= maxFiles || !editingEnabled"
                    dropZoneClassName="attachment-drop-zone"
                    contentClassName="attachment-content">
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
          <div *ngIf="!files.length" class="placeholder" fxLayout="row" fxLayoutAlign="center center">
            <mat-label *ngIf="editingEnabled" class="h4 silver">{{'ATTACHMENT.DROP_LABEL' | translate}}</mat-label>
            <mat-label *ngIf="!editingEnabled" class="h4 silver">{{'ATTACHMENT.DROP_LABEL_DISABLED' | translate}}</mat-label>
          </div>

          <div class="upload-list p-8" *ngIf="files.length > 0" fxLayout="row" fxLayoutAlign="start center">
            <div *ngFor="let file of files" class='attachment' fxLayout="column" fxLayoutAlign="center center" (click)="$event.stopPropagation()">
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-label style="font-size: 10px;">{{ file.name | slice:0 :15 }}</mat-label>
                <button *ngIf="editingEnabled"
                        type="button"
                        class="attachment-remove ml-4"
                        mat-icon-button
                        (click)="removeFile(file); $event.stopPropagation()">
                    <mat-icon>delete</mat-icon>
                </button>
              </div>
              <img src='{{file.content}}' class='attachment-image' (click)=openFile(file)>
            </div>
          </div>
        </ng-template>
      </ngx-file-drop>
  </div>
  <div *ngIf="files.length > 0 || editingEnabled" fxLayout="row" fxLayoutAlign="end start" fxFill>
    <mat-label class="silver">{{files.length}}/{{maxFiles}}</mat-label>
  </div>
</div>
