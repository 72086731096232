import { ReportBuilder } from "./report-builder";
import { PFQuery } from 'app/components/data/core/models/base/query';
import { Order, getOrderAuthorName, Type } from 'app/components/data/core/models/order/order';
import { Trip, TripHistoryRecord, TripStage } from 'app/components/data/core/models/trip/trip';

import { XLSXBuilder } from 'app/components/excel-export/XLSXBuilder';

import { TranslateService } from '@ngx-translate/core';
import { Driver } from 'app/components/data/core/models/persons/driver';
import { Carrier } from 'app/components/data/core/models/persons/carrier';

export class DefaultReportBuilder implements ReportBuilder {
    constructor(
        private translate: TranslateService, 
        private queryModifier: (query: PFQuery)=> PFQuery = null
        ) { }

    fileType(): string {
        return XLSXBuilder.fileType;
    }
    
    requestData(beginDate: Date, endDate: Date): Promise<any> {
        let query = new PFQuery('Trip');

        query.includes([
            "order.intermediary",
            "order.supplier",
            "order.customer",
            "order.author.manager",
            "order.author.dispatcher",
            "order.author.customer",
            "order.articleBrand",
            "order.loadingPoint",
            "order.unloadingPoint",
            "order.carriers",
            "transportUnit.driver.carrier",
            "transportUnit.vehicle",
            "transportUnit.trailer",
            "historyRecords.additionalData",
        ]);
        
        query.equalTo("stage", TripStage.Unloaded);
        query.ascending("stageChangeDate");

        if (this.queryModifier) {
            query = this.queryModifier(query);
        }
        
        query.greaterThan("stageChangeDate", beginDate);
        query.lessThan("createdAt", endDate);

        console.log("Begin collecting data for report");
        return query.findAll().then(result => {
            if (result && result.length > 0) {

                let filtered = result.filter((trip: Trip) => {
                    return trip.order().isDeleted() == false
                })
                return Promise.resolve(filtered)
            }
        });
    }

    makeFields() {
        return [
            { key: "number", header: this.translate.instant("ExcelExport.Fields.Number"), width: 8 },
            { key: "salePriceType", header: this.translate.instant("ExcelExport.Fields.SalePriceType"), width: 10 },
            { key: "saleTariff", header: this.translate.instant("ExcelExport.Fields.SaleTariff"), width: 10 },
            { key: "deliveryPriceType", header: this.translate.instant("ExcelExport.Fields.DeliveryPriceType"), width: 10 },
            { key: "deliveryTariff", header: this.translate.instant("ExcelExport.Fields.DeliveryTariff"), width: 10 },
            { key: "supplier", header: this.translate.instant("ExcelExport.Fields.Supplier"), width: 20 },
            { key: "loadingDate", header: this.translate.instant("ExcelExport.Fields.LoadingDate"), width: 15 },
            { key: "loadingPoint", header: this.translate.instant("ExcelExport.Fields.LoadingPoint"), width: 20 },
            { key: "loadingBrand", header: this.translate.instant("ExcelExport.Fields.LoadingBrand"), width: 15 },
            { key: "loadingTonnage", header: this.translate.instant("ExcelExport.Fields.LoadingTonnage"), width: 15 },
            { key: "unloadingDate", header: this.translate.instant("ExcelExport.Fields.UnloadingDate"), width: 15 },
            { key: "customer", header: this.translate.instant("ExcelExport.Fields.Customer"), width: 20 },
            { key: "unloadingPoint", header: this.translate.instant("ExcelExport.Fields.UnloadingPoint"), width: 20 },
            { key: "unloadingBrand", header: this.translate.instant("ExcelExport.Fields.UnloadingBrand"), width: 15 },
            { key: "unloadingTonnage", header: this.translate.instant("ExcelExport.Fields.UnloadingTonnage"), width: 15 },
            { key: "intermediary", header: this.translate.instant("ExcelExport.Fields.Intermediary"), width: 20 },
            { key: "author", header: this.translate.instant("ExcelExport.Fields.Author"), width: 20 },
            { key: "carrier", header: this.translate.instant("ExcelExport.Fields.Carrier"), width: 20 },
            { key: "driver", header: this.translate.instant("ExcelExport.Fields.Driver"), width: 20 },
            { key: "vehicleNumber", header: this.translate.instant("ExcelExport.Fields.VehicleNumber"), width: 13 },
            { key: "trailerNumber", header: this.translate.instant("ExcelExport.Fields.TrailerNumber"), width: 13 },
            { key: "kilometers", header: this.translate.instant("ExcelExport.Fields.Kilometers"), width: 10 },
            { key: "waybillNumber", header: this.translate.instant("ExcelExport.Fields.WaybillNumber"), width: 13 },
            { key: "sum", header: this.translate.instant("ExcelExport.Fields.Sum"), width: 10 }
        ];
    }

    build(title: string, data: any) {
        let fields = this.makeFields();
        let builder = new XLSXBuilder(fields);
        builder.addHeader();
        let trips = data as Trip[];
        trips.forEach((trip) => {
            builder.addRow(this.makeRowForTrip(trip));
        });
        return builder.write(title);
    }

    private makeRowForTrip(trip: Trip) {
        let order: Order = trip.order();
        let transportUnit = trip.transportUnit();
        let tonnage = trip.tonnage();

        let authorName = getOrderAuthorName(order);

        let loadingDate = order.loadingDate();
        let unloadingDate = order.unloadingBeginDate();

        let loadingTonnage = tonnage;
        let unloadingTonnage = tonnage;

        let loadingHistoryRecord: TripHistoryRecord;
        let unloadingHistoryRecord: TripHistoryRecord;
        
        let kilometers = "-";
        let waybillNumber = "-";

        let historyRecords = trip.historyRecords();
        if (historyRecords) {
            loadingHistoryRecord = historyRecords.find(historyRecord => historyRecord.stage() === TripStage.Loaded);
            unloadingHistoryRecord = historyRecords.find(historyRecord => historyRecord.stage() === TripStage.Unloaded);
        }

        if (loadingHistoryRecord) {
            if (loadingHistoryRecord.date()) {
                loadingDate = loadingHistoryRecord.date();
            }

            if (loadingHistoryRecord.tonnage()) {
                loadingTonnage = loadingHistoryRecord.tonnage();
            }
            let additionalData = loadingHistoryRecord.additionalData();
            if (order.getType() == Type.Carriage && additionalData) {
                if (additionalData.kilometers) {
                    kilometers = `${additionalData.kilometers}`;
                }
                if (additionalData.waybillNumber) {
                    waybillNumber = additionalData.waybillNumber;
                }
            }
        }
        if (unloadingHistoryRecord) {
            if (unloadingHistoryRecord.date()) {
                unloadingDate = unloadingHistoryRecord.date();
            }

            if (unloadingHistoryRecord.tonnage()) {
                unloadingTonnage = unloadingHistoryRecord.tonnage();
            }
        }

        let loadingPoint = order.loadingPoint();
        let unloadingPoint = order.unloadingPoint();

        let driver: Driver = transportUnit.driver();
        let carrier: Carrier = driver.getCarrier();
        let vehicleNumber = transportUnit.vehicle().number();
        let trailerNumber = transportUnit.trailer() ? transportUnit.trailer().number() : '';

        let supplierName = "";
        let supplier = order.supplier();
        if (supplier) {
            supplierName = supplier.getName();
        }

        let salePriceType = order.salePriceType() === 0 ?
            this.translate.instant('ExcelExport.PriceType.OneTime') :
            this.translate.instant('ExcelExport.PriceType.NotOneTime');

        let deliveryPriceType = order.deliveryPriceType() === 0 ?
            this.translate.instant('ExcelExport.PriceType.OneTime') :
            this.translate.instant('ExcelExport.PriceType.NotOneTime');

        return {
            number: order.number(),
            salePriceType: salePriceType,
            saleTariff: order.saleTariff() ? order.saleTariff() : null,
            deliveryPriceType: deliveryPriceType,
            deliveryTariff: order.deliveryTariff() ? order.deliveryTariff() : null,
            supplier: supplierName,
            loadingDate: loadingDate,
            loadingPoint: loadingPoint ? loadingPoint.getAddress() : "",
            loadingBrand: order.articleBrand().name(),
            loadingTonnage: loadingTonnage,
            unloadingDate: unloadingDate,
            customer: order.customer().getName(),
            unloadingPoint: unloadingPoint ? unloadingPoint.getAddress() : "",
            unloadingBrand: order.articleBrand().name(),
            unloadingTonnage: unloadingTonnage,
            intermediary: order.intermediary().getName(),
            author: authorName,
            carrier: carrier ? carrier.getName() : "",
            driver: driver.getName(),
            vehicleNumber: vehicleNumber,
            trailerNumber: trailerNumber,
            kilometers: kilometers,
            waybillNumber: waybillNumber,
            sum: this.getSum(order, unloadingTonnage),
        }
    }

    private getSum(order: Order, tonnage: number): number {
        if (!order.saleTariff() && !order.deliveryTariff()) {
            return null;
        }
        var sum = 0;
        if (order.saleTariff()) {
            sum += order.saleTariff() * tonnage;
        }
        if (order.deliveryTariff()) {
            sum += order.deliveryTariff() * tonnage;
        }
        return sum;
    }
}