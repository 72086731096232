import { Component, ViewChild } from '@angular/core';

import { LocaleService } from 'app/components/locale/locale.service';
import { proposals } from '../../locale/locale';
import { default_row_operations, common, equipment, orders } from '../../../locale/locale';
import { ProposalsFromCustomersTableData } from './proposals-list-from-customers.tabledata';
import { DateSelectorComponent } from 'app/components/date-selector/date-selector.component';
import { TableComponent } from 'app/components/table/table.component';
import { TableOperationsController } from 'app/components/table-operations-controller/table-operations-controller';
import { DateHelper } from 'app/components/helpers/date.helper';
import { TranslateService } from '@ngx-translate/core';

const locales = [
  proposals,
  common,
  default_row_operations,
  equipment,
  orders,
];

@Component({
  selector: 'proposals-list-from-customers',
  templateUrl: './proposals-list-from-customers.component.html',
  styleUrls: ['./proposals-list-from-customers.component.scss']
})
export class ProposalsListFromCustomersComponent {
  tableData: ProposalsFromCustomersTableData;
  operationsController: TableOperationsController;

  initialDate = DateHelper.nextDay(DateHelper.setDayBegin(new Date()));
  
  constructor(
    private localeService: LocaleService,
    private translate: TranslateService,
  ) {
    this.localeService.loadLocales(locales);
    this.tableData = new ProposalsFromCustomersTableData(this.initialDate, this.translate);
  }

  @ViewChild(DateSelectorComponent) private dateSelector: DateSelectorComponent;
  @ViewChild(TableComponent) private table: TableComponent;

  search(searchString: string) {
     this.table.search(searchString);
  }

  reloadOrders() {
    this.tableData = new ProposalsFromCustomersTableData(this.dateSelector.selectedDate, this.translate);
  }
  
}

