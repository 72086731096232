export const locale = {
  'CREATEBRAND': {
    'TITLE': {
      'CREATE': 'Create brand',
      'EDIT': 'Edit brand',
    },
    'CONFIRMBUTTON': {
      'CREATE': 'Create',
      'SAVE': 'Save',
    },
    'NAME': 'Brand name',
    'TYPE': 'Select type',
    'TONNAGE_PER_TRUCK': 'Tonnage per truck',
  },
};
