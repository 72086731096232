export const locale = {
  'ORDER': {
    'FIELD_NAME': {
      'INTERMEDIARY': 'Дилер',
      'CUSTOMER': 'Покупатель',
      'CHECK_CUSTOMER': 'Проверить покупателя',
      'UNLOADING_POINT': 'Адрес разгрузки',
      'UNLOADING_ENTRANCE': 'Точка разгрузки',
      'UNLOADING_DATE': 'Дата разгрузки',
      'UNLOADING_TIME_FROM': 'Время c',
      'UNLOADING_TIME_TO': 'до',
      'LOAD_TYPE': 'Тип загрузки',
      'TRANSFER_POINT': 'Склад',
      'LOADING_TIME': 'Время',
      'SUPPLIER': 'Поставщик',
      'AGREEMENT': 'Соглашение',
      'LOADING_POINT': 'Адрес загрузки',
      'LOADING_ENTRANCE': 'Точка загрузки',
      'LOADING_DATE': 'Дата загрузки',
      'ARTICLE_TYPE': 'Вид товара',
      'ARTICLE_BRAND': 'Марка товара',
      'ARTICLE_SHAPE': 'Форма товара',
      'TONNAGE': 'Тоннаж',
      'SALE_PRICE': 'Цена продажи',
      'DELIVERY_PRICE': 'Цена доставки',
      'DELIVERY_PRICE_TYPE': 'Тип цены доставки',
      'SALE_PRICE_TYPE': 'Тип цены продажи',
      'PRICE_TYPES': {
        'ONCE': 'Разовая',
        'MULTIPLE': 'Не разовая',
      },
      'TIMEOUT': 'Таймаут, час',
      'COMMENT': 'Комментарий',
      'CONTACT_NAME': 'Имя контакта',
      'CONTACT_PHONE': 'Телефон контакта',
      'TYPE': 'Тип заказа',
      'MANAGER_NAME': 'Менеджер',
      'MANAGER_PHONE': 'Телефон менеджера',
      'KILOMETERS': 'Километраж',
      'UNLOADING_CONTACT': 'Контакт на разгрузке',
    },
    'UNDEFINED': 'Не указано',
    'LOAD_TYPE': {
      'SUPPLIER': 'Поставщик',
      'TRANSSHIPMENT': 'Перевалка',
    },
    'TYPE': {
      'DEFAULT': 'Обычный',
      'CARRIAGE': 'Перевозка',
    },
  },
};
