
export const locale = {
  'DIALOG': {
    'UNIT': {
      'CREATE': {
        'TITLE': 'Create',
        'CONFIRMBUTTON': 'Create',
      },
      'CARRIER_SELECT': 'Select carrier',
      'DRIVER_SELECT': 'Select driver',
      'VEHICLE_SELECT': 'Select vehicle',
      'TRAILER_SELECT': 'Select trailer',
      
      'ERROR': {
        'NO_CARRIER': 'Carrier not selected',
        'NO_DRIVER': 'Driver not selected',
        'NO_VEHICLE': 'Vehicle not selected',
        'NO_TRAILER': 'Trailer not selected',
      }
    }
  },
};
