import Parse from 'parse';
import {BehaviorSubject, Observable} from 'rxjs';
import {TableDataSource, FieldFilter, FilterCondition} from '../table.component';
import {LiveQuerySubscriber} from './live-query-subscriber';
import {Sort} from '@angular/material/sort';
import {Directive} from '@angular/core';
import {NewListCloudExecutor} from "../../data/cloud-executors/new-list-executor";
import {PFQuery} from "../../data/core/models/base/query";
import {getOrderAuthorID, getOrderAuthorName} from "../../data/core/models/order/order";
import {PFObject} from "../../data/core/models/base/object";

export type TableDataQueryModify = (query: PFQuery) => PFQuery;
export type TableDataQueryFilter = (filterStr: string, query: PFQuery) => PFQuery;

@Directive()
export class ServerV2TableDataSource {
    data = new BehaviorSubject<Parse.Object[]>([]);
    dataColumns = new BehaviorSubject<Parse.Object[]>([]);
    dataCustomers = new BehaviorSubject<Parse.Object[]>([]);
    dataAuthors = new BehaviorSubject<Parse.Object[]>([]);
    dataSuppliers = new BehaviorSubject<Parse.Object[]>([]);
    private loading: boolean = false;
    public lastLoadError;

    public queryModify: TableDataQueryModify;
    public queryFilter: TableDataQueryFilter;

    private count: number = 0;
    private countDisplay: number = 0;
    private subscribedTabledata;

    private filter: string = '';
    private otherFilters: FieldFilter[];
    private treeNodeFilters: FieldFilter[];

    private subscriber: LiveQuerySubscriber;

    private defaultSort: Sort = null;
    private sort: Sort = null;

    private cloudExecutor = new NewListCloudExecutor();

    constructor(
        private tableName: string,
        private includedFields: string[] = []
    ) {
    }

    ngOnDestroy() {
        this.unsubscribe();
    }

    // DataSource Interface
    connect(): Observable<Parse.Object[]> {
        return this.data.asObservable();
    }

    disconnect(): void {
        this.data.complete();
    }

    // TableDataSource Interface
    hasData(): boolean {
        let value = this.data.getValue();
        return value && value.length > 0;
    }

    totalDataCount(): number {
        return this.count;
    }

    setSearchFilter(value: string) {
        this.filter = value;
    }

    setOtherFilters(filters: FieldFilter[]) {
        this.otherFilters = filters;
    }

    setNodeTreeFilters(filters: FieldFilter[]) {
        this.treeNodeFilters = filters;
    }

    getData() {
        return this.data.getValue();
    }

    refresh() {
        this.data.next(this.getData());
    }

    loadData(offset: number, count: number) {
        this.startLoading();

        const currentSort = this.determineSort();
        const params = this.buildQueryParams(offset, count, currentSort);

        this.executeQuery(params)
            .then(response => this.processResponse(response))
            .catch(error => this.handleError(error));
    }

    private startLoading(): void {
        this.loading = true;
        this.data.next([]);
        this.lastLoadError = null;
    }

    private determineSort(): { active: string, direction: string } {
        return this.sort?.active
            ? { active: this.sort.active, direction: this.sort.direction }
            : { active: this.defaultSort.active, direction: this.defaultSort.direction };
    }

    private getFilter(): string | undefined {
        return this.filter && this.queryFilter ? this.filter : undefined;
    }

    private buildQueryParams(offset: number, count: number, sort: { active: string, direction: string }): any {
        if (count > 0) {
            this.countDisplay = count;
        }

        const queryParams: any = {
            offset,
            count: this.countDisplay,
            filters: this.otherFilters,
            sort,
        };

        const filter = this.getFilter();
        if (filter) {
            queryParams.filter = filter;
        }

        return queryParams;
    }

    private executeQuery(params: { offset: number, count: number } | any = null): Promise<any> {
        return this.cloudExecutor
            .getOrders(params)
            .then(response => response)
            .catch(error => {
                throw error;
            });
    }

    private processResponse(response: any): void {
        const ordersColumns: Parse.Object[] = Array.isArray(response.objects) ? response.objects : [];
        console.log('Sliced results:', ordersColumns);

        const ordersList = this.mapOrders(ordersColumns);

        console.log("ordersList");
        console.log(ordersList);

        this.count = response.count;
        this.data.next(ordersList);
        this.dataColumns.next(ordersColumns);
        this.loading = false;
    }

    private mapOrders(ordersColumns: Parse.Object[]): any[] {
        return ordersColumns
            .map((item) => {
                if (!item.order) return null;
                return Object.assign(item.order, {
                    columnNumber: item.number,
                    columnCustomer: item.customer,
                    columnEquipent: item.equipent,
                    columnComment: item.comment,
                    columnAuthor: item.author,
                    columnStatus: item.status,
                    columnTonnage: item.tonnage,
                    columnUnloadingAdress: item.unloadingAdress,
                    columnUnloadingBeginDate: item.unloadingBeginDate,
                    columnCreatedAt: item.createdAt,
                    columnBrand: item.brand
                });
            })
            .filter((order) => !!order);
    }

    private handleError(error: any): void {
        console.error('Error while loading orders:', error);
        this.lastLoadError = error;
        this.loading = false;
    }

    isLoading(): boolean {
        return this.loading;
    }

    getLastLoadError(): string {
        return this.lastLoadError;
    }

    setDefaultSort(sort: Sort) {
        this.defaultSort = sort;
    }

    setSort(sort: Sort) {
        this.sort = sort;
    }

    // Class Public methods

    setLiveQuerySubscriber(subscriber: LiveQuerySubscriber) {
        if (this.subscriber) {
            this.unsubscribe();
        }
        this.subscriber = subscriber;
        this.subscribe();
    }

    // TODO: Вынести в end-point
    loadAllCustomers(): void {
        this.loading = true;
        this.lastLoadError = null;

        const query = new PFQuery('Customer');
        query.ascending('name');
        query.findAll().then((customers) => {
            this.dataCustomers.next(customers);
            this.loading = false;
        }).catch((error) => {
            this.lastLoadError = error;
            this.loading = false;
        });
    }

    // TODO: Вынести в end-point
    loadAllSuppliers(): void {
        this.loading = true;
        this.lastLoadError = null;

        const query = new PFQuery('Supplier');
        query.ascending('name');
        query.findAll().then((suppliers) => {
            this.dataSuppliers.next(suppliers);
            this.loading = false;
        }).catch((error) => {
            this.lastLoadError = error;
            this.loading = false;
        });
    }

    // TODO: Вынести в end-point
    loadUniqueAuthors(): void {
        this.loading = true;
        this.lastLoadError = null;

        const query = new PFQuery('Order');

        query.includes([
            'author',
            'author.manager',
            'author.dispatcher',
            'author.customer',
            'author.logistician',
        ]);
        query.findAll().then((orders) => {
            const authorNamesPromise = Promise.all(
                orders.map(order => {
                    const id = getOrderAuthorID(order);
                    const name = getOrderAuthorName(order);
                    return {id, name};
                })
            );

            authorNamesPromise.then(authors => {
                // Фильтруем уникальные записи и исключаем 'Admin'
                const uniqueAuthorsMap = new Map();
                authors.forEach(({id, name}) => {
                    if (name !== 'Admin') {
                        uniqueAuthorsMap.set(id, name);
                    }
                });

                // Преобразуем Map обратно в массив объектов
                const uniqueAuthors = Array.from(uniqueAuthorsMap.entries()).map(([id, name]) => ({
                    id,
                    name
                }));
                this.loading = false; // Устанавливаем состояние загрузки в false
                this.dataAuthors.next(uniqueAuthors);
            });
            //

        }).catch((error) => {
            // Обработка ошибок
            this.lastLoadError = error;
            this.loading = false;
        });
    }

    private removeObject(fromData: Parse.Object[], object: Parse.Object) {
        let index = fromData.findIndex(o => {
            return PFObject.compareFn(o, object);
        });
        if (index != -1) {
            fromData.splice(index, 1);
        }
        return fromData;
    }

    private addObject(toData: Parse.Object[], object: Parse.Object) {
        let index = toData.findIndex(o => {
            return PFObject.compareFn(o, object);
        });
        if (index == -1) {
            toData.unshift(object);
        }
        return toData;
    }

    private reloadData() {
        this.loadData(0, 0)
    }

    private reloadDataEditObject(object: Parse.Object = null) {
        let objects = this.data.getValue();
        this.executeQuery()
            .then(response => {
                const ordersColumns: Parse.Object[] = Array.isArray(response.objects) ? response.objects : [];

                const ordersList = this.mapOrders(ordersColumns);
                const order = ordersList.find(order => order.id === object.id)

                if (order) {
                    // Найти индекс элемента с совпадающим id
                    const index = objects.findIndex(o => o.id === order.id);

                    if (index !== -1) {
                        // Заменить элемент в массиве
                        objects[index] = order;
                    } else {
                        console.log(`Элемент с id ${order.id} не найден в массиве objects.`);
                    }
                } else {
                    console.log(`Элемент с id ${object.id} не найден в ordersList.`);
                }

                this.data.next(objects);
                this.count = objects.length;

            })
            .catch(error => {
                console.error('Ошибка:', error);
            });
    }

    private commitChanges(objects: Parse.Object[], object: Parse.Object = null) {
        this.data.next(objects);
        this.count = objects.length;
    }

    private commitChangesAddObject(objects: Parse.Object[], object: Parse.Object = null) {
        this.executeQuery()
            .then(response => {
                const ordersColumns: Parse.Object[] = Array.isArray(response.objects) ? response.objects : [];

                const ordersList = this.mapOrders(ordersColumns);
                const order = ordersList.find(order => order.id === object.id)

                if (order) {
                    // Найти индекс элемента с совпадающим id
                    const index = objects.findIndex(o => o.id === order.id);

                    if (index !== -1) {
                        // Заменить элемент в массиве
                        objects[index] = order;
                    } else {
                        console.log(`Элемент с id ${order.id} не найден в массиве objects.`);
                    }
                } else {
                    console.log(`Элемент с id ${object.id} не найден в ordersList.`);
                }

                this.data.next(objects);
                this.count = objects.length;

            })
            .catch(error => {
                console.error('Ошибка:', error);
            });
    }

    private subscribe(): void {
        this.unsubscribe();
        if (this.subscriber != null) {
            this.subscriber.subscribe((oldObject, newObject) => {
                if (PFObject.compareFn(oldObject, newObject)) {
                    if (!PFObject.compareFnObjCount(oldObject, newObject)) {
                        // need change ?
                        this.reloadDataEditObject(newObject)
                    }
                    return;
                } else {
                    let orders = this.data.getValue();
                    if (oldObject != null) {
                        orders = this.removeObject(orders, oldObject);
                        this.commitChanges(orders, oldObject);
                    }
                    if (newObject != null) {
                        orders = this.addObject(orders, newObject);
                        this.commitChangesAddObject(orders, newObject);
                    }
                }
            });
        }
    }

    private unsubscribe(): void {
        if (this.subscribedTabledata != null) {
            this.subscribedTabledata.subscriber.unsubscribe();
            this.subscribedTabledata = null;
        }
    }
}