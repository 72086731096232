import { environment } from 'environments/environment';
import { Parse } from 'parse';

import { Configuration } from './models/config/configuration';
import { LoadingPoint, UnloadingPoint, Waypoint, Entrance } from './models/points/points';
import { ArticleBrand, ArticleShape, ArticleType } from './models/articles/article';

import { Manager } from './models/persons/manager';
import { Dispatcher } from './models/persons/dispatcher';
import { Supplier } from './models/persons/supplier';
import { Customer } from './models/persons/customer';
import { Driver } from './models/persons/driver';
import { Intermediary } from './models/persons/intermediary';
import { Carrier } from './models/persons/carrier';

import { Order, OrderHistoryRecord } from './models/order/order';
import { Offer } from './models/offer';
import { TransportUnit, Vehicle, VehicleBrand, VehicleModel, Trailer} from './models/transport/transport';
import { Trip, TripHistoryRecord, TripProblem } from './models/trip/trip';
import { ExportChangeFlag } from './models/export-change-flag';
import { SaleTariff } from './models/tariff/tariff';
import { PurchaseTariff } from './models/tariff/tariff';
import { CarrierOffer } from './models/carrier-offer';
import { Message } from './models/message';
import { User } from './models/base/user';
import { Logistician } from './models/persons/logistician';
import { Distance } from './models/distance';

export function initializeParse() {
  Parse.initialize(environment.parseAppId);
  Parse.serverURL = environment.parseServerUrl;
  Parse.javaScriptKey = environment.parseJavascriptKey;
  console.log('Parse initialized');

  Parse.User.allowCustomUserClass(true);

  Parse.Object.registerSubclass('Configuration', Configuration);
  Parse.Object.registerSubclass('LoadingPoint', LoadingPoint);
  Parse.Object.registerSubclass('UnloadingPoint', UnloadingPoint);
  Parse.Object.registerSubclass('ArticleBrand', ArticleBrand);
  Parse.Object.registerSubclass('ArticleType', ArticleType);
  Parse.Object.registerSubclass('ArticleShape', ArticleShape);
  Parse.Object.registerSubclass('Order', Order);

  Parse.Object.registerSubclass('Manager', Manager);
  Parse.Object.registerSubclass('Dispatcher', Dispatcher);
  Parse.Object.registerSubclass('Supplier', Supplier);
  Parse.Object.registerSubclass('Customer', Customer);
  Parse.Object.registerSubclass('Driver', Driver);

  Parse.Object.registerSubclass('Offer', Offer);
  Parse.Object.registerSubclass('TransportUnit', TransportUnit);
  Parse.Object.registerSubclass('Vehicle', Vehicle);
  Parse.Object.registerSubclass('VehicleModel', VehicleModel);
  Parse.Object.registerSubclass('VehicleBrand', VehicleBrand);
  Parse.Object.registerSubclass('Trailer', Trailer);
  Parse.Object.registerSubclass('Trip', Trip);
  Parse.Object.registerSubclass('TripHistoryRecord', TripHistoryRecord);

  Parse.Object.registerSubclass('Waypoint', Waypoint);
  Parse.Object.registerSubclass('TripProblem', TripProblem);

  Parse.Object.registerSubclass('Intermediary', Intermediary);
  Parse.Object.registerSubclass('Entrance', Entrance);
  Parse.Object.registerSubclass('Carrier', Carrier);
  Parse.Object.registerSubclass('ExportChangeFlag', ExportChangeFlag);
  Parse.Object.registerSubclass('SaleTariff', SaleTariff);
  Parse.Object.registerSubclass('PurchaseTariff', PurchaseTariff);
  Parse.Object.registerSubclass('CarrierOffer', CarrierOffer);
  Parse.Object.registerSubclass('Message', Message);
  Parse.Object.registerSubclass('_User', User);
  Parse.Object.registerSubclass('Logistician', Logistician);
  Parse.Object.registerSubclass('OrderHistoryRecord', OrderHistoryRecord);
  Parse.Object.registerSubclass('Distance', Distance);
  
  subscribeLiveQueryStatus();
}

function subscribeLiveQueryStatus(): void {
  Parse.LiveQuery.on("open", () => {
    console.log("Live query connection established");
  });

  Parse.LiveQuery.on("close", () => {
    console.log("Live query connection closed");
  });

  Parse.LiveQuery.on('error', (error) => {
    console.log("Live query connection error:");
    console.log(error);
  });
}
