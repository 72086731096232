export const locale = {
  'DIALOG' : {
    'TRANSPORT_PROBLEM': {
      'TITLE' : 'Transport problem',
      'MESSAGE' : 'There is some transport problems. Do you want to continue?',
      'CONFIRM' : 'Yes, continue',
      'DECLINE' : 'Cancel',
      'VEHICLE_INSPECTION': 'Vehicle inspection is expired',
      'TRAILER_INSPECTION': 'Trailer inspection is expired',
      'VEHICLE_INSURANCE': 'Vehicle insurance is expired',
    },
  },
};
