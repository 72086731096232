
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { MaterialModule } from 'app/main/material.module';

import { DialogHeaderModule } from 'app/components/dialogs/dialog-header/dialog-header.module';
import { CreateMessageDialog } from './create_message/create-message-dialog.component';


@NgModule({
    declarations: [
        CreateMessageDialog
    ],
    imports: [
        TranslateModule,
        FuseSharedModule,
        MaterialModule,
        DialogHeaderModule,
    ],
    exports: [
        CreateMessageDialog
    ]
})
export class MessagesDialogsModule {
}
