
export const locale = {
  'DIALOG' : {
    'INTERMEDIARY' : {
      'TITLE' : {
        'CREATE' : 'Создать дилера',
        'EDIT' : 'Изменить дилера',
        'REMOVE' : 'Удалить',
      },
      'CONFIRMBUTTON' : {
        'CREATE' : 'Создать',
        'SAVE' : 'Сохранить',
        'CLOSE' : 'Закрыть',
      },
      'ERROR' : {
        'MUST_BE_FILLED' : 'Поле должно быть заполнено',
      },
      'NAME' : 'Название дилера',
    },
  },
};
