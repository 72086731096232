export const locale = {
  'DIALOG' : {
    'TRANSPORT_PROBLEM': {
      'TITLE' : 'Проблема с экипажем',
      'MESSAGE' : 'У этого экипажа есть проблемы с транспортом. Все равно продолжить?',
      'CONFIRM' : 'Да, продолжить',
      'DECLINE' : 'Отмена',
      'VEHICLE_INSPECTION': 'Тех. осмотр автомобиля просрочен',
      'TRAILER_INSPECTION': 'Тех. осмотр прицепа просрочен',
      'VEHICLE_INSURANCE': 'Полис ОСАГО просрочен',
    },
  },
};
