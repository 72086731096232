import { TableData, TableRowData, TableSort } from 'app/components/table/tabledata';

import { PFQuery } from 'app/components/data/core/models/base/query';
import { Customer } from 'app/components/data/core/models/persons/customer';

import { ServerTableDataSource } from 'app/components/table/data_sources/server-data-source';
import { filterDeleted } from 'app/components/access/query';
import { CurrentUser } from 'app/components/data/core/models/base/user';

export class CustomersTableData extends TableData {

  constructor() {
    super();

    let columns = [
      new TableRowData({
        key: 'name',
        header: 'USERS.TABLEROW.NAME',
        cell: (customer: Customer) => `${customer.getName()}`
      }),
      new TableRowData({
        key: 'ITN',
        header: 'USERS.TABLEROW.ITN',
        cell: (customer: Customer) => `${customer.getITN() ? customer.getITN() : ''}`
      }),
    ];

    if (CurrentUser.isLogistician() || CurrentUser.isAdministrator() || CurrentUser.isManager()) {
      columns.push(
        new TableRowData({
          key: 'credentials',
          header: 'USERS.TABLEROW.LOGIN',
          cell: (customer: Customer) => `${customer.getUser() ? customer.getUser().getUsername() : ''}`
        }),
      );
    }

    if (CurrentUser.isLogistician() || CurrentUser.isAdministrator()) {
      columns.push(
        new TableRowData({
          key: 'manager',
          header: 'USERS.TABLEROW.MANAGER',
          cell: (customer: Customer) => `${this.gerManagersNames(customer)}`
        }),
      );
    }

    this.setColumns(columns);

    let sort: TableSort = new TableSort('name', 'asc');
    let tableDataSource = new ServerTableDataSource('Customer', ['unloadingPoints.entrances', 'user', 'unloadingPoints.manager']);
    tableDataSource.setDefaultSort(sort);
    tableDataSource.queryFilter = (filter, query) => {
      query.contains('name', filter);

      let itnQuery: PFQuery = new PFQuery('Customer');
      itnQuery.contains('ITN', filter);

      let orQuery = PFQuery.or(query, itnQuery);

      if (CurrentUser.isLogistician() || CurrentUser.isAdministrator()) {
        let managerQuery = new PFQuery('Manager');
        managerQuery.contains('name', filter);

        let unloadingPointQuery = new PFQuery('UnloadingPoint');
        unloadingPointQuery.exists('manager');
        unloadingPointQuery.matchesQuery('manager', managerQuery);

        let customerManagerQuery = new PFQuery('Customer');
        customerManagerQuery.exists('unloadingPoints');
        customerManagerQuery.matchesQuery('unloadingPoints', unloadingPointQuery);

        return PFQuery.or(orQuery, customerManagerQuery);
      } else {
        return orQuery
      }
    };
    tableDataSource.queryModify = function (query: PFQuery): PFQuery {
      return filterDeleted(query);
    };
    this.setProvider(tableDataSource);
  }

  private gerManagersNames(customer: Customer) {
    let managers = [];
    customer.getUnloadingPoints().forEach(point => {
      let manager = point.getManager();
      if (manager) {
        let managerName = point.getManager().getName();
        let index = managers.indexOf(managerName);
        if (index == -1) {
          managers.push(managerName);
        }
      }
    });
    return managers;
  }
}
