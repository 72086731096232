import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { MaterialModule } from 'app/main/material.module';
import { TableModule } from 'app/components/table/table.module';
import { DialogHeaderModule } from 'app/components/dialogs/dialog-header/dialog-header.module';
import { CreateProposalDialog } from './create/create-proposal-dialog.component';
import { DeclineProposalDialog } from './decline/decline-proposal-dialog.component';
import { ProposalReservationByManagerDialog } from './reservation/by-manager/proposal-reservation-by-manager-dialog.component';
import { ProposalReservationByCarrierDialog } from './reservation/by-carrier/proposal-reservation-by-carrier-dialog.component';

import { ContactsModule } from 'app/main/admin/contacts/contacts.module';

@NgModule({
    declarations: [
        CreateProposalDialog,
        DeclineProposalDialog,
        ProposalReservationByManagerDialog,
        ProposalReservationByCarrierDialog,
    ],
    imports: [
        MaterialModule,
        TranslateModule,
        FuseSharedModule,
        TableModule,
        DialogHeaderModule,
        ContactsModule
    ],
    exports: [
        CreateProposalDialog,
        DeclineProposalDialog,
        ProposalReservationByManagerDialog,
        ProposalReservationByCarrierDialog,
    ]
})

export class ProposalDialogsModule
{
}
