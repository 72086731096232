import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { GroupTableComponent } from './group-table.component';
import { MaterialModule } from 'app/main/material.module';


@NgModule({
    declarations: [
        GroupTableComponent,
    ],
    imports: [
        FuseSharedModule,
        MaterialModule,
    ],
    exports: [
        GroupTableComponent,
    ],
})

export class GroupTableModule {
}
