import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/material.module';

import { CustomerInfoComponent } from './customer-info.component';
import { RouterModule, Routes } from '@angular/router';
import { UserRoleGuard, Role } from 'app/guards/user-role-guard.service';
import { TableHeaderModule } from 'app/components/tableheader/tableheader.module';

const routes: Routes = [
    {
      path: 'customer-info',
      canActivate: [UserRoleGuard],
      data: { roles: [Role.Customer] },
      component: CustomerInfoComponent,
    }
  ];

@NgModule({
    declarations: [
        CustomerInfoComponent,
    ],
    imports     : [
        TranslateModule,

        FuseSharedModule,
        MaterialModule,

        RouterModule.forChild(routes),

        TableHeaderModule,
    ],
    exports     : [
        CustomerInfoComponent,
    ],
})

export class CustomerInfoModule {}