import { NgModule } from '@angular/core';

import { DefaultLayoutModule } from 'app/layout/default-layout/default-layout.module';

@NgModule({
    imports: [
        DefaultLayoutModule,
    ],
    exports: [
        DefaultLayoutModule,
    ]
})
export class LayoutModule
{
}
