import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { LocaleService } from 'app/components/locale/locale.service';
import { deleteconfirmation } from '../../locale/locale';

import { TranslateService } from '@ngx-translate/core';
import { Order } from 'app/components/data/core/models/order/order';
import { DispatcherCloudExecutor } from 'app/components/data/cloud-executors/dispatcher-cloud-executor';
import { LocalNotificationService } from 'app/components/local-notifications-service/local-notifications-service';

@Component({
  selector: 'delete-order-dialog',
  templateUrl: './delete-order-dialog.component.html',
  styleUrls: ['./delete-order-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DeleteOrderDialogComponent {
  public orders: Order[];
  public currentProcessingOrder;
  private executor = new DispatcherCloudExecutor;
  private forceDeleteOrders: boolean;
  currentIndex: number;

  constructor(
    public dialogRef: MatDialogRef<DeleteOrderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) _data: any,
    private localeService: LocaleService,
    private translate: TranslateService,
    private notifications: LocalNotificationService,
  ) {
    this.orders = _data.object;
    this.forceDeleteOrders = _data.forceDeleteOrders;
    this.localeService.loadLocale(deleteconfirmation);
  }

  close() {
    this.executor.cancel();
  }

  isExecuting() {
    return this.executor.isProcessing();
  }

  execute() {
    let progress = (current: number) => {
      this.currentIndex = current + 1;
    };

    let promise;
    if (this.forceDeleteOrders) {
      promise = this.executor.deleteOrders(this.orders, progress);
    } else {
      promise = this.executor.cancelAndDeleteOrders(this.orders, progress);
    }
    promise.then(() => {
      this.dialogRef.close(true);
    }, error => {
      if (error !== 'canceled') {
        let localizedMessage = this.translate.instant('DELETECONFIRM.ERROR');
        this.notifications.showErrorNotification(localizedMessage);
      }
    });
  }
}
