
import { Component } from '@angular/core';
import { LocaleService } from 'app/components/locale/locale.service';

import { main } from './locale/locale';


@Component({
  selector: 'main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {
  constructor(
    localeService: LocaleService,
  ) {
    localeService.loadLocale(main);
  }
}
