export const locale = {
  'DELETECONFIRM' : {
    'TITLE' : 'Удалить заказ',
    'MESSAGE' : 'Вы уверены, что хотите удалить эти заказы?',
    'CONFIRM' : 'Да, удалить',
    'DECLINE' : 'Отменить',
    'ORDERNUMBER' : 'Заказ #',
    'DELETING' : 'Удаление заказов',
    'ERROR' : 'Не удалось удалить заказы',
  },
};
