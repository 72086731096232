import { Component, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { Intermediary } from 'app/components/data/core/models/persons/intermediary';

import { LocaleService } from 'app/components/locale/locale.service';
import { intermediary } from 'app/main/admin/users/locale/locale';

let locales = [intermediary];

import * as FormHelper from 'app/components/form-helper/form-helper';

import { CloudExecutor } from 'app/components/data/cloud-executors/cloud-executor';

@Component({
    selector   : 'intermediary-create-dialog',
    templateUrl: './intermediary-create-dialog.component.html',
    styleUrls  : ['./intermediary-create-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class IntermediaryCreateDialog {

  public form: UntypedFormGroup;
  public title: string;
  public confirmButtonTitle: string;

  private object: Intermediary;

  public loading: boolean = false;

  private dataSaver = new CloudExecutor;

  constructor (
    private localeService: LocaleService,
    public dialogRef: MatDialogRef<IntermediaryCreateDialog>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: UntypedFormBuilder,
  ) {
    this.localeService.loadLocales(locales);

    if (this._data.action === 'create') {
      this.object = new Intermediary();

      this.title = 'DIALOG.INTERMEDIARY.TITLE.CREATE';
      this.confirmButtonTitle = 'DIALOG.INTERMEDIARY.CONFIRMBUTTON.CREATE';

    } else {
      this.object = this._data.object;
      this.title = 'DIALOG.INTERMEDIARY.TITLE.EDIT';
      this.confirmButtonTitle = 'DIALOG.INTERMEDIARY.CONFIRMBUTTON.SAVE';
    }
    this.form = this.createContactForm();
  }

  createContactForm(): UntypedFormGroup {
    return this._formBuilder.group({
                name            : this.object.getName(),
              });
  }

  checkAndSave() {
    let verified = this.checkForm();

    if (verified) {
      this.loading = true;
      this.object.setName(this.form.controls.name.value);
        this.dataSaver.saveObject(this.object).then((c) => {
          this.close(c);
        });
    }
  }

  close(intermediary) {
    this.dialogRef.close(intermediary);
  }

  checkForm() : boolean {
    return FormHelper.checkForm(this.form);
  }
}
