export const locale = {
  'ORDER': {
    'FIELD_NAME': {
      'INTERMEDIARY': 'Intermediary',
      'CUSTOMER': 'Customer',
      'CHECK_CUSTOMER': 'Check customer',
      'UNLOADING_POINT': 'Unloading address',
      'UNLOADING_ENTRANCE': 'Unloading entance',
      'UNLOADING_DATE': 'Unloading date',
      'UNLOADING_TIME_FROM': 'Time from',
      'UNLOADING_TIME_TO': 'to',
      'LOAD_TYPE': 'Load type',
      'TRANSFER_POINT': 'Transfer point',
      'LOADING_TIME': 'Время',
      'SUPPLIER': 'Supplier',
      'AGREEMENT': 'Agreement',
      'LOADING_POINT': 'Loading address',
      'LOADING_ENTRANCE': 'Loading entance',
      'LOADING_DATE': 'Loading date',
      'ARTICLE_TYPE': 'Article type',
      'ARTICLE_BRAND': 'Article brand',
      'ARTICLE_SHAPE': 'Article shape',
      'TONNAGE': 'Tonnage',
      'SALE_PRICE': 'Sale price',
      'DELIVERY_PRICE': 'Devivery price',
      'DELIVERY_PRICE_TYPE': 'Devivery price type',
      'SALE_PRICE_TYPE': 'Sale price type',
      'PRICE_TYPES': {
        'ONCE': 'Once',
        'MULTIPLE': 'Not once',
      },
      'TIMEOUT': 'No connection, h',
      'COMMENT': 'Comment',
      'CONTACT_NAME': 'Contact name',
      'CONTACT_PHONE': 'Contact phone',
      'TYPE': 'Order type',
      'MANAGER_NAME': 'Manager',
      'MANAGER_PHONE': 'Manager phone',
      'KILOMETERS': 'Distance',
      'UNLOADING_CONTACT': 'Unloading contact',
    },
    'UNDEFINED': 'Undefined',
    'LOAD_TYPE': {
      'SUPPLIER': 'Supplier',
      'TRANSSHIPMENT': 'Transshipment',
    },
    'TYPE': {
      'DEFAULT': 'Default',
      'CARRIAGE': 'Carriage',
    },
  },
};
