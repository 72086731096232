
<div class="dialog-content-wrapper">
  <dialog-header headerClass="primary" 
                 title="{{title | translate}}">
  </dialog-header>
  <div class="content py-8 px-16" fxLayout="column">
    <form [formGroup]="form" fxFlex>
      <div fxLayout="column" 
           fxLayoutAlign="space-between stretch"
           fxLayout.gt-xs="row" 
           fxLayoutAlign.gt-xs="space-between center">
        <mat-form-field fxFlex fxFlex.gt-xs="49">
          <mat-select placeholder="{{'CUSTOMER_ORDER.DIALOG.CREATE.CONTROLS.TYPE_SELECTOR' | translate}}"
                      formControlName="articleType"
                      (selectionChange)="typeChanged()"
                      [compareWith]="compareFunction"
                      required>
            <mat-option *ngFor='let type of availableTypes' 
                        [value]="type">
                        {{type.name()}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex fxFlex.gt-xs="49">
          <mat-select placeholder="{{'CUSTOMER_ORDER.DIALOG.CREATE.CONTROLS.BRAND_SELECTOR' | translate}}"
                      formControlName="articleBrand"
                      [compareWith]="compareFunction"
                      required>
            <mat-option *ngFor='let brand of availableBrands' 
                        [value]="brand"
                        [disabled]="brand.isDeleted()">
                        {{brand.name()}}{{brand.isDeleted() ? ' (deleted)' : ''}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="column" 
           fxLayoutAlign="space-between stretch"
           fxLayout.gt-xs="row" 
           fxLayoutAlign.gt-xs="space-between center">
           <mat-form-field fxFlex fxFlex.gt-xs="33">
            <input matInput
                   type="text"
                   [matDatepicker]="unloadPicker"
                   placeholder="{{'CUSTOMER_ORDER.DIALOG.CREATE.CONTROLS.UNLOADING_DATE' | translate}}"
                   formControlName="unloadingDate"
                   required>
            <mat-datepicker-toggle matSuffix [for]="unloadPicker"></mat-datepicker-toggle>
            <mat-datepicker #unloadPicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field fxFlex fxFlex.gt-xs="33">
            <input matInput
                   type="number"
                   placeholder="{{'CUSTOMER_ORDER.DIALOG.CREATE.CONTROLS.UNLOADING_TIME_FROM' | translate}}"
                   formControlName="unloadingTimeFrom"
                   min=0
                   max=23
                   cdkFocusInitial
                   required>
          </mat-form-field>
          <mat-form-field fxFlex fxFlex.gt-xs="33">
            <input matInput
                   type="number"
                   placeholder="{{'CUSTOMER_ORDER.DIALOG.CREATE.CONTROLS.UNLOADING_TIME_TO' | translate}}"
                   formControlName="unloadingTimeTo"
                   min=0
                   max=23
                   required>
          </mat-form-field>
      </div>
      
      <div>
           <mat-form-field fxFlex>
            <input matInput 
                   type="number"
                   formControlName="tonnage"
                   placeholder="{{'CUSTOMER_ORDER.DIALOG.CREATE.CONTROLS.TONNAGE' | translate}}"
                   min="0"
                   required>
          </mat-form-field>
      </div>

      <div>
        <mat-form-field fxFlex>
          <mat-select placeholder="{{'CUSTOMER_ORDER.DIALOG.CREATE.CONTROLS.UNLOADING_ADDRESS_SELECTOR' | translate}}"
                      formControlName="unloadingPoint"
                      [compareWith]="compareFunction">
            <mat-option *ngFor='let point of availableUnloadingPoints' 
                        [value]="point">
                        {{ point.getAddress() }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-icon-button 
                type="button" 
                (click)="addUnloadingPoint()">
                <mat-icon>add</mat-icon>
        </button>
      </div>

      <div>
        <mat-form-field fxFlex>
          <textarea matInput
                    formControlName="comment"
                    placeholder="{{'CUSTOMER_ORDER.DIALOG.CREATE.CONTROLS.COMMENT' | translate}}">
          </textarea>
        </mat-form-field>
      </div>
      <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button
                color="accent"
                [disabled] = "executor.isProcessing()"
                (click)='checkAndSave()'>{{buttonName | translate}}
        </button>
      </div>
    </form>
    
  </div>

</div>
