export const locale = {
    'CUSTOMER_ORDER': {
        'DIALOG': {
            'CREATE': {
                'TITLE': {
                    'CREATE': 'Создать заказ',
                    'EDIT': 'Редактировать заказ',
                },
                'CONFIRMBUTTON': {
                    'CREATE': 'Создать',
                    'SAVE': 'Сохранить',
                },
                'CONTROLS': {
                    'BRAND_SELECTOR': 'Марка товара',
                    'TYPE_SELECTOR': 'Вид товара',
                    'UNLOADING_DATE': 'Дата разгрузки',
                    'UNLOADING_TIME_FROM': 'Время c',
                    'UNLOADING_TIME_TO': 'до',
                    'TONNAGE': 'Тоннаж',
                    'COUNT': 'Кол-во машин',
                    'UNLOADING_ADDRESS_SELECTOR': 'Адрес разгрузки',
                    'COMMENT': 'Комментарий',
                },
                'ERROR': {
                    'SAVE_ERROR': 'Неудалось обновить заказ. Попробуйте позже или свяжитесь с вашим менеджером.',
                    'LOAD_ERROR': 'Ошибка загрузки',
                    'MUST_SELECT': 'Необходимо выбрать из списка',
                    'UNDEFINED': 'Неудалось создать заказ. Попробуйте позже или свяжитесь с вашим менеджером.',
                    'NoSupplierForArticle': 'Товара нет в продаже',
                },
            },
        },
    },
};
