
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DownloadService } from 'app/components/download/download.service';

import { CustomerReportBuilder } from 'app/components/excel-export/orders/customer.report-builder';
import { DispatcherReportBuilder } from 'app/components/excel-export/orders/dispatcher.report-builder';
import { ManagerReportBuilder } from 'app/components/excel-export/orders/manager.report-builder';
import { DefaultReportBuilder } from 'app/components/excel-export/orders/default.report-builder';
import { ReportGenerator } from 'app/components/excel-export/orders/report';

import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'app/components/locale/locale.service';
import { report } from './locale/locale';
import { locale as excelExportLocale } from 'app/components/excel-export/locale/locale';
import { LocalNotificationService } from 'app/components/local-notifications-service/local-notifications-service';
import { DateHelper } from 'app/components/helpers/date.helper';
import { CurrentUser } from 'app/components/data/core/models/base/user';

import * as FormHelper from 'app/components/form-helper/form-helper';

@Component({
  selector: 'reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent {
  public formGroup: UntypedFormGroup;
  public generating: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private localeService: LocaleService,
    private translate: TranslateService,
    private notifications: LocalNotificationService,
    private downloadService: DownloadService) {
    this.localeService.loadLocale(report);
    this.localeService.loadLocale(excelExportLocale);
    this.formGroup = this.makeFormGroup();
  }

  public createReport() {
    if (this.checkForm()) {
      let beginDate = DateHelper.setDayBegin(new Date(this.formGroup.controls.beginDate.value));
      let endDate = DateHelper.setDayEnd(new Date(this.formGroup.controls.endDate.value));

      let title = this.translate.instant('ExcelExport.WorksheetTitle');
      let reportBuilder;
      if (CurrentUser.isCustomer()) {
        reportBuilder = new CustomerReportBuilder(this.translate, CurrentUser.customer());
      } else if (CurrentUser.isDispatcher()) {
        reportBuilder = new DispatcherReportBuilder(this.translate);
      } else if (!CurrentUser.isAdministrator() && !CurrentUser.isLogistician()) {
        reportBuilder = new ManagerReportBuilder(this.translate);
      } else {
        reportBuilder = new DefaultReportBuilder(this.translate);
      }
      let reportGenerator = new ReportGenerator(title, reportBuilder);
      let ordersPromise = reportGenerator.generateReport(beginDate, endDate);
      let fileName = `orders_${beginDate.toLocaleDateString()}_${endDate.toLocaleDateString()}.xlsx`;
      this.generating = true;
      this.downloadService
        .createLinkForDataPromise(ordersPromise, reportBuilder.fileType(), false)
        .then(url => {
          this.downloadUrl(url, fileName);
          this.generating = false;
        })
        .catch(error => {
          this.generating = false;
          this.handleError(error);
        });
    }
  }

  private downloadUrl(url, fileName) {
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  private checkForm(): boolean {
    return FormHelper.checkForm(this.formGroup);
  }

  private makeFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      beginDate: [],
      endDate: []
    });
  }

  private handleError(error: string) {
    console.log(error);
    let localizedMSG = this.translate.instant('REPORTS.ERROR.FAILED');
    this.notifications.showErrorNotification(localizedMSG);
  }
}
