import { ActionBarOperation } from 'app/components/action-bar/action-bar.component';
import { DialogData, DialogPresenterService } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';

export enum TableOperationActionType {
    Add = 'ADD',
    Copy = 'COPY',
    Edit = 'EDIT',
    Remove = 'REMOVE',
}

export namespace TableOperationsHelper {
    export function notSingleSelection(selected: any[]) {
        return !selected || selected.length != 1;
    }

    export function noSelectedValues(selected: any[]) {
        return !selected || selected.length == 0;
    }

    export function makeCreateOperation(action: () => void): ActionBarOperation {
        return new ActionBarOperation({
            title: 'ROWOPERATION.ADD',
            icon: 'add',
            action: action,
            disabled: false
        })
    }

    export function makeCopyOperation(action: () => void, selected: any[]): ActionBarOperation {
        return new ActionBarOperation({
            title: 'ROWOPERATION.COPY',
            icon: 'file_copy',
            action: action,
            disabled: notSingleSelection(selected)
        })
    }

    export function makeEditOperation(action: () => void, selected: any[]): ActionBarOperation {
        return new ActionBarOperation({
            title: 'ROWOPERATION.EDIT',
            icon: 'edit',
            action: action,
            disabled: notSingleSelection(selected)
        })
    }

    export function makeRemoveOperation(action: () => void, selected: any[]): ActionBarOperation {
        return new ActionBarOperation({
            title: 'ROWOPERATION.REMOVE',
            icon: 'remove',
            action: action,
            disabled: noSelectedValues(selected)
        })
    }

    export function makeDefaultOperations(
        dialogPresenter: DialogPresenterService,
        dialogDataConstructor: () => DialogData,
        selected: any[],
        completion: (error) => void = null) {

        let defaultTypes = [
            TableOperationActionType.Add,
            TableOperationActionType.Edit,
            TableOperationActionType.Copy,
            TableOperationActionType.Remove,
        ];
        return makeOperationsWithTypes(
            defaultTypes,
            dialogPresenter,
            dialogDataConstructor,
            selected,
            completion
        );
    }

    export function makeOperationsWithTypes(
        types: TableOperationActionType[],
        dialogPresenter: DialogPresenterService,
        dialogDataConstructor: () => DialogData,
        selected: any[],
        completion: (error) => void = null) {

        let operations = [];
        types.forEach(type => {
            operations.push(
                makeOperationWithType(
                    type,
                    dialogPresenter,
                    type == TableOperationActionType.Remove ? null : dialogDataConstructor,
                    selected,
                    completion
                )
            );
        });
        return operations;
    }

    export function makeOperationWithType(
        type: TableOperationActionType,
        dialogPresenter: DialogPresenterService,
        dialogDataConstructor: () => DialogData,
        selected: any[],
        completion: (error) => void = null) {

        let action = () => {
            if (dialogDataConstructor) {
                let dialogData = dialogDataConstructor();
                dialogData.data = modyfyDataForOperationType(dialogData.data, type, selected);
                dialogPresenter.showDialog(dialogData, type == TableOperationActionType.Edit ? null : completion);
            } else if (type == TableOperationActionType.Remove) {
                dialogPresenter.askRemoveItems(selected, completion);
            }
        }
        return operationWithType(type, action, selected);
    }

    function operationWithType(type: TableOperationActionType, action: () => void, selected: any[]) {
        switch (type) {
            case TableOperationActionType.Add:
                return makeCreateOperation(action);
            case TableOperationActionType.Edit:
                return makeEditOperation(action, selected)
            case TableOperationActionType.Copy:
                return makeCopyOperation(action, selected)
            case TableOperationActionType.Remove:
                return makeRemoveOperation(action, selected)
        }
    }

    function modyfyDataForOperationType(data, type: TableOperationActionType, items: any[]) {
        if (!data) {
            data = {};
        }

        switch (type) {
            case TableOperationActionType.Add:
                data.action = 'create';
                break;
            case TableOperationActionType.Edit:
                data.action = 'edit';
                data.object = items[0];
                break;
            case TableOperationActionType.Copy:
                data.action = 'create';
                data.object = items[0].copy();
                break;
            case TableOperationActionType.Remove:
                data.action = 'remove';
                data.object = items;
                break;
        }
        return data;
    }
}