import { Parse } from 'parse';

export class PFGeoPoint extends Parse.GeoPoint {
  constructor(point: { latitude: number, longitude: number }) {
    super(point);
  }

  get latitude() { return super.latitude; }
  get longitude() { return super.longitude; }

  kilometersTo(point: PFGeoPoint) {
    return super.kilometersTo(point);
  }
}