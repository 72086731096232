
<div class="page-layout carded fullwidth" fusePerfectScrollbar>
  <div class="top-bg primary"></div>
  <div class="center">

    <table-header headerTitle="{{'Settings.Title' | translate}}" 
                  iconName="settings"
                  noSearchBar="true"></table-header>

    <div class="mat-elevation-z8 fuse-white mb-16 px-16 pt-8">
      <form [formGroup]="formGroup">
        <div>
          <fieldset>
            <legend class="h2">{{"Settings.Order.Label" | translate}}</legend>
            <div class="value">
              <mat-label class="h3">{{"Settings.Order.TimeoutDescription" | translate}}</mat-label>
              <div>
                <mat-form-field fxFlex>
                  <input matInput placeholder="{{'Settings.TimeIntervalInHours' | translate}}" formControlName="orderTimeout" required>
                </mat-form-field>
              </div>
            </div>
          </fieldset>

          <fieldset>
            <legend class="h2">{{"Settings.TransportUnit.Label" | translate}}</legend>
            <div class="value">
              <mat-label class="h3">{{"Settings.TransportUnit.InactivityTimeIntervalDescription" | translate}}</mat-label>
              <div>
                <mat-form-field fxFlex>
                  <input matInput placeholder="{{'Settings.TimeIntervalInHours' | translate}}" formControlName="transportUnitInactivityTimeInterval" required>
                </mat-form-field>
              </div>
            </div>
          </fieldset>

          <fieldset>
            <legend class="h2">{{"Settings.TripDelay.Label" | translate}}</legend>
            <div *ngFor="let stage of tripStages" class="value">
              <mat-label class="h3">{{"Settings.TripDelay.TimeIntervalDescription" | translate: ({stage: titleForStage(stage)})}}</mat-label>
              <div>
                <mat-form-field fxFlex>
                  <input matInput placeholder="{{'Settings.TimeIntervalInMinutes' | translate}}" formControlName="tripDelayTimeInterval.{{stage}}" required>
                </mat-form-field>
              </div>
            </div>
          </fieldset>

          <fieldset>
            <legend class="h2">{{"Settings.TripStoppage.Label" | translate}}</legend>
            <div class="value">
              <mat-label class="h3">{{"Settings.TripStoppage.TimeIntervalDescription" | translate}}</mat-label>
              <div>
                <mat-form-field fxFlex>
                  <input matInput placeholder="{{'Settings.TimeIntervalInMinutes' | translate}}" formControlName="tripStoppageTimeInterval" required>
                </mat-form-field>
              </div>
            </div>
          </fieldset>

          <fieldset>
            <legend class="h2">{{"Settings.MaxDistance.Label" | translate}}</legend>
            <div class="value">
              <mat-label class="h3">{{"Settings.MaxDistance.Description" | translate}}</mat-label>
              <div>
                <mat-form-field fxFlex>
                  <input matInput placeholder="{{'Settings.MaxDistance.DistanceInKilometers' | translate}}" formControlName="maxDistance" required>
                </mat-form-field>
              </div>
            </div>
          </fieldset>

          <fieldset>
            <legend class="h2">{{"Settings.SuppliersOrder.Label" | translate}}</legend>
            <div class="value">
              <mat-label class="h3">{{"Settings.SuppliersOrder.Description" | translate}}</mat-label>
              <div>
                <button mat-raised-button class="accent my-16" (click)="changeSuppliersOrder()">{{"Settings.SuppliersOrder.ChangeOrder" | translate}}</button>
              </div>
            </div>
          </fieldset>

        </div>

        <div class="my-16">
          <button mat-raised-button
                  color="accent"
                  class="submit-button"
                  [disabled]="formGroup.pristine"
                  (click)="saveConfiguration()">
            {{"Settings.Apply" | translate}}
          </button>
        </div>

      </form>
    </div>

  </div>
</div>
