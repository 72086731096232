
import { PersonWithUser } from './person';

export class Logistician extends PersonWithUser {
  constructor() { super("Logistician"); }

  copy(): Logistician {
    let copy = new Logistician();
    copy.setName(this.getName());
    return copy;
  }
}
