
export const locale = {
  lang: "ru",
  data: {
    "Username": "Логин",
    "Password": "Пароль",
    "Title": "ВОЙДИТЕ В АККАУНТ",
    "LogIn": "ВОЙТИ",
    "UsernameError": "Логин обязателен",
    "PasswordError": "Пароль обязателен",
    "LogInError": "Произошла ошибка. Пожалуйста, проверьте логин и пароль.",
  }
};
