
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { UserRoleGuard, Role } from 'app/guards/user-role-guard.service';

import { UsersModule } from './users/users.module';
import { VehiclesModule } from './vehicles/vehicles.module';
import { ArticlesModule } from './articles/articles.module';
import { SettingsModule } from './settings/settings.module';
import { MessagesModule} from './messages/messages.module';
import { KilometersModule } from './kilometers/kilometers.module';

import { UsersComponent } from './users/users.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { ArticlesComponent } from './articles/articles.component';
import { SettingsComponent } from './settings/settings.component';
import { ExportQueueModule } from './queue/queue.module';
import { ExportQueueComponent } from './queue/queue.component';
import { MessagesListComponent } from './messages/messages-list.component';
import { KilometersComponent} from './kilometers/kilometers.component';

const routes: Routes = [
  {
    path     : 'admin',
    children: [
      {
        path: 'users',
        canActivate: [UserRoleGuard],
        data: {roles: [Role.Administrator, Role.Logistician, Role.Manager, Role.Dispatcher]},
        component: UsersComponent,
      },
      {
        path: 'vehicles',
        canActivate: [UserRoleGuard],
        data: {roles: [Role.Administrator, Role.Logistician, Role.Manager, Role.Dispatcher]},
        component: VehiclesComponent,
      },
      {
        path: 'articles',
        canActivate: [UserRoleGuard],
        data: {roles: [Role.Administrator, Role.Logistician, Role.Manager]},
        component: ArticlesComponent,
      },
      {
        path: 'export-queue',
        canActivate: [UserRoleGuard],
        data: {roles: [Role.Administrator, Role.Logistician]},
        component: ExportQueueComponent,
      },
      {
        path: 'messages-list',
        canActivate: [UserRoleGuard],
        data: {roles: [Role.Administrator, Role.Logistician]},
        component: MessagesListComponent,
      },
      {
        path: 'settings',
        canActivate: [UserRoleGuard],
        data: {roles: [Role.Administrator, Role.Logistician]},
        component: SettingsComponent,
      },
      {
        path: 'kilometers',
        canActivate: [UserRoleGuard],
        data: {roles: [Role.Administrator, Role.Logistician]},
        component: KilometersComponent,
      },
      {
        path: '',
        redirectTo: 'users',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  providers: [
    UserRoleGuard,
  ],
  imports     : [
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,

    UsersModule,
    VehiclesModule,
    ArticlesModule,
    SettingsModule,
    ExportQueueModule,
    MessagesModule,
    KilometersModule,
  ],
})
export class AdminModule {
}
