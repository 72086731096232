import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { proposals_reservation_dialog } from '../locale/locale';

import { Order } from 'app/components/data/core/models/order/order';
import { LocaleService } from 'app/components/locale/locale.service';
import { DispatcherCloudExecutor } from 'app/components/data/cloud-executors/dispatcher-cloud-executor';
import { LocalNotificationService } from 'app/components/local-notifications-service/local-notifications-service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'proposal-reservation-by-carrier-dialog',
  templateUrl: './proposal-reservation-by-carrier-dialog.component.html',
  styleUrls: ['./proposal-reservation-by-carrier-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ProposalReservationByCarrierDialog {
  executor = new DispatcherCloudExecutor();

  private object: Order;

  constructor(
    public dialogRef: MatDialogRef<ProposalReservationByCarrierDialog>,
    @Inject(MAT_DIALOG_DATA) _data: any,
    private localeService: LocaleService,
    private translate: TranslateService,
    private notifications: LocalNotificationService
  ) {
    this.localeService.loadLocale(proposals_reservation_dialog);
    this.object = _data.object;
  }

  reserve() {
    this.executor.reserveOrder(this.object)
      .then(() => {
        this.dialogRef.close();
      })
      .catch(() => {
        let msg = this.translate.instant('PROPOSALS.DIALOG.RESERVATION.ERROR');
        this.notifications.showErrorNotification(msg);
      });
  }

}
