export const locale = {
    'PROPOSALS': {
        'TITLE': {
            'LIST': 'Proposals',
            'BY_CARRIER': 'Proposals by carrier',
            'BY_SUPPLIER': 'Proposals by supplier',
            'FOR_CARRIER': 'Proposals for carrier',
            'FROM_CUSTOMERS': 'Proposals from customers',
        },
        'STOCK': 'Stock',
        'LOADING': 'Proposals are loading',
        'SUPPLIER': 'Supplier',
        'NO_CARRIER': 'No carrier',
        'HEADER': {
            'PROPOSALS': 'Proposals',
            'COUNT': 'Count',
            'PERCENTAGE': '% from total proposals',
            'REMAINS': 'Remains',
            'SUMMARY': 'Summary',
        },
        'FOOTER': {
            'TOTAL': 'Total proposals',
            'TOTAL_PRICE': 'Total price',
        },
        'TABLEROW': {
            'TIME': 'Time',
            'COUNT': 'Count',
            'CUSTOMER': 'Customer',
            'UNLOADING_ADDRESS': 'Unloading address',
            'LOADING_ADDRESS': 'Loading address',
            'COMMENT': 'Comment',
            'CARRIER': 'Carrier',
            'INFO': 'Info',
            'BRAND': 'Brand',
            'RESERVE': 'Reserve',
            'DISTANCE': 'Distance',
        },
        'ROWOPERATION': {
            'DECLINE': 'Decline proposal',
            'MAKE_ORDER': 'Make order',
            'RESERVATION': 'Reservation',
            'PROCESS': 'Process',
            'TO_WORK': 'Take to work',
        },
        'ORDER_NUMBER': 'Order #{{value}}',
        'PREORDER_NUMBER': 'Pre-order #{{value}}',
        'ALERT' : {
            'TITLE': {
                'WARNING': 'Warning',
            },
            'MESSAGE': {
                'ORDER_READY': 'Order is already created. You can change it in Orders section.',
                'ORDER_ALREADY_CREATED': 'Order is already created for this proposal',
                'ORDER_ALREADY_LOADED': {
                    'REMOVE': 'You cannot remove an order that is already loaded',
                    'RESERVE': 'You cannot change reservation for an order that is already loaded',
                },
                'NO_RESERVED_TO_DECLINE': "Unable to decline proposals that have not yet been reserved",
                'CANT_CHANGE_STATUS': "Proposal already has 'In Work' status",
            },
        },
        'RESERVE_STATUS': {
            'RESERVED': 'Reserved',
            'NOT_RESERVED': 'Not reserved',
        },
    },
};
