import { locale as settings_en } from "./en/locale.user-settings";
import { locale as settings_ru } from "./ru/locale.user-settings";

export const settings = [
  {
    lang: "en",
    data: settings_en
  },
  {
    lang: "ru",
    data: settings_ru
  }
];
