export const locale = {
  'KILOMETERS': {
    'TABLEROW': {
      'LOADING_POINT': 'Loading address',
      'UNLOADING_POINT': 'Unloading address',
      'DISTANCE': 'Distance',
    },
    'TITLE': 'Kilometers',
  }
};
