import { PFDeletableObject } from '../base/deletableObject'
import { User } from "../base/user";

export class Person extends PFDeletableObject {
  getName(): string {return super.get("name");}
  setName(value: string): void {super.set("name", value);}
}

export class PersonWithUser extends Person {
  getUser(): User {return super.get('user')}
  setUser(user: User) {super.set("user", user);}
}
