<div class="m-0" [ngClass]="isFullScreen ? 'fullscreen' : 'default'">
  <mat-toolbar class="primary">
      <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
          <span class="title dialog-title">{{ title ? title : ('SHOWONMAP.TITLE' | translate)}}</span>
          <div fxLayout="row" fxLayoutAlign="space-between center">
              <button mat-icon-button (click)="toggleFullScreen()">
                  <mat-icon *ngIf="!isFullScreen">fullscreen</mat-icon>
                  <mat-icon *ngIf="isFullScreen">fullscreen_exit</mat-icon>
                </button>
                <button mat-icon-button (click)="dialogRef.close()">
                    <mat-icon>close</mat-icon>
                </button>
          </div>
      </mat-toolbar-row>
  </mat-toolbar>

  <div class="dialog-content parent-block" *ngIf="coordinate">
    <agm-map #map 
             [latitude]="coordinate.latitude" 
             [longitude]="coordinate.longitude" 
             [zoom]="zoom > 0 ? zoom : 13"
             (mapClick)="map.lastOpen?.close(); map.lastOpen = null"
             [fitBounds]="bounds ? bounds : false"
             [ngClass]="isFullScreen ? 'fullscreen-map' : 'default-map'">

      <agm-marker *ngIf="transportUnit && transportUnit.coordinate()"
                  [iconUrl] = "'assets/images/markers/marker_truck.png'"
                  [latitude]="transportUnit.coordinate().latitude"
                  [longitude]="transportUnit.coordinate().longitude"
                  (markerClick)="map.lastOpen?.close(); map.lastOpen = unitInfo">
        <agm-info-window #unitInfo>
          <strong>{{transportUnit.driver().getName()}}</strong>
          <p>{{transportUnit.vehicleName()}}</p>
          <p>{{transportUnit.vehicle().number()}}</p>
          <p *ngIf="transportUnit.trailer()">{{transportUnit.trailer().number()}}</p>
          <p>{{transportUnit.roundedSpeed()}} {{'SHOWONMAP.SPEEDUNIT' | translate}}</p>
          <p>{{transportUnit.lastVisitStr()}}</p>
        </agm-info-window>
      </agm-marker>

      <ng-container *ngIf="trip && !dontShowTrack">

        <ng-container>

          <agm-marker *ngIf="getLoadingEntrance() && getLoadingEntrance().getCoordinate()"
                      [latitude]="getLoadingEntrance().getCoordinate().latitude"
                      [longitude]="getLoadingEntrance().getCoordinate().longitude"
                      [iconUrl] = "'assets/images/markers/marker_start.png'"
                      (markerClick)="map.lastOpen?.close(); map.lastOpen = loadInfo">
            <agm-info-window #loadInfo>
              <strong>{{getLoadingEntrance().getName()}}</strong>
              <p>{{getLoadingEntrance().getAddress()}}</p>
            </agm-info-window>
          </agm-marker>

          <agm-marker *ngIf="getUnloadingEntrance() && getUnloadingEntrance().getCoordinate()"
                      [latitude]="getUnloadingEntrance().getCoordinate().latitude"
                      [longitude]="getUnloadingEntrance().getCoordinate().longitude"
                      [iconUrl] = "'assets/images/markers/marker_finish.png'"
                      (markerClick)="map.lastOpen?.close(); map.lastOpen = unloadInfo">
            <agm-info-window #unloadInfo>
              <strong>{{getUnloadingEntrance().getName()}}</strong>
              <p>{{getUnloadingEntrance().getAddress()}}</p>
            </agm-info-window>
          </agm-marker>

          <agm-marker *ngIf="getUnloadingRecord() && getUnloadingRecord().coordinate()"
                      [latitude]="getUnloadingRecord().coordinate().latitude"
                      [longitude]="getUnloadingRecord().coordinate().longitude"
                      [iconUrl] = "'assets/images/markers/marker_parking.png'"
                      (markerClick)="map.lastOpen?.close(); map.lastOpen = info">
            <agm-info-window #info>
              <p>{{'SHOWONMAP.INFO.DATE' | translate}}: {{getUnloadingRecord().date().toLocaleString()}}</p>
              <p>{{'SHOWONMAP.STOPINFO.UNLOAD_TIME' | translate}}: {{getUnloadTime()}}</p>
			        <p>{{'SHOWONMAP.STOPINFO.VEHICLE_NUMBER' | translate}}: {{trip.transportUnit().vehicle().number()}}</p>              
              <p>{{'SHOWONMAP.STOPINFO.ADDRESS' | translate}}: {{getUnloadingAddress()}}</p>
            </agm-info-window>
          </agm-marker>

        </ng-container>

        <agm-polyline [strokeColor]="getLoadingTripColor()" [strokeWeight]="6">				
          <agm-polyline-point *ngFor="let point of forwardTrackPoints"
                              [latitude]="point.y"
                              [longitude]="point.x">
          </agm-polyline-point>
          <agm-icon-sequence *ngIf="map.zoom > getZoomToShowTripOverlays()"
                             [offset]="'70px'"
                             [path]="'FORWARD_CLOSED_ARROW'" 
                             [repeat]="'800px'"
                             [scale]="4"
                             [fillColor]="getLoadingTripColor()"
                             [fillOpacity]="1"
                             [strokeOpacity]="0.9"
                             [strokeColor]="'white'"
                             [strokeWeight]="1">
          </agm-icon-sequence>
        </agm-polyline>

        <agm-polyline [strokeColor]="getUnloadingTripColor()" [strokeWeight]="6">			
          <agm-polyline-point *ngFor="let point of backwardTrackPoints"
                              [latitude]="point.y"
                              [longitude]="point.x">
          </agm-polyline-point>
          <agm-icon-sequence *ngIf="map.zoom > getZoomToShowTripOverlays()"
                             [offset]="'70px'"
                             [path]="'FORWARD_CLOSED_ARROW'" 
                             [repeat]="'800px'"
                             [scale]="4"
                             [fillColor]="getUnloadingTripColor()"
                             [fillOpacity]="1"
                             [strokeOpacity]="0.9"
                             [strokeColor]="'white'"
                             [strokeWeight]="1">
          </agm-icon-sequence>
        </agm-polyline>

        <ng-container *ngIf="map.zoom > getZoomToShowTripOverlays()">
          <agm-marker *ngFor="let mapWaypoint of forwardMapWaypoints"
                      [latitude]="mapWaypoint.coordinate.latitude"
                      [longitude]="mapWaypoint.coordinate.longitude"
                      [iconUrl]="{url: 'assets/images/markers/direction_loading.png', anchor: {x:8, y:8}}"
                      (markerClick)="map.lastOpen?.close(); map.lastOpen = loadingWaypoint">
            <agm-info-window #loadingWaypoint>
              <strong *ngIf="mapWaypoint.stage != undefined">
                {{'SHOWONMAP.INFO.STAGE' | translate}}: {{('TRIP.STAGE.' + mapWaypoint.stage) | translate}}
              </strong>
              <p>{{'SHOWONMAP.INFO.DATE' | translate}}: {{mapWaypoint.date.toLocaleString()}}</p>
            </agm-info-window>
          </agm-marker>
          <agm-marker *ngFor="let mapWaypoint of backwardMapWaypoints"
                      [latitude]="mapWaypoint.coordinate.latitude"
                      [longitude]="mapWaypoint.coordinate.longitude"
                      [iconUrl]="{url: 'assets/images/markers/direction_unloading.png', anchor: {x:8, y:8}}"
                      (markerClick)="map.lastOpen?.close(); map.lastOpen = unloadingWaypoint">
            <agm-info-window #unloadingWaypoint>
              <strong *ngIf="mapWaypoint.stage != undefined">
                {{'SHOWONMAP.INFO.STAGE' | translate}}: {{('TRIP.STAGE.' + mapWaypoint.stage) | translate}}
              </strong>
              <p>{{'SHOWONMAP.INFO.DATE' | translate}}: {{mapWaypoint.date.toLocaleString()}}</p>
            </agm-info-window>
          </agm-marker>
        </ng-container>

      </ng-container>

    </agm-map>

    <div id="loading-block" *ngIf="loading">
      <div fxLayout="row" fxLayoutAlign="space-between center">
          <mat-spinner diameter="20" color="accent"></mat-spinner>
          <mat-label class="pl-20 h4" style="color:white;">{{'SHOWONMAP.LOADING_TRACK' | translate}}</mat-label>
      </div>
    </div>
    
    <div id="buttons-panel" fxLayout="column" fxLayoutAlign="center center">
        <button class="panel-button"
                *ngIf="getLoadingEntrance()"
                mat-raised-button 
                (click)="showA()">
            <mat-label>A</mat-label>
        </button>

        <button class="mt-8 panel-button"
                *ngIf="getUnloadingEntrance()"
                mat-raised-button 
                (click)="showB()">
            <mat-label>B</mat-label>
        </button>
        <button *ngIf="trip && getUnloadingRecord() && getUnloadingRecord().coordinate()"
                class="mt-8 panel-button"
                mat-raised-button 
                (click)="showP()">
            <mat-label>P</mat-label>
        </button>
    </div>
  </div>
  <div *ngIf="!coordinate" style="height: 150px" fxLayout="column" fxLayoutAlign="center center">
    <div fxLayout="row" fxLayoutAlign="center center">
      <mat-icon class="s-50" color="warn">location_off</mat-icon>
      <mat-error>{{'SHOWONMAP.ERROR.NO_UNIT_COORDINATES' | translate}}</mat-error>
    </div>
  </div>
</div>
