
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { Parse } from 'parse';

import { TransportUnit, TransportUnitStatus } from 'app/components/data/core/models/transport/transport';

import { AlertComponent } from 'app/components/alert/alert.component';

import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'app/components/locale/locale.service';
import { notifications } from './locale/locale';

@Injectable()
export class TransportUnitBreakageService {

  private subscription: Parse.LiveQuerySubscription;
  private dialogReference: MatDialogRef<AlertComponent>;

  constructor(
    private dialog: MatDialog,
    private localeService: LocaleService,
    private translateService: TranslateService) {
    this.localeService.loadLocale(notifications);
  }
  subscribe(): void {
    let query = new Parse.Query(TransportUnit);
    query.exists("user");
    query.equalTo("status", TransportUnitStatus.Breakage);
    query.subscribe().then(subscription => this.subscribed(subscription));
  }

  subscribed(subscription) {
    this.subscription = subscription;

    this.subscription.on('error', (error) => {
      console.log("Transport unit breakage subscription failed.");
    });

    this.subscription.on('enter', (object) => {
      this.refetch(object).then((transportUnit) => {
        console.log("Breakage found");
        this.showAlert(transportUnit);
      });
    });
  }

  unsubscribe(): void {
    this.subscription.unsubscribe();
  }

  private showAlert(transportUnit): void {
    this.dialogReference = this.dialog.open(AlertComponent, {data: {
      title: this.translateService.instant("TransportUnitBreakage.Title"),
      content: this.formatContent(transportUnit)
    }});

    this.dialogReference.afterClosed().subscribe(() => {
      this.dialogReference = undefined;
    });
  }

  private formatContent(transportUnit): string {
    let message = this.translateService.instant("TransportUnitBreakage.Message");
    let details = this.formatDetails(transportUnit);
    return `<p>${message}</p><p>${details}</p>`;
  }

  private formatDetails(transportUnit): string {
    let user = this.formatDriver(transportUnit.driver());
    let order = transportUnit.trip() ? this.formatOrder(transportUnit.trip().order()) : "";
    let vehicle = this.formatVehicle(transportUnit.vehicle());
    let trailer = transportUnit.trailer() ? this.formatTrailer(transportUnit.trailer()) : "";
    return [user, order, vehicle, trailer].filter(Boolean).join(", ");
  }

  private formatDriver(driver): string {
    return driver.getName();
  }

  private formatOrder(order): string {
    return this.translateService.instant("TransportUnitBreakage.Details.Order", {number: order.number()});
  }

  private formatVehicle(vehicle): string {
    return this.translateService.instant("TransportUnitBreakage.Details.Vehicle", {number: vehicle.number()});
  }

  private formatTrailer(trailer): string {
    return this.translateService.instant("TransportUnitBreakage.Details.Trailer", {number: trailer.number()});
  }

  private refetch(object): Promise<any> {
    let query = new Parse.Query(TransportUnit);
    query.equalTo("objectId", object.id);
    query.include("driver");
    query.include("vehicle");
    query.include("trailer");
    query.include("trip");
    query.include("trip.order");
    return query.first();
  }

}
