import { Component } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as en } from './locale/en';
import { locale as ru } from './locale/ru';

@Component({
    selector   : 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls  : ['./toolbar.component.scss']
})

export class ToolbarComponent 
{
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    )
    {
      this._fuseTranslationLoaderService.loadTranslations(en, ru);

    }
}
