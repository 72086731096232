export const locale = {
    'DIALOG': {
        'CONTACTS': {
          'TITLE': {
            'CREATE': 'Добавить контакт',
            'EDIT': 'Изменить контакт',
          },
          'CONFIRMBUTTON': {
            'CREATE': 'Добавить',
            'SAVE': 'Сохранить',
          },
          'NAME': 'Имя',
          'PHONE_NUMBER': 'Номер телефона',
        },
      },
  };
  