
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { CurrentUser } from 'app/components/data/core/models/base/user';


@Injectable()
export class AuthenticationService {

  constructor(private router: Router, private navigationService: FuseNavigationService) {}

  redirectURL: string;

  isAuthenticated() {
    let user = CurrentUser.user();
    return user && user.authenticated() && user.isValid() && CurrentUser.isValidRole(user);
  }

  checkSession() {
    CurrentUser.currentSession().then((session) => {
      if (!(session && session.isValid())) {
        CurrentUser.logOut();
        this.finishLogOut();
      }
    }).catch(error => {
      console.log(error);
      CurrentUser.logOut();
      this.finishLogOut();
    });
  }

  defaultRoute() {
    if (CurrentUser.isCustomer()) {
      return '/orders/inprogress';
    } else {
      return '/main';
    }
  }

  loginRoute() {
    return "/login";
  }

  currentRole() {
    return CurrentUser.user().role();
  }

  finishLogIn(): void {
    this.updateNavigation();
    this.redirectBack();
  }

  finishLogOut(): void {
    this.updateNavigation();
    this.router.navigate(["/login"]);
  }

  updateNavigation(): void {
    let navigation = "none";
    if (CurrentUser.isAuthenticated()) {
      if (CurrentUser.isAdministrator() || CurrentUser.isLogistician()) {
        navigation = "administrator";
      } else if (CurrentUser.isDispatcher()) {
        navigation = "dispatcher";
      } else if (CurrentUser.isManager()) {
        navigation = "manager";
      } else if (CurrentUser.isCustomer()) {
        navigation = "customer";
      }
    }
    this.navigationService.setCurrentNavigation(navigation);
  }

  private redirectBack(): void {
    console.log('redirectBack')
    if (this.redirectURL && this.redirectURL !== "") {
      this.router.navigateByUrl(this.redirectURL);
    } else {
      this.router.navigate([this.defaultRoute()]);
    }
  }

}
