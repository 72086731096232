<div class="m-0">
    <dialog-header headerClass="primary"
                   title="{{'DIALOG.FINISH_ORDER.TITLE' | translate}}">
    </dialog-header>

    <div class="dialog-content pt-8 px-16">
        <div *ngIf="!executor.isProcessing()">
            <form [formGroup]="form">
                <fieldset>
                    <legend class="h2">{{ 'DIALOG.FINISH_ORDER.LOAD' | translate }}</legend>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <mat-form-field fxFlex="78">
                            <input matInput
                                   type="text"
                                   [matDatepicker]="loadDatePicker"
                                   placeholder="{{'DIALOG.FINISH_ORDER.DATE' | translate}}"
                                   formControlName="loaded_date"
                                   cdkFocusInitial
                                   required>
                            <mat-datepicker-toggle matSuffix [for]="loadDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #loadDatePicker></mat-datepicker>
                            <mat-error
                                    *ngIf="form.get('loaded_date').hasError('noMatchToRegEx') || form.get('loaded_date').invalid">
                                {{ 'COMMON.ERROR.INVALID_DATE' | translate }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field fxFlex="20">
                            <input matInput
                                   type="number"
                                   placeholder="{{'DIALOG.FINISH_ORDER.TIME' | translate}}"
                                   formControlName="load_time"
                                   min="0"
                                   max="23"
                                   required>
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field fxFlex>
                            <input matInput
                                   type="number"
                                   placeholder="{{'DIALOG.FINISH_ORDER.TONNAGE' | translate}}"
                                   formControlName="loaded_tonnage"
                                   required>
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field fxFlex>
                            <input matInput
                                   type="number"
                                   placeholder="{{'DIALOG.FINISH_ORDER.DISTANCE' | translate}}"
                                   formControlName="distance"
                                   required
                            >
                        </mat-form-field>
                    </div>

                    <div>
                        <div *ngIf="!loadPhoto">
                            <button mat-button
                                    [disabled]="disabledLoadSection"
                                    ngxFilePicker
                                    accept="image/*"
                                    (filePick)="loadPhoto = $event">
                                {{ 'DIALOG.FINISH_ORDER.ADD_PHOTO' | translate }}
                            </button>
                        </div>
                        <div *ngIf="loadPhoto">
                            <img height="150" width="150" src="{{ loadPhoto.content }}" alt="{{ loadPhoto.name }}"
                                 (click)="openImage(loadPhoto)">
                        </div>
                        <div *ngIf="loadPhoto">
                            <mat-label>{{ loadPhoto.name }}</mat-label>
                            <button mat-icon-button
                                    [disabled]="disabledLoadSection"
                                    ngxFilePicker
                                    accept="image/*"
                                    (filePick)="loadPhoto = $event">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button
                                    [disabled]="disabledLoadSection"
                                    (click)="loadPhoto = null">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>

                </fieldset>

                <fieldset>
                    <legend class="h2">{{ 'DIALOG.FINISH_ORDER.UNLOAD' | translate }}</legend>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <mat-form-field fxFlex="78">
                            <input matInput
                                   type="text"
                                   [matDatepicker]="unloadPicker"
                                   placeholder="{{'DIALOG.FINISH_ORDER.DATE' | translate}}"
                                   formControlName="unloaded_date"
                                   required>
                            <mat-datepicker-toggle matSuffix [for]="unloadPicker"></mat-datepicker-toggle>
                            <mat-datepicker #unloadPicker></mat-datepicker>
                            <mat-error
                                    *ngIf="form.get('unloaded_date').hasError('noMatchToRegEx') || form.get('unloaded_date').invalid">
                                {{ 'COMMON.ERROR.INVALID_DATE' | translate }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field fxFlex="20">
                            <input matInput
                                   type="number"
                                   placeholder="{{'DIALOG.FINISH_ORDER.TIME' | translate}}"
                                   min="0"
                                   max="23"
                                   formControlName="unload_time"
                                   required>
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field fxFlex>
                            <input matInput type="number"
                                   placeholder="{{'DIALOG.FINISH_ORDER.TONNAGE' | translate}}"
                                   formControlName="unloaded_tonnage"
                                   required>
                        </mat-form-field>
                    </div>

                    <div>
                        <div *ngIf="!unloadPhoto">
                            <button mat-button
                                    ngxFilePicker
                                    accept="image/*"
                                    (filePick)="unloadPhoto = $event">{{ 'DIALOG.FINISH_ORDER.ADD_PHOTO' | translate }}
                            </button>
                        </div>
                        <div *ngIf="unloadPhoto">
                            <img height="150" width="150" src="{{ unloadPhoto.content }}" alt="{{ unloadPhoto.name }} "
                                 (click)="openImage(unloadPhoto)">
                        </div>
                        <div *ngIf="unloadPhoto">
                            <mat-label>{{ unloadPhoto.name }}</mat-label>
                            <button mat-icon-button
                                    ngxFilePicker
                                    accept="image/*"
                                    (filePick)="unloadPhoto = $event">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button (click)="unloadPhoto = null">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </fieldset>

                <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
                    <button mat-raised-button
                            color="accent"
                            (click)='finishOrder()'>{{ 'DIALOG.FINISH_ORDER.CONFIRM_BUTTON' | translate }}
                    </button>
                </div>
            </form>
        </div>
        <div class="loading-shade py-40" *ngIf="executor.isProcessing()" fxLayout="column"
             fxLayoutAlign="center center">
            <div fxLayout="row" fxLayoutAlign="center center">
                <mat-spinner diameter="30" color="accent"></mat-spinner>
                <p class="pl-16">{{ 'DIALOG.FINISH_ORDER.FINISHING' | translate }}</p>
            </div>
        </div>
    </div>

</div>
