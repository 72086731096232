export type AccessQueueFinishCallback = () => void;
export type AccessQueueAction = (finishCallback: AccessQueueFinishCallback) => void;

export class AccessQueue {
    private chain: AccessQueueAction[];
    private executing: boolean = false;
    constructor() {
      this.chain = [];
    }

    public enqueue(action: AccessQueueAction): void {
        this.chain.push(action);
        this.executeNext();
    }

    public cancelAll() {
        while (this.chain.length > 0) {
            this.dequeue();
        }
    }

    private executeNext() {
        if (this.executing) {
            return;
        } else {
            if (this.chain.length > 0) {
                this.executing = true;
                let next: AccessQueueAction = this.chain[0];
                this.dequeue();
                next(() => {
                    this.executing = false;
                    this.executeNext();
                })
            }
        }
    }

    private dequeue() {
        this.chain.shift();
    }

}