export const locale = {
  'NAV': {
    'MAIN': {
      'TITLE': 'Главная',
    },
    'AUTO': {
      'TITLE': 'Водители',
      'ONMAP': 'На карте',
      'TRANSPORTUNITS': 'Экипажи',
    },
    'ORDERS': {
      'TITLE': 'Заказы',
      'ALL': 'Все',
      'LIST': 'Новые',
      'INPROGRESS': 'В процессе',
      'FINISHED': 'Завершенные',
    },
    'PROPOSALS': {
      'TITLE': 'Заявки',
      'LIST': 'Список заявок',
      'CARRIER_PROPOSALS': 'Заявки перевозчика',
      'PROPOSALS_BY_CARRIERS': 'По перевозчикам',
      'PROPOSALS_BY_SUPPLIERS': 'По поставщикам',
      'PROPOSALS_FROM_CUSTOMERS': 'От покупателей',
    },
    'REPORTS': {
      'TITLE': 'Отчеты',
      'CREATE': 'Создать отчет',
    },
    'ADMIN': {
      'TITLE': 'Еще',
      'USERS': 'Люди и компании',
      'VEHICLES': 'Автомобили и прицепы',
      'ARTICLES': 'Товары',
      'SETTINGS': 'Настройки',
      'EXPORT_QUEUE': 'Очередь экспорта',
      'MESSAGES_LIST': 'Сообщения',
      'KILOMETERS': 'Километраж',
    },
    'CUSTOMER_INFO': {
      'TITLE': 'Мои данные',
    }
  }
};
