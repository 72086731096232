
import { Component, ViewEncapsulation, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import * as Location from 'app/components/location/coordinate';
import * as Geocode from 'app/components/geocode/geocode';
import * as Places from 'app/components/geocode/places';

import { LocaleService } from 'app/components/locale/locale.service';
import { CloudExecutor } from 'app/components/data/cloud-executors/cloud-executor';
import { Entrance, UnloadingPoint } from 'app/components/data/core/models/points/points';
import { PFGeoPoint } from 'app/components/data/core/models/base/point';

import { unloading_points } from './locale/locale';
import { TranslateService } from '@ngx-translate/core';

import * as FormHelper from 'app/components/form-helper/form-helper';

const defaultZoom = 8;
const placeFoundZoom = 15;

@Component({
    selector   : 'unloading-points-dialog',
    templateUrl: './unloading-points-dialog.component.html',
    styleUrls  : ['./unloading-points-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UnloadingPointDialog {

  public form: UntypedFormGroup;
  private object: UnloadingPoint;

  public title: string;
  public buttonName: string;

  public zoom;
  public lat;
  public lon;

  public latitudeErrorParam = { min: -85, max: 85};
  public longitudeErrorParam = { min: -180, max: 180};

  private geocoder: Geocode.Geocoder;
  private addressSearchService: Places.SearchService;

  public addressSearchResults: Places.SearchResult[];
  private lastGeocodedAddress: string;
  private lastGeocodedCoordinate: Location.Coordinate;

  public dataSaver = new CloudExecutor;

  constructor (
    private localeService: LocaleService,
    public dialogRef: MatDialogRef<UnloadingPointDialog>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    private translate: TranslateService,
  ) {
    this.localeService.loadLocale(unloading_points);
    this.object = this._data.object;
    if (!this.object) {
      this.object = new UnloadingPoint();
    }
    if (this._data.action === 'create') {
        this.title = 'DIALOG.UNLOADING_POINT.TITLE.CREATE';
        this.buttonName = 'DIALOG.UNLOADING_POINT.CONFIRMBUTTON.CREATE';
        this.zoom = defaultZoom;
        this.lat = 55.4507;
        this.lon = 37.3656;
    } else if (this._data.action === 'edit') {
        this.title = 'DIALOG.UNLOADING_POINT.TITLE.EDIT';
        this.buttonName = 'DIALOG.UNLOADING_POINT.CONFIRMBUTTON.SAVE';
        this.zoom = placeFoundZoom;
    }

    if (this._data.customTitle) {
      this.title = this._data.customTitle;
    }

    this.form = this.createContactForm();
  }

  parentContactName(): string {
    return this._data.contactName;
  }

  parentContactPhone(): string {
    return this._data.contactPhone;
  }

  public mapReady(map: any) {
    this.loadGeocoding(map);

    this.findCoordinate();
  }

  createContactForm(): UntypedFormGroup {
    let address = this.object.getAddress();
    return this._formBuilder.group({
      contactName: this.object.getContactName(),
      contactPhone: this.object.getContactPhone(),
      address: [address],
    });
  }

  checkAndSave() {
    let verified = this.checkForm();

    if (verified) {
      let address = this.form.get('address').value;
      this.object.setAddress(address);

      this.object.setContactName(this.form.controls.contactName.value);
      this.object.setContactPhone(this.form.controls.contactPhone.value);

      let entrance = new Entrance();
      entrance.setAddress(address);
      entrance.setName(this.translate.instant('DIALOG.CUSTOMER.DEFAULT_POINT'));
      let point: PFGeoPoint = new PFGeoPoint({
        latitude: this.lastGeocodedCoordinate.latitude,
        longitude: this.lastGeocodedCoordinate.longitude
      });
      entrance.setCoordinate(point);

      this.object.addEntrance(entrance);

      this.dataSaver.saveObject(this.object).then(obj => {
        this.dialogRef.close(obj);
      });
    }
  }

  checkForm(): boolean {
    return FormHelper.checkForm(this.form);
  }

  zoomChanged(event) {
    this.zoom = event;
  }

  updatePosition(event) {
    this.findAddress(event.coords.lat, event.coords.lng);
  }

  public addressChanged(text: string): void {
    if (text.length > 2) {
      this.addressSearchService.search(text);
    }
  }

  public addressSearchResultSelected(searchResult: Places.SearchResult) {
    this.form.controls.address.setValue("");
    this.addressSearchService.getDetails(searchResult).then((details) => {
      this.setAddress(details.address);
      this.setCoordinate(details.coordinate);
      this.changeDetector.detectChanges();
    });
  }

  findCoordinate(): void {
    if (!this.form.controls.address.valid) {
      this.form.controls.address.markAsTouched({onlySelf: true});
      return;
    }

    let address = this.form.controls.address.value;
    if (this.lastGeocodedAddress && this.lastGeocodedAddress === address) {
      return;
    }

    this.lastGeocodedAddress = address;
    this.getCoordsForAddress(address, (coordinate) => {
      this.setCoordinate(coordinate);
      this.changeDetector.detectChanges();
    })
  }

  getCoordsForAddress(address: string, completion) {
    this.geocoder.codeAddress(address).then((coordinate) => {
      if (completion) {
        completion(coordinate);
      }
    });
  }

  findAddress(latitude, longitude): void {
    if (!isFinite(latitude) || !isFinite(longitude)) {
      return;
    }

    let coordinate: Location.Coordinate = {latitude: latitude, longitude: longitude};
    if (this.lastGeocodedCoordinate && Location.isCoordinatesEqual(this.lastGeocodedCoordinate, coordinate)) {
      return;
    }
    this.lastGeocodedCoordinate = coordinate;

    this.geocoder.decodeAddress(coordinate).then((address) => {
      this.setAddress(address);
      this.changeDetector.detectChanges();
    });
  }

  private setAddress(address: string): void {
    this.lastGeocodedAddress = address;
    this.form.controls.address.setValue(address);
    this.form.controls.address.setErrors(null);
  }

  private setCoordinate(coordinate: Location.Coordinate): void {
    this.lastGeocodedCoordinate = coordinate;
    this.zoom = placeFoundZoom;
    this.lat = coordinate.latitude;
    this.lon = coordinate.longitude;
  }

  private loadGeocoding(map: any): void {
    this.geocoder = new Geocode.Geocoder();
    this.addressSearchService = Places.SearchService.all(map);

    this.addressSearchService.results.subscribe((results) => {
      this.addressSearchResults = results;
      this.changeDetector.detectChanges();
    });
  }
}
