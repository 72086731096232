import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PFObject } from 'app/components/data/core/models/base/object';
import { Supplier } from 'app/components/data/core/models/persons/supplier';
import { SupplierPriorityTableData } from './supplier-priority.tabledata';
import { LocaleService } from 'app/components/locale/locale.service';
import { supplier_priority } from '../../locale/locale';
import { defaultTableOptions, TableOptions } from 'app/components/table/table.component';
import { ConfigurationService } from '../../configuration.service';
import { DataLoader } from 'app/components/data/data-loader';
import { filterDeleted } from 'app/components/access/query';

let locales = [
  supplier_priority,
];

@Component({
  selector: 'supplier-priority-dialog',
  templateUrl: './supplier-priority-dialog.component.html',
  styleUrls: ['./supplier-priority-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SupplierPriorityDialog {
  compareFunction = PFObject.compareFn;
  
  public object: Supplier;

  tableData;
  tableOptions: TableOptions;

  private suppliersData = new DataLoader('Supplier');
  
  constructor(
    private localeService: LocaleService,
    private dialogRef: MatDialogRef<SupplierPriorityDialog>,
    private configService: ConfigurationService,
    @Inject(MAT_DIALOG_DATA) private _data: any,
  ) {
    this.localeService.loadLocales(locales);
    this.object = this._data.object;
    this.tableOptions = defaultTableOptions();
    this.tableOptions.selectable = false;
    this.tableOptions.dragable = true;

    this.suppliersData.setQueryModificationBlock((query) => {
      return filterDeleted(query);
    })
  }

  ngOnInit() {
    this.load();
  }

  private async load() {
    let config = await this.configService.fetch();
    let suppliers = await this.suppliersData.loadAll();
    let availableSuppliers = PFObject.sort(suppliers, config.getSupplierOrder());
    this.updateTableData(availableSuppliers);
  }

  private updateTableData(availableSuppliers) {
    this.tableData = new SupplierPriorityTableData(availableSuppliers);
  }

  save() {
    let suppliers = this.tableData.provider.getData();
    let config = this.configService.getConfiguration();
    config.setSupplierOrder(suppliers);
    config.save().then(() => {
      this.dialogRef.close();
    });
  }
}
