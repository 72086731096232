
export const locale = {
  lang: "en",
  data: {
    "Download": {
      "Text": "Click a link below to start download:",
      "Close": "Close",
    }
  }
};
