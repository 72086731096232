<div class="dialog-content-wrapper">
  <dialog-header headerClass="primary" 
                 title="{{'DISBAND.TITLE' | translate}}"
                 hideCloseButton="true">
  </dialog-header>
  
  <div class="p-16">
    <div *ngIf="!executor.isProcessing()" fxFlex>
      <mat-label class="font-size-16">{{'DISBAND.MESSAGE' | translate}}</mat-label>
      <div *ngFor="let unit of units">
        <p>{{'DISBAND.UNIT' | translate: ({driverName: driverName(unit), vehicleModel: vehicleModel(unit), vehicleNumber: vehicleNumber(unit)})}}</p>
      </div>
      <mat-dialog-actions class="pt-16 m-0" align="end">
        <button mat-raised-button
                [matDialogClose]="false"
                cdkFocusInitial>{{'DISBAND.DECLINE' | translate}}
        </button>
        <button mat-raised-button
                color="warn"
                (click)="disband()"
                cdkFocusInitial>{{'DISBAND.CONFIRM' | translate}}
        </button>
      </mat-dialog-actions>
    </div>

    <div class="loading-shade py-40" *ngIf="executor.isProcessing()" fxLayout="column" fxLayoutAlign="center center">
      <div fxLayout="row" fxLayoutAlign="center center"> 
        <mat-spinner diameter="30" color="accent"></mat-spinner>
        <p class="pl-16" translate [translateParams]="{cur: curIndex(), total: units.length}">DISBAND.EXECUTING</p>
      </div>
    </div>
  </div>
  
</div>
