
<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="primary" 
                 title="{{title | translate}}">
  </dialog-header>
  
  <div class="pt-8 px-16">
    <form [formGroup]="form">

      <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">
        <mat-form-field fxFlex>
          <input matInput
                 placeholder="{{'DIALOG.DISPATCHER.FULLNAME' | translate}}"
                 formControlName="name"
                 required
                 cdkFocusInitial>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field fxFlex>
          <mat-select placeholder="{{'DIALOG.DISPATCHER.CARRIER' | translate}}"
                    formControlName="carrier"
                    [compareWith]="compareFunction"
                    required>
                    <mat-option *ngFor='let carrier of dataLoader.getLoadedItems()' [value]="carrier">{{carrier.get('name')}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button
                color="accent"
                [disabled] = "dataSaver.isProcessing()"
                (click)='checkAndSave()'>{{buttonName | translate}}
        </button>
      </div>
    </form>
  </div>
</div>
