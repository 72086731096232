import { NgModule } from '@angular/core';
import { MaterialModule } from 'app/main/material.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { DateRangeSelectorComponent }  from './date-range-selector.component'

@NgModule({
    declarations: [
        DateRangeSelectorComponent,
    ],
    imports: [
        FuseSharedModule,
        MaterialModule,
    ],
    exports: [
        DateRangeSelectorComponent,
    ],
})

export class DateRangeSelectorModule {
}
