
import { Injectable } from '@angular/core';
import { Parse } from 'parse';

import { CurrentUser } from 'app/components/data/core/models/base/user';
import { TripProblem, TripProblemType } from 'app/components/data/core/models/trip/trip';

import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'app/components/locale/locale.service';
import { trip_problem } from 'app/main/locale/locale';
import { notifications } from './locale/locale';
import { DialogPresenterService } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';

let locales = [
  trip_problem,
  notifications,
]

@Injectable()
export class OrdersProblemsService {

  private subscription: Parse.LiveQuerySubscription;

  constructor(
    private dialogPresenter: DialogPresenterService,
    private localeService: LocaleService,
    private translateService: TranslateService) {
    this.localeService.loadLocales(locales);
  }

  subscribe(): void {
    let query = new Parse.Query(TripProblem);
    query.subscribe().then(subscription => this.subscribed(subscription));
  }

  subscribed(subscription) {
    this.subscription = subscription;
    this.subscription.on('error', (error) => {
      console.log("Trips problems subscription failed.");
    });

    this.subscription.on('create', (object) => {
      console.log('Order problem added');
      this.refetch(object).then((tripProblem) => {
        if (CurrentUser.isAdministrator() ||
          CurrentUser.isLogistician() ||
          tripProblem.order().user().id === CurrentUser.user().id
        ) {
          this.showAlert(tripProblem);
        }
      });
    });
  }

  unsubscribe(): void {
    this.subscription.unsubscribe();
  }

  private showAlert(tripProblem): void {
    if (!CurrentUser.getSettings().notifications) return;
    this.dialogPresenter.showAlert({
      title: this.translateService.instant("OrderProblem.Title"),
      content: this.formatContent(tripProblem)
    });
  }

  private formatContent(tripProblem): string {
    let message = this.translateService.instant("OrderProblem.Message");
    let details = this.formatDetails(tripProblem);
    return `<p>${message}</p><p>${details}</p>`;
  }

  private formatDetails(tripProblem): string {
    let trip = tripProblem.trip();
    let order = trip.order();
    let tu = trip.transportUnit();

    let userText = this.formatDriver(tu.driver());
    let problemText = this.formatProblem(tripProblem);
    let orderText = this.formatOrder(order);
    let vehicleText = this.formatVehicle(tu.vehicle());
    let trailerText = tu.trailer() ? this.formatTrailer(tu.trailer()) : "";
    return [userText, problemText, orderText, vehicleText, trailerText].filter(Boolean).join("<br>");
  }

  private formatDriver(driver): string {
    return this.translateService.instant("OrderProblem.Details.Driver", { name: driver.getName() });
  }

  private formatProblem(problem): string {
    let problemText = this.labelForTripProblemType(problem.type());
    return this.translateService.instant("OrderProblem.Details.Problem", { problem: problemText });
  }

  private formatOrder(order): string {
    return this.translateService.instant("OrderProblem.Details.Order", { number: order.number() });
  }

  private formatVehicle(vehicle): string {
    return this.translateService.instant("OrderProblem.Details.Vehicle", { number: vehicle.number() });
  }

  private formatTrailer(trailer): string {
    return this.translateService.instant("OrderProblem.Details.Trailer", { number: trailer.number() });
  }

  private labelForTripProblemType(type: TripProblemType): string {
    if (type === undefined) {
      type = 2;
    }
    let problemText = TripProblem.problemTypeText(type);
    return this.translateService.instant(problemText);
  }

  private refetch(tripProblem): Promise<any> {
    let query = new Parse.Query(TripProblem);
    query.equalTo("objectId", tripProblem.id);
    query.include("trip.transportUnit.vehicle");
    query.include("trip.transportUnit.trailer");
    query.include("trip.transportUnit.driver");
    query.include("trip.order");
    return query.first();
  }

}
