<div class="page-layout carded fullwidth" fusePerfectScrollbar>
    <div class="top-bg primary"></div>
      <div class="center">
          <div fxLayout="column"
               fxLayoutAlign="center stretch"
               fxLayout.gt-xs="row"
               fxLayoutAlign.gt-xs="space-between stretch">
            <table-header fxFlex
                          headerTitle="{{'PROPOSALS.TITLE.FROM_CUSTOMERS' | translate}}" 
                          iconName="assignment_returned" 
                          searchBarTitle="COMMON.SEARCHTITLE"
                          (onSearch)="search($event)">
            </table-header>
            <date-selector class="primary mt-32 mb-20" 
                           [initialDate]="initialDate"
                           (dateSelected)="reloadOrders()">
            </date-selector>
          </div>
          
          <div class="mat-elevation-z8 fuse-white mb-16" 
               fxLayout="column">
                <cd-table fxFlex
                          [tabledata]="tableData"
                          (onSelectionChange)="operationsController.updateOperations($event.table)">
                </cd-table>
          </div>
    </div>
</div>
