
export const locale = {
  'DIALOG' : {
    'CUSTOMER' : {
      'TITLE' : {
        'CREATE' : 'Create customer',
        'EDIT' : 'Edit customer',
        'CHECK' : 'Customer check',
        'POINTS' : 'Unloading points',
        'ENTRANCES': 'Entrances',
      },
      'CONFIRMBUTTON' : {
        'CREATE' : 'Create',
        'SAVE' : 'Save',
        'CLOSE' : 'Close',
      },
      'ERROR' : {
        'ITN_ONLY_NUMBERS': 'ITN may contain only numbers',
        'ITN_LENGTH' : 'Must be 12 digits',
        'MUST_BE_FILLED' : 'Must be filled',
      },
      'HINT' : {
        'ITN' : '12 digits',
      },
      'NAME' : 'Customer name',
      'CONTACT_NAME' : 'Contact name',
      'CONTACT_PHONE' : 'Contact phone',
      'ITN' : 'ITN',
      'CUSTOMER' : 'Customer',
      'ADDRESS' : 'Address',
      'UNLOADING_POINTS' : 'Unloading points',
      'DEFAULT_POINT' : 'Entrance 1',
      'DEFAULT_PRICE_TYPE' : 'Default price type',
      'RECEIVABLES' : 'Receivables',
      'CREDIT' : 'Credit',
      'LAST_PAYMENT' : 'Last payment',
      'PERMISSIONS' : {
        'TITLE': 'Permissions',
        'MESSAGE': "Change permissions for '{{value}}'?",
        'SAVE': 'Save',
        'DRIVERS_PHONE': "Can see driver's phone",
      },
    },
  },
};
