import { Component, ViewChild } from '@angular/core';

import { LocaleService } from 'app/components/locale/locale.service';
import { proposals } from '../../locale/locale';
import { default_row_operations, common, orders, tripStage, units, offers, equipment } from '../../../locale/locale';
import { DataLoader } from 'app/components/data/data-loader';
import { PFQuery } from 'app/components/data/core/models/base/query';
import { Order, OrdersFilter } from 'app/components/data/core/models/order/order';

import { PFObject } from 'app/components/data/core/models/base/object';
import { ProposalsByCarrierTableData } from './proposals-list-by-carrier.tabledata';
import { TranslateService } from '@ngx-translate/core';
import { Carrier } from 'app/components/data/core/models/persons/carrier';
import { DateHelper } from 'app/components/helpers/date.helper';
import { ProposalHelper } from '../../helpers/proposal-info.helper';
import { Group } from 'app/components/group-table/group-table.component';
import { DateSelectorComponent } from 'app/components/date-selector/date-selector.component';
import { defaultTableOptions, TableComponent, TableOptions, } from 'app/components/table/table.component';
import { TableOperationsController } from 'app/components/table-operations-controller/table-operations-controller';
import { ProposalTableOperationsFactory } from '../../operations-factory/proposals.operation-factory';
import { DialogPresenterService } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import { SearchHelper } from 'app/components/helpers/search.helper';
import { ProposalsLoader } from '../../proposals-loader';
import { GroupHeaderItem } from '../common/header-item';

const locales = [
  proposals,
  common,
  default_row_operations,
  orders,
  tripStage,
  units,
  offers,
  equipment,
];

interface CarrierGroup extends Group {
  name: string,
  headerItems: GroupHeaderItem[],
  carrier: Carrier | null,
  orders: Order[],
  totalOrders: number,
  tableData: ProposalsByCarrierTableData,
}

@Component({
  selector: 'proposals-list-by-carrier',
  templateUrl: './proposals-list-by-carrier.component.html',
  styleUrls: ['./proposals-list-by-carrier.component.scss']
})
export class ProposalsListByCarrierComponent {
  compareFunction = PFObject.compareFn;
  
  initialDate = DateHelper.nextDay(DateHelper.setDayBegin(new Date()));

  proposalGroups;
  footerItems = [];

  tableOptions: TableOptions;
  operationsController: TableOperationsController;

  private proposalsLoader = new ProposalsLoader();
  private carriersData: DataLoader;
  
  private lastSelectedTable: TableComponent;
  private searchFilter;

  @ViewChild(DateSelectorComponent) private dateSelector: DateSelectorComponent;

  constructor(
    private localeService: LocaleService,
    private translate: TranslateService,
    private dialogPresenter: DialogPresenterService,
  ) {
    this.localeService.loadLocales(locales);
    this.carriersData = new DataLoader("Carrier");
    this.tableOptions = defaultTableOptions();
    this.tableOptions.hiddenPaginator = true;

    let factory = new ProposalTableOperationsFactory(
      this.dialogPresenter, 
      this.translate, 
      ()=>this.makeDialogData());

    this.operationsController = new TableOperationsController(factory);
  }

  ngOnInit() {
    this.carriersData.loadAll().then(() => {
      this.reloadOrders();
    });
    this.proposalsLoader.items.subscribe((orders) => {
      if (orders) this.reloadContent();
    });
  }

  search(searchString: string) {
    this.searchFilter  = searchString;
    this.reloadContent();
  }

  isLoading(): boolean {
    return this.carriersData.isLoading() || this.proposalsLoader.isLoading();
  }

  updateOperations(table: TableComponent) {
    if (this.lastSelectedTable && this.lastSelectedTable != table) {
      this.lastSelectedTable.deselectAll();
    }
    this.lastSelectedTable = table;
    this.operationsController.updateOperations(table);
  }

  async reloadOrders() {
    this.updateOperations(null);
    if (this.carriersData.getLoadedItems().length == 0) return;
    let selectedDate = this.dateSelector.selectedDate;
    let startDate = DateHelper.setDayBegin(new Date(selectedDate));
    let endDate = DateHelper.setDayEnd(new Date(selectedDate));
    
    this.proposalsLoader.setModifyBlock((query: PFQuery) => {
      query.exists('supplier');
      query.greaterThanOrEqualTo("unloadingBeginDate", startDate);
      query.lessThanOrEqualTo("unloadingBeginDate", endDate);
      return query;
    });
    this.proposalsLoader.reloadData();
  }

  private makeDialogData() {
    return {date: this.dateSelector.selectedDate};
  }

  private reloadContent() {
    let orders = this.proposalsLoader.items.getValue();
    let carriers = this.carriersData.getLoadedItems();
    let groups: CarrierGroup[] = [];
    let ordersWithoutCarriers = OrdersFilter.filterOrdersWithoutCarriers(orders);
    ordersWithoutCarriers = OrdersFilter.filterProposalsNotContainedText(ordersWithoutCarriers, this.searchFilter);
    let filteredTodayOrders = ordersWithoutCarriers;
    if (ordersWithoutCarriers.length) {
      groups.push({
        identifier: 'blank',
        headerClass: 'primary-50-bg',
        name: this.translate.instant('PROPOSALS.NO_CARRIER'),
        headerItems: this.makeHeader(ordersWithoutCarriers, orders.length),
        carrier: null,
        orders: ordersWithoutCarriers,
        totalOrders: orders.length,
        tableData: new ProposalsByCarrierTableData(ordersWithoutCarriers, this.translate),
        expandedByDefault: ordersWithoutCarriers.length > 0 
      });
    }
    let carrierGroups = [];
    for (let carrier of carriers) {
      let name = carrier.getName();
      let carrierOrders = OrdersFilter.filterOrdersByCarrier(orders, carrier);
      if (!SearchHelper.isContained(name, this.searchFilter)) {
        carrierOrders = OrdersFilter.filterProposalsNotContainedText(carrierOrders, this.searchFilter);
        if (carrierOrders.length === 0) continue;
      }
      carrierGroups.push({
        identifier: name,
        headerStyle: {'background-color':  carrierOrders.length > 0 ? 'rgb(233,233,233)' : 'rgb(253,222,189)'},
        name: name,
        headerItems: this.makeHeader(carrierOrders, orders.length),
        carrier: carrier,
        orders: carrierOrders,
        totalOrders: orders.length,
        tableData: new ProposalsByCarrierTableData(carrierOrders, this.translate),
        expandedByDefault: carrierOrders.length > 0 
      });
      filteredTodayOrders = filteredTodayOrders.concat(carrierOrders);
    }
    carrierGroups = carrierGroups.sort((g1,g2) => {
      let length1 = g1.orders.length;
      let length2 = g2.orders.length;
      if (length1 > length2) return -1;
      if (length1  < length2) return 1;
      return 0;
    })
    groups = groups.concat(carrierGroups);
    this.proposalGroups = groups;
    this.footerItems = this.footerItemsForOrders(filteredTodayOrders);
  }

  private makeHeader(orders: Order[], totalOrders: number): GroupHeaderItem[] {
    let tonnage = ProposalHelper.totalTonnage(orders);
    let percent = (totalOrders > 0) ? Math.round( orders.length / totalOrders * 100)  : 0;
    return [
      {
        title: this.translate.instant("PROPOSALS.HEADER.PROPOSALS"),
        value: orders.length,
      },
      {
        title: this.translate.instant("PROPOSALS.HEADER.COUNT"),
        value: tonnage,
      },
      {
        title: this.translate.instant("PROPOSALS.HEADER.PERCENTAGE"),
        value: percent,
      },
    ];
  }

  private footerItemsForOrders(orders) {
    return [
      {
        title: this.translate.instant("PROPOSALS.FOOTER.TOTAL"),
        value: orders.length,
      },
    ];
  }
}

