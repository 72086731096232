import { Component, ViewChild } from '@angular/core';

import { KilometersTableData } from './kilometers.tabledata';

import { TableComponent } from 'app/components/table/table.component';

import { LocaleService } from 'app/components/locale/locale.service';
import { kilometers } from './locale/locale';
import { default_row_operations, common } from '../../locale/locale';

import { TableData } from 'app/components/table/tabledata';
import { DialogPresenterService } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import { TableOperationsController } from 'app/components/table-operations-controller/table-operations-controller';
import { KilometersTableOperationsFactory } from './kilometers-table.operations-factory';

const locales = [
  kilometers,
  default_row_operations,
  common,
];

@Component({
  selector: 'kilometers',
  templateUrl: './kilometers.component.html',
})

export class KilometersComponent {
  tableData: TableData;
  operationsController: TableOperationsController;
  @ViewChild(TableComponent) private table: TableComponent;
  
  headerButtons = [];

  constructor(
    private localeService: LocaleService,
    dialogPresenter: DialogPresenterService,
  ) {
    this.localeService.loadLocales(locales);
    this.tableData = new KilometersTableData();

    let operationsFactory = new KilometersTableOperationsFactory(dialogPresenter, () => { this.reloadData() });
    this.operationsController = new TableOperationsController(operationsFactory);
    this.headerButtons = this.makeHeaderButtons();
  }

  updateOperations(table: TableComponent) {
    this.operationsController.updateOperations(table);
  }

  search(searchString: string) {
    this.table.search(searchString);
  }

  private makeHeaderButtons() {
    return [
      {
        'title': 'COMMON.BUTTONS.RELOAD',
        'action': () => this.reloadData()
      },
    ];
  }
  
  private reloadData() {
    this.table.reloadData();
  }
}
