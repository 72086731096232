export const locale = {
  'CREATESHAPE' : {
    'TITLE' : {
      'CREATE' : 'Create shape',
      'EDIT' : 'Edit shape',
    },
    'CONFIRMBUTTON' : {
      'CREATE' : 'Create',
      'SAVE' : 'Save',
    },
    'NAME' : 'Shape name',
  },
};
