export const locale = {
  'DIALOG': {
    'DECLINE': {
      'TITLE': 'Decline order',
      'MESSAGE': 'Are you sure you want to decline this orders?',
      'CONFIRM': 'Yes, decline',
      'DECLINE': 'Close',
      'ORDERNUMBER': 'Order #',
      'ERROR' : 'Failed to decline orders',
    },
  },
};
