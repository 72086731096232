export const locale = {
    'CUSTOMER_ORDER': {
        'DIALOG': {
            'CANCEL' : {
                'TITLE': 'Отмена заказа',
                'CONFIRM': 'Да, отменить',
                'MESSAGE': 'Вы действительно хотите отменить заказ?',
                'ORDERNUMBER': 'Заказ №',
                'ERROR': {
                    'UNDEFINED': 'Не удалось отменить заказ. Попробуйте еще раз немного позже или свяжитесь с вашим менеджером.',
                    'INVALID_STAGE': "На данном этапе исполнения уже невозможно отменить заказ",
                },
                'TONNAGE': '{{value}} тонн',
            },
        },
    },
};
