import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DispatcherCloudExecutor } from 'app/components/data/cloud-executors/dispatcher-cloud-executor';
import { PersonWithUser } from 'app/components/data/core/models/persons/person';
import { LocalNotificationService } from 'app/components/local-notifications-service/local-notifications-service';

import { LocaleService } from 'app/components/locale/locale.service';
import { removedialog } from './locale/locale';

@Component({
    selector   : 'remove-user-dialog',
    templateUrl: './remove-user-dialog.component.html',
    styleUrls  : ['./remove-user-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class RemoveUserDialogComponent {
  private objects: any[];
  private role: string;
  private executor = new DispatcherCloudExecutor()

  constructor (
    private localeService: LocaleService,
    private notifications: LocalNotificationService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<RemoveUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) _data: any,
  ) {
    this.localeService.loadLocale(removedialog);
    if (_data) {
      this.objects = _data.object;
      this.role = _data.role;
    }
  }

  confirmDeletion() {
    let promise = Promise.resolve();
    this.objects.forEach(user => {
      promise = promise.then(() => {
        if (user instanceof PersonWithUser && user.getUser()) {
          return this.executor.deleteUser(this.role, user.id).then(() => {
            return user.markDeleted();
          })
        } else {
          return user.markDeleted();
        }
      });
    });

    promise.then(() => {
      this.dialogRef.close(true);
    }).catch(error => {
      console.log(error);
      let msg = this.translate.instant('REMOVE_USER_DIALOG.ERROR');
      this.notifications.showErrorNotification(msg);
    });
  }
}
