import { Component, EventEmitter, Input, Output, ElementRef, ViewChild, Inject, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { TreeNode } from './tree-node.model';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-hierarchical-checkbox',
  templateUrl: './hierarchical-checkbox.component.html',
  styleUrls: ['./hierarchical-checkbox.component.scss']
})
export class HierarchicalCheckboxComponent implements OnInit {
  nodes: TreeNode[] = [];
  private nodeChangeSubject = new Subject<TreeNode>();
  nodeChange$ = this.nodeChangeSubject.asObservable();

  @ViewChild('dropdownElement') dropdownElement!: ElementRef;
  isVisible: boolean = true;
  private isFocusedInside: boolean = true;

  constructor(
      public dialogRef: MatDialogRef<HierarchicalCheckboxComponent>,
      @Inject(MAT_DIALOG_DATA) public data?: {
        elementRef;
        treeData: any[];
      },
  ) {
    this.nodes = this.data.treeData || [];

    // Обработка клика вне модального окна
    this.dialogRef.backdropClick().subscribe(() => {
      this.dialogRef.close(this.nodes); // Закрываем модальное окно и возвращаем текущие данные
    });
  }

  ngOnInit() {
    // Проверяем, что элемент был передан
    if (this.data.elementRef) {
      const rect = (this.data.elementRef as HTMLElement).getBoundingClientRect();

      // Устанавливаем положение диалога
      const dialogElement = document.querySelector('.mat-dialog-container') as HTMLElement;
      if (dialogElement) {
        dialogElement.style.position = 'absolute';

        // Проверяем, находится ли элемент левее центра экрана
        let leftPosition;
        if (rect.left < window.innerWidth / 2) {
          // Если элемент находится левее центра, располагаем окно справа от него
          leftPosition = rect.left;
        } else {
          leftPosition = rect.left - 360;
        }

        dialogElement.style.top = `${rect.bottom}px`;
        dialogElement.style.left = `${leftPosition}px`;
        dialogElement.style.padding = `0px`;
        dialogElement.style.width = `auto`;
        dialogElement.style.height = `auto`;
        dialogElement.style.background = 'none';
        dialogElement.style.boxShadow = 'none';
      }
    }
    this.nodes = this.data.treeData;
  }

  // Метод для обработки клика по чекбоксу родителя
  onParentCheckChange(node: TreeNode) {
    const isChecked = node.checked;
    if (isChecked) {
      this.selectAllChildren(node);
    } else {
      this.deselectAllChildren(node);
    }
    this.emitChange(node);
  }

  // Метод для обработки клика по чекбоксу ребенка
  onChildNodeChange(child: TreeNode, parent: TreeNode) {
    parent.checked = parent.children?.every(child => child.checked) || false;
    this.emitChange(parent);
  }

  // Метод для выделения всех детей родителя
  selectAllChildren(node: TreeNode) {
    if (node.children) {
      node.children.forEach(child => {
        child.checked = true;
        this.onChildNodeChange(child, node);
      });
    }
  }

  // Метод для отмены выбора всех детей родителя
  deselectAllChildren(node: TreeNode) {
    if (node.children) {
      node.children.forEach(child => {
        child.checked = false;
        this.onChildNodeChange(child, node);
      });
    }
  }

  openDropdown() {
    this.isVisible = true;
    setTimeout(() => {
      this.dropdownElement.nativeElement.focus();
    }, 0);
  }

  // Метод для отправки изменения узла через Subject
  private emitChange(node: TreeNode) {
    this.nodeChangeSubject.next(node);
  }

  onFocusIn() {
    this.isFocusedInside = true;
  }

  onFocusOut(event: FocusEvent) {
    setTimeout(() => {
      if (!this.isFocusedInside) {
        this.isVisible = false;
      }
    }, 0);
    this.isFocusedInside = false;
  }
}