import { Component, ViewChild } from '@angular/core';

import { MessagesTableData } from './messages.tabledata';

import { TableComponent, TableOptions } from 'app/components/table/table.component';

import { TranslateService } from '@ngx-translate/core';

import { LocaleService } from 'app/components/locale/locale.service';
import { messages } from './locale/locale';
import { default_row_operations, common } from '../../locale/locale';

import { TableData } from 'app/components/table/tabledata';
import { DialogPresenterService } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import { TableOperationsController } from 'app/components/table-operations-controller/table-operations-controller';
import { MessagesTableOperationsFactory } from './messages-table.operations-factory';


const locales = [
  messages,
  default_row_operations,
  common,
];

@Component({
  selector: 'messages-list',
  templateUrl: './messages-list.component.html',
})

export class MessagesListComponent {
  tableData: TableData;
  operationsController: TableOperationsController;
  @ViewChild(TableComponent) private table: TableComponent;
  
  headerButtons = [];

  constructor(
    private localeService: LocaleService,
    private translate: TranslateService,
    dialogPresenter: DialogPresenterService,
  ) {
    this.localeService.loadLocales(locales);
    this.tableData = new MessagesTableData(this.translate);

    let operationsFactory = new MessagesTableOperationsFactory(dialogPresenter, () => { this.reloadData() });
    this.operationsController = new TableOperationsController(operationsFactory);
    this.headerButtons = this.makeHeaderButtons();
  }

  updateOperations(table: TableComponent) {
    this.operationsController.updateOperations(table);
  }

  private makeHeaderButtons() {
    return [
      {
        'title': 'COMMON.BUTTONS.RELOAD',
        'action': () => this.reloadData()
      },
    ];
  }
  
  private reloadData() {
    this.table.reloadData();
  }
}
