import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { MaterialModule } from 'app/main/material.module';
import { VehicleModelDialog } from './vehicle-model-dialog.component';

import { CreateVehicleBrandModule } from '../vehicle_brand/vehicle-brand-dialog.module';
import { DialogHeaderModule } from 'app/components/dialogs/dialog-header/dialog-header.module';

@NgModule({
    declarations: [
        VehicleModelDialog,
    ],
    imports: [
        MaterialModule,
        TranslateModule,
        FuseSharedModule,
        CreateVehicleBrandModule,
    ],
    exports: [
        VehicleModelDialog,
        DialogHeaderModule,
    ]
})

export class CreateVehicleModelModule
{
}
