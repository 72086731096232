export const locale = {
  'EXPORT_QUEUE': {
    'TABLEROW': {
      'TYPE': 'Тип',
      'DESCRIPTION': 'Описание',
      'REASON': 'Причина',
      'DATE': 'Дата',
    },
    'TITLE': 'Очередь экспорта',
    'ERROR': {
      'UNDEFINED': 'Неопределенная ошибка',
      'InvalidReponse': 'Неправильный ответ сервера',
      'ExternalAPIError': 'Ошибка внешнего API',
      'NotSavedOnExternal': 'Не сохранено на внешнем сервере',
      'FileDownloadFailed': 'Ошибка загрузки файла',
      'NOT_SAVED_DESCRIPTION': "'{{value}}' еще не сохранен в 1с",
    },
    'NO_DESCRIPTION': 'Нет описания',
    'CLASS_NAME': {
      'Order': 'Заказ',
      'Driver': 'Водитель',
      'Vehicle': 'Транспорт',
      'Trailer': 'Прицеп',
      'Customer': 'Покупатель',
      'UnloadingPoint': 'Точка разгрузки',
    },
  }
};
