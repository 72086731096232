
export const locale = {
  "Settings": {
    "Title": "Настройки",
    "TimeIntervalInMinutes": "Интервал времени, мин",
    "TimeIntervalInHours": "Интервал времени, ч",
    "Order": {
      "Label": "Заказы",
      "TimeoutDescription": "Таймаут на принятие заказа водителем:",
    },
    "TransportUnit": {
      "Label": "Экипажи",
      "InactivityTimeIntervalDescription": "Автоматическое освобождение после отсутствия связи:",
    },
    "TripDelay": {
      "Label": "Задержки на этапах заказа",
      "TimeIntervalDescription": "Переход на \"{{stage}}\":",
    },
    "TripStoppage": {
      "Label": "Простой на этапе заказа",
      "TimeIntervalDescription": "Допустимое время простоя:",
    },
    "MaxDistance": {
      "Label": "Максимальная разница",
      "Description": "Максимальная разница в расстояниях между заданными точками погрузки/разгрузки и реальными",
      "DistanceInKilometers": "Расстояние, км",
    },
    "SuppliersOrder": {
      "Label": "Порядок поставщиков",
      "Description": "Порядок, в котором указаны поставщики",
      "ChangeOrder": "Изменить порядок",
    },
    "Apply": "Применить",
  },
};
