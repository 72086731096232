
<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="primary" 
                 title="{{'DIALOG.DRIVER.CREATE.TITLE' | translate}}">
  </dialog-header>
  
  <div class="pt-8 px-16">
    <form class="mb-10" [formGroup]="form">
      <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">
        <mat-form-field fxFlex>
          <input matInput
                 placeholder="{{'DIALOG.DRIVER.FULLNAME' | translate}}"
                 formControlName="name"
                 required
                 cdkFocusInitial>
        </mat-form-field>
      </div>

      <div *ngIf="isCarrierVisible()">
        <mat-form-field fxFlex>
          <mat-select placeholder="{{'DIALOG.DRIVER.CARRIER_SELECT' | translate}}"
                      formControlName="carrier"
                      [compareWith]="compareFunction"
                      required>
            <mat-option *ngFor='let carrier of carriersLoader.getLoadedItems()' [value]="carrier">{{carrier.getName()}}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('carrier').invalid">{{'DIALOG.DRIVER.ERROR.NO_CARRIER' | translate}}</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-form-field fxFlex>
          <mat-select placeholder="{{'DIALOG.VEHICLE.MODEL_SELECT' | translate}}"
                      formControlName="model"
                      [compareWith]="compareFunction"
                      required
                      cdkFocusInitial>
            <mat-option *ngFor='let model of modelsLoader.getLoadedItems()' [value]="model">{{model.fullName()}}</mat-option>
          </mat-select>
        </mat-form-field>
        <button type="button" mat-icon-button (click)="showDialog()"><mat-icon>add</mat-icon></button>
      </div>

      <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">
        <mat-form-field fxFlex='45'>
          <input matInput
                 placeholder="{{'DIALOG.VEHICLE.NUMBER' | translate}}"
                 formControlName="vehicleNumber"
                 required>
          <mat-error *ngIf="form.get('vehicleNumber').hasError('noMatchToRegEx') || form.get('vehicleNumber').invalid">
            {{'DIALOG.VEHICLE.ERROR.INVALID_NUMBER' | translate}}
          </mat-error>
          <mat-hint *ngIf="!form.get('vehicleNumber').value || !form.get('vehicleNumber').hasError('noMatchToRegEx')" align="end">
            {{'DIALOG.VEHICLE.HINT.VALID_NUMBER' | translate}}
          </mat-hint>
        </mat-form-field>

        <mat-form-field fxFlex='45'>
          <input matInput
                 type="number"
                 class="no-spinners"
                 placeholder="{{'DIALOG.VEHICLE.TONNAGE' | translate}}"
                 formControlName="vehicleTonnage"
                 required>
          <mat-error *ngIf="(form.get('vehicleTonnage').hasError('min') || form.get('vehicleTonnage').hasError('max')) || form.get('vehicleTonnage').invalid">
            {{'DIALOG.VEHICLE.ERROR.INVALID_TONNAGE' | translate}}
          </mat-error>
          <mat-hint *ngIf="!(form.get('vehicleTonnage').hasError('min') || form.get('vehicleTonnage').hasError('max'))" align="end">
            1-99
          </mat-hint>
        </mat-form-field>
      </div>

      <div class="mt-20" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">
        <mat-form-field fxFlex='45'>
          <input matInput
                 placeholder="{{'DIALOG.TRAILER.NUMBER' | translate}}"
                 formControlName="trailerNumber">
          <mat-error *ngIf="form.get('trailerNumber').hasError('noMatchToRegEx')">
            {{'DIALOG.TRAILER.ERROR.INVALID_NUMBER' | translate}}
          </mat-error>
          <mat-hint *ngIf="!form.get('trailerNumber').value || !form.get('trailerNumber').hasError('noMatchToRegEx')" align="end">
            {{'DIALOG.TRAILER.HINT.VALID_NUMBER' | translate}}
          </mat-hint>
        </mat-form-field>

        <mat-form-field fxFlex='45'>
          <input matInput
                 type="number"
                 class="no-spinners"
                 placeholder="{{'DIALOG.TRAILER.TONNAGE' | translate}}"
                 formControlName="trailerTonnage">
          <mat-error *ngIf="(form.get('trailerTonnage').hasError('min') || form.get('trailerTonnage').hasError('max'))">
            {{'DIALOG.TRAILER.ERROR.INVALID_TONNAGE' | translate}}
          </mat-error>
          <mat-hint *ngIf="!(form.get('trailerTonnage').hasError('min') || form.get('trailerTonnage').hasError('max'))" align="end">
            0-99
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="mt-20">
        <attachment #attachment></attachment>
      </div>

      <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button
                color="accent"
                class="submit-button"
                [disabled] = "inProgress"
                (click)='checkAndSave()'>{{'DIALOG.DRIVER.CREATE.CONFIRMBUTTON' | translate}}
        </button>
      </div>
    </form>
  </div>
</div>
