import { TableRowData, RowParameters } from 'app/components/table/tabledata';
import { EquipmentHelper } from 'app/components/helpers/equipment.helper';
import { Order, OrderStatus } from 'app/components/data/core/models/order/order';
import { TranslateService } from '@ngx-translate/core';

import { DateHelper } from 'app/components/helpers/date.helper';
import { OrderHelper } from 'app/components/helpers/order.helper';

export namespace ProposalColumns {
    export function makeColumnTime(): TableRowData {
        return new TableRowData({
            key: 'time',
            header: 'PROPOSALS.TABLEROW.TIME',
            cell: (order: Order) => `${getTime(order)}`,
            headerCellStyle: () => makeStyle(80),
            cellStyle: () => makeStyle(80),
        });
    }

    export function makeColumnCount(): TableRowData {
        return new TableRowData({
            key: 'count',
            header: 'PROPOSALS.TABLEROW.COUNT',
            cell: (order: Order) => `${order.tonnage()}`,
            headerCellStyle: () => makeStyle(80),
            cellStyle: () => makeStyle(80),
        });
    }

    export function makeColumnCustomer(translate: TranslateService): TableRowData {
        return new TableRowData({
            key: 'customer',
            header: 'ORDER.TABLEROW.CUSTOMER',
            cell: (order: Order) => getCustomerName(order, translate),
            cellClass: (order: Order) => { return getCustomerCellClass(order) },
            params: new RowParameters('12')
        });
    }

    export function makeColumnLoadingAddress(): TableRowData {
        return new TableRowData({
            key: 'loading_address',
            header: 'PROPOSALS.TABLEROW.LOADING_ADDRESS',
            cell: (order: Order) => `${order.loadingPoint()?.getAddress() ?? ''}`,
            params: new RowParameters('15')
        });
    }

    export function makeColumnUnloadingAddress(): TableRowData {
        return new TableRowData({
            key: 'unloading_address',
            header: 'PROPOSALS.TABLEROW.UNLOADING_ADDRESS',
            cell: (order: Order) => `${order.unloadingPoint()?.getAddress() ?? ''}`,
            params: new RowParameters('15')
        });
    }

    export function makeColumnEquipment(translate: TranslateService): TableRowData {
        return new TableRowData({
            key: 'equipment',
            header: 'ORDER.TABLEROW.REQUIRED_EQUIPMENT',
            cell: (order: Order) => EquipmentHelper.localizedEquipmentRequirementsList(
                order.unloadingPoint(),
                translate
            ),
            headerCellStyle: () => makeStyle(),
            cellStyle: () => makeStyle(),
            params: new RowParameters('10')
        });
    }

    export function makeColumnCarrier(): TableRowData {
        return new TableRowData({
            key: 'carrier',
            header: 'PROPOSALS.TABLEROW.CARRIER',
            cell: (order: Order) => `${getCarrierName(order)}`,
            cellStyle: (order: Order) => getCarrierCellStyle(order),
            params: new RowParameters('10')
        })
    }

    export function makeColumnComment(): TableRowData {
        return new TableRowData({
            key: 'comment',
            header: 'PROPOSALS.TABLEROW.COMMENT',
            cell: (order: Order) => order.comment(),
        })
    }

    export function makeColumnInfo(translate: TranslateService): TableRowData {
        return new TableRowData({
            key: 'info',
            header: 'PROPOSALS.TABLEROW.INFO',
            cell: (order: Order) => `${getInfo(order, translate)}`,
            cellStyle: (order: Order) => getCarrierOfferCellStyle(order),
            params: new RowParameters('12', true),
        })
    }

    export function makeColumnBrand(): TableRowData {
        return new TableRowData({
            key: 'brand',
            header: 'PROPOSALS.TABLEROW.BRAND',
            cell: (order: Order) => order.articleBrand().name(),
            params: new RowParameters('10')
        })
    }

    export function makeColumnReserve(translate: TranslateService): TableRowData {
        return new TableRowData({
            key:  'reserved',
            header: 'PROPOSALS.TABLEROW.RESERVE',
            cell: (order: Order) => reserverString(order, translate),
          })
    }

    export function makeColumnDistance(): TableRowData {
        return new TableRowData({
            key: 'distance',
            header: 'PROPOSALS.TABLEROW.DISTANCE',
            cell: (order: Order) => `${order.distance() ?? ''}`,
            headerCellStyle: () => makeStyle(80),
            cellStyle: () => makeStyle(80),
        });
    }

    function getTime(order: Order) {
        let date = order.unloadingBeginDate();
        return DateHelper.timeString(date);
    }

    function getCustomerCellClass(order: Order) {
        if (order.customer()) return null;
        return {
            'warn': true,
        }
    }

    function getCustomerName(order: Order, translate: TranslateService) {
        return order.customer()?.getName() ?? translate.instant('PROPOSALS.STOCK');
    }

    function getCarrierName(order: Order) {
        let carriers = order.getCarriers();
        let name = "";
        if (carriers && carriers.length > 0) {
            name = carriers[0].getName();
        }
        return name;
    }

    function getCarrierCellStyle(order: Order) {
        let carriers = order.getCarriers();
        let color;
        if (carriers && carriers.length > 0) {
            color = carriers[0].getMarkColor();
        }
        return {
            'background': color,
            'padding-left': '8px',
            'padding-right': '8px',
        };
    }

    function getInfo(order: Order, translate: TranslateService) {
        var status
        if (order.status() === OrderStatus.READY) {
            status = translate.instant('PROPOSALS.ORDER_NUMBER', { value: order.number() });
        } else {
            status = translate.instant('PROPOSALS.PREORDER_NUMBER', { value: order.number() });
        }
        if (order.offers() || order.getCarrierOffers()) {
            status += "<br>";
            status += OrderHelper.orderStatus(order, translate);
        }
        return status;
    }

    function getCarrierOfferCellStyle(order: Order) {
        let offers = order.getCarrierOffers()
        if (offers && offers.length > 0) {
            let offer = offers[0];
            if (offer.isAccepted() == false) {
                return {
                    'color': "red",
                }
            }
        }
        return null;
    }

    function reserverString(order: Order, translate: TranslateService) {
        let reserveStatus = OrderHelper.isReserved(order) ? 'PROPOSALS.RESERVE_STATUS.RESERVED' : 'PROPOSALS.RESERVE_STATUS.NOT_RESERVED';
        return translate.instant(reserveStatus);
    }

    function makeStyle(width: number = 0) {
        var style = {
            'justify-content': 'center',
        }
        if (width > 0) {
            style['max-width'] = `${width}px`
        }
        return style;
    }

}