<div id="login" fxLayout="column">

    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="login-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img src="assets/images/logos/cargodeal.svg">
            </div>

            <div class="title">{{"Title" | translate}}</div>

            <form name="loginForm" [formGroup]="loginForm" novalidate>

                <mat-form-field>
                    <input #nameInput matInput placeholder="{{'Username' | translate}}" formControlName="name">
                    <mat-error *ngIf="loginFormErrors.name.required">
                        {{"UsernameError" | translate}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <input matInput type="password" placeholder="{{'Password' | translate}}" formControlName="password">
                    <mat-error *ngIf="loginFormErrors.password.required">
                        {{"PasswordError" | translate}}
                    </mat-error>
                </mat-form-field>

                <button (click) = "onLoginClicked()" mat-raised-button color="accent" class="submit-button"
                        [disabled]="loginForm.invalid || loginForm.disabled">
                    {{"LogIn" | translate}}
                </button>

                <div *ngIf = "error" class="error-message">{{"LogInError" | translate}}</div>

            </form>

        </div>

    </div>

</div>
