import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {FuseSharedModule} from '@fuse/shared.module';

import {UserRoleGuard} from 'app/guards/user-role-guard.service';

import {OrdersListModule} from './subcat/list/list.module';
import {OrdersInProgressModule} from './subcat/inprogress/inprogress.module';
import {FinishedOrdersListModule} from './subcat/finished/finished-orders.module';

import {OrdersInProgressComponent} from './subcat/inprogress/inprogress.component';
import {OrdersListComponent} from './subcat/list/list.component';
import {FinishedOrdersListComponent} from './subcat/finished/finished-orders.component';

import {OrderDialogModule} from 'app/main/orders/dialog/order/order-dialog.module';

import {SupplierDialogModule} from 'app/main/admin/users/dialogs/supplier/supplier-dialog.module';
import {CustomerDialogsModule} from 'app/main/admin/users/dialogs/customer/customer-dialogs.module';

import {CustomerOrderDialogsModule} from './dialog/customer/customer-order-dialogs.module';
import {AllComponent} from './subcat/all/all.component';
import {AllModule} from './subcat/all/all.module';

const routes: Routes = [
    {
        path: 'orders',
        canActivateChild: [UserRoleGuard],
        children: [
            {
                path: 'all',
                component: AllComponent,
            },
            {
                path: 'list',
                component: OrdersListComponent
            },
            {
                path: 'inprogress',
                component: OrdersInProgressComponent
            },
            {
                path: 'finished',
                component: FinishedOrdersListComponent
            },
            {
                path: '',
                redirectTo: 'list',
                pathMatch: 'full'
            }
        ]
    }
];

@NgModule({
    providers: [
        UserRoleGuard
    ],
    imports: [
        RouterModule.forChild(routes),

        FuseSharedModule,
        AllModule,
        OrdersListModule,
        OrdersInProgressModule,
        FinishedOrdersListModule,
        OrderDialogModule,
        SupplierDialogModule,
        CustomerDialogsModule,
        CustomerOrderDialogsModule,
    ],
})

export class OrdersModule {
}
