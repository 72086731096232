export const locale = {
  'ORDER': {
    'TABLEROW': {
      'INPOINT': 'In point',
      'OUTPOINT': 'Out point',
      'WEIGHT': 'Weight',
      'USERNAME': 'Author',
      'CREATEDATE': 'Created',
      'STATUS': 'Status',
      'BRAND': 'Brand',
      'CUSTOMER': ' Customer',
      'UNLOAD_DATE': 'Unload date',
      'REQUIRED_EQUIPMENT': 'Equipment requirements',
      'COMMENT':'Comment',
    },
    'STATUS': {
      'IN_WORK': 'In work',
      'IN_WORK_BY_USER': "In work for '{{user}}'",
      'NOT_AGREE': "Not agree",
      'AGREE': "Agreed",
      'CARRIER': {
        'ACCEPTED' : 'Accepted',
        'DECLINED' : 'Declined',
        'UNACCEPTED' : 'Unaccepted',
      },
      'CUSTOMER': {
        'PROCESSING': 'Processing',
        'HANDED_TO_DRIVER': 'Handed to driver',
        'ACCEPTED_BY_CARRIER': 'Accepted by carrier',
      },
    },
  },
};
