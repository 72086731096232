import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { PFQuery } from 'app/components/data/core/models/base/query';
import { PFObject } from 'app/components/data/core/models/base/object';

import { Vehicle, Trailer, VehicleModel } from 'app/components/data/core/models/transport/transport';

import { Driver } from 'app/components/data/core/models/persons/driver';
import * as FormHelper from 'app/components/form-helper/form-helper';

import { AttachmentComponent } from 'app/components/attachment/attachment.component';

import { VehicleModelDialog } from 'app/main/admin/vehicles/dialogs/vehicle_model/vehicle-model-dialog.component';
import { DialogPresenterService } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';

import * as CustomValidators from 'app/components/validators/validators';
import { CurrentUser } from 'app/components/data/core/models/base/user';
import { DataLoader } from 'app/components/data/data-loader';
import { DispatcherCloudExecutor } from 'app/components/data/cloud-executors/dispatcher-cloud-executor';

import { LocaleService } from 'app/components/locale/locale.service';
import { create_driver } from 'app/main/admin/users/locale/locale';
import { vehicle, trailer } from 'app/main/admin/vehicles/locale/locale';
import { LocalNotificationService } from 'app/components/local-notifications-service/local-notifications-service';
import { TranslateService } from '@ngx-translate/core';
let locales = [
  create_driver,
  vehicle,
  trailer,
];

@Component({
  selector: 'driver-create-dialog',
  templateUrl: './driver-create-dialog.component.html',
  styleUrls: ['./driver-create-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DriverCreateDialog {
  compareFunction = PFObject.compareFn;

  public form: UntypedFormGroup;

  private driver: Driver;
  private vehicle: Vehicle;
  private trailer: Trailer;

  carriersLoader: DataLoader;
  modelsLoader: DataLoader;
  executor = new DispatcherCloudExecutor;

  public inProgress: boolean = false;

  @ViewChild('attachment') attachment: AttachmentComponent;

  constructor(
    public dialogRef: MatDialogRef<DriverCreateDialog>,
    private _formBuilder: UntypedFormBuilder,
    private localeService: LocaleService,
    private dialogPresenterService: DialogPresenterService,
    private translate: TranslateService,
    private notifications: LocalNotificationService
  ) {
    this.localeService.loadLocales(locales);
    this.carriersLoader = new DataLoader('Carrier');
    this.modelsLoader = new DataLoader('VehicleModel');
    this.modelsLoader.setQueryModificationBlock((query) => {
      query.include('brand');
    });

    this.driver = new Driver();
    this.vehicle = new Vehicle();
    this.form = this.createContactForm();
  }

  ngOnInit() {
    this.loadModels();
    if (this.isCarrierVisible()) {
      this.carriersLoader.loadAll();
    }
  }

  private loadModels() {
    this.modelsLoader.loadAll();
  }

  isCarrierVisible(): boolean {
    return CurrentUser.isAdministrator() || CurrentUser.isLogistician() || CurrentUser.isManager();
  }

  createContactForm(): UntypedFormGroup {
    let formGroup = {
      name: null,
      carrier: null,
      model: null,
      vehicleNumber: null,
      vehicleTonnage: [0, [Validators.min(1), Validators.max(99)]],
      trailerNumber: null,
      trailerTonnage: [0, [Validators.min(0), Validators.max(99)]],
    };
    return this._formBuilder.group(formGroup);
  }

  private createVehicle(): Promise<any> {
    let number = this.form.controls.vehicleNumber.value;
    this.vehicle.setModel(this.form.controls.model.value);
    this.vehicle.setNumber(number);
    this.vehicle.setTonnage(this.form.controls.vehicleTonnage.value);
    if (CurrentUser.isDispatcher()) {
      this.vehicle.setCarrier(CurrentUser.carrier())
    } else if (this.isCarrierVisible()) {
      this.vehicle.setCarrier(this.form.controls.carrier.value);
    }
    return this.checkTransport('Vehicle', this.vehicle)
      .then(() => {
        return this.executor.saveObject(this.vehicle);
      })
      .catch(error => {
        var msg = 'DIALOG.VEHICLE.ERROR.';
        if (!error) {
          msg += 'VEHICLE_EXISTS';
        } else {
          msg += 'UNDEFINED'
        }
        return Promise.reject(this.translate.instant(msg))
      });
  }

  private createTrailer(): Promise<any> {
    let trailerNumber = this.form.controls.trailerNumber.value;
    if (trailerNumber && trailerNumber.length > 0) {
      this.trailer = new Trailer();
      this.trailer.setNumber(trailerNumber);
      this.trailer.setTonnage(this.form.controls.trailerTonnage.value);
      if (CurrentUser.isDispatcher()) {
        this.trailer.setCarrier(CurrentUser.carrier())
      } else if (this.isCarrierVisible()) {
        this.trailer.setCarrier(this.form.controls.carrier.value);
      }
      return this.checkTransport('Trailer', this.trailer)
        .then(() => {
          return this.executor.saveObject(this.trailer);
        })
        .catch(error => {
          var msg = 'DIALOG.TRAILER.ERROR.';
          if (!error) {
            msg += 'TRAILER_EXISTS';
          } else {
            msg += 'UNDEFINED'
          }
          return Promise.reject(this.translate.instant(msg))
        });
    }
    return Promise.resolve();
  }

  private createDriver(): Promise<any> {
    this.driver.setName(this.form.controls.name.value);
    if (CurrentUser.isDispatcher()) {
      this.driver.setCarrier(CurrentUser.carrier())
    } else if (this.isCarrierVisible()) {
      this.driver.setCarrier(this.form.controls.carrier.value);
    }
    return this.executor.saveObject(this.driver);
  }

  private saveAttachments(): Promise<any> {
    return this.attachment.uploadAttachments();
  }

  private addAttachments(attachedFiles) {
    if (attachedFiles && attachedFiles.length > 0) {
      attachedFiles.forEach(obj => {
        this.driver.attachDocument(obj);
      });
      return this.executor.saveObject(this.driver);
    }
    return Promise.resolve();
  }

  private checkTransport(className, object) {
    let query = new PFQuery(className);
    query.contains('number', object.number());
    query.equalTo('carrier', object.carrier());
    return query.first().then((obj) => {
      if (obj) {
        return Promise.reject();
      } else {
        return Promise.resolve();
      }
    });
  }

  createUnit(): Promise<any> {
    return this.executor.makeTransportUnit(this.driver, this.vehicle, this.trailer);
  }

  checkAndSave() {
    if (this.checkForm()) {
      this.inProgress = true;
      this.createVehicle()
        .then(() => this.createTrailer())
        .then(() => this.createDriver())
        .then(driver => {
          this.driver = driver;
          return this.saveAttachments();
        })
        .then((attachedFiles) => this.addAttachments(attachedFiles))
        .then(() => this.createUnit())
        .then(() => this.dialogRef.close(true))
        .catch(error => this.notifications.showErrorNotification(error))
        .finally(() => {
          this.inProgress = false;
        });
    }
  }

  showDialog() {
    let handler = this;
    let data = new Map<string, any>();
    data['action'] = 'create';
    this.dialogPresenterService.showDialog({
      dialog: VehicleModelDialog,
      panel: 'vehicle-model-dialog-container',
      data: data,
    }, function (model) {
      if (model) {
        handler.addModel(model);
      }
    });
  }
  private addModel(model: VehicleModel) {
    this.loadModels();
    this.form.controls.model.setValue(model);
  }

  checkForm(): boolean {
    return FormHelper.checkForm(this.form);
  }
}
