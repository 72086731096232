import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { TableHeaderComponent } from './tableheader.component'
import { MaterialModule } from 'app/main/material.module';

@NgModule({
    declarations: [
        TableHeaderComponent,
    ],
    imports     : [
        MaterialModule,
        TranslateModule,
        FuseSharedModule

    ],
    exports     : [
        TableHeaderComponent,
    ],
})

export class TableHeaderModule
{
}
