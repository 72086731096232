
export const data = {
  "ExcelExport": {
    "WorksheetTitle": "Отчет",
    "Fields": {
      "Number": "№",
      "Supplier" : "Поставщик",
      "LoadingDate": "Дата загрузки",
      "LoadingPoint": "Завод",
      "LoadingBrand": "Марка загрузки",
      "LoadingTonnage": "Тоннаж загрузки",
      "UnloadingDate": "Дата разгрузки",
      "Customer": "Покупатель",
      "UnloadingPoint": "Адрес объекта",
      "UnloadingBrand": "Марка разгрузки",
      "UnloadingTonnage": "Тоннаж разгрузки",
      "Intermediary": "Дилер",
      "Author": "Автор",
      "Carrier": "Перевозчик",
      "Driver": "Водитель по ТН",
      "VehicleNumber": "Номер авто",
      "TrailerNumber": "Номер прицепа",
      "Kilometers": "Километраж",
      "WaybillNumber": "Номер накладной",
      "SalePriceType": "Тип цены продажи",
      "SaleTariff": "Цена продажи",
      "DeliveryPriceType": "Тип цены доставки",
      "DeliveryTariff": "Цена доставки",
      "Sum": "Сумма",
    },
    "PriceType": {
      "OneTime": "Разовая",
      "NotOneTime": "Не разовая",
    },
  },
};
