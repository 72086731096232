export const locale = {
    'ALLORDERSLIST': {
        'TITLE': 'All orders',
        'DATE_FROM': 'from',
        'DATE_TO': 'to',
        'RESET': 'Reset',
        'ERROR': {
            'DATE_TO_LOW': 'End date lower then start date',
        },
        'TABLEROW': {
            'UNLOADED_TONNAGE': 'Unloaded tonnage',
            'LOAD_DATE': 'Load date',
            'UNLOAD_DATE': 'Unload date',
            'SUPPLIER': 'Supplier',
            'DRIVER': 'Driver',
        },
    },
    'VEHICLE.GROUP.ALL': 'All',
};
