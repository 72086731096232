import { TableData, TableRowData, RowParameters } from 'app/components/table/tabledata';

import { LocalDataSource } from 'app/components/table/data_sources/local-data-source';

import { Contact, Contactable } from 'app/components/data/core/models/persons/contact';

export class ContactsTableData extends TableData {

  constructor(
    private object: Contactable
  ) {
    super();
    let columns = [
      new TableRowData({
        key: 'name', 
        header:'DIALOG.CONTACTS.TABLEROW.NAME',
        cell: (contact: Contact) => `${contact.name}`, 
        params: new RowParameters('30')
      }),
      new TableRowData({
        key: 'phone',
        header:'DIALOG.CONTACTS.TABLEROW.PHONE',
        cell: (contact: Contact) => `${contact.phoneNumber}`
      }),
    ];

    this.setColumns(columns);

    let tableDataSource = new LocalDataSource(this.getContacts());
    this.setProvider(tableDataSource);
  }

  private getContacts() {
    let contacts = this.object.getContacts();
    if (contacts === undefined) {
      contacts = [];
    }
    return contacts;
  }
}
