import { Component, ViewChild } from '@angular/core';

import { LocaleService } from 'app/components/locale/locale.service';
import { proposals } from '../../locale/locale';
import { default_row_operations, common, orders, tripStage, offers, units, equipment } from '../../../locale/locale';
import { PFQuery } from 'app/components/data/core/models/base/query';
import { Order, OrdersFilter } from 'app/components/data/core/models/order/order';
import { Supplier } from 'app/components/data/core/models/persons/supplier';

import { PFObject } from 'app/components/data/core/models/base/object';
import { ArticleBrand } from 'app/components/data/core/models/articles/article';
import { ProposalsTableData } from './proposals-list.tabledata';
import { TranslateService } from '@ngx-translate/core';

import { DateHelper } from 'app/components/helpers/date.helper';
import { ProposalHelper } from '../../helpers/proposal-info.helper';
import { Group } from 'app/components/group-table/group-table.component';
import { DateSelectorComponent } from 'app/components/date-selector/date-selector.component';
import { defaultTableOptions, TableComponent, TableOptions } from 'app/components/table/table.component';
import { DialogPresenterService } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import { TableOperationsController } from 'app/components/table-operations-controller/table-operations-controller';

import { ProposalTableOperationsFactory } from '../../operations-factory/proposals.operation-factory';
import { PurchaseTariff } from 'app/components/data/core/models/tariff/tariff';

import { SearchHelper } from 'app/components/helpers/search.helper';

import { ProposalsLoader } from '../../proposals-loader';
import { DataLoader } from 'app/components/data/data-loader';
import { ConfigurationService } from 'app/main/admin/settings/configuration.service';
import { filterDeleted } from 'app/components/access/query';
import { QueryResultFilter } from 'app/components/access/queryResultFilter';
import { GroupHeaderItem } from '../common/header-item';

const locales = [
  proposals,
  common,
  default_row_operations,
  orders,
  tripStage,
  offers,
  units,
  equipment,
];

interface BrandGroup extends Group {
  name: string,
  headerItems: GroupHeaderItem[];
  brand: ArticleBrand
  orders: Order[]
  tableData: ProposalsTableData,
}

@Component({
  selector: 'proposals-list',
  templateUrl: './proposals-list.component.html',
  styleUrls: ['./proposals-list.component.scss']
})
export class ProposalsListComponent {
  compareFunction = PFObject.compareFn;

  initialDate = DateHelper.nextDay(DateHelper.setDayBegin(new Date()));
  
  selectedSupplier: Supplier;
  availableSuppliers: Supplier[];
  proposalGroups: BrandGroup[];
  footerItems = [];

  tableOptions: TableOptions;
  operationsController: TableOperationsController;

  private operationsFactory: ProposalTableOperationsFactory;
  private tariffsData = new DataLoader('PurchaseTariff');
  private suppliersData = new DataLoader('Supplier');
  private proposalsLoader = new ProposalsLoader();

  private lastSelectedTable: TableComponent;
  private searchFilter: string;
  
  @ViewChild(DateSelectorComponent) dateSelector: DateSelectorComponent;

  constructor(
    private localeService: LocaleService,
    private translate: TranslateService,
    private dialogPresenter: DialogPresenterService,
    private configService: ConfigurationService,
  ) {
    this.tariffsData.filterDeleted = false;

    this.localeService.loadLocales(locales);
    this.tableOptions = defaultTableOptions();
    this.tableOptions.hiddenPaginator = true;

    this.operationsFactory = new ProposalTableOperationsFactory(
      this.dialogPresenter, 
      this.translate, 
      ()=>this.makeDialogData());

    this.operationsController = new TableOperationsController(this.operationsFactory);
    this.tariffsData.setQueryModificationBlock((query: PFQuery) => {
      query.equalTo('supplier', this.selectedSupplier);
      return query;
    });

    this.suppliersData.setQueryModificationBlock((query: PFQuery) => {
      query.includes([
        'articleBrands.type',
        'loadingPoint',
        'supplier.loadingPoints.entrances',
      ]);
      return filterDeleted(query);
    });
  }

  ngOnInit() {
    this.load();
    this.proposalsLoader.items.subscribe((orders) => {
      if (orders) this.reloadContent();
    })
  }

  private async load() {
    await this.configService.fetch();
    await this.suppliersData.loadAll();
    let suppliers = this.suppliersData.getLoadedItems();
    let suppliersOrder = this.configService.getConfiguration().getSupplierOrder();
    this.availableSuppliers = PFObject.sort(suppliers, suppliersOrder);
    this.selectedSupplier = this.availableSuppliers[0];
    this.reloadOrders();
  }

  search(searchString: string) {
    this.searchFilter  = searchString;
    this.reloadContent();
  }

  addProposalInGroup(group: BrandGroup, event) {
    event.stopPropagation();
    this.operationsFactory.addProposal(this.selectedSupplier, group.brand, this.dateSelector.selectedDate);
  }

  isLoading(): boolean {
    return this.tariffsData.isLoading() || this.proposalsLoader.isLoading() || this.suppliersData.isLoading();
  }

  updateOperations(table: TableComponent) {
    if (this.lastSelectedTable  && this.lastSelectedTable != table) {
      this.lastSelectedTable.deselectAll();
    }
    this.lastSelectedTable = table;
    this.operationsController.updateOperations(table);
  }

  async reloadOrders() {
    this.updateOperations(null);
    await this.tariffsData.loadAll();
    let supplier = this.selectedSupplier;
    let selectedDate = this.dateSelector.selectedDate;
    let startDate = DateHelper.prevDay(DateHelper.setDayBegin(new Date(selectedDate)));
    let endDate = DateHelper.setDayEnd(new Date(selectedDate));
    this.proposalsLoader.setModifyBlock((query: PFQuery) => {
      query.equalTo("supplier", supplier);
      query.greaterThanOrEqualTo("unloadingBeginDate", startDate);
      query.lessThanOrEqualTo("unloadingBeginDate", endDate);
      return query;
    });
    this.proposalsLoader.reloadData();
  }

  private makeDialogData() {
    return {date: this.dateSelector.selectedDate, supplier: this.selectedSupplier};
  }

  private reloadContent() {
    let supplier = this.selectedSupplier;
    let selectedDate = this.dateSelector.selectedDate;
    let orders = this.proposalsLoader.items.getValue();
    let filteredTodayOrders = [];
    let brands = QueryResultFilter.filterDeleted(supplier.getBrands());
    let groups: BrandGroup[] = [];
    for (let brand of brands) {
      let name = brand.name();
      let headerColor = 'rgb(233,233,233)';
      if (brand.isDeleted()) {
        name += " (deleted)";
        headerColor = 'rgb(203,203,203)';
      }
      let ordersWithBrand = OrdersFilter.filterOrdersByBrand(orders, brand);
        let dayBeforeOrders = OrdersFilter.filterOrdersByUnloadingBeginDay(ordersWithBrand, DateHelper.prevDay(selectedDate));
        let remains = OrdersFilter.filterRemains(dayBeforeOrders);
        let currentDayOrders = OrdersFilter.filterOrdersByUnloadingBeginDay(ordersWithBrand, selectedDate);
        currentDayOrders = OrdersFilter.filterProposalsNotContainedText(currentDayOrders, this.searchFilter);
        if (!SearchHelper.isContained(name, this.searchFilter)) {
          currentDayOrders = OrdersFilter.filterProposalsNotContainedText(currentDayOrders, this.searchFilter);
          if (currentDayOrders.length === 0) continue;
        }
        groups.push({
          identifier: name,
          headerStyle: { 'background-color': headerColor},
          name: name,
          headerItems: this.makeHeader(currentDayOrders, remains.length),
          brand: brand,
          orders: currentDayOrders,
          tableData: new ProposalsTableData(currentDayOrders, this.translate),
          expandedByDefault: currentDayOrders.length > 0 
        });

        filteredTodayOrders = filteredTodayOrders.concat(currentDayOrders);
    }
    this.proposalGroups = groups;
    this.footerItems = this.footerItemsForOrders(filteredTodayOrders, this.tariffsData.getLoadedItems());
  }

  private makeHeader(orders: Order[], remains: number): GroupHeaderItem[] {
    let tonnage = ProposalHelper.totalTonnage(orders);
    return [
      {
        title: this.translate.instant("PROPOSALS.HEADER.PROPOSALS"),
        value: orders.length,
      },
      {
        title: this.translate.instant("PROPOSALS.HEADER.COUNT"),
        value: tonnage,
      },
      {
        title: this.translate.instant("PROPOSALS.HEADER.REMAINS"),
        value: remains,
      },
    ];
  }

  private footerItemsForOrders(orders, tariffs: PurchaseTariff[]) {
    return [
      {
        title: this.translate.instant("PROPOSALS.FOOTER.TOTAL"),
        value: orders.length,
      },
      {
        title: this.translate.instant("PROPOSALS.FOOTER.TOTAL_PRICE"),
        value: ProposalHelper.totalPrice(orders, tariffs),
      },
    ];
  }
}

