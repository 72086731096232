
import { Component, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';

import { TableComponent } from 'app/components/table/table.component';

import { VehicleBrandsTableData } from './tabledata/vehicle-brands-tabledata';
import { VehicleModelsTableData } from './tabledata/vehicle-models-tabledata';
import { VehiclesTableData } from './tabledata/vehicle-tabledata';
import { TrailersTableData } from './tabledata/trailers-tabledata';

import { DialogPresenterService } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';

import { CurrentUser } from 'app/components/data/core/models/base/user';

import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'app/components/locale/locale.service';
import { vehicles } from './locale/locale';
import { default_row_operations, common, approval, equipment, passes } from "app/main/locale/locale";
import { TabInfo } from 'app/components/tab/tab-info';

import { VehiclesTableOperationsFactory } from 'app/main/admin/vehicles/operations-factory/vehicles-table.operations-factory';
import { TrailersTableTypeOperationsFactory } from 'app/main/admin/vehicles/operations-factory/trailers-table.operations-factory';
import { VehicleBrandTableOperationsFactory } from 'app/main/admin/vehicles/operations-factory/vehicle-brand-table.operations-factory';
import { VehicleModelTableOperationsFactory } from 'app/main/admin/vehicles/operations-factory/vehicle-model-table.operations-factory';

const locales = [
  vehicles,
  default_row_operations,
  common,
  approval,
  equipment,
  passes,
];

@Component({
  selector: 'vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss']
})

export class VehiclesComponent {

  public administratorAccess: boolean;

  public tabsList: TabInfo[];
  headerButtons = [];

  @ViewChildren(TableComponent) tables: QueryList<TableComponent>;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  constructor(
    private translate: TranslateService,
    private localeService: LocaleService,
    private dialogPresenterService: DialogPresenterService
  ) {
    this.administratorAccess = CurrentUser.isAdministrator() || CurrentUser.isLogistician();
    this.localeService.loadLocales(locales);
    this.tabsList = this.createTabsList();
    this.headerButtons = this.makeHeaderButtons();
  }

  private createTabsList() {
    let tabsData = [];
    tabsData.push(new TabInfo({
      title: 'VEHICLES.TABS.VEHICLES',
      tableData: new VehiclesTableData(this.translate),
      operationsFactory: new VehiclesTableOperationsFactory(this.dialogPresenterService, () => { this.reloadCurrentTableData() }),
    }));
    tabsData.push(new TabInfo({
      title: 'VEHICLES.TABS.TRAILERS',
      tableData: new TrailersTableData(this.translate),
      operationsFactory: new TrailersTableTypeOperationsFactory(this.dialogPresenterService, () => { this.reloadCurrentTableData() }),
    }));
    if (CurrentUser.isAdministrator() || CurrentUser.isLogistician() || CurrentUser.isManager()) {
      tabsData.push(new TabInfo({
        title: 'VEHICLES.TABS.BRANDS',
        tableData: new VehicleBrandsTableData(),
        operationsFactory: new VehicleBrandTableOperationsFactory(this.dialogPresenterService, () => { this.reloadCurrentTableData() }),
      }));
      tabsData.push(new TabInfo({
        title: 'VEHICLES.TABS.MODELS',
        tableData: new VehicleModelsTableData(),
        operationsFactory: new VehicleModelTableOperationsFactory(this.dialogPresenterService, () => { this.reloadCurrentTableData() }),
      }));
    }
    return tabsData;
  }

  currentTable() {
    return this.tables.toArray()[this.tabGroup.selectedIndex];
  }

  tabChanged() {
    this.reloadCurrentTableData();
  }

  reloadCurrentTableData() {
    this.currentTable().reloadData();
  }

  search(searchString: string) {
    this.currentTable().search(searchString);
  }

  private makeHeaderButtons() {
    return [
      {
        'title': 'COMMON.BUTTONS.RELOAD',
        'action': () => this.reloadCurrentTableData()
      },
    ];
  }
}
