
export const locale = {
  lang: "ru",
  data: {
    "Download": {
      "Text": "Щелкните по ссылке, чтобы начать скачивание:",
      "Close": "Закрыть",
    }
  }
};
