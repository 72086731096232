import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { PFObject } from 'app/components/data/core/models/base/object';

import { LocaleService } from 'app/components/locale/locale.service';
import { create_message } from '../../locale/locale';

import * as FormHelper from 'app/components/form-helper/form-helper';
import { Message } from 'app/components/data/core/models/message';
import { DispatcherCloudExecutor } from 'app/components/data/cloud-executors/dispatcher-cloud-executor';
import { Role, User } from 'app/components/data/core/models/base/user';
import { Target, TargetsHelper } from '../../helpers/target.helper';
import { TranslateService } from '@ngx-translate/core';
import { DataLoader } from 'app/components/data/data-loader';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalNotificationService } from 'app/components/local-notifications-service/local-notifications-service';

let availableRoles = [
  Role.Customer,
  Role.Dispatcher,
  Role.Driver,
  Role.Manager,
  Role.Supplier,
]

@Component({
  selector: 'create-message-dialog',
  templateUrl: './create-message-dialog.component.html',
  styleUrls: ['./create-message-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CreateMessageDialog {
  compareFunction = PFObject.compareFn;

  public form: UntypedFormGroup;
  public targets: Target[];
  private object: Message;

  public dataLoader = new DataLoader(User);
  public executor = new DispatcherCloudExecutor();

  constructor(
    private localeService: LocaleService,
    public dialogRef: MatDialogRef<CreateMessageDialog>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private notifications: LocalNotificationService,
  ) {
    this.localeService.loadLocale(create_message);
    this.object = this._data.object;
    this.targets = this.makeTargets();
    this.form = this.createContactForm();
    this.dataLoader.filterDeleted = false;

    let includes = [
      'manager',
      'supplier',
      'driver',
      'customer',
      'dispatcher',
    ];

    this.dataLoader.setQueryModificationBlock((q) => {
      q.includes(includes);
      q.containedIn('role', availableRoles);
      q.ascending('role');
      return q;
    })

  }

  ngOnInit() {
    this.dataLoader.loadAll().then(() => {
      this.targets = this.makeTargets();
    });
  }

  createContactForm(): UntypedFormGroup {
    return this._formBuilder.group({
      title: [this.object ? this.object.getTitle() : null],
      body: [this.object ? this.object.getBody() : null],
      target: [this.object ? this.targetFromMessage(this.object) : null]
    });
  }

  private targetFromMessage(message: Message) {
    let role = message.getRole();
    let user = message.getUser();
    if (role) {
      return TargetsHelper.makeTargetForRole(role, this.translate);
    } else if (user) {
      return TargetsHelper.makeTargetForUser(user, this.translate);
    }
    return null;
  }

  checkAndSave() {
    let verified = this.checkForm();

    if (verified) {
      let target = this.form.controls.target.value;
      let title = this.form.controls.title.value;
      let body = this.form.controls.body.value;
      this.executor
        .sendMessage(target ? target.role : null, target ? target.userId : null, title, body)
        .then(() => {
          this.dialogRef.close(true);
        })
        .catch(() => {
          let localizedMSG = this.translate.instant('CREATE_MESSAGE.ERROR.UNDEFINED');
          this.notifications.showErrorNotification(localizedMSG);
        });
    }
  }

  checkForm(): boolean {
    return FormHelper.checkForm(this.form);
  }

  private makeTargets() {
    var targets = this.makeDefaultTargets();
    this.dataLoader.getLoadedItems().forEach((u: User) => {
      targets.push(TargetsHelper.makeTargetForUser(u, this.translate))
    });
    return targets;
  }

  private makeDefaultTargets() {
    var targets: Target[] = [];
    availableRoles.forEach(role => {
      targets.push({
        role: role,
        name: TargetsHelper.localizedTargetRole(role, this.translate)
      })
    });

    return targets;
  }
}

