export const locale = {
    'PROPOSALS': {
        'DIALOG': {
            'DECLINE' : {
                'TITLE': 'Decline proposals',
                'CONFIRMBUTTON': 'Decline',
                'MESSAGE': 'Are you sure you want to decline this proposals?',
                'TONNAGE': '{{value}} tonns',
            },
        },
    },
};
