
export const locale = {
  'DIALOG' : {
    'POINTS' : {
      'TITLE' : {
        'CREATE' : 'Создать адрес',
        'EDIT' : 'Изменить адрес',
      },
      'CONFIRMBUTTON' : {
        'CREATE' : 'Создать',
        'SAVE' : 'Сохранить',
      },
      'ADDRESS' : 'Адрес',
      'ENTRANCES' : 'Точки разгрузки',
      'TABLEROW' : {
        'NAME' : 'Название',
        'ADDRESS' : 'Адрес',
        'REQUIRED_EQUIPMENT': 'Требуемое оборудование',
      }
    },
  },
};
