import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { LocaleService } from 'app/components/locale/locale.service';
import { create_kilometers } from '../../locale/locale';

import * as FormHelper from 'app/components/form-helper/form-helper';
import { DispatcherCloudExecutor } from 'app/components/data/cloud-executors/dispatcher-cloud-executor';
import { TranslateService } from '@ngx-translate/core';
import { LocalNotificationService } from 'app/components/local-notifications-service/local-notifications-service';

import { Distance } from 'app/components/data/core/models/distance';

@Component({
  selector: 'edit-kilometers-dialog',
  templateUrl: './edit-kilometers-dialog.component.html',
  styleUrls: ['./edit-kilometers-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class EditKilometersDialog {
  
  public form: UntypedFormGroup;
  private object: Distance;

  public executor = new DispatcherCloudExecutor();

  constructor(
    private localeService: LocaleService,
    public dialogRef: MatDialogRef<EditKilometersDialog>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private notifications: LocalNotificationService,
  ) {
    this.localeService.loadLocale(create_kilometers);
    this.object = this._data.object;
    this.form = this.createContactForm();
  }

  createContactForm(): UntypedFormGroup {
    return this._formBuilder.group({
      distance: [this.object.distance()],
    });
  }

  checkAndSave() {
    let verified = this.checkForm();

    if (verified) {
      this.object.setDistance(this.form.controls.distance.value);
      this.executor
        .saveObject(this.object)
        .then(() => {
          this.dialogRef.close(true);
        })
        .catch(() => {
          let localizedMSG = this.translate.instant('DIALOG.KILOMETERS.ERROR.UNDEFINED');
          this.notifications.showErrorNotification(localizedMSG);
        });
    }
  }

  checkForm(): boolean {
    return FormHelper.checkForm(this.form);
  }
}

