import { TableRowData, RowParameters } from 'app/components/table/tabledata';
import { Vehicle, Transport } from 'app/components/data/core/models/transport/transport';
import { TranslateService } from '@ngx-translate/core';
import { EquipmentHelper } from 'app/components/helpers/equipment.helper';
import { PassesHelper } from 'app/components/helpers/passes.helper';
import { TransportHelper } from 'app/components/helpers/transport.helper';

export namespace TransportColumns {
    export function vehicleModelName(): TableRowData {
        return new TableRowData({
            key: 'fullmodelname',
            header: 'VEHICLES.TABLEROW.MODELNAME',
            cell: (vehicle: Vehicle) => `${vehicle.model().fullName()}`
        })
    }

    export function number(): TableRowData {
        return new TableRowData({
            key: 'number',
            header: 'VEHICLES.TABLEROW.NUMBER',
            cell: (transport: Transport) => `${transport.number()}`
        })
    }

    export function tonnage(): TableRowData {
        return new TableRowData({
            key: 'tonnage',
            header: 'VEHICLES.TABLEROW.TONNAGE',
            cell: (transport: Transport) => `${transport.tonnage()}`
        })
    }

    export function approved(translate: TranslateService): TableRowData {
        return new TableRowData({
            key: 'approved',
            header: 'VEHICLES.TABLEROW.APPROVE_STATUS',
            cell: (transport: Transport) => `${approvalStatusFor(transport, translate)}`,
            cellClass: (transport: Transport) => getApprovalCellState(transport)
        })
    }

    export function vehicleEquipment(translate: TranslateService): TableRowData {
        return new TableRowData({
            key: 'equipment',
            header: 'VEHICLES.TABLEROW.EQUIPMENT',
            cell: (vehicle: Vehicle) => `${EquipmentHelper.localizedAvailableEquipmentList(vehicle, translate)}`,
        })
    }

    export function vehiclePasses(translate: TranslateService): TableRowData {
        return new TableRowData({
            key: 'passes',
            header: 'VEHICLES.TABLEROW.PASS',
            cell: (vehicle: Vehicle) => `${PassesHelper.localizedPassInfo(vehicle, translate)}`,
            params: new RowParameters('fill', true)
        })
    }

    export function inspection(): TableRowData {
        return new TableRowData({
            key: 'inspection',
            header: 'VEHICLES.TABLEROW.INSPECTION',
            cell: (transport: Transport) => `${dateString(transport.inspectionDate())}`,
            cellClass: (transport: Transport) => `${getInspectionCellClass(transport)}`,
        })
    }

    export function insurance(): TableRowData {
        return new TableRowData({
            key: 'insurance',
            header: 'VEHICLES.TABLEROW.INSURANCE',
            cell: (vehicle: Vehicle) => `${dateString(vehicle.insuranceDate())}`,
            cellClass: (vehicle: Vehicle) => `${getInsuranceCellClass(vehicle)}`,
        })
    }

    export function carrier(): TableRowData {
        return new TableRowData({
            key: 'carrier',
            header: 'VEHICLES.TABLEROW.CARRIER',
            cell: (transport: Transport) => `${transport.carrier() ? transport.carrier().getName() : ''}`
        })
    }

    function getApprovalCellState(transport: Transport) {
        if (!transport.approved()) {
            return 'warn-fg';
        }
        return null;
    }

    function approvalStatusFor(transport: Transport, translate: TranslateService) {
        let status = transport.approved() ? 'APPROVAL.STATUS.APPROVED' : 'APPROVAL.STATUS.NOT_APPROVED';
        return translate.instant(status);
    }

    function dateString(date: Date) {
        if (!date) return '';
        return date.toLocaleDateString();
    }

    function getInspectionCellClass(transport: Transport) {
        if (TransportHelper.isInspectionExpired(transport)) {
            return 'warn-fg';
        }
        return null;
    }

    function getInsuranceCellClass(vehicle: Vehicle) {
        if (TransportHelper.isInsuranceExpired(vehicle)) {
            return 'warn-fg';
        }
        return null;
    }
}