import { PFQuery } from 'app/components/data/core/models/base/query';
export namespace TransportQuerySearchFilter {
    export function addNumberFilter(query, number) {
        query.contains('number', number);
        return query;
    }

    export function carrierName(className, name) {
        let carrierQuery = new PFQuery('Carrier');
        carrierQuery.contains('name', name);
        let transportCarrierQuery = new PFQuery(className);
        transportCarrierQuery.matchesQuery('carrier', carrierQuery);
        return transportCarrierQuery;
    }
}