import { NgModule } from '@angular/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxFileHelpersModule } from 'ngx-file-helpers';

import { FinishOrderComponent } from './finish-order-dialog.component';
import { AlertModule } from 'app/components/alert/alert.module';
import { DisbandUnitModule } from 'app/main/auto/dialog/disbandconfirmation/disband-unit-dialog.module';
import { DialogHeaderModule } from 'app/components/dialogs/dialog-header/dialog-header.module';

@NgModule({
    declarations: [
        FinishOrderComponent,
    ],
    imports: [
        FuseSharedModule,
        MaterialModule,
        TranslateModule,
        NgxFileHelpersModule,
        AlertModule,
        DisbandUnitModule,
        DialogHeaderModule,
    ],
    exports: [
        FinishOrderComponent,
    ]
})

export class FinishOrderDialogModule
{
}
