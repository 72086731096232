<div class="carrier-wrapper">
  <div class="px-16" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxFlex fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="start center" fxFlex>
        <mat-icon>search</mat-icon>
        <input #searchbar (keyup)="searchStringChanged($event.target.value)"
          placeholder="{{'TRANSFER.SEARCH' | translate}}" fxFlex cdkFocusInitial>
        <mat-icon *ngIf="searchbar.value" (click)="searchbar.value = ''; searchStringChanged('');">close</mat-icon>
      </div>
      <mat-divider></mat-divider>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center">
      <button mat-icon-button (click)="reload()">
        <mat-icon class="s-20">refresh</mat-icon>
      </button>
    </div>
  </div>

  <div class="table-wrapper">
    <cd-table [tabledata]="tableData"
              [options]="tableOptions">
    </cd-table>
  </div>
</div>