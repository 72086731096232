export const locale = {
    'DIALOG': {
        'EDIT_PASSES': {
            'TITLE': 'Изменение пропусков',
            'CONFIRMBUTTON': 'Закрыть',
            
            'TABLEROW': {
                'ZONE': 'Вид пропуска',
                'TIME_OF_ACTION': 'Время действия',
                'DATES': 'Период действия',
                'NUMBER': 'Номер',
                'STATUS': 'Статус',
            }
        },
    },
};
