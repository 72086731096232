import { PFDeletableObject } from "./base/deletableObject";
import { Role, User } from "./base/user";

export class Message extends PFDeletableObject {

  constructor() { super("Message"); }

  getDate(): Date { return super.get('createdAt'); }
  getTitle(): string { return super.get('title'); }
  getBody(): string { return super.get('body'); }
  getRole(): Role { return super.get('role'); }
  getUser(): User { return super.get('user'); }

  private setTitle(title: string) { super.set('title', title); }
  private setBody(body: string) { super.set('body', body); }
  private setRole(role: string) { super.set('role', role); }
  private setUser(user: User) { super.set('user', user); }

  copy() {
    let copy = new Message();
    copy.setTitle(this.getTitle());
    copy.setBody(this.getBody());
    copy.setRole(this.getRole());
    copy.setUser(this.getUser());
    return copy;
  }
}
