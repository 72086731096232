import {locale as main_en} from "./en/locale.main";
import {locale as main_ru} from "./ru/locale.main";

export const main = [
  {
    lang: 'en',
    data: main_en
  },
  {
    lang: 'ru',
    data: main_ru
  },
];
