<div class="info-container pt-8 px-16 pb-20">
  <form [formGroup]="form">
    <div> 
       <mat-form-field class='no-padding info-input' fxFlex appearance="outline">
         <mat-label>{{'ORDER.FIELD_NAME.UNLOADING_ENTRANCE' | translate}}</mat-label>
         <textarea
             class="address-area"
             matInput
             cdkTextareaAutosize
             cdkAutosizeMinRows="1"
             cdkAutosizeMaxRows="2"
             formControlName="unloadingEntrance">
          </textarea>
       </mat-form-field>
    </div>

    <div> 
      <mat-form-field class='no-padding info-input' fxFlex appearance="outline">
       <mat-label>{{'ORDER.FIELD_NAME.UNLOADING_DATE' | translate}}</mat-label>
       <input matInput formControlName="unloadingDate">
     </mat-form-field>
   </div>

    <div fxLayout="column"
         fxLayoutAlign="center stretch"
         fxLayout.gt-xs="row"
         fxLayoutAlign.gt-xs="space-between center">
        <mat-form-field class='no-padding info-input' fxFlex="32" appearance="outline">
          <mat-label>{{'ORDER.FIELD_NAME.ARTICLE_TYPE' | translate}}</mat-label>
          <input matInput formControlName="articleType">
        </mat-form-field>

        <mat-form-field class='no-padding info-input' fxFlex="32" appearance="outline">
          <mat-label>{{'ORDER.FIELD_NAME.ARTICLE_BRAND' | translate}}</mat-label>
          <input matInput formControlName="articleBrand">
        </mat-form-field>

        <mat-form-field class='no-padding info-input' fxFlex="32" appearance="outline">
          <mat-label>{{'ORDER.FIELD_NAME.TONNAGE' | translate}}</mat-label>
          <input matInput formControlName="tonnage">
        </mat-form-field>
    </div>

    <div fxLayout="column"
         fxLayoutAlign="center stretch"
         fxLayout.gt-xs="row"
         fxLayoutAlign.gt-xs="space-between center">
        <mat-form-field class="no-padding info-input" appearance="outline" fxFlex="64">
          <mat-label>{{'ORDER.FIELD_NAME.MANAGER_NAME' | translate}}</mat-label>
          <input matInput formControlName="contactName">
        </mat-form-field>
      
        <mat-form-field class="no-padding info-input" appearance="outline" fxFlex="34">
          <mat-label>{{'ORDER.FIELD_NAME.MANAGER_PHONE' | translate}}</mat-label>
          <input matInput formControlName="contactPhone">
        </mat-form-field>
    </div>

    <div class="comment" *ngIf="order.comment()">
      <mat-form-field fxFlex>
        <textarea matInput
                  formControlName="comment"
                  placeholder="{{'ORDER.FIELD_NAME.COMMENT' | translate}}"></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
