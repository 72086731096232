import { Injectable } from '@angular/core';

import { OrderInfoDialogComponent } from 'app/main/orders/dialog/orderinfo/order-info-dialog.component';
import { OrderDialogComponent } from 'app/main/orders/dialog/order/order-dialog.component';
import { CancelOrderDialogComponent } from 'app/main/orders/dialog/cancelconfirmation/cancel-order-dialog.component';
import { DeclineOrderDialogComponent } from 'app/main/orders/dialog/decline/decline-order-dialog.component';
import { DeleteOrderDialogComponent } from 'app/main/orders/dialog/deleteconfirmation/delete-order-dialog.component'
import { TransferOrderDialogComponent } from 'app/main/orders/dialog/transfer/transfer-order-dialog.component';
import { FinishOrderComponent } from 'app/main/orders/dialog/finishorder/finish-order-dialog.component';

import { TranslateService } from '@ngx-translate/core';
import { DialogPresenterService } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';

import { Order, OrderStatus } from 'app/components/data/core/models/order/order';
import { MatDialog } from '@angular/material/dialog';
import { CurrentUser, Role } from 'app/components/data/core/models/base/user';
import { OrderHelper } from 'app/components/helpers/order.helper';
import { CancelCustomerOrderDialogComponent } from '../customer/cancel/cancel-customer-order-dialog.component';
import { CustomerOrderDialog } from '../customer/create/customer-order-dialog.component';
import { CustomerOrderInfoDialog } from '../customer/orderinfo/customer-order-info-dialog.component';

@Injectable()
export class OrdersDialogPresenterService extends DialogPresenterService {
  constructor(
    private translate: TranslateService,
    public dialog: MatDialog,
  ) { super(dialog) }

  showOrderInfo(order: Order) {
    let data = {
      dialog: CurrentUser.isCustomer() ? CustomerOrderInfoDialog : OrderInfoDialogComponent,
      panel: CurrentUser.isCustomer() ? 'customer-order-info-dialog-container' : 'order-info-dialog-container',
      data: {
        object: order,
        disableClose: false,
      }
    };
    this.showDialog(data);
  }

  askNewOrder(): void {
    let data = {
      dialog: CurrentUser.isCustomer() ? CustomerOrderDialog : OrderDialogComponent,
      panel: CurrentUser.isCustomer() ? 'customer-order-dialog-container' : 'order-dialog-container',
      data: {
        action: 'create'
      }
    };
    this.showDialog(data, null);
  }

  askCopyOrder(order: Order) {
    let data = {
      dialog: CurrentUser.isCustomer() ? CustomerOrderDialog : OrderDialogComponent,
      panel: CurrentUser.isCustomer() ? 'customer-order-dialog-container' : 'order-dialog-container',
      data: {
        object: order.copy(),
        action: 'create'
      }
    };
    this.showDialog(data, null);
  };

  askCancelOrder(orders: Order[], role = null) {
    let canBeCanceled;
    for (let order of orders) {
      if (canBeCanceled) break;
      if (role === Role.Customer) {
        let status = order.status();
        canBeCanceled = status == OrderStatus.CUSTOMER_REQUEST ||
          status == OrderStatus.IN_WORK;
      } else {
        canBeCanceled = !OrderHelper.isLoaded(order);
      }
    }
    if (canBeCanceled) {
      this.showCancelOrderDialog(orders);
    } else {
      let message;
      if (role == Role.Customer) {
        message = "ROWOPERATION.ERROR.CANCEL_CUSTOMER_ORDER"
      } else {
        message = "ROWOPERATION.ERROR.CANCEL_ORDER";
      }
      this.showAlert({
        title: this.translate.instant("ROWOPERATION.WARNING"),
        content: this.translate.instant(message),
      });
    }
  };

  askRemoveOrders(orders: Order[], force: boolean = false) {
    let data = {
      dialog: DeleteOrderDialogComponent,
      panel: 'delete-order-dialog-container',
      data: {
        object: orders,
        disableClose: true,
        forceDeleteOrders: force,
      }
    };
    this.showDialog(data);
  };

  askToTransfer(orders: Order[], completion = null) {
    let data = {
      dialog: TransferOrderDialogComponent,
      panel: 'transfer-order-dialog-container',
      data: {
        object: orders,
        disableClose: false,
      }
    };
    this.showDialog(data, completion);
  };

  askEditOrder(order: Order, completion = null) {
    let data = {
      dialog: CurrentUser.isCustomer() ? CustomerOrderDialog : OrderDialogComponent,
      panel: CurrentUser.isCustomer() ? 'customer-order-dialog-container' : 'order-dialog-container',
      data: {
        object: order,
        action: 'edit',
      }
    };
    this.showDialog(data, completion);
  };

  askToDecline(orders: Order[], completion = null) {
    var error;
    let userId = CurrentUser.user().id;
    for (let order of orders) {
      let authorId = order.author().id;
      if (authorId == userId) {
        error = 'ROWOPERATION.ERROR.OWNED_DECLINE';
        break;
      }
    }

    if (error) {
      this.showAlert({
        title: this.translate.instant("ROWOPERATION.WARNING"),
        content: this.translate.instant("ROWOPERATION.ERROR.OWNED_DECLINE"),
      });
    } else {
      this.showDeclineDialog(orders, completion);
    }
  }

  askToFinish(orders) {
    let firstOrder = orders[0];
    this.showFinishOrder(firstOrder);
  }

  private showFinishOrder(order: Order, completion = null) {
    let data = {
      dialog: FinishOrderComponent,
      panel: 'finish-order-dialog-container',
      data: {
        object: order
      }
    };
    this.showDialog(data, completion);
  }

  private showCancelOrderDialog(orders) {
    let data = {
      dialog: CurrentUser.isCustomer() ? CancelCustomerOrderDialogComponent : CancelOrderDialogComponent,
      panel: CurrentUser.isCustomer() ? 'cancel-customer-order-dialog-container' : 'cancel-order-dialog-container',
      data: {
        object: orders,
        disableClose: false,
      }
    };
    this.showDialog(data);
  }

  private showDeclineDialog(orders, completion) {
    let data = {
      dialog: DeclineOrderDialogComponent,
      panel: 'decline-order-dialog-container',
      data: {
        object: orders,
        disableClose: false,
      }
    };
    this.showDialog(data, completion);
  }
}
