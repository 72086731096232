
import { RowParameters, TableData, TableRowData } from 'app/components/table/tabledata';

import { CurrentUser } from 'app/components/data/core/models/base/user';
import { PFQuery } from 'app/components/data/core/models/base/query';
import * as AccessQueries from 'app/components/access/query';

import { TranslateService } from '@ngx-translate/core';
import { TransportUnit } from 'app/components/data/core/models/transport/transport';
import { QueryResultFilter } from 'app/components/access/queryResultFilter';
import { ServerTableDataSource } from 'app/components/table/data_sources/server-data-source';
import { LiveQuerySubscriber, LiveQueryModifier } from 'app/components/table/data_sources/live-query-subscriber';
import { PassesHelper } from 'app/components/helpers/passes.helper';

export class UnitInfoTableData extends TableData {

  constructor(
    private translate: TranslateService
  ) {
    super();
    let handler = this;
    let columns = [
      new TableRowData({
        key: 'auto',
        header: 'UNITS.TABLEROW.UNIT',
        cell: (unit: TransportUnit) => `${unit.vehicleName()}`
      }),
      new TableRowData({
        key: 'driver',
        header: 'UNITS.TABLEROW.DRIVER',
        cell: (unit: TransportUnit) => `${unit.driver().getName()}`
      }),
      new TableRowData({
        key: 'status',
        header: 'UNITS.TABLEROW.STATUS',
        cell: (unit: TransportUnit) => `${handler.statusForUnit(unit)}`
      }),
      new TableRowData({
        key: 'autos_number',
        header: 'UNITS.TABLEROW.UNITNUMBER',
        cell: (unit: TransportUnit) => `${unit.vehicle().number()}`
      }),
      new TableRowData({
        key: 'tonnage',
        header: 'UNITS.TABLEROW.TONNAGE',
        cell: (unit: TransportUnit) => `${unit.totalTonnage()}`
      }),
      new TableRowData({
        key: 'passes',
        header: 'UNITS.TABLEROW.PASS',
        cell: (unit: TransportUnit) => `${ PassesHelper.localizedPassInfo(unit.vehicle(), this.translate) }`,
        cellClass: (unit: TransportUnit) => `${ PassesHelper.getPassCellClass(unit.vehicle()) }`,
        params: new RowParameters('fill', true)
      }),
    ];

    this.setColumns(columns);

    let includeFields = ['driver', 'vehicle', 'trailer', 'vehicle.model.brand'];
    let tableDataSource = new ServerTableDataSource('TransportUnit', includeFields);
    tableDataSource.queryModify = function (query: PFQuery): PFQuery {
      query.equalTo("disbanded", false);
      if (CurrentUser.isDispatcher()) {
        AccessQueries.driverMatchesCurrentCarrier(query);
      }
      return query;
    };

    tableDataSource.queryFilter = (filter, query) => {
      let driverQuery = new PFQuery('Driver');
      driverQuery.contains('name', filter);
      query.matchesQuery('driver', driverQuery);
      
      let vehicleQuery = new PFQuery('Vehicle');
      vehicleQuery.contains('number', filter);
      let uQuery1 = new PFQuery('TransportUnit');
      uQuery1.matchesQuery('vehicle', vehicleQuery);

      return PFQuery.or(query, uQuery1);
    };

    let subscriber = new LiveQuerySubscriber('TransportUnit', includeFields);
    subscriber.liveQueryModifier = new LiveQueryModifier(
      function (query: PFQuery) {
        query.equalTo("disbanded", false);
        return query;
      },
      function (object: TransportUnit) {
        if (CurrentUser.isDispatcher() && !QueryResultFilter.transportUnitMatchesCurrentCarrier(object)) {
          return null;
        }
        return object;
      });
    tableDataSource.setLiveQuerySubscriber(subscriber);
    this.setProvider(tableDataSource);
  }

  statusForUnit(unit: TransportUnit) {
    return this.translate.instant(unit.statusStringKey());
  }

}
