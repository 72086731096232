
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { ArticlesComponent } from './articles.component';

import { MaterialModule } from 'app/main/material.module';

import { TableHeaderModule} from 'app/components/tableheader/tableheader.module'
import { TableModule } from 'app/components/table/table.module';

import { RemoveDialogModule } from 'app/components/dialogs/removedialog/remove-dialog.module'

import { CreateArticleTypeDialog } from 'app/main/admin/articles/dialogs/createtype/create-article-type-dialog.component'
import { CreateArticleBrandDialog } from 'app/main/admin/articles/dialogs/createbrand/create-article-brand-dialog.component'
import { CreateArticleShapeDialog } from 'app/main/admin/articles/dialogs/createshape/create-article-shape-dialog.component'
import { DialogHeaderModule } from 'app/components/dialogs/dialog-header/dialog-header.module';
import { ActionBarModule } from 'app/components/action-bar/action-bar.module';

@NgModule({
    declarations: [
        ArticlesComponent,
        CreateArticleTypeDialog,
        CreateArticleBrandDialog,
        CreateArticleShapeDialog,
    ],
    imports: [
        TranslateModule,
        FuseSharedModule,
        MaterialModule,
        TableHeaderModule,
        TableModule,
        RemoveDialogModule,
        DialogHeaderModule,
        ActionBarModule,
    ],
    exports: [
        ArticlesComponent,
        CreateArticleTypeDialog,
        CreateArticleBrandDialog,
        CreateArticleShapeDialog,
    ]
})
export class ArticlesModule {
}
