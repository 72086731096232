export const locale = {
  'ROWOPERATION': {
    'INFO': 'Info',
    'TRANSFER': 'Transfer',
    'CANCEL': 'Cancel',
    'DECLINE': 'Decline',
    'FINISH': 'Finish',
    'WARNING': 'Warning',
    'ERROR': {
      'TRANSFERRED': 'The is already transferred. Cancel transfer, beefore editing.',
      'NOT_OWNED_TRANSFER': "You can't change the order sent to you. You can only change your own orders",
      'NOT_OWNED_REMOVE': "You can't remove an order that you did not create",
      'OWNED_DECLINE': "You can't decline an order that you did create",
      'CANCEL_ORDER': 'You can only cancel an order if it has not been loaded',
      'CANCEL_CUSTOMER_ORDER': 'The order is accepted for work and can be canceled by the your manager.',
    },
  },

};
