
import { TableData, TableSort } from 'app/components/table/tabledata';

import { PFQuery } from 'app/components/data/core/models/base/query';
import { CurrentUser } from 'app/components/data/core/models/base/user';
import * as AccessQueries from 'app/components/access/query';

import { TranslateService } from '@ngx-translate/core';
import { ServerTableDataSource } from 'app/components/table/data_sources/server-data-source';

import { TransportColumns } from '../helpers/transport-columns-factory';
import { TransportQuerySearchFilter } from '../helpers/transport-query-search-filter';

export class VehiclesTableData extends TableData {

  constructor(
    translate: TranslateService
  ) {
    super();

    let columns = [
      TransportColumns.vehicleModelName(),
      TransportColumns.number(),
      TransportColumns.tonnage(),
      TransportColumns.approved(translate),
      TransportColumns.vehicleEquipment(translate),
      TransportColumns.vehiclePasses(translate),
      TransportColumns.inspection(),
      TransportColumns.insurance(),
    ];

    if (CurrentUser.isAdministrator() || CurrentUser.isLogistician() || CurrentUser.isManager()) {
      columns.push(TransportColumns.carrier());
    }

    this.setColumns(columns);

    let sort: TableSort = new TableSort('number', 'asc');
    let tableDataSource = new ServerTableDataSource('Vehicle', ['model.brand', 'carrier']);
    tableDataSource.setDefaultSort(sort);
    tableDataSource.queryFilter = (filter, defaultQuery) => {
      TransportQuerySearchFilter.addNumberFilter(defaultQuery, filter);
      let vehicleCariierQuery = TransportQuerySearchFilter.carrierName('Vehicle', filter);
      return PFQuery.or(defaultQuery, vehicleCariierQuery);
    };
    tableDataSource.queryModify = function (query: PFQuery): PFQuery {
      if (CurrentUser.isDispatcher()) {
        AccessQueries.matchesCurrentCarrier(query);
      }
      query.ascending('approved');

      return AccessQueries.filterDeleted(query);
    };
    this.setProvider(tableDataSource);
  }

  canSortColumn(column) {
    if (column.key === 'fullmodelname' ||
      column.key === 'inspection' ||
      column.key === 'insurance') {
      return false;
    }

    return super.canSortColumn(column);
  }

}
