import {Entrance, LoadingPoint, UnloadingPoint} from '../points/points';
import {ArticleBrand, ArticleShape} from '../articles/article';
import {Intermediary} from '../persons/intermediary';
import {Supplier} from '../persons/supplier';
import {Customer} from '../persons/customer';
import {Carrier} from '../persons/carrier';
import {Offer} from '../offer';
import {TripStage} from '../trip/trip';
import {PFGeoPoint} from '../base/point';
import {Manager} from '../persons/manager';
import {SearchHelper} from '../../../../helpers/search.helper';
import {DateHelper} from '../../../../helpers/date.helper';
import {CarrierOffer} from '../carrier-offer';
import {PFObject} from '../base/object';
import {Role, User} from '../base/user';
import {PFDeletableObject} from '../base/deletableObject';
import {Contact} from '../persons/contact';

let TimeoutFormat = 60 * 60 * 1000;

let fields = [
    'intermediary',
    'articleBrand',
    'customer',
    'supplier',
    'loadingPoint',
    'unloadingPoint',
    'loadingEntrance',
    'unloadingEntrance',
    'loadingDate',
    'unloadingBeginDate',
    'unloadingEndDate',
    'unloadingContact',
    'tonnage',
    'saleTariff',
    'deliveryTariff',
    'salePriceType',
    'deliveryPriceType',
    'inactivityTimeInterval',
    'comment',
    'distance',
    'type',
    'consistency'
];

export enum PriceType {
    ONCE = 0,
    MULTIPLE = 1,
}

export enum OrderAgreeStatus {
    AGREE = 'agree',
    NOT_AGREE = 'not_agree'
}

export enum Type {
    Default = 'default',
    Carriage = 'carriage',
}

export enum OrderStatus {
    CUSTOMER_REQUEST = 'customerRequest',
    SUPPLY_RESERVED = 'supplyReserved',
    IN_WORK = 'inWork',
    READY = 'ready',
}

export class Order extends PFDeletableObject {
    constructor() {
        super('Order');
    }

    static defaultInactivityTimeInterval = 24; //hours

    static nameForPriceType(value: PriceType) {
        switch (value) {
            case PriceType.ONCE:
                return 'ORDER.FIELD_NAME.PRICE_TYPES.ONCE';
            case PriceType.MULTIPLE:
                return 'ORDER.FIELD_NAME.PRICE_TYPES.MULTIPLE';
            default:
                break;
        }

        return 'INVALID_PRICE_TYPE';
    }

    static priceTypesList = [
        PriceType.ONCE,
        PriceType.MULTIPLE,
    ];

    static orderAgreeStatusList = [
        OrderAgreeStatus.AGREE,
        OrderAgreeStatus.NOT_AGREE,
    ];

    isQueue(): boolean {
        return super.get('isQueue');
    }

    number(): number {
        return super.get('number');
    }

    creationDate(): Date {
        return new Date(super.get('createdAt'));
    }

    author(): User {
        return super.get('author');
    }

    intermediary(): Intermediary {
        return super.get('intermediary');
    }

    setIntermediary(value: Intermediary) {
        super.set('intermediary', value);
    }

    articleBrand(): ArticleBrand {
        return super.get('articleBrand');
    }

    setArticleBrand(brand: ArticleBrand) {
        super.set('articleBrand', brand);
    }

    articleShape(): ArticleShape {
        return super.get('articleShape');
    }

    setArticleShape(shape: ArticleShape) {
        super.set('articleShape', shape);
    }

    supplier(): Supplier {
        return super.get('supplier');
    }

    setSupplier(value: Supplier) {
        super.set('supplier', value);
    }

    customer(): Customer {
        return super.get('customer');
    }

    setCustomer(value: Customer) {
        if (!value) {
            super.unset('customer');
        } else {
            super.set('customer', value);
        }
    }

    loadingPoint(): LoadingPoint {
        return super.get('loadingPoint');
    }

    setLoadingPoint(point: LoadingPoint) {
        super.set('loadingPoint', point);
    }

    loadingEntrance(): Entrance {
        return super.get('loadingEntrance');
    }

    setLoadingEntrance(value: Entrance) {
        super.set('loadingEntrance', value);
    }

    unloadingPoint(): UnloadingPoint {
        return super.get('unloadingPoint');
    }

    setUnloadingPoint(point: UnloadingPoint) {
        if (!point) {
            super.unset('unloadingPoint');
        } else {
            super.set('unloadingPoint', point);
        }
    }

    unloadingEntrance(): Entrance {
        return super.get('unloadingEntrance');
    }

    setUnloadingEntrance(value: Entrance) {
        super.set('unloadingEntrance', value);
    }

    saleTariff(): number {
        return super.get('saleTariff');
    }

    setSaleTariff(value: number) {
        super.set('saleTariff', value);
    }

    deliveryTariff(): number {
        return super.get('deliveryTariff');
    }

    setDeliveryTariff(value: number) {
        super.set('deliveryTariff', value);
    }

    salePriceType(): number {
        return super.get('salePriceType');
    }

    setSalePriceType(value: number) {
        super.set('salePriceType', value);
    }

    deliveryPriceType(): number {
        return super.get('deliveryPriceType');
    }

    setDeliveryPriceType(value: number) {
        super.set('deliveryPriceType', value);
    }

    loadingDate(): Date {
        return super.get('loadingDate');
    }

    setLoadingDate(date: Date) {
        super.set('loadingDate', date);
    }

    unloadingBeginDate(): Date {
        return super.get('unloadingBeginDate');
    }

    setUnloadingBeginDate(date: Date) {
        super.set('unloadingBeginDate', date);
    }

    unloadingEndDate(): Date {
        return super.get('unloadingEndDate');
    }

    setUnloadingEndDate(date: Date) {
        super.set('unloadingEndDate', date);
    }

    tonnage(): number {
        return super.get('tonnage');
    }

    setTonnage(tonnage: number) {
        super.set('tonnage', tonnage);
    }

    comment(): string {
        return super.get('comment');
    }

    setComment(comment: string) {
        super.set('comment', comment);
    }

    unfinishedTonnage(): number {
        return super.get('unfinishedTonnage');
    }

    undistributedTonnage(): number {
        return super.get('undistributedTonnage');
    }

    setUndistributedTonnage(tonnage: number) {
        super.set('undistributedTonnage', tonnage);
    }

    distributedTonnage(): number {
        return super.get('distributedTonnage');
    }

    setDistributedTonnage(tonnage: number) {
        super.set('distributedTonnage', tonnage);
    }

    addCarrier(carrier: Carrier) {
        super.add('carriers', carrier);
    }

    getCarriers(): Carrier[] {
        return super.get('carriers');
    }

    getCarrierOffers(): CarrierOffer[] {
        return super.get('carrierOffers');
    }

    offers(): Offer[] {
        return super.get('offers') || [];
    }

    setInactivityTimeInterval(value: number): void {
        super.set('inactivityTimeInterval', value * TimeoutFormat);
    }

    unloadingContact(): Contact {
        return super.get('unloadingContact');
    }

    setUnloadingContact(value: Contact) {
        super.set('unloadingContact', value);
    }

    getManager(): Manager {
        return super.get('manager');
    }

    status(): string {
        return super.get('status');
    };

    setStatus(value: string): void {
        super.set('status', value);
    };

    getType(): Type {
        return super.get('type');
    }

    setType(type: Type) {
        if (type == Type.Carriage) {
            super.set('type', type);
        } else {
            super.unset('type');
        }
    }

    getAgreeStatus(): OrderAgreeStatus {
        const orderStatus = super.get('consistency');
        if (orderStatus === undefined) {
            return null;
        }
        if (orderStatus === OrderAgreeStatus.AGREE) {
            return OrderAgreeStatus.AGREE;
        } else {
            return OrderAgreeStatus.NOT_AGREE;
        }

    }

    setAgreeStatus(status: OrderAgreeStatus) {
        super.set('consistency', status);
    }

    inactivityTimeInterval(): number {
        let interval = super.get('inactivityTimeInterval');
        if (interval != undefined) {
            return interval / TimeoutFormat;
        }
        return undefined;
    }

    loadingPointAddress(): string {
        let point: Entrance = this.loadingEntrance();
        if (point != undefined) {
            return point.getAddress();
        }
        return '';
    }

    unloadingPointAddress(): string {
        let point: Entrance = this.unloadingEntrance();
        if (point != undefined) {
            return point.getAddress();
        }
        return '';
    }

    historyRecords(): OrderHistoryRecord[] {
        return super.get('historyRecords');
    }

    distance() {
        return super.get('distance');
    }

    setDistance(value: number) {
        super.set('distance', value);
    }

    copy(): Order {
        let copy = new Order();
        fields.forEach(field => {
            let val = super.get(field);
            if (val instanceof Date) {
                val = new Date(val);
            }
            copy.set(field, val);
        });
        return copy;
    }

    getOrderStagePoint(stage: TripStage) {
        let offers = this.offers();
        for (let offer of offers) {
            let trip = offer.trip();
            if (trip && trip.historyRecords()) {
                let record = trip.historyRecords().find(historyRecord => historyRecord.stage() === stage);
                if (record && !record.ignoresWrongCoordinate()) {
                    return record.coordinate();
                }
            }
        }
        return undefined;
    }

    isPointDifferentFromPointInStage(point: PFGeoPoint, stage: TripStage, maxDistance: number) {
        let realPoint = this.getOrderStagePoint(stage);
        if (point && realPoint) {
            let distance = point.kilometersTo(realPoint);
            if (distance > maxDistance) {
                return true;
            }
        }
        return false;
    }

    isDifferentUnloadingPoint(maxDistance: number) {
        let unloadingEntrance = this.unloadingEntrance();
        if (!unloadingEntrance) {
            console.log('Missing unloadingEntrance entrance in Order#' + this.number());
            return true;
        }
        let point: PFGeoPoint = unloadingEntrance.getCoordinate();
        return this.isPointDifferentFromPointInStage(point, TripStage.Unloaded, maxDistance);
    }

    isDifferentLoadingPoint(maxDistance: number) {
        let loadingEntrance = this.loadingEntrance();
        if (!loadingEntrance) {
            console.log('Missing loadingEntrance entrance in Order#' + this.number());
            return true;
        }
        let point: PFGeoPoint = loadingEntrance.getCoordinate();
        return this.isPointDifferentFromPointInStage(point, TripStage.Loaded, maxDistance);
    }
}

export function getOrderAuthorName(order: Order) {
    let user = order.author();
    if (user !== undefined) {
        if (user.isAdministrator()) {
            return 'Admin';
        }
        return user.name();
    } else {
        let manager = order.getManager();
        if (manager != undefined) {
            return manager.getName();
        }
    }
    return '';
}

export function getOrderAuthorID(order: Order) {
    let user = order.author();
    if (user !== undefined) {
        return user.id;
    } else {
        let manager = order.getManager();
        if (manager != undefined) {
            return manager.id;
        }
    }
    return '';
}

export enum OrderHistoryAction {
    TAKE_INTO_WORK = 'takenIntoWork',
}

export class OrderHistoryRecord extends PFObject {
    constructor() {
        super('OrderHistoryRecord');
    }

    user(): User {
        return super.get('user');
    }

    date(): Date {
        return super.get('date');
    }

    action(): OrderHistoryAction {
        return super.get('action');
    }
}

export namespace OrdersFilter {
    export function filterProposalsNotContainedText(orders: Order[], filter: string) {
        return orders.filter(o => {
            if (o.customer() && SearchHelper.isContained(o.customer().getName(), filter)) {
                return true;
            }
            if (o.unloadingPoint() && SearchHelper.isContained(o.unloadingPoint().getAddress(), filter)) {
                return true;
            }
            if (o.supplier() && SearchHelper.isContained(o.supplier().getName(), filter)) {
                return true;
            }
            if (o.articleBrand() && SearchHelper.isContained(o.articleBrand().name(), filter)) {
                return true;
            }
            if (o.comment() && SearchHelper.isContained(o.comment(), filter)) {
                return true;
            }
            return false;
        });
    }

    export function filterOrdersBySupplier(orders: Order[], supplier: Supplier) {
        return orders.filter(order => {
            return order.supplier().id == supplier.id;
        });
    }

    export function filterRemains(orders: Order[]) {
        return orders.filter(order => {
            if (order.status() !== OrderStatus.READY) {
                return true;
            }
            let offers = order.offers();
            if (offers && offers.length > 0) {
                for (let offer of offers) {
                    let trip = offer.trip();
                    if (trip) {
                        let records = trip.historyRecords();
                        if (records) {
                            let loadRecord = records.find(r => r.stage() == TripStage.Loaded);
                            if (loadRecord) {
                                return false;
                            }
                        }
                    }
                }
            }
            ;
            return true;
        });
    }

    export function filterOrdersByUnloadingBeginDay(orders: Order[], date: Date) {
        let time = date.getTime();
        return orders.filter(order => {
            let unloadDate = new Date(order.unloadingBeginDate());
            let startDateTime = DateHelper.setDayBegin(unloadDate).getTime();
            let endDateTime = DateHelper.setDayEnd(unloadDate).getTime();
            return time >= startDateTime && time <= endDateTime;
        });
    }

    export function filterOrdersByBrand(orders: Order[], brand: ArticleBrand) {
        return orders.filter(order => {
            return order.articleBrand().id == brand.id;
        });
    }

    export function filterOrdersByCarrier(orders: Order[], carrier: Carrier) {
        return orders.filter(order => {
            let orderCarriers = order.getCarriers();
            if (!orderCarriers) {
                return false;
            }
            let index = orderCarriers.findIndex((c) => {
                return c.id == carrier.id;
            });
            return index != -1;
        });
    }

    export function filterOrdersWithoutCarriers(orders: Order[]) {
        return orders.filter(order => {
            let carriers = order.getCarriers();
            return carriers === undefined || carriers.length === 0;
        });
    }

}

