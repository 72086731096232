
export const locale = {
  'REMOVEDIALOG' : {
    'TITLE' : 'Удаление',
    'MESSAGE' : 'Вы действительно хотите удалить все выбранные элементы?',
    'BUTTON' : {
      'CONFIRM' : 'Да, удалить',
      'DECLINE' : 'Отмена',
    },
  }
};
