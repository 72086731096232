export const locale = {
    'PASSES': {
        'TYPE': {
            '0': 'СК',
            '1': 'ТТК',
            '2': 'МКАД',
            'INVALID': "Invalid zone",
        },
        'TIME_OF_ACTION': {
            'DAY': "Day",
            'NIGHT': "Night",
            'INVALID': "Invalid time of action",
        },
        'INVALID': 'canceled',
        'INTERVAL': {
            'FORMAT': 'from {{from}} to {{to}}',
            'INVALID': 'Invalid dates',
        },
        'STATE': {
            'VALID': 'Valid',
            'INVALID': 'Invalid',
            'CANCELED': 'Canceled',
            'NOT_VALID_NOW': 'Not valid now',
        },
    },
}
