export const locale = {
  "COMMON": {
    'SEARCHTITLE': 'Поиск',
    'BUTTONS' : {
      'RELOAD' : 'Обновить',
      'RESET_FILTERS' : 'Сбросить фильтры ',
      'ACCEPT': 'Применить',
      'RESET': 'Сбросить',
    },
    'SPEEDUNIT' : 'км/ч',
    'NO_ITEMS' : 'Нет записей',
    'DELETED': 'Удален',
    'ERROR' : {
      'INVALID_DATE' : "У даты должен быть такой формат: '01.01.2001, 00:00'",
      'LOAD_ERROR' : "Произошла ошибка при загрузке данных.",
    }
  },
};
