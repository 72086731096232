export const locale = {
  'CUSTOMER_INFO' : {
    'TITLE' : 'Мои данные',
    'FIELD_NAME': {
      'CONTACT_NAME': 'Имя контакта',
      'CONTACT_PHONE': 'Телефон контакта',
      'ITN': 'ИНН',
    },
    'BUTTON': {
      'REFRESH_DEBT': 'Проверить статус',
    },
    'UNDEFINED': 'Не указано',
    'RECEIVABLES': 'Дебиторская задолженность',
    'CREDIT': 'Кредиторская задолженность',
    'LAST_PAYMENT': 'Последняя оплата',
    'ERROR': {
      'VERIFY_ERROR': 'Не удалось запросить задолженность',
    }
  },
};
