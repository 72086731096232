import { NgModule } from '@angular/core';
import { CustomDateRangePickerComponent } from './custom-date-range-picker.component';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/material.module';
import {MatDialogModule} from "@angular/material/dialog";
import {CommonModule} from "@angular/common";
import {MatCardModule} from "@angular/material/card";
import {MatButtonModule} from "@angular/material/button";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";

@NgModule({
    declarations: [
        CustomDateRangePickerComponent,
    ],
    imports: [
        FuseSharedModule,
        TranslateModule,
        MaterialModule,
        MatDialogModule,
        CommonModule,
        MatCardModule,
        MatButtonModule,
        MatDatepickerModule,
        MatNativeDateModule,
    ],
    exports: [
        CustomDateRangePickerComponent,
    ]
})

export class CustomDateRangePickerModule
{
}
