
import { data as data_en } from "./en";
import { data as data_ru } from "./ru";

export const locale = [
  {
    lang: 'en',
    data: data_en,
  },
  {
    lang: 'ru',
    data: data_ru,
  },
];
