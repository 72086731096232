<div class="m-0">
  <dialog-header headerClass="primary" 
                 title="{{'CONSISTENCY.TITLE' | translate}}"
                 hideCloseButton="true">
  </dialog-header>
  
  <div class="pt-8 px-16" fxLayout="column">

    <div class="pb-8" fxLayout="row" fxFlex="90">
      {{'CONSISTENCY.MESSAGE' | translate}}
    </div>

    <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
      <button class="mr-16"
              mat-raised-button
              [matDialogClose]="false">{{'CONSISTENCY.DECLINE' | translate}}
      </button>
      <button mat-raised-button
              color="accent"
              [matDialogClose]="true"
              cdkFocusInitial>{{'CONSISTENCY.CONFIRM' | translate}}
      </button>
    </div>
  </div>
</div>
