import { Component, Input, TemplateRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

export interface Group {
    identifier?: string | null
    headerClass?: any
    headerStyle?: any
    contentClass?: any
    expandedByDefault?: boolean
}

@Component({
    selector: 'group-table',
    templateUrl: './group-table.component.html',
    styleUrls: ['./group-table.component.scss']
})
export class GroupTableComponent {
    groupDataSource;
    private expandedGroups: Group[] = [];

    private _groups: Group[];
    get groups(): Group[] { return this._groups; }
    @Input() set groups(g: Group[] | null) {
        this._groups = g;
        this.updateDatasource();
    };

    @Input() headerInfo: TemplateRef<any>;
    @Input() groupContent: TemplateRef<any>;

    constructor() { }

    private updateDatasource() {
        this.expandedGroups = [];
        this.groupDataSource = new MatTableDataSource(this.groups);
        if (this.groups) {
            this.groups.forEach(g=> {
                if (g.expandedByDefault) {
                    this.expandedGroups.push(g);
                }
            })
        }
    }

    isExpanded(group: Group) {
        return this.expandedIndex(group) != -1;
    }

    toggleExpanded(group: Group) {
        let index = this.expandedIndex(group);
        if (index == -1) {
            this.expandedGroups.push(group);
        } else {
            this.expandedGroups.splice(index, 1);
        }
    }

    expandedIndex(group) {
        return this.expandedGroups.findIndex((g)=>{
            return group === g || (group.identifier && g.identifier ? group.identifier === g.identifier : false);
        });
    }
}