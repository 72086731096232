
export const locale = {
  'DIALOG' : {
    'INTERMEDIARY' : {
      'TITLE' : {
        'CREATE' : 'Create intermediary',
        'EDIT' : 'Edit intermediary',
        'REMOVE' : 'Remove',
      },
      'CONFIRMBUTTON' : {
        'CREATE' : 'Create',
        'SAVE' : 'Save',
        'CLOSE' : 'Close',
      },
      'ERROR' : {
        'MUST_BE_FILLED' : 'Must be filled',
      },
      'NAME' : 'Intermediary name',
    },
  },
};
