export const locale = {
  'ARTICLE' : {
    'ERROR' : {
      'UNDEFINED' : 'Undefined error has occured',
      'RELATION' : "Can't remove object '{{value}}'. Because there is some relations with other objects",
    },
    'TABLEROW' : {
      'SHAPENAME' : 'Shape name',
      'BRANDNAME' : 'Brand name',
      'TYPENAME' : 'Type name',
    },
    'TITLE' : 'Articles',
    'TABS' : {
      'TYPES' : 'Article types',
      'BRANDS': 'Article brands',
      'SHAPES': 'Article shapes',
    },
  }
};
