import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { LocaleService } from 'app/components/locale/locale.service';
import { deleteconfirmation } from '../../locale/locale';

import { DispatcherCloudExecutor } from 'app/components/data/cloud-executors/dispatcher-cloud-executor';
import {TripHistoryRecord} from "../../../../components/data/core/models/trip/trip";
import {TranslateService} from "@ngx-translate/core";
import {LocalNotificationService} from "../../../../components/local-notifications-service/local-notifications-service";

@Component({
  selector: 'delete-image-dialog',
  templateUrl: './delete-image-dialog.component.html',
  styleUrls: ['./delete-image-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DeleteImageDialogComponent {
  public record: TripHistoryRecord;

  private executor = new DispatcherCloudExecutor;

  constructor(
    public dialogRef: MatDialogRef<DeleteImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) _data: any,
    private localeService: LocaleService,
    private translate: TranslateService,
    private notifications: LocalNotificationService,
  ) {
    this.record = _data.object;
    this.localeService.loadLocale(deleteconfirmation);
  }

  close() {
    this.executor.cancel();
  }

  isExecuting() {
    return this.executor.isProcessing();
  }

  execute() {
    let promise = this.executor.deleteImage(this.record);
    promise.then(() => {
      this.dialogRef.close(true);
    }, error => {
      if (error !== 'canceled') {
        let localizedMessage = this.translate.instant('DELETECONFIRM.IMAGEDELETE_ERROR');
        this.notifications.showErrorNotification(localizedMessage);
      }
    });
  }
}
