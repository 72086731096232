
import * as XLSX from 'xlsx';

export class XLSXBuilder {

  private keys: string[] = [];
  private headers: string[] = [];
  private columns: XLSX.ColInfo[] = [];
  private worksheet = {};
  private row = 0;

  constructor(schema: any[]) {
    schema.forEach((schemaField) => {
      this.keys.push(schemaField.key);
      this.headers.push(schemaField.header);
      this.columns.push({width: schemaField.width});
    });
  }

  addHeader(): void {
    this.worksheet["!cols"] = this.columns;
    XLSX.utils.sheet_add_json(this.worksheet, [], {header: this.headers});
    this.row = 1;
  }

  addRow(object: any): void {
    let JSONObject = {};

    for (let key in object) {
      if (object.hasOwnProperty(key)) {
        let index = this.keys.indexOf(key);
        if (index >= 0) {
          let header = this.headers[index];
        JSONObject[header] = object[key];
        }
      }
    }

    XLSX.utils.sheet_add_json(this.worksheet, [JSONObject], {skipHeader: true, origin: this.row});
    ++this.row;
  }

  write(worksheetTitle: string): any {
    let worksheets = {};
    worksheets[worksheetTitle] = this.worksheet;
    let workbook = {Sheets: worksheets, SheetNames: [worksheetTitle]};
    return XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
  }

  static fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

}
