import { FuseNavigation } from '@fuse/types';

export const carrierProposals: FuseNavigation = {
  id       : 'carrierProposals',
  title    : 'Carrier Proposals',
  translate: 'NAV.PROPOSALS.CARRIER_PROPOSALS',
  type     : 'item',
  icon     : 'assignment_returned',
  url      : '/proposals/proposals-for-carrier',
};

export const proposalsList: FuseNavigation = {
    id       : 'proposals-list',
    title    : 'Proposals List',
    translate: 'NAV.PROPOSALS.LIST',
    type     : 'item',
    icon     : 'assignment_returned',
    url      : '/proposals/proposals-list',
};

export const proposalsByCarrier: FuseNavigation = {
    id       : 'proposalsByCarrier',
    title    : 'Proposals by carrier',
    translate: 'NAV.PROPOSALS.PROPOSALS_BY_CARRIERS',
    type     : 'item',
    icon     : 'drive_eta',
    url      : '/proposals/proposals-by-carrier',
};

export const proposalsBySupplier: FuseNavigation = {
    id       : 'proposalsBySuppliers',
    title    : 'Proposals by suppliers',
    translate: 'NAV.PROPOSALS.PROPOSALS_BY_SUPPLIERS',
    type     : 'item',
    icon     : 'perm_contact_calendar',
    url      : '/proposals/proposals-by-suppliers',
};

export const proposalsFromCustomers: FuseNavigation = {
    id       : 'proposalsFromCustomers',
    title    : 'Proposals from customers',
    translate: 'NAV.PROPOSALS.PROPOSALS_FROM_CUSTOMERS',
    type     : 'item',
    icon     : 'shopping_cart',
    url      : '/proposals/proposals-from-customers',
};

