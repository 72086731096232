import { Component, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { PFObject } from 'app/components/data/core/models/base/object';

import { Driver } from 'app/components/data/core/models/persons/driver';

import { LocaleService } from 'app/components/locale/locale.service';
import { create_driver } from 'app/main/admin/users/locale/locale';

import { CloudExecutor } from 'app/components/data/cloud-executors/cloud-executor';

@Component({
    selector   : 'driver-approve-dialog',
    templateUrl: './driver-approve-dialog.component.html',
    styleUrls  : ['./driver-approve-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class DriverApproveDialog {
  compareFunction = PFObject.compareFn;
  
  public form: UntypedFormGroup;
  private object: Driver;

  attachments = [];
  public dataSaver = new CloudExecutor;

  constructor (
    public dialogRef: MatDialogRef<DriverApproveDialog>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _formBuilder: UntypedFormBuilder,
    private localeService: LocaleService,
  ) {
    this.localeService.loadLocale(create_driver);

    this.object = this._data.object;
    this.form = this.createContactForm();
  }

  ngOnInit() {
    this.loadAttachments();
  }

  createContactForm(): UntypedFormGroup {
    return this._formBuilder.group({
      name: [{value: this.object.getName(), disabled: true}],
    });
  }

  isApproved(): boolean {
    return this.object.getApproved();
  }

  isEditingEnabled() {
    return false;
  }

  toggleApprovement() {
    this.approve(!this.object.getApproved());
  }

  approve(value: boolean) {
    this.object.setApproved(value);
    this.dataSaver.saveObject(this.object).then((driver) => {
      this.close(driver);
    });
  }
  
  close(driver) {
    this.dialogRef.close(driver);
  }

  private loadAttachments() {
    let attachedFiles = [];

    let files = this.object.getAttachedDocuments();
    if (!files) {
      attachedFiles = [];
    } else {
      files.forEach(file => {
        let name = file.name();
        name = name.substr(name.indexOf('_')+1);
        let doc = {
          name: name,
          file: file,
          content: file.url(),
        }
        attachedFiles.push(doc);
      });
    }
    this.attachments = attachedFiles;
  }
}
