export const locale = {
  'CREATETYPE' : {
    'TITLE' : {
      'CREATE' : 'Создать вид',
      'EDIT' : 'Изменить вид',
    },
    'CONFIRMBUTTON' : {
      'CREATE' : 'Создать',
      'SAVE' : 'Сохранить',
    },
    'NAME' : 'Название вида',
  },
};
