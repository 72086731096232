<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="primary" 
                 title="{{'DIALOG.CARRIER.LIMITATIONS.TITLE' | translate}}"></dialog-header>
  <div class="py-8 px-16">
    <div class="py-16">
      <mat-label translate [translateParams]="{value: name()}">
        DIALOG.CARRIER.LIMITATIONS.MESSAGE
      </mat-label>
      <div class="py-16" fxLayout="column" fxLayoutAlign="space-between start">
        <mat-checkbox [(ngModel)]="canSeeSalePrice">{{'DIALOG.CARRIER.LIMITATIONS.SALE_PRICE' | translate}}</mat-checkbox>
        
        <mat-checkbox [(ngModel)]="canEditOrder">{{'DIALOG.CARRIER.LIMITATIONS.EDIT' | translate}}</mat-checkbox>
        <mat-checkbox [disabled]="!canEditOrder" [(ngModel)]="canCreateOrder">{{'DIALOG.CARRIER.LIMITATIONS.CREATE' | translate}}</mat-checkbox>
        <mat-checkbox [disabled]="!canEditOrder" [(ngModel)]="canReserveOrder">{{'DIALOG.CARRIER.LIMITATIONS.RESERVE' | translate}}</mat-checkbox>
        <div class="pt-16 h-100" fxLayout="column" fxLayoutAlign="space-between center" fxFill>
          <button class="w-220" mat-raised-button color="accent" (click)="showCustomersLimitations()">{{'DIALOG.CARRIER.LIMITATIONS.LIST.CUSTOMERS' | translate}}</button>
          <button class="w-220" mat-raised-button color="accent" (click)="showSuppliersLimitations()">{{'DIALOG.CARRIER.LIMITATIONS.LIST.SUPPLIERS' | translate}}</button>
        </div>
      </div> 
      
    </div>
    <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button 
              color="accent" 
              [disabled]="dataSaver.isProcessing()"
              (click)='confirm()'>{{'DIALOG.CARRIER.LIMITATIONS.SAVE' | translate}}
      </button>
    </div>
  </div>

</div>