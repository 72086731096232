import { Component, ViewChild } from '@angular/core';

import { TransportUnitsTableData } from './transport-units-table-data';

import { TableComponent, ClickData } from 'app/components/table/table.component';

import { TranslateService } from '@ngx-translate/core';

import { ShowOnMapDialogComponent } from 'app/main/auto/dialog/showonmap/show-on-map-dialog.component';
import { DisbandUnitDialogComponent } from 'app/main/auto/dialog/disbandconfirmation/disband-unit-dialog.component';

import { CurrentUser } from 'app/components/data/core/models/base/user';

import { LocaleService } from 'app/components/locale/locale.service';
import { autolist, autos_row_operations } from '../../locale/locale';
import { units, common } from '../../../locale/locale';
import { DialogPresenterService } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import { TableData } from 'app/components/table/tabledata';
import { ActionBarOperation } from 'app/components/action-bar/action-bar.component';
import { TableOperationsHelper } from 'app/components/helpers/table-operations.helper';
import { TransportUnit } from 'app/components/data/core/models/transport/transport';

const locales = [
  units,
  common,
  autolist,
  autos_row_operations
];

@Component({
  selector: 'autoList',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class AutoListComponent {
  tableData: TableData;
  tableOperations: ActionBarOperation[] = [];
  
  @ViewChild(TableComponent) private table: TableComponent;

  headerButtons = [];

  constructor(
    private localeService: LocaleService,
    private translate: TranslateService,
    private dialogPresenter: DialogPresenterService,
  ) {
    this.localeService.loadLocales(locales);
    this.tableData = new TransportUnitsTableData(this.translate);
    this.tableOperations = this.makeTableOperations();

    this.headerButtons = this.makeHeaderButtons();
  }

  search(searchString: string) {
    this.table.search(searchString);
  }

  doubleClickHandler(click: ClickData) {
    this.showOnMapDialog(click.data);
  }

  updateOperations(): void {
    this.tableOperations = this.makeTableOperations();
  }

  private reloadData() {
    this.table.reloadData();
  }

  private makeTableOperations(): ActionBarOperation[] {
    let selected = this.table ? this.table.selectedValues() : [];
    let showOnMapOperation = new ActionBarOperation({
      title: 'ROWOPERATION.SHOWONMAP',
      icon: 'gps_fixed',
      action: () => this.showOnMap(selected),
      disabled: TableOperationsHelper.notSingleSelection(selected)
    });

    let disbandOperation = new ActionBarOperation({
      title: 'ROWOPERATION.DISBAND',
      icon: 'cancel',
      action: () => this.disbandUnit(selected),
      disabled: TableOperationsHelper.notSingleSelection(selected)
    });

    let operations = [];
    operations.push(showOnMapOperation);
    if (CurrentUser.isAdministrator() || CurrentUser.isLogistician() || CurrentUser.isDispatcher()) {
      operations.push(disbandOperation);
    }
    return operations;
  }

  private showOnMap(selected) {
    this.showOnMapDialog(selected[0]);
  }

  private showOnMapDialog(unit: TransportUnit) {
    this.dialogPresenter.showDialog({
      dialog: ShowOnMapDialogComponent,
      panel: 'show-on-map-dialog-container',
      data: {
        unit: unit,
        dontShowTrack: true,
      }
    });
  }

  private disbandUnit(selected) {
    this.askDisband(selected);
  }

  private askDisband(units) {
    this.dialogPresenter.showDialog({
      dialog: DisbandUnitDialogComponent,
      panel: 'disband-unit-dialog-container',
      data: {
        units: units,
      }
    }, (confirmed) => {
      if (confirmed) {
        this.reloadData();
      }
    });
  }

  private makeHeaderButtons() {
    return [
      {
        'title': 'COMMON.BUTTONS.RELOAD',
        'action': () => this.reloadData()
      },
    ];
  }
}
