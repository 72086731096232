import { Component, Output, EventEmitter, Input } from '@angular/core';
import { DateHelper } from 'app/components/helpers/date.helper';

@Component({
  selector: 'date-selector',
  templateUrl: './date-selector.component.html',
})

export class DateSelectorComponent {
  @Input() initialDate = new Date();
  selectedDate: Date;
  @Output() dateSelected = new EventEmitter<Date>();

  ngOnInit() {
    this.prepareDates();
  }

  private prepareDates(): void {
    let initialDate = this.initialDate ? this.initialDate : DateHelper.setDayBegin(new Date());
    this.selectedDate = initialDate;
  }

  private setSelectedDate(date: Date): void {
    this.selectedDate = date;
    this.dateSelected.emit(date);
  }

  selectDate(date) {
    this.setSelectedDate(new Date(date));
  }

  nextDay(): void {
    let date = DateHelper.nextDay(this.selectedDate);
    this.setSelectedDate(date);
  }

  prevDay(): void {
    let date = DateHelper.prevDay(this.selectedDate);
    this.setSelectedDate(date);
  }


}