
<div class="table-header primary mt-32 mb-20"
     fxLayout="column" fxLayoutAlign="center center"
     fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between stretch">

         <!-- APP TITLE -->
    <div class="logo my-12 m-sm-0"
         fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon mr-16">
            {{iconName}}
        </mat-icon>
        <span class="logo-text h1">
            {{headerTitle}}
        </span>
    </div>
         
    <mat-form-field *ngIf="!noSearchBar" class="search-bar no-padding" fxFlex='40'>
             <!-- <mat-icon matPrefix>search</mat-icon> -->
        <mat-label>{{ searchBarTitle | translate}}</mat-label>
        <input matInput #searchbar>
        <span *ngIf='searchbar.value' mat-icon-button (click)="searchbar.value = ''; applyFilter('');" matSuffix>
            <mat-icon>close</mat-icon>
        </span>
    </mat-form-field>

    <div fxLayout="row" fxLayoutAlign="space-between center">
        <ng-container *ngFor="let button of buttons">
            <button class="h-36 button-spacing" mat-raised-button (click)="button.action()">
               {{button.title | translate}}
            </button>
        </ng-container>
    </div>
</div>
