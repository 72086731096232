
/// <reference types="@types/googlemaps" />

export function getAdministrativeAreaName(addressComponent: google.maps.GeocoderAddressComponent): string {
  let replacing = "область";
  let replacement = "обл.";

  if (addressComponent.long_name.includes(replacing)) {
    return addressComponent.long_name.replace(replacing, replacement);
  }
  else {
    return addressComponent.short_name;
  }
}
