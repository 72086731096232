import { Component, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';

import { TransportUnit, TransportUnitStatus} from 'app/components/data/core/models/transport/transport';
import { PFQuery } from 'app/components/data/core/models/base/query';

import { CurrentUser } from 'app/components/data/core/models/base/user';
import * as AccessQueries from 'app/components/access/query';

import { LocaleService } from 'app/components/locale/locale.service';
import { autoonmap } from '../../locale/locale';
import { units } from '../../../locale/locale';
import { QueryResultFilter } from 'app/components/access/queryResultFilter';
import { ServerTableDataSource } from 'app/components/table/data_sources/server-data-source';
import { LiveQuerySubscriber, LiveQueryModifier } from 'app/components/table/data_sources/live-query-subscriber';
import { Observable } from 'rxjs';
import { FieldFilter } from 'app/components/table/table.component';
import { DataLoader } from 'app/components/data/data-loader';

import { DataSource } from 'app/components/data/data.source';
import { Supplier } from 'app/components/data/core/models/persons/supplier';

const locales = [
  units,
  autoonmap
];

@Component({
    selector   : 'onmap',
    templateUrl: './onmap.component.html',
    styleUrls  : ['./onmap.component.scss']
})
export class AutoOnMapComponent {
    public statuses;
    public get units() {
      return this.unitsDataSource.items;
    };
    public get suppliers() {
      return this.suppliersDataSource.items;
    };
    
    private unitsDataSource: DataSource<TransportUnit>;
    private suppliersDataSource: DataSource<Supplier>;

    @ViewChild('statusFilter') statusFilter: MatSelect;

    constructor(
        private localeService: LocaleService
    ) {
        this.localeService.loadLocales(locales);
        this.statuses = Object
        .keys(TransportUnitStatus)
        .map(k => TransportUnitStatus[k])
        .filter(v => typeof v === 'number');
    }

    ngOnInit() {
      this.initializeDataSources();
      this.unitsDataSource.reloadData();
      this.suppliersDataSource.reloadData();
    }

    reloadData() {
      this.unitsDataSource.reloadData();
    }

    applyFilter(value) {
      if (value != TransportUnitStatus.Undefined) {
        this.unitsDataSource.setModifyBlock(query => {
          query.equalTo('status', value);
          return query;
        });
      } else {
        this.unitsDataSource.setModifyBlock(q => q);
      }
      this.reloadData();
    }

    private initializeDataSources(): void {
      let unitFields = [
        'driver',
        'vehicle',
        'trailer',
        'vehicle.model',
        'vehicle.model.brand',
      ];
      this.unitsDataSource = new DataSource<TransportUnit>(TransportUnit, unitFields);
      this.unitsDataSource.options.filterDeleted = false;
      this.unitsDataSource.setModifyBlock((query: PFQuery) => {
        query.equalTo("disbanded", false);
        query.exists("coordinate");
        if (CurrentUser.isDispatcher()) {
          AccessQueries.driverMatchesCurrentCarrier(query);
        }
        return query;
      });

      let supplierFields = [
        'loadingPoints.entrances'
      ];
      this.suppliersDataSource = new DataSource<Supplier>(Supplier, supplierFields);
    }
}
