export const locale = {
  'UNITS' : {
    'TABLEROW': {
      'UNIT': 'Авто',
      'DRIVER': 'Водитель',
      'STATUS': 'Статус',
      'UNITNUMBER': 'Номер авто',
      'TRAILERNUMBER': 'Номер прицепа',
      'SPEED': 'Скорость',
      'TONNAGE' : 'Тоннаж',
      'LASTSEEN' : 'Последний сеанс',
      'PROBLEM' : ' Проблема',
      'ORDERNUMBER' : 'Номер заказа',
      'POSITION': 'Позиция',
      'PASS': 'Пропуск',
    },
    'STATUS' : {
      '0' : 'Не готов принять заказ',
      '1' : 'Готов принять заказ',
      '2' : 'Сломался',
      '3' : 'Невидимый',
      '4' : 'Работаю',
      '5' : 'Отдыхаю',
      '6' : 'В ремонте',
      '90': 'Не определен',
      'WITHOUT_APP': 'Без приложения',
      '-1': 'Все',
    },
  }
};
