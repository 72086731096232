import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { proposals_decline_dialog } from './locale/locale';
import { LocaleService } from 'app/components/locale/locale.service';

import { TranslateService } from '@ngx-translate/core';
import { Order } from 'app/components/data/core/models/order/order';
import { DispatcherCloudExecutor } from 'app/components/data/cloud-executors/dispatcher-cloud-executor';
import { LocalNotificationService } from 'app/components/local-notifications-service/local-notifications-service';

@Component({
  selector: 'decline-proposal-dialog',
  templateUrl: './decline-proposal-dialog.component.html',
  styleUrls: ['./decline-proposal-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DeclineProposalDialog {
  orders: Order[];
  private executor = new DispatcherCloudExecutor;

  constructor(
    private notifications: LocalNotificationService,
    private translate: TranslateService,
    private localeService: LocaleService,
    private dialogRef: MatDialogRef<DeclineProposalDialog>,
    @Inject(MAT_DIALOG_DATA) _data: any,
  ) {
    this.localeService.loadLocale(proposals_decline_dialog);
    this.orders = _data.object;
  }

  close() {
    this.executor.cancel();
  }

  isExecuting() {
    return this.executor.isProcessing();
  }

  execute() {
    this.executor.declineOrders(this.orders)
      .then(() => {
        this.dialogRef.close(true);
      })
      .catch(error => {
        if (error != 'canceled') {
          let localizedMessage = this.translate.instant('DIALOG.DECLINE.ERROR');
          this.notifications.showErrorNotification(localizedMessage);
        }
      });
  }
}
