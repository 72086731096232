
export const locale = {
  'DIALOG': {
    'CREATEPOINT': {
      'TITLE': {
        'CREATE': 'Create point',
        'EDIT': 'Edit point',
      },
      'CONFIRMBUTTON': {
        'CREATE': 'Create',
        'SAVE': 'Save',
      },
      'NAME': 'Point name',
      'CONTACT_NAME': 'Contact name',
      'CONTACT_PHONE': 'Contact phone',
      'ADDRESS': 'Address',
      'LATITUDE': 'Latitude',
      'LONGITUDE': 'Longitude',
      'FINDADDRESS': 'Find address',
      'FINDCOORDINATE': 'Find coordinates',
      'ERROR': {
        'MIN': 'Min {{min}}',
        'MAX': 'Max {{max}}',
      },
    },
  },
};
