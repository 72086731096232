
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/material.module';

import { ConfigurationService } from "./configuration.service";
import { SettingsComponent } from './settings.component';
import { TableHeaderModule} from 'app/components/tableheader/tableheader.module'

import { SupplierPriorityDialog } from './dialog/supplier-priority/supplier-priority-dialog.component';
import { TableModule } from 'app/components/table/table.module';
import { DialogHeaderModule } from 'app/components/dialogs/dialog-header/dialog-header.module';

@NgModule({
    providers: [
        ConfigurationService
    ],
    declarations: [
        SettingsComponent,
        SupplierPriorityDialog,
    ],
    imports: [
        TranslateModule,
        FuseSharedModule,
        MaterialModule,
        TableHeaderModule,
        DialogHeaderModule,
        TableModule,
    ],
    exports: [
        SettingsComponent,
        SupplierPriorityDialog,
    ]
})
export class SettingsModule {
}
