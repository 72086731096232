
import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'app/main/material.module';
import { DownloadComponent } from './download.component';
import { DownloadService } from './download.service';

@NgModule({
    declarations: [
        DownloadComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        MaterialModule,
    ],
    providers: [
        DownloadService
    ]
})
export class DownloadModule
{
}
