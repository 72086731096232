export const locale = {
  'REPORTS': {
    'TITLE': 'Create report',
    'BEGINDATE': 'Begin date',
    'ENDDATE': 'End date',
    'CREATE': 'Create',
    'ERROR': {
      'FAILED': 'Failed to create report.',
    },
  },
};
