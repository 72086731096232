import { TableData, TableSort } from 'app/components/table/tabledata';

import { CurrentUser } from 'app/components/data/core/models/base/user';
import { Order, OrderStatus } from 'app/components/data/core/models/order/order';
import { PFQuery } from 'app/components/data/core/models/base/query';
import * as AccessQueries from 'app/components/access/query';
import { ServerTableDataSource } from 'app/components/table/data_sources/server-data-source';
import { LiveQueryModifier, LiveQuerySubscriber } from 'app/components/table/data_sources/live-query-subscriber';

import { ConfigurationService } from "app/main/admin/settings/configuration.service";
import { OrderQuerySearchFilter } from '../../helpers/order-query-search-filter';
import { OrderColumns } from '../../helpers/order-columns-factory';
import {TableNameKeys} from "../../../../components/table/data_sources/table-name-keys";

export class FinishedOrdersTabledata extends TableData {
  constructor(
    private configurationService: ConfigurationService
  ) {
    super();

    let columns = [
      OrderColumns.makeColumnNumber()
    ];

    let userColumns;
    if (CurrentUser.isCustomer()) {
      userColumns = [
        OrderColumns.makeColumnUnloadPoint(),
        OrderColumns.makeColumnBrand(),
        OrderColumns.makeColumnUnloadedTonnage(),
        OrderColumns.makeColumnFacticalUnloadDate(),
      ];
    } else {
      userColumns = [
        OrderColumns.makeColumnCustomer(),
        OrderColumns.makeColumnUnloadPoint(),
        OrderColumns.makeColumnFacticalUnloadDate(),
        OrderColumns.makeColumnUnloadedTonnage(),
        OrderColumns.makeColumnFacticalLoadDate(),
        OrderColumns.makeColumnSupplier(),
      ];
      if (CurrentUser.isDispatcher()) {
        userColumns.push(OrderColumns.makeColumnDriver());
      }
    }
    userColumns.push(OrderColumns.makeColumnComment());

    columns = columns.concat(userColumns);
    this.setColumns(columns);

    let includeFields = [
      'unloadingPoint',
      'offers.trip.historyRecords',
      'offers.trip.transportUnit.driver',
      'offers.transportUnit.driver',
      'offers.transportUnit.user',
      'offers.transportUnit.vehicle.model.brand',
      'offers.transportUnit.trailer',
      'articleBrand.type',
      'customer',
      'loadingEntrance',
      'unloadingEntrance',
      'carriers',
      'carrierOffers.driver',
    ];

    if (CurrentUser.isCustomer()) {
      includeFields.push('unloadingPoint.manager');
    } else {
      includeFields = includeFields.concat([
        'loadingPoint',
        'intermediary',
        'supplier',
        'loadingAgreement',
      ]);
    }

    let sort: TableSort = new TableSort('lastProgressDate', 'desc');
    let tableDataSource = new ServerTableDataSource('Order', includeFields);
    if (!CurrentUser.isCustomer()){
      tableDataSource.loadAllCustomers();
      tableDataSource.loadAllSuppliers();
    }
    tableDataSource.setDefaultSort(sort);
    tableDataSource.queryFilter = (filter, defaultQuery) => {
      let searchQuery;
      let unloadingEntranceAddressFilter = OrderQuerySearchFilter.unloadingEntranceAddress(filter);

      if (CurrentUser.isCustomer()) {
        let brandNameFilter = OrderQuerySearchFilter.brandName(filter);
        searchQuery = PFQuery.or(
          brandNameFilter,
          unloadingEntranceAddressFilter,
        );
      } else {
        let customerNameFilter = OrderQuerySearchFilter.customerName(filter);
        let supplierNameFilter = OrderQuerySearchFilter.supplierName(filter);
        searchQuery = PFQuery.or(
          customerNameFilter,
          supplierNameFilter,
          unloadingEntranceAddressFilter
        );

        let tonnage = parseFloat(filter);
        if (!isNaN(tonnage)) {
          let facticalTonnageFilter = OrderQuerySearchFilter.facticalTonnage(tonnage);
          searchQuery = PFQuery.or(searchQuery, facticalTonnageFilter);
        }
      }

      let number = parseFloat(filter);
      if (!isNaN(number)) {
        let numberFilter = OrderQuerySearchFilter.orderNumber(number);
        searchQuery = PFQuery.or(searchQuery, numberFilter);
      }
      return searchQuery;
    };

    function applyQueryModification(query: PFQuery) {
      if (CurrentUser.isCustomer()) {
        query = AccessQueries.containsCurrentCustomer(query);
      } else if (CurrentUser.isDispatcher()) {
        query = AccessQueries.containsCurrentCarrier(query);
      }
      else if (!CurrentUser.isAdministrator() && !CurrentUser.isLogistician()) {
        query = AccessQueries.authorMatchesCurrentUser(query);
        let managerAssignedOrders = new PFQuery('Order');
        managerAssignedOrders = AccessQueries.managerMatchesCurrentUser(managerAssignedOrders);
        query = PFQuery.or(query, managerAssignedOrders);
      }
      query.equalTo('unfinishedTonnage', 0);
      query = AccessQueries.filterDeleted(query);
      query.equalTo('status', OrderStatus.READY);
      return query;
    }

    tableDataSource.queryModify = function (query: PFQuery): PFQuery {
      //query.descending("lastProgressDate");
      return applyQueryModification(query);
    };

    let subscriber = new LiveQuerySubscriber('Order', includeFields);

    subscriber.liveQueryModifier = new LiveQueryModifier(
      function (query) {
        return applyQueryModification(query);
      }
    );

    tableDataSource.setLiveQuerySubscriber(subscriber);
    this.setProvider(tableDataSource);
  }

  canSortColumn(column) {
    switch (column.key) {
      case TableNameKeys.number:
      case TableNameKeys.loadDateFact:
      case TableNameKeys.unloadDateFact:
      case TableNameKeys.customer:
      case TableNameKeys.tonnageFact:
        return true;
      default:
        return false;
    }
  }

  canSortByDateColumn(column) {
    switch (column.key) {
      case TableNameKeys.unloadDateFact:
      case TableNameKeys.loadDateFact:
      case TableNameKeys.customer:
      case TableNameKeys.supplier:
        return false;
      default:
        return true;
    }
  }

  isWarnRow(order: Order) {
    if (CurrentUser.isCustomer()) return false;
    if (order.isDeleted()) return false;
    let conf = this.configurationService.getConfiguration();
    if (!conf) return false;
    let maxDistance = conf.getMaxDistanceDifference() / 1000;
    return order.isDifferentUnloadingPoint(maxDistance) || order.isDifferentLoadingPoint(maxDistance);
  }
}
