<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="primary" 
                 title="{{title | translate}}">
  </dialog-header>
  <div class="amber" *ngIf="!isEditingEnabled()" fxLayout="row" fxLayoutAlign="center center">
    <mat-label>{{ 'DIALOG.DRIVER.APPROVED_LOCK' | translate }}</mat-label>
  </div>

  <div class="pt-8 px-16">
    <form [formGroup]="form">

      <div>
        <mat-form-field fxFlex>
          <input 
          matInput 
          placeholder="{{'DIALOG.DRIVER.FULLNAME' | translate}}" 
          formControlName="name" 
          required
          cdkFocusInitial>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field fxFlex>
          <input matInput 
                 placeholder="{{'DIALOG.DRIVER.PHONE_NUMBER' | translate}}" 
                 formControlName="phoneNumber">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-label>{{'DIALOG.DRIVER.SHOW_TARIFF_OPTION' | translate}}</mat-label>
        <mat-slide-toggle formControlName="showTariff"></mat-slide-toggle>
      </div>

      <div *ngIf="isCarrierVisible()">
        <mat-form-field fxFlex>
          <mat-select placeholder="{{'DIALOG.DRIVER.CARRIER_SELECT' | translate}}"
                      formControlName="carrier"
                      [compareWith]="compareFunction"
                      required>
            <mat-option *ngFor='let carrier of carriersLoader.getLoadedItems()' [value]="carrier">{{carrier.getName()}}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('carrier').invalid">{{'DIALOG.DRIVER.ERROR.NO_CARRIER' | translate}}</mat-error>
        </mat-form-field>
      </div>

      <div class="pt-8">
        <attachment #attachment 
                    [files]="attachments" 
                    [editingEnabled]="isEditingEnabled()"
                    (onRemoveCallback)="onAttachmentRemove($event.value)">
        </attachment>
      </div>

      <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button color="accent" 
                [disabled]="dataSaver.isProcessing()"
                (click)='checkAndSave()'>{{confirmButton | translate}}
        </button>
      </div>
    </form>
  </div>
</div>