import { NgModule } from '@angular/core';

import { EntranceDialog } from './entrance-dialog.component'

import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/material.module';
import { AgmCoreModule } from '@agm/core';
import { DialogHeaderModule } from 'app/components/dialogs/dialog-header/dialog-header.module';

@NgModule({
    declarations: [
        EntranceDialog,
    ],
    imports: [
        TranslateModule,
        FuseSharedModule,
        MaterialModule,
        AgmCoreModule,
        DialogHeaderModule,
    ],
    exports: [
        EntranceDialog,
    ]
})

export class EntranceDialogModule
{
}
