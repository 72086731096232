import {locale as queue_en} from "./en/locale.queue";
import {locale as queue_ru} from "./ru/locale.queue";

export const queue = [
  {
    lang: "en",
    data: queue_en
  },
  {
    lang: "ru",
    data: queue_ru
  }
];
