import { Component, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { fromEvent } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'table-header',
    templateUrl: './tableheader.component.html',
    styleUrls: ['./tableheader.component.scss']
})
export class TableHeaderComponent {

    @Input()
    public headerTitle: string;
    @Input()
    public iconName: string;
    @Input()
    public buttons = [];
    @Input()
    public noSearchBar: boolean = false;

    @Input()
    public searchBarTitle: string = 'Search';
    
    @Output() onSearch = new EventEmitter();

    @ViewChild('searchbar') private searchBar: ElementRef;

    constructor(
        public fuseTranslationLoaderService: FuseTranslationLoaderService
    ) { }

    ngAfterViewInit() {
        if (this.searchBar) {
            fromEvent(this.searchBar.nativeElement, 'keyup')
                .pipe(
                    map((event: Event) => (<HTMLInputElement>event.target).value),
                    debounceTime(500),
                    distinctUntilChanged(),
                )
                .subscribe(value => {
                    this.applyFilter(this.searchBar.nativeElement.value);
                });
        }
    }

    applyFilter(searchString: string) {
        searchString = searchString.trim();
        this.onSearch.emit(searchString);
    }
}
