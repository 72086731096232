import { TranslateService } from '@ngx-translate/core';
import { DefaultReportBuilder } from './default.report-builder';

import * as AccessQueries from 'app/components/access/query';

export class DispatcherReportBuilder extends DefaultReportBuilder {
    constructor(translate: TranslateService) {
        super(translate, query => {
            return AccessQueries.transportUnitMatchesCurrentCarrier(query);
        })
    }

    makeFields() {
        let fields = super.makeFields()
        let filterFields = [
            "salePriceType",
            "saleTariff",
            "author",
            "sum"
        ];

        fields = fields.filter((field) => filterFields.indexOf(field.key) == -1);
        return fields;
    }
}