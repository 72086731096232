
import { FuseNavigation } from '@fuse/types';

export const list: FuseNavigation = {
  id       : 'orderslist',
  title    : 'Orders list',
  translate: 'NAV.ORDERS.LIST',
  type     : 'item',
  icon     : 'assignment',
  url      : '/orders/list',
};
export const all: FuseNavigation = {
  id       : 'ordersalllist',
  title    : 'Orders all',
  translate: 'NAV.ORDERS.ALL',
  type     : 'item',
  icon     : 'assignment_turned_in',
  url      : '/orders/all',
};

export const progress: FuseNavigation = {
  id       : 'ordersinprogress',
  title    : 'In progress',
  translate: 'NAV.ORDERS.INPROGRESS',
  type     : 'item',
  icon     : 'swap_calls',
  url      : '/orders/inprogress',
};

export const finished: FuseNavigation = {
  id       : 'finishedorderslist',
  title    : 'Finished',
  translate: 'NAV.ORDERS.FINISHED',
  type     : 'item',
  icon     : 'done_all',
  url      : '/orders/finished',
};

export const map: FuseNavigation = {
  id       : 'ordersOnMap',
  title    : 'On map',
  translate: 'NAV.ORDERS.ONMAP',
  type     : 'item',
  icon     : 'location_on',
  url      : '/orders/onmap',
};
