export const locale = {
    'SHOWONMAP': {
      'TITLE' : 'Позиция автомобиля',
      'DOWNLOAD' : 'Скачать трек',
      'LOADING_TRACK' : 'Загрузка трека...',
      'SPEEDUNIT' : 'км/ч',
      'ERROR' : {
        'NO_UNIT_COORDINATES' : 'Нет геоданных',
      },
      'INFO' : {
        'DATE' : 'Дата, время',
        'STAGE' : 'Статус заказа',
      },
      'STOPINFO' : {
        'VEHICLE_NUMBER' : 'Гос. номер',
        'UNLOAD_TIME' : 'Длительность разгрузки',
        'ADDRESS' : 'Адрес',
      }
    },
};
