import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { NgxFileDropEntry, FileSystemFileEntry, NgxFileDropComponent } from 'ngx-file-drop';

import { Parse } from 'parse';
import { LocaleService } from 'app/components/locale/locale.service';
import { attachment } from './locale/locale';

export interface AttachmentFileData {
  name: string,
  file: File,
  content: any,
}

@Component({
  selector: 'attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss']
})

export class AttachmentComponent {
  @Input()
  public files: AttachmentFileData[] = [];
  public maxFiles = 5;

  @Input()
  public editingEnabled: boolean = true;

  @Input()
  public dropZoneLabel: string = 'Test';

  @Output()
  public onRemoveCallback: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('dropzone') dropZone: NgxFileDropComponent;

  constructor(private localeService: LocaleService) {
    this.localeService.loadLocale(attachment);
  }

  public dropped(files: NgxFileDropEntry[]) {
    for (let droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        let fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (file.type.indexOf('image/') !== 0)
            return;

          let handler = this;
          let reader = new FileReader();
          reader.onload = (event: any) => {
            let index = handler.files.length + 1;
            let ext = file.type.substring(file.type.indexOf('/') + 1);
            let fileName = "file" + index + "." + ext;
            handler.addFileData({
              name: fileName,
              file: file,
              content: event.target.result,
            });
          };
          reader.readAsDataURL(file);
        });
      }
    }
  }

  openFileBrowser(event) {
    if (!this.dropZone.disabled)
      this.dropZone.openFileSelector(event);
  }

  openFile(fileData) {
    window.open(fileData.content, "_blank");
  }

  private addFileData(fileData) {
    if (this.files.length >= this.maxFiles) {
      return;
    }

    this.files.push(fileData);
  }

  public removeFile(fileData) {
    if (this.onRemoveCallback) {
      this.onRemoveCallback.emit(new AttachmentRemoveEvent(fileData));
    }

    this.files.splice(this.files.indexOf(fileData), 1);
  }

  public uploadAttachments(): Promise<any> {
    if (this.files.length > 0) {
      let attachedFiles = [];

      for (let i = 0; i < this.files.length; i++) {
        let fileData = this.files[i];
        let file = fileData.file;
        if (file instanceof File) {
          let name = fileData.name;
          attachedFiles.push(new Parse.File(name, file));
        }
      }
      if (attachedFiles.length > 0) {
        return Parse.Object.saveAll(attachedFiles);
      }
    }
    return Promise.resolve();
  }
}

export class AttachmentRemoveEvent {
  public value;
  constructor(item: AttachmentFileData) {
    this.value = item;
  }
}
