import {locale as removedialog_en} from "./en/locale.removedialog";
import {locale as removedialog_ru} from "./ru/locale.removedialog";

export const removedialog = [
  {
    lang: "en",
    data: removedialog_en
  },
  {
    lang: "ru",
    data: removedialog_ru
  }
];
