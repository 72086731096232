import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { OrdersListComponent } from './list.component';
import { MaterialModule } from 'app/main/material.module';

import { TableHeaderModule} from 'app/components/tableheader/tableheader.module'
import { TableModule } from 'app/components/table/table.module';

import { TransferOrderModule } from 'app/main/orders/dialog/transfer/transfer-order-dialog.module';
import { OrderInfoModule } from 'app/main/orders/dialog/orderinfo/order-info-dialog.module';

import { DeleteOrderDialogModule } from 'app/main/orders/dialog/deleteconfirmation/delete-order-dialog.module';
import { DeclineOrderDialogModule} from 'app/main/orders/dialog/decline/decline-order-dialog.module';

import { OrdersDialogPresenterService } from 'app/main/orders/dialog/ordersdialogspresenter/orders-dialog-presenter.service'
import { ActionBarModule } from 'app/components/action-bar/action-bar.module';

@NgModule({
    declarations: [
        OrdersListComponent,
    ],
    imports     : [
        TranslateModule,

        FuseSharedModule,

        MaterialModule,
        TableHeaderModule,
        TableModule,
        OrderInfoModule,
        TransferOrderModule,
        DeleteOrderDialogModule,
        DeclineOrderDialogModule,
        ActionBarModule,
    ],
    exports     : [
        OrdersListComponent,
    ],
    providers: [
      OrdersDialogPresenterService,
    ],
})

export class OrdersListModule
{
}
