import { Component, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
@Component({
    selector: 'app-autocomplete-widget',
    templateUrl: './autocomplete-widget.component.html',
})
export class AutocompleteWidgetComponent implements OnInit {
    @Input() placeholder: string = '';
    @Input() dataSource: any[] = [];
    @Input() displayFn: (item: any) => string = item => item;
    @Input() required: boolean = false;
    @Input() control: FormControl = new FormControl();

    filteredItems: Observable<any[]>;

    ngOnInit() {
        this.setupFilter();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dataSource) {
            this.setupFilter(); // Обновляем фильтр при изменении dataSource
        }
    }

    private setupFilter() {
        this.filteredItems = this.control.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : this.displayFn(value))),
            map(name => this.filterItems(name))
        );

        // Триггерим обновление, чтобы сразу показать актуальные данные
        this.control.updateValueAndValidity({ emitEvent: true });
    }

    private filterItems(name: string): any[] {
        if (!name) return this.dataSource;
        const filterValue = name.toLowerCase();
        return this.dataSource.filter(item =>
            this.displayFn(item).toLowerCase().includes(filterValue)
        );
    }
}
