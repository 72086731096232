import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { LocaleService } from 'app/components/locale/locale.service';
import { cancelconfirm } from '../../locale/locale';

import { DispatcherCloudExecutor } from 'app/components/data/cloud-executors/dispatcher-cloud-executor';

@Component({
  selector: 'cancel-order-dialog',
  templateUrl: './cancel-order-dialog.component.html',
  styleUrls: ['./cancel-order-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CancelOrderDialogComponent {
  public orders;
  private executor = new DispatcherCloudExecutor;
  constructor(
    public localeService: LocaleService,
    public dialogRef: MatDialogRef<CancelOrderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) _data: any,
  ) {
    this.orders = _data.object;
    this.localeService.loadLocale(cancelconfirm);
  }

  isExecuting() {
    return this.executor.isProcessing();
  }

  close() {
    this.executor.cancel();
  }

  execute() {
    return this.executor.cancelOrders(this.orders)
      .then(() => {
        this.dialogRef.close(true);
      })
      .catch(() => {
        this.dialogRef.close(false);
      });
  }
}
