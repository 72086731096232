
<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="primary" 
                 title="{{title | translate}}">
  </dialog-header>
  
  <div class="pt-8 px-16">
    <form [formGroup]="form">

      <div>
        <mat-form-field fxFlex>
          <input matInput
                 placeholder="{{'DIALOG.CREATEBRAND.NAME' | translate}}"
                 formControlName="name"
                 required
                 cdkFocusInitial>
        </mat-form-field>
      </div>

      <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button
                color="accent"
                [disabled] = "dataSaver.isProcessing()"
                (click)='checkAndSave()'>{{buttonName | translate}}
        </button>
      </div>
    </form>
  </div>
</div>
