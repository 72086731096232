import { Component, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { UnloadingPoint, Entrance } from 'app/components/data/core/models/points/points';
import { Customer } from 'app/components/data/core/models/persons/customer';
import { PFGeoPoint } from 'app/components/data/core/models/base/point';
import { PFObject } from 'app/components/data/core/models/base/object';
import { Order, PriceType } from 'app/components/data/core/models/order/order';

import { LocaleService } from 'app/components/locale/locale.service';
import { customer } from 'app/main/admin/users/locale/locale';
import { order_fields } from 'app/main/orders/locale/locale';

let locales = [customer, order_fields];

import * as FormHelper from 'app/components/form-helper/form-helper';

import { debounceTime } from 'rxjs/operators';
import * as Location from "app/components/location/coordinate";
import * as Geocode from 'app/components/geocode/geocode';
import * as Places from 'app/components/geocode/places';

import { AttachmentComponent } from 'app/components/attachment/attachment.component';

import { CurrentUser } from 'app/components/data/core/models/base/user';
import { CloudExecutor } from 'app/components/data/cloud-executors/cloud-executor';

import * as CustomValidators from 'app/components/validators/validators';

@Component({
    selector   : 'customer-create-dialog',
    templateUrl: './customer-create-dialog.component.html',
    styleUrls  : ['./customer-create-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CustomerCreateDialog {
  compareFunction = PFObject.compareFn;
  
  public form: UntypedFormGroup;

  public title: string;
  public confirmButtonTitle: string;
  public priceTypes;

  private object: Customer;

  public addressSearchResults: Places.SearchResult[];
  private addressSearchService: Places.SearchService;
  private lastGeocodedAddress: string;
  private lastGeocodedCoordinate: Location.Coordinate;
  private geocoder: Geocode.Geocoder;

  public loading: boolean = false;
  private attachmentsToRemove = [];

  private dataSaver = new CloudExecutor;

  @ViewChild('attachment') attachment: AttachmentComponent;

  constructor (
    private localeService: LocaleService,
    public dialogRef: MatDialogRef<CustomerCreateDialog>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: UntypedFormBuilder,
  ) {
    this.localeService.loadLocales(locales);
    this.priceTypes = Order.priceTypesList;

    if (this._data.action === 'create') {
      this.object = new Customer();

      this.title = 'DIALOG.CUSTOMER.TITLE.CREATE';
      this.confirmButtonTitle = 'DIALOG.CUSTOMER.CONFIRMBUTTON.CREATE';

    } else {
      this.object = this._data.object;
      this.title = 'DIALOG.CUSTOMER.TITLE.EDIT';
      this.confirmButtonTitle = 'DIALOG.CUSTOMER.CONFIRMBUTTON.SAVE';
    }
    this.form = this.createContactForm();

    this.form
      .get('address')
      .valueChanges
      .pipe(debounceTime(500))
      .subscribe( value => this.addressChanged(value));
  }

  ngOnInit() {
    if (this._data.action === 'edit') {
      this.loadAttachments();
    }
  }

  mapReady(map) {
    this.loadAddressService(map);
  }

  createContactForm(): UntypedFormGroup {
    let points = this.object.getUnloadingPoints();
    let unloadingPointAddress = points ? points[0].getAddress() : null;
    let selectedPriceType = this.object.getDefaultPriceType() !== undefined ? this.object.getDefaultPriceType() : PriceType.MULTIPLE;

    return this._formBuilder.group({
                name            : this.object.getName(),
                itn             : [this.object.getITN() , [CustomValidators.regExValidator(/^[0-9]+$/)]],
                contactName     : this.object.getContactName(),
                contactPhone    : this.object.getContactPhone(),
                defaultPriceType: selectedPriceType,
                address         : unloadingPointAddress,
              });
  }

  priceTypeName(value: PriceType) {
    return Order.nameForPriceType(value);
  }

  checkAndSave() {
    let verified = this.checkForm();

    if (verified) {
      this.loading = true;
      this.object.setName(this.form.controls.name.value);
      this.object.setITN(this.form.controls.itn.value ? this.form.controls.itn.value.toString() : "");
      this.object.setContactName(this.form.controls.contactName.value);
      this.object.setContactPhone(this.form.controls.contactPhone.value);
      this.object.setDefaultPriceType(this.form.controls.defaultPriceType.value);

      let address = this.form.controls.address.value;

      if (this._data.action === 'create') {
        this.getCoordinateForAddress(address).then((coordinate) => {
          return this.createEntrance(address, coordinate);
        }).then(entrance => {
          return this.cresteUnloadingPointWithEntrance(entrance);
        }).then(point => {
          this.object.addUnloadingPoint(point);
          return this.uploadAttachments();
        }).then((attachedFiles) => {
          this.addAttachments(this.object, attachedFiles);
          return this.dataSaver.saveObject(this.object);
        }).then((c) => {
          this.close(c);
        });
      } else {
        let customer = this.object;
        let point = customer.getUnloadingPoints() ? customer.getUnloadingPoints()[0] : null;
        this.updatePointAddress(point, address).then(() => {
          this.removeAttachments(customer, this.attachmentsToRemove);
          return this.uploadAttachments();
        }).then((attachedFiles) => {
          this.addAttachments(customer, attachedFiles);
          return this.dataSaver.saveObject(customer);
        }).then((c) => {
          this.close(c);
        });
      }
    }
  }

  getCoordinateForAddress(address: string) {
    if (address === this.lastGeocodedAddress && this.lastGeocodedCoordinate) {
      return Promise.resolve(this.lastGeocodedCoordinate);
    } else {
      return this.geocoder.codeAddress(address);
    }
  }

  createEntrance(address, coordinate) {
    let entrance = new Entrance();
    entrance.setName(this.localeService.translateService.instant('DIALOG.CUSTOMER.DEFAULT_POINT'));
    entrance.setAddress(address);

    if (coordinate) {
      entrance.setCoordinate(new PFGeoPoint({
        latitude: coordinate.latitude,
        longitude: coordinate.longitude
      }));
    } else {
      console.log('No coords for address ' + address);
    }
    return this.dataSaver.saveObject(entrance);
  }

  cresteUnloadingPointWithEntrance(entrance: Entrance) {
    let unloadingPoint = new UnloadingPoint();
    unloadingPoint.setAddress(entrance.getAddress());
    unloadingPoint.addEntrance(entrance);

    if (CurrentUser.isManager()) {
      unloadingPoint.setManager(CurrentUser.manager());
    }

    return this.dataSaver.saveObject(unloadingPoint);
  }

  updatePointAddress(point, address) {
    if (point && address !== point.getAddress()) {
      point.setAddress(address);
      return this.dataSaver.saveObject(point);
    } else {
      return Promise.resolve(point);
    }
  }

  uploadAttachments() {
    return this.attachment.uploadAttachments();
  }

  addAttachments(customer, attachments) {
    if (attachments) {
      attachments.forEach( obj => {
        customer.attachDocument(obj);
      });
    }
  }

  removeAttachments(customer, attachments) {
    if (attachments.length > 0) {
      attachments.forEach( obj => {
        customer.removeDocument(obj.file);
      });
    }
  }

  close(customer) {
    this.dialogRef.close(customer);
  }

  onAttachmentRemove(attachment) {
    if (!(attachment.file instanceof File)) {
      this.attachmentsToRemove.push(attachment);
    }
  }

  loadAttachments() {
    let attachedFiles = [];

    if (!this.object.getAttachedDocuments()) {
      return;
    }
    this.object.getAttachedDocuments().forEach(file => {
      let name = file.name();
      name = name.substr(name.indexOf('_')+1);
      let doc = {
        name: name,
        file: file,
        content: file.url(),
      }
      attachedFiles.push(doc);
    });

    this.attachment.files = attachedFiles;
  }

  checkForm() : boolean {
    return FormHelper.checkForm(this.form);
  }

  loadAddressService(map) {
    this.geocoder = new Geocode.Geocoder();
    this.addressSearchService = Places.SearchService.all(map);

    this.addressSearchService.results.subscribe((results) => {
      this.addressSearchResults = results;
    });
  }

  public addressChanged(text: string): void {
    if (text.length > 2) {
      this.addressSearchService.search(text);
    } else {
      this.addressSearchResults = [];
    }
  }

  public addressSearchResultSelected(searchResult: Places.SearchResult) {
    this.form.controls.address.setValue("");
    this.addressSearchService.getDetails(searchResult).then((details) => {
      this.setAddress(details.address);
      this.lastGeocodedCoordinate = details.coordinate;
    });
  }

  private setAddress(address: string): void {
    this.lastGeocodedAddress = address;
    this.form.controls.address.setValue(address);
    this.form.controls.address.setErrors(null);
  }
}
