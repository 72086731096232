import { ValidatorFn, AbstractControl } from '@angular/forms';

export function regExValidator(regEx): ValidatorFn {
    return (control): { [key: string]: boolean } | null => {
      let value = control.value;
      if (!value)
        return null;
      
      if (!regEx.test(value))
        return { 'noMatchToRegEx': true };
      return null;
    };
}

export function isObjectValidator(control: AbstractControl): {[key: string]: any} | null {
  if (control && control.value && (typeof control.value !== 'object')) {
      return { 'notObject': true };
  }
  return null;
}

export function trailerNumberValidator(): ValidatorFn {
  return regExValidator(/^[АВЕКМНОРСТУХ]{2}\d{4}\d{2,3}$/ui)
}

export function vehicleNumberValidator(): ValidatorFn {
  return regExValidator(/^[АВЕКМНОРСТУХ]\d{3}[АВЕКМНОРСТУХ]{2}\d{2,3}$/ui)
}
