import {Component, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatTabGroup} from '@angular/material/tabs';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

import {Order, OrderAgreeStatus} from 'app/components/data/core/models/order/order';
import {TransportUnit} from 'app/components/data/core/models/transport/transport';
import {Carrier} from 'app/components/data/core/models/persons/carrier';

import {UnitSelectComponent} from './unit/unit-select.component';
import {CarrierSelectComponent} from './carrier/carrier-select.component';

import {DialogPresenterService} from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import {NoProcuratoryDialogComponent} from './no_procuratory/no_procuratory_dialog.component';

import {DispatcherCloudExecutor} from 'app/components/data/cloud-executors/dispatcher-cloud-executor';
import {TranslateService} from '@ngx-translate/core';
import {LocaleService} from 'app/components/locale/locale.service';
import {order_fields, transfer, consistency} from '../../locale/locale';
import {passes, units} from 'app/main/locale/locale';
import {CurrentUser} from 'app/components/data/core/models/base/user';
import {ConfigurationService} from '../../../admin/settings/configuration.service';
import {LocalNotificationService} from 'app/components/local-notifications-service/local-notifications-service';
import {TransportHelper} from 'app/components/helpers/transport.helper';
import {UnitTransportProblemDialogComponent} from './unit-tansport-problem/unit-tansport-problem-dialog.component';
import {ConsistencyDialogComponent} from './consistency/consistency_dialog.component';
import {RetransferDialogComponent} from './retransfer/retransfer_dialog.component';

let locales = [
    transfer,
    consistency,
    order_fields,
    units,
    passes,
];

enum TransferTab {
    Drivers = 0,
    Carriers = 1,
}

@Component({
    selector: 'transfer-order-dialog',
    templateUrl: './transfer-order-dialog.component.html',
    styleUrls: ['./transfer-order-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class TransferOrderDialogComponent implements OnInit {
    public form: UntypedFormGroup;
    public orders: Order[];

    public executor = new DispatcherCloudExecutor;

    @ViewChild(UnitSelectComponent) driversComponent: UnitSelectComponent;
    @ViewChild(CarrierSelectComponent) carriersComponent: CarrierSelectComponent;
    @ViewChild(MatTabGroup) transferTabs: MatTabGroup;

    constructor(
        public dialogRef: MatDialogRef<TransferOrderDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: UntypedFormBuilder,
        private translate: TranslateService,
        private localeService: LocaleService,
        private dialogPresenterService: DialogPresenterService,
        private configService: ConfigurationService,
        private notifications: LocalNotificationService,
    ) {

        this.localeService.loadLocales(locales);
        this.orders = this._data.object;
        this.form = this.makeFormGroup();
    }

    private overloadFactor: number;

    ngOnInit() {
        this.configService.fetch().then(() => {
            this.overloadFactor = this.configService.getConfiguration().getOverloadFactor();
        });
    }

    private makeFormGroup(): UntypedFormGroup {
        let customer = this.orders[0].customer();
        let tonnage = this.orders[0].tonnage();
        let loadingEntrance = this.orders[0].loadingEntrance();
        let loadingAddress = loadingEntrance ? loadingEntrance.getAddress() : 'Undefined';
        let unloadingEntrance = this.orders[0].unloadingEntrance();
        let unloadingAddress = unloadingEntrance ? unloadingEntrance.getAddress() : 'Undefined';

        return this._formBuilder.group({
            customer: [{value: customer.getName(), disabled: true}],
            tonnage: [{value: tonnage, disabled: true}],
            loadingEntrance: [{value: loadingAddress, disabled: true}],
            unloadingEntrance: [{value: unloadingAddress, disabled: true}],
        });
    }

    isAllowTransferToCarrier(): boolean {
        return CurrentUser.isAdministrator() || CurrentUser.isLogistician() || CurrentUser.isManager();
    }

    getFirstOrder() {
        return this.orders[0];
    }

    private isTransferred(order: Order): boolean {
        let offers = order.offers();
        let carriers = order.getCarriers();
        return (offers && offers.length > 0) || (carriers && carriers.length > 0);
    }

    checkAndTransfer() {
        this.orders.forEach((order) => {
            let isNotAgreeOrder = order.getAgreeStatus() === OrderAgreeStatus.NOT_AGREE;
            if (this.isTransferred(order)) {
                if (isNotAgreeOrder) {
                    this.showConsistencyDialog(() => {
                        this.setAgreeTransferOrder(order);
                        this.showRetransferDialog(() => {
                            this.cancelAndTransfer(order);
                        });
                    })
                } else {
                    this.showRetransferDialog(() => {
                        this.cancelAndTransfer(order);
                    });
                }
            } else {
                if (isNotAgreeOrder) {
                    this.showConsistencyDialog(() => {
                        this.setAgreeTransferOrder(order);
                        this.transfer(order);
                    });

                } else {
                    this.transfer(order);
                }
            }
        });
    }

    private cancelAndTransfer(order: Order) {
        this.executor.cancelOrder(order)
            .then(() => this.transfer(order))
            .catch(() => this.notifications.showErrorNotification(this.cancelTransferErrorMessage()));
    }

    private transfer(order: Order) {
        if (!this.isAllowTransferToCarrier() || (this.driversComponent !== undefined && this.transferTabs.selectedIndex == TransferTab.Drivers)) {
            this.transferToDrivers(order);
        } else {
            this.transferToCarriers(order);
        }
    }

    private checkIsUnitReady(unit: TransportUnit, completion) {
        let vehicleInsuranceCheck = !TransportHelper.isInsuranceExpired(unit.vehicle());
        let vehicleInspectionCheck = !TransportHelper.isInspectionExpired(unit.vehicle());
        let trailerInspectionCheck = !TransportHelper.isInspectionExpired(unit.trailer());
        if (vehicleInsuranceCheck && vehicleInspectionCheck && trailerInspectionCheck) {
            completion();
        } else {
            this.showUnitNotReadyDialog(
                {
                    vehicleInsuranceCheck: vehicleInsuranceCheck,
                    vehicleInspectionCheck: vehicleInspectionCheck,
                    trailerInspectionCheck: trailerInspectionCheck,
                },
                completion);
        }
    }

    private isCorrectCapacity(unit: TransportUnit, order: Order) {
        return unit.totalTonnage() * this.overloadFactor >= order.tonnage();
    }

    private transferToDrivers(order: Order) {
        let selectedValues: TransportUnit[] = this.driversComponent.getSelectedUnits();
        if (selectedValues.length != 0) {
            let unit = selectedValues[0];
            this.checkIsUnitReady(unit, () => this.transferToUnit(unit, order));
        } else {
            this.notifications.showErrorNotification(this.noSelectedErrorMessage());
        }
    }

    private transferToUnit(unit, order) {
        if (this.isCorrectCapacity(unit, order)) {
            if (unit.user()) {
                if (unit.driver().getApproved()) {
                    if (CurrentUser.isDispatcher()) {
                        this.transferOrder(order, unit);
                    } else {
                        this.checkProcuratory(order, unit);
                    }
                } else {
                    this.notifications.showErrorNotification(this.driverNotApprovedMessage());
                }
            } else {
                this.assignOrderToUnit(order, unit);
            }
        } else {
            this.notifications.showErrorNotification(this.lowTonnageErrorMessage());
        }
    }

    private transferToCarriers(order: Order) {
        let carriers: Carrier[] = this.carriersComponent.getSelectedCarriers();
        this.assignOrderToCarriers(order, carriers);
    }

    private checkProcuratory(order: Order, unit: TransportUnit) {
        this.executor.verifyDriver(unit.driver())
            .then(response => {
                if (response.powerOfAttorney) {
                    this.transferOrder(order, unit);
                } else {
                    this.showNoProcuratoryDialog(() => {
                        this.transferOrder(order, unit);
                    });
                }
            })
            .catch(() => this.notifications.showErrorNotification(this.assignErrorMessage()));
    }

    private transferOrder(order: Order, unit: TransportUnit) {
        this.executor.sendOffers([order], [unit])
            .then(() => this.dialogRef.close(true))
            .catch(() => this.notifications.showErrorNotification(this.assignErrorMessage()));
    }

    private assignOrderToUnit(order: Order, unit: TransportUnit) {
        this.executor.assignTransportUnit(order, unit)
            .then(() => this.dialogRef.close(true))
            .catch(() => this.notifications.showErrorNotification(this.assignErrorMessage()));
    }

    private assignOrderToCarriers(order: Order, carriers: Carrier[]) {
        this.executor.assignCarriers([order], carriers)
            .then(() => this.dialogRef.close(true))
            .catch(() => this.notifications.showErrorNotification(this.assignErrorMessage()));
    }

    private noSelectedErrorMessage(): string {
        return this.translate.instant('TRANSFER.ERROR.NOTSELECTED');
    }

    private lowTonnageErrorMessage(): string {
        return this.translate.instant('TRANSFER.ERROR.LOW_TONNAGE');
    }

    private driverNotApprovedMessage(): string {
        return this.translate.instant('TRANSFER.ERROR.DRIVER_NOT_APPROVED');
    }

    private assignErrorMessage(): string {
        return this.translate.instant('TRANSFER.ERROR.UNDEFINED_ERROR');
    }

    private cancelTransferErrorMessage(): string {
        return this.translate.instant('TRANSFER.ERROR.UNDEFINED_ERROR');
    }

    private showUnitNotReadyDialog(checks, completion) {
        this.dialogPresenterService.showDialog({
            dialog: UnitTransportProblemDialogComponent,
            panel: 'unit-tansport-problem-dialog-container',
            data: {
                disableClose: false,
                checks: checks,
            },
        }, function (success) {
            if (success) {
                completion();
            }
        });
    }

    private showNoProcuratoryDialog(completion) {
        this.dialogPresenterService.showDialog({
            dialog: NoProcuratoryDialogComponent,
            panel: 'no-procuratory-dialog-container',
            data: {
                disableClose: false,
            },
        }, function (success) {
            if (success) {
                completion();
            }
        });
    }

    private showConsistencyDialog(completion) {
        this.dialogPresenterService.showDialog({
            dialog: ConsistencyDialogComponent,
            panel: 'retransfer-dialog-container',
            data: {
                disableClose: false,
            },
        }, function (success) {
            if (success) {
                completion();
            }
        });
    }

    private showRetransferDialog(completion) {
        this.dialogPresenterService.showDialog({
            dialog: RetransferDialogComponent,
            panel: 'retransfer-dialog-container',
            data: {
                disableClose: false,
            },
        }, function (success) {
            if (success) {
                completion();
            }
        });
    }

    isSingleOrderSelected() {
        return this.orders.length == 1;
    }

    private setAgreeTransferOrder(order: Order) {
        order.setAgreeStatus(OrderAgreeStatus.AGREE);
    }
}
