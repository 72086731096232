import { Component, Input, ViewEncapsulation } from '@angular/core';

import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { Order } from 'app/components/data/core/models/order/order';

import { LocaleService } from 'app/components/locale/locale.service';
import { CurrentUser } from 'app/components/data/core/models/base/user';
import { TranslateService } from '@ngx-translate/core';
import { LoadingPoint } from 'app/components/data/core/models/points/points';
import { ConfigurationService } from 'app/main/admin/settings/configuration.service';
import { OrderHelper } from 'app/components/helpers/order.helper';

@Component({
    selector   : 'info-form',
    templateUrl: './info-form.component.html',
    styleUrls  : ['./info-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class InfoFormComponent {

  @Input()
  order: Order;

  public form: UntypedFormGroup;

  constructor(
    private localeService: LocaleService,
    private translate: TranslateService,
    private _formBuilder: UntypedFormBuilder,
    private configurationService: ConfigurationService,
  ) {}

  ngOnInit() {
    let intermediary = this.order.intermediary();
    let intermediaryName = " ";
    if (intermediary) {
      intermediaryName = intermediary.getName();
    }

    let salePriceTypeName = this.localeService.translateService.instant(Order.nameForPriceType(this.order.salePriceType()));
    let deliveryPriceTypeName = this.localeService.translateService.instant(Order.nameForPriceType(this.order.deliveryPriceType()));

    let loadingDate = this.order.loadingDate();
    let loadingDateText = loadingDate.toLocaleDateString() + ' ' + loadingDate.getHours() + ':00';

    let unloadingBeginDate = this.order.unloadingBeginDate();
    
    var unloadingDateText = unloadingBeginDate.toLocaleDateString()
    unloadingDateText += ' ' + unloadingBeginDate.getHours() + ':00';

    let unloadingEndDate = this.order.unloadingEndDate();
    if (unloadingEndDate) {
      unloadingDateText += ' - ' + unloadingEndDate.getHours() + ':00';
    }

    let unloadingEntrance = this.order.unloadingEntrance();
    let unloadingAddress = " ";
    if (unloadingEntrance != null) {
      unloadingAddress = unloadingEntrance.getName() + ' (' + unloadingEntrance.getAddress() + ')';
    }

    let unloadingContact = this.order.unloadingContact();
    let unloadingContactName;
    let unloadingContactPhone;
    if (unloadingContact) {
      unloadingContactName = unloadingContact.name;
      unloadingContactPhone = unloadingContact.phoneNumber;
    }

    let loadingEntrance = this.order.loadingEntrance();
    let loadingAddress = " ";
    if (loadingEntrance != null) {
      loadingAddress = loadingEntrance.getName() + ' (' + loadingEntrance.getAddress() + ')';
    }

    let point: LoadingPoint = this.order.loadingPoint();
    let pointAddress = point ? point.getAddress() : ' ';

    let supplierName;
    if (this.order.supplier()) {
      supplierName = this.order.supplier().getName();
    } else {
      supplierName = this.translate.instant('ORDER.LOAD_TYPE.TRANSSHIPMENT');
    }

    let orderType = this.order.getType();
    let orderTypeName = this.translate.instant(OrderHelper.nameForOrderType(orderType));

    let undef_val = this.translate.instant('ORDER.UNDEFINED');
    this.form = this._formBuilder.group({
      intermediary      : [{value: intermediaryName, disabled:true}],
      orderType         : [{value: orderTypeName, disabled:true}],
      customer          : [{value: this.order.customer().getName(), disabled:true}],
      unloadingEntrance : [{value: unloadingAddress, disabled:true}],
      unloadingDate     : [{value: unloadingDateText, disabled:true}],

      supplier          : [{value: supplierName, disabled:true}],
      loadingPoint      : [{value: pointAddress, disabled:true}],
      loadingEntrance   : [{value: loadingAddress, disabled:true}],
      loadingDate       : [{value: loadingDateText, disabled:true}],

      articleType       : [{value: this.order.articleBrand().type().name(), disabled:true}],
      articleBrand      : [{value: this.order.articleBrand().name(), disabled:true}],
      tonnage           : [{value: this.order.tonnage() || undef_val , disabled:true}],
      salePriceType     : [{value: salePriceTypeName, disabled:true}],
      saleTariff        : [{value: this.order.saleTariff() || undef_val, disabled:true}],
      deliveryPriceType : [{value: deliveryPriceTypeName, disabled:true}],
      deliveryTariff    : [{value: this.order.deliveryTariff() || undef_val, disabled:true}],
      timeout           : [{value: this.order.inactivityTimeInterval(), disabled:true}],
      comment           : [{value: this.order.comment() || '', disabled:true}],

      contactName       : [{value: unloadingContactName || undef_val, disabled:true}],
      contactPhone      : [{value: unloadingContactPhone || undef_val, disabled:true}],
    });
  }

  canShowSalePrice() {
    if (CurrentUser.isDispatcher()) {
      return CurrentUser.carrier().getOrderPermissions().salePrice;
    }
    return true;
  }

  isDifferentUnloadingPoint() {
    let conf = this.configurationService.getConfiguration();
    if (conf) {
      let maxDistance = conf.getMaxDistanceDifference() / 1000;
      return this.order.isDifferentUnloadingPoint(maxDistance);
    }
    return false;
  }

  isDifferentLoadingPoint() {
    let conf = this.configurationService.getConfiguration();
    if (conf) {
      let maxDistance = conf.getMaxDistanceDifference() / 1000;
      return this.order.isDifferentLoadingPoint(maxDistance);
    }
    return false;
  }
}
