
<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="primary" 
                 title="{{title | translate}}">
  </dialog-header>
  
  <div class="pt-8 px-16">
    <form [formGroup]="form">
      <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">
        <mat-form-field fxFlex>
          <input matInput
                 placeholder="{{'DIALOG.INTERMEDIARY.NAME' | translate}}"
                 formControlName="name"
                 required
                 cdkFocusInitial>
          <mat-error *ngIf="form.get('name').hasError('required')">{{'DIALOG.INTERMEDIARY.ERROR.MUST_BE_FILLED' | translate}}</mat-error>
        </mat-form-field>
      </div>

      <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button
                color="accent"
                [disabled] = "loading"
                (click)='checkAndSave()'>{{confirmButtonTitle | translate}}
        </button>
      </div>
    </form>
  </div>
</div>
