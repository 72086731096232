<div class="filter-box">
    <mat-calendar
        #calendar
        [(selected)]="selectedDateRange"
        (selectedChange)="rangeChanged($event)">
    </mat-calendar>
    <div class="button-container">
        <button mat-raised-button color="primary" (click)="resetSelection()" [disabled]="isAcceptDisabled" class="reset-button">
            {{ 'COMMON.BUTTONS.RESET' | translate }}
        </button>
        <button mat-raised-button color="accent" [disabled]="isAcceptDisabled" (click)="acceptSelection()" class="reset-button">
            {{ 'COMMON.BUTTONS.ACCEPT' | translate }}
        </button>
    </div>
</div>