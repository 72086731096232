export const locale = {
    'PROPOSALS': {
        'DIALOG': {
            'DECLINE' : {
                'TITLE': 'Отказ от заявок',
                'CONFIRMBUTTON': 'Отказаться',
                'MESSAGE': 'Вы уверены, что хотите отказаться от выбранных заявок?',
                'TONNAGE': '{{value}} тонн',
            },
        },
    },
};
