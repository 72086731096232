export const locale = {
    'PROPOSALS': {
        'DIALOG': {
            'CREATE': {
                'TITLE': {
                    'CREATE': 'Создать заявку',
                    'EDIT': 'Редактировать заявку',
                },
                'CONFIRMBUTTON': {
                    'CREATE': 'Создать',
                    'SAVE': 'Сохранить',
                },
                'CONTROLS': {
                    'SUPPLIER_SELECTOR': 'Поставщик',
                    'BRAND_SELECTOR': 'Марка товара',
                    'TYPE_SELECTOR': 'Вид товара',
                    'UNLOADING_DATE': 'Дата разгрузки',
                    'UNLOADING_TIME': 'Время',
                    'TONNAGE': 'Тоннаж',
                    'COUNT': 'Кол-во машин',
                    'CUSTOMER_SELECTOR': 'Покупатель',
                    'LOADING_ADDRESS_SELECTOR': 'Адрес загрузки',
                    'UNLOADING_ADDRESS_SELECTOR': 'Адрес разгрузки',
                    'UNLOADING_CONTACT': 'Контакт на разгрузке',
                    'COMMENT': 'Комментарий',
                },
                'ERROR': {
                    'LOAD_ERROR': 'Ошибка загрузки',
                    'MUST_SELECT': 'Необходимо выбрать из списка',
                },
            },
        },
    },
};
