import {locale as create_contact_en} from "./en/locale.create-contact";
import {locale as create_contact_ru} from "./ru/locale.create-contact";

import {locale as manage_contact_en} from "./en/locale.manage-contacts";
import {locale as manage_contact_ru} from "./ru/locale.manage-contacts";

export const create_contacts = [
  {
    lang: "en",
    data: create_contact_en
  },
  {
    lang: "ru",
    data: create_contact_ru
  }
];

export const manage_contacts = [
  {
    lang: "en",
    data: manage_contact_en
  },
  {
    lang: "ru",
    data: manage_contact_ru
  }
];
