import {locale as notifications_en} from "./en/locale.notifications";
import {locale as notifications_ru} from "./ru/locale.notifications";

export const notifications = [
  {
    lang: 'en',
    data: notifications_en
  },
  {
    lang: 'ru',
    data: notifications_ru
  },
];
