<div class="filter-box"
     (focusout)="onFocusOut($event)"
     (focusin)="onFocusIn()"
     *ngIf="isVisible"
     tabindex="0"
     #dropdownElement>
    <div *ngFor="let node of nodes" class="parent-container">
        <div class="checkbox-container">
            <mat-checkbox [(ngModel)]="node.checked" (change)="onParentCheckChange(node)">
                {{ node.name }}
            </mat-checkbox>
            <button class="toggle-btn" *ngIf="node.children && node.children.length" (click)="node.isExpanded = !node.isExpanded">
                {{ node.isExpanded ? '−' : '+' }}
            </button>
        </div>

        <div *ngIf="node.isExpanded" class="children">
            <mat-checkbox
                    *ngFor="let child of node.children"
                    [(ngModel)]="child.checked"
                    (change)="onChildNodeChange(child, node)"
                    class="child-checkbox"
            >
                {{ child.name }}
            </mat-checkbox>
        </div>
    </div>
</div>
