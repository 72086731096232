import { DialogPresenterService, DialogData } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import { TableOperationsHelper } from 'app/components/helpers/table-operations.helper';
import { ActionBarOperation } from 'app/components/action-bar/action-bar.component';
import { CredentialsDialog } from 'app/main/admin/users/dialogs/credentials/credentials-dialog.component';
import { Role } from 'app/guards/user-role-guard.service';
import { RemoveUserDialogComponent } from '../dialogs/removedialog/remove-user-dialog.component';

export namespace UsersTableHelper {
    export function askCreateUserCredentials(dialogPresenter: DialogPresenterService, selected, role: string) {
        let dialogData: DialogData = {
            dialog: CredentialsDialog,
            panel: 'credentials-dialog-container',
            data: { object: selected[0].getUser(), personId: selected[0].id, role: role }
        }
        dialogPresenter.showDialog(dialogData);
    }

    export function makeRemoveUserDialodData(role: Role) {
        return {
            dialog: RemoveUserDialogComponent,
            panel: 'remove-user-dialog-container',
            data: {
                role: role
            }
        }
    }

    export function makeCreateUserCredentialsOperation(dialogPresenter: DialogPresenterService, selected: any[], role: string) {
        return new ActionBarOperation({
            title: 'USERS.ROWOPERATION.CREDENTIALS',
            icon: 'people',
            action: () => askCreateUserCredentials(dialogPresenter, selected, role),
            disabled: TableOperationsHelper.notSingleSelection(selected)
        })
    }
} 