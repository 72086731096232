import {locale as report_en} from "./en/locale.report";
import {locale as report_ru} from "./ru/locale.report";

export const report = [
  {
    lang: 'en',
    data: report_en
  },
  {
    lang: 'ru',
    data: report_ru
  },
];
