export const locale = {
  'HOME': {
    'TITLE': 'Home',
    'DESCRIPTION': 'Description',
    'DOWNLOAD': 'Download',
    'BUTTON': {
      'ENTER': 'LogIn',
    }
  },
};
