import { TableData, TableRowData } from 'app/components/table/tabledata';

import { LocalDataSource } from 'app/components/table/data_sources/local-data-source';
import { Supplier } from 'app/components/data/core/models/persons/supplier';

export class SupplierPriorityTableData extends TableData{

  constructor(
    private suppliers: Supplier[]
  ) {
    super();
    let columns = [
      new TableRowData({
        key: 'address', 
        header: 'DIALOG.SUPPLIER_PRIORITY.NAME', 
        cell: (supplier: Supplier) => `${supplier.getName()}`
      }),
    ];

    this.setColumns(columns);

    let tableDataSource = new LocalDataSource(this.suppliers);
    this.setProvider(tableDataSource);
  }
}
