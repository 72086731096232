export const locale = {
    'CREATE_MESSAGE': {
        'TITLE': 'Create message',
        'FIELD': {
            'TITLE': 'Message title',
            'TARGET': 'Message target',
            'BODY': 'Message',
        },
        
        'CONFIRMBUTTON': 'Create',
        'ERROR': {
            'UNDEFINED': 'Undefined error',
          },
    }
  };
  