<div class="page-layout carded fullwidth" fusePerfectScrollbar>
    <div class="top-bg primary"></div>
      <div class="center">
          <div fxLayout="column"
          fxLayoutAlign="center stretch"
          fxLayout.gt-xs="row"
          fxLayoutAlign.gt-xs="space-between stretch">
            <table-header fxFlex
                          headerTitle="{{'PROPOSALS.TITLE.LIST' | translate}}" 
                          iconName="done_all" 
                          searchBarTitle="COMMON.SEARCHTITLE"
                          (onSearch)="search($event)">
            </table-header>
            <div class="primary mt-32 mb-20" fxFlex="30">
              <mat-form-field class="no-padding supplier-selector" fxFlex>
                <mat-select placeholder="{{'PROPOSALS.SUPPLIER' | translate}}"
                            (selectionChange)='reloadOrders()'
                            [(ngModel)]="selectedSupplier"
                            [compareWith]="compareFunction"
                            #supplierSelector>
                              <mat-option *ngFor='let supplier of availableSuppliers' 
                                          [value]="supplier">
                                          {{ supplier.getName()}}
                              </mat-option>
                  </mat-select>
                </mat-form-field>
            </div>
            <date-selector class="primary mt-32 mb-20" 
                           [initialDate]="initialDate"
                           (dateSelected)="reloadOrders()">
            </date-selector>
            
          </div>
          
          <div class="mat-elevation-z8 fuse-white mb-16" 
               fxLayout="column">
               <action-bar [operations]="operationsController.operations" class="sticky fuse-white"></action-bar>
               <div [fxHide]="isLoading()">
                <group-table [groups]="proposalGroups" 
                             [headerInfo]="header" 
                             [groupContent]="content">
                    <ng-template #header let-group>
                      <div fxFlex
                           fxLayout="column" 
                           fxLayoutAlign="space-between stretch"
                           fxLayout.gt-xs="row" 
                           fxLayoutAlign.gt-xs="space-between center">
                        <mat-label class="text-bold mr-32">{{ group.name }}</mat-label>
                        <div fxFlex fxLayout="row">
                          <ng-container *ngFor="let item of group.headerItems" 
                                        [ngTemplateOutlet]="infoItem" 
                                        [ngTemplateOutletContext]="{$implicit:item}">
                          </ng-container>
                        </div>
                        <button mat-icon-button 
                                class="pr-8"
                                (click)="addProposalInGroup(group, $event)">
                          <mat-icon>add_circle_outline</mat-icon>
                        </button>
                      </div>
                    </ng-template>
                    <ng-template #content let-group>
                      <cd-table fxFlex
                                [tabledata]="group.tableData" 
                                [options]="tableOptions"
                                (rowDoubleClick)="operationsController.doubleClickHandler($event)"
                                (onSelectionChange)="updateOperations($event.table)">
                      </cd-table>
                    </ng-template>
                  </group-table>
                  <div class="h-64 pl-16 pt-16">
                    <div fxFlex fxLayout="column">
                      <ng-container *ngFor="let item of footerItems" 
                                    [ngTemplateOutlet]="infoItem" 
                                    [ngTemplateOutletContext]="{$implicit:item}">
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div [fxShow]="isLoading()"
                     class="h-200"
                     fxFlex 
                     fxLayout="row" 
                     fxLayoutAlign="center center">
                  <mat-spinner diameter="30" color="accent"></mat-spinner>
                  <div class="ml-16 text-size-30">{{'PROPOSALS.LOADING' | translate}}</div>
                </div>
          </div>
    </div>
</div>

<ng-template #infoItem let-item>
  <div fxLayout="row">
    <div class="pl-4 fuse-navy-300-fg">{{item.title}}:</div>
    <div class="pl-4">{{item.value}}</div>
  </div>
</ng-template>