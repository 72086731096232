
<div class="page-layout carded fullwidth" fusePerfectScrollbar>

    <div class="top-bg primary"></div>
      <div class="center">
        <div class="table-header primary mt-48 mb-36"
             fxLayout="column" fxLayoutAlign="center center"
             fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between stretch">
                 <div class="logo my-12 m-sm-0"
                      fxLayout="row" fxLayoutAlign="start center">
                     <mat-icon class="logo-icon mr-16">
                         home
                     </mat-icon>
                     <span class="logo-text h1">
                         {{'MAIN.TITLE' | translate}}
                     </span>
                 </div>
        </div>

        <div class="info-tabs-wrapper fuse-white mat-elevation-z2"
             fxLayout="column" 
             fxLayoutAlign="left">
          <div class="text-block" 
               fxLayout="column" 
               fxLayoutAlign="left">
            <p>
              CRD - программный диспетчерско-логистический комплекс для автоматизации управления заказами и логистикой при продаже и доставке материалов автотранспортом.<br>
              Предназначен для обеспечения операционной эффективности компании.<br>
              CRD включает в себя модуль заказчика, менеджера, диспетчера, логиста и водителя.<br>
              Заказчики, менеджеры, логисты и диспетчеры могут использовать как веб-интерфейс, так и мобильное приложение.<br>
              Заявки, полученные от заказчиков, обрабатываются в автоматическом режиме под контролем диспетчера.<br>
              Водители получают заказы, и с помощью мобильного приложения устанавливают статусы их выполнения.<br><br>
              Основные функции программы:<br>
              1. прием, обработка и выполнение заказов,<br>
              2. передача заказов на исполнение водителям и перевозчикам,<br>
              3. отслеживание заказов в режиме онлайн в процессе выполнения,<br>
              4. передача и хранение первичных документов,<br>
              5. отправление отчетов о выполнении этапов заказа,<br>
              6. просмотр выполненных заказов,<br>
              7. отслеживание местонахождения и маршрута автотранспорта,<br>
              8. формирование полного свода данных в Excel (интеграция 1С).<br>
            </p>
          </div>
        </div>

    </div>
</div>
