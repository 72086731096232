
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/material.module';

import { UserRoleGuard, Role } from 'app/guards/user-role-guard.service';

import { ReportsComponent } from './reports.component';
import { DownloadService } from 'app/components/download/download.service';
import { TableHeaderModule} from 'app/components/tableheader/tableheader.module';

const routes = [
  {
    path     : 'reports',
    canActivate: [UserRoleGuard],
    data: {roles: [Role.Administrator, Role.Logistician, Role.Dispatcher, Role.Customer]},
    component: ReportsComponent,
  }
];

@NgModule({
  declarations: [
    ReportsComponent
  ],
  providers: [
    UserRoleGuard,
    DownloadService,
  ],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    MaterialModule,
    TableHeaderModule,
  ],
  exports: [
    ReportsComponent
  ]
})
export class ReportsModule
{
}
