
<h1 matDialogTitle>{{title | translate}}</h1>

<div mat-dialog-content style="width:400px;">
  <div *ngIf="prepearingData">
    <mat-spinner diameter="30" color="accent"></mat-spinner>
  </div>
  <div *ngIf="URL === undefined">{{progressText | translate}}</div>
  <div *ngIf="URL !== undefined">
    <p>{{"Download.Text" | translate}}</p>
    <p><a [href]="URL" [download]="fileName">{{linkText | translate}}</a></p>
  </div>
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button color="accent" [matDialogClose]="true" cdkFocusInitial>{{'Download.Close' | translate}}</button>
</mat-dialog-actions>
