
import { Component, ViewChildren, ViewChild, QueryList } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';

import { TableComponent } from 'app/components/table/table.component';

import { CustomersTableData } from './table-data/customers-tabledata';
import { DriversTableData } from './table-data/drivers-tabledata';
import { SuppliersTableData } from './table-data/suppliers-tabledata';
import { DispatchersTableData } from './table-data/dispatchers-tabledata';
import { ManagersTableData } from './table-data/managers-tabledata';
import { IntermediaryTableData } from './table-data/intermediary-tabledata';
import { CarriersTableData } from './table-data/carrier-tabledata';
import { LogisticiansTableData } from './table-data/logisticians-tabledata';

import { CurrentUser } from 'app/components/data/core/models/base/user';

import { DialogPresenterService } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';

import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'app/components/locale/locale.service';
import { users } from './locale/locale';
import { default_row_operations, common, approval } from "app/main/locale/locale";

import { TransferPointTableData } from './table-data/transfer-point-tabledata';

import { TabInfo } from 'app/components/tab/tab-info';

import { ManagersTableOperationsFactory } from 'app/main/admin/users/operations-factory/managers-table.operations-factory';
import { DispatchersTableOperationsFactory } from 'app/main/admin/users/operations-factory/dispatchers-table.operations-factory';
import { CarriersTableOperationsFactory } from 'app/main/admin/users/operations-factory/carriers-table.operations-factory';
import { IntermediaryTableOperationsFactory } from 'app/main/admin/users/operations-factory/intermediary-table.operations-factory';
import { DriversTableOperationsFactory } from 'app/main/admin/users/operations-factory/drivers-table.operations-factory';
import { CustomersTableOperationsFactory } from 'app/main/admin/users/operations-factory/customers-table.operations-factory';
import { SuppliersTableOperationsFactory } from 'app/main/admin/users/operations-factory/suppliers-table.operations-factory';
import { LogisticiansTableOperationsFactory } from 'app/main/admin/users/operations-factory/logistician-table.operations-factory';


const locales = [
  users,
  default_row_operations,
  common,
  approval,
];

@Component({
  selector: 'users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})

export class UsersComponent {
  public administratorAccess: boolean;
  public tabsList: TabInfo[];
  headerButtons = [];

  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  @ViewChildren(TableComponent) tables: QueryList<TableComponent>;

  constructor(
    private localeService: LocaleService,
    private translate: TranslateService,
    private dialogPresenterService: DialogPresenterService
  ) {
    this.administratorAccess = CurrentUser.isAdministrator() || CurrentUser.isLogistician();
    this.localeService.loadLocales(locales);
    this.tabsList = this.createTabsList();
    this.headerButtons = this.makeHeaderButtons();
  }

  private createTabsList() {
    let tabsData = [];
    if (CurrentUser.isAdministrator() || CurrentUser.isLogistician()) {
      tabsData.push(new TabInfo({
        title: 'USERS.TABS.MANAGERS',
        tableData: new ManagersTableData(),
        operationsFactory: new ManagersTableOperationsFactory(this.dialogPresenterService, () => { this.reloadCurrentTableData() }),
      }));
      tabsData.push(new TabInfo({
        title: 'USERS.TABS.DISPATCHERS',
        tableData: new DispatchersTableData(),
        operationsFactory: new DispatchersTableOperationsFactory(this.dialogPresenterService, () => { this.reloadCurrentTableData() }),
      }));
      tabsData.push(new TabInfo({
        title: 'USERS.TABS.CARRIERS.TITLE',
        tableData: new CarriersTableData(this.translate),
        operationsFactory: new CarriersTableOperationsFactory(this.dialogPresenterService, () => { this.reloadCurrentTableData() }),
      }));
      tabsData.push(new TabInfo({
        title: 'USERS.TABS.INTERMEDIARY',
        tableData: new IntermediaryTableData(),
        operationsFactory: new IntermediaryTableOperationsFactory(this.dialogPresenterService, () => { this.reloadCurrentTableData() }),
      }));
    }

    if (CurrentUser.isAdministrator()) {
      tabsData.push(new TabInfo({
        title: 'USERS.TABS.LOGISTICIAN',
        tableData: new LogisticiansTableData(),
        operationsFactory: new LogisticiansTableOperationsFactory(this.dialogPresenterService, () => { this.reloadCurrentTableData() }),
      }));
    }

    if (CurrentUser.isAdministrator() || CurrentUser.isLogistician() || CurrentUser.isManager() || CurrentUser.isDispatcher()) {
      tabsData.push(new TabInfo({
        title: 'USERS.TABS.DRIVERS.TITLE',
        tableData: new DriversTableData(this.translate),
        operationsFactory: new DriversTableOperationsFactory(this.dialogPresenterService, () => { this.reloadCurrentTableData() }),
      }));
    }

    if (CurrentUser.isAdministrator() || CurrentUser.isLogistician() || CurrentUser.isManager()) {
      tabsData.push(new TabInfo({
        title: 'USERS.TABS.CUSTOMERS.TITLE',
        tableData: new CustomersTableData(),
        operationsFactory: new CustomersTableOperationsFactory(this.dialogPresenterService, () => { this.reloadCurrentTableData() }),
      }));
      tabsData.push(new TabInfo({
        title: 'USERS.TABS.SUPPLIERS.TITLE',
        tableData: new SuppliersTableData(),
        operationsFactory: new SuppliersTableOperationsFactory(this.dialogPresenterService, false, () => { this.reloadCurrentTableData() }),
      }));
      tabsData.push(new TabInfo({
        title: 'USERS.TABS.TRANSFER_POINT.TITLE',
        tableData: new TransferPointTableData(),
        operationsFactory: new SuppliersTableOperationsFactory(this.dialogPresenterService, true, () => { this.reloadCurrentTableData() }),
      }));
    }
    return tabsData;
  }

  currentTable() {
    return this.tables.toArray()[this.tabGroup.selectedIndex];
  }

  tabChanged() {
    this.reloadCurrentTableData();
  }

  reloadCurrentTableData() {
    this.currentTable().reloadData();
  }

  search(searchString: string) {
    this.currentTable().search(searchString);
  }

  private makeHeaderButtons() {
    return [
      {
        'title': 'COMMON.BUTTONS.RELOAD',
        'action': () => this.reloadCurrentTableData()
      },
    ];
  }
}
