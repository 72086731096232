export const locale = {
  'ORDER': {
    'TABLEROW': {
      'INPOINT': 'Точка загрузки',
      'OUTPOINT': 'Точка разгрузки',
      'WEIGHT': 'Тоннаж',
      'USERNAME': 'Автор',
      'CREATEDATE': 'Создан',
      'STATUS': 'Статус',
      'BRAND': 'Марка товара',
      'CUSTOMER': ' Покупатель',
      'UNLOAD_DATE': 'Дата разгрузки',
      'REQUIRED_EQUIPMENT': 'Требуемое оборудование',
      'COMMENT': 'Комментарий',
    },
    'STATUS': {
      'IN_WORK': 'В работе',
      'IN_WORK_BY_USER': "В работе у '{{user}}'",
      'NOT_AGREE': "Не согласован",
      'AGREE': "Cогласован",
      'CARRIER': {
        'ACCEPTED' : 'Подтвержден',
        'DECLINED' : 'Отказ',
        'UNACCEPTED' : 'Не подтвержден',
      },
      'CUSTOMER': {
        'PROCESSING': 'Обрабатывается',
        'HANDED_TO_DRIVER': 'Передан водителю',
        'ACCEPTED_BY_CARRIER': 'Принят перевозчиком',
      },
    },
  },
};
