import { Component, ViewChild } from '@angular/core';

import { LocaleService } from 'app/components/locale/locale.service';
import { proposals } from '../../locale/locale';
import { default_row_operations, common, equipment, orders } from '../../../locale/locale';
import { ProposalsForCarrierTableData } from './proposals-list-for-carrier.tabledata';
import { DateSelectorComponent } from 'app/components/date-selector/date-selector.component';
import { TableComponent } from 'app/components/table/table.component';
import { DialogPresenterService } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import { TableOperationsController } from 'app/components/table-operations-controller/table-operations-controller';
import { ProposalForCarriersTableOperationsFactory } from '../../operations-factory/proposals-for-carriers.operation-factory';
import { TranslateService } from '@ngx-translate/core';
import { DateHelper } from 'app/components/helpers/date.helper';

const locales = [
  proposals,
  common,
  default_row_operations,
  orders,
  equipment,
];

@Component({
  selector: 'proposals-list-for-carrier',
  templateUrl: './proposals-list-for-carrier.component.html',
  styleUrls: ['./proposals-list-for-carrier.component.scss']
})
export class ProposalsListForCarrierComponent {
  tableData: ProposalsForCarrierTableData;
  operationsController: TableOperationsController;

  initialDate = DateHelper.nextDay(DateHelper.setDayBegin(new Date()));
  
  constructor(
    private localeService: LocaleService,
    private dialogPresenter: DialogPresenterService,
    private translate: TranslateService,
  ) {
    this.localeService.loadLocales(locales);
    this.tableData = new ProposalsForCarrierTableData(this.translate, this.initialDate);
    let factory = new ProposalForCarriersTableOperationsFactory(this.dialogPresenter, this.translate);
    this.operationsController = new TableOperationsController(factory);
  }

  @ViewChild(DateSelectorComponent) private dateSelector: DateSelectorComponent;
  @ViewChild(TableComponent) private table: TableComponent;

  search(searchString: string) {
     this.table.search(searchString);
  }

  reloadOrders() {
    this.tableData = new ProposalsForCarrierTableData(this.translate, this.dateSelector.selectedDate);
  }
  
}

