
export class XMLBuilderClosure {

  private text = "";

  constructor(private indentLevel: number) {}

  addTag(name: string, attributes?: any, content?: any): void {
    this.text += this.indent();
    this.text += "<" + name;

    if (attributes) {
      for (let key in attributes) {
        if (attributes.hasOwnProperty(key)) {
          this.text += " " + key + "=\"" + attributes[key] + "\"";
        }
      }
    }

    this.text += ">";

    if (content) {
      if (typeof content === "string") {
        this.text += content;
      }
      else {
        this.text += "\n";
        let closure = new XMLBuilderClosure(this.indentLevel + 1);
        content(closure);
        this.text += closure.getText();
        this.text += this.indent();
      }
    }

    this.text += "</" + name + ">\n";
  }

  getText(): string {
    return this.text;
  }

  private indent(): string {
    let indent = "";
    for (let index = 0; index < this.indentLevel; ++index) {
      indent += "  ";
    }
    return indent;
  }

}

export class XMLBuilder {

  static build(mainTagName: string, mainTagAttributes?: any, content?: any): string {
    let text = "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n";

    let closure = new XMLBuilderClosure(0);
    closure.addTag(mainTagName, mainTagAttributes, content);
    text += closure.getText();

    return text;
  }

}
