import { Component, Input, ViewEncapsulation } from '@angular/core';

import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { Order } from 'app/components/data/core/models/order/order';

import { TranslateService } from '@ngx-translate/core';

@Component({
    selector   : 'info-form',
    templateUrl: './info-form.component.html',
    styleUrls  : ['./info-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class InfoFormComponent {

  @Input()
  order: Order;

  public form: UntypedFormGroup;

  constructor(
    private translate: TranslateService,
    private _formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    let unloadingBeginDate = this.order.unloadingBeginDate()
    let unloadingBeginTime = unloadingBeginDate.getHours();
    let unloadingEndDate = this.order.unloadingEndDate() || unloadingBeginDate;
    let unloadingEndTime = unloadingEndDate.getHours();
    var unloadingDateText = unloadingBeginDate.toLocaleDateString()
    unloadingDateText += ' ' + unloadingBeginTime + ':00';
    if (unloadingEndTime > unloadingBeginTime) {
      unloadingDateText += ' - ' + unloadingEndTime + ':00';
    }
    
    let unloadingPoint = this.order.unloadingPoint();
    let unloadingAddress = " ";
    if (unloadingPoint != null) {
      unloadingAddress = unloadingPoint.getAddress();
    }

    let manager = this.order.unloadingPoint().getManager();
    let contactName;
    let contactPhone;
    if (manager != null) {
      contactName = manager.getName();
      contactPhone = manager.getPhoneNumber();
    } 

    let undef_val = this.translate.instant('ORDER.UNDEFINED');
    this.form = this._formBuilder.group({
      unloadingEntrance : [{value: unloadingAddress, disabled:true}],
      unloadingDate     : [{value: unloadingDateText, disabled:true}],

      articleType       : [{value: this.order.articleBrand().type().name(), disabled:true}],
      articleBrand      : [{value: this.order.articleBrand().name(), disabled:true}],
      tonnage           : [{value: this.order.tonnage() || undef_val , disabled:true}],
      comment           : [{value: this.order.comment() || '', disabled:true}],

      contactName       : [{value: contactName || undef_val, disabled:true}],
      contactPhone      : [{value: contactPhone || undef_val, disabled:true}],
    });
  }
}
