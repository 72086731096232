
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { Waypoint } from 'app/components/data/core/models/points/points';
import { Trip } from 'app/components/data/core/models/trip/trip';
import { PFQuery } from 'app/components/data/core/models/base/query';

import { GPXBuilder, GPXBuilderTrackPoint } from 'app/components/track-download/GPXBuilder';

import { DownloadComponent } from 'app/components/download/download.component';

import { locale as english } from './locale/en';
import { locale as russian } from './locale/ru';

@Injectable()
export class TrackDownloadDialog {

  constructor(
    private dialog: MatDialog,
    private translateService: TranslateService,
    private translationLoaderService: FuseTranslationLoaderService
  ) {
    this.translationLoaderService.loadTranslations(english, russian);
  }

  open(trip: Trip): MatDialogRef<DownloadComponent> {
    return this.dialog.open(DownloadComponent, {data: {
      fileDataPromise: this.getFileData(trip),
      fileType: "application/gpx+xml",
      fileName: "track.gpx",

      title: this.translateService.instant("TrackDownload.Title"),
      progressText: this.translateService.instant("TrackDownload.ProgressText"),
      linkText: this.translateService.instant("TrackDownload.LinkText"),
    }});
  }

  private getFileData(trip: Trip): Promise<string> {
    return new Promise((resolve) => {
      let query = new PFQuery('Waypoint');
      query.equalTo("trip", trip);
      query.ascending("index");
      query.findAll().then((waypoints) => {
        let text = this.writeGPX(trip, waypoints);
        resolve(text);
      });
    });
  }

  private writeGPX(trip: Trip, waypoints: Waypoint[]): string {
    waypoints.sort((a, b) => {
      let d1 = a.getDate();
      let d2 = b.getDate();
      if (d1 > d2) {
        return 1;
      } else if (d2 > d1) {
        return -1;
      }
      return 0;
    });

    let trackPoints = waypoints.map(waypoint => new GPXBuilderTrackPoint(
      waypoint.getCoordinate().latitude,
      waypoint.getCoordinate().longitude,
      waypoint.getDate(),
      waypoint.getSpeed()
    ));

    return GPXBuilder.build(
      new Date(),
      this.translateService.instant("TrackDownload.GPX.Name", {orderNumber: trip.order().number()}),
      this.translateService.instant("TrackDownload.GPX.Description", {driverName: trip.transportUnit().driver().getName()}),
      trackPoints
    );
  }
}
