import { Component, ViewEncapsulation, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { Parse } from "parse";
import * as LiveQuery from "app/components/live-query/live-query";
import { Order } from 'app/components/data/core/models/order/order';

import { CurrentUser } from 'app/components/data/core/models/base/user';
import { Offer } from 'app/components/data/core/models/offer';
import { Trip } from 'app/components/data/core/models/trip/trip';

import { animate, state, style, transition, trigger } from '@angular/animations';

import { TableRowData, RowParameters } from 'app/components/table/tabledata';

import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'app/components/locale/locale.service';
import { orderinfo, order_fields } from '../../locale/locale';
import { offers } from 'app/main/locale/locale';
import { Observable } from 'rxjs';

let locales = [
  orderinfo,
  order_fields,
  offers
];

@Component({
    selector   : 'order-info-dialog',
    templateUrl: './order-info-dialog.component.html',
    styleUrls  : ['./order-info-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class OrderInfoDialogComponent {

  public order: Order;
  public dataSource;
  public expandedElement: Offer;
  public columns = [];
  public displayedColumns;
  
  private orderUpdate: Observable<Parse.Object>;

  constructor (
    public dialogRef: MatDialogRef<OrderInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,

    private translate: TranslateService,
    private localeService: LocaleService,
  ) {
    this.localeService.loadLocales(locales);

    this.order = this._data.object;

    this.dataSource = new MatTableDataSource(this.filteredOffers());

    let handler = this;
    this.columns = [
      new TableRowData({
        key: 'tonnage',
        header: 'ORDERINFO.TABLEROW.TONNAGE',
        cell: (offer: Offer) => `${offer.tonnage()}`,
        params: new RowParameters('12')
      }),
      new TableRowData({
        key: 'driver_name',
        header: 'ORDERINFO.TABLEROW.DRIVER.NAME',
        cell: (offer: Offer) => `${offer.transportUnit().driver().getName()}`
      }),
      new TableRowData({
        key: 'driver_phone',
        header: 'ORDERINFO.TABLEROW.DRIVER.PHONE',
        cell: (offer: Offer) => `${offer.transportUnit().driver().phoneNumber() || "-"}`
      }),
      new TableRowData({
        key: 'auto',
        header: 'ORDERINFO.TABLEROW.AUTO',
        cell: (offer: Offer) => `${offer.transportName()}`
      }),
      new TableRowData({
        key: 'auto_number',
        header: 'ORDERINFO.TABLEROW.AUTONUMBER',
        cell: (offer: Offer) => `${offer.transportUnit().vehicle().number()}`
      }),
      new TableRowData({
        key: 'trailer_number',
        header: 'ORDERINFO.TABLEROW.TRAILER_NUMBER',
        cell: (offer: Offer) => `${handler.trailerNumber(offer)}`
      }),
      new TableRowData({
        key: 'state',
        header: 'ORDERINFO.TABLEROW.STATE',
        cell: (offer: Offer) => `${handler.statusForOffer(offer)}`
      }),
    ];
    this.displayedColumns = this.columns.map(x => x.key);
    this.orderUpdate = LiveQuery.observeObject(Order, this.order.id);
  }

  statusForOffer(offer) {
    let trip = offer.trip();
    if (trip) {
      return this.translate.instant(Trip.tripStageName(trip.stage()));
    } else {
      return this.translate.instant(offer.getStatusString());
    }
  }

  clickOnRow(offer) {
    if (this.expandedElement === offer) {
      this.expandedElement = null;
    } else {
      if (offer.trip() && offer.trip().historyRecords()) {
        this.expandedElement = offer;
      }
    }
  }

  hasOffers(): boolean {
    let offers = this.filteredOffers();
    return offers && offers.length > 0; 
  }

  private trailerNumber(offer: Offer) {
    let tu = offer.transportUnit();
    let trailer = tu.trailer();
    if (trailer) {
      return trailer.number();
    }
    return '';
  }

  private filteredOffers(): Offer[] {
    let offers = this.order.offers();
    if (!CurrentUser.isDispatcher()) {
      return offers;
    }
    let user = CurrentUser.user();
    return offers.filter((offer) => {
      return offer.transportUnit().driver().getCarrier().id === user.carrier().id;
    });
  }
}
