
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthenticationService } from 'app/authentication/authentication.service';

@Injectable()
export class LoginGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthenticationService) {}

  canActivate(): boolean {
    this.authService.checkSession();
    if (!this.authService.isAuthenticated()) {
      return true;
    }
    this.router.navigate([this.authService.defaultRoute()]);
    return false;
  }

}
