export const locale = {
  'DISBAND': {
    'TITLE': 'Disband',
    'MESSAGE': 'Are you sure you want to disband this units?',
    'UNIT': 'Driver: {{driverName}}, {{vehicleModel}} {{vehicleNumber}}',
    'CONFIRM': 'Yes, disband',
    'DECLINE': 'Cancel',
    'ERROR': 'Disband failed',
    'EXECUTING': 'Disbanding {{cur}}/{{total}}',
    },
};
