import { Role, User } from 'app/components/data/core/models/base/user';
import { TranslateService } from '@ngx-translate/core';

export interface Target {
    role?: string,
    userId?: string,
    name: string,
}

export namespace TargetsHelper {
    export function localizedTargetRole(role: Role, translate: TranslateService) {
        var targetRole = "MESSAGES.TARGET.";
        switch (role) {
            case Role.Customer: targetRole += "CUSTOMERS"; break;
            case Role.Supplier: targetRole += "SUPPLIERS"; break;
            case Role.Manager: targetRole += "MANAGERS"; break;
            case Role.Driver: targetRole += "DRIVERS"; break;
            case Role.Dispatcher: targetRole += "DISPATCHERS"; break;
            default: targetRole += "UNDEFINED"; break;
        }
        return translate.instant(targetRole);
    }

    export function makeTargetForRole(role: Role, translate: TranslateService) {
        return {
            role: role,
            name: TargetsHelper.localizedTargetRole(role, translate),
        }
    }

    export function makeTargetForUser(user: User, translate: TranslateService) {
        let localizedRole = translate.instant('USERS.ROLE.' + user.role());
        let name = user.name();
        return {
            userId: user.id,
            name: `${name} (${localizedRole})`,
        }
    }
}