import { VehiclePassTimeOfAction } from 'app/components/data/core/models/transport/vehicle-pass';
import { PassesHelper } from 'app/components/helpers/passes.helper';
import { VehiclePassTimeOfActionItem } from '../model/time.model';

export namespace VehiclePassTimeOfActionItemFactory {

    export function compare(o1: VehiclePassTimeOfActionItem, o2: VehiclePassTimeOfActionItem) {
        return o1.time == o2.time;
    }

    export function makeDefault(): VehiclePassTimeOfActionItem[] {
        return [
            makeWithActionTime(VehiclePassTimeOfAction.Day),
            makeWithActionTime(VehiclePassTimeOfAction.Night)
        ]
    }

    export function makeWithActionTime(time: VehiclePassTimeOfAction): VehiclePassTimeOfActionItem {
        return { 
            time: time,
            name: PassesHelper.timeOfActionStringFor(time)
        }
    }
}