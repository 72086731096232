
export const locale = {
  'DIALOG' : {
    'LOGISTICIAN' : {
      'TITLE' : {
        'CREATE' : 'Create dispatcher',
        'EDIT' : 'Edit dispatcher',
      },
      'CONFIRMBUTTON' : {
        'CREATE' : 'Create',
        'SAVE' : 'Save',
      },
      'FULLNAME' : 'Full name',
    },
    'ERROR' : {
      'ALREADYEXISTS' : 'Account already exists for this username.',
    }
  },
};
