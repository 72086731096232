
import { XMLBuilder, XMLBuilderClosure } from "app/components/track-download/XMLBuilder";

export class GPXBuilderTrackPoint {
  constructor(public latitude: number, public longitude: number, public date: Date, public speed: number) {}
}

export class GPXBuilder {

  static build(date: Date, name: string, description: string, trackPoints: GPXBuilderTrackPoint[]): string {
    return GPXBuilder.addMain((closure) => {
      closure.addTag("time", null, GPXBuilder.formatDate(date));
      closure.addTag("metadata", null, (closure) => {
        closure.addTag("name", null, name);
        closure.addTag("desc", null, description);
      });
      closure.addTag("trk", null, (closure) => {
        closure.addTag("trkseg", null, (closure) => {
          trackPoints.forEach((trackPoint) => {
            closure.addTag("trkpt", {lat: `${trackPoint.latitude}`, lon: `${trackPoint.longitude}`}, (closure) => {
              closure.addTag("time", null, this.formatDate(trackPoint.date));
              closure.addTag("speed", null, `${trackPoint.speed}`);
            });
          });
        });
      });
    });
  }

  private static addMain(content): string {
    let attributes = {};

    attributes["xmlns"] = "http://www.topografix.com/GPX/1/1";
    attributes["version"] = "1.1";
    attributes["creator"] = "Macsoftex";
    attributes["xmlns:xsi"] = "http://www.w3.org/2001/XMLSchema-instance";
    attributes["xsi:schemaLocation"] = "http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd";

    return XMLBuilder.build("gpx", attributes, content);
  }

  private static formatDate(date: Date): string {
    let year = `${date.getUTCFullYear()}`;
    let month = GPXBuilder.pad(date.getUTCMonth() + 1);
    let day = GPXBuilder.pad(date.getUTCDate());
    let hour = GPXBuilder.pad(date.getUTCHours());
    let minute = GPXBuilder.pad(date.getUTCMinutes());
    let second = GPXBuilder.pad(date.getUTCSeconds());
    return year + "-" + month + "-" + day + "T" + hour + ":" + minute + ":" + second + "Z";
  }

  private static pad(value: number): string {
    let text = `${value}`;
    while (text.length < 2) {
      text = "0" + text;
    }
    return text;
  }

}
