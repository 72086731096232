import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { ArticleShape } from 'app/components/data/core/models/articles/article';

import { LocaleService } from 'app/components/locale/locale.service';
import { createshape } from '../../locale/locale';
import { CloudExecutor } from 'app/components/data/cloud-executors/cloud-executor';
import * as FormHelper from 'app/components/form-helper/form-helper';

@Component({
    selector   : 'create-article-shape-dialog',
    templateUrl: './create-article-shape-dialog.component.html',
    styleUrls  : ['./create-article-shape-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CreateArticleShapeDialog {

  public form: UntypedFormGroup;
  public object: ArticleShape;

  public title: string;
  public buttonName: string;

  public dataSaver = new CloudExecutor();

  constructor (
    private localeService: LocaleService,
    public dialogRef: MatDialogRef<CreateArticleShapeDialog>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: UntypedFormBuilder
  ) {
    this.localeService.loadLocale(createshape);
    this.object = this._data.object;
    if (!this.object) {
      this.object = new ArticleShape();
    }

    if (this._data.action === 'create')
    {
        this.title = 'CREATESHAPE.TITLE.CREATE';
        this.buttonName = 'CREATESHAPE.CONFIRMBUTTON.CREATE';
    }
    else if (this._data.action === 'edit')
    {
        this.title = 'CREATESHAPE.TITLE.EDIT';
        this.buttonName = 'CREATESHAPE.CONFIRMBUTTON.SAVE';
    }
    this.form = this.createContactForm();
  }

  createContactForm(): UntypedFormGroup
  {
    return this._formBuilder.group({
                name       : [this.object.name()],
              });
  }

  checkAndSave() {
    let verified = this.checkForm();

    if (verified) {
      this.object.setName(this.form.controls.name.value);
      this.dataSaver.saveObject(this.object).then(obj => {
        this.dialogRef.close(obj);
      });
    }
  }

  checkForm() : boolean {
    return FormHelper.checkForm(this.form);
  }
}
