import { Component, ViewChild } from '@angular/core';

import { LocaleService } from 'app/components/locale/locale.service';
import { proposals } from '../../locale/locale';
import { default_row_operations, common, orders, tripStage, offers, units, equipment } from '../../../locale/locale';
import { PFQuery } from 'app/components/data/core/models/base/query';
import { Order, OrdersFilter } from 'app/components/data/core/models/order/order';

import { PFObject } from 'app/components/data/core/models/base/object';
import { ProposalsBySupplierTableData } from './proposals-list-by-supplier.tabledata';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Supplier } from 'app/components/data/core/models/persons/supplier';
import { DateHelper } from 'app/components/helpers/date.helper';
import { ProposalHelper } from '../../helpers/proposal-info.helper';
import { Group } from 'app/components/group-table/group-table.component';
import { ArticleBrand } from 'app/components/data/core/models/articles/article';
import { DateSelectorComponent } from 'app/components/date-selector/date-selector.component';
import { defaultTableOptions, TableComponent, TableOptions } from 'app/components/table/table.component';
import { TableOperationsController } from 'app/components/table-operations-controller/table-operations-controller';
import { ProposalTableOperationsFactory } from '../../operations-factory/proposals.operation-factory';
import { DialogPresenterService } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import { PurchaseTariff, Tariff, TariffFilter } from 'app/components/data/core/models/tariff/tariff';
import { SearchHelper } from 'app/components/helpers/search.helper';
import { ProposalsLoader } from '../../proposals-loader';
import { DataLoader } from 'app/components/data/data-loader';
import { ConfigurationService } from 'app/main/admin/settings/configuration.service';
import { filterDeleted } from 'app/components/access/query';
import { QueryResultFilter } from 'app/components/access/queryResultFilter';

import { GroupHeaderItem } from '../common/header-item';

const locales = [
  proposals,
  common,
  default_row_operations,
  orders,
  tripStage,
  offers,
  units,
  equipment,
];

interface BrandGroup extends Group {
  name: string,
  headerItems: GroupHeaderItem[],
  brand: ArticleBrand,
  tableData: ProposalsBySupplierTableData,
}

interface SupplierGroup extends Group {
  name: string,
  headerItems: GroupHeaderItem[],
  supplier: Supplier,
  brandGroups: BrandGroup[],
}

@Component({
  selector: 'proposals-list-by-supplier',
  templateUrl: './proposals-list-by-supplier.component.html',
  styleUrls: ['./proposals-list-by-supplier.component.scss']
})
export class ProposalsListBySupplierComponent {
  compareFunction = PFObject.compareFn;

  initialDate = DateHelper.nextDay(DateHelper.setDayBegin(new Date()));

  supplierGroups: SupplierGroup[];
  footerItems = [];

  tableOptions: TableOptions;
  operationsController: TableOperationsController;

  private proposalsLoader = new ProposalsLoader();
  private tariffsData = new DataLoader('PurchaseTariff');
  private suppliersData = new DataLoader('Supplier');

  private availableSuppliers: Supplier[];

  private operationsFactory: ProposalTableOperationsFactory;
  private lastSelectedTable: TableComponent;
  private searchFilter;

  @ViewChild(DateSelectorComponent) private dateSelector: DateSelectorComponent;

  constructor(
    private localeService: LocaleService,
    private translate: TranslateService,
    private dialogPresenter: DialogPresenterService,
    private configService: ConfigurationService,
  ) {
    this.tariffsData.filterDeleted = false;
    
    this.localeService.loadLocales(locales);
    this.tableOptions = defaultTableOptions();
    this.tableOptions.hiddenPaginator = true;

    this.operationsFactory = new ProposalTableOperationsFactory(
      this.dialogPresenter,
      this.translate,
      () => this.makeDialogData());

    this.operationsController = new TableOperationsController(this.operationsFactory);
    this.suppliersData.setQueryModificationBlock((query: PFQuery) => {
      query.includes([
        'articleBrands.type',
        'loadingPoint',
        'supplier.loadingPoints.entrances',
      ]);
      query.exists('articleBrands');
      return filterDeleted(query);
    });
  }

  ngOnInit() {
    this.load();
    this.proposalsLoader.items.subscribe((orders) => {
      if (orders) this.reloadContent();
    });
  }

  private async load() {
    await this.configService.fetch();
    await this.suppliersData.loadAll();
    await this.tariffsData.loadAll();
    let suppliers = this.suppliersData.getLoadedItems();
    let suppliersOrder = this.configService.getConfiguration().getSupplierOrder();
    this.availableSuppliers = PFObject.sort(suppliers, suppliersOrder);
    this.reloadOrders();
  }

  search(searchString: string) {
    this.searchFilter = searchString;
    this.reloadContent()
  }

  addProposalInGroup(group: SupplierGroup, brandGroup: BrandGroup, event) {
    event.stopPropagation();
    this.operationsFactory.addProposal(group.supplier, brandGroup.brand, this.dateSelector.selectedDate);
  }

  isLoading(): boolean {
    return this.proposalsLoader.isLoading() || this.tariffsData.isLoading() || this.suppliersData.isLoading();
  }

  async reloadOrders() {
    this.updateOperations(null);
    let selectedDate = this.dateSelector.selectedDate;
    let startDate = DateHelper.prevDay(DateHelper.setDayBegin(new Date(selectedDate)));
    let endDate = DateHelper.setDayEnd(new Date(selectedDate));
    this.proposalsLoader.setModifyBlock((query: PFQuery) => {
      query.exists('supplier');
      query.greaterThanOrEqualTo("unloadingBeginDate", startDate);
      query.lessThanOrEqualTo("unloadingBeginDate", endDate);
      return query;
    });
    this.proposalsLoader.reloadData();
  }

  private makeDialogData() {
    return { date: this.dateSelector.selectedDate };
  }

  private reloadContent() {
    let selectedDate = this.dateSelector.selectedDate;
    let orders = this.proposalsLoader.items.getValue();
    let groups: SupplierGroup[] = [];
    let tariffs = this.tariffsData.getLoadedItems();

    let filteredTodayOrders = [];
    for (let supplier of this.availableSuppliers) {
      let supplierName = supplier.getName();
      let supplierNotFiltered = SearchHelper.isContained(supplierName, this.searchFilter);
      let ordersBySupplier = OrdersFilter.filterOrdersBySupplier(orders, supplier);
      let todayDayOrders = OrdersFilter.filterOrdersByUnloadingBeginDay(ordersBySupplier, selectedDate);
      let brandGroups: BrandGroup[] = [];
      let supplierTariffs = TariffFilter.filterTariffWithSupplier(tariffs, supplier);
      let brands = QueryResultFilter.filterDeleted(supplier.getBrands());
      let hasExpandedBrandGroup = false;
      let supplierOrders = [];
      let supplierGroup;
      if (this.supplierGroups) {
        supplierGroup =  this.supplierGroups.find(g=> {
          return PFObject.compareFn(g.supplier, supplier);
        });
      }
      for (let brand of brands) {
          let brandName = brand.name();
          let ordersByBrand = OrdersFilter.filterOrdersByBrand(ordersBySupplier, brand);
          let today = OrdersFilter.filterOrdersByUnloadingBeginDay(ordersByBrand, selectedDate);
          let dayBeforeOrders = OrdersFilter.filterOrdersByUnloadingBeginDay(ordersByBrand, DateHelper.prevDay(selectedDate));
          let remains = OrdersFilter.filterRemains(dayBeforeOrders);
          
          if (!supplierNotFiltered && !SearchHelper.isContained(brandName, this.searchFilter)) {
            today = OrdersFilter.filterProposalsNotContainedText(today, this.searchFilter);
            if (today.length === 0) continue;
          }

          supplierOrders = supplierOrders.concat(today);
          if (today.length > 0) {
            hasExpandedBrandGroup = true;
          }
          let brandGroup;
          if (supplierGroup) {
            brandGroup = supplierGroup.brandGroups.find(bg=> PFObject.compareFn(bg.brand, brand));
            if (brandGroup) {
              brandGroup.headerItems = this.makeBrandHeader(today, remains.length);
              brandGroup.tableData = new ProposalsBySupplierTableData(today, this.translate);
            }
          } 
          
          if (!brandGroup) {
            brandGroup = this.makeGroupForBrand(brand, today, remains);
          }
          brandGroup.expandedByDefault = (today.length > 0);
          brandGroups.push(brandGroup);
      }
      if (supplierNotFiltered || brandGroups.length) {
        filteredTodayOrders = filteredTodayOrders.concat(supplierOrders);
        if (supplierGroup) {
          supplierGroup.headerItems = this.makeSupplierHeader(supplierOrders, supplierTariffs);
          supplierGroup.brandGroups = brandGroups;
        } else {
          supplierGroup = this.makeGroupForSupplier(supplier, supplierOrders, supplierTariffs, brandGroups);
        }
        supplierGroup.expandedByDefault = hasExpandedBrandGroup;
        groups.push(supplierGroup);
      }
    }
    
    this.supplierGroups = groups;
    this.footerItems = this.footerItemsForOrders(filteredTodayOrders, tariffs);
  }

  private makeGroupForBrand(brand: ArticleBrand, orders: Order[], remains: Order[]) {
    let brandName = brand.name();
    let headerColor = 'rgb(242,242,242)';
    if (brand.isDeleted()) {
      brandName += " (deleted)";
      headerColor = 'rgb(203,203,203)';
    }
    return {
      identifier: brandName,
      headerClass: 'pl-16',
      headerStyle: { 'background-color': headerColor},
      contentClass: { 'px-16': true },
      name: brandName,
      headerItems: this.makeBrandHeader(orders, remains.length),
      brand: brand,
      tableData: new ProposalsBySupplierTableData(orders, this.translate),
    }
  }

  private makeGroupForSupplier(
    supplier: Supplier, 
    orders: Order[],
    tariffs: Tariff[], 
    brandGroups: BrandGroup[],) {
    let supplierName = supplier.getName();
    let headerColor = '#C0F3FF';
    if (supplier.isDeleted()) {
      supplierName += " (deleted)";
      headerColor = 'rgb(203,203,203)';
    }
    return {
      identifier: supplierName,
      headerStyle: { 'background-color': headerColor},
      name: supplierName,
      headerItems: this.makeSupplierHeader(orders, tariffs),
      supplier: supplier,
      brandGroups: brandGroups,
    }
  }

  updateOperations(table: TableComponent) {
    if (this.lastSelectedTable && this.lastSelectedTable != table) {
      this.lastSelectedTable.deselectAll();
    }
    this.lastSelectedTable = table;
    this.operationsController.updateOperations(table);
  }

  supplierName(group) {
    return group.supplier.getName();
  }

  brandName(group) {
    return group.brand.name();
  }

  private makeSupplierHeader(orders: Order[], tariffs: PurchaseTariff[]): GroupHeaderItem[] {
    let tonnage = ProposalHelper.totalTonnage(orders);
    return [
      {
        title: this.translate.instant("PROPOSALS.HEADER.PROPOSALS"),
        value: orders.length,
      },
      {
        title: this.translate.instant("PROPOSALS.HEADER.COUNT"),
        value: tonnage,
      },
      {
        title: this.translate.instant("PROPOSALS.HEADER.SUMMARY"),
        value: ProposalHelper.totalPrice(orders, tariffs),
      },
    ];
  }

  private makeBrandHeader(orders: Order[], remains: number): GroupHeaderItem[] {
    let tonnage = ProposalHelper.totalTonnage(orders);
    return [
      {
        title: this.translate.instant("PROPOSALS.HEADER.PROPOSALS"),
        value: orders.length,
      },
      {
        title: this.translate.instant("PROPOSALS.HEADER.COUNT"),
        value: tonnage,
      },
      {
        title: this.translate.instant("PROPOSALS.HEADER.REMAINS"),
        value: remains,
      },
    ];
  }

  private footerItemsForOrders(orders, tariffs: PurchaseTariff[]) {
    return [
      {
        title: this.translate.instant("PROPOSALS.FOOTER.TOTAL"),
        value: orders.length,
      },
      {
        title: this.translate.instant("PROPOSALS.FOOTER.TOTAL_PRICE"),
        value: ProposalHelper.totalPrice(orders, tariffs),
      },
    ];
  }
}

