
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { AuthenticationService } from 'app/authentication/authentication.service';
import { LoginGuard } from 'app/guards/login-guard.service';
import { LoginComponent } from 'app/authentication/login/login.component';

const routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard],
  }
];

@NgModule({
  declarations: [
    LoginComponent
  ],
  providers: [
    AuthenticationService,
    LoginGuard,
  ],
  imports     : [
    RouterModule.forChild(routes),

    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,

    TranslateModule,
    FuseSharedModule,
  ],
  exports: [
    LoginComponent
  ]
})
export class LoginModule
{
}
