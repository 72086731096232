import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { FinishedOrdersListComponent } from './finished-orders.component';
import { MaterialModule } from 'app/main/material.module';

import { TableHeaderModule} from 'app/components/tableheader/tableheader.module';
import { TableModule } from 'app/components/table/table.module';

import { OrderInfoModule } from 'app/main/orders/dialog/orderinfo/order-info-dialog.module';
import { DeleteOrderDialogModule } from 'app/main/orders/dialog/deleteconfirmation/delete-order-dialog.module';

import { OrdersDialogPresenterService } from 'app/main/orders/dialog/ordersdialogspresenter/orders-dialog-presenter.service'

import { ActionBarModule } from 'app/components/action-bar/action-bar.module';
@NgModule({
    declarations: [
        FinishedOrdersListComponent,
                  ],
    imports     : [
        TranslateModule,

        FuseSharedModule,

        MaterialModule,
        TableHeaderModule,
        TableModule,
        OrderInfoModule,
        DeleteOrderDialogModule,
        ActionBarModule,
    ],
    exports     : [
        FinishedOrdersListComponent,
    ],
    providers: [
      OrdersDialogPresenterService,
    ],
})

export class FinishedOrdersListModule
{
}
