export const locale = {
    'DIALOG': {
        'KILOMETERS': {
          'TITLE': {
            'CREATE': 'Добавить километраж',
            'EDIT': 'Изменить километраж',
          },
          'CONFIRMBUTTON': {
            'CREATE': 'Добавить',
            'SAVE': 'Сохранить',
          },
          'CUSTOMER': 'Покупатель',
          'UNLOADING_POINT': 'Адрес разгрузки',
          'SUPPLIER': 'Поставщик',
          'LOADING_POINT': 'Адрес загрузки',
          'DISTANCE': 'Километраж, км',
          'ERROR': {
            'UNDEFINED': 'Произошла неизвестная ошибка. Попробуйте еще раз позже.',
            'EXISTS': 'Такой километраж уже существует',
          },
        },
      },
  };
  