<div class="progress-container">
  <div *ngIf="offer.trip()">
    <ul *ngIf="historyRecords" class="timeline">
      <li *ngFor="let record of historyRecords">
        <div class="timeline-date">{{record.date().toLocaleString()}}</div>
        <div class="timeline-content">

          <h3>{{ getTripStageName(record) | translate}}</h3>

          <ng-container [ngTemplateOutlet]="tonnageInfo" 
                        [ngTemplateOutletContext]="{$implicit:record}">
          </ng-container>

          <ng-container [ngTemplateOutlet]="wrongCoords" 
                        [ngTemplateOutletContext]="{$implicit:record}">
          </ng-container>
          
          <ng-container [ngTemplateOutlet]="additionalData" 
                        [ngTemplateOutletContext]="{$implicit:record}">
          </ng-container>
          
          <ng-container [ngTemplateOutlet]="addressInfo" 
                        [ngTemplateOutletContext]="{$implicit:record}">
          </ng-container>

          <ng-container [ngTemplateOutlet]="comment" 
                        [ngTemplateOutletContext]="{$implicit:record}">
          </ng-container>

          <ng-container [ngTemplateOutlet]="recordPhoto" 
                        [ngTemplateOutletContext]="{$implicit:record}">
          </ng-container>
        </div>
      </li>
    </ul>

    <div>
      <button mat-button (click)="showOnMap()">
        <mat-icon>gps_fixed</mat-icon>
        <mat-label>{{'ORDERINFO.SHOWONMAP' | translate}}</mat-label>
      </button>

      <button mat-button (click)="downloadTrack()" *ngIf="isExtended()">
        <mat-icon>get_app</mat-icon>
        <mat-label>{{'ORDERINFO.DOWNLOADTRACK' | translate}}</mat-label>
      </button>
     </div>
  </div>
</div>

<ng-template #tonnageInfo let-record>
  <div *ngIf="record.tonnage()" fxLayout="row" fxLayoutAlign="start center">
    <div class="info-block">
      {{'ORDERINFO.TONNAGE_INFO' | translate : { actualTonnage: record.tonnage()} }} 
    </div>
    <button class="ml-16" mat-button (click)="editTonnage(record)" *ngIf="isExtended()">
      <mat-icon>edit</mat-icon>
    </button> 
  </div>
</ng-template>

<ng-template #wrongCoords let-record>
  <div *ngIf="hasWrongCoordinate(record) && isExtended()" fxLayout="row" fxLayoutAlign="start center">
    <mat-error>{{'ORDERINFO.ERROR.WRONG_COORDINATE' | translate}}</mat-error>
    <button mat-icon-button (click)="setWrongState(record)">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
</ng-template>

<ng-template #additionalData let-record>
  <div *ngIf="shouldShowAdditionalData(record)"> 
    <div *ngIf="record.additionalData()?.kilometers >= 0" fxLayout="row" fxLayoutAlign="start center">
      <div class="info-block">
        {{'ORDERINFO.KILOMETERS_INFO' | translate : { distance: record.additionalData().kilometers} }} 
      </div>
      <button class="ml-16" *ngIf="isExtended()" mat-button (click)="editKilometers(record)">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  
    <div *ngIf="record.additionalData()?.waybillNumber" fxLayout="row" fxLayoutAlign="start center">
      <div class="info-block">
        {{'ORDERINFO.WAYBILL_INFO' | translate : { number: record.additionalData().waybillNumber} }} 
      </div>
      <button class="ml-16" *ngIf="isExtended()" mat-button (click)="editWaybill(record)">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #addressInfo let-record>
  <div *ngIf="record.address()" class="info-block">
    {{record.address()}}
  </div>
</ng-template>

<ng-template #comment let-record>
  <div *ngIf="record.comment()" class="info-block">
    {{'ORDERINFO.COMMENT' | translate}}: {{record.comment()}}
  </div>
</ng-template>

<ng-template #recordPhoto let-record>
  <div *ngIf="shouldShowPhoto(record)" style="display:table">
    <div *ngIf="record.photo()">
      <a href="{{record.photo().url()}}" target="_blank">
        <img src='{{thumbnail(record).url()}}' class="photo">
      </a>
    </div>
    <div *ngIf="!record.photo()" class="photo-placeholder">
      {{'ORDERINFO.PHOTONOTUPLOADED' | translate}}
    </div>
  </div>
</ng-template>


