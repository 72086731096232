import { TableData, TableRowData } from 'app/components/table/tabledata';

import { LocalDataSource } from 'app/components/table/data_sources/local-data-source';
import { Customer } from 'app/components/data/core/models/persons/customer';
import { EquipmentHelper } from 'app/components/helpers/equipment.helper';
import { UnloadingPoint } from 'app/components/data/core/models/points/points';
import { CurrentUser } from 'app/components/data/core/models/base/user';
import { TranslateService } from '@ngx-translate/core';

export class UnloadingPointsTableData extends TableData{

  constructor(
    private object: Customer,
    private translate: TranslateService
  ) {
    super();
    let columns = [
      new TableRowData({
        key: 'address', 
        header: 'DIALOG.POINTS.TABLEROW.ADDRESS', 
        cell: (point: UnloadingPoint) => `${point.getAddress()}`
      }),
      new TableRowData({
        key: 'equipment',
        header: 'DIALOG.POINTS.TABLEROW.REQUIRED_EQUIPMENT',
        cell: (point: UnloadingPoint) => `${ EquipmentHelper.localizedEquipmentRequirementsList(point, this.translate) }`,
      }),
    ];

    this.setColumns(columns);

    let tableDataSource = new LocalDataSource(this.getPoints());
    this.setProvider(tableDataSource);
  }

  private getPoints() {
    let points = this.object.getUnloadingPoints();
    if (points === undefined) {
      points = [];
    }

    let displayDeleted = CurrentUser.getSettings().displayDeleted;
    points = points.filter(p => {
      return !p.isDeleted() || displayDeleted;
    });
    return points;
  }
}
