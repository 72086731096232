import {locale as trip_stage_en} from "./en/locale.trip.stage";
import {locale as trip_stage_ru} from "./ru/locale.trip.stage";

import {locale as units_en} from "./en/locale.units";
import {locale as units_ru} from "./ru/locale.units";

import {locale as common_en} from "./en/locale.common";
import {locale as common_ru} from "./ru/locale.common";

import {locale as admin_en} from "./en/locale.row.operations";
import {locale as admin_ru} from "./ru/locale.row.operations";

import {locale as trip_problem_en} from "./en/locale.trip-problem";
import {locale as trip_problem_ru} from "./ru/locale.trip-problem";

import {locale as orders_en} from "./en/locale.orders";
import {locale as orders_ru} from "./ru/locale.orders";

import {locale as approval_en} from "./en/locale.approval";
import {locale as approval_ru} from "./ru/locale.approval";

import {locale as users_en} from "./en/locale.users";
import {locale as users_ru} from "./ru/locale.users";

import {locale as offers_en} from "./en/locale.offer";
import {locale as offers_ru} from "./ru/locale.offer";

import {locale as equipment_en} from "./en/locale.equipment";
import {locale as equipment_ru} from "./ru/locale.equipment";

import {locale as passes_en} from "./en/locale.passes";
import {locale as passes_ru} from "./ru/locale.passes";

export const trip_problem = [
  {
    lang: 'en',
    data: trip_problem_en
  },
  {
    lang: 'ru',
    data: trip_problem_ru
  },
];

export const common = [
  {
    lang: 'en',
    data: common_en
  },
  {
    lang: 'ru',
    data: common_ru
  },
];

export const tripStage = [
  {
    lang: 'en',
    data: trip_stage_en
  },
  {
    lang: 'ru',
    data: trip_stage_ru
  },
];

export const units = [
  {
    lang: 'en',
    data: units_en
  },
  {
    lang: 'ru',
    data: units_ru
  },
];

export const default_row_operations = [
  {
    lang: 'en',
    data: admin_en
  },
  {
    lang: 'ru',
    data: admin_ru
  },
];

export const orders = [
  {
    lang: 'en',
    data: orders_en
  },
  {
    lang: 'ru',
    data: orders_ru
  },
];

export const approval = [
  {
    lang: 'en',
    data: approval_en
  },
  {
    lang: 'ru',
    data: approval_ru
  },
];

export const users = [
  {
    lang: 'en',
    data: users_en
  },
  {
    lang: 'ru',
    data: users_ru
  },
];

export const offers = [
  {
    lang: 'en',
    data: offers_en
  },
  {
    lang: 'ru',
    data: offers_ru
  },
];

export const equipment = [
  {
    lang: 'en',
    data: equipment_en
  },
  {
    lang: 'ru',
    data: equipment_ru
  },
];

export const passes = [
  {
    lang: 'en',
    data: passes_en
  },
  {
    lang: 'ru',
    data: passes_ru
  },
];