<div class="page-layout carded fullwidth" fusePerfectScrollbar>
  <div class="top-bg primary"></div>
      <div class="center">
          <table-header headerTitle="{{'EXPORT_QUEUE.TITLE' | translate}}" 
                        iconName="hourglass_bottom" 
                        noSearchBar="true"
                        [buttons]="headerButtons"></table-header>
          <div class="mat-elevation-z8 fuse-white mb-16">
            <cd-table [tabledata]="tableData"
                      [options]="tableOptions">
            </cd-table>
          </div>
    </div>
</div>
