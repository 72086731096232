export const locale = {
  'DIALOG': {
    'FINISH_ORDER': {
      'TITLE': 'Finish order',
      'CONFIRM_BUTTON': 'Finish',
      'DATE': 'Date',
      'TONNAGE': 'Tonnage',
      'DISTANCE': 'Mileage',
      'TIME': 'Time',
      'ADD_PHOTO': 'Add photo',
      'LOAD': 'Load',
      'UNLOAD': 'Unload',
      'FINISHING': 'Finishing order. Please wait...',
    },
  }
};
