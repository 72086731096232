import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Customer, CustomerPermissions } from 'app/components/data/core/models/persons/customer';

import { LocaleService } from 'app/components/locale/locale.service';
import { customer } from '../../../locale/locale';
import { CloudExecutor } from 'app/components/data/cloud-executors/cloud-executor';

@Component({
    selector   : 'customer-permissions-dialog',
    templateUrl: './customer-permissions-dialog.component.html',
    styleUrls  : ['./customer-permissions-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CustomerPermissionsDialog {

  private object: Customer;
  public dataSaver = new CloudExecutor();

  canSeeDriverPhoneNumber;

  constructor (
    public dialogRef: MatDialogRef<CustomerPermissionsDialog>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private localeService: LocaleService,
  ) {
    this.localeService.loadLocale(customer);
    this.object = this._data.object;

    let permissions = this.object.permissions();
    this.canSeeDriverPhoneNumber = permissions.driverPhoneNumber;
  }

  name(): string {
    return this.object.getName();
  }

  confirm() {
    let permissions: CustomerPermissions = {
      driverPhoneNumber: this.canSeeDriverPhoneNumber
    }
    this.object.setPermissions(permissions);
    this.dataSaver.saveObject(this.object).then(obj => {
      this.dialogRef.close(obj);
    });
  }
}
