import { TableData, TableRowData, TableSort } from 'app/components/table/tabledata';

import { VehicleModel } from 'app/components/data/core/models/transport/transport';
import { PFQuery } from 'app/components/data/core/models/base/query';
import { ServerTableDataSource } from 'app/components/table/data_sources/server-data-source';
import { filterDeleted } from 'app/components/access/query';

export class VehicleModelsTableData extends TableData {

  constructor() {
    super();

    let columns = [
      new TableRowData({
        key: 'name',
        header: 'VEHICLES.TABLEROW.MODELNAME',
        cell: (model: VehicleModel) => `${model.name()}`
      }),
      new TableRowData({
        key: 'brandName',
        header: 'VEHICLES.TABLEROW.BRANDNAME',
        cell: (model: VehicleModel) => `${model.brand().name()}`
      }),
    ];
    this.setColumns(columns);

    let sort: TableSort = new TableSort('name','asc');
    let tableDataSource = new ServerTableDataSource('VehicleModel',['brand']);
    tableDataSource.setDefaultSort(sort);
    tableDataSource.queryModify = function (query: PFQuery): PFQuery {
      return filterDeleted(query);
    };
    tableDataSource.queryFilter = (filter, query) => {
      query.contains('name', filter);
      let otherQuery: PFQuery = new PFQuery('VehicleModel');
      let typeQuery = new PFQuery('VehicleBrand');
      typeQuery.contains('name', filter);
      otherQuery.matchesQuery("brand",typeQuery);
      return PFQuery.or(query, otherQuery);
    };
    this.setProvider(tableDataSource);
  }

  canSortColumn(column) {
    if (column.key === 'brandName') {
      return false;
    }
    return super.canSortColumn(column);
  }
}
