
<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="primary" 
                 title="{{'DIALOG.CUSTOMER.TITLE.ENTRANCES' | translate}}"
                 subtitle="{{object.getName()}}">
  </dialog-header>
  
  <div class="pt-8 px-16" fxFlex fxLayout="column">
    <form [formGroup]="form">
        <mat-form-field fxFlex>
            <input matInput
                   placeholder="{{'DIALOG.CUSTOMER.ADDRESS' | translate}}"
                   [matAutocomplete]="addressAutocomplete"
                   formControlName="unloadingPoint"
                   required>
            <mat-icon matSuffix>arrow_drop_down</mat-icon>
            <mat-autocomplete #addressAutocomplete="matAutocomplete"
                              [displayWith]="pointDisplay"
                              (optionSelected)="updateTableData($event.option.value)">
                <mat-option *ngFor="let point of filteredPoints"
                            [value]="point"
                            [disabled]="point.isDeleted()">
                    {{point.getAddress()}}{{point.isDeleted() ? ' (deleted)' : ''}}
                </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="form.get('unloadingPoint').hasError('required')">
                {{'DIALOG.CUSTOMER.ERROR.MUST_BE_FILLED' | translate}}
            </mat-error>
        </mat-form-field>
    </form>
    <div class="table-wrapper mat-elevation-z1" fxFlex>
      <div fxLayout="column" fxFlex>
        <action-bar [operations]="tableOperations"></action-bar>
        <cd-table [tabledata]="tableData"
                  (rowDoubleClick)="doubleClickHandler($event)"
                  (onSelectionChange)="updateOperations($event)">
        </cd-table>
      </div>
    </div>
    <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button
              color="accent"
              (click)="dialogRef.close()">{{'DIALOG.CUSTOMER.CONFIRMBUTTON.CLOSE' | translate}}
      </button>
    </div>
  </div>
</div>
