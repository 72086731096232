import {locale as autolist_en} from "./en/locale.autolist";
import {locale as autolist_ru} from "./ru/locale.autolist";

import {locale as autoonmap_en} from "./en/locale.autoonmap";
import {locale as autoonmap_ru} from "./ru/locale.autoonmap";

import {locale as disband_en} from "./en/locale.disband";
import {locale as disband_ru} from "./ru/locale.disband";

import {locale as showonmap_en} from "./en/locale.showonmap";
import {locale as showonmap_ru} from "./ru/locale.showonmap";

import {locale as row_operations_en} from "./en/locale.row.operations";
import {locale as row_operations_ru} from "./ru/locale.row.operations";

export const autolist = [
  {
    lang: "en",
    data: autolist_en
  },
  {
    lang: "ru",
    data: autolist_ru
  }
];

export const autoonmap = [
  {
    lang: "en",
    data: autoonmap_en
  },
  {
    lang: "ru",
    data: autoonmap_ru
  }
];

export const disband = [
  {
    lang: "en",
    data: disband_en
  },
  {
    lang: "ru",
    data: disband_ru
  }
];

export const showonmap = [
  {
    lang: "en",
    data: showonmap_en
  },
  {
    lang: "ru",
    data: showonmap_ru
  }
];

export const autos_row_operations = [
  {
    lang: 'en',
    data: row_operations_en
  },
  {
    lang: 'ru',
    data: row_operations_ru
  },
];
