import { NgModule } from '@angular/core';

import { AttachmentComponent } from './attachment.component'

import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/material.module';

import { NgxFileDropModule } from 'ngx-file-drop';

@NgModule({
    declarations: [
        AttachmentComponent,
    ],
    imports: [
        TranslateModule,
        FuseSharedModule,
        MaterialModule,
        NgxFileDropModule,
    ],
    exports: [
        AttachmentComponent,
    ]
})

export class AttachmentModule
{
}
