import { DialogPresenterService, DialogData } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import { IntermediaryCreateDialog } from 'app/main/admin/users/dialogs/intermediary/create/intermediary-create-dialog.component';
import { TableOperationActionType, TableOperationsHelper } from 'app/components/helpers/table-operations.helper';
import { TableOperationsFactory } from 'app/components/table-operations-controller/table-operations-controller';

export class IntermediaryTableOperationsFactory implements TableOperationsFactory {

    constructor(
        private dialogPresenter: DialogPresenterService,
        private completion
    ) { }

    makeOperations(selected) {
        return this.makeOperationsWithSelected(selected);
    }

    makeDefaultOperation(selected) {
        return TableOperationsHelper.makeOperationWithType(
            TableOperationActionType.Edit,
            this.dialogPresenter,
            () => this.makeDialogData(),
            selected);
    }

    private makeOperationsWithSelected(selected) {
        let types = [
            TableOperationActionType.Add,
            TableOperationActionType.Edit,
            TableOperationActionType.Remove,
        ];
        return TableOperationsHelper.makeOperationsWithTypes(
            types,
            this.dialogPresenter,
            () => this.makeDialogData(),
            selected,
            this.completion
        );
    }

    private makeDialogData(): DialogData {
        return {
            dialog: IntermediaryCreateDialog,
            panel: 'intermediary-create-dialog-container',
            data: {}
        }
    }
}
