<div class="dialog-content-wrapper m-0">
    <dialog-header headerClass="primary" 
                   title="{{'DIALOG.SUPPLIER.TITLE.BRANDS_LIST_ADD' | translate}}">
    </dialog-header>
    <div class="pt-8 px-16" fxFlex fxLayout="column" fxLayoutAlign="space-between stretch">
      <div>
        <mat-form-field fxFlex>
          <mat-select placeholder="{{'DIALOG.SUPPLIER.BRAND_TYPE' | translate}}"
                      [(ngModel)]="selectedType"
                      required>
            <mat-option value="all">
              {{'DIALOG.SUPPLIER.TYPE_FILTER_ALL' | translate}}
            </mat-option>
            <mat-option *ngFor='let type of dataLoader.getLoadedItems()' 
                        [value]="type">
                        {{type.get('name')}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="mat-elevation-z1 table-wrapper" fxFlex cdkScrollable>
        <cd-table [tabledata]="tableData" 
                  [options]="tableOptions">
        </cd-table>
      </div>
      <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button
                color="accent"
                (click)="save()">{{'DIALOG.SUPPLIER.CONFIRMBUTTON.ADD' | translate}}
        </button>
      </div>
    </div>
  </div>