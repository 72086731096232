import { DialogPresenterService, DialogData } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import { TableOperationActionType, TableOperationsHelper } from 'app/components/helpers/table-operations.helper';
import { TableOperationsFactory } from 'app/components/table-operations-controller/table-operations-controller';
import { CreateMessageDialog } from './dialog/create_message/create-message-dialog.component';

export class MessagesTableOperationsFactory implements TableOperationsFactory {

    constructor(
        private dialogPresenter: DialogPresenterService,
        private completion,
    ) { }

    makeOperations(selected) {
        return this.makeOperationsWithSelected(selected);
    }

    private makeOperationsWithSelected(selected) {
        let operationTypes = [
            TableOperationActionType.Add,
            TableOperationActionType.Copy,
        ]
        var operations = TableOperationsHelper.makeOperationsWithTypes(
            operationTypes,
            this.dialogPresenter,
            () => this.makeDialogData(),
            selected,
            this.completion
        );

        operations.push(TableOperationsHelper.makeRemoveOperation(() => this.removeMessage(selected), selected),)
        return operations;
    }

    private makeDialogData(): DialogData {
        return {
            dialog: CreateMessageDialog,
            panel: 'create-message-dialog-container',
            data: {}
        }
    }

    private removeMessage(selected) {
        this.dialogPresenter.askRemoveItems(selected, this.completion, true);
    }
}