import {locale as kilometers_en} from "./en/locale.kilometers";
import {locale as kilometers_ru} from "./ru/locale.kilometers";

import {locale as create_kilometers_en} from "./en/locale.create-kilometers";
import {locale as create_kilometers_ru} from "./ru/locale.create-kilometers";

export const kilometers = [
  {
    lang: "en",
    data: kilometers_en
  },
  {
    lang: "ru",
    data: kilometers_ru
  }
];

export const create_kilometers = [
  {
    lang: "en",
    data: create_kilometers_en
  },
  {
    lang: "ru",
    data: create_kilometers_ru
  }
];
