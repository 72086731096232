
<div class="dialog-content-wrapper">
  <dialog-header headerClass="primary" 
                 title="{{'PROPOSALS.DIALOG.RESERVATION.TITLE' | translate}}">
  </dialog-header>
  <div class="content py-8 px-16" fxLayout="column">
    <div fxFlex>
      {{'PROPOSALS.DIALOG.RESERVATION.BY_CARRIER_MESSAGE' | translate}}
    </div>
    <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button
              color="accent"
              [disabled] = "executor.isProcessing()"
              (click)='reserve()'>{{'PROPOSALS.DIALOG.RESERVATION.CONFIRMBUTTON' | translate}}
      </button>
    </div>
  </div>

</div>
