
export const locale = {
  'REMOVE_USER_DIALOG' : {
    'TITLE' : 'Удаление',
    'MESSAGE' : 'Вы действительно хотите удалить все выбранные элементы?',
    'BUTTON' : {
      'CONFIRM' : 'Да, удалить',
      'DECLINE' : 'Отмена',
    },
    'ERROR': 'Не удалось удалить пользователя. Попробуйте еще раз позже.',
  }
};
