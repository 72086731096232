import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { LocaleService } from 'app/components/locale/locale.service';
import { credentials } from 'app/main/admin/users/locale/locale';

import * as FormHelper from 'app/components/form-helper/form-helper';
import { DispatcherCloudExecutor } from 'app/components/data/cloud-executors/dispatcher-cloud-executor';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/components/data/core/models/base/user';
import { LocalNotificationService } from 'app/components/local-notifications-service/local-notifications-service';

@Component({
  selector: 'credentials-dialog',
  templateUrl: './credentials-dialog.component.html',
  styleUrls: ['./credentials-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CredentialsDialog {
  form: UntypedFormGroup;
  user: User;
  executor = new DispatcherCloudExecutor;

  constructor(
    private localeService: LocaleService,
    public dialogRef: MatDialogRef<CredentialsDialog>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private notifications: LocalNotificationService
  ) {
    this.localeService.loadLocale(credentials);
    this.user = this._data.object;
    this.form = this.createContactForm();
  }

  private createContactForm(): UntypedFormGroup {
    let name = this.user ? this.user.getUsername() : null;
    let disabled = this.user !== undefined;
    return this._formBuilder.group({
      name: { value: name, disabled: disabled },
      password: null,
    });
  }

  checkAndSave() {
    if (this.checkForm()) {
      if (!this.user)
        this.createUser();
      else
        this.setPassword()
    }
  }

  private checkForm(): boolean {
    return FormHelper.checkForm(this.form);
  }

  setPassword(): void {
    this.executor.setUserPassword(this.user.id, this.form.controls.password.value)
      .then(() => {
        this.dialogRef.close(true);
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  createUser(): void {
    let role = this._data.role;
    let personId = this._data.personId;
    let username = this.form.controls.name.value;
    let password = this.form.controls.password.value;

    this.executor.makeUser(role, personId, username, password)
      .then(() => {
        this.dialogRef.close(true);
      }).catch(error => {
        this.handleError(error);
      });
  }

  removeUser() {
    this.executor.deleteUser(this._data.role, this._data.personId)
      .then(() => {
        this.dialogRef.close(true);
      }).catch(error => {
        this.handleError(error);
      });
  }

  private handleError(error) {
    let errorMsg = 'DIALOG.CREDENTIALS.ERROR.';
    if (typeof error == "string") {
      switch (error) {
        case 'Forbidden':
        case 'AlreadyHasUser':
        case 'ObjectIsReferenced':
        case 'ObjectNotFound':
        case 'UserAlreadyExists':
          errorMsg += error;
          break;
        default:
          errorMsg += "UNDEFINED";
          break;
      }
    } else {
      errorMsg += "UNDEFINED";
    }
    let localizedMSG = this.translate.instant(errorMsg);
    this.notifications.showErrorNotification(localizedMSG);
  };
}

