<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="primary" 
                 title="{{'DIALOG.DRIVER.APPROVE.TITLE' | translate}}">
  </dialog-header>
  
  <div class="pt-8 px-16">
    <form [formGroup]="form">
      <div>
        <mat-form-field fxFlex>
          <input matInput placeholder="{{'DIALOG.DRIVER.FULLNAME' | translate}}" formControlName="name">
        </mat-form-field>
      </div>

      <div>
        <attachment [files]="attachments" 
                    [editingEnabled]="isEditingEnabled()">
        </attachment>
      </div>
      <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button 
                color="{{isApproved() ? 'warn' : 'accent'}}"
                [disabled]="dataSaver.isProcessing()"
                (click)='toggleApprovement()'>{{
            ('DIALOG.DRIVER.APPROVE.' + (isApproved() ? 'DECLINEBUTTON' : 'CONFIRMBUTTON')) | translate
          }}
        </button>
      </div>
    </form>
  </div>
</div>