import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { PFQuery } from 'app/components/data/core/models/base/query';
import { PFObject } from 'app/components/data/core/models/base/object';
import { proposals_reservation_dialog } from '../locale/locale';

import { DataLoader } from 'app/components/data/data-loader';
import { Order } from 'app/components/data/core/models/order/order';
import { Carrier } from 'app/components/data/core/models/persons/carrier';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { LocaleService } from 'app/components/locale/locale.service';
import { DispatcherCloudExecutor } from 'app/components/data/cloud-executors/dispatcher-cloud-executor';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LocalNotificationService } from 'app/components/local-notifications-service/local-notifications-service';


@Component({
  selector: 'proposal-reservation-by-manager-dialog',
  templateUrl: './proposal-reservation-by-manager-dialog.component.html',
  styleUrls: ['./proposal-reservation-by-manager-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ProposalReservationByManagerDialog {
  compareFunction = PFObject.compareFn;

  carrierSelector = new UntypedFormControl();
  carriersData: DataLoader;
  executor = new DispatcherCloudExecutor();

  private object: Order;

  private get carrier() {
    return this.carrierSelector.value;
  }

  constructor(
    public dialogRef: MatDialogRef<ProposalReservationByManagerDialog>,
    @Inject(MAT_DIALOG_DATA) _data: any,
    private localeService: LocaleService,
    private translate: TranslateService,
    private notifications: LocalNotificationService
  ) {
    this.localeService.loadLocale(proposals_reservation_dialog);

    this.carriersData = this.makeCarrierData();
    this.object = _data.object;

    let carriers = this.object.getCarriers();
    if (carriers && carriers.length) {
      this.carrierSelector.setValue(carriers[0]);
    }

    this.reloadCarriers();
  }

  private makeCarrierData() {
    let loader = new DataLoader('Carrier');
    loader.setQueryModificationBlock((query: PFQuery) => {
      if (this.carrier && this.carrier.length) {
        query.contains('name', this.carrier);
      }
    });
    return loader;
  }

  ngAfterViewInit() {
    this.carrierSelector.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(() => this.reloadCarriers());
  }

  private reloadCarriers() {
    this.carriersData.loadAll();
  }

  carrierDisplay(carrier?: Carrier) {
    return carrier ? carrier.getName() : undefined;
  }

  isReserved() {
    let carriers = this.object.getCarriers();
    return carriers && carriers.length > 0;
  }

  removeReserve() {
    this.setCarriers(null);
  }

  reserve() {
    let carrier = this.carrier;
    if (carrier instanceof Carrier) {
      this.setCarriers([carrier]);
    }
  }

  setCarriers(carriers: Carrier[]) {
    this.executor.assignCarriers([this.object], carriers)
      .then(() => {
        this.dialogRef.close(true);
      })
      .catch(() => {
        let msg = this.translate.instant('PROPOSALS.DIALOG.RESERVATION.ERROR');
        this.notifications.showErrorNotification(msg);
      });
  }
}
