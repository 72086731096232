import { NgModule } from '@angular/core';

import { DialogHeaderComponent } from './dialog-header.component'

import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/material.module';

@NgModule({
    declarations: [
        DialogHeaderComponent,
    ],
    imports: [
        FuseSharedModule,
        MaterialModule,
        TranslateModule,
    ],
    exports: [
        DialogHeaderComponent,
    ]
})

export class DialogHeaderModule
{
}
