import { Component, Input } from '@angular/core';

@Component({
    selector: 'action-bar',
    templateUrl: './action-bar.component.html',
})

export class ActionBarComponent {
    @Input() operations: ActionBarOperation[] = [];
    constructor() {}
}

interface Operation {
    title: string,
    icon: string,
    action: ()=>void,
    disabled?: boolean
}

export class ActionBarOperation {
    get title() {
        return this.operation.title;
    }

    get icon() {
        return this.operation.icon;
    }

    get action() {
        return this.operation.action;
    }

    isDisabled(): boolean {
        return this.operation.disabled;
    }

    constructor(private operation: Operation) {}
}