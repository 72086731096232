
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Injectable()

export class DownloadService {

  constructor (
    private domSanitizer: DomSanitizer
  ){}

  createLinkForDataPromise(dataPromise: Promise<any>, fileType, sanitized: boolean): Promise<any> {
    return new Promise( (resolve, reject) => {
      dataPromise.then((fileData) => {
        let blob = new Blob([fileData], {type: fileType});
        var url;
        if (sanitized) {
          url = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
        } else {
          url = window.URL.createObjectURL(blob);
        }
        resolve(url);
      }).catch(error => {
        reject(error);
      });
    })
  }
}
