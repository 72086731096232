export const locale = {
    'PROPOSALS': {
        'DIALOG': {
            'RESERVATION': {
                'TITLE': 'Reservation',
                'CONFIRMBUTTON': 'Reserve',
                'BY_CARRIER_MESSAGE': 'Are you sure to reserve this proposal?',
                'REMOVE': 'Remove reserve',
                'CONTROLS': {
                    'CARRIER_SELECTOR': 'Carrier',
                },
                'ERROR': 'Failed to reserve'
            },
        },
    },
};
