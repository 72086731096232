
export const locale = {
  lang: "en",
  data: {
    "TrackDownload": {
      "Title": "Download Track",
      "ProgressText": "Track is being created...",
      "LinkText": "Track",
      "GPX": {
        "Name": "Track of Order #{{orderNumber}}",
        "Description": "Trip of driver: {{driverName}}",
      },
    }
  }
};
