import * as moment from 'moment';

export namespace DateHelper {
    export function setDayBegin(date: Date) {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
    }
    
    export function setDayEnd(date: Date) {
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        date.setMilliseconds(999);
        return date;
    }

    export function nextDay(date: Date) {
        date = new Date(date);
        date.setDate(date.getDate() + 1);
        return date;
    }
    
    export function prevDay(date: Date) {
        date = new Date(date);
        date.setDate(date.getDate() - 1);
        return date;
    }

    export function timeString(date: Date) {
        if (typeof date === 'string') {
            date = new Date(date);
        }
        let hours = prefixZeroForTimeIfNeeded(date.getHours());
        let minutes = prefixZeroForTimeIfNeeded(date.getMinutes());
        return `${hours}:${minutes}`;
    }

    function prefixZeroForTimeIfNeeded(value) {
        return value > 9 ? value : '0' + value;
    }

    export function dateToFormattedString(date: Date, format: string) {
        return moment(date).format(format);
    }

    export function isValid(date): boolean {
        return date instanceof Date && !isNaN(Number(date))
    }
}
