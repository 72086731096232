export const locale = {
  'DELETECONFIRM' : {
    'TITLE' : 'Delete',
    'MESSAGE' : 'Are you sure you want to delete this orders?',
    'CONFIRM' : 'Yes, delete',
    'DECLINE' : 'Cancel',
    'ORDERNUMBER' : 'Order #',
    'DELETING' : 'Deleting orders',
    'ERROR' : 'Failed to remove orders',
  },
};
