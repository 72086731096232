export const locale = {
    'CREATE_MESSAGE': {
        'TITLE': 'Новое сообщение',
        'FIELD': {
            'TITLE': 'Заголовок сообщения',
            'TARGET': 'Кому',
            'BODY': 'Текст сообщения',
        },
        'CONFIRMBUTTON': 'Отправить',
        'ERROR': {
            'UNDEFINED': 'Произошла неизвестная ошибка. Попробуйте еще раз.',
          },
    }
  };
  