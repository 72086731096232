import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { LocaleService } from 'app/components/locale/locale.service';
import { create_contacts } from '../locale/locale';

import { CloudExecutor } from 'app/components/data/cloud-executors/cloud-executor';
import * as FormHelper from 'app/components/form-helper/form-helper';
import { Contact, Contactable } from 'app/components/data/core/models/persons/contact';

@Component({
  selector: 'create-contact-dialog',
  templateUrl: './create-contact-dialog.component.html',
  styleUrls: ['./create-contact-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CreateContactDialog {
  public form: UntypedFormGroup;
  private object: Contactable;
  private contact: Contact;

  public title: string;
  public buttonName: string;

  public dataSaver = new CloudExecutor();

  constructor(
    private localeService: LocaleService,
    public dialogRef: MatDialogRef<CreateContactDialog>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: UntypedFormBuilder
  ) {
    this.localeService.loadLocale(create_contacts);
    this.object = _data.object;
    this.contact = _data.contact;
    if (!this.contact) {
      this.contact = {
        name: '',
        phoneNumber: ''
      };
    }

    if (this._data.action === 'create') {
      this.title = 'DIALOG.CONTACTS.TITLE.CREATE';
      this.buttonName = 'DIALOG.CONTACTS.CONFIRMBUTTON.CREATE';
    } else if (this._data.action === 'edit') {
      this.title = 'DIALOG.CONTACTS.TITLE.EDIT';
      this.buttonName = 'DIALOG.CONTACTS.CONFIRMBUTTON.SAVE';
    }

    this.form = this.createContactForm();
  }

  createContactForm(): UntypedFormGroup {
    return this._formBuilder.group({
      name: [this.contact.name],
      phoneNumber: [this.contact.phoneNumber],
    });
  }

  checkAndSave() {
    let verified = this.checkForm();

    if (verified) {
      this.contact.name = this.form.controls.name.value;
      this.contact.phoneNumber = this.form.controls.phoneNumber.value;
      if (this._data.action === 'create') {
        this.object.addContact(this.contact);
      }
      this.dataSaver.saveObject(this.object).then(obj => {
        this.dialogRef.close(this.contact);
      });
    }
  }

  checkForm(): boolean {
    return FormHelper.checkForm(this.form);
  }
}
