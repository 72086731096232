
<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="primary" 
                 title="{{'DIALOG.EDIT_PASSES.TITLE' | translate}}"
                 subtitle="{{object.fullName()}} ({{object.number()}})">
  </dialog-header>
  
  <div class="pt-8 px-16" fxFlex fxLayout="column">
    <div class="table-wrapper mat-elevation-z1" fxLayout="column" fxFlex>
      <div fxLayout="column" fxFlex>
        <action-bar [operations]="tableOperations"></action-bar>
        <cd-table [tabledata]="tableData"
                  (rowDoubleClick)="doubleClickHandler($event)"
                  (onSelectionChange)="updateOperations($event)">
        </cd-table>
      </div>
    </div>
    <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button
              color="accent"
              (click)="dialogRef.close()">{{'DIALOG.EDIT_PASSES.CONFIRMBUTTON' | translate}}
      </button>
    </div>
  </div>
</div>
