export const locale = {
  'CUSTOMER_INFO' : {
    'TITLE' : 'Info',
    'FIELD_NAME': {
      'CONTACT_NAME': 'Contact name',
      'CONTACT_PHONE': 'Contact phone',
      'ITN': 'ITN',
    },
    'BUTTON': {
      'REFRESH_DEBT': 'Refresh debt',
    },
    'UNDEFINED': 'Undefined',
    'RECEIVABLES': 'Receivables',
    'CREDIT': 'Credit',
    'ERROR': {
      'VERIFY_ERROR': "Can't request debt",
    }
  },
};
