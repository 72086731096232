import { Parse } from 'parse';
import { BehaviorSubject } from 'rxjs';
import { filterDeleted } from '../access/query';
import { PFQuery } from '../data/core/models/base/query';

export class DataLoader {
  private queryModificationBlock: (query: PFQuery) => void;
  private loading: boolean;
  private loadError;

  filterDeleted = true

  loadedObjects = new BehaviorSubject<Parse.Object[]>([]);
  delayBeforeFinish: number = 0;
  postProcess: (result: any) => any;

  constructor(private objectClass) {}
  public loadItems(offset = 0, limit = 1000): Promise<any> {
    this.prepareLoad();
    return new Promise((resolve, reject) => {
      let query = this.buildQuery();
      query.skip(offset);
      query.limit(limit);
      query.find().then(objs => {
        setTimeout(() => {
          this.finishLoad(objs);
          resolve(objs);
        }, this.delayBeforeFinish);
      }, error => {
        this.finishedWIthError(error);
        reject(error);
      });
    })
  }

  public loadAll(): Promise<any> {
    this.prepareLoad();
    return new Promise((resolve, reject) => {
      let query = this.buildQuery();
      query.findAll().then(objs => {
        setTimeout(() => {
          this.finishLoad(objs);
          resolve(objs);
        }, this.delayBeforeFinish);
      }, error => {
        this.finishedWIthError(error);
        reject(error);
      });
    })
  }

  public setQueryModificationBlock(block: (query: PFQuery) => void) {
    this.queryModificationBlock = block;
  }

  public isLoading(): boolean {
    return this.loading;
  }

  public getLoadedItems() {
    return this.loadedObjects.getValue();
  }

  public addItemToLoaded(item) {
    let items = this.getLoadedItems();
    items.push(item);
    this.loadedObjects.next(items);
  }

  public getLoadError() {
    return this.loadError;
  }

  private prepareLoad() {
    this.loading = true;
    this.loadedObjects.next([]);
    this.loadError = null;
  }

  private finishLoad(objects) {
    this.loading = false;
    if (this.postProcess) {
      objects = this.postProcess(objects);
    }
    this.loadedObjects.next(objects);
  }

  private finishedWIthError(error) {
    this.loading = false;
    this.loadError = error;
  }

  private buildQuery(): PFQuery {
    let query = new PFQuery(this.objectClass);
    if (this.filterDeleted) {
      query = filterDeleted(query);
    }
    if (this.queryModificationBlock) {
      this.queryModificationBlock(query);
    }
    return query;
  }
}
