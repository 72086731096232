export const locale = {
  'DELETECONFIRM' : {
    'TITLE' : 'Удалить заказ',
    'MESSAGE' : 'Вы уверены, что хотите удалить эти заказы?',
    'CONFIRM' : 'Да, удалить',
    'DECLINE' : 'Отменить',
    'ORDERNUMBER' : 'Заказ #',
    'DELETING' : 'Удаление заказов',
    'IMAGEDELETE' : 'Вы уверены, что хотите удалить это изображение?',
    'IMAGEDELETE_TITLE' : 'Удалить изображение?',
    'IMAGEDELETE_DELETING' : 'Удаление изображений',
    'IMAGEDELETE_ERROR' : 'Не удалось удалить изображение',
    'IMAGECHANGE_ERROR' : 'Не удалось заменить изображение',

    'ERROR' : 'Не удалось удалить заказы',
  },
};
