
export const locale = {
  'DIALOG': {
    'CREDENTIALS': {
      'TITLE': 'Credentials',
      'BUTTON': {
        'REMOVE': 'Remove',
        'CREATE': 'Create',
        'CHANGE_PASSWORD': 'Change password',
      },
      'NAME': 'Login',
      'PASSWORD': 'Password',
      'NEW_PASSWORD': 'New password',
      'ERROR': {
        'Forbidden': 'Operation forbidden',
        'ObjectNotFound': 'Object not found',
        'AlreadyHasUser': 'This user already has credentials',
        'UserAlreadyExists': 'User already exists',
        'ObjectIsReferenced': "Can't remove object. It has references for other objects",
        'UNDEFINED': 'Undefined error',
      },
    },
  },
};
