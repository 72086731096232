import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/material.module';

import { TrailerDialog } from './create/trailer-dialog.component';
import { TrailerApproveDialog } from './approve/trailer-approve-dialog.component';
import { DialogHeaderModule } from 'app/components/dialogs/dialog-header/dialog-header.module';
import { AttachmentModule } from 'app/components/attachment/attachment.module';

@NgModule({
    declarations: [
        TrailerDialog,
        TrailerApproveDialog,
    ],
    imports: [
        TranslateModule,
        FuseSharedModule,
        MaterialModule,
        DialogHeaderModule,
        AttachmentModule,
    ],
    exports: [
        TrailerDialog,
        TrailerApproveDialog,
    ]
})

export class TrailerDialogModule
{
}
