<mat-toolbar class="dialog-header" [ngClass]="headerClass">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column">
            <span class="title dialog-title">{{title}}</span>
            <span class="h4 subtitle">{{subtitle}}</span>
        </div>
        <button *ngIf="!hideCloseButton" 
                mat-icon-button 
                matDialogClose
                (click)="click($event)">
                <mat-icon>close</mat-icon>
        </button>
    </mat-toolbar-row>
</mat-toolbar>