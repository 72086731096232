import { NgModule } from '@angular/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/material.module';
import { TranslateModule } from '@ngx-translate/core';
import {AutocompleteWidgetComponent} from "./autocomplete-widget.component";

@NgModule({
    declarations: [
        AutocompleteWidgetComponent,
    ],
    imports: [
        FuseSharedModule,
        MaterialModule,
        TranslateModule,
    ],
    exports: [
        AutocompleteWidgetComponent,
    ]
})

export class AutocompleteWidgetModule
{
}
