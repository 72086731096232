import { TableData, TableRowData } from 'app/components/table/tabledata';

import { LocalDataSource } from 'app/components/table/data_sources/local-data-source';
import { TranslateService } from '@ngx-translate/core';
import { Vehicle } from 'app/components/data/core/models/transport/transport';
import { VehiclePass } from 'app/components/data/core/models/transport/vehicle-pass';
import { PassesHelper } from 'app/components/helpers/passes.helper';

export class PassesTableData extends TableData{

  constructor(
    private object: Vehicle,
    private translate: TranslateService
  ) {
    super();
    let columns = [
      new TableRowData({
        key: 'zone', 
        header: 'DIALOG.EDIT_PASSES.TABLEROW.ZONE', 
        cell: (pass: VehiclePass) => `${this.translate.instant(PassesHelper.passTypeNameFor(pass.zone))}`
      }),
      new TableRowData({
        key: 'timeOfAction', 
        header: 'DIALOG.EDIT_PASSES.TABLEROW.TIME_OF_ACTION', 
        cell: (pass: VehiclePass) => `${this.translate.instant(PassesHelper.timeOfActionStringFor(pass.timeOfAction))}`
      }),
      new TableRowData({
        key: 'number', 
        header: 'DIALOG.EDIT_PASSES.TABLEROW.NUMBER', 
        cell: (pass: VehiclePass) => `${pass.number ?? ''}`
      }),
      new TableRowData({
        key: 'dates', 
        header: 'DIALOG.EDIT_PASSES.TABLEROW.DATES', 
        cell: (pass: VehiclePass) => `${PassesHelper.validityString(this.translate,pass)}`
      }),
      new TableRowData({
        key: 'status', 
        header: 'DIALOG.EDIT_PASSES.TABLEROW.STATUS', 
        cell: (pass: VehiclePass) => `${this.translate.instant(PassesHelper.passStateName(pass))}`,
        cellClass: (pass: VehiclePass) => PassesHelper.passStateClass(pass),
      }),
    ];

    this.setColumns(columns);

    let tableDataSource = new LocalDataSource(this.getPasses());
    this.setProvider(tableDataSource);
  }

  private getPasses() {
    return this.object.passes() ?? [];
  }
}
