<div class="page-layout carded fullwidth" fusePerfectScrollbar>

    <div class="top-bg primary"></div>
      <div class="center">
          <table-header headerTitle="{{'CUSTOMER_INFO.TITLE' | translate}}"
                        iconName="info" 
                        noSearchBar="true">
          </table-header>
          <div class="mat-elevation-z8 fuse-white mb-16 p-16 customer-info-content" fxLayout="column">
            <form [formGroup]="form">
              <div> 
                 <mat-form-field class='no-padding info-input' fxFlex appearance="outline">
                   <mat-label>{{'CUSTOMER_INFO.FIELD_NAME.CONTACT_NAME' | translate}}</mat-label>
                   <input matInput
                          formControlName="contactName">
                 </mat-form-field>
              </div>
              <div> 
                <mat-form-field class='no-padding info-input' fxFlex appearance="outline">
                  <mat-label>{{'CUSTOMER_INFO.FIELD_NAME.CONTACT_PHONE' | translate}}</mat-label>
                  <input matInput
                         formControlName="contactPhone">
                </mat-form-field>
              </div>
              <div> 
                <mat-form-field class='no-padding info-input' fxFlex appearance="outline">
                  <mat-label>{{'CUSTOMER_INFO.FIELD_NAME.ITN' | translate}}</mat-label>
                  <input matInput
                         formControlName="itn">
                </mat-form-field>
              </div>
            </form>

            <div class="my-16" fxLayout="column" fxLayoutAlign="center center">
              <div  fxLayout="row" fxLayoutAlign="center center">
                <button mat-raised-button
                        color="accent"
                        (click)="refreshDebt()">
                  {{"CUSTOMER_INFO.BUTTON.REFRESH_DEBT" | translate}}
                </button>
              </div>

              <div *ngIf='executor.isProcessing()' class='py-20' fxLayout="column" fxLayoutAlign="center center">
                <mat-spinner diameter="30" color="accent"></mat-spinner>
              </div>
    
              <div *ngIf='checkError' class='py-20' fxLayout="column" fxLayoutAlign="center center">
                {{'CUSTOMER_INFO.ERROR.VERIFY_ERROR' | translate}}
              </div>
    
              <div *ngIf="customerStatus" 
                   class='customer-check mt-20 p-8'
                   [ngClass]="customerStatus.allowed ? 'green-500-bg' : 'deep-orange-300-bg'"
                   fxLayout="column"
                   fxLayoutAlign="center start">
                  <mat-label class='check-label'>
                    {{'CUSTOMER_INFO.RECEIVABLES' | translate}}: {{rank(customerStatus.accountsReceivable)}}
                  </mat-label>
                  <mat-label class='check-label'>
                    {{'CUSTOMER_INFO.CREDIT' | translate}}: {{rank(customerStatus.accountsPayable)}}
                  </mat-label>
                  <mat-label class='check-label' *ngIf="customerStatus.lastPaymentDate">
                    {{'CUSTOMER_INFO.LAST_PAYMENT' | translate}}: {{customerStatus.lastPaymentDate.toLocaleString()}}
                  </mat-label>
              </div>
    
              <div *ngIf="customerStatus && customerStatus.stopFactor" 
                   class="customer-check warn-400-bg mt-20 p-8">
                <mat-label class='check-label'>{{customerStatus.stopFactor}}</mat-label>
              </div>
            </div>
          
          </div>
    </div>
</div>
