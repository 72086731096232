import { PFFile } from "../base/file";
import { Carrier } from "./carrier";

import { PersonWithUser } from './person';

export class Driver extends PersonWithUser {
  constructor() { super("Driver"); }

  getCarrier(): Carrier {return super.get("carrier");}
  setCarrier(value: Carrier): void {super.set("carrier", value);}

  getApproved(): boolean {return super.get("approved");}
  setApproved(value: boolean): void { super.set("approved", value);}

  getShowTariff(): boolean {return super.get("showTariff");}
  setShowTariff(value: boolean): void { super.set("showTariff", value);}

  getLicense(): string { return super.get("license"); }

  phoneNumber(): string { return super.get('phoneNumber'); }
  setPhoneNumber(value: string) { super.set('phoneNumber', value); }

  getAttachedDocuments(): PFFile[] {return super.get("attachedDocuments");}
  attachDocument(value: PFFile): void {
    let docs = this.getAttachedDocuments();
    if (!docs) {
      docs = [];
    }
    docs.push(value);
    super.set("attachedDocuments", docs);
  }

  removeDocument(value: PFFile): void {
    let docs = this.getAttachedDocuments();
    if (!docs) {
      return;
    }
    let index = docs.indexOf(value);
    if (index >= 0) {
      docs.splice(index, 1);
    }
    super.set("attachedDocuments", docs);
  }

  isInternal(): boolean {return super.get("internal");}

  copy(): Driver {
    let copy = new Driver();
    copy.setName(this.getName());
    copy.setCarrier(this.getCarrier());
    return copy;
  }
}
