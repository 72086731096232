import { PFObject } from './base/object';
import { LoadingPoint, UnloadingPoint } from './points/points';

export class Distance extends PFObject {
  constructor() { super('Distance'); }

  loadingPoint(): LoadingPoint { return super.get('loadingPoint'); }
  setLoadingPoint(value: LoadingPoint) { super.set('loadingPoint', value); }

  unloadingPoint(): UnloadingPoint { return super.get('unloadingPoint'); }
  setUnloadingPoint(value: UnloadingPoint) { return super.set('unloadingPoint', value); }

  distance(): number { return super.get('distance'); }
  setDistance(value: number) { return super.set('distance', value); }
}