import { TableData, TableRowData, TableSort } from 'app/components/table/tabledata';

import { Manager } from 'app/components/data/core/models/persons/manager';
import { ServerTableDataSource } from 'app/components/table/data_sources/server-data-source';
import { PFQuery } from 'app/components/data/core/models/base/query';
import { filterDeleted } from 'app/components/access/query';

export class ManagersTableData extends TableData {

  constructor() {
    super();

    let columns = [
      new TableRowData({
        key: 'name',
        header: 'USERS.TABLEROW.FULLNAME',
        cell: (manager: Manager) => `${ manager.getName() }`
      }),
      new TableRowData({
        key: 'credentials',
        header: 'USERS.TABLEROW.LOGIN',
        cell: (manager: Manager) => `${ manager.getUser() ? manager.getUser().getUsername() : ''}`
      }),
    ];
    this.setColumns(columns);

    let sort: TableSort = new TableSort('name','asc');
    let tableDataSource = new ServerTableDataSource('Manager', ['user']);
    tableDataSource.setDefaultSort(sort);
    tableDataSource.queryModify = function (query: PFQuery): PFQuery {
      return filterDeleted(query);
    };
    tableDataSource.queryFilter = (filter, defaultQuery) => {
      defaultQuery.contains("name", filter);

      let usernameQuery = new PFQuery('User');
      usernameQuery.contains('username', filter);
      let managerUsernameQuery = new PFQuery('Manager');
      managerUsernameQuery.exists('user');
      managerUsernameQuery.matchesQuery('user', usernameQuery);

      return PFQuery.or(defaultQuery, managerUsernameQuery);
    };
    this.setProvider(tableDataSource);
  }

  canSortColumn(column) {
    if (column.key === 'credentials') {
      return false;
    }
    return super.canSortColumn(column);
  }
}
