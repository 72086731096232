import {locale as app_en} from "./en/app";
import {locale as app_ru} from "./ru/app";

import {locale as navigation_en} from "./en/navigation";
import {locale as navigation_ru} from "./ru/navigation";

export const app = [
  {
    lang: 'en',
    data: app_en
  },
  {
    lang: 'ru',
    data: app_ru
  },
];

export const navigation = [
  {
    lang: 'en',
    data: navigation_en
  },
  {
    lang: 'ru',
    data: navigation_ru
  },
];
