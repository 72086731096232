import { ReportBuilder } from "./report-builder";
import { XLSXBuilder } from 'app/components/excel-export/XLSXBuilder';

import { TranslateService } from "@ngx-translate/core";
import { Customer } from "app/components/data/core/models/persons/customer";
import { PFQuery } from "app/components/data/core/models/base/query";
import { Trip, TripStage } from "app/components/data/core/models/trip/trip";
import { Type } from "app/components/data/core/models/order/order";
import { Driver } from "app/components/data/core/models/persons/driver";


export class CustomerReportBuilder implements ReportBuilder {
    constructor(private translate: TranslateService, private customer: Customer) { }

    fileType(): string {
        return XLSXBuilder.fileType;
    }
    
    requestData(beginDate: Date, endDate: Date): Promise<any> {
        let query = new PFQuery('Trip');

        query.includes([
            "order",
            "order.articleBrand",
            "order.unloadingPoint",
            "transportUnit.driver",
            "transportUnit.vehicle",
            "transportUnit.trailer",
            "historyRecords.additionalData",
        ]);
        
        query.equalTo("stage", TripStage.Unloaded);

        let orderQuery = new PFQuery('Order');
        orderQuery.equalTo('customer', this.customer);

        query.matchesQuery('order', orderQuery);

        query.ascending("stageChangeDate");

        query.greaterThan("stageChangeDate", beginDate);
        query.lessThan("createdAt", endDate);

        console.log("Begin collecting data for report");
        return query.findAll().then(result => {
            if (result && result.length > 0) {

                let filtered = result.filter((trip: Trip) => {
                    return trip.order().isDeleted() == false
                })
                return Promise.resolve(filtered)
            }
        });
    }

    build(title: string, data: any) {
        let fields = this.makeFields();
        let builder = new XLSXBuilder(fields);
        builder.addHeader();
        let trips = data as Trip[];
        trips.forEach((trip) => {
            builder.addRow(this.makeRowForTrip(trip));
        });
        return builder.write(title);
    }

    private makeFields() {
        return [
            { key: "number", header: this.translate.instant("ExcelExport.Fields.Number"), width: 8 },
            { key: "unloadingDate", header: this.translate.instant("ExcelExport.Fields.UnloadingDate"), width: 15 },
            { key: "unloadingPoint", header: this.translate.instant("ExcelExport.Fields.UnloadingPoint"), width: 20 },
            { key: "unloadingBrand", header: this.translate.instant("ExcelExport.Fields.UnloadingBrand"), width: 15 },
            { key: "unloadingTonnage", header: this.translate.instant("ExcelExport.Fields.UnloadingTonnage"), width: 15 },
            { key: "driver", header: this.translate.instant("ExcelExport.Fields.Driver"), width: 20 },
            { key: "vehicleNumber", header: this.translate.instant("ExcelExport.Fields.VehicleNumber"), width: 13 },
            { key: "trailerNumber", header: this.translate.instant("ExcelExport.Fields.TrailerNumber"), width: 13 }, 
            { key: "kilometers", header: this.translate.instant("ExcelExport.Fields.Kilometers"), width: 10 },
            { key: "waybillNumber", header: this.translate.instant("ExcelExport.Fields.WaybillNumber"), width: 13 },           
        ];
    }

    private makeRowForTrip(trip: Trip) {
        let order = trip.order();
        let transportUnit = trip.transportUnit();
        let tonnage = trip.tonnage();

        let unloadingDate = order.unloadingBeginDate();

        let unloadingTonnage = tonnage;

        let loadingHistoryRecord;
        let unloadingHistoryRecord;

        let kilometers = "-";
        let waybillNumber = "-";

        let historyRecords = trip.historyRecords();
        if (historyRecords) {
            loadingHistoryRecord = historyRecords.find(historyRecord => historyRecord.stage() === TripStage.Loaded);
            unloadingHistoryRecord = historyRecords.find(historyRecord => historyRecord.stage() === TripStage.Unloaded);
        }
        
        if (loadingHistoryRecord) {
            let additionalData = loadingHistoryRecord.additionalData();
            if (order.getType() == Type.Carriage && additionalData) {
                if (additionalData.kilometers) {
                    kilometers = `${additionalData.kilometers}`;
                }
                if (additionalData.waybillNumber) {
                    waybillNumber = additionalData.waybillNumber;
                }
            }
        }

        if (unloadingHistoryRecord) {
            if (unloadingHistoryRecord.date()) {
                unloadingDate = unloadingHistoryRecord.date();
            }

            if (unloadingHistoryRecord.tonnage()) {
                unloadingTonnage = unloadingHistoryRecord.tonnage();
            }
        }

        let unloadingPoint = order.unloadingPoint();
        let driver: Driver = transportUnit.driver();

        let vehicleNumber = transportUnit.vehicle().number();
        let trailerNumber = transportUnit.trailer() ? transportUnit.trailer().number() : '';

        return {
            number: order.number(),
            unloadingDate: unloadingDate,
            unloadingPoint: unloadingPoint ? unloadingPoint.getAddress() : "",
            unloadingBrand: order.articleBrand().name(),
            unloadingTonnage: unloadingTonnage,
            driver: driver.getName(),
            vehicleNumber: vehicleNumber,
            trailerNumber: trailerNumber,
            kilometers: kilometers,
            waybillNumber: waybillNumber,
        };
    }
}