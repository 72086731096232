
import { ActionBarOperation } from 'app/components/action-bar/action-bar.component';
import { TableComponent, ClickData } from 'app/components/table/table.component';

export interface TableOperationsFactory {
    makeDefaultOperation?: (value: any) => ActionBarOperation;
    makeOperations: (value: any) => ActionBarOperation[];
}

export class TableOperationsController {
    operations: ActionBarOperation[];
    constructor(private factory: TableOperationsFactory) {
        this.updateOperations(null);
    }
    doubleClickHandler(value: ClickData) {
        if (!this.factory.makeDefaultOperation) return;
        let operation = this.factory.makeDefaultOperation([value.data]);
        operation.action();
    };

    updateOperations(table: TableComponent) {
        let selected = table ? table.selectedValues() : [];
        this.operations = this.factory.makeOperations(selected);
    };
}
