import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { PFQuery } from 'app/components/data/core/models/base/query';
import { PFObject } from 'app/components/data/core/models/base/object';

import * as FormHelper from 'app/components/form-helper/form-helper';

import { DataLoader } from 'app/components/data/data-loader';
import { DispatcherCloudExecutor } from 'app/components/data/cloud-executors/dispatcher-cloud-executor';

import { LocaleService } from 'app/components/locale/locale.service';
import { create_unit } from 'app/main/admin/users/locale/locale';
import { vehicle, trailer } from 'app/main/admin/vehicles/locale/locale';
import { Driver } from 'app/components/data/core/models/persons/driver';
import { Trailer, Vehicle } from 'app/components/data/core/models/transport/transport';
import { Carrier } from 'app/components/data/core/models/persons/carrier';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

import * as CustomValidators from 'app/components/validators/validators';
import { CurrentUser } from 'app/components/data/core/models/base/user';
import { LocalNotificationService } from 'app/components/local-notifications-service/local-notifications-service';

let locales = [
  create_unit,
  vehicle,
  trailer,
];

@Component({
  selector: 'unit-create-dialog',
  templateUrl: './unit-create-dialog.component.html',
  styleUrls: ['./unit-create-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class UnitCreateDialog {
  compareFunction = PFObject.compareFn;
  
  public form: UntypedFormGroup;

  carriersLoader: DataLoader;
  driversLoader: DataLoader;
  vehiclesLoader: DataLoader;
  trailersLoader: DataLoader;

  carriers: Observable<Carrier[]>;
  drivers: Observable<Driver[]>;
  vehicles: Observable<Vehicle[]>;
  trailers: Observable<Trailer[]>;

  executor = new DispatcherCloudExecutor;

  isInitialized: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<UnitCreateDialog>,
    private _formBuilder: UntypedFormBuilder,
    private localeService: LocaleService,
    private notifications: LocalNotificationService
  ) {
    this.localeService.loadLocales(locales);
    this.carriersLoader = new DataLoader('Carrier');
    this.driversLoader = new DataLoader('Driver');
    this.vehiclesLoader = new DataLoader('Vehicle');
    this.trailersLoader = new DataLoader('Trailer');

    this.driversLoader.setQueryModificationBlock((query: PFQuery) => {
      query.equalTo('carrier', this.getCarrier());
      query.doesNotExist('transportUnit');
    });
    this.vehiclesLoader.setQueryModificationBlock((query: PFQuery) => {
      query.equalTo('carrier', this.getCarrier());
      query.doesNotExist('transportUnit');
    });
    this.trailersLoader.setQueryModificationBlock((query: PFQuery) => {
      query.equalTo('carrier', this.getCarrier());
      query.doesNotExist('transportUnit');
    });

    this.form = this.createContactForm();
  }

  ngOnInit() {
    this.carriers = this.form.controls.carrier.valueChanges.pipe(
      startWith<string | Carrier>(''),
      map(value => this.nameFilter(value, this.carriersLoader.getLoadedItems()))
    );

    this.drivers = this.form.controls.driver.valueChanges.pipe(
      startWith<string | Driver>(''),
      map(value => this.nameFilter(value, this.driversLoader.getLoadedItems()))
    );

    this.vehicles = this.form.controls.vehicle.valueChanges.pipe(
      startWith<string | Vehicle>(''),
      map(value => this.numberFilter(value, this.vehiclesLoader.getLoadedItems()))
    );

    this.trailers = this.form.controls.trailer.valueChanges.pipe(
      startWith<string | Trailer>(''),
      map(value => this.numberFilter(value, this.trailersLoader.getLoadedItems()))
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.isCarrierVisible()) {
        this.carriersLoader.loadAll().then(() => {
          this.form.controls.carrier.setValue('');
        });
      } else {
        this.carrierChanged();
      }
      this.isInitialized = true;
    }, 200);
  }

  isCarrierVisible(): boolean {
    return CurrentUser.isAdministrator() || CurrentUser.isManager() || CurrentUser.isLogistician();
  }

  private nameFilter(value, options): any[] {
    if (!options) {
      return [];
    }

    if (!value || typeof value !== 'string') {
      return options.slice();
    }

    let filterValue = value.toLowerCase();
    return options.filter(opt => {
      let name = opt.getName();
      return name && name.toLowerCase().indexOf(filterValue) !== -1;
    });
  }

  private numberFilter(value, options) {
    if (!options) {
      return [];
    }

    if (!value || typeof value !== 'string') {
      return options.slice();
    }

    let filterValue = value.toLowerCase();
    return options.filter(opt => {
      let number = opt.number();
      return number && number.toLowerCase().indexOf(filterValue) !== -1;
    });
  }

  nameFn(obj) {
    return (obj && typeof obj !== 'string') ? obj.getName() : undefined;
  }

  numberFn(obj) {
    return (obj && typeof obj !== 'string') ? obj.number() : undefined;
  }

  private createContactForm(): UntypedFormGroup {
    let formGroup = {
      carrier: [null, CustomValidators.isObjectValidator],
      driver: [null, CustomValidators.isObjectValidator],
      vehicle: [null, CustomValidators.isObjectValidator],
      trailer: [null, CustomValidators.isObjectValidator],
    };
    return this._formBuilder.group(formGroup);
  }

  carrierChanged() {
    this.driversLoader.loadAll().then(() => {
      this.form.controls.driver.setValue('');
    });
    this.vehiclesLoader.loadAll().then(() => {
      this.form.controls.vehicle.setValue('');
    });
    this.trailersLoader.loadAll().then(() => {
      this.form.controls.trailer.setValue('');
    });
  }

  private getCarrier(): Carrier {
    if (this.isCarrierVisible()) {
      return this.form.controls.carrier.value;
    }
    return CurrentUser.carrier();
  }

  private getDriver(): Driver {
    return this.form.controls.driver.value;
  }

  private getVehicle(): Vehicle {
    return this.form.controls.vehicle.value;
  }

  private getTrailer(): Trailer {
    return this.form.controls.trailer.value;
  }

  private createUnit(): Promise<any> {
    return this.executor.makeTransportUnit(this.getDriver(), this.getVehicle(), this.getTrailer());
  }

  checkAndSave() {
    if (this.checkForm()) {
      this.createUnit().then(() => {
        this.dialogRef.close(true);
      }).catch(error => {
        this.notifications.showErrorNotification(error);
      });
    }
  }

  private checkForm(): boolean {
    return FormHelper.checkForm(this.form);
  }
}
