
export const locale = {
  'DIALOG': {
    'DRIVER': {
      'CREATE': {
        'TITLE': 'Create',
        'CONFIRMBUTTON': 'Create',
      },
      'EDIT': {
        'TITLE': 'Edit',
        'CONFIRMBUTTON': 'Save',
      },
      'APPROVE': {
        'TITLE': 'Approve driver',
        'CONFIRMBUTTON': 'Approve',
        'DECLINEBUTTON': 'Cancel approval',
      },
      'FULLNAME': 'Full name',
      'PHONE_NUMBER': 'Phone number',
      'APPROVED_LOCK': 'Editing forbidden for approved drivers',
      'SHOW_TARIFF_OPTION': 'Show delivery tariff',
      'CARRIER_SELECT': 'Select carrier',
      'ERROR': {
        'NO_CARRIER': 'Carrier not selected',
      }
    }
  },
};
