export const locale = {
  'DIALOG' : {
    'UNLOADING_POINT' : {
      'TITLE' : {
        'CREATE' : 'Создать точку',
        'EDIT' : 'Изменить точку',
      },
      'CONFIRMBUTTON' : {
        'CREATE' : 'Создать',
        'SAVE' : 'Сохранить',
      },
      'ADDRESS' : 'Адрес',
      'CONTACT_NAME': 'Имя контакта',
      'CONTACT_PHONE': 'Телефон контакта',
    },
  },
};
