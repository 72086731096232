export const locale = {
    'DIALOG': {
        'CONTACTS': {
          'TITLE': {
            'CREATE': 'Create contact',
            'EDIT': 'Edit contact',
          },
          'CONFIRMBUTTON': {
            'CREATE': 'Create',
            'SAVE': 'Save',
          },
          'NAME': 'Name',
          'PHONE_NUMBER': 'Phone number',
        },
      },
  };
  