import { TableData, TableRowData, TableSort } from 'app/components/table/tabledata';
import { ServerTableDataSource } from 'app/components/table/data_sources/server-data-source';
import { PFQuery } from 'app/components/data/core/models/base/query';
import { filterDeleted } from 'app/components/access/query';
import { Supplier } from 'app/components/data/core/models/persons/supplier';

export class TransferPointTableData extends TableData {

  constructor() {
    super();

    let columns = [
      new TableRowData({
        key: 'name',
        header: 'USERS.TABLEROW.STORAGE',
        cell: (supplier: Supplier) => `${supplier.getName()}`
      }),
    ];
    this.setColumns(columns);

    let sort: TableSort = new TableSort('name','asc');
    let tableDataSource = new ServerTableDataSource('Supplier', ['loadingPoints.entrances']);
    tableDataSource.setDefaultSort(sort);
    tableDataSource.queryModify = function (query: PFQuery): PFQuery {
      query.equalTo('transfer', true);
      return filterDeleted(query);
    };
    tableDataSource.queryFilter = (filter, query) => {
      query.contains('name', filter);
      return query;
    };
    this.setProvider(tableDataSource);
  }
}