import { Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PFObject } from 'app/components/data/core/models/base/object';
import { LocaleService } from 'app/components/locale/locale.service';
import { supplier } from '../../../../../locale/locale';
import { defaultTableOptions, TableComponent, TableOptions } from 'app/components/table/table.component';
import { AvailableArticleBrandsTableData } from './article-brands-tabledata';
import { ArticleBrand, ArticleType } from 'app/components/data/core/models/articles/article';
import { DataLoader } from 'app/components/data/data-loader';

let locales = [
  supplier,
];

@Component({
  selector: 'add-supplier-brands-dialog',
  templateUrl: './add-brands.dialog.component.html',
  styleUrls: ['./add-brands.dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SupplierBrandAddDialog {
  compareFunction = PFObject.compareFn;

  tableData: AvailableArticleBrandsTableData;
  tableOptions: TableOptions;
  @ViewChild(TableComponent) table: TableComponent

  public dataLoader: DataLoader;
  private alreadyAdded: ArticleBrand[];

  private _selectedType: ArticleType | string = 'all';

  get selectedType(): ArticleType | string {
    return this._selectedType;
  }

  set selectedType(value: ArticleType | string) {
    this._selectedType = value;
    this.updateData()
  }

  constructor(
    private localeService: LocaleService,
    private dialogRef: MatDialogRef<SupplierBrandAddDialog>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.localeService.loadLocales(locales);
    this.tableOptions = defaultTableOptions();
    this.alreadyAdded = data.alreadyAdded;
    this.dataLoader = new DataLoader('ArticleType');
    this.updateData()
    this.dataLoader.loadAll()
  }

  private updateData() {

    this.tableData = new AvailableArticleBrandsTableData(
      this.selectedType instanceof ArticleType ? this.selectedType : null,
      this.alreadyAdded
    );
  }

  save() {
    console.log('Add brands save')
    this.dialogRef.close(this.table.selectedValues());
  }
}
