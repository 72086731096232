
<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="primary" 
                 title="{{'DIALOG.SUPPLIER.TITLE.BRANDS_LIST' | translate}}"
                 subtitle="{{supplier.getName()}}">
  </dialog-header>
  <div class="pt-8 px-16" fxFlex fxLayout="column">
    <div class="mat-elevation-z1 table-wrapper" fxFlex cdkScrollable>
      <action-bar [operations]="operationsController.operations">
      </action-bar>
      <cd-table [tabledata]="tableData" 
                [options]="tableOptions"
                (onSelectionChange)="updateOperations($event.table)">
      </cd-table>
    </div>
    <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button
              color="accent"
              [disabled]="isProcessing()"
              (click)="save()">{{'DIALOG.SUPPLIER.CONFIRMBUTTON.SAVE' | translate}}
      </button>
    </div>
  </div>
</div>
