import {locale as articles_en} from "./en/locale.articles";
import {locale as articles_ru} from "./ru/locale.articles";

import {locale as createbrand_en} from "./en/locale.createbrand";
import {locale as createbrand_ru} from "./ru/locale.createbrand";

import {locale as createshape_en} from "./en/locale.createshape";
import {locale as createshape_ru} from "./ru/locale.createshape";

import {locale as createtype_en} from "./en/locale.createtype";
import {locale as createtype_ru} from "./ru/locale.createtype";

export const articles = [
  {
    lang: "en",
    data: articles_en
  },
  {
    lang: "ru",
    data: articles_ru
  }
];

export const createbrand = [
  {
    lang: "en",
    data: createbrand_en
  },
  {
    lang: "ru",
    data: createbrand_ru
  }
];

export const createshape = [
  {
    lang: "en",
    data: createshape_en
  },
  {
    lang: "ru",
    data: createshape_ru
  }
];

export const createtype = [
  {
    lang: "en",
    data: createtype_en
  },
  {
    lang: "ru",
    data: createtype_ru
  }
];
