import {locale as passes_en} from "./en/locale.pass";
import {locale as passes_ru} from "./ru/locale.pass";

export const pass = [
  {
    lang: "en",
    data: passes_en
  },
  {
    lang: "ru",
    data: passes_ru
  }
];