<div class="customer-step-wrapper">
    <form [formGroup]="form">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field fxFlex>
                <mat-select placeholder="{{'ORDER.FIELD_NAME.INTERMEDIARY' | translate}}"
                            formControlName="intermediary"
                            [compareWith]="compareFunction"
                            [attr.cdkFocusInitial]="editing ? '' : null"
                            required>
                    <mat-option *ngIf='intermediarysDataLoader.isLoading()'>
                        <mat-spinner diameter="20" color="accent"></mat-spinner>
                    </mat-option>
                    <mat-option *ngIf="!intermediarysDataLoader.isLoading() && intermediarysDataLoader.getLoadError()">
                        {{ 'ORDER.DIALOG.ERROR.LOAD_ERROR' | translate }}
                    </mat-option>
                    <mat-option *ngFor='let intermediary of intermediarysDataLoader.getLoadedItems()'
                                [value]="intermediary"
                                [disabled]="intermediary.isDeleted()">
                        {{ intermediary.getName() }}{{ intermediary.isDeleted() ? ' (deleted)' : '' }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-icon-button *ngIf="canAddIntermediary()" type="button" (click)="addIntermediary()">
                <mat-icon>add</mat-icon>
            </button>
            <button *ngIf="intermediarysDataLoader.getLoadError()"
                    mat-icon-button type="button"
                    (click)="reloadIntermediarys()">
                <mat-icon>refresh</mat-icon>
            </button>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field fxFlex>
                <input #customerInput
                       matInput
                       placeholder="{{'ORDER.FIELD_NAME.CUSTOMER' | translate}}"
                       [matAutocomplete]="auto"
                       formControlName="customer"
                       [attr.cdkFocusInitial]="editing ? null : ''"
                       required>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="customerDisplay"
                                  (optionSelected)='customerChanged($event.option.value)'>
                    <mat-option *ngIf='customersDataLoader.isLoading()'>
                        <mat-spinner diameter="20" color="accent"></mat-spinner>
                    </mat-option>
                    <mat-option *ngIf="!customersDataLoader.isLoading() && customersDataLoader.getLoadError()">
                        {{ 'ORDER.DIALOG.ERROR.LOAD_ERROR' | translate }}
                    </mat-option>
                    <mat-option *ngFor='let customer of filteredCustomers | async'
                                [value]="customer"
                                [disabled]="customer.isDeleted()">
                        {{ customer.getName() }}{{ customer.isDeleted() ? ' (deleted)' : '' }}
                    </mat-option>
                </mat-autocomplete>
                <mat-error
                        *ngIf="form.get('customer').errors?.notObject">{{ 'ORDER.DIALOG.ERROR.MUST_SELECT' | translate }}
                </mat-error>
                <mat-error
                        *ngIf="form.get('customer').errors?.notChecked">{{ 'ORDER.DIALOG.ERROR.CUSTOMER_NOT_CHECKED' | translate }}
                </mat-error>
            </mat-form-field>

            <button mat-icon-button *ngIf="canAdd()" type="button" (click)="addCustomer()">
                <mat-icon>add</mat-icon>
            </button>
            <button *ngIf="customersDataLoader.getLoadError()"
                    mat-icon-button type="button"
                    (click)="reloadCustomers()">
                <mat-icon>refresh</mat-icon>
            </button>
        </div>

        <div *ngIf="getCustomer()">
            <div *ngIf="!getCustomer().isInternal()">
                <div fxLayout="column" fxLayoutAlign="center center">
                    <button mat-raised-button class="accent"
                            type="button"
                            (click)="checkCustomer()">
                        {{ 'ORDER.FIELD_NAME.CHECK_CUSTOMER' | translate }}
                    </button>
                </div>

                <div *ngIf='executor.isProcessing()'
                     class='py-20'
                     fxLayout="column"
                     fxLayoutAlign="center center">
                    <mat-spinner diameter="30" color="accent"></mat-spinner>
                </div>

                <div *ngIf='checkError' class='py-8' fxLayout="column" fxLayoutAlign="center center">
                    {{ 'ORDER.DIALOG.ERROR.VERIFY_ERROR' | translate }}
                </div>

                <div *ngIf="customerCheckResult">
                    <div class='customer-check mt-20'
                         [ngClass]="customerCheckResult.block ? 'deep-orange-300-bg' : 'green-500-bg'">
                        <mat-label class='check-label'
                                   fxLayoutAlign="center center">
                            {{ 'DIALOG.CUSTOMER.RECEIVABLES' | translate }}
                            : {{ rank(customerCheckResult.receivables) }}  {{ 'DIALOG.CUSTOMER.CREDIT' | translate }}
                            : {{ rank(customerCheckResult.credit) }}
                        </mat-label>
                    </div>

                    <div *ngIf="customerCheckResult.block"
                         class="customer-check warn-400-bg mt-20">
                        <mat-label class='check-label'
                                   fxLayoutAlign="center center">
                            {{ customerCheckResult.block }}
                        </mat-label>
                    </div>
                </div>
            </div>

            <div *ngIf="canShowCustomer()">
                <div>
                    <mat-form-field fxFlex>
                        <mat-select placeholder="{{'ORDER.FIELD_NAME.UNLOADING_POINT' | translate}}"
                                    formControlName="unloadingPoint"
                                    (selectionChange)='pointChanged($event.value)'
                                    [compareWith]="compareFunction"
                                    required>
                            <mat-option *ngFor='let point of getUnloadingPoints()'
                                        [value]="point"
                                        [disabled]="point.isDeleted()">
                                {{ point.getAddress() }}{{ point.isDeleted() ? ' (deleted)' : '' }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button mat-icon-button *ngIf="canAdd()" type="button" (click)="addUnloadingPoint()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>

                <div *ngIf="getUnloadingPoint()">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <mat-form-field fxFlex>
                            <mat-select placeholder="{{'ORDER.FIELD_NAME.UNLOADING_ENTRANCE' | translate}}"
                                        formControlName="unloadingEntrance"
                                        [compareWith]="compareFunction"
                                        required>
                                <mat-option *ngFor="let entrance of getEntrances();"
                                            [value]="entrance"
                                            [disabled]="entrance.isDeleted()">
                                    {{ entrance.getName() }} ({{ entrance.getAddress() }}
                                    ){{ entrance.isDeleted() ? ' (deleted)' : '' }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <button mat-icon-button *ngIf="canAdd()" type="button" (click)="addUnloadingEntrance()">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>

                <div>
                    <mat-form-field fxFlex>
                        <mat-select placeholder="{{'ORDER.FIELD_NAME.UNLOADING_CONTACT' | translate}}"
                                    formControlName="contacts"
                                    [compareWith]="contactCompare">
                            <mat-option *ngFor='let contact of contacts'
                                        [value]="contact">
                                {{ contact.phoneNumber }} ({{ contact.name }})
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <button mat-icon-button
                            type="button"
                            [disabled]="!isContactsSelectable()"
                            (click)="addUnloadingContact()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>

                <div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <mat-form-field fxFlex="56">
                            <input matInput
                                   type="text"
                                   [matDatepicker]="unloadPicker"
                                   placeholder="{{'ORDER.FIELD_NAME.UNLOADING_DATE' | translate}}"
                                   formControlName="unloadingDate"
                                   required>
                            <mat-datepicker-toggle matSuffix [for]="unloadPicker"></mat-datepicker-toggle>
                            <mat-datepicker #unloadPicker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field fxFlex="20">
                            <input matInput
                                   type="number"
                                   placeholder="{{'ORDER.FIELD_NAME.UNLOADING_TIME_FROM' | translate}}"
                                   formControlName="unload_time_from"
                                   (change)="updateDate()"
                                   min="0"
                                   max="23"
                                   required>
                        </mat-form-field>
                        <mat-form-field fxFlex="20">
                            <input matInput
                                   type="number"
                                   placeholder="{{'ORDER.FIELD_NAME.UNLOADING_TIME_TO' | translate}}"
                                   formControlName="unload_time_to"
                                   min="0"
                                   max="23"
                                   required>
                        </mat-form-field>
                    </div>
                    <mat-error
                            *ngIf="form.get('unload_time_to').errors?.invalidInterval">{{ 'ORDER.DIALOG.ERROR.INVALID_INTERVAL' | translate }}
                    </mat-error>
                </div>
            </div>
            <div *ngIf="canShowCustomer() && CurrentUser.canChangeAgreeStatus()">
                <mat-form-field fxFlex>
                    <mat-select placeholder="{{ 'STEP_CUSTOMER_ORDER.DIALOG.AGREE_FIELD.TITLE'|translate }}"
                                formControlName="agreeOrderStatus"
                                required>
                        <mat-option *ngFor='let type of agreeOrderStatuses'
                                    [value]="type">
                            {{ agreeStatusName(type) | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </form>

</div>
