
import { PersonWithUser } from "./person";

export class Manager extends PersonWithUser {
  constructor() { super("Manager"); }

  getPhoneNumber() { return super.get('phoneNumber'); }

  copy(): Manager {
    let copy = new Manager();
    copy.setName(this.getName());
    return copy;
  }
}
