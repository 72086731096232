import { Component, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCheckbox } from '@angular/material/checkbox';

import { Carrier, OrderLimitation } from 'app/components/data/core/models/persons/carrier';

import { LocaleService } from 'app/components/locale/locale.service';
import { carrier } from '../../../locale/locale';
import { CloudExecutor } from 'app/components/data/cloud-executors/cloud-executor';
import { DialogPresenterService } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import { CarrierCustomerLimitationsDialog } from './lists/customers/carrier-customers-limitations-dialog.component';
import { CarrierSupplierLimitationsDialog } from './lists/suppliers/carrier-supplier-limitations-dialog.component';

@Component({
    selector   : 'carrier-limitations-dialog',
    templateUrl: './carrier-limitations-dialog.component.html',
    styleUrls  : ['./carrier-limitations-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CarrierLimitationsDialog {

  private object: Carrier;
  public dataSaver = new CloudExecutor();

  canCreateOrder;
  canEditOrder;
  canSeeSalePrice;
  canReserveOrder;

  constructor (
    public dialogRef: MatDialogRef<CarrierLimitationsDialog>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private localeService: LocaleService,
    private dialogPresenterService: DialogPresenterService
  ) {
    this.localeService.loadLocale(carrier);
    this.object = this._data.object;

    let permissions = this.object.getOrderPermissions();
    this.canCreateOrder = permissions.create;
    this.canEditOrder = permissions.update;
    this.canSeeSalePrice = permissions.salePrice;
    this.canReserveOrder = permissions.reserveOrder;
  }

  name(): string {
    return this.object.getName();
  }

  showCustomersLimitations() {
    let data = { action: null, object: this.object, disableClose: false, };
    let dialogData = { dialog: CarrierCustomerLimitationsDialog, panel: 'carrier-customers-limitations-dialog-container', data: data };
    this.dialogPresenterService.showDialog(dialogData, null);
  }

  showSuppliersLimitations() {
    let data = { action: null, object: this.object, disableClose: false, };
    let dialogData = { dialog: CarrierSupplierLimitationsDialog, panel: 'carrier-supplier-limitations-dialog-container', data: data };
    this.dialogPresenterService.showDialog(dialogData, null);
  }

  confirm() {
    let limitations: OrderLimitation = {
      create: !this.canEditOrder ? false : this.canCreateOrder,
      update: this.canEditOrder,
      salePrice: this.canSeeSalePrice,
      reserveOrder: this.canReserveOrder
    }
    this.object.setOrderPermissions(limitations);
    this.dataSaver.saveObject(this.object).then(obj => {
      this.dialogRef.close(obj);
    });
  }
}
