import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { LocaleService } from 'app/components/locale/locale.service';
import { LoadingPoint, Entrance } from 'app/components/data/core/models/points/points';
import { PFGeoPoint } from 'app/components/data/core/models/base/point';
import { agreement } from '../../../../locale/locale';

import { CloudExecutor } from 'app/components/data/cloud-executors/cloud-executor';
import * as FormHelper from 'app/components/form-helper/form-helper';

import { debounceTime } from 'rxjs/operators';
import * as Location from "app/components/location/coordinate";
import * as Geocode from 'app/components/geocode/geocode';
import * as Places from 'app/components/geocode/places';

import { TranslateService } from '@ngx-translate/core';
import { LocalNotificationService } from 'app/components/local-notifications-service/local-notifications-service';

@Component({
  selector: 'loading-point-create-dialog',
  templateUrl: './loading-point-create-dialog.component.html',
  styleUrls: ['./loading-point-create-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SupplierLoadingPointDialog {

  public form: UntypedFormGroup;
  private object: LoadingPoint;

  public title: string;
  public buttonName: string;

  public dataSaver = new CloudExecutor;

  public processing: boolean = false;

  public addressSearchResults: Places.SearchResult[];
  private addressSearchService: Places.SearchService;
  private lastGeocodedAddress: string;
  private lastGeocodedCoordinate: Location.Coordinate;
  private geocoder: Geocode.Geocoder;

  constructor(
    public dialogRef: MatDialogRef<SupplierLoadingPointDialog>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _formBuilder: UntypedFormBuilder,
    private localeService: LocaleService,
    private notifications: LocalNotificationService,
    private translate: TranslateService
  ) {
    this.localeService.loadLocale(agreement);

    this.object = this._data.object;
    if (this._data.action === 'create') {
      this.title = 'DIALOG.AGREEMENT.TITLE.CREATE';
      this.buttonName = 'DIALOG.AGREEMENT.CONFIRMBUTTON.CREATE';
    } else if (this._data.action === 'edit') {
      this.title = 'DIALOG.AGREEMENT.TITLE.EDIT';
      this.buttonName = 'DIALOG.AGREEMENT.CONFIRMBUTTON.SAVE';
    }

    if (this.object == null) {
      this.object = new LoadingPoint();
    }
    this.form = this.createContactForm();

    this.form
      .get('address')
      .valueChanges
      .pipe(debounceTime(500))
      .subscribe(value => this.addressChanged(value));
  }

  createContactForm(): UntypedFormGroup {
    let formGroup = {
      address: this.object.getAddress()
    };
    return this._formBuilder.group(formGroup);
  }

  checkAndSave() {
    if (this.checkForm()) {
      this.processing = true;
      let address = this.form.controls.address.value;

      this.object.setAddress(address);

      if (this._data.action === 'create') {
        this.getCoordinateForAddress(address).then((coordinate) => {
          return this.createEntrance(address, coordinate);
        }).then(point => {
          this.object.addEntrance(point);
          return this.dataSaver.saveObject(this.object);
        }).then((c) => {
          this.close(c);
        }).catch(error => {
          this.handleError(error, address);
          this.processing = false;
        });
      } else {
        this.dataSaver.saveObject(this.object).then((loadingPoint) => {
          this.close(loadingPoint);
        });
      }
    }
  }

  handleError(error: string, address: string) {
    var errorMsg;
    var errorObject;
    if (error == 'ZERO_RESULTS') {
      errorMsg = 'DIALOG.AGREEMENT.ERROR.ZERO_RESULTS';
      errorObject = address;
    }

    let localizedMessage = this.translate.instant(errorMsg, {value: errorObject});
    this.notifications.showErrorNotification(localizedMessage);
  }

  close(loadingPoint) {
    this.dialogRef.close(loadingPoint);
  }

  createEntrance(address, coordinate) {
    let entrance = new Entrance();
    entrance.setName(this.localeService.translateService.instant('DIALOG.AGREEMENT.DEFAULT_POINT'));
    entrance.setAddress(address);

    if (coordinate) {
      entrance.setCoordinate(new PFGeoPoint({
        latitude: coordinate.latitude,
        longitude: coordinate.longitude
      }));
    } else {
      console.log('No coords for address ' + address);
    }
    return this.dataSaver.saveObject(entrance);
  }

  getCoordinateForAddress(address: string) {
    if (address === this.lastGeocodedAddress && this.lastGeocodedCoordinate) {
      return Promise.resolve(this.lastGeocodedCoordinate);
    } else {
      return this.geocoder.codeAddress(address);
    }
  }

  checkForm(): boolean {
    return FormHelper.checkForm(this.form);
  }

  mapReady(map) {
    this.loadAddressService(map);
  }

  loadAddressService(map) {
    this.geocoder = new Geocode.Geocoder();
    this.addressSearchService = Places.SearchService.all(map);

    this.addressSearchService.results.subscribe((results) => {
      this.addressSearchResults = results;
    });
  }

  public addressChanged(text: string): void {
    if (text.length > 2) {
      this.addressSearchService.search(text);
    } else {
      this.addressSearchResults = [];
    }
  }

  public addressSearchResultSelected(searchResult: Places.SearchResult) {
    this.form.controls.address.setValue("");
    this.addressSearchService.getDetails(searchResult).then((details) => {
      this.setAddress(details.address);
      this.lastGeocodedCoordinate = details.coordinate;
    });
  }

  private setAddress(address: string): void {
    this.lastGeocodedAddress = address;
    this.form.controls.address.setValue(address);
    this.form.controls.address.setErrors(null);
  }
}
