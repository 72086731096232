import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { RemoveDialogComponent } from 'app/components/dialogs/removedialog/remove-dialog.component';
import { AlertComponent } from 'app/components/alert/alert.component';

export interface DialogData {
  dialog;
  panel: string;
  data;
}

export interface AlertData {
  title: string,
  content: string,
}

@Injectable()
export class DialogPresenterService {

  constructor(public dialog: MatDialog) { }

  showDialog(dialogData: DialogData, completion = null) {
    const dialogRef = this.dialog.open(dialogData.dialog, {
      panelClass: dialogData.panel,
      disableClose: dialogData.data.disableClose != null ? dialogData.data.disableClose : true,
      data: dialogData.data,
    });
    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed && completion) {
        completion(confirmed);
      }
    });
  }

  showAlert(data: AlertData, completion = null) {
    const dialogRef = this.dialog.open(AlertComponent, {
      panelClass: 'alert-component',
      data: data,
    });
    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed && completion) {
        completion();
      }
    });
  }

  askRemoveItems(items, completion = null, hard = false, onlyAsk = false) {
    let dialogData = {
      dialog: RemoveDialogComponent,
      panel: 'remove-dialog-container',
      data: { objects: items, hard: hard, onlyAsk: onlyAsk }
    }
    this.showDialog(dialogData, completion)
  }
}
