import { DialogPresenterService, DialogData } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import { CreateArticleTypeDialog } from 'app/main/admin/articles/dialogs/createtype/create-article-type-dialog.component';
import { TableOperationActionType, TableOperationsHelper } from 'app/components/helpers/table-operations.helper';
import { TableOperationsFactory } from 'app/components/table-operations-controller/table-operations-controller';

export class ArticlesTypeOperationsFactory implements TableOperationsFactory {

    constructor(
        private dialogPresenter: DialogPresenterService,
        private completion,
    ) { }

    makeOperations(selected) {
        return this.makeOperationsWithSelected(selected);
    }

    makeDefaultOperation(selected) {
        return TableOperationsHelper.makeOperationWithType(
            TableOperationActionType.Edit,
            this.dialogPresenter,
            () => this.makeDialogData(),
            selected);
    }

    private makeOperationsWithSelected(selected) {
        return TableOperationsHelper.makeDefaultOperations(this.dialogPresenter, () => this.makeDialogData(), selected, this.completion);
    }

    private makeDialogData(): DialogData {
        return {
            dialog: CreateArticleTypeDialog,
            panel: 'create-article-type-dialog-container',
            data: {}
        }
    }
}