import { CurrentUser } from '../data/core/models/base/user';
import { Driver } from 'app/components/data/core/models/persons/driver';
import { PFDeletableObject } from '../data/core/models/base/deletableObject';
import { PFObject } from '../data/core/models/base/object';
import { Order } from '../data/core/models/order/order';
import { TransportUnit } from '../data/core/models/transport/transport';

export namespace QueryResultFilter {
    export function filterDeleted(objects) {
        let settings = CurrentUser.getSettings();
        if (!settings.displayDeleted) {
            return PFDeletableObject.filterDeleted(objects);
        }
        return objects;
    }

    export function containsCurrentCarrier(object: Order): boolean {
        let carriers = object.getCarriers();
        if (!carriers) return false;

        for (let carrier of carriers) {
            if (PFObject.compareFn(carrier, CurrentUser.carrier())) {
                return true;
            }
        }
        return false;
    }

    export function orderAuthorMatchesCurrentUser(object): boolean {
        let order = object.get("order");
        if (!order) return false;
        return authorMatchesCurrentUser(order);
    }

    export function authorMatchesCurrentUser(object: Order): boolean {
        let author = object.author();
        if (!author) return false;
        return PFObject.compareFn(author, CurrentUser.user());
    }

    export function transportUnitMatchesCurrentCarrier(object: TransportUnit): boolean {
        return driverMatchesCurrentCarrier(object.driver());
    }

    export function driverMatchesCurrentCarrier(object: Driver): boolean {
        if (!object) return false;
        let carrier = object.getCarrier();
        if (!carrier) return false;
        return PFObject.compareFn(carrier, CurrentUser.carrier());
    }
}
