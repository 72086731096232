import { DataLoader } from 'app/components/data/data-loader';
import { PFObject } from 'app/components/data/core/models/base/object';
import { PFQuery } from 'app/components/data/core/models/base/query';
import { LiveQueryModifier, LiveQuerySubscriber } from 'app/components/table/data_sources/live-query-subscriber';
import { BehaviorSubject } from 'rxjs';
interface DataSourceOptions {
    delayBeforeFinish: number
    filterDeleted: boolean
}

export class DataSource<T> {
    items = new BehaviorSubject<T[]>(null);

    private fields: string[];
    options: DataSourceOptions = {
        delayBeforeFinish: 500,
        filterDeleted: true
    }

    private loader: DataLoader;
    private subscriber: LiveQuerySubscriber;

    constructor(obj: any, fields: string[], options: DataSourceOptions = null) {
        this.fields = fields;
        if (options) {
            this.options = options;
        }
        this.loader = new DataLoader(obj);
        this.subscriber = new LiveQuerySubscriber(obj, fields);

        this.setModifyBlock((q) => q);
    }

    setModifyBlock(modify: (query: PFQuery) => PFQuery) {
        let block = (query: PFQuery) => {
            query.includes(this.fields);
            return modify(query);
        }
        this.loader.setQueryModificationBlock(block);
        this.subscriber.liveQueryModifier = new LiveQueryModifier(block, null);
    }

    async reloadData() {
        this.loader.filterDeleted = this.options.filterDeleted;
        this.loader.delayBeforeFinish = this.options.delayBeforeFinish;

        this.commitChanges([])
        this.subscriber.unsubscribe();
        let items = await this.loader.loadAll();
        this.commitChanges(items)
        this.subscriber.subscribe((oldObject, newObject) => {
            if (PFObject.compareFn(oldObject, newObject)) {
                this.refresh();
            } else {
                let items = this.items.getValue();
                if (oldObject != null) {
                    items = this.removeObject(items, oldObject);
                }
                if (newObject != null) {
                    items = this.addObject(items, newObject);
                }
                this.commitChanges(items);
            }
        });
    }

    isLoading() {
        return this.loader.isLoading();
    }

    private refresh() {
        this.commitChanges(this.items.getValue());
    }

    private removeObject(fromItems: T[], item: T) {
        let index = fromItems.findIndex(o => {
            return PFObject.compareFn(o, item);
        });
        if (index != -1) {
            fromItems.splice(index, 1);
        }
        return fromItems;
    }

    private addObject(toItems: T[], item: T) {
        let index = toItems.findIndex(o => {
            return PFObject.compareFn(o, item);
        });
        if (index == -1) {
            toItems.unshift(item);
        }
        return toItems;
    }

    private commitChanges(items: T[]) {
        this.items.next(items);
    }
}