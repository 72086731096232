import { Component, ViewChild } from '@angular/core';

import { OrdersInProgressListTableData } from './orders-inprogress-tabledata';
import { DispatcherOrderWriteAccess } from 'app/components/access/dispatcher-order-write';

import { TableComponent } from 'app/components/table/table.component';

import { OrdersDialogPresenterService } from 'app/main/orders/dialog/ordersdialogspresenter/orders-dialog-presenter.service';

import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'app/components/locale/locale.service';
import { inprogress, orders_row_operations } from '../../locale/locale';
import { default_row_operations, orders, tripStage, common, units } from 'app/main/locale/locale';
import { TableOperationsController } from 'app/components/table-operations-controller/table-operations-controller';
import { OrdersInProgressTableOperationsFactory } from './orders-inprogress.operation-factory';
const locales = [
  inprogress,
  orders,
  common,
  default_row_operations,
  orders_row_operations,
  tripStage,
  units,
];

@Component({
  selector: 'orders-in-progress',
  templateUrl: './inprogress.component.html',
  styleUrls: ['./inprogress.component.scss']
})
export class OrdersInProgressComponent implements DispatcherOrderWriteAccess.TableOperationUpdating {
  tableData: OrdersInProgressListTableData;
  operationsController: TableOperationsController;
  headerButtons = [];
  @ViewChild(TableComponent) private table: TableComponent;

  constructor(
    private localeService: LocaleService,
    private translate: TranslateService,
    private dialogPresenter: OrdersDialogPresenterService
  ) {
    this.localeService.loadLocales(locales);

    this.tableData = new OrdersInProgressListTableData(this.translate);
    let operationsFactory = new OrdersInProgressTableOperationsFactory(
      this.dialogPresenter
    );
    this.operationsController = new TableOperationsController(operationsFactory);
    this.headerButtons = this.makeHeaderButtons();
  }

  ngAfterViewInit() {
    DispatcherOrderWriteAccess.checkTableOperations(this);
  }

  search(searchString: string) {
    this.table.search(searchString);
  }

  updateOperations(): void {
    this.operationsController.updateOperations(this.table)
  }

  private reloadData() {
    this.table.reloadData();
    DispatcherOrderWriteAccess.reloadTableOperations(this);
  }

  private filtersReset() {
    this.table.filtersReset();
  }


  private makeHeaderButtons() {
    return [
      {
        'title': 'COMMON.BUTTONS.RELOAD',
        'action': () => this.reloadData()
      },
      {
        'title': 'COMMON.BUTTONS.RESET_FILTERS',
        'action': () => this.filtersReset()
      }
    ];
  }
}
