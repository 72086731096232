import { NgModule } from '@angular/core';

import { RemoveUserDialogComponent } from './remove-user-dialog.component'

import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/material.module';
import { DialogHeaderModule } from 'app/components/dialogs/dialog-header/dialog-header.module';

@NgModule({
    declarations: [
        RemoveUserDialogComponent,
    ],
    imports: [
        TranslateModule,
        FuseSharedModule,
        MaterialModule,
        DialogHeaderModule,
    ],
    exports: [
        RemoveUserDialogComponent,
    ]
})

export class RemoveUserDialogModule
{
}
