import { TableData, TableSort } from 'app/components/table/tabledata';

import * as AccessQueries from 'app/components/access/query';
import { DateHelper } from 'app/components/helpers/date.helper';
import { CurrentUser } from 'app/components/data/core/models/base/user';
import { QueryResultFilter } from 'app/components/access/queryResultFilter';
import { LiveQueryInclusion, LiveQueryModifier, LiveQuerySubscriber } from 'app/components/table/data_sources/live-query-subscriber';
import { ServerTableDataSource } from 'app/components/table/data_sources/server-data-source';
import { PFQuery } from 'app/components/data/core/models/base/query';
import { TranslateService } from '@ngx-translate/core';
import { ProposalColumns } from '../../helpers/proposal-columns-factory';
import { OrderQuerySearchFilter } from 'app/main/orders/helpers/order-query-search-filter';

export class ProposalsForCarrierTableData extends TableData {
  constructor(
    private translate: TranslateService,
    date: Date
  ) {
    super();

    let carrier = CurrentUser.carrier();
    let permissions = carrier.getOrderPermissions();

    let columns = [
      ProposalColumns.makeColumnTime(),
      ProposalColumns.makeColumnCount(),
      ProposalColumns.makeColumnBrand(),
      ProposalColumns.makeColumnLoadingAddress(),
      ProposalColumns.makeColumnUnloadingAddress(),
      ProposalColumns.makeColumnEquipment(this.translate),
      ProposalColumns.makeColumnComment(),
    ];

    if (permissions.reserveOrder) {
      columns.push(
        ProposalColumns.makeColumnReserve(this.translate)
      );
    }

    this.setColumns(columns);

    let includeFields = [
      'articleBrand.type',
      'customer',
      'loadingPoint',
      'unloadingPoint',
      'carriers',
    ];

    let sort: TableSort = new TableSort('number', 'desc');
    let tableDataSource = new ServerTableDataSource('Order', includeFields);
    tableDataSource.setDefaultSort(sort);
    tableDataSource.queryFilter = (filter, query) => {
      let customerNameFilter = OrderQuerySearchFilter.customerName(filter);
      let unloadingEntranceFilter = OrderQuerySearchFilter.unloadingEntranceAddress(filter);
      let brandFilter = OrderQuerySearchFilter.brandName(filter);
      let loadingPointAddresFilter = OrderQuerySearchFilter.loadingPointAddress(filter);

      let orQuery = PFQuery.or(
        customerNameFilter,
        unloadingEntranceFilter,
        brandFilter,
        loadingPointAddresFilter
      );

      let number = parseFloat(filter);
      if (!isNaN(number)) {
        let numberFilter = OrderQuerySearchFilter.orderNumber(number);
        orQuery = PFQuery.or(orQuery, numberFilter);
      }
      return orQuery;
    };

    let startDate = DateHelper.setDayBegin(new Date(date));
    let endDate = DateHelper.setDayEnd(new Date(date));
    function applyQueryModification(query: PFQuery) {
      query = AccessQueries.containsCurrentCarrier(query);

      if (permissions.reserveOrder) {
        let orderQuery = new PFQuery('Order');
        orderQuery.doesNotExist('carriers');
        query = PFQuery.or(query, orderQuery);
      }

      query.greaterThanOrEqualTo("unloadingBeginDate", startDate);
      query.lessThanOrEqualTo("unloadingBeginDate", endDate);
      query.greaterThan('undistributedTonnage', 0);
      query.exists('customer');
      query = AccessQueries.filterDeleted(query);
      return query;
    }

    tableDataSource.queryModify = function (query: PFQuery): PFQuery {
      return applyQueryModification(query);
    };

    let subscriber = new LiveQuerySubscriber('Order', includeFields);

    subscriber.liveQueryModifier = new LiveQueryModifier(
      function (query) {
        return applyQueryModification(query);
      }, (order) => {
        return order;
      }
    );

    let filter = function (object) {
      if (CurrentUser.isDispatcher()) {
        if (!QueryResultFilter.transportUnitMatchesCurrentCarrier(object.transportUnit())) { return null; }
      }
      return object;
    };

    subscriber.liveQueryInclusions = [
      new LiveQueryInclusion("Offer", new LiveQueryModifier(null, filter)),
      new LiveQueryInclusion("Trip", new LiveQueryModifier(null, filter), ["historyRecords"]),
    ];

    tableDataSource.setLiveQuerySubscriber(subscriber);
    this.setProvider(tableDataSource);
  }

  canSortColumn(column) {
    return false;
  }
}
