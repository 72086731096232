import { Injectable } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})

export class LocaleService {

  constructor(
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    public translateService: TranslateService,
  ) {
    // Add languages
    this.translateService.addLangs(['ru']);

    // Set the default language
    this.translateService.setDefaultLang('ru');

    // Use a language
    this.translateService.use(this.translateService.defaultLang);
  }

  loadLocale(localePack) {
    localePack.forEach(locale => this.fuseTranslationLoaderService.loadTranslations(locale));
  }

  loadLocales(localePacks) {
    localePacks.forEach(localePack => this.loadLocale(localePack));
  }
}
