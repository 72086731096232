
import { FuseNavigation } from '@fuse/types';

export const map: FuseNavigation = {
  id       : 'autoOnMap',
  title    : 'On map',
  translate: 'NAV.AUTO.ONMAP',
  type     : 'item',
  icon     : 'location_on',
  url      : '/auto/onmap',
};

export const list: FuseNavigation = {
  id       : 'listAuto',
  title    : 'List',
  translate: 'NAV.AUTO.TRANSPORTUNITS',
  type     : 'item',
  icon     : 'people',
  url      : '/auto/list',
};
