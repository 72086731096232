<div class="home-container" 
     fxFlex 
     fxLayout="column" 
     fxLayoutAlign="space-between stretch">
    <div class="top-bar" fxLayoutAlign="end center">
        <button mat-raised-button 
                class="w-100 ml-16 my-8"
                color="accent" 
                (click)="enter()">{{'HOME.BUTTON.ENTER' | translate}}
        </button>
    </div>
    <div fxFlex fxLayoutAlign="center center">
        <div class="content-box"
             fxLayout="column" 
             fxLayoutAlign="space-between center">

            <div class="logo">
                <img src="assets/images/logos/cargodeal.svg">
            </div>
            <div class="title-text">
                {{'HOME.TITLE' | translate}}
            </div>
            <div class="description-text">
                {{'HOME.DESCRIPTION' | translate}}
            </div>

            <div>
                <div class="download-title p-16">
                    {{'HOME.DOWNLOAD' | translate}}
                </div>
                <div class="mx-16"
                     fxLayout="row" 
                     fxLayoutAlign="space-between center"
                     fxLayout.xs="column" 
                     fxLayoutAlign.xs="center center">
                    <button mat-button
                            class="app-store-button m-4"
                            color="accent" 
                            (click)="goToAppStore()">
                    </button>
                    <button mat-button
                            class="google-play-button m-4"
                            color="accent" 
                            (click)="goToPlayMarket()">
                    </button>
                </div>
            </div>
    </div>
</div>