import {locale as step_order_en} from "./en/locale.step-order-create";
import {locale as step_order_ru} from "./ru/locale.step-order-create";

export const step_order_create_dialog = [
  {
    lang: 'en',
    data: step_order_en
  },
  {
    lang: 'ru',
    data: step_order_ru
  },
];

