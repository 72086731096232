<div *ngIf="operations.length > 0"
     class="table-actions m-8">
    <ng-container *ngFor="let operation of operations">
        <button mat-button
                (click)="operation.action();" 
                [disabled]="operation.isDisabled()">
            <mat-icon *ngIf="operation.icon" 
                    class="mr-8 s-18">
                    {{operation.icon}}
            </mat-icon>
            {{operation.title | translate}}
        </button>
    </ng-container>
</div>