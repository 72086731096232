import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocaleService } from 'app/components/locale/locale.service';
import { no_procuratory } from 'app/main/orders/locale/locale';

@Component({
    selector   : 'no_procuratory_dialog',
    templateUrl: './no_procuratory_dialog.component.html',
    styleUrls  : ['./no_procuratory_dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class NoProcuratoryDialogComponent {
  constructor (
    public localeService: LocaleService,
    public dialogRef: MatDialogRef<NoProcuratoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
  ) {
    this.localeService.loadLocale(no_procuratory);
  }
}
