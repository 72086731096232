export const locale = {
    'CUSTOMER_ORDER': {
        'DIALOG': {
            'CANCEL': {
                'TITLE': 'Cancel order',
                'MESSAGE': 'Are you sure you want to cancel this orders?',
                'CONFIRM': 'Yes, cancel',
                'ORDERNUMBER': 'Order #',
                'ERROR': {
                    'UNDEFINED': 'Failed  to cancel  order. Try again later or contact your manager.',
                    'INVALID_STAGE': "You cant't cancel order on this stage",
                },
                'TONNAGE': '{{value}} tonns',
            },
        },
    },
};
