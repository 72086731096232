import { TableData, TableRowData, TableSort } from 'app/components/table/tabledata';

import { Driver } from 'app/components/data/core/models/persons/driver';
import { TranslateService } from '@ngx-translate/core';
import { CurrentUser } from 'app/components/data/core/models/base/user';
import { PFQuery } from 'app/components/data/core/models/base/query';

import * as AccessQueries from 'app/components/access/query';
import { ServerTableDataSource } from 'app/components/table/data_sources/server-data-source';

export class DriversTableData extends TableData {

  constructor(
    private translate: TranslateService
  ) {
    super();

    let handler = this;
    let columns = [
      new TableRowData({
        key: 'name',
        header: 'USERS.TABLEROW.FULLNAME',
        cell: (driver: Driver) => `${ driver.getName() }`
      }),      
      new TableRowData({
        key: 'phone',
        header: 'USERS.TABLEROW.PHONE',
        cell: (driver: Driver) => `${ driver.phoneNumber() ? driver.phoneNumber() : ''}`
      }),
      new TableRowData({
        key: 'licence',
        header: 'USERS.TABLEROW.LICENSE',
        cell: (driver: Driver) => `${ driver.getLicense() ?  driver.getLicense() : ''}`
      }),
      new TableRowData({
        key: 'credentials',
        header: 'USERS.TABLEROW.LOGIN',
        cell: (driver: Driver) => `${ driver.getUser() ? driver.getUser().getUsername() : ''}`
      }),
      new TableRowData({
        key: 'carrier',
        header: 'USERS.TABLEROW.CARRIER',
        cell: (driver: Driver) => `${ driver.getCarrier() ? driver.getCarrier().getName() : ''}`
      }),
      new TableRowData({
        key: 'approved',
        header: 'USERS.TABS.DRIVERS.TABLEROW.APPROVE_STATUS',
        cell: (driver: Driver) => `${ handler.approvalStatusFor(driver) }`, 
        cellClass: (driver: Driver) => handler.getCellState(driver)
      }),

    ];
    this.setColumns(columns);

    let sort: TableSort = new TableSort('name','asc');
    let tableDataSource = new ServerTableDataSource('Driver', ['user', 'carrier']);
    tableDataSource.setDefaultSort(sort);
    tableDataSource.queryFilter = (filter, defaultQuery) => {
      defaultQuery.contains('name', filter);

      let licenseQuery = new PFQuery('Driver');
      licenseQuery.contains('license', filter);

      let carrierQuery = new PFQuery('Carrier');
      carrierQuery.contains('name', filter);
      let driverCarrierQuery = new PFQuery('Driver');
      driverCarrierQuery.exists('carrier');
      driverCarrierQuery.matchesQuery('carrier', carrierQuery);

      let usernameQuery = new PFQuery('User');
      usernameQuery.contains('username', filter);
      let driverUsernameQuery = new PFQuery('Driver');
      driverUsernameQuery.exists('user');
      driverUsernameQuery.matchesQuery('user', usernameQuery);

      return PFQuery.or(defaultQuery, licenseQuery, driverCarrierQuery, driverUsernameQuery);
    };
    tableDataSource.queryModify = function (query: PFQuery) : PFQuery {
      if (CurrentUser.isDispatcher()) {
        AccessQueries.matchesCurrentCarrier(query);
      }
      query.ascending('approved');
      return AccessQueries.filterDeleted(query);
    };
    this.setProvider(tableDataSource);
  }

  canSortColumn(column) {
    if (column.key === 'credentials') {
      return false;
    }
    return super.canSortColumn(column);
  }

  getCellState(driver: Driver): string {
    if (!driver.getApproved()) {
      return 'warn-fg';
    }
    return null;
  }

  approvalStatusFor(driver: Driver) {
    let status = driver.getApproved() ? 'APPROVAL.STATUS.APPROVED' : 'APPROVAL.STATUS.NOT_APPROVED';
    return this.translate.instant(status);
  }
}
