
export const locale = {
  lang: "ru",
  data: {
    "TrackDownload": {
      "Title": "Скачать трек",
      "ProgressText": "Трек создается...",
      "LinkText": "Трек",
      "GPX": {
        "Name": "Трек заказа №{{orderNumber}}",
        "Description": "Поездка водителя: {{driverName}}",
      },
    }
  }
};
