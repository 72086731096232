import { PFQuery } from 'app/components/data/core/models/base/query';
import { TripStage } from 'app/components/data/core/models/trip/trip';
export namespace OrderQuerySearchFilter {
    export function customerName(name) {
        let orderQuery = new PFQuery('Order');
        let customerQuery = new PFQuery('Customer');
        customerQuery.contains('name', name);
        orderQuery.exists('customer');
        orderQuery.matchesQuery('customer', customerQuery);
        return orderQuery;
    }

    export function unloadingEntranceAddress(address) {
        let orderQuery = new PFQuery('Order');
        let unloadPoint = new PFQuery('Entrance');
        unloadPoint.contains('address', address);
        orderQuery.exists('unloadingEntrance');
        orderQuery.matchesQuery('unloadingEntrance', unloadPoint);
        return orderQuery;
    }

    export function loadingPointAddress(address) {
        let orderQuery = new PFQuery('Order');
        let loadingPoint = new PFQuery('LoadingPoint');
        loadingPoint.contains('address', address);
        orderQuery.exists('loadingPoint');
        orderQuery.matchesQuery('loadingPoint', loadingPoint);
        return orderQuery;
    }

    export function orderNumber(number) {
        let orderQuery = new PFQuery('Order');
        orderQuery.equalTo("number", number);
        return orderQuery;
    }

    export function driverName(name) {
        let orderQuery = new PFQuery('Order');
        let driverQuery = new PFQuery('Driver');
        driverQuery.contains('name', name);

        let tuQuery = new PFQuery('TransportUnit');
        tuQuery.matchesQuery('driver', driverQuery);

        let tripQuery = new PFQuery('Trip');
        tripQuery.matchesQuery('transportUnit', tuQuery);

        let offersQuery = new PFQuery('Offer');
        offersQuery.matchesQuery('trip', tripQuery);

        orderQuery.matchesQuery('offers', offersQuery);
        return orderQuery;
    }

    export function supplierName(name) {
        let orderQuery = new PFQuery('Order');
        let supplierQuery = new PFQuery('Supplier');
        supplierQuery.contains('name', name);
        orderQuery.exists('supplier');
        orderQuery.matchesQuery('supplier', supplierQuery);
        return orderQuery;
    }

    export function facticalTonnage(tonnage) {
        let orderQuery = new PFQuery('Order');
        let recordQuery = new PFQuery('TripHistoryRecord');
        recordQuery.equalTo('stage', TripStage.Unloaded)
        recordQuery.equalTo('tonnage', tonnage);

        let tripQuery = new PFQuery('Trip');
        tripQuery.matchesQuery('historyRecords', recordQuery);

        let offersQuery = new PFQuery('Offer');
        offersQuery.matchesQuery('trip', tripQuery);

        orderQuery.matchesQuery('offers', offersQuery);
        return orderQuery;
    }

    export function expectedTonnage(tonnage) {
        let orderQuery = new PFQuery('Order');
        orderQuery.equalTo('tonnage', tonnage);
        return orderQuery;
    }

    export function brandName(name) {
        let orderQuery = new PFQuery('Order');
        let brandQuery = new PFQuery('ArticleBrand');
        brandQuery.contains('name', name);
        orderQuery.exists('articleBrand');
        orderQuery.matchesQuery('articleBrand', brandQuery);
        return orderQuery;
    }
}