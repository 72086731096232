
import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'app/main/material.module';
import { AlertComponent } from './alert.component';
import { FuseSharedModule } from '@fuse/shared.module';

@NgModule({
    declarations: [
        AlertComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        MaterialModule,
        FuseSharedModule,
    ]
})
export class AlertModule
{
}
