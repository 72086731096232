import { DialogPresenterService, DialogData } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import { CustomerEditEntrancesDialogComponent } from 'app/main/admin/users/dialogs/customer/edit-entrances/customer-edit-entrances-dialog.component';
import { CustomerCreateDialog } from 'app/main/admin/users/dialogs/customer/create/customer-create-dialog.component';
import { CustomerCheckDialog } from 'app/main/admin/users/dialogs/customer/check/customer-check-dialog.component';
import { CustomerEditAddressDialogComponent } from 'app/main/admin/users/dialogs/customer/edit-address/customer-edit-address-dialog.component';
import { TableOperationActionType, TableOperationsHelper } from 'app/components/helpers/table-operations.helper';
import { TableOperationsFactory } from 'app/components/table-operations-controller/table-operations-controller';
import { ActionBarOperation } from 'app/components/action-bar/action-bar.component';
import { UsersTableHelper } from '../helpers/users-table-operations.helper';

import { UnloadingPointContactsDialog } from 'app/main/admin/contacts/manage/unloading-point-contacts-dialog.component';

import { CurrentUser, Role } from 'app/components/data/core/models/base/user';
import { CustomerPermissionsDialog } from '../dialogs/customer/permissions/customer-permissions-dialog.component';

export class CustomersTableOperationsFactory implements TableOperationsFactory {

    constructor(
        private dialogPresenter: DialogPresenterService,
        private completion
    ) { }

    makeOperations(selected) {
        return this.makeOperationsWithSelected(selected);
    }

    makeDefaultOperation(selected) {
        return TableOperationsHelper.makeOperationWithType(
            TableOperationActionType.Edit,
            this.dialogPresenter,
            () => this.makeDialogData(),
            selected);
    }

    private makeOperationsWithSelected(selected) {
        let types = [
            TableOperationActionType.Add,
            TableOperationActionType.Edit,
        ];
        let operations = TableOperationsHelper.makeOperationsWithTypes(
            types,
            this.dialogPresenter,
            () => this.makeDialogData(),
            selected,
            this.completion
        );
        
        operations.push(
            this.makeCheckOperation(selected)
        )
        if (CurrentUser.isLogistician() || CurrentUser.isAdministrator() || CurrentUser.isManager()) {
            operations.push(
                UsersTableHelper.makeCreateUserCredentialsOperation(
                    this.dialogPresenter,
                    selected,
                    Role.Customer
                )
            );
        }
        
        operations.push(
            this.makeEditUnloadingPointsOperation(selected)
        )
        operations.push(
            this.makeEditUnloadingPointsEntrancesOperation(selected)
        )
        operations.push(
            this.makeEditUnloadingPointsContactsOperation(selected)
        )
        operations.push(
            this.makePermissionsOperation(selected)
        )
        operations.push(
            TableOperationsHelper.makeOperationWithType(
                TableOperationActionType.Remove, 
                this.dialogPresenter, 
                () => UsersTableHelper.makeRemoveUserDialodData(Role.Customer),
                selected, 
                this.completion
            )
        )
        return operations;
    }

    private makeDialogData(): DialogData {
        return {
            dialog: CustomerCreateDialog,
            panel: 'customer-create-dialog-container',
            data: {}
        }
    }

    private makeCheckOperation(selected) {
        let dialogData: DialogData = {
            dialog: CustomerCheckDialog,
            panel: 'customer-check-dialog-container',
            data: { object: selected[0], disableClose: false }
        };
        return new ActionBarOperation({
            title: 'USERS.TABS.CUSTOMERS.ROWOPERATION.CHECK',
            icon: 'check_circle',
            action: () => { this.dialogPresenter.showDialog(dialogData) },
            disabled: TableOperationsHelper.notSingleSelection(selected)
        });
    }

    private makeEditUnloadingPointsOperation(selected) {
        let dialogData: DialogData = {
            dialog: CustomerEditAddressDialogComponent,
            panel: 'customer-edit-address-dialog-container',
            data: { object: selected[0], disableClose: false }
        };
        return new ActionBarOperation({
            title: 'USERS.ROWOPERATION.UNLOADING_POINTS',
            icon: 'edit_location',
            action: () => { this.dialogPresenter.showDialog(dialogData) },
            disabled: TableOperationsHelper.notSingleSelection(selected)
        });
    }

    private makeEditUnloadingPointsEntrancesOperation(selected) {
        let dialogData: DialogData = {
            dialog: CustomerEditEntrancesDialogComponent,
            panel: 'customer-edit-entrances-dialog-container',
            data: { object: selected[0], disableClose: false }
        };
        return new ActionBarOperation({
            title: 'USERS.ROWOPERATION.UNLOADING_ENTRANCES',
            icon: 'edit_location',
            action: () => { this.dialogPresenter.showDialog(dialogData) },
            disabled: TableOperationsHelper.notSingleSelection(selected)
        });
    }

    private makeEditUnloadingPointsContactsOperation(selected) {
        let dialogData: DialogData = {
            dialog: UnloadingPointContactsDialog,
            panel: 'unloading-point-contacts-dialog-container',
            data: { object: selected[0], disableClose: false }
        };
        return new ActionBarOperation({
            title: 'USERS.ROWOPERATION.CONTACTS',
            icon: 'contacts',
            action: () => { this.dialogPresenter.showDialog(dialogData) },
            disabled: TableOperationsHelper.notSingleSelection(selected)
        });
    }

    private makePermissionsOperation(selected) {
        let dialogData: DialogData = {
            dialog: CustomerPermissionsDialog,
            panel: 'customer-permissions-dialog-container',
            data: { object: selected[0], disableClose: false }
        };
        return new ActionBarOperation({
            title: 'USERS.TABS.CUSTOMERS.ROWOPERATION.PERMISSIONS',
            icon: 'check_circle',
            action: () => { this.dialogPresenter.showDialog(dialogData) },
            disabled: TableOperationsHelper.notSingleSelection(selected)
        });
    }
}