
export const locale = {
  "TRIP": {
    "STAGE": {
      "None": "Order accepted",
      "0": "Going to loading point",
      "1": "Arrived to loading point",
      "2": "Loaded",
      "3": "Going to unloading point",
      "4": "Arrived to unloading point",
      "5": "Unloaded",
    },
  },
};
