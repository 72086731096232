
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { MaterialModule } from 'app/main/material.module';

import { DialogHeaderModule } from 'app/components/dialogs/dialog-header/dialog-header.module';
import { CreateKilometersDialog } from './create/create-kilometers-dialog.component';
import { EditKilometersDialog } from './edit/edit-kilometers-dialog.component';
import {AutocompleteWidgetModule} from "../../../../components/autocomplete-widget/autocomplete-widget.module";

@NgModule({
    declarations: [
        CreateKilometersDialog,
        EditKilometersDialog,
    ],
    imports: [
        TranslateModule,
        FuseSharedModule,
        MaterialModule,
        DialogHeaderModule,
        AutocompleteWidgetModule,
    ],
    exports: [
        CreateKilometersDialog,
        EditKilometersDialog,
    ]
})
export class KilometersDialogsModule {}
