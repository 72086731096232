import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/material.module';
import { TrackDownloadDialog } from 'app/components/track-download/track-download.dialog';

import { DialogHeaderModule } from 'app/components/dialogs/dialog-header/dialog-header.module';

import { CustomerOrderInfoModule } from './orderinfo/customer-order-info-dialog.module';
import { CancelCustomerOrderDialogModule } from './cancel/cancel-customer-order-dialog.module';
import { CustomerOrderDialogModule } from './create/customer-order-dialog.module';

@NgModule({
    providers: [
      TrackDownloadDialog
    ],
    imports     : [
      TranslateModule,
      FuseSharedModule,
      MaterialModule,
      DialogHeaderModule,
      CustomerOrderInfoModule,
      CancelCustomerOrderDialogModule,
      CustomerOrderDialogModule,
    ],
})

export class CustomerOrderDialogsModule {}
