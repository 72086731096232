
import { TableData, TableRowData, RowParameters } from 'app/components/table/tabledata';

import { TranslateService } from '@ngx-translate/core';
import { ServerTableDataSource } from 'app/components/table/data_sources/server-data-source';
import { LiveQuerySubscriber } from 'app/components/table/data_sources/live-query-subscriber';
import { ExportChangeFlag, ExportError } from 'app/components/data/core/models/export-change-flag';

export class QueueTableData extends TableData {

  constructor(
    private translate: TranslateService
  ) {
    super();
    let handler = this;

    let columns = [
      new TableRowData({
        key: 'type', 
        header: 'EXPORT_QUEUE.TABLEROW.TYPE', 
        cell: (flag: ExportChangeFlag) => `${handler.localizedType(flag)}`, 
        params: new RowParameters('15')
      }),
      new TableRowData({
        key: 'description', 
        header: 'EXPORT_QUEUE.TABLEROW.DESCRIPTION', 
        cell: (flag: ExportChangeFlag) => `${handler.localizedDescription(flag)}`, 
        params: new RowParameters('15')
      }),
      new TableRowData({
        key: 'updatedAt', 
        header: 'EXPORT_QUEUE.TABLEROW.DATE', 
        cell: (flag: ExportChangeFlag) => `${handler.dateStr(flag)}`, 
        params: new RowParameters('15')
      }),
      new TableRowData({
        key: 'reason', 
        header: 'EXPORT_QUEUE.TABLEROW.REASON', 
        cell: (flag: ExportChangeFlag) => `${handler.localizedReason(flag)}`
      }),
    ];

    this.setColumns(columns);

    let tableDataSource = new ServerTableDataSource('ExportChangeFlag');
    this.setProvider(tableDataSource);
  }

  canSortColumn(column) {
    if (column.key === 'updatedAt' || column.key === 'type') return true;
    return false;
  }

  private localizedType(flag: ExportChangeFlag) {
    return this.localizedClassName(flag.getChangedClassName());
  }

  private localizedDescription(flag: ExportChangeFlag) {
    let additionalData = flag.getAdditionalData();
    var description;
    if (additionalData) {
        switch (flag.getChangedClassName()) {
            case "Order": description = additionalData.number; break;
            case "Driver": description = additionalData.number; break;
            case "Vehicle": description = additionalData.number; break;
            case "Trailer": description = additionalData.number; break;
            case "UnloadingPoint": description = additionalData.address; break;
            case "Customer": description = additionalData.name; break;
            default: break;
        }
    }
    if (!description) {
        description = this.translate.instant('EXPORT_QUEUE.NO_DESCRIPTION');
    }
    return description;
  }

  private localizedClassName(name) {
    switch (name) {
      case "Order":
      case "Driver":
      case "Vehicle":
      case "Trailer":
      case "UnloadingPoint":
      case "Customer":
      case "Manager":
        name = this.translate.instant('EXPORT_QUEUE.CLASS_NAME.' + name); break;
      default: break;
    }
    return name
  }

  private localizedErrorName(name) {
    switch (name) {
      case 'InvalidReponse':
      case 'ExternalAPIError': 
      case 'FileDownloadFailed':
      case 'NotSavedOnExternal': 
      case 'UNDEFINED': name = this.translate.instant(('EXPORT_QUEUE.ERROR.' + name)); break;
      default: break;
    }
    return name;
  }

  private errorDescriptionForError(error: ExportError) {
    let errorDescription;
    if (error.externalMessage) {
      errorDescription = error.externalMessage;
    }
    if (error.data) {
      if (errorDescription) {
        errorDescription += "\n";
      }
      let name = this.localizedClassName(error.data.className);
      errorDescription = this.translate.instant('EXPORT_QUEUE.ERROR.NOT_SAVED_DESCRIPTION', { value: name });
    }
    return errorDescription;
  }

  private localizedReason(flag: ExportChangeFlag) {
    let error = flag.getReason();
    var reason;
    if (error) {
        reason = this.localizedErrorName(error.name);
        let errorDescription = this.errorDescriptionForError(error);
        if (errorDescription) {
            reason += "\n";
            reason += errorDescription;
        }
    } else {
        reason = this.localizedErrorName('UNDEFINED');
    }
    return reason;
  }

  private dateStr(flag: ExportChangeFlag) {
    let date = flag.getDate();
    return date ? date.toLocaleString() : '';
  }
}
