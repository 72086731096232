import { Component, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { PFObject } from 'app/components/data/core/models/base/object';

import { Driver } from 'app/components/data/core/models/persons/driver';

import * as FormHelper from 'app/components/form-helper/form-helper';

import { LocaleService } from 'app/components/locale/locale.service';
import { create_driver } from 'app/main/admin/users/locale/locale';

import { AttachmentComponent } from 'app/components/attachment/attachment.component';
import { CloudExecutor } from 'app/components/data/cloud-executors/cloud-executor';
import { CurrentUser } from 'app/components/data/core/models/base/user';
import { DataLoader } from 'app/components/data/data-loader';

@Component({
    selector   : 'driver-dialog',
    templateUrl: './driver-dialog.component.html',
    styleUrls  : ['./driver-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class DriverDialog {
  compareFunction = PFObject.compareFn;
  
  form: UntypedFormGroup;
  private object: Driver;

  title: string;
  confirmButton: string;

  carriersLoader: DataLoader;
  dataSaver = new CloudExecutor;

  attachments = [];
  private attachmentsToRemove = [];
  @ViewChild('attachment') attachment: AttachmentComponent;

  constructor (
    public dialogRef: MatDialogRef<DriverDialog>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _formBuilder: UntypedFormBuilder,
    private localeService: LocaleService,
  ) {
    this.carriersLoader = new DataLoader('Carrier');
    this.localeService.loadLocale(create_driver);

    if (this._data.action === 'create') {
      this.title = 'DIALOG.DRIVER.CREATE.TITLE';
      this.confirmButton = 'DIALOG.DRIVER.CREATE.CONFIRMBUTTON';
    } else {
      this.title = 'DIALOG.DRIVER.EDIT.TITLE';
      this.confirmButton = 'DIALOG.DRIVER.EDIT.CONFIRMBUTTON';
    }

    this.object = this._data.object;
    if (!this.object) {
      this.object = new Driver();
    }
    this.form = this.createContactForm();
  }

  ngOnInit() {
    if (this._data.action === 'edit') {
      this.loadAttachments();
    }

    if (this.isCarrierVisible()) {
      this.carriersLoader.loadAll();
    }
  }

  isEditingEnabled(): boolean {
    return !this.object.getApproved();
  }

  isCarrierVisible(): boolean {
    return CurrentUser.isAdministrator() || CurrentUser.isLogistician() || CurrentUser.isManager();
  }

  private createContactForm(): UntypedFormGroup {
    return this._formBuilder.group({
      name: [{value: this.object.getName(), disabled:!this.isEditingEnabled()}],
      phoneNumber: this.object.phoneNumber(),
      showTariff: this.object.getShowTariff(),
      carrier: [{value: this.object.getCarrier(), disabled:!this.isEditingEnabled()}],
    });
  }

  checkAndSave() {
    if (this.checkForm()) {
      this.object.setName(this.form.controls.name.value);
      this.object.setPhoneNumber(this.form.controls.phoneNumber.value)
      this.object.setShowTariff(this.form.controls.showTariff.value);
      if (this.isCarrierVisible()) {
        this.object.setCarrier(this.form.controls.carrier.value);
      }
      let driver = this.object;
      if (this._data.action === 'create') {
        if (CurrentUser.isDispatcher()) {
          this.object.setCarrier(CurrentUser.carrier());
        }
        this.uploadAttachments().then((attachedFiles) => {
          this.addAttachments(driver, attachedFiles);
          return this.dataSaver.saveObject(driver);
        }).then((drv) => {
          this.close(drv);
        });
      } else {
        this.removeAttachments(this.object, this.attachmentsToRemove);
        this.uploadAttachments().then((attachedFiles) => {
          this.addAttachments(driver, attachedFiles);
          return this.dataSaver.saveObject(driver);
        }).then((drv) => {
          this.close(drv);
        });
      }
    }
  }

  uploadAttachments() {
    return this.attachment.uploadAttachments();
  }

  addAttachments(driver, attachments) {
    if (attachments) {
      attachments.forEach( obj => {
        driver.attachDocument(obj);
      });
    }
  }

  removeAttachments(driver, attachments) {
    if (attachments.length > 0) {
      attachments.forEach( obj => {
        driver.removeDocument(obj.file);
      });
    }
  }

  close(driver) {
    this.dialogRef.close(driver);
  }

  onAttachmentRemove(attachment) {
    if (!(attachment.file instanceof File)) {
      this.attachmentsToRemove.push(attachment);
    }
  }

  private loadAttachments() {
    let attachedFiles = [];

    let files = this.object.getAttachedDocuments();
    if (!files) {
      attachedFiles = [];
    } else {
      files.forEach(file => {
        let name = file.name();
        name = name.substr(name.indexOf('_')+1);
        let doc = {
          name: name,
          file: file,
          content: file.url(),
        }
        attachedFiles.push(doc);
      });
    }
    this.attachments = attachedFiles;
  }

  private checkForm() : boolean {
    return FormHelper.checkForm(this.form);
  }
}
