import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LocaleService } from 'app/components/locale/locale.service';
import { retransfer } from 'app/main/orders/locale/locale';

@Component({
    selector   : 'consistency_dialog',
    templateUrl: './consistency_dialog.component.html',
    styleUrls  : ['./consistency_dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ConsistencyDialogComponent {
  constructor (
    public localeService: LocaleService,
    public dialogRef: MatDialogRef<ConsistencyDialogComponent>,
  ) {
    this.localeService.loadLocale(retransfer);
  }
}
