import {PFObject} from './base/object';
import {TransportUnit} from './transport/transport';
import {Trip} from './trip/trip';
import {Order} from './order/order';

export class Offer extends PFObject {
    constructor() {
        super('Offer');
    }

    transportUnit(): TransportUnit {
        return super.get('transportUnit');
    }

    trip(): Trip {
        return super.get('trip');
    }

    declined(): boolean {
        return super.get('declined');
    }

    isQueue(): boolean {
        return  super.get('queueStatus') === 'queued';
    }

    order(): Order {
        return super.get('order');
    }

    tonnage(): number {
        let tonnage = 0;
        let trip = this.trip();
        if (trip) {
            tonnage = trip.tonnage();
        }
        return tonnage;
    }

    getStatusString() {
        if (this.declined()) {
            return 'OFFER.STATUS.DECLINED';
        } else if (this.isQueue()) {
            return 'OFFER.STATUS.IN_QUEUE';
        } else {
            return 'OFFER.STATUS.NOTACCEPTED';
        }
    }

    transportName(): string {
        let transportName = '';
        let transportUnit: TransportUnit = this.transportUnit();
        if (transportUnit) {
            let vehicle = transportUnit.vehicle();
            if (vehicle) {
                transportName = vehicle.fullName();
            }
        }
        return transportName;
    }
}
