
<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="primary" 
                 title="{{title | translate}}">
  </dialog-header>

  <div class="pt-8 px-16">
    <form [formGroup]="form">
      <div>
        <mat-form-field fxFlex>
          <input matInput
                 placeholder="{{'DIALOG.SUPPLIER.NAME' | translate}}"
                 formControlName="name"
                 required
                 cdkFocusInitial>
          <mat-error *ngIf="form.get('name').hasError('required')">{{'DIALOG.SUPPLIER.ERROR.MUST_BE_FILLED' | translate}}</mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="!isTransferPoint"> 
        <mat-form-field fxFlex>
          <input matInput
                 placeholder="{{'DIALOG.SUPPLIER.ITN' | translate}}"
                 formControlName="itn">
          <mat-error *ngIf="form.get('itn').hasError('noMatchToRegEx')">{{'DIALOG.SUPPLIER.ERROR.ITN_ONLY_NUMBERS' | translate}}</mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="isCreationForm()">
        <div>
          <mat-form-field fxFlex>
            <input matInput
                   placeholder="{{'DIALOG.SUPPLIER.ADDRESS' | translate}}"
                   formControlName="address"
                   required
                   [matAutocomplete]="addressAutocomplete">
                   
            <mat-error *ngIf="form.get('address').hasError('required')">{{'DIALOG.SUPPLIER.ERROR.MUST_BE_FILLED' | translate}}</mat-error>
            <mat-autocomplete #addressAutocomplete="matAutocomplete" (optionSelected)="addressSearchResultSelected($event.option.value)">
              <mat-option *ngFor="let searchResult of addressSearchResults" [value]="searchResult">
                {{searchResult.description}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
  
        <agm-map (mapReady)="mapReady($event)"></agm-map>
      </div>

      <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button
                color="accent"
                [disabled] = "processing"
                (click)='checkAndSave()'>{{confirmButtonTitle | translate}}
        </button>
      </div>
    </form>
  </div>
</div>
