
import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as Location from 'app/components/location/coordinate';

import { PFQuery } from 'app/components/data/core/models/base/query';
import { TransportUnit } from 'app/components/data/core/models/transport/transport';
import { Trip, TripStage, TripHistoryRecord } from 'app/components/data/core/models/trip/trip';
import { Waypoint } from 'app/components/data/core/models/points/points';

import { LocaleService } from 'app/components/locale/locale.service';
import { showonmap } from '../../locale/locale';
import { tripStage } from 'app/main/locale/locale';

import simplify from "simplify-js";

@Component({
  selector: 'show-on-map-dialog',
  templateUrl: './show-on-map-dialog.component.html',
  styleUrls: ['./show-on-map-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ShowOnMapDialogComponent {

  public getZoomToShowTripOverlays() { return 11; }

  public getLoadingTripColor() { return '#2196F3'; }
  public getUnloadingTripColor() { return '#F44336'; }

  transportUnit: TransportUnit;
  trip: Trip;
  dontShowTrack = false;
  forwardTrackPoints = [];
  backwardTrackPoints = [];
  forwardMapWaypoints = [];
  backwardMapWaypoints = [];
  loading: boolean = false;
  zoom: number;
  coordinate;
  bounds = null;
  isFullScreen: boolean = false;
  title: String;

  constructor(
    public dialogRef: MatDialogRef<ShowOnMapDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private localeService: LocaleService,
  ) {
    this.localeService.loadLocales([showonmap, tripStage]);

    this.dontShowTrack = this._data.dontShowTrack ? true : false;
    this.title = this._data.title;
    if (this._data.trip) {
      this.trip = this._data.trip;
      if (this.trip.stage() !== TripStage.Unloaded) {
        this.transportUnit = this.trip.transportUnit();
      }
    }
    else {
      this.transportUnit = this._data.unit;
      this.trip = this.transportUnit.trip();
    }

    if (this.transportUnit && this.transportUnit.coordinate()) {
      this.coordinate = this.transportUnit.coordinate();
    } else if (this.trip) {
      let order = this.trip.order();
      let unloadingEntrance = order.unloadingEntrance();
      this.coordinate = unloadingEntrance.getCoordinate();
    }
  }

  ngOnInit() {
    if (this.trip && this.dontShowTrack == false) {
      this.loading = true;
      let query = new PFQuery('Waypoint');
      query.equalTo("trip", this.trip);
      query.ascending("index");
      query.findAll().then((waypoints) => {
        this.loading = false;
        this.setWaypoints(waypoints);
      });
    }
  }

  getRecordWithStage(stage: number): TripHistoryRecord {
    let records = this.trip.historyRecords();
    if (records != null) {
      for (let record of records) {
        if (record.stage() == stage) {
          return record;
        }
      }
    }
    return null;
  }

  getUnloadingRecord(): TripHistoryRecord {
    return this.getRecordWithStage(TripStage.Unloaded);
  }

  getUnloadingAddress() {
    let inUnloadPointRecord = this.getRecordWithStage(TripStage.InUnloadingPoint);
    return inUnloadPointRecord.address();
  }

  getUnloadTime(): String {
    let inUnloadPointRecord = this.getRecordWithStage(TripStage.InUnloadingPoint);
    let unloadRecord = this.getRecordWithStage(TripStage.Unloaded);
    let time = unloadRecord.date().getTime() - inUnloadPointRecord.date().getTime();
    return this.getHHMMSS(time / 1000);
  }

  getHHMMSS(timeinterval: number): String {
    let hours = Math.floor(timeinterval / 3600);
    let minutes = Math.floor((timeinterval - (hours * 3600)) / 60);
    let seconds = Math.floor(timeinterval - (hours * 3600) - (minutes * 60));

    var timeStr = '';
    timeStr += hours > 0 ? (hours > 9 ? hours : '0' + hours) : '00';
    timeStr += ':';
    timeStr += minutes > 0 ? (minutes > 9 ? minutes : '0' + minutes) : '00';
    timeStr += ':';
    timeStr += seconds > 0 ? (seconds > 9 ? seconds : '0' + seconds) : '00';

    return timeStr;
  }

  showA() {
    this.resetMap();
    setTimeout(() => {
      this.coordinate = this.getLoadingEntrance().getCoordinate();
      this.zoom = 17;
    });
  }

  showB() {
    this.resetMap();
    setTimeout(() => {
      this.coordinate = this.getUnloadingEntrance().getCoordinate();
      this.zoom = 17;
    });
  }

  showP() {
    this.resetMap();
    setTimeout(() => {
      this.coordinate = this.getUnloadingRecord().coordinate();
      this.zoom = 17;
    });
  }

  resetMap() {
    this.coordinate = {
      latitude: 0,
      longitude: 0
    };
    this.zoom = 16;
  }

  setWaypoints(waypoints: Waypoint[]) {
    if (!waypoints || waypoints.length == 0) {
      return;
    }
    this.forwardTrackPoints = [];
    this.backwardTrackPoints = [];
    this.forwardMapWaypoints = [];
    this.backwardMapWaypoints = [];

    let date = null;
    let record = this.getRecordWithStage(TripStage.Loaded);
    if (record != null) {
      date = record.date();
    }

    let fwdPoints = [];
    let bkwdPoints = [];
    let bounds = new google.maps.LatLngBounds();

    waypoints.map((wp: Waypoint) => {
      let coordinate = wp.getCoordinate();

      let p = {
        x: coordinate.longitude,
        y: coordinate.latitude,
        waypoint: wp,
      };
      bounds.extend(new google.maps.LatLng(p.y, p.x));
      if (date == null || date.getTime() > wp.getDate().getTime()) {
        fwdPoints.push(p);
      } else {
        bkwdPoints.push(p);
      }
    });

    if (fwdPoints.length > 0) {
      this.forwardTrackPoints = simplify(fwdPoints, 0.0001, true);
      this.forwardMapWaypoints = this.filterMapWaypoints(this.toMapWaypoints(this.forwardTrackPoints));
    }

    if (bkwdPoints.length > 0) {
      this.backwardTrackPoints = simplify(bkwdPoints, 0.0001, true);
      this.backwardMapWaypoints = this.filterMapWaypoints(this.toMapWaypoints(this.backwardTrackPoints));
    }

    this.bounds = bounds;
  }

  toMapWaypoints(points: any[]): any[] {
    let historyRecords = this.trip.historyRecords().sort((one, other) => one.stage() - other.stage());
    let mapWaypoints = [];

    let historyRecordIndex = 0;
    for (let point of points) {
      while (historyRecordIndex < historyRecords.length && point.waypoint.getDate() > historyRecords[historyRecordIndex].date()) {
        ++historyRecordIndex;
      }

      let stage;
      if (historyRecordIndex !== 0) {
        stage = historyRecords[historyRecordIndex - 1].stage();
      }

      mapWaypoints.push({ coordinate: point.waypoint.getCoordinate(), date: point.waypoint.getDate(), stage: stage });
    }

    return mapWaypoints;
  }

  filterMapWaypoints(mapWaypoints: any[]): any[] {
    if (mapWaypoints.length <= 2) {
      return mapWaypoints;
    }

    let filteredMapWaypoints = [];
    filteredMapWaypoints.push(mapWaypoints[0]);

    let totalDistance = 0;
    for (let counter = 0; counter < mapWaypoints.length - 1; ++counter) {
      let one = mapWaypoints[counter];
      let other = mapWaypoints[counter + 1];

      totalDistance += Location.getDistance(one.coordinate, other.coordinate);

      if (totalDistance > 200) {
        totalDistance = 0;
        filteredMapWaypoints.push(other);
      }
    }

    return filteredMapWaypoints;
  }

  toggleFullScreen() {
    this.isFullScreen = !this.isFullScreen;
  }

  getOrder() {
    return this.trip.order();
  }

  getLoadingEntrance() {
    return this.getOrder().loadingEntrance();
  }

  getUnloadingEntrance() {
    return this.getOrder().unloadingEntrance();
  }
}
