
export const locale = {
  "TransportUnitBreakage": {
    "Title": "Уведомление",
    "Message": "Водитель сменил статус на \"Сломался\".",
    "Details": {
      "Order": "Заказ №{{number}}",
      "Vehicle": "Автомобиль: {{number}}",
      "Trailer": "Прицеп: {{number}}",
    }
  },
  "OrderProblem": {
    "Title": "Уведомление",
    "Message": "Во время выполнения заказа возникла проблема",
    "Details": {
      "Driver": "Водитеть: {{name}}",
      "Problem": "Проблема: {{problem}}",
      "Order": "Заказ №{{number}}",
      "Vehicle": "Автомобиль: {{number}}",
      "Trailer": "Прицеп: {{number}}",
    }
  }
};
