export const locale = {
    'ONMAP': {
        'TITLE' : 'Units on map',
        'FILTER' : {
          'READYTOWORK' : "Only 'Ready to work'",
          'TUSTATUSPLASEHOLDER' : 'Select status',
          'TUGROUPPLASEHOLDER' : 'Select group',
        },
        'GROUP' : {
          'ALL' : 'All',
        },
        'LABEL' : {
          'STATUS' : 'Status filter',
          'GROUP' : 'TU filter',
        },
    },
};
