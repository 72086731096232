export const locale = {
  'CREATESHAPE' : {
    'TITLE' : {
      'CREATE' : 'Создать форму',
      'EDIT' : 'Изменить форму',
    },
    'CONFIRMBUTTON' : {
      'CREATE' : 'Создать',
      'SAVE' : 'Сохранить',
    },
    'NAME' : 'Название формы',
  },
};
