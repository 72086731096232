
export const locale = {
  'DIALOG' : {
    'LOADING_POINTS' : {
      'TITLE' : {
        'CREATE' : 'Create point',
        'EDIT' : 'Edit point',
      },
      'CONFIRMBUTTON' : {
        'CREATE' : 'Create',
        'SAVE' : 'Save',
      },
      'ADDRESS' : 'Address',
      'ENTRANCES' : 'Entrances',
      'TABLEROW' : {
        'NAME' : 'Name',
        'ADDRESS' : 'Address',
        'REQUIRED_EQUIPMENT': 'Equipment requirements',
      }
    },
  },
};
