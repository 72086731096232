import {locale as vehicles_en} from "./en/locale.vehicles";
import {locale as vehicles_ru} from "./ru/locale.vehicles";

import {locale as brand_en} from "./en/locale.brand";
import {locale as brand_ru} from "./ru/locale.brand";

import {locale as model_en} from "./en/locale.model";
import {locale as model_ru} from "./ru/locale.model";

import {locale as trailer_en} from "./en/locale.trailer";
import {locale as trailer_ru} from "./ru/locale.trailer";

import {locale as type_en} from "./en/locale.type";
import {locale as type_ru} from "./ru/locale.type";

import {locale as vehicle_en} from "./en/locale.vehicle";
import {locale as vehicle_ru} from "./ru/locale.vehicle";

import {locale as edit_passes_en} from "./en/locale.edit_passes";
import {locale as edit_passes_ru} from "./ru/locale.edit_passes";

export const edit_passes = [
  {
    lang: "en",
    data: edit_passes_en
  },
  {
    lang: "ru",
    data: edit_passes_ru
  }
];

export const vehicles = [
  {
    lang: "en",
    data: vehicles_en
  },
  {
    lang: "ru",
    data: vehicles_ru
  }
];

export const brand = [
  {
    lang: "en",
    data: brand_en
  },
  {
    lang: "ru",
    data: brand_ru
  }
];

export const model = [
  {
    lang: "en",
    data: model_en
  },
  {
    lang: "ru",
    data: model_ru
  }
];

export const trailer = [
  {
    lang: "en",
    data: trailer_en
  },
  {
    lang: "ru",
    data: trailer_ru
  }
];

export const type = [
  {
    lang: "en",
    data: type_en
  },
  {
    lang: "ru",
    data: type_ru
  }
];

export const vehicle = [
  {
    lang: "en",
    data: vehicle_en
  },
  {
    lang: "ru",
    data: vehicle_ru
  }
];
