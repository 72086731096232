<div class="supplier-step-wrapper">
    <form [formGroup]="form">
        <div fxLayout="column"
             fxLayoutAlign="space-between none"
             fxLayout.gt-xs="row"
             fxLayoutAlign.gt-xs="space-between center">
            <mat-form-field fxFlex="49">
                <mat-select placeholder="{{'ORDER.FIELD_NAME.LOAD_TYPE' | translate}}"
                            formControlName="loadType"
                            (selectionChange)='loadTypeChanged($event.value)'
                            required>
                    <mat-option *ngFor='let type of availableLoadTypes'
                                [value]="type">
                        {{ nameForLoadType(type) | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="49">
                <mat-select placeholder="{{'ORDER.FIELD_NAME.TYPE' | translate}}"
                            formControlName="orderType"
                            required>
                    <mat-option *ngFor='let type of availableOrderTypes'
                                [value]="type">
                        {{ nameForOrderType(type) | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxLayout="column"
             fxLayoutAlign="space-between none"
             fxLayout.gt-xs="row"
             fxLayoutAlign.gt-xs="space-between center">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
                <mat-form-field fxFlex>
                    <mat-autocomplete #auto="matAutocomplete"
                                      [displayWith]="supplierDisplay"
                                      (optionSelected)='supplierChanged($event.option.value)'>
                        <mat-option *ngIf='suppliersData.isLoading()'>
                            <mat-spinner diameter="20" color="accent"></mat-spinner>
                        </mat-option>
                        <mat-option *ngFor='let supplier of availableSuppliers'
                                    [value]="supplier"
                                    [disabled]="supplier.isDeleted()">
                            {{ supplier.getName() }}{{ supplier.isDeleted() ? ' (deleted)' : '' }}
                        </mat-option>
                    </mat-autocomplete>
                    <input #supplierInput
                           matInput
                           placeholder="{{'ORDER.FIELD_NAME.SUPPLIER' | translate}}"
                           [matAutocomplete]="auto"
                           formControlName="supplier"
                           cdkFocusInitial
                           required>
                    <mat-error *ngIf="form.get('supplier').errors?.notObject">
                        {{ 'ORDER.DIALOG.ERROR.MUST_SELECT' | translate }}
                    </mat-error>
                </mat-form-field>

                <button *ngIf="canAdd()"
                        mat-icon-button
                        (click)="addSupplier()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </div>

        <div fxLayout="column"
             fxLayoutAlign="space-between none"
             fxLayout.gt-xs="row"
             fxLayoutAlign.gt-xs="space-between center">
            <mat-form-field fxFlex="49">
                <mat-select placeholder="{{'ORDER.FIELD_NAME.LOADING_POINT' | translate}}"
                            formControlName="loadingPoint"
                            (selectionChange)='pointChanged($event.value)'
                            [compareWith]="compareFunction"
                            required>
                    <mat-option *ngFor='let point of availablePoints'
                                [value]="point"
                                [disabled]="point.isDeleted()">
                        {{ point.getAddress() }}{{ point.isDeleted() ? ' (deleted)' : '' }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="49">
                <mat-form-field fxFlex="90">
                    <mat-select placeholder="{{'ORDER.FIELD_NAME.LOADING_ENTRANCE' | translate}}"
                                formControlName="loadingEntrance"
                                [compareWith]="compareFunction"
                                required>
                        <mat-option *ngFor='let entrance of availableEntrances'
                                    [value]="entrance"
                                    [disabled]="entrance.isDeleted()">
                            {{ entrance.getName() }} ({{ entrance.getAddress() }}
                            ){{ entrance.isDeleted() ? ' (deleted)' : '' }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button *ngIf="canAdd()"
                        mat-icon-button
                        (click)="addLoadingEntrance()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </div>

        <div *ngIf="form.value.loadingEntrance">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-form-field fxFlex="66">
                    <input matInput
                           type="text"
                           [matDatepicker]="loadPicker"
                           placeholder="{{'ORDER.FIELD_NAME.LOADING_DATE' | translate}}"
                           formControlName="loadingDate"
                           required>
                    <mat-datepicker-toggle matSuffix [for]="loadPicker"></mat-datepicker-toggle>
                    <mat-datepicker #loadPicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field fxFlex="32">
                    <input matInput
                           type="number"
                           placeholder="{{'ORDER.FIELD_NAME.LOADING_TIME' | translate}}"
                           formControlName="loadingTime"
                           min="0"
                           max="23"
                           required>
                </mat-form-field>
            </div>

            <div fxLayout="column"
                 fxLayoutAlign="space-between none"
                 fxLayout.gt-xs="row"
                 fxLayoutAlign.gt-xs="space-between center">
                <div fxLayout="column"
                     fxLayoutAlign="space-between none"
                     fxLayout.gt-xs="row"
                     fxLayoutAlign.gt-xs="space-between center"
                     fxFlex="66">
                    <mat-form-field fxFlex="32">
                        <mat-select placeholder="{{'ORDER.FIELD_NAME.ARTICLE_TYPE' | translate}}"
                                    formControlName="articleType"
                                    [compareWith]="compareFunction"
                                    (selectionChange)='articleTypeChanged($event.value)'
                                    required>
                            <mat-option *ngFor='let type of availableTypes'
                                        [value]="type"
                                        [disabled]="type.isDeleted()">
                                {{ type.name() }}{{ type.isDeleted() ? ' (deleted)' : '' }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field fxFlex="32">
                        <mat-select placeholder="{{'ORDER.FIELD_NAME.ARTICLE_BRAND' | translate}}"
                                    formControlName="articleBrand"
                                    [compareWith]="compareFunction"
                                    required>
                            <mat-option *ngFor='let brand of availableBrands'
                                        [value]="brand"
                                        [disabled]="brand.isDeleted()">
                                {{ brand.name() }}{{ brand.isDeleted() ? ' (deleted)' : '' }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field fxFlex='32'>
                        <input matInput type="number"
                               placeholder="{{'ORDER.FIELD_NAME.TONNAGE' | translate}}"
                               formControlName="tonnage"
                               min="0"
                               max="99"
                               required>
                        <mat-error *ngIf="form.get('tonnage').hasError('min') || form.get('tonnage').hasError('max')">
                            {{ 'ORDER.DIALOG.ERROR.TONNAGE_LIMIT' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="32">
                    <mat-form-field fxFlex>
                        <input matInput
                               type="number"
                               placeholder="{{'ORDER.FIELD_NAME.KILOMETERS' | translate}}"
                               formControlName="distance"
                               min="0">
                        <mat-error *ngIf="form.get('distance').hasError('min')">
                            {{ 'ORDER.DIALOG.ERROR.TONNAGE_LIMIT' | translate }}
                        </mat-error>
                    </mat-form-field>

                    <button mat-icon-button
                            (click)="getDistance()">
                        <mat-icon>refresh</mat-icon>
                    </button>
                </div>

            </div>

            <div fxLayout="column"
                 fxLayoutAlign="space-between none"
                 fxLayout.gt-xs="row"
                 fxLayoutAlign.gt-xs="space-between center">
                <div *ngIf="canShowSalePrice()"
                     fxFlex='35'
                     fxLayout="row"
                     fxLayoutAlign="space-between center">
                    <mat-form-field fxFlex='49'>
                        <mat-select placeholder="{{'ORDER.FIELD_NAME.SALE_PRICE_TYPE' | translate}}"
                                    formControlName="salePriceType"
                                    required>
                            <mat-option *ngFor='let type of priceTypes'
                                        [value]="type">
                                {{ priceTypeName(type) | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex='49'>
                        <input matInput
                               class="no-spinners"
                               type="number"
                               placeholder="{{'ORDER.FIELD_NAME.SALE_PRICE' | translate}}"
                               formControlName="saleTariff"
                               min="0">
                        <mat-error *ngIf="form.get('saleTariff').hasError('min')">
                            {{ 'ORDER.DIALOG.ERROR.NOT_LESS_THEN' | translate:{'value': getMinSellPrice()} }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex
                     fxLayout="row"
                     [fxFlexOffset]='canShowSalePrice() ? 1 : 0'
                     fxLayoutAlign="space-between center">
                    <mat-form-field fxFlex='49'>
                        <mat-select placeholder="{{'ORDER.FIELD_NAME.DELIVERY_PRICE_TYPE' | translate}}"
                                    formControlName="deliveryPriceType"
                                    required>
                            <mat-option *ngFor='let type of priceTypes'
                                        [value]="type">
                                {{ priceTypeName(type) | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex='49'>
                        <input matInput
                               class="no-spinners"
                               type="number"
                               placeholder="{{'ORDER.FIELD_NAME.DELIVERY_PRICE' | translate}}"
                               formControlName="deliveryTariff"
                               min="0">
                        <mat-error *ngIf="form.get('deliveryTariff').hasError('min')">
                            {{ 'ORDER.DIALOG.ERROR.NOT_LESS_THEN' | translate:{'value': baseDeliveryPrice} }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <mat-form-field fxFlex='12' fxFlexOffset='1'>
                    <input matInput
                           class="no-spinners"
                           type="number"
                           placeholder="{{'ORDER.FIELD_NAME.TIMEOUT' | translate}}"
                           formControlName="timeout"
                           min="0"
                           required>
                </mat-form-field>
            </div>

            <div>
                <mat-form-field fxFlex>
                        <textarea matInput
                                  formControlName="comment"
                                  placeholder="{{'ORDER.FIELD_NAME.COMMENT' | translate}}">
                        </textarea>
                </mat-form-field>
            </div>

        </div>
    </form>
</div>
