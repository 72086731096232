
import { FuseNavigation } from '@fuse/types';

export const create: FuseNavigation = {
  id       : 'reports',
  title    : 'Create report',
  translate: 'NAV.REPORTS.CREATE',
  type     : 'item',
  icon     : 'rate_review',
  url      : 'reports',
};
