export const locale = {
  'KILOMETERS': {
    'TABLEROW': {
      'LOADING_POINT': 'Адрес загрузки',
      'UNLOADING_POINT': 'Адрес разгрузки',
      'DISTANCE': 'Километраж',
    },
    'TITLE': 'Километраж',
  }
};
