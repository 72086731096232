import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { TableModule } from 'app/components/table/table.module';
import { MaterialModule } from 'app/main/material.module';

import { EntranceDialogModule } from '../entrance/entrance-dialog.module';
import { SupplierCreateDialog } from './create/supplier-create-dialog.component';
import { SupplierPointsDialog } from './loading_points/edit-points/supplier-points-dialog.component';
import { SupplierLoadingPointDialog } from './loading_points/create/loading-point-create-dialog.component';
import { SupplierBrandPriorityDialog } from './brands/supplier-brands-dialog.component';
import { SupplierBrandAddDialog } from './brands/dialog/add/add-brands.dialog.component';

import { AgmCoreModule } from '@agm/core';
import { DialogHeaderModule } from 'app/components/dialogs/dialog-header/dialog-header.module';
import { ActionBarModule } from 'app/components/action-bar/action-bar.module';

@NgModule({
    declarations: [
        SupplierPointsDialog,
        SupplierCreateDialog,
        SupplierLoadingPointDialog,
        SupplierBrandPriorityDialog,
        SupplierBrandAddDialog,
    ],
    imports: [
        MaterialModule,
        TranslateModule,
        FuseSharedModule,
        TableModule,
        EntranceDialogModule,
        AgmCoreModule,
        DialogHeaderModule,
        ActionBarModule,
    ],
    exports: [
        SupplierPointsDialog,
        SupplierCreateDialog,
        SupplierLoadingPointDialog,
        SupplierBrandPriorityDialog,
        SupplierBrandAddDialog,
    ]
})

export class SupplierDialogModule {
}
