import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { ProposalsListForCarrierComponent } from './proposals-list-for-carrier.component';
import { MaterialModule } from 'app/main/material.module';

import { TableHeaderModule } from 'app/components/tableheader/tableheader.module';
import { TableModule } from 'app/components/table/table.module';

import { OrderInfoModule } from 'app/main/orders/dialog/orderinfo/order-info-dialog.module';
import { DeleteOrderDialogModule } from 'app/main/orders/dialog/deleteconfirmation/delete-order-dialog.module';

import { GroupTableModule } from 'app/components/group-table/group-table.module';
import { DateSelectorModule } from 'app/components/date-selector/date-selector.module';
import { ActionBarModule } from 'app/components/action-bar/action-bar.module';

@NgModule({
    declarations: [
        ProposalsListForCarrierComponent,
    ],
    imports: [
        TranslateModule,
        FuseSharedModule,
        MaterialModule,
        TableHeaderModule,
        TableModule,
        OrderInfoModule,
        DeleteOrderDialogModule,
        GroupTableModule,
        DateSelectorModule,
        ActionBarModule,
    ],
    exports: [
        ProposalsListForCarrierComponent,
    ],
})

export class ProposalsListForCarrierModule {
}
