import { NgModule } from '@angular/core';

import { OrderInfoDialogComponent } from './order-info-dialog.component'
import { InfoFormComponent } from './info-form/info-form.component'

import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/material.module';
import { DownloadModule } from 'app/components/download/download.module';
import { ShowOnMapModule } from 'app/main/auto/dialog/showonmap/show-on-map-dialog.module';
import { DialogHeaderModule } from 'app/components/dialogs/dialog-header/dialog-header.module';
import { ProgressInfoModule }  from './progress-info/progress-info.module';

@NgModule({
    declarations: [
        OrderInfoDialogComponent,
        InfoFormComponent,
    ],
    imports: [
        TranslateModule,
        FuseSharedModule,
        MaterialModule,
        DownloadModule,
        ShowOnMapModule,
        DialogHeaderModule,
        ProgressInfoModule,
    ],
    exports: [
        OrderInfoDialogComponent,
        InfoFormComponent,
    ]
})

export class OrderInfoModule {}
