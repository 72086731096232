<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="warn"
                 title="{{'REMOVEDIALOG.TITLE' | translate}}" >
  </dialog-header>
  <div class="pt-8 px-16">
    <p>{{'REMOVEDIALOG.MESSAGE' | translate}}</p>
    
    <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button
              color="warn"
              (click)="confirmDeletion()"
              cdkFocusInitial>{{'REMOVEDIALOG.BUTTON.CONFIRM' | translate}}
      </button>
    </div>
  </div>
</div>
