
<div class="dialog-content-wrapper">
  <dialog-header headerClass="primary" 
                 title="{{'DIALOG.KILOMETERS.TITLE.CREATE' | translate}}">
  </dialog-header>
  <div class="pt-8 px-16" fxFlex fxLayout="column">
    <form [formGroup]="form" fxLayout="column" fxFlex>
      <div>
        <mat-form-field fxFlex>
          <input  matInput
                  placeholder="{{'DIALOG.KILOMETERS.SUPPLIER' | translate}}"
                  [matAutocomplete]="supplierAutocomplete"
                  formControlName="supplier"
                  required>
            <mat-icon matSuffix>arrow_drop_down</mat-icon>
          <mat-autocomplete #supplierAutocomplete="matAutocomplete" 
                            [displayWith]="personDisplay"
                            (optionSelected)='updateWithSupplier($event.option.value)'>
            <mat-option *ngIf='suppliersData.isLoading()'>
              <mat-spinner diameter="20" color="accent"></mat-spinner>
            </mat-option>
            <mat-option *ngFor='let supplier of availableSuppliers | async' 
                        [value]="supplier"
                        [disabled]="supplier.isDeleted()">
                        {{supplier.getName()}}{{supplier.isDeleted() ? ' (deleted)' : ''}}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="form.get('supplier').errors?.notObject">
            {{'DIALOG.KILOMETERS.ERROR.MUST_SELECT' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

        <app-autocomplete-widget
                [placeholder]="'DIALOG.KILOMETERS.LOADING_POINT' | translate"
                [dataSource]="availableLoadingPoints"
                [displayFn]="pointDisplay"
                [control]="form.controls.loadingPoint"
                [required]="true">
        </app-autocomplete-widget>

        <div>
        <mat-form-field fxFlex>
          <input  matInput
                  placeholder="{{'DIALOG.KILOMETERS.CUSTOMER' | translate}}"
                  [matAutocomplete]="customerAutocomplete"
                  formControlName="customer"
                  required>
            <mat-icon matSuffix>arrow_drop_down</mat-icon>
          <mat-autocomplete #customerAutocomplete="matAutocomplete" 
                            [displayWith]="personDisplay"
                            (optionSelected)='updateWithCustomer($event.option.value)'>
            <mat-option *ngIf='customersData.isLoading()'>
              <mat-spinner diameter="20" color="accent"></mat-spinner>
            </mat-option>
            <mat-option *ngFor='let customer of availableCustomers | async' 
                        [value]="customer"
                        [disabled]="customer.isDeleted()">
                        {{customer.getName()}}{{customer.isDeleted() ? ' (deleted)' : ''}}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="form.get('customer').errors?.notObject">
            {{'DIALOG.KILOMETERS.ERROR.MUST_SELECT' | translate}}
          </mat-error>
        </mat-form-field>
      </div>
        <app-autocomplete-widget
                [placeholder]="'DIALOG.KILOMETERS.UNLOADING_POINT' | translate"
                [dataSource]="availableUnloadingPoints"
                [displayFn]="pointDisplay"
                [control]="form.controls.unloadingPoint"
                [required]="true">
        </app-autocomplete-widget>
        <div>
        <mat-form-field fxFlex>
          <input matInput
                 type="number"
                 min=0
                 placeholder="{{'DIALOG.KILOMETERS.DISTANCE' | translate}}"
                 formControlName="distance">                 
        </mat-form-field>
      </div>

      <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button
                color="accent"
                [disabled] = "executor.isProcessing()"
                (click)='checkAndSave()'>{{'DIALOG.KILOMETERS.CONFIRMBUTTON.CREATE' | translate}}
        </button>
      </div>

    </form>
  </div>
</div>
