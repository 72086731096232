<div class="page-layout carded fullwidth" fusePerfectScrollbar>
  <div class="top-bg primary"></div>
      <div class="center">
          <table-header headerTitle="{{'MESSAGES.TITLE' | translate}}" 
                        iconName="message" 
                        noSearchBar="true"
                        [buttons]="headerButtons">
          </table-header>
          <div class="mat-elevation-z8 fuse-white mb-16">
            <action-bar [operations]="operationsController.operations" class="sticky fuse-white">
            </action-bar>
            <cd-table [tabledata]="tableData"
                      (onSelectionChange)="updateOperations($event.table)">
            </cd-table>
          </div>
    </div>
</div>
