import { PFObject } from "../base/object";

export const enum TripStage {
  DrivingForLoading = 0,
  InLoadingPoint = 1,
  Loaded = 2,
  DrivingForUnloading = 3,
  InUnloadingPoint = 4,
  Unloaded = 5
}

export class Trip extends PFObject {
  constructor() { super('Trip'); }

  historyRecords() : TripHistoryRecord[] { return super.get('historyRecords'); }
  tonnage() { return super.get('tonnage'); }
  stage() { return super.get('stage'); }
  order() { return super.get('order'); }
  transportUnit() { return super.get('transportUnit'); }
  problems() { return super.get('problems'); }
  static tripStageName(stage: number) {
    if (stage === undefined || stage === null) {
      return 'TRIP.STAGE.None';
    }
    let stageName = ('TRIP.STAGE.' + stage);
    return stageName;
  }
}

export interface TripHistoryRecordAdditionalData {
  kilometers?: number
  waybillNumber?: string
}

export class TripHistoryRecord extends PFObject {
  constructor() { super('TripHistoryRecord'); }

  photo() { return super.get('photo'); }
  thumbnail() { return super.get('thumbnail'); }
  stage(): TripStage { return super.get('stage'); }
  date() { return super.get('date'); }
  tonnage(): number { return super.get('tonnage'); }
  setTonnage(value: number): void { super.set('tonnage', value)}
  comment(): string { return super.get('comment'); }
  address(): string { return super.get('address'); }
  coordinate() { return super.get('coordinate'); }

  additionalData(): TripHistoryRecordAdditionalData { return super.get('additionalData'); }
  setAdditionalData(value: TripHistoryRecordAdditionalData) { return super.set('additionalData', value); }

  ignoresWrongCoordinate(): boolean { return super.get('ignoreWrongCoordinate'); }
  setIgnoreWrongCoordinate(value: boolean) { super.set('ignoreWrongCoordinate', value); }
}

export const enum TripProblemType {
  Breakage = 0,
  Inactivity = 1,
  Delay = 2,
  Stoppage = 3
}

export class TripProblem extends PFObject {

  constructor() { super('TripProblem'); }

  type() { return super.get('type'); }
  date() { return super.get('date'); }
  coordinate() { return super.get('coordinate'); }
  trip(): Trip {return super.get('trip');}

  static problemTypeText(type: TripProblemType): string {
    switch (type) {
      case TripProblemType.Breakage: return 'PROBLEM.BREAKAGE';
      case TripProblemType.Inactivity: return 'PROBLEM.INACTIVITY';
      case TripProblemType.Delay: return 'PROBLEM.DELAY';
      case TripProblemType.Stoppage: return 'PROBLEM.STOPPAGE';
      default: return undefined;
    }
  }
}
