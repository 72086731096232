
import { Component, ViewEncapsulation, Inject, NgZone, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { PFGeoPoint } from 'app/components/data/core/models/base/point';
import { Entrance } from 'app/components/data/core/models/points/points';

import * as Location from 'app/components/location/coordinate';
import * as Geocode from 'app/components/geocode/geocode';
import * as Places from 'app/components/geocode/places';

import { LocaleService } from 'app/components/locale/locale.service';
import { entrance } from '../../locale/locale';
import { CloudExecutor } from 'app/components/data/cloud-executors/cloud-executor';
import * as FormHelper from 'app/components/form-helper/form-helper';

const defaultZoom = 8;
const placeFoundZoom = 15;

@Component({
    selector   : 'entrance-dialog',
    templateUrl: './entrance-dialog.component.html',
    styleUrls  : ['./entrance-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EntranceDialog {

  public form: UntypedFormGroup;
  private object: Entrance;

  public title: string;
  public buttonName: string;

  public zoom;
  public lat;
  public lon;

  public latitudeErrorParam = { min: -85, max: 85};
  public longitudeErrorParam = { min: -180, max: 180};

  private geocoder: Geocode.Geocoder;
  private addressSearchService: Places.SearchService;

  public addressSearchResults: Places.SearchResult[];
  private lastGeocodedAddress: string;
  private lastGeocodedCoordinate: Location.Coordinate;

  public dataSaver = new CloudExecutor;

  constructor (
    private localeService: LocaleService,
    public dialogRef: MatDialogRef<EntranceDialog>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef
  ) {
    this.localeService.loadLocale(entrance);
    this.object = this._data.object;
    if (this._data.action === 'create') {
        this.title = 'DIALOG.CREATEPOINT.TITLE.CREATE';
        this.buttonName = 'DIALOG.CREATEPOINT.CONFIRMBUTTON.CREATE';
        this.zoom = defaultZoom;
        this.lat = 55.4507;
        this.lon = 37.3656;
    } else if (this._data.action === 'edit') {
        this.title = 'DIALOG.CREATEPOINT.TITLE.EDIT';
        this.buttonName = 'DIALOG.CREATEPOINT.CONFIRMBUTTON.SAVE';
        this.zoom = placeFoundZoom;
        let point = this.object.getCoordinate();
        if (point) {
          this.lat = point.latitude;
          this.lon = point.longitude;
        }
    }

    if (this._data.customTitle) {
      this.title = this._data.customTitle;
    }

    if (this.object == null) {
      this.object = new Entrance();
      this.object.setAddress(this._data.address);
    }
    this.form = this.createContactForm();
  }

  parentContactName(): string {
    return this._data.contactName;
  }

  parentContactPhone(): string {
    return this._data.contactPhone;
  }

  public mapReady(map: any) {
    this.loadGeocoding(map);

    if (!this.object.getCoordinate()) {
      this.findCoordinate();
    }
  }

  createContactForm(): UntypedFormGroup {
    let name = this.object.getName();
    let address = this.object.getAddress();
    let coord = this.object.getCoordinate();
    let lat = '';
    let lon = '';
    if (coord) {
      lat = coord.latitude;
      lon = coord.longitude;
    }
    return this._formBuilder.group({
      name: [name],
      address: [address],
      lat: [lat, [Validators.required, Validators.min(this.latitudeErrorParam.min), Validators.max(this.latitudeErrorParam.max)]],
      lon: [lon, [Validators.required, Validators.min(this.longitudeErrorParam.min), Validators.max(this.longitudeErrorParam.max)]],
    });
  }

  checkAndSave() {
    let verified = this.checkForm();

    if (verified) {
      let name = this.form.get('name').value;
      this.object.setName(name);

      let address = this.form.get('address').value;
      this.object.setAddress(address);

      let lat = this.form.get('lat').value;
      let lon = this.form.get('lon').value;
      let point: PFGeoPoint = new PFGeoPoint({latitude: lat, longitude: lon});

      this.object.setCoordinate(point);

      this.dataSaver.saveObject(this.object).then(obj => {
        this.dialogRef.close(obj);
      });
    }
  }

  checkForm(): boolean {
    return FormHelper.checkForm(this.form);
  }

  zoomChanged(event) {
    this.zoom = event;
  }

  updatePosition(event) {
    this.form.get('lat').setValue(event.coords.lat);
    this.form.get('lon').setValue(event.coords.lng);
    this.findAddress();
  }

  updateLat() {
    if (this.form.controls.lat.valid) {
      let value = parseFloat(this.form.controls.lat.value);
      if (isFinite(value)) {
        this.lat = value;
      }
    }
  }

  updateLon() {
    if (this.form.controls.lon.valid) {
      let value = parseFloat(this.form.controls.lon.value);
      if (isFinite(value)) {
        this.lon = value;
      }
    }
  }

  public addressChanged(text: string): void {
    if (text.length > 2) {
      this.addressSearchService.search(text);
    }
  }

  public addressSearchResultSelected(searchResult: Places.SearchResult) {
    this.form.controls.address.setValue("");
    this.addressSearchService.getDetails(searchResult).then((details) => {
      this.setAddress(details.address);
      this.setCoordinate(details.coordinate);
      this.changeDetector.detectChanges();
    });
  }

  findCoordinate(): void {
    if (!this.form.controls.address.valid) {
      this.form.controls.address.markAsTouched({onlySelf: true});
      return;
    }

    let address = this.form.controls.address.value;
    if (this.lastGeocodedAddress && this.lastGeocodedAddress === address) {
      return;
    }

    this.lastGeocodedAddress = address;
    this.getCoordsForAddress(address, (coordinate) => {
      this.setCoordinate(coordinate);
      this.changeDetector.detectChanges();
    })
  }

  getCoordsForAddress(address: string, completion) {
    this.geocoder.codeAddress(address).then((coordinate) => {
      if (completion) {
        completion(coordinate);
      }
    });
  }

  findAddress(): void {
    if (!this.form.controls.lat.valid || !this.form.controls.lon.valid) {
      this.form.controls.lat.markAsTouched({onlySelf: true});
      this.form.controls.lon.markAsTouched({onlySelf: true});
      return;
    }

    let latitude = parseFloat(this.form.controls.lat.value);
    let longitude = parseFloat(this.form.controls.lon.value);
    if (!isFinite(latitude) || !isFinite(longitude)) {
      return;
    }

    let coordinate: Location.Coordinate = {latitude: latitude, longitude: longitude};
    if (this.lastGeocodedCoordinate && Location.isCoordinatesEqual(this.lastGeocodedCoordinate, coordinate)) {
      return;
    }
    this.lastGeocodedCoordinate = coordinate;

    this.geocoder.decodeAddress(coordinate).then((address) => {
      this.setAddress(address);
      this.changeDetector.detectChanges();
    });
  }

  private setAddress(address: string): void {
    this.lastGeocodedAddress = address;
    this.form.controls.address.setValue(address);
    this.form.controls.address.setErrors(null);
  }

  private setCoordinate(coordinate: Location.Coordinate): void {
    this.lastGeocodedCoordinate = coordinate;
    this.zoom = placeFoundZoom;
    this.lat = coordinate.latitude;
    this.lon = coordinate.longitude;
    this.form.controls.lat.setValue(coordinate.latitude);
    this.form.controls.lon.setValue(coordinate.longitude);
    this.form.controls.lat.setErrors(null);
    this.form.controls.lon.setErrors(null);
  }

  private loadGeocoding(map: any): void {
    this.geocoder = new Geocode.Geocoder();
    this.addressSearchService = Places.SearchService.all(map);

    this.addressSearchService.results.subscribe((results) => {
      this.addressSearchResults = results;
      this.changeDetector.detectChanges();
    });
  }
}
