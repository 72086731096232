
<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="primary" 
                 title="{{'DIALOG.CREDENTIALS.TITLE' | translate}}">
  </dialog-header>
  
  <div class="pt-8 px-16" fxFlex fxLayout="column">
    <form [formGroup]="form" fxFlex>
      <div>
        <mat-form-field fxFlex>
          <input matInput
                 placeholder="{{'DIALOG.CREDENTIALS.NAME' | translate}}"
                 formControlName="name"
                 required
                 [attr.cdkFocusInitial]="user ? null : ''">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-form-field fxFlex>
          <input #passwordInput
                 matInput
                 type="password"
                 placeholder="{{ (user ? 'DIALOG.CREDENTIALS.NEW_PASSWORD' : 'DIALOG.CREDENTIALS.PASSWORD') | translate}}"
                 formControlName="password"
                 required>
        </mat-form-field>
        <button
          mat-icon-button
          type="button"
          name="button"
          (click)="passwordInput.type === 'password' ? passwordInput.type='text' : passwordInput.type='password'">
            <mat-icon *ngIf="passwordInput.type === 'password'">visibility</mat-icon>
            <mat-icon *ngIf="passwordInput.type === 'text'">visibility_off</mat-icon>
        </button>
      </div>
    </form>
    <div class="py-8" fxLayout="row" [fxLayoutAlign]="(user ? 'space-between center' : 'end center')">
      <button *ngIf="user" 
              mat-raised-button
              type="button"
              [disabled]="executor.isProcessing()"
              color="warn"
              (click)='removeUser()'>{{'DIALOG.CREDENTIALS.BUTTON.REMOVE' | translate}}
      </button>
      <button mat-raised-button
              color="accent"
              [disabled]="executor.isProcessing()"
              (click)='checkAndSave()'>{{ (user ? 'DIALOG.CREDENTIALS.BUTTON.CHANGE_PASSWORD' : 'DIALOG.CREDENTIALS.BUTTON.CREATE') | translate}}
      </button>
    </div>
  </div>
</div>
