import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { PFObject } from 'app/components/data/core/models/base/object';
import { ArticleBrand } from 'app/components/data/core/models/articles/article';

import { LocaleService } from 'app/components/locale/locale.service';
import { createbrand } from '../../locale/locale';

import { DataLoader } from 'app/components/data/data-loader';
import { CloudExecutor } from 'app/components/data/cloud-executors/cloud-executor';
import * as FormHelper from 'app/components/form-helper/form-helper';

@Component({
  selector: 'create-article-brand-dialog',
  templateUrl: './create-article-brand-dialog.component.html',
  styleUrls: ['./create-article-brand-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CreateArticleBrandDialog {
  compareFunction = PFObject.compareFn;

  public form: UntypedFormGroup;
  public object: ArticleBrand;

  public title: string;
  public buttonName: string;

  public dataLoader: DataLoader;
  public dataSaver = new CloudExecutor();

  constructor(
    private localeService: LocaleService,
    public dialogRef: MatDialogRef<CreateArticleBrandDialog>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: UntypedFormBuilder
  ) {
    this.dataLoader = new DataLoader('ArticleType');
    this.localeService.loadLocale(createbrand);
    this.object = this._data.object;
    if (!this.object) {
      this.object = new ArticleBrand();
    }

    if (this._data.action === 'create') {
      this.title = 'CREATEBRAND.TITLE.CREATE';
      this.buttonName = 'CREATEBRAND.CONFIRMBUTTON.CREATE';
    } else if (this._data.action === 'edit') {
      this.title = 'CREATEBRAND.TITLE.EDIT';
      this.buttonName = 'CREATEBRAND.CONFIRMBUTTON.SAVE';
    }

    this.form = this.createContactForm();
    this.loadTypes();
  }

  loadTypes() {
    this.dataLoader.loadAll();
  }

  createContactForm(): UntypedFormGroup {
    return this._formBuilder.group({
      name: [this.object.name()],
      type: [this.object.type()],
      tonnagePerTruck: [this.object.tonnagePerTruck()]
    });
  }

  checkAndSave() {
    let verified = this.checkForm();

    if (verified) {
      this.object.setName(this.form.controls.name.value);
      this.object.setType(this.form.controls.type.value);
      this.object.setTonnagePerTruck(this.form.controls.tonnagePerTruck.value);

      this.dataSaver.saveObject(this.object).then(obj => {
        this.dialogRef.close(obj);
      });
    }
  }

  checkForm(): boolean {
    return FormHelper.checkForm(this.form);
  }
}
