<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="primary" 
                 title="{{'DIALOG.CARRIER.LIMITATIONS.LIST.SUPPLIERS' | translate}}">
  </dialog-header>
  
  <div class="py-8 px-16" fxLayout="column" fxLayoutAlign="space-between start">
    <mat-label>{{'DIALOG.CARRIER.LIMITATIONS.LIST.ALLOW_MESSAGE' | translate}}</mat-label>
    <mat-radio-group class="py-16" [(ngModel)]="choosenStyle">
      <mat-radio-button class="ml-16" *ngFor="let style of styles" [value]="style">{{ style.name | translate}}</mat-radio-button>
    </mat-radio-group>
    <div class="table-wrapper">
      <cd-table [tabledata]="tableData"
                [disabled]="!isTableVisible()">
      </cd-table>
    </div>
    <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button 
              color="accent" 
              [disabled]="dataSaver.isProcessing()" 
              (click)='confirm()'>{{'DIALOG.CARRIER.LIMITATIONS.SAVE' | translate}}
      </button>
    </div>
  </div>

</div>