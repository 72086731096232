import { TableData, TableRowData, RowParameters } from 'app/components/table/tabledata';

import { Entrance } from 'app/components/data/core/models/points/points';

import { LocalDataSource } from 'app/components/table/data_sources/local-data-source';
import { CurrentUser } from 'app/components/data/core/models/base/user';

export interface EntranceSource {
  getEntrances(): Entrance[];
  removeEntrance(Entrance);
}

export class EntranceTableData extends TableData{

  constructor(
    private object: EntranceSource
  ) {
    super();
    let columns = [
      new TableRowData({
        key: 'name', 
        header:'DIALOG.POINTS.TABLEROW.NAME',
        cell: (entrance: Entrance) => `${entrance.getName()}`, 
        params: new RowParameters('30')
      }),
      new TableRowData({
        key: 'address',
        header:'DIALOG.POINTS.TABLEROW.ADDRESS',
        cell: (entrance: Entrance) => `${entrance.getAddress()}`
      }),
    ];

    this.setColumns(columns);

    let tableDataSource = new LocalDataSource(this.getEntrances());
    this.setProvider(tableDataSource);
  }

  private getEntrances() {
    let entrances = this.object.getEntrances();
    if (entrances === undefined) {
      entrances = [];
    }
    let displayDeleted = CurrentUser.getSettings().displayDeleted;
    entrances = entrances.filter(e => {
      return !e.isDeleted() || displayDeleted;
    });
    return entrances;
  }
}
