import { TableData, TableRowData, TableSort } from 'app/components/table/tabledata';

import { Dispatcher } from 'app/components/data/core/models/persons/dispatcher';
import { ServerTableDataSource } from 'app/components/table/data_sources/server-data-source';
import { PFQuery } from 'app/components/data/core/models/base/query';
import { filterDeleted } from 'app/components/access/query';

export class DispatchersTableData extends TableData {

  constructor() {
    super();

    let columns = [
      new TableRowData({
        key: 'name',
        header: 'USERS.TABLEROW.FULLNAME',
        cell: (dispatcher: Dispatcher) => `${ dispatcher.getName() }`
      }),
      new TableRowData({
        key: 'carrier',
        header: 'USERS.TABLEROW.CARRIER',
        cell: (dispatcher: Dispatcher) => `${ dispatcher.getCarrier() ? dispatcher.getCarrier().getName() : '' }`
      }),
      new TableRowData({
        key: 'credentials',
        header: 'USERS.TABLEROW.LOGIN',
        cell: (dispatcher: Dispatcher) => `${ dispatcher.getUser() ? dispatcher.getUser().getUsername() : ''}`
      }),
    ];
    this.setColumns(columns);

    let sort: TableSort = new TableSort('name','asc');
    let tableDataSource = new ServerTableDataSource('Dispatcher', ['user', 'carrier']);
    tableDataSource.setDefaultSort(sort);
    tableDataSource.queryModify = function (query: PFQuery): PFQuery {
      return filterDeleted(query);
    };
    tableDataSource.queryFilter = (filter, defaultQuery) => {
      defaultQuery.contains("name", filter);

      let carrierQuery = new PFQuery('Carrier');
      carrierQuery.contains('name', filter);
      let dispatcherCarrierQuery = new PFQuery('Dispatcher');
      dispatcherCarrierQuery.matchesQuery('carrier', carrierQuery);

      let usernameQuery = new PFQuery('User');
      usernameQuery.contains('username', filter);
      let dispatcherUsernameQuery = new PFQuery('Dispatcher');
      dispatcherUsernameQuery.matchesQuery('user', usernameQuery);

      return PFQuery.or(defaultQuery, dispatcherCarrierQuery, dispatcherUsernameQuery);
    };
    this.setProvider(tableDataSource);
  }

  canSortColumn(column) {
    if (column.key !== 'name') {
      return false;
    }
    return super.canSortColumn(column);
  }
}
