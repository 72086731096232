import {locale as customer_info_en} from "./en/locale.customer-info";
import {locale as customer_info_ru} from "./ru/locale.customer-info";

export const customer_info = [
  {
    lang: 'en',
    data: customer_info_en
  },
  {
    lang: 'ru',
    data: customer_info_ru
  },
];

