
export const locale = {
  'REMOVE_USER_DIALOG' : {
    'TITLE' : 'Remove',
    'MESSAGE' : 'Do you really want to delete this item/items?',
    'BUTTON' : {
      'CONFIRM' : 'Confirm',
      'DECLINE' : 'Decline',
    },
    'ERROR': 'Failed to remove user. Try again later.',
  }
};
