import {locale as order_en} from "./en/locale.order-create";
import {locale as order_ru} from "./ru/locale.order-create";

export const order_create_dialog = [
  {
    lang: 'en',
    data: order_en
  },
  {
    lang: 'ru',
    data: order_ru
  },
];

