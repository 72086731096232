import { Vehicle } from 'app/components/data/core/models/transport/transport';
import { VehiclePass, VehiclePassState, VehiclePassTimeOfAction, VehiclePassZone } from 'app/components/data/core/models/transport/vehicle-pass';
import { TranslateService } from '@ngx-translate/core';

export namespace PassesHelper {
  export function localizedPassInfo(vehicle: Vehicle, translate: TranslateService): string {
    let passes: VehiclePass[] = vehicle.passes();
    if (passes) {
      return passes.map(pass => {
        return passTemplate(translate, pass)
      }).join("");
    }
    return '';
  }

  export function getPassCellClass(vehicle: Vehicle) {
    let passes = vehicle.passes();
    if (passes && passes.length > 0) {
      let pass = passes[0];
      if (pass.canceled) {
        return 'warn-fg';
      } else {
        return 'green-fg'
      }
    }
    return null;
  }

  function passTemplate(translate: TranslateService, pass: VehiclePass) {
    let components = [];
    if (pass.number) {
      components.push(pass.number);
    }
    components.push(translate.instant(passTypeNameFor(pass.zone)));
    if (pass.timeOfAction) {
      components.push(translate.instant(timeOfActionStringFor(pass.timeOfAction)))
    }

    let textClass = passStateClass(pass)
    return `<div class='${textClass}'>
              <div>${components.join(', ')}</div>
              <div>${validityString(translate, pass)}</div>
            </div>`
  }

  export function passStateName(pass: VehiclePass) {
    switch (VehiclePass.passState(pass)) {
      case VehiclePassState.Valid: return "PASSES.STATE.VALID";
      case VehiclePassState.Canceled: return "PASSES.STATE.CANCELED";
      case VehiclePassState.Invalid: return "PASSES.STATE.INVALID";
      case VehiclePassState.NotValidNow: return "PASSES.STATE.NOT_VALID_NOW";
    }
  }

  export function passStateClass(pass: VehiclePass) {
    switch (VehiclePass.passState(pass)) {
      case VehiclePassState.Valid: return "green-fg";
      case VehiclePassState.Canceled: return "warn-fg";
      case VehiclePassState.Invalid: return "warn-fg";
      case VehiclePassState.NotValidNow: return "orange-fg";
    }
  }

  export function validityString(translate: TranslateService, pass: VehiclePass) {
    if (pass.canceled) {
      return `${translate.instant('PASSES.INVALID')}`
    } else if (pass.beginDate && pass.endDate) {
      let beginDate = new Date(pass.beginDate);
      let endDate = new Date(pass.endDate);
      return `${translate.instant('PASSES.INTERVAL.FORMAT', { from: beginDate.toLocaleDateString(), to: endDate.toLocaleDateString() })}`
    } else return translate.instant('PASSES.INTERVAL.INVALID');
  }

  export function passTypeNameFor(zone: VehiclePassZone): string {
    switch (zone) {
      case VehiclePassZone.MKAD:
      case VehiclePassZone.CK:
      case VehiclePassZone.TTK:
        return 'PASSES.TYPE.' + zone;
      default: return "PASSES.TYPE.INVALID";
    }
  }

  export function timeOfActionStringFor(timeOfAction: VehiclePassTimeOfAction): string {
    switch (timeOfAction) {
      case VehiclePassTimeOfAction.Day:
      case VehiclePassTimeOfAction.Night:
        return 'PASSES.TIME_OF_ACTION.' + timeOfAction.toUpperCase();
      default: return "PASSES.TIME_OF_ACTION.INVALID";
    }
  }
}