import { AllComponent } from './all.component';
import { NgModule } from '@angular/core';
import {ActionBarModule} from '../../../../components/action-bar/action-bar.module';
import {CommonModule} from '@angular/common';
import {FlexModule} from '@angular/flex-layout';
import {FuseDirectivesModule} from '../../../../../@fuse/directives/directives';
import {MatButtonModule} from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {TableHeaderModule} from '../../../../components/tableheader/tableheader.module';
import {TableModule} from '../../../../components/table/table.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [
    AllComponent
  ],
  exports: [
    AllComponent
  ],
  imports: [
    ActionBarModule,
    CommonModule,
    FlexModule,
    FuseDirectivesModule,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    TableHeaderModule,
    TableModule,
    TranslateModule
  ]
})
export class AllModule { }
