
import { PFObject } from "../base/object";
import { Supplier } from '../persons/supplier';
import { TripStage } from "../trip/trip";

export class Configuration extends PFObject {

  constructor() { super("Configuration"); }

  getOrderTimeout(): number {return super.get("orderTimeout");}
  setOrderTimeout(value: number): void {super.set("orderTimeout", value);}

  getTransportUnitInactivityTimeInterval(): number {return super.get("transportUnitInactivityTimeInterval");}
  setTransportUnitInactivityTimeInterval(value: number): void {super.set("transportUnitInactivityTimeInterval", value);}

  getTripDelayTimeInterval(nextStage: TripStage): number {return super.get("tripDelayTimeIntervals")[nextStage];}

  setTripDelayTimeInterval(value: number, nextStage: TripStage): void {
    let values = super.get("tripDelayTimeIntervals");
    values[nextStage] = value;
    super.set("tripDelayTimeIntervals", values);
  }

  getTripStoppageTimeInterval(): number {return super.get("tripStoppageTimeInterval");}
  setTripStoppageTimeInterval(value: number): void {super.set("tripStoppageTimeInterval", value);}

  getMaxDistanceDifference(): number {return super.get("maxDistanceDifference");}
  setMaxDistanceDifference(value: number): void {super.set("maxDistanceDifference", value);}

  getOverloadFactor(): number { return super.get("overloadFactor"); }

  getSupplierOrder(): Supplier[] { return super.get('supplierOrder'); }
  setSupplierOrder(value: Supplier[]) { return super.set('supplierOrder', value); }
  save() { return super.save();}
}
