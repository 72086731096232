<div class="wrapper">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-checkbox #readyBox (change)='filterState($event.checked)'></mat-checkbox>
      <button mat-button (click)="readyBox.checked = !readyBox.checked; filterState(readyBox.checked)">
        <mat-label class="ml-6" fxFlex="auto">{{'TRANSFER.TAB.DRIVERS.READYTOWORK' | translate }}
        </mat-label>
      </button>
    </div>

    <div class="pl-8" fxFlex fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="start center" fxFlex>
        <mat-icon>search</mat-icon>
        <input #searchbar (keyup)="searchStringChanged($event.target.value)"
          placeholder="{{'TRANSFER.SEARCH' | translate}}" fxFlex cdkFocusInitial>
        <mat-icon *ngIf="searchbar.value" (click)="searchbar.value = ''; searchStringChanged('');">close</mat-icon>
      </div>
      <mat-divider></mat-divider>
    </div>

    <div *ngIf="extended" class="pl-16" fxLayout="row" fxLayoutAlign="center center">
      <button mat-stroked-button (click)="createUnit()">
        <mat-label>{{'TRANSFER.TAB.DRIVERS.CREATE_UNIT' | translate}}</mat-label>
      </button>

      <button class="ml-8" mat-stroked-button (click)="createDriver()">
        <mat-label>{{'TRANSFER.TAB.DRIVERS.CREATE_DRIVER' | translate}}</mat-label>
      </button>

      <button mat-icon-button (click)="reload()">
        <mat-icon class="s-20">refresh</mat-icon>
      </button>
    </div>
  </div>

  <mat-tab-group>
    <mat-tab label="{{'TRANSFER.TAB.DRIVERS.SUBTAB.LIST' | translate}}">
      <div class="table-wrapper">
        <cd-table [tabledata]="tableData"
                  [options]="tableOptions">
        </cd-table>
      </div>
    </mat-tab>
    <mat-tab label="{{'TRANSFER.TAB.DRIVERS.SUBTAB.MAP' | translate}}">
      <agm-map [latitude]="lat" [longitude]="lon">
        <agm-marker class="loading-point-marker"
          [latitude]="order.loadingEntrance().getCoordinate().latitude"
          [longitude]="order.loadingEntrance().getCoordinate().longitude">
          <agm-info-window #loadInfo>
            <strong>{{order.loadingEntrance().getName()}}</strong>
            <p>{{order.loadingEntrance().getAddress()}}</p>
          </agm-info-window>
        </agm-marker>

        <agm-marker-cluster
          imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">
          <agm-marker *ngFor="let tu of units();" class="unit-marker" [iconUrl]="iconNameForUnit(tu)"
            (markerClick)="clickMarker(tu)" [latitude]="tu.coordinate().latitude"
            [longitude]="tu.coordinate().longitude">
            <agm-info-window #unitInfo>
              <strong>{{tu.driver().getName()}}</strong>
              <p>{{tu.vehicleName()}}</p>
              <p>{{tu.vehicle().number()}}</p>
              <p *ngIf="tu.trailer()">{{tu.trailer().number()}}</p>
            </agm-info-window>
          </agm-marker>
        </agm-marker-cluster>
      </agm-map>
    </mat-tab>
  </mat-tab-group>
</div>