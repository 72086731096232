import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { LocaleService } from 'app/components/locale/locale.service';
import { PFDeletableObject } from 'app/components/data/core/models/base/deletableObject';
import { PFObject } from 'app/components/data/core/models/base/object';
import { removedialog } from './locale/locale';

@Component({
    selector   : 'remove-dialog',
    templateUrl: './remove-dialog.component.html',
    styleUrls  : ['./remove-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class RemoveDialogComponent {
  private objects: any[];
  private hard: boolean;
  private onlyAsk: boolean;

  constructor (
    private localeService: LocaleService,
    public dialogRef: MatDialogRef<RemoveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) _data: any,
  ) {
    this.localeService.loadLocale(removedialog);

    if (_data) {
      this.objects = _data.objects;
      this.hard = _data.hard ? _data.hard : false;
      this.onlyAsk = _data.onlyAsk ? _data.onlyAsk : false;
    }
  }

  confirmDeletion() {
    if (this.onlyAsk) {
      this.dialogRef.close(this.objects);
      return;
    } 
    let promise;
    if (this.hard) {
      promise = Promise.resolve();
      this.objects.forEach(o => {
        promise = promise.then(() => {
          return PFObject.destroyObject(o);
        });
      })
    } else {
      promise = PFDeletableObject.markObjectsDeleted(this.objects);
    }

    promise.then(() => {
      this.dialogRef.close(this.objects);
    });
  }
}
