import {TableRowData, RowParameters} from 'app/components/table/tabledata';
import {EquipmentHelper} from 'app/components/helpers/equipment.helper';
import {Order, getOrderAuthorName} from 'app/components/data/core/models/order/order';
import {OrderHelper} from 'app/components/helpers/order.helper';
import {TranslateService} from '@ngx-translate/core';
import {TripStage} from 'app/components/data/core/models/trip/trip';
import {TableNameKeys} from "../../../components/table/data_sources/table-name-keys";

export namespace OrderColumns {

    let statusParams = new RowParameters();
    statusParams.isHTML = true;

    let unloadDateOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    };
    var createDateOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    };


    export function makeColumnNumber(): TableRowData {
        return new TableRowData({
            key: TableNameKeys.number,
            header: '#',
            cell: (order: Order) => `${order.number()}`,
            headerCellStyle: () => makeStyle(80),
            cellStyle: () => makeStyle(80),
        });
    }

    export function makeColumnCustomer(): TableRowData {
        return new TableRowData({
            key: TableNameKeys.customer,
            header: 'ORDER.TABLEROW.CUSTOMER',
            cell: (order: Order) => {
                if (order) {
                    let customer = order.customer();
                    if (customer) {
                        return `${customer.getName()}`;
                    }
                }
                return '';

            },
        });
    }

    export function makeColumnBrand(): TableRowData {
        return new TableRowData({
            key: TableNameKeys.brand,
            header: 'ORDER.TABLEROW.BRAND',
            cell: (order: Order) => `${order.articleBrand().name()}`
        });
    }

    export function makeColumnUnloadDate(language): TableRowData {
        return new TableRowData({
            key: TableNameKeys.unloadDate,
            header: 'ORDER.TABLEROW.UNLOAD_DATE',
            cell: (order: Order) => `${getDateStrWithOptions(order.unloadingBeginDate(), unloadDateOptions, language)}`,
            headerCellStyle: () => makeStyle(130),
            cellStyle: () => makeStyle(130),
        });
    }

    export function makeColumnUnloadPoint(): TableRowData {
        return new TableRowData({
            key: TableNameKeys.unLoadingPoint,
            header: 'ORDER.TABLEROW.OUTPOINT',
            cell: (order: Order) => `${order.unloadingPointAddress()}`,
        });
    }

    export function makeColumnTonnage(): TableRowData {
        return new TableRowData({
            key: TableNameKeys.tonnage,
            header: 'ORDER.TABLEROW.WEIGHT',
            cell: (order: Order) => `${order.tonnage()}`,
            headerCellStyle: () => makeStyle(80),
            cellStyle: () => makeStyle(60),
        });
    }

    export function makeColumnCreatedAt(language): TableRowData {
        return new TableRowData({
            key: TableNameKeys.createdAt,
            header: 'ORDER.TABLEROW.CREATEDATE',
            cell: (order: Order) => `${getDateStrWithOptions(order.creationDate(), createDateOptions, language)}`,
            headerCellStyle: () => makeStyle(120),
            cellStyle: () => makeStyle(120),
        });
    }

    export function makeColumnDriverStatus(translate: TranslateService): TableRowData {
        return new TableRowData({
            key: TableNameKeys.driverStatus,
            header: 'ORDER.TABLEROW.STATUS',
            cell: (order: Order) => `${OrderHelper.orderStatus(order, translate)}`,
            params: statusParams,
            headerCellStyle: () => makeStyle(200),
            cellStyle: () => makeStyle(200),
        });
    }

    export function makeColumnEquipment(translate: TranslateService): TableRowData {
        return new TableRowData({
            key: TableNameKeys.equipment,
            header: 'ORDER.TABLEROW.REQUIRED_EQUIPMENT',
            cell: (order: Order) => `${EquipmentHelper.localizedEquipmentRequirementsList(order.unloadingPoint(), translate)}`,
            headerCellStyle: () => makeStyle(120),
            cellStyle: () => makeStyle(120),
        });
    }

    export function makeColumnAuthorName(): TableRowData {
        return new TableRowData({
            key: TableNameKeys.author,
            header: 'ORDER.TABLEROW.USERNAME',
            cell: (order: Order) => `${getOrderAuthorName(order)}`,
            headerCellStyle: () => makeStyle(120),
            cellStyle: () => makeStyle(120),
        });
    }

    export function makeColumnComment(): TableRowData {
        return new TableRowData({
            key: TableNameKeys.comment,
            header: 'ORDER.TABLEROW.COMMENT',
            cell: (order: Order) => `${getComment(order)}`,
        });
    }

    function makeStyle(width: number = 0, justifyContent: string = 'start') {
        var style = {
            'justify-content': `${justifyContent}`,
        };
        if (width > 0) {
            style['max-width'] = `${width}px`;
        }
        return style;
    }

    export function makeColumnCustomerStatus(translate: TranslateService): TableRowData {
        return new TableRowData({
            key: TableNameKeys.customerStatus,
            header: 'ORDER.TABLEROW.STATUS',
            cell: (order: Order) => `${OrderHelper.customerOrderStatus(order, translate)}`,
            headerCellStyle: () => makeStyle(120),
            cellStyle: () => makeStyle(120),
        });
    }

    export function makeColumnUnloadedTonnage(): TableRowData {
        return new TableRowData({
            key: TableNameKeys.tonnageFact,
            header: 'FINISHEORDERSLIST.TABLEROW.UNLOADED_TONNAGE',
            cell: (order: Order) => `${getUnloadedTonnage(order)}`,
            headerCellStyle: () => makeStyle(120),
            cellStyle: () => makeStyle(100),
        });
    }

    export function makeColumnFacticalUnloadDate(): TableRowData {
        return new TableRowData({
            key: TableNameKeys.unloadDateFact,
            header: 'FINISHEORDERSLIST.TABLEROW.UNLOAD_DATE',
            cell: (order: Order) => `${getUnloadDateStr(order)}`,
            headerCellStyle: () => makeStyle(150),
            cellStyle: () => makeStyle(150),
        });
    }

    export function makeColumnFacticalLoadDate(): TableRowData {
        return new TableRowData({
            key: TableNameKeys.loadDateFact,
            header: 'FINISHEORDERSLIST.TABLEROW.LOAD_DATE',
            cell: (order: Order) => `${getLoadDateStr(order)}`,
            headerCellStyle: () => makeStyle(150),
            cellStyle: () => makeStyle(150),
        });
    }

    export function makeColumnSupplier(): TableRowData {
        return new TableRowData({
            key: TableNameKeys.supplier,
            header: 'FINISHEORDERSLIST.TABLEROW.SUPPLIER',
            cell: (order: Order) => `${getSupplierName(order)}`,
        });
    }

    export function makeColumnDriver(): TableRowData {
        return new TableRowData({
            key: TableNameKeys.driver,
            header: 'FINISHEORDERSLIST.TABLEROW.DRIVER',
            cell: (order: Order) => `${getDriverName(order)}`
        });
    }

    function getDateStrWithOptions(date: Date, ops, language) {
        if (date instanceof Date) {
            return date.toLocaleDateString(language, ops);
        }
        return '';
    }

    function getLoadDateStr(order: Order): string {
        let offers = order.offers();
        for (let offer of offers) {
            let trip = offer.trip();
            if (trip) {
                if (trip.historyRecords()) {
                    let loadRecord = trip.historyRecords().find(historyRecord => historyRecord.stage() === TripStage.Loaded);
                    if (loadRecord) {
                        return loadRecord.date().toLocaleString();
                    }
                } else {
                    return '';
                }
            }
        }
        return '';
    }

    function getUnloadDateStr(order: Order): string {
        let offers = order.offers();
        for (let offer of offers) {
            let trip = offer.trip();
            if (trip) {
                let loadRecord = trip.historyRecords().find(historyRecord => historyRecord.stage() === TripStage.Unloaded);
                if (loadRecord) {
                    return loadRecord.date().toLocaleString();
                }
            }
        }
        return '';
    }

    function getUnloadedTonnage(order: Order) {
        let offers = order.offers();
        for (let offer of offers) {
            let trip = offer.trip();
            if (trip && trip.historyRecords()) {
                let unloadRecord = trip.historyRecords().find(historyRecord => historyRecord.stage() === TripStage.Unloaded);
                if (unloadRecord) {
                    return unloadRecord.tonnage();
                }
            }
        }
        return 0;
    }

    function getSupplierName(order: Order) {
        let supplier = order.supplier();
        if (supplier) {
            return supplier.getName();
        }
        return '';
    }

    function getComment(order: Order) {
        let comment = order.comment();
        if (comment) {
            return comment;
        }
        return '';
    }

    function getDriverName(order: Order) {
        let offers = order.offers();
        if (offers.length > 0) {
            let tu = offers[0].transportUnit();
            if (tu) {
                return tu.driver().getName();
            }
        }
        return '';
    }

}
