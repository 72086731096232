import { Component, ViewEncapsulation, Inject, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { PFObject } from 'app/components/data/core/models/base/object';

import { Manager } from 'app/components/data/core/models/persons/manager';

import { LocaleService } from 'app/components/locale/locale.service';
import { create_manager } from '../../locale/locale';

import { CloudExecutor } from 'app/components/data/cloud-executors/cloud-executor';
import * as FormHelper from 'app/components/form-helper/form-helper';

@Component({
    selector   : 'manager-dialog',
    templateUrl: './manager-dialog.component.html',
    styleUrls  : ['./manager-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ManagerDialog {
  compareFunction = PFObject.compareFn;
  
  public form: UntypedFormGroup;
  private object: Manager;

  public title: string;
  public buttonName: string;

  public dataSaver = new CloudExecutor;

  constructor (
    public dialogRef: MatDialogRef<ManagerDialog>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _formBuilder: UntypedFormBuilder,
    private localeService: LocaleService,
    private zone: NgZone
  ) {
    this.localeService.loadLocale(create_manager);

    this.object = this._data.object;
    if (this._data.action === 'create') {
        this.title = 'DIALOG.CREATE_MANAGER.TITLE.CREATE';
        this.buttonName = 'DIALOG.CREATE_MANAGER.CONFIRMBUTTON.CREATE';
    } else if (this._data.action === 'edit') {
        this.title = 'DIALOG.CREATE_MANAGER.TITLE.EDIT';
        this.buttonName = 'DIALOG.CREATE_MANAGER.CONFIRMBUTTON.SAVE';
    }

    if (this.object == null) {
      this.object = new Manager();
    }
    this.form = this.createContactForm();
  }

  createContactForm(): UntypedFormGroup {
    let formGroup = {
      name: [this.object.getName()],
    };
    return this._formBuilder.group(formGroup);
  }

  checkAndSave() {
    if (this.checkForm()) {
      this.object.setName(this.form.get('name').value);

      this.dataSaver.saveObject(this.object).then(obj => {
        this.dialogRef.close(obj);
      });
    }
  }

  checkForm(): boolean {
    return FormHelper.checkForm(this.form);
  }
}
