
/// <reference types="@types/googlemaps" />
import * as Location from "app/components/location/coordinate";
import * as Formatter from "./formatter";

declare const google: any;

export class Geocoder {

  private internal: google.maps.Geocoder;

  constructor() {
    this.internal = new google.maps.Geocoder();
  }

  decodeAddress(coordinate: Location.Coordinate): Promise<string> {
    return new Promise((resolve, reject) => {
      this.internal.geocode({location: new google.maps.LatLng(coordinate.latitude, coordinate.longitude)}, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          console.log("Geocoding succeeded");
          console.log(results[0]);
          resolve(Formatter.formatAddress(results[0].address_components));
        } else {
          console.log("Geocoding failed: " + status);
          reject(status);
        }
      });
    });
  }

  codeAddress(address: string): Promise<Location.Coordinate> {
    return new Promise((resolve, reject) => {
      this.internal.geocode({address: address}, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          console.log("Geocoding succeeded");
          let internalCoordinate = results[0].geometry.location;
          resolve({latitude: internalCoordinate.lat(), longitude: internalCoordinate.lng()});
        } else {
          console.log('Geocoding failed: ' + status);
          reject(status);
        }
      });
    });
  }

}
