export const locale = {
    'DIALOG': {
        'CONTACTS': {
          'HEADER_TITLE': 'CONTACTS',
          'CONFIRMBUTTON': {
            'CLOSE': 'Close',
          },
          'ADDRESS': 'Address',

          'TABLEROW': {
            'NAME': 'Name',
            'PHONE': 'Phone',
          },
        },
      },
  };
  