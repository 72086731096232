
import { MatPaginatorIntl } from '@angular/material/paginator';

function getRangeLabel(pageIndex: number, pageSize: number, length: number) {
  if (length == 0 || pageSize == 0) {
    return `0 из ${length}`;
  }

  length = Math.max(length, 0);
  let startIndex = pageIndex * pageSize;
  let endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} из ${length}`;
}

export function getPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = "Элементов на странице:";
  paginatorIntl.nextPageLabel = "Следующая страница";
  paginatorIntl.previousPageLabel = "Предыдущая страница";
  paginatorIntl.getRangeLabel = getRangeLabel;

  return paginatorIntl;
}
