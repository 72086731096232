<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="primary" 
                 title="{{'DELETECONFIRM.TITLE' | translate}}"
                 (clickAction)="close()">
  </dialog-header>
  <div class="pt-8 px-16 delete-content" *ngIf="!isExecuting()" fxLayout="column" fxLayoutAlign="space-between stretch">
    <div fxLayout="column" fxLayoutAlign="center start">
      <p>{{'DELETECONFIRM.MESSAGE' | translate}}</p>
      <p>{{'DELETECONFIRM.ORDERNUMBER' | translate}} 
        <span *ngFor="let order of orders">
          {{order.number()}}<span *ngIf="order != orders[orders.length-1]">,</span>
        </span>
      </p>
    </div>
    <div class="py-8" fxLayout="row" fxLayoutAlign="end end" fxFlex>
      <button mat-raised-button
              color="warn"
              (click)="execute()"
              [disabled]="isExecuting()"
              cdkFocusInitial>{{'DELETECONFIRM.CONFIRM' | translate}}
      </button>
    </div>
  </div>
  <div class="pt-8 px-16 delete-content" *ngIf="isExecuting()" fxLayout="row" fxLayoutAlign="center center">
    <mat-spinner diameter="30" color="accent"></mat-spinner>
    <mat-label class="px-16">
      {{'DELETECONFIRM.DELETING' | translate}} {{currentIndex}}/{{orders.length}}
    </mat-label>
  </div>

</div>
