import { TableData, TableSort } from 'app/components/table/tabledata';
import { OrderStatus } from 'app/components/data/core/models/order/order';

import * as AccessQueries from 'app/components/access/query';
import { DateHelper } from 'app/components/helpers/date.helper';
import { LiveQueryModifier, LiveQuerySubscriber } from 'app/components/table/data_sources/live-query-subscriber';
import { ServerTableDataSource } from 'app/components/table/data_sources/server-data-source';
import { PFQuery } from 'app/components/data/core/models/base/query';
import { TranslateService } from '@ngx-translate/core';
import { ProposalColumns } from '../../helpers/proposal-columns-factory';
import { OrderQuerySearchFilter } from 'app/main/orders/helpers/order-query-search-filter';

export class ProposalsFromCustomersTableData extends TableData {
  constructor(
    date: Date,
    private translate: TranslateService
  ) {
    super();

    let columns = [
      ProposalColumns.makeColumnTime(),
      ProposalColumns.makeColumnCount(),
      ProposalColumns.makeColumnBrand(),
      ProposalColumns.makeColumnCustomer(this.translate),
      ProposalColumns.makeColumnUnloadingAddress(),
      ProposalColumns.makeColumnEquipment(this.translate),
      ProposalColumns.makeColumnComment(),
    ];

    this.setColumns(columns);

    let includeFields = [
      'articleBrand.type',
      'customer',
      'unloadingPoint',
    ];

    let sort: TableSort = new TableSort('number', 'desc');
    let tableDataSource = new ServerTableDataSource('Order', includeFields);
    tableDataSource.setDefaultSort(sort);
    tableDataSource.queryFilter = (filter, query) => {
      let customerNameFilter = OrderQuerySearchFilter.customerName(filter);
      let unloadingEntranceFilter = OrderQuerySearchFilter.unloadingEntranceAddress(filter);
      let brandFilter = OrderQuerySearchFilter.brandName(filter);

      let orQuery = PFQuery.or(
        customerNameFilter,
        unloadingEntranceFilter,
        brandFilter
      );

      let number = parseFloat(filter);
      if (!isNaN(number)) {
        let numberFilter = OrderQuerySearchFilter.orderNumber(number);
        orQuery = PFQuery.or(orQuery, numberFilter);
      }
      return orQuery;
    };

    let startDate = DateHelper.setDayBegin(new Date(date));
    let endDate = DateHelper.setDayEnd(new Date(date));
    function applyQueryModification(query: PFQuery) {
      query.greaterThanOrEqualTo("unloadingBeginDate", startDate);
      query.lessThanOrEqualTo("unloadingBeginDate", endDate);
      query.equalTo('status', OrderStatus.CUSTOMER_REQUEST);
      query.exists('customer');
      query = AccessQueries.filterDeleted(query);
      return query;
    }

    tableDataSource.queryModify = function (query: PFQuery): PFQuery {
      return applyQueryModification(query);
    };

    let subscriber = new LiveQuerySubscriber('Order', includeFields);

    subscriber.liveQueryModifier = new LiveQueryModifier(
      function (query) {
        return applyQueryModification(query);
      }, (order) => {
        return order;
      }
    );

    tableDataSource.setLiveQuerySubscriber(subscriber);
    this.setProvider(tableDataSource);
  }

  canSortColumn(column) {
    return false;
  }
}
