<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="primary" 
                 title="{{'DIALOG.CUSTOMER.PERMISSIONS.TITLE' | translate}}"></dialog-header>
  <div class="py-8 px-16">
    <div class="py-16">
      <mat-label translate [translateParams]="{value: name()}">
        DIALOG.CUSTOMER.PERMISSIONS.MESSAGE
      </mat-label>
      <div class="py-16" fxLayout="column" fxLayoutAlign="space-between start">
        <mat-checkbox [(ngModel)]="canSeeDriverPhoneNumber">{{'DIALOG.CUSTOMER.PERMISSIONS.DRIVERS_PHONE' | translate}}</mat-checkbox>
      </div> 
      
    </div>
    <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button 
              color="accent" 
              [disabled]="dataSaver.isProcessing()"
              (click)='confirm()'>{{'DIALOG.CUSTOMER.PERMISSIONS.SAVE' | translate}}
      </button>
    </div>
  </div>

</div>