import { PFObject } from 'app/components/data/core/models/base/object';
import { Order } from 'app/components/data/core/models/order/order';
import { Tariff } from 'app/components/data/core/models/tariff/tariff';

export namespace ProposalHelper {
    export function totalTonnage(orders: Order[]) {
        let tonnage = 0;
        orders.forEach(order => {
            tonnage += order.tonnage();
        });
        return tonnage;
    }

    export function totalPrice(orders: Order[], tariffs: Tariff[]) {
        let sum = 0;
        orders.forEach(o => {
            let tariff = tariffs.find(t => {
                return PFObject.compareFn(t.supplier(), o.supplier()) && 
                PFObject.compareFn(t.brand(), o.articleBrand()) &&
                PFObject.compareFn(t.loadingPoint(), o.loadingPoint());
            });
            if (tariff) {
                sum += tariff.price() * o.tonnage();
            }
        });
        return sum;
    }
}