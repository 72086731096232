export const locale = {
  "COMMON": {
    'SEARCHTITLE': 'Search',
    'BUTTONS' : {
      'RELOAD' : 'Reload data',
    },
    'SPEEDUNIT' : 'km/h',
    'NO_ITEMS' : 'No items',
    'DELETED': 'Deleted',
    'ERROR' : {
      'INVALID_DATE' : "Date must be in format '01.01.2001, 00:00'",
      'LOAD_ERROR' : "Load error.",
    }
  },
};
