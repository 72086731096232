
<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="primary" 
                 title="{{title | translate}}">
  </dialog-header>
  
  <div class="pt-8 px-16">
    <form [formGroup]="form">
      <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">
        <mat-form-field fxFlex='49' [floatLabel]="parentContactName() ? 'always' : 'auto'">
          <mat-label>{{'DIALOG.UNLOADING_POINT.CONTACT_NAME' | translate}}</mat-label>
          <input matInput formControlName="contactName" [placeholder]="parentContactName()">
        </mat-form-field>
      
        <mat-form-field fxFlex='49' [floatLabel]="parentContactPhone() ? 'always' : 'auto'">
          <mat-label>{{'DIALOG.UNLOADING_POINT.CONTACT_PHONE' | translate}}</mat-label>
          <input matInput formControlName="contactPhone" [placeholder]="parentContactPhone()">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field fxFlex>
          <input matInput
                 placeholder="{{'DIALOG.UNLOADING_POINT.ADDRESS' | translate}}"
                 formControlName="address"
                 required
                 [matAutocomplete]="addressAutocomplete"
                 (input)="addressChanged($event.target.value)">
          <mat-autocomplete #addressAutocomplete="matAutocomplete" (optionSelected)="addressSearchResultSelected($event.option.value)">
            <mat-option *ngFor="let searchResult of addressSearchResults" [value]="searchResult">
              {{searchResult.description}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <agm-map #map
               [zoom]="zoom"
               [latitude]="lat"
               [longitude]="lon"
               (mapReady)="mapReady($event)"
               (zoomChange)="zoomChanged($event)">

        <agm-marker [latitude]="lat"
                    [longitude]="lon"
                    [markerDraggable]="true"
                    (dragEnd)="updatePosition($event)">
        </agm-marker>

      </agm-map>

      <div class='py-8' fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button
                color="accent"
                [disabled] = "dataSaver.isProcessing()"
                (click)='checkAndSave()'>{{buttonName | translate}}
        </button>
      </div>
    </form>
  </div>
</div>
