export const locale = {
  'DIALOG' : {
    'VEHICLE' : {
      'CREATE' : {
        'TITLE' : 'Создать авто',
        'CONFIRMBUTTON' : 'Создать',
      },
      'EDIT' : {
        'TITLE' : 'Изменить авто',
        'CONFIRMBUTTON' : 'Сохранить',
      },
      'APPROVE' : {
        'TITLE' : 'Утверждение авто',
        'CONFIRMBUTTON' : 'Утвердить',
        'DECLINEBUTTON' : 'Снять утверждение',
      },
      'MODEL' : 'Модель автомобиля',
      'MODEL_SELECT' : 'Выберите модель автомобиля',
      'CARRIER_SELECT': 'Выберите перевозчика',
      'NUMBER': 'Номер автомобиля',
      'TONNAGE': 'Тоннаж автомобиля',
      'INSPECTION_DATE': 'Срок действия тех. осмотра',
      'INSURANCE_DATE': 'Срок действия ОСАГО',
      'HINT' : {
        'VALID_NUMBER' : 'Пример: с065мк78, в777мс177',
      },
      'ERROR' : {
        'VEHICLE_EXISTS' : 'Автомобиль с таким номером уже существует',
        'NO_MODEL' : 'Модель не выбрана',
        'INVALID_NUMBER' : 'Номер должен быть в виде а111аа11 или а111аа111',
        'INVALID_TONNAGE' : 'Тоннаж должен быть от 1 до 99',
        'NO_CARRIER': 'Перевозчик не выбран',
        'UNDEFINED': 'Не удалось сохранить автомобиль. Попробуйте еще раз позже.',
      },
      'APPROVED_LOCK' : 'Редактирование заблокировано для утвержденных автомобилей',
    },
  },
};
