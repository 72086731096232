import { TableData } from 'app/components/table/tabledata';

import { LocalDataSource } from 'app/components/table/data_sources/local-data-source';
import { TranslateService } from '@ngx-translate/core';
import { ProposalColumns } from '../../helpers/proposal-columns-factory';

export class ProposalsByCarrierTableData extends TableData {
  constructor(
      private orders,
      private translate: TranslateService
    ) {
    super();
    
    let columns = [
      ProposalColumns.makeColumnTime(),
      ProposalColumns.makeColumnCount(),
      ProposalColumns.makeColumnBrand(),
      ProposalColumns.makeColumnCustomer(this.translate),
      ProposalColumns.makeColumnUnloadingAddress(),
      ProposalColumns.makeColumnEquipment(this.translate),
      ProposalColumns.makeColumnComment(),
      ProposalColumns.makeColumnInfo(this.translate),
    ];

    this.setColumns(columns);

    let tableDataSource = new LocalDataSource(this.orders ? this.orders : []);
    this.setProvider(tableDataSource);
  }

  canSortColumn(column) {
    return false;
  }
}
