import { locale as settings_en } from "./en/locale.settings";
import { locale as settings_ru } from "./ru/locale.settings";

import { locale as supplier_priority_en } from "./en/locale.supplier-priority";
import { locale as supplier_priority_ru } from "./ru/locale.supplier-priority";

export const settings = [
  {
    lang: "en",
    data: settings_en
  },
  {
    lang: "ru",
    data: settings_ru
  }
];

export const supplier_priority = [
  {
    lang: "en",
    data: supplier_priority_en
  },
  {
    lang: "ru",
    data: supplier_priority_ru
  }
];


