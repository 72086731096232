export const locale = {
  'DIALOG' : {
    'CREATEMODEL' : {
      'TITLE' : {
        'CREATE' : 'Create model',
        'EDIT' : 'Edit model',
      },
      'CONFIRMBUTTON' : {
        'CREATE' : 'Create',
        'SAVE' : 'Save',
      },
      'NAME' : 'Model name',
      'BRAND': 'Select brand',
    },
  },
};
