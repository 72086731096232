export const locale = {
  'USERS' : {
    'ROLE': {
      'Administrator': 'Администратор',
      'Logistician': 'Диспетчер',
      'Manager': 'Менеджер',
      'Dispatcher': 'Диспетчер перевозчика',
      'Customer': 'Покупатель',
      'Driver': 'Водитель',
      'Supplier': 'Поставщик',
    },
  }
};
