export const locale = {
  'DIALOG': {
    'TRAILER': {
      'CREATE': {
        'TITLE': 'Создать прицеп',
        'CONFIRMBUTTON': 'Создать',
      },
      'EDIT': {
        'TITLE': 'Изменить прицеп',
        'CONFIRMBUTTON': 'Сохранить',
      },
      'APPROVE': {
        'TITLE': 'Утверждение прицепа',
        'CONFIRMBUTTON': 'Утвердить',
        'DECLINEBUTTON': 'Снять утверждение',
      },
      'NUMBER': 'Номер прицепа',
      'TONNAGE': 'Тоннаж прицепа',
      'CARRIER_SELECT': 'Выберите перевозчика',
      'INSPECTION_DATE': 'Срок действия тех. осмотра',
      'HINT': {
        'VALID_NUMBER': 'Пример: ан733147, вм7771777',
      },
      'ERROR': {
        'TRAILER_EXISTS': 'Прицеп с таким номером уже существует',
        'INVALID_NUMBER': 'Номер должен быть в виде аа1111111 или аа111111',
        'INVALID_TONNAGE': 'Тоннаж должен быть от 1 до 99',
        'NO_CARRIER': 'Перевозчик не выбран',
        'UNDEFINED': 'Не удалось сохранить прицеп. Попробуйте еще раз позже.',
      },
      'APPROVED_LOCK': 'Редактирование заблокировано для утвержденных прицепов',
    },
  },
};
