
export const locale = {
  'ORDER': {
    'DIALOG': {
      'TITLE': {
        'CREATE': 'Создать заказ',
        'EDIT': 'Редактировать заказ',
      },
      'CONFIRMBUTTON': {
        'SAVE': 'Сохранить',
        'CREATE': 'Создать'
      },
      'CUSTOMER': 'Дилер, Покупатель',
      'SUPPLIER': 'Поставщик, Товар',
      'NEXT': 'Далее',
      'BACK': 'Назад',
      'CLOSE': 'Закрыть',

      "ERROR": {
        'VERIFY_ERROR': "Не удалось проверить покупателя.",
        'LOAD_ERROR': 'Ошибка загрузки',
        'CUSTOMER_NOT_CHECKED': 'Покупатель не проверен',
        'MUST_SELECT': 'Необходимо выбрать из списка',
        'TONNAGE_LIMIT': 'Должно быть 1-99',
        'NOT_LESS_THEN': 'Не меньше {{value}}',
        'NOT_GREATER_THEN': 'Не больше {{value}}',
        'INVALID_INTERVAL': "Неправильный интервал. 'Время с' больше 'до'",
        'TYPE_NOT_SELECTED': "Не выбран 'Вид'",
        'SAVE_ERROR': 'Не удалось сохранить заказ. Попробуйте позже.',
        'NO_DISTANCE': 'Нет подходящего километража',
        'NO_LOADING_POINT': 'Точка загрузки не выбрана',
      },
    },
  },
};
