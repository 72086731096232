
import { Person } from './person';
import { Customer } from './customer';
import { Supplier } from './supplier';

export interface OrderLimitation {
  create: boolean,
  update: boolean,
  salePrice: boolean,
  reserveOrder: boolean,
}

export class Carrier extends Person {
  constructor() { super("Carrier"); }

  getOrderPermissions(): OrderLimitation { 
    return super.get('orderPermissions');
  }

  getShowAllCustomers(): boolean { return super.get('showAllCustomers'); }
  setShowAllCustomers(value: boolean) { super.set('showAllCustomers', value); }
  getCustomersList(): Customer[] { return super.get('customers'); }
  setCustomersList(value: Customer[]) { super.set('customers', value)}
  
  getShowAllSuppliers(): boolean { return super.get('showAllSuppliers'); }
  setShowAllSuppliers(value: boolean) { return super.set('showAllSuppliers', value); }
  getSuppliersList(): Supplier[] { return super.get('suppliers'); }
  setSuppliersList(value: Supplier[]) { super.set('suppliers', value); }

  getMarkColor(): string { return super.get('markColor'); }
  setMarkColor(value: string): void { return super.set('markColor', value); }

  setOrderPermissions(limitations: OrderLimitation) {
    super.set('orderPermissions', {
      create: limitations.create,
      update: limitations.update,
      salePrice: limitations.salePrice,
      reserveOrder: limitations.reserveOrder,
    });
  }

  copy(): Carrier {
    let copy = new Carrier();
    copy.setName(this.getName());

    return copy;
  }
}