
import { Component, Inject, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './locale/en';
import { locale as russian } from './locale/ru';

import { DownloadService } from './download.service'

@Component({
  selector: 'download',
  templateUrl: './download.component.html'
})
export class DownloadComponent implements OnInit {

  private fileDataPromise: Promise<any>;
  private fileType: string;
  private fileName: string;

  public title: string;
  private progressText: string;
  private linkText: string;

  public URL: SafeResourceUrl;

  public prepearingData: boolean = false;

  constructor (
    @Inject(MAT_DIALOG_DATA) private data: any,
    private translationLoaderService: FuseTranslationLoaderService,
    private downloadService: DownloadService
  ) {
    this.fileDataPromise = data.fileDataPromise;
    this.fileType = data.fileType;
    this.fileName = data.fileName;

    this.title = data.title;
    this.progressText = data.progressText;
    this.linkText = data.linkText;

    this.translationLoaderService.loadTranslations(english, russian);
  }

  ngOnInit() {
    setTimeout(() => {
      this.prepearingData = true;
      this.downloadService.createLinkForDataPromise(this.fileDataPromise, this.fileType, true).then( url => {
        this.prepearingData = false;
        this.URL = url;
      });
    });
  }
}
