
import { Subject } from "rxjs";
/// <reference types="@types/googlemaps" />

import * as Location from "app/components/location/coordinate";
import * as Formatter from "./formatter";

declare const google: any;

export interface SearchResult {
  description: string;
  identifier: string;
}

export interface SearchDetails {
  name: string;
  address: string;
  coordinate: Location.Coordinate;
}

export class SearchService {

  results: Subject<SearchResult[]> = new Subject();

  private types: string[];
  private internal: google.maps.places.AutocompleteService;
  private placeService: google.maps.places.PlacesService;
  private sessionToken: google.maps.places.AutocompleteSessionToken;

  private running = false;
  private nextSearchText: string;
  private lastSearchText: string;

  constructor(attributionElement: any, types: string[]) {
    this.types = types;
    this.internal = new google.maps.places.AutocompleteService();
    if (attributionElement)
      this.placeService = new google.maps.places.PlacesService(attributionElement);
  }

  search(searchText: string): void {
    if (this.lastSearchText === undefined || searchText !== this.lastSearchText) {
      if (this.running) {
        this.nextSearchText = searchText;
      }
      else {
        this.running = true;
        this.startRequest(searchText);
      }
    }
  }

  getDetails(result: SearchResult): Promise<SearchDetails> {
    return new Promise((resolve, reject) => {
      this.placeService.getDetails(
        {
          placeId: result.identifier,
          fields: ["name", "address_components", "geometry.location"],
          sessionToken: this.sessionToken
        },
        (place, status) => {
          this.sessionToken = undefined;
          if (status == google.maps.places.PlacesServiceStatus.OK && place.geometry) {
            console.log("Details request succeeded");
            console.log(place);
            let name = place.name;
            let address = Formatter.formatAddress(place.address_components);
            let coordinate: Location.Coordinate = {latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng()};
            resolve({name: name, address: address, coordinate: coordinate});
          } else {
            console.log("Details request failed: " + status);
            reject(status);
          }
        }
      );
    });
  }

  static all(attributionElement: any): SearchService {
    return new SearchService(attributionElement, []);
  }

  static addresses(attributionElement: any): SearchService {
    return new SearchService(attributionElement, ["address"]);
  }

  static establishments(attributionElement: any): SearchService {
    return new SearchService(attributionElement, ["establishment"]);
  }

  private startRequest(searchText: string): void {
    if (this.sessionToken === undefined) {
      this.sessionToken = new google.maps.places.AutocompleteSessionToken();
    }

    this.lastSearchText = searchText;

    this.internal.getPlacePredictions(
      {
        input: searchText,
        types: this.types,
        sessionToken: this.sessionToken
      },
      (predictions, status) => this.finishRequest(predictions, status)
    );
  }

  private finishRequest(predictions: google.maps.places.AutocompletePrediction[], status: google.maps.places.PlacesServiceStatus): void {
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      let results = predictions.map(prediction => ({description: prediction.description, identifier: prediction.place_id}));
      this.results.next(results);
    } else {
      console.log("Address search failed: " + status);
      this.results.next([]);
    }

    if (this.nextSearchText !== undefined) {
      let searchText = this.nextSearchText;
      this.nextSearchText = undefined;
      this.startRequest(searchText);
    }
    else {
      this.running = false;
    }
  }

}
