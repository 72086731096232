
import { Person } from "./person";
import { ArticleBrand } from '../articles/article';
import { LoadingPoint } from '../points/points';

export class Supplier extends Person {
  constructor() { super("Supplier"); }

  getITN(): string {return super.get("ITN");}
  setITN(value: string): void {super.set("ITN", value);}
  
  getContacts() { return super.get('contacts'); }

  getLoadingPoints(): LoadingPoint[] { return super.get('loadingPoints');}
  addLoadingPoint(loadingPoint: LoadingPoint) {super.add('loadingPoints', loadingPoint);}
  removeLoadingPoint(loadingPoint: LoadingPoint) { super.remove('loadingPoints', loadingPoint);}

  isTransferPoint(): boolean { return super.get('transfer'); }
  setTransfer(value: boolean) { return super.set('transfer', value); }

  getBrands(): ArticleBrand[] { return super.get('articleBrands'); }
  setBrands(value: ArticleBrand[]): void { return super.set('articleBrands', value); }

  copy(): Supplier {
    let copy = new Supplier();
    copy.setName(this.getName());
    copy.setITN(this.getITN());
    return copy;
  }
}
