import { TranslateService } from '@ngx-translate/core';
import { ActionBarOperation } from 'app/components/action-bar/action-bar.component';
import { DialogPresenterService, DialogData } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import { OrderHelper } from 'app/components/helpers/order.helper';
import { TableOperationsHelper } from 'app/components/helpers/table-operations.helper';
import { Order } from 'app/components/data/core/models/order/order';
import { CurrentUser } from 'app/components/data/core/models/base/user';
import { TableOperationsFactory } from 'app/components/table-operations-controller/table-operations-controller';
import { DeclineProposalDialog } from '../dialog/decline/decline-proposal-dialog.component';
import { ProposalReservationByCarrierDialog } from '../dialog/reservation/by-carrier/proposal-reservation-by-carrier-dialog.component';

export class ProposalForCarriersTableOperationsFactory implements TableOperationsFactory {

    constructor(
        private dialogPresenter: DialogPresenterService, 
        private translate: TranslateService) { }

    makeOperations(selected) {
        return this.makeOperationsWithSelected(selected);
    }

    private makeOperationsWithSelected(selected) {
        let operations = [];
        let carrier = CurrentUser.carrier();
        let permissions = carrier.getOrderPermissions();
        if (permissions.reserveOrder) {
            operations.push(this.makeReserveProposalOperation(selected));
        }
        operations.push(this.makeDeclineProposalOperation(selected));
        return operations;
    }

    private makeDeclineProposalOperation(selected) {
        return new ActionBarOperation({
            title: 'PROPOSALS.ROWOPERATION.DECLINE',
            icon: 'highlight_off',
            action: () => { this.decline(selected) },
            disabled: TableOperationsHelper.noSelectedValues(selected)
        });
    }

    private makeReserveProposalOperation(selected) {
        return new ActionBarOperation({
            title: 'PROPOSALS.ROWOPERATION.RESERVATION',
            icon: 'assignment_turned_in',
            action: () => { this.reserve(selected[0]) },
            disabled: TableOperationsHelper.notSingleSelection(selected)
        });
    }

    private reserve(order) {
        let dialogData: DialogData = {
            dialog: ProposalReservationByCarrierDialog,
            panel: 'proposal-reservation-by-carrier-dialog-container',
            data: { object: order },
        };
        this.dialogPresenter.showDialog(dialogData);
    }

    private decline(orders: Order[]) {
        let filtered = orders.filter(o => {
            return OrderHelper.isReserved(o);
        });
        if (filtered.length == 0) {
            this.dialogPresenter.showAlert({
                title: this.translate.instant("PROPOSALS.ALERT.TITLE.WARNING"),
                content: this.translate.instant("PROPOSALS.ALERT.MESSAGE.NO_RESERVED_TO_DECLINE"),
            });
        } else {
            let dialogData: DialogData = {
                dialog: DeclineProposalDialog,
                panel: 'decline-proposal-dialog-container',
                data: { object: filtered },
            };
            this.dialogPresenter.showDialog(dialogData);
        }
    }
}