import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { TableComponent } from './table.component'
import { MaterialModule } from 'app/main/material.module';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CustomerSelectModule } from 'app/main/customer/customer-select/customer-select.module';
import { HierarchicalCheckboxModule } from 'app/components/hierarchical-checkbox/hierarchical-checkbox.module';
import {MatDialogModule} from "@angular/material/dialog";

@NgModule({
    declarations: [
        TableComponent,
    ],
    imports     : [
        MaterialModule,
        MatDialogModule,
        TranslateModule,
        FuseSharedModule,
        DragDropModule,
        CustomerSelectModule,
        HierarchicalCheckboxModule,
    ],
    exports     : [
        TableComponent,
    ],
})

export class TableModule
{
}
