<div>
    <mat-form-field fxFlex>
        <input
                type="text"
                matInput
                [placeholder]="placeholder"
                [matAutocomplete]="auto"
                [formControl]="control"
                [required]="required"/>
        <mat-icon matSuffix>arrow_drop_down</mat-icon>
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let item of filteredItems | async" [value]="item">
                {{ displayFn(item) }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>
