export const locale = {
    'DISBAND': {
      'TITLE' : 'Освободить грузовик',
      'MESSAGE' : 'Вы действительно хотите освободить этот грузовик?',
      'UNIT' : 'Водитель: {{driverName}}, {{vehicleModel}} {{vehicleNumber}}',
      'CONFIRM' : 'Да, освободить',
      'DECLINE' : 'Отмена',
      'ERROR': 'Освобождение грузовика не удалось',
      'EXECUTING': 'Освобождение грузовиков {{cur}}/{{total}}',
    },
};
