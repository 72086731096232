export const locale = {
  'UNITS' : {
    'TABLEROW': {
        'UNIT': 'Unit',
        'DRIVER': 'Driver',
        'STATUS': 'Status',
        'UNITNUMBER': 'Unit number',
        'TRAILERNUMBER': 'Trailer number',
        'SPEED': 'Speed',
        'TONNAGE' : 'Tonnage',
        'LASTSEEN' : 'Last seen',
        'PROBLEM' : 'Problem',
        'ORDERNUMBER' : 'Order number',
        'POSITION': 'Position',
        'PASS': 'Pass',
    },
    'STATUS' : {
      '0' : 'Not ready',
      '1' : 'Ready',
      '2' : 'Broken',
      '3' : 'Invisible',
      '4' : 'Working',
      '5' : 'Resting',
      '6' : 'Under repair',
      '90': 'Undefined',
      'WITHOUT_APP': 'Without app',
      '-1': 'All',
    },
  }
};
