
/// <reference types="@types/googlemaps" />
import * as Localizable from "./localizable";

export function formatAddress(components: google.maps.GeocoderAddressComponent[]): string {
  let administrativeArea = findAddressComponent(components, "administrative_area_level_1");
  let locality = findAddressComponent(components, "locality");
  let route = findAddressComponent(components, "route");
  let streetNumber = findAddressComponent(components, "street_number");

  if (!administrativeArea && !locality && !route && !streetNumber) {
    return undefined;
  }

  if (administrativeArea && locality && administrativeArea.short_name === locality.short_name) {
    administrativeArea = undefined;
  }

  let administrativeAreaName;
  let localityName;
  let routeName;
  let streetNumberName;

  if (administrativeArea) {administrativeAreaName = Localizable.getAdministrativeAreaName(administrativeArea);}
  if (locality) {localityName = locality.short_name;}
  if (route) {routeName = route.short_name;}
  if (streetNumber) {streetNumberName = streetNumber.short_name;}

  return [administrativeAreaName, localityName, routeName, streetNumberName].filter(Boolean).join(", ");
}

function findAddressComponent(addressComponents: google.maps.GeocoderAddressComponent[], type: string) {
  return addressComponents.find((addressComponent) => {
    return addressComponent.types.includes(type);
  });
}
