
import { TableData, TableRowData, RowParameters, TableSort } from 'app/components/table/tabledata';

import { TranslateService } from '@ngx-translate/core';
import { ServerTableDataSource } from 'app/components/table/data_sources/server-data-source';
import { LiveQuerySubscriber } from 'app/components/table/data_sources/live-query-subscriber';
import { Message } from 'app/components/data/core/models/message';
import { TargetsHelper } from './helpers/target.helper';
import { User } from 'app/components/data/core/models/base/user';

export class MessagesTableData extends TableData {

  constructor(
    private translate: TranslateService
  ) {
    super();
    let handler = this;

    let columns = [
      new TableRowData({
        key: 'date', 
        header: 'MESSAGES.TABLEROW.DATE', 
        cell: (message: Message) => `${handler.dateStr(message)}`, 
        params: new RowParameters('15')
      }),
      new TableRowData({
        key: 'type', 
        header: 'MESSAGES.TABLEROW.TITLE', 
        cell: (message: Message) => message.getTitle(), 
      }),
      new TableRowData({
        key: 'description', 
        header: 'MESSAGES.TABLEROW.BODY', 
        cell: (message: Message) => message.getBody(), 
      }),
      new TableRowData({
        key: 'target', 
        header: 'MESSAGES.TABLEROW.TARGET', 
        cell: (message: Message) => `${handler.getTarget(message)}`, 
      }),
    ];

    this.setColumns(columns);
    let includes = [
      'user.manager',
      'user.dispatcher',
      'user.driver',
      'user.supplier',
      'user.customer',
    ]

    let sort: TableSort = new TableSort('date','desc');
    let tableDataSource = new ServerTableDataSource('Message', includes);
    tableDataSource.setDefaultSort(sort)
    this.setProvider(tableDataSource);
  }

  private getTarget(message: Message) {
    let role = message.getRole();
    let user = message.getUser();
    if (role != undefined) {
      return TargetsHelper.localizedTargetRole(role, this.translate);
    } else if (user != undefined) {
      return this.getUserName(user);
    }
    return this.translate.instant('MESSAGES.TARGET.ALL');
  }
  private dateStr(message: Message) {
    let date = message.getDate();
    return date ? date.toLocaleString() : '';
  }

  private getUserName(user: User) {
    return TargetsHelper.makeTargetForUser(user, this.translate).name;
  }
}
