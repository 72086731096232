import { DialogPresenterService, DialogData } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import { DriverDialog } from 'app/main/admin/users/dialogs/driver/create/driver-dialog.component';
import { DriverApproveDialog } from 'app/main/admin/users/dialogs/driver/approve/driver-approve-dialog.component';
import { TableOperationActionType, TableOperationsHelper } from 'app/components/helpers/table-operations.helper';
import { TableOperationsFactory } from 'app/components/table-operations-controller/table-operations-controller';
import { ActionBarOperation } from 'app/components/action-bar/action-bar.component';

import { CurrentUser, Role } from 'app/components/data/core/models/base/user';
import { UsersTableHelper } from '../helpers/users-table-operations.helper';

export class DriversTableOperationsFactory implements TableOperationsFactory {

    constructor(
        private dialogPresenter: DialogPresenterService,
        private completion
    ) { }

    makeOperations(selected) {
        return this.makeOperationsWithSelected(selected);
    }

    makeDefaultOperation(selected) {
        return TableOperationsHelper.makeOperationWithType(
            TableOperationActionType.Edit,
            this.dialogPresenter,
            () => this.makeDialogData(),
            selected);
    }

    private makeOperationsWithSelected(selected) {
        let types = [
            TableOperationActionType.Add,
            TableOperationActionType.Edit,
        ];
        let operations = TableOperationsHelper.makeOperationsWithTypes(
            types,
            this.dialogPresenter,
            () => this.makeDialogData(),
            selected,
            this.completion
        );
        if (CurrentUser.isAdministrator() || CurrentUser.isLogistician()) {
            operations.push(this.makeCheckLimitationsOperation(selected));
        }
        operations.push(
            UsersTableHelper.makeCreateUserCredentialsOperation(
                this.dialogPresenter,
                selected,
                Role.Driver
            )
        );
        operations.push(
            TableOperationsHelper.makeOperationWithType(
                TableOperationActionType.Remove,
                this.dialogPresenter,
                () => UsersTableHelper.makeRemoveUserDialodData(Role.Driver),
                selected,
                this.completion
            )
        );
        return operations;
    }

    private makeDialogData(): DialogData {
        return {
            dialog: DriverDialog,
            panel: 'driver-dialog-container',
            data: {}
        }
    }

    private makeCheckLimitationsOperation(selected) {
        let dialogData: DialogData = {
            dialog: DriverApproveDialog,
            panel: 'driver-approve-dialog-container',
            data: { object: selected[0], disableClose: false }
        };
        return new ActionBarOperation({
            title: 'USERS.TABS.DRIVERS.ROWOPERATION.APPROVE',
            icon: 'check_circle',
            action: () => { this.dialogPresenter.showDialog(dialogData) },
            disabled: TableOperationsHelper.notSingleSelection(selected)
        });
    }
}