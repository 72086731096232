
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { MaterialModule } from 'app/main/material.module';

import { CreateContactDialog } from './create/create-contact-dialog.component';
import { UnloadingPointContactsDialog } from './manage/unloading-point-contacts-dialog.component';

import { DialogHeaderModule } from 'app/components/dialogs/dialog-header/dialog-header.module';
import { ActionBarModule } from 'app/components/action-bar/action-bar.module';
import { TableModule } from 'app/components/table/table.module';

@NgModule({
    declarations: [
        CreateContactDialog,
        UnloadingPointContactsDialog,
    ],
    imports: [
        TranslateModule,
        FuseSharedModule,
        MaterialModule,
        DialogHeaderModule,
        ActionBarModule,
        TableModule,
    ],
    exports: [
        CreateContactDialog,
        UnloadingPointContactsDialog,
    ]
})
export class ContactsModule {
}
