<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="warn" 
                 title="{{'PROPOSALS.DIALOG.DECLINE.TITLE' | translate}}"
                 (clickAction)="close()">
  </dialog-header>
  
  <div class="py-8 px-16 content" fxLayout="column">
    <div fxFlex>
      <p>{{'PROPOSALS.DIALOG.DECLINE.MESSAGE' | translate}}</p>
      <div *ngFor="let order of orders">
        <p>{{order.supplier().getName()}} {{order.articleBrand().name()}} {{ 'PROPOSALS.DIALOG.DECLINE.TONNAGE' | translate:{'value': order.tonnage()} }}</p>
      </div>
    </div>
    <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button
              color="warn"
              (click)="execute()"
              [disabled]="isExecuting()"
              cdkFocusInitial>{{'PROPOSALS.DIALOG.DECLINE.CONFIRMBUTTON' | translate}}
      </button>
    </div>
  </div>
</div>
