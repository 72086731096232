export const locale = {
  'MAIN': {
      'TITLE' : 'Главная',
      'TAB' : {
        'UNITS' : 'Авто',
        'ORDERS' : 'Заказы в процессе',
      },
      'WIDGET' : {
        'PROBLEMTRIPS' : 'Проблемных авто',
        'READYTRANSPORTUNITS' : 'Готовых к работе',
        'UNDISTRIBUTEDORDERS' : 'Нераспределено',
      },
      'PROBLEM' : {
        'BREAKAGE' : 'Сломался',
        'INACTIVITY' : 'Нет связи',
        'DELAY' : 'Задержка этапа',
        'STOPPAGE' : 'Простаивает',
      },
  },
  'ORDER': {
    'DIALOG' : {
      'CANCELCONFIRM' : {
        'TITLE' : 'Отменить заказ',
        'MESSAGE' : 'Вы уверены, что хотите отменить эти заказы?',
        'CONFIRM' : 'Да, отменить',
        'DECLINE' : 'Закрыть',
        'ORDERNUMBER' : 'Заказ №',
      },
    },
  },
};
