import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'date-range-selector',
  templateUrl: './date-range-selector.component.html',
})
export class DateRangeSelectorComponent {

  @Input()
  disabled: boolean = false

  @Input()
  disableInput: boolean

  @Input()
  hideReset: boolean

  @Input()
  range: DateRange<Moment>

  @Input()
  appearance: string

  @Output()
  rangeChange = new EventEmitter<DateRange<Moment>>();

  rangeForm: FormGroup
  private lastFilterRange = new DateRange<Moment>(null, null);

  constructor() { }

  ngOnInit() {
    this.rangeForm = new FormGroup({
      start: new FormControl({ value: this.range ? this.range.start : null, disabled: this.disableInput }),
      end: new FormControl({ value: this.range ? this.range.end : null, disabled: this.disableInput }),
    });
  }

  ngAfterViewInit() {
    this.rangeForm
      .valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((range: DateRange<Moment>) => {
        this.applyRange(range);
      })
  }

  reset() {
    this.applyRange(new DateRange<Moment>(null, null))
    this.rangeForm.controls.start.setValue(null);
    this.rangeForm.controls.end.setValue(null);
  }

  private applyRange(range: DateRange<Moment>) {
    if (range.start == this.lastFilterRange.start && range.end == this.lastFilterRange.end) {
      return
    }
    
    this.lastFilterRange = range;
    this.rangeChange.emit(range)
  }
}