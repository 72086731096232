<div class="navbar-vertical" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

    <div class="navbar-header">

        <div class="logo">
            <img class="logo-icon" src="assets/images/logos/cargodeal.svg">
            <span class="logo-text">{{"CRD Logistic" | translate}}</span>
        </div>

        <button mat-icon-button class="toggle-sidebar-folded"
                (click)="toggleSidebarFolded()" fxHide.lt-lg>
            <mat-icon>menu</mat-icon>
        </button>

        <button mat-icon-button class="toggle-sidebar-opened"
                (click)="toggleSidebarOpened()" fxHide.gt-md>
            <mat-icon>arrow_back</mat-icon>
        </button>

    </div>

    <div class="user-section" [fxShow]="isAuthorized()">
        <button mat-icon-button (click)="toggleSettings()">
            <mat-icon>settings</mat-icon>
        </button>
        <div class="user-title">
            <span class="h3 text-center">{{ getUserName() }}</span>
            <span class="h5 hint-text text-center">{{ getRole() | translate}}</span>
        </div>
        <button mat-icon-button (click)="logOutUser()">
            <mat-icon>logout</mat-icon>
        </button>
    </div>

    <div class="navbar-content pt-16" fusePerfectScrollbar
         [fusePerfectScrollbarOptions]="{suppressScrollX: true}"
         [ngClass]="fuseConfig.layout.navbar.primaryBackground">
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
    </div>

    <div class="px-16"
         [ngClass]="fuseConfig.layout.navbar.primaryBackground">
         <p style="color: silver">Version {{version}}</p>
    </div>

</div>
