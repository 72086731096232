export const locale = {
  'DIALOG': {
    'VEHICLE': {
      'CREATE': {
        'TITLE': 'Create vehicle',
        'CONFIRMBUTTON': 'Create',
      },
      'EDIT': {
        'TITLE': 'Edit vehicle',
        'CONFIRMBUTTON': 'Save',
      },
      'APPROVE': {
        'TITLE': 'Approve vehicle',
        'CONFIRMBUTTON': 'Approve',
        'DECLINEBUTTON': 'Cancel approval',
      },

      'MODEL': 'Vehicle model',
      'MODEL_SELECT': 'Select vehicle model',
      'CARRIER_SELECT': 'Select carrier',
      'NUMBER': 'Vehicle number',
      'TONNAGE': 'Vehicle tonnage',
      'INSPECTION_DATE': 'Inspection date',
      'INSURANCE_DATE': 'Insurance date',
      'HINT': {
        'VALID_NUMBER': 'Ex: а111аа001',
      },
      'ERROR': {
        'VEHICLE_EXISTS': 'Vehicle with that number exists',
        'NO_MODEL': 'Model not selected',
        'INVALID_NUMBER': 'Invalid number. Must be like this: а111аа001',
        'INVALID_TONNAGE': 'Invalid tonnage. Must be from 1 to 99',
        'NO_CARRIER': 'Carrier not selected',
        'UNDEFINED': 'Something went wrong. Try again later.',
      },
      'APPROVED_LOCK': 'Editing forbidden for approved vehicles',
    },
  },
};
