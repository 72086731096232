export const locale = {
  'ORDERINFO': {
    'TITLE': 'Info about order #',
    'MAIN': 'Main info',
    'PROGRESS': 'Progress',
    'DISTRIBUTED': 'Distributed tonnage',
    'TABLEROW': {
      'TONNAGE': 'Tonnage',
      'DRIVER': {
        'NAME': 'Driver',
        'PHONE': 'Phone'
      },
      'AUTO': 'Auto',
      'AUTONUMBER': 'Vehicle number',
      'TRAILER_NUMBER': 'Trailer number',
      'STATE': 'State',
      'PHONE_NUMBER': 'Phone number',
    },
    'NO_OFFERS': "This order has no data for now",
    'COMMENT': "Comment",
    'PHOTONOTUPLOADED': 'Photo not yet uploaded',
    'SHOWONMAP': 'Show on map',
    'DOWNLOADTRACK': 'Download track',
    'ORDER_TRACK': 'Track for order #',
    'TONNAGE_INFO': 'Actual tonnage: {{actualTonnage}} tonns',
    'KILOMETERS_INFO': 'Distance {{distance}} km',
    'WAYBILL_INFO': 'Invoice number: {{number}}',
    'DIALOG': {
      'FIELD_EDIT': {
        'TITLE': 'Edit value',
        'TONNAGE': 'Tonnage',
        'KILOMETERS': 'Kilometers',
        'WAYBILL_NUMBER': 'Waybill number',
        'SAVE': 'Save',
        'ERROR': {
          'LIMIT': 'Must be more than 0',
        },
      },
    },
    'ERROR' : {
      'WRONG_COORDINATE' : 'Coordinates do not match planned',
    },
  },
};
