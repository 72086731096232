import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { CloudExecutor } from 'app/components/data/cloud-executors/cloud-executor';
import { ArticleType } from 'app/components/data/core/models/articles/article';

import { LocaleService } from 'app/components/locale/locale.service';
import { createtype } from '../../locale/locale';
import * as FormHelper from 'app/components/form-helper/form-helper';

@Component({
    selector   : 'create-article-type-dialog',
    templateUrl: './create-article-type-dialog.component.html',
    styleUrls  : ['./create-article-type-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CreateArticleTypeDialog {

  public form: UntypedFormGroup;
  private object: ArticleType;

  public title: string;
  public buttonName: string;

  public dataSaver = new CloudExecutor();

  constructor (
    private localeService: LocaleService,
    public dialogRef: MatDialogRef<CreateArticleTypeDialog>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: UntypedFormBuilder
  ) {
    this.localeService.loadLocale(createtype);
    this.object = this._data.object;
    if (!this.object) {
      this.object = new ArticleType();
    }

    if (this._data.action === 'create') {
        this.title = 'CREATETYPE.TITLE.CREATE';
        this.buttonName = 'CREATETYPE.CONFIRMBUTTON.CREATE';
    } else if (this._data.action === 'edit') {
        this.title = 'CREATETYPE.TITLE.EDIT';
        this.buttonName = 'CREATETYPE.CONFIRMBUTTON.SAVE';
    }
    this.form = this.createContactForm();
  }

  createContactForm(): UntypedFormGroup {
    return this._formBuilder.group({
                name       : [this.object.name()],
              });
  }

  checkAndSave() {
    let verified = this.checkForm();

    if (verified) {
      this.object.setName(this.form.controls.name.value);
      this.dataSaver.saveObject(this.object).then(obj => {
        this.dialogRef.close(obj);
      });
    }
  }

  checkForm() : boolean {
    return FormHelper.checkForm(this.form);
  }
}
