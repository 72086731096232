import {locale as cancelconfirm_en} from './en/locale.cancelconfirm';
import {locale as cancelconfirm_ru} from './ru/locale.cancelconfirm';

import {locale as decline_order_en} from './en/locale.decline_order';
import {locale as decline_order_ru} from './ru/locale.decline_order';

import {locale as deleteconfirmation_en} from './en/locale.deleteconfirmation';
import {locale as deleteconfirmation_ru} from './ru/locale.deleteconfirmation';

import {locale as finished_en} from './en/locale.finished';
import {locale as finished_ru} from './ru/locale.finished';

import {locale as inprogress_en} from './en/locale.inprogress';
import {locale as inprogress_ru} from './ru/locale.inprogress';

import {locale as order_dialog_en} from './en/locale.order-dialog';
import {locale as order_dialog_ru} from './ru/locale.order-dialog';

import {locale as orderinfo_en} from './en/locale.orderinfo';
import {locale as orderinfo_ru} from './ru/locale.orderinfo';

import {locale as orderlist_en} from './en/locale.orderlist';
import {locale as orderlist_ru} from './ru/locale.orderlist';

import {locale as ordersonmap_en} from './en/locale.ordersonmap';
import {locale as ordersonmap_ru} from './ru/locale.ordersonmap';

import {locale as transfer_en} from './en/locale.transfer';
import {locale as transfer_ru} from './ru/locale.transfer';

import {locale as row_operations_en} from './en/locale.row.operations';
import {locale as row_operations_ru} from './ru/locale.row.operations';

import {locale as order_fields_en} from './en/locale.order-fields';
import {locale as order_fields_ru} from './ru/locale.order-fields';

import {locale as finish_order_en} from './en/locale.finish-order';
import {locale as finish_order_ru} from './ru/locale.finish-order';

import {locale as no_procuratory_en} from './en/locale.no_procuratory';
import {locale as no_procuratory_ru} from './ru/locale.no_procuratory';

import {locale as retransfer_en} from './en/locale.retransfer';
import {locale as retransfer_ru} from './ru/locale.retransfer';

import {locale as proposals_en} from './en/locale.proposals';
import {locale as proposals_ru} from './ru/locale.proposals';

import {locale as unit_transport_problem_en} from './en/locale.unit-transport-problem';
import {locale as unit_transport_problem_ru} from './ru/locale.unit-transport-problem';

import {locale as all_orders_en} from './en/locale.allorders';
import {locale as all_orders_ru} from './ru/locale.allorders';

import {locale as consistency_en} from './en/locale.consistency';
import {locale as consistency_ru} from './ru/locale.consistency';

export const all_orders = [
    {
        lang: 'en',
        data: all_orders_en
    },
    {
        lang: 'ru',
        data: all_orders_ru
    },
];
export const consistency = [
    {
        lang: 'en',
        data: consistency_en
    },
    {
        lang: 'ru',
        data: consistency_ru
    },
];

export const finish_order = [
    {
        lang: 'en',
        data: finish_order_en
    },
    {
        lang: 'ru',
        data: finish_order_ru
    },
];

export const no_procuratory = [
    {
        lang: 'en',
        data: no_procuratory_en
    },
    {
        lang: 'ru',
        data: no_procuratory_ru
    },
];

export const order_fields = [
    {
        lang: 'en',
        data: order_fields_en
    },
    {
        lang: 'ru',
        data: order_fields_ru
    },
];

export const cancelconfirm = [
    {
        lang: 'en',
        data: cancelconfirm_en
    },
    {
        lang: 'ru',
        data: cancelconfirm_ru
    },
];

export const decline_order = [
    {
        lang: 'en',
        data: decline_order_en
    },
    {
        lang: 'ru',
        data: decline_order_ru
    },
];

export const deleteconfirmation = [
    {
        lang: 'en',
        data: deleteconfirmation_en
    },
    {
        lang: 'ru',
        data: deleteconfirmation_ru
    },
];

export const finished = [
    {
        lang: 'en',
        data: finished_en
    },
    {
        lang: 'ru',
        data: finished_ru
    },
];

export const inprogress = [
    {
        lang: 'en',
        data: inprogress_en
    },
    {
        lang: 'ru',
        data: inprogress_ru
    },
];

export const order_dialog = [
    {
        lang: 'en',
        data: order_dialog_en
    },
    {
        lang: 'ru',
        data: order_dialog_ru
    },
];

export const orderinfo = [
    {
        lang: 'en',
        data: orderinfo_en
    },
    {
        lang: 'ru',
        data: orderinfo_ru
    },
];

export const orderlist = [
    {
        lang: 'en',
        data: orderlist_en
    },
    {
        lang: 'ru',
        data: orderlist_ru
    },
];

export const ordersonmap = [
    {
        lang: 'en',
        data: ordersonmap_en
    },
    {
        lang: 'ru',
        data: ordersonmap_ru
    },
];

export const transfer = [
    {
        lang: 'en',
        data: transfer_en
    },
    {
        lang: 'ru',
        data: transfer_ru
    },
];

export const orders_row_operations = [
    {
        lang: 'en',
        data: row_operations_en
    },
    {
        lang: 'ru',
        data: row_operations_ru
    },
];

export const retransfer = [
    {
        lang: 'en',
        data: retransfer_en
    },
    {
        lang: 'ru',
        data: retransfer_ru
    },
];

export const proposals = [
    {
        lang: 'en',
        data: proposals_en
    },
    {
        lang: 'ru',
        data: proposals_ru
    },
];

export const unit_transport_problem = [
    {
        lang: 'en',
        data: unit_transport_problem_en
    },
    {
        lang: 'ru',
        data: unit_transport_problem_ru
    },
];

