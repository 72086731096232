import { NgModule } from '@angular/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { MainComponent } from './main.component';
import { MaterialModule } from 'app/main/material.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    MainComponent
  ],
  imports: [
    TranslateModule,
    FuseSharedModule,
    MaterialModule,
  ],
  exports: [
    MainComponent,
  ],
})

export class MainModule { }
