export const locale = {
  'ONMAP': {
      'TITLE' : 'Авто на карте',
      'FILTER' : {
        'READYTOWORK' : "Только \"Готовые к работе\"",
        'TUSTATUSPLASEHOLDER' : 'Статус',
        'TUGROUPPLASEHOLDER' : 'Группа ТС',
      },
      'GROUP' : {
        'ALL' : 'Все',
      },
      'LABEL' : {
        'STATUS' : 'Фильтр статуса',
        'GROUP' : 'Фильтр группы ТС',
      },
  },
};
