
export const locale = {
  'DIALOG': {
    'CARRIER': {
      'TITLE': {
        'CREATE': 'Создать перевозчика',
        'EDIT': 'Редактировать перевозчика',
      },
      'CONFIRMBUTTON': {
        'CREATE': 'Создать',
        'SAVE': 'Сохранить',
      },
      'PICK_COLOR': 'Цвет метки',
      'FULLNAME': 'Название',
      'LIMITATIONS': {
        'TITLE': 'Ограничения',
        'MESSAGE': "Изменить действия с заказами для '{{value}}'?",
        'SAVE': 'Сохранить',
        'CREATE': 'Разрешить создание заказов',
        'EDIT': 'Разрешить изменение заказов',
        'SALE_PRICE': 'Разрешить видеть цену продажи/доставки',
        'RESERVE': 'Разрешить бронировать заявки',
        'LIST': {
          'ALLOW_MESSAGE': 'Разрешить перевозчику видеть',
          'CUSTOMERS': 'Ограничить покупателей',
          'SUPPLIERS': 'Ограничить поставщиков',
          'STYLE': {
            'ALL': 'Всех',
            'LIST': 'Список',
            'NONE': 'Никого',
          },
        },
      },
    },
  },
};
