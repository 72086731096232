export const locale = {
    'DIALOG': {
        'PASS': {
            'TITLE': {
                'CREATE': 'Создать пропуск',
                'EDIT': 'Изменить пропуск',
            },
            'CONFIRMBUTTON': {
                'CREATE': 'Создать',
                'SAVE': 'Сохранить',
            },
            'ZONE': 'Вид пропуска',
            'TIME_OF_ACTION': 'Время действия',
            'DATES': 'Период действия',
            'NUMBER': 'Номер',
        },
    },
};
