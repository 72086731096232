import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/material.module';

import { VehicleDialog } from './create/vehicle-dialog.component';
import { VehicleApproveDialog } from './approve/vehicle-approve-dialog.component';
import { DialogHeaderModule } from 'app/components/dialogs/dialog-header/dialog-header.module';
import { AttachmentModule } from 'app/components/attachment/attachment.module';
import { VehicleEditPassDialog } from './pass/vehicle-edit-passes-dialog.component';
import { TableModule } from 'app/components/table/table.module';
import { ActionBarModule } from 'app/components/action-bar/action-bar.module';
import { PassDialog } from './pass/pass/pass-dialog.component';
import { DateRangeSelectorModule } from 'app/components/date-range-selector/date-range-selector.module';

@NgModule({
    declarations: [
        VehicleDialog,
        VehicleApproveDialog,
        VehicleEditPassDialog,
        PassDialog,
    ],
    imports: [
        TranslateModule,
        FuseSharedModule,
        MaterialModule,
        DialogHeaderModule,
        AttachmentModule,
        TableModule,
        ActionBarModule,
        DateRangeSelectorModule,
    ],
    exports: [
        VehicleDialog,
        VehicleApproveDialog,
        VehicleEditPassDialog,
        PassDialog,
    ]
})

export class VehicleDialogModule {
}
