import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TransportUnit } from 'app/components/data/core/models/transport/transport';

import { LocaleService } from 'app/components/locale/locale.service';
import { disband } from '../../locale/locale';
import { DispatcherCloudExecutor } from 'app/components/data/cloud-executors/dispatcher-cloud-executor';
import { TranslateService } from '@ngx-translate/core';
import { LocalNotificationService } from 'app/components/local-notifications-service/local-notifications-service';

@Component({
  selector: 'disband-unit-dialog',
  templateUrl: './disband-unit-dialog.component.html',
  styleUrls: ['./disband-unit-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DisbandUnitDialogComponent {
  public units: TransportUnit[];
  disbandingUnit: TransportUnit;
  executor = new DispatcherCloudExecutor;
  constructor(
    private notifications: LocalNotificationService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<DisbandUnitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) _data: any,
    private localeService: LocaleService
  ) {
    this.localeService.loadLocale(disband);
    this.units = _data.units;
  }

  driverName(transportUnit: TransportUnit): string {
    return transportUnit.driver().getName();
  }

  vehicleModel(transportUnit: TransportUnit): string {
    return transportUnit.vehicle().model().fullName();
  }

  vehicleNumber(transportUnit: TransportUnit): string {
    let vehicle = transportUnit.vehicle();
    let trailer = transportUnit.trailer();
    if (trailer) {
      return vehicle.number() + "-" + trailer.number();
    } else {
      return vehicle.number();
    }
  }

  curIndex() {
    var index = this.units.indexOf(this.disbandingUnit);
    if (index == -1) {
      index = 0;
    }
    return index;
  }

  disband() {
    let promise = Promise.resolve();

    this.units.forEach((unit) => {
      promise = promise.then(() => {
        this.disbandingUnit = unit;
        return this.executor.disbandTransportUnit(unit);
      })
    });

    promise.then(() => this.dialogRef.close(true))
      .catch(() => {
        let localizedMessage = this.translate.instant('DISBAND.ERROR');
        this.notifications.showErrorNotification(localizedMessage);
      });
  }
}
