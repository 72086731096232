
import {Injectable} from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY'
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  },
};

@Injectable()
export class CustomeDateAdapter extends MomentDateAdapter {

  getFirstDayOfWeek(): number {
    return 1;
  }

}