<div class="page-layout carded fullwidth">
  <div class="top-bg primary">
  </div>
  <div class="center">
    <table-header headerTitle="{{'ARTICLE.TITLE' | translate}}" 
                  iconName="local_mall" 
                  searchBarTitle="COMMON.SEARCHTITLE"
                  [buttons]="headerButtons"
                  (onSearch)="search($event)">
    </table-header>
    <div class="mat-elevation-z8 fuse-white mb-16" fusePerfectScrollbar>
      <mat-tab-group (selectedIndexChange)="tabChanged()" fxFlex>
        <mat-tab *ngFor="let tabInfo of tabsList">
          <ng-template mat-tab-label>
            {{tabInfo.title | translate}}
          </ng-template>
          <div fxLayout="column" fxFlex>
            <action-bar [operations]="tabInfo.tableOperations" class="sticky fuse-white">
            </action-bar>
            <cd-table [tabledata]="tabInfo.tableData"
                      (rowDoubleClick)="tabInfo.doubleClickHandler($event)"
                      (onSelectionChange)="tabInfo.updateOperations($event.table)">
            </cd-table>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
