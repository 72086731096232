export const locale = {
    'PROPOSALS': {
        'DIALOG': {
            'CREATE' : {
                'TITLE': {
                    'CREATE': 'Create proposal',
                    'EDIT': 'Edit proposal',
                },
                'CONFIRMBUTTON': {
                    'CREATE': 'Create',
                    'SAVE': 'SAVE',
                },
                'CONTROLS': {
                    'SUPPLIER_SELECTOR': 'Supplier',
                    'BRAND_SELECTOR': 'Brand',
                    'TYPE_SELECTOR': 'Type',
                    'UNLOADING_DATE': 'Unloading date',
                    'UNLOADING_TIME': 'Time',
                    'TONNAGE': 'Tonnage',
                    'COUNT': 'Count',
                    'CUSTOMER_SELECTOR': 'Customer',
                    'LOADING_ADDRESS_SELECTOR': 'Loading address',
                    'UNLOADING_ADDRESS_SELECTOR': 'Unloading address',
                    'UNLOADING_CONTACT': 'Unloading contact',
                    'COMMENT': 'Comment',
                },
                'ERROR': {
                    'LOAD_ERROR': 'Load error',
                    'MUST_SELECT': 'You need to select item from list',
                },
            },
        },
    },
};
