import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { LocaleService } from 'app/components/locale/locale.service';
import { trailer } from '../../../locale/locale';

import { PFObject } from 'app/components/data/core/models/base/object';

import { Trailer } from 'app/components/data/core/models/transport/transport';

import * as CustomValidators from 'app/components/validators/validators';
import { CloudExecutor } from 'app/components/data/cloud-executors/cloud-executor';

@Component({
    selector   : 'trailer-approve-dialog',
    templateUrl: './trailer-approve-dialog.component.html',
    styleUrls  : ['./trailer-approve-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class TrailerApproveDialog {
  compareFunction = PFObject.compareFn;

  public form: UntypedFormGroup;
  private object: Trailer;

  public dataSaver = new CloudExecutor;

  constructor (
    private localeService: LocaleService,
    public dialogRef: MatDialogRef<TrailerApproveDialog>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: UntypedFormBuilder
  ) {
    this.localeService.loadLocale(trailer);
    this.object = this._data.object;
    this.form = this.createContactForm();
  }

  createContactForm(): UntypedFormGroup
  {
    let number = this.object.number();
    let tonnageValidators = [Validators.min(1), Validators.max(99)];
    
    return this._formBuilder.group({
                number: [{value: number, disabled: true}],
                tonnage: [{value: this.object.tonnage(), disabled: true}, tonnageValidators],
                carrier: [{value: this.object.carrier(), disabled: true}],
              });
  }

  isApproved(): boolean {
    return this.object.approved();
  }

  ngOnInit() {
    
  }

  toggleApprovement() {
    this.approve(!this.object.approved());
  }

  approve(value: boolean) {
    this.object.setApproved(value);
    this.dataSaver.saveObject(this.object).then((trailer) => {
      this.close(trailer);
    });
  }

  close(trailer) {
    this.dialogRef.close(trailer);
  }
}
