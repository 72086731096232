
import { Manager } from "../persons/manager";
import { Supplier } from '../persons/supplier';
import { Customer } from '../persons/customer';
import { PFDeletableObject } from "../base/deletableObject";
import { PFGeoPoint } from "../base/point";
import { PFObject } from "../base/object";
import { Contact, Contactable } from "../persons/contact";

class Address extends PFDeletableObject {
  getAddress(): string {return super.get("address");}
  setAddress(value: string): void {super.set("address", value);}
} 

class AddressWithEntrances extends Address {
  getEntrances(): Entrance[] {return super.get("entrances");}
  addEntrance(value: Entrance): void {super.add("entrances", value);}
  removeEntrance(value: Entrance): void {super.remove("entrances", value);}
}

export class Entrance extends Address {
  constructor() { super("Entrance"); }

  getName(): string {return super.get("name");}
  setName(value: string): void {super.set("name", value);}

  getContactName(): string {return super.get("contactName");}
  setContactName(value: string): void {super.set("contactName", value);}

  getContactPhone(): string {return super.get("contactPhone");}
  setContactPhone(value: string): void {super.set("contactPhone", value);}

  getCoordinate(): PFGeoPoint {return super.get("coordinate");}
  setCoordinate(value: PFGeoPoint): void {super.set("coordinate", value);}
}

export class LoadingPoint extends AddressWithEntrances {
  constructor() { super("LoadingPoint"); }

  getSupplier(): Supplier {return super.get("supplier");}
  setSupplier(value: Supplier): void {super.set("supplier", value);}

  getManager(): Manager {return super.get("manager");}
  setManager(value: Manager): void {super.set("manager", value);}

  copy(): LoadingPoint {
    let copy = new LoadingPoint();
    copy.setSupplier(this.getSupplier());
    copy.setAddress(this.getAddress());
    return copy;
  }
}

export class UnloadingPoint extends AddressWithEntrances implements Contactable {
  constructor() { super("UnloadingPoint"); }

  getCustomer(): Customer {return super.get("customer");}
  setCustomer(value: Customer): void {super.set("customer", value);}

  getContactName(): string {return super.get("contactName");}
  setContactName(value: string): void {super.set("contactName", value);}

  getContactPhone(): string {return super.get("contactPhone");}
  setContactPhone(value: string): void {super.set("contactPhone", value);}

  getManager(): Manager {return super.get("manager");}
  setManager(value: Manager): void {super.set("manager", value); }

  getContacts(): Contact[] { return super.get('contacts'); }
  setContacts(value: Contact[]) { super.set('contacts', value); }
  addContact(value: Contact) { 
    let contacts = this.getContacts();
    if (!contacts) {
      contacts = [];
    }
    contacts.push(value);
    this.setContacts(contacts);
   }
  removeContact(value: Contact) { 
    let contacts = this.getContacts();
    if (!contacts) {
      return;
    }
    let index = contacts.indexOf(value);
    if (index >= 0) {
      contacts.splice(index, 1);
    }
    this.setContacts(contacts);
  }

  isInternal(): boolean { return super.get("internal");}
  
  requiredEquipment() { return  super.get('equipmentRequirements'); }

  copy(): UnloadingPoint {
    let copy = new UnloadingPoint();
    copy.setCustomer(this.getCustomer());
    copy.setAddress(this.getAddress());
    return copy;
  }
}

export class Waypoint extends PFObject {
  getCoordinate(): PFGeoPoint {return super.get("coordinate");}
  getSpeed(): number {return super.get("speed");}
  getDate(): Date {return super.get("date");}
}
