
<div class="dialog-content-wrapper">
  <dialog-header headerClass="primary" 
                 title="{{title | translate}}">
  </dialog-header>
  <div class="content py-8 px-16" fxLayout="column">
    <form [formGroup]="form" fxFlex>
      <div fxLayout="row">
        <mat-form-field fxFlex>
          <input  matInput
                  placeholder="{{'PROPOSALS.DIALOG.CREATE.CONTROLS.SUPPLIER_SELECTOR' | translate}}"
                  [matAutocomplete]="supplierAutocomplete"
                  formControlName="supplier"
                  required>
          <mat-autocomplete #supplierAutocomplete="matAutocomplete" 
                            [displayWith]="supplierDisplay"
                            (optionSelected)='updateWithSupplier($event.option.value)'>
            <mat-option *ngIf='suppliersData.isLoading()'>
              <mat-spinner diameter="20" color="accent"></mat-spinner>
            </mat-option>
            <mat-option *ngFor='let supplier of availableSuppliers | async' 
                        [value]="supplier"
                        [disabled]="supplier.isDeleted()">
                        {{supplier.getName()}}{{supplier.isDeleted() ? ' (deleted)' : ''}}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="form.get('supplier').errors?.notObject">
            {{'PROPOSALS.DIALOG.CREATE.ERROR.MUST_SELECT' | translate}}
          </mat-error>
        </mat-form-field>
      </div>
      
      <div>
        <mat-form-field fxFlex="100">
          <mat-select placeholder="{{'PROPOSALS.DIALOG.CREATE.CONTROLS.LOADING_ADDRESS_SELECTOR' | translate}}"
                      formControlName="loadingPoint"
                      [compareWith]="compareFunction"
                      required>
            <mat-option *ngFor='let point of availableLoadingPoints' 
                        [value]="point">
                        {{ point.getAddress() }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="column" 
           fxLayoutAlign="space-between stretch"
           fxLayout.gt-xs="row" 
           fxLayoutAlign.gt-xs="space-between center">
        <mat-form-field fxFlex fxFlex.gt-xs="49">
          <mat-select placeholder="{{'PROPOSALS.DIALOG.CREATE.CONTROLS.TYPE_SELECTOR' | translate}}"
                      formControlName="articleType"
                      (selectionChange)="typeChanged()"
                      [compareWith]="compareFunction"
                      required>
            <mat-option *ngFor='let type of availableTypes' 
                        [value]="type">
                        {{type.name()}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex fxFlex.gt-xs="49">
          <mat-select placeholder="{{'PROPOSALS.DIALOG.CREATE.CONTROLS.BRAND_SELECTOR' | translate}}"
                      formControlName="articleBrand"
                      [compareWith]="compareFunction"
                      (selectionChange)="brandChanged()"
                      required>
            <mat-option *ngFor='let brand of availableBrands' 
                        [value]="brand"
                        [disabled]="brand.isDeleted()">
                        {{brand.name()}}{{brand.isDeleted() ? ' (deleted)' : ''}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="column" 
           fxLayoutAlign="space-between stretch"
           fxLayout.gt-xs="row" 
           fxLayoutAlign.gt-xs="space-between center">
           <mat-form-field fxFlex fxFlex.gt-xs="49">
            <input matInput
                   type="text"
                   [matDatepicker]="unloadPicker"
                   placeholder="{{'PROPOSALS.DIALOG.CREATE.CONTROLS.UNLOADING_DATE' | translate}}"
                   formControlName="unloadingDate"
                   required>
            <mat-datepicker-toggle matSuffix [for]="unloadPicker"></mat-datepicker-toggle>
            <mat-datepicker #unloadPicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field fxFlex fxFlex.gt-xs="49">
            <input matInput
                   type="number"
                   placeholder="{{'PROPOSALS.DIALOG.CREATE.CONTROLS.UNLOADING_TIME' | translate}}"
                   formControlName="unloadingTime"
                   cdkFocusInitial
                   required>
          </mat-form-field>
      </div>
      
      <div fxLayout="column"
           fxLayoutAlign="space-between none"
           fxLayout.gt-xs="row"
           fxLayoutAlign.gt-xs="space-between center">
           <mat-form-field fxFlex fxFlex.gt-xs="49">
            <input matInput 
                   type="number"
                   formControlName="tonnage"
                   placeholder="{{'PROPOSALS.DIALOG.CREATE.CONTROLS.TONNAGE' | translate}}"
                   (change)="tonnageChanged($event.target.value)"
                   min="0"
                   required>
          </mat-form-field>

            <mat-form-field fxFlex fxFlex.gt-xs="49">
              <input matInput 
                     type="number"
                     formControlName="count"
                     placeholder="{{'PROPOSALS.DIALOG.CREATE.CONTROLS.COUNT' | translate}}"
                     (change)="countChanged($event.target.value)">
            </mat-form-field>
      </div>

      <div fxLayout="row">
        <mat-form-field fxFlex>
          <input  matInput
                  placeholder="{{'PROPOSALS.DIALOG.CREATE.CONTROLS.CUSTOMER_SELECTOR' | translate}}"
                  [matAutocomplete]="customerAutocomplete"
                  formControlName="customer"
                  required>
          <mat-autocomplete #customerAutocomplete="matAutocomplete" 
                            [displayWith]="customerDisplay"
                            (optionSelected)='updateWithCustomer($event.option.value)'>
            <mat-option *ngIf='customersData.isLoading()'>
              <mat-spinner diameter="20" color="accent"></mat-spinner>
            </mat-option>
            <mat-option *ngIf="!customersData.isLoading() && customersData.getLoadError()">
              {{'PROPOSALS.DIALOG.CREATE.ERROR.LOAD_ERROR' | translate}}
            </mat-option>
            <mat-option *ngIf='!customersData.isLoading()' [value]="stockValue">
              {{stockValue.name}}
            </mat-option>
            <mat-option *ngFor='let customer of availableCustomers | async' 
                        [value]="customer">
                        {{ customer.getName()}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field fxFlex>
          <mat-select placeholder="{{'PROPOSALS.DIALOG.CREATE.CONTROLS.UNLOADING_ADDRESS_SELECTOR' | translate}}"
                      formControlName="unloadingPoint"
                      [compareWith]="compareFunction">
            <mat-option *ngFor='let point of availableUnloadingPoints' 
                        [value]="point">
                        {{ point.getAddress() }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field fxFlex>
          <mat-select placeholder="{{'PROPOSALS.DIALOG.CREATE.CONTROLS.UNLOADING_CONTACT' | translate}}"
                      formControlName="contacts"
                      [compareWith]="contactCompare">
            <mat-option *ngFor='let contact of contacts' 
                        [value]="contact">
                        {{ contact.phoneNumber}} ({{ contact.name }})
            </mat-option>
          </mat-select>
        </mat-form-field>

        <button mat-icon-button
                type="button"
                [disabled]="!isContactsSelectable()"
                (click)="addUnloadingContact()">
                <mat-icon>add</mat-icon>
        </button>
      </div>

      <div>
        <mat-form-field fxFlex>
          <textarea matInput
                    formControlName="comment"
                    placeholder="{{'PROPOSALS.DIALOG.CREATE.CONTROLS.COMMENT' | translate}}">
          </textarea>
        </mat-form-field>
      </div>
    </form>
    <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button
              color="accent"
              [disabled] = "dataSaver.isProcessing()"
              (click)='checkAndSave()'>{{buttonName | translate}}
      </button>
    </div>
  </div>

</div>
