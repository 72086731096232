import { NgModule } from '@angular/core';

import { ActionBarComponent } from './action-bar.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/material.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        ActionBarComponent,
    ],
    imports: [
        FuseSharedModule,
        MaterialModule,
        TranslateModule,
    ],
    exports: [
        ActionBarComponent,
    ]
})

export class ActionBarModule
{
}
