
export const locale = {
  'DIALOG' : {
    'CREATEBRAND' : {
      'TITLE' : {
        'CREATE' : 'Создать марку',
        'EDIT' : 'Изменить марку',
      },
      'CONFIRMBUTTON' : {
        'CREATE' : 'Создать',
        'SAVE' : 'Сохранить',
      },
      'NAME' : 'Название марки',
    },
  },
};
