import packages from '../../package.json';

export const environment = {
    production: false,
    version: `${packages.version}-dev`,
    parseAppId: 'X3nvnkeAubR8L23s8BpzWELQ9uCRAXw0HfHxG9uU',
    parseJavascriptKey: '6EEEZDo3XW0NpxCa1Q8hP5apKop8tMsB4JAuvyaA',
    parseServerUrl: 'https://dev.cargodeal.ru/cargodeal-dev/',

    googleMapApiKey: 'AIzaSyDIljZjTPM6J0UDqyA6BeJWD16ybuNLyzM',
};
