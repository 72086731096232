
export const locale = {
  'USERS': {
    'ERROR': {
      'UNDEFINED': 'Undefined error has occured',
      'RELATION': "Can't remove object '{{value}}'. Because there is some relations with other objects",
      'RELATION_DRIVER': "Can't remove object '{{value}}'. Because there is relation with transport unit. You need to disband it first",
      'RELATION_CUSTOMER': "Can't remove object '{{value}}'. Because there is relation with order. You need to remove it first",
    },
    'ROWOPERATION': {
      'UNLOADING_POINTS': 'Unloading points',
      'UNLOADING_ENTRANCES': 'Unloading entrances',
      'LOADING_POINTS': 'Loading points',
      'CREDENTIALS': 'Credentials',
      'BRANDS_LIST': 'Brand management',
      'CONTACTS': 'Contacts',
    },
    'TABLEROW': {
      'FULLNAME': 'Full name',
      'PHONE': 'Phone',
      'NAME': 'Name',
      'CONTACTS': 'Contacts',
      'LICENSE': 'Driver license',
      'ITN': 'ITN',
      'LOGIN': 'Login',
      'CARRIER': 'Carrier',
      'STORAGE': 'Storage',
      'MANAGER': 'Manager',
    },
    'TITLE': 'Users',
    'TABS': {
      'DRIVERS': {
        'TITLE': 'Drivers',
        'ROWOPERATION': {
          'APPROVE': 'Approve',
        },
        'TABLEROW': {
          'APPROVE_STATUS': 'Status',
        }
      },
      'CUSTOMERS': {
        'TITLE': 'Customers',
        'ROWOPERATION': {
          'CHECK': 'Check',
          'PERMISSIONS': 'Limitations',
        },
      },
      'SUPPLIERS': {
        'TITLE': 'Suppliers',
        'ROWOPERATION': {
          'MANAGE_AGREEMENTS': 'Manage agreements',
        },
      },
      'TRANSFER_POINT': {
        'TITLE': 'Transfer point',
      },
      'INTERMEDIARY': 'Intermediary',
      'DISPATCHERS': 'Carrier dispatchers',
      'LOGISTICIAN': 'Dispatchers',
      'MANAGERS': 'Managers',
      'CARRIERS': {
        'TITLE': 'Carriers',
        'STATUS': {
          'MESSAGE': 'Create: {{createOrdersStatusValue}}, Edit: {{updateOrdersStatus}}, Reserve: {{reserveOrderStatus}}',
          'ALLOWED': 'Allowed',
          'DENIED': 'Denied',
        },
        'TABLEROW': {
          'ORDER_MANAGEMENT': 'Order managment',
        },
        'ROWOPERATION': {
          'LIMITATIONS': 'Limitations',
        }
      },
    },
  }
};
