import { OrdersDialogPresenterService } from 'app/main/orders/dialog/ordersdialogspresenter/orders-dialog-presenter.service';
import { TableOperationsHelper } from 'app/components/helpers/table-operations.helper';
import { ActionBarOperation } from 'app/components/action-bar/action-bar.component';

export namespace OrderTableHelper {
    export function askCreateOrder(dialogPresenter: OrdersDialogPresenterService) {
        dialogPresenter.askNewOrder();
    }

    export function askCopyOrder(selected: any[], dialogPresenter: OrdersDialogPresenterService) {
        dialogPresenter.askCopyOrder(selected[0]);
    }

    export function askEditOrder(selected: any[], dialogPresenter: OrdersDialogPresenterService) {
        dialogPresenter.askEditOrder(selected[0])
    }

    export function showOrderInfo(selected: any[], dialogPresenter: OrdersDialogPresenterService) {
        dialogPresenter.showOrderInfo(selected[0]);
    }

    export function cancelOrders(selected: any[], dialogPresenter: OrdersDialogPresenterService, role) {
        dialogPresenter.askCancelOrder(selected, role);
    }

    export function transferOrders(selected: any[], dialogPresenter: OrdersDialogPresenterService) {
        dialogPresenter.askToTransfer(selected);
    }

    export function declineOrders(selected: any[], dialogPresenter: OrdersDialogPresenterService) {
        dialogPresenter.askToDecline(selected);
    }

    export function finishOrders(selected: any[], dialogPresenter: OrdersDialogPresenterService) {
        dialogPresenter.askToFinish(selected);
    }

    export function askRemoveOrders(selected: any[], dialogPresenter: OrdersDialogPresenterService, force: boolean) {
        dialogPresenter.askRemoveOrders(selected, force);
    }

    export function makeCreateOrderOperation(dialogPresenter: OrdersDialogPresenterService) {
        return TableOperationsHelper.makeCreateOperation(() => askCreateOrder(dialogPresenter));
    }

    export function makeCopyOrderOperation(selected: any[], dialogPresenter: OrdersDialogPresenterService) {
        return TableOperationsHelper.makeCopyOperation(() => askCopyOrder(selected, dialogPresenter), selected);
    }

    export function makeEditOrderOperation(selected: any[], dialogPresenter: OrdersDialogPresenterService) {
        return TableOperationsHelper.makeEditOperation(() => askEditOrder(selected, dialogPresenter), selected);
    }

    export function makeShowOrderInfoOperation(selected: any[], dialogPresenter: OrdersDialogPresenterService) {
        return new ActionBarOperation({
            title: 'ROWOPERATION.INFO',
            icon: 'info',
            action: () => showOrderInfo(selected, dialogPresenter),
            disabled: TableOperationsHelper.notSingleSelection(selected)
        })
    }

    export function makeCancelOrdersOperation(selected: any[], dialogPresenter: OrdersDialogPresenterService, role) {
        return new ActionBarOperation({
            title: 'ROWOPERATION.CANCEL',
            icon: 'cancel',
            action: () => cancelOrders(selected, dialogPresenter, role),
            disabled: TableOperationsHelper.noSelectedValues(selected)
        });
    }

    export function makeTransferOrdersOperation(selected: any[], dialogPresenter: OrdersDialogPresenterService) {
        return new ActionBarOperation({
            title: 'ROWOPERATION.TRANSFER',
            icon: 'trending_flat',
            action: () => transferOrders(selected, dialogPresenter),
            disabled: TableOperationsHelper.notSingleSelection(selected)
        });
    }

    export function makeDeclineOrdersOperation(selected: any[], dialogPresenter: OrdersDialogPresenterService) {
        return new ActionBarOperation({
            title: 'ROWOPERATION.DECLINE',
            icon: 'not_interested',
            action: () => declineOrders(selected, dialogPresenter),
            disabled: TableOperationsHelper.notSingleSelection(selected)
        });
    }

    export function makeFinishOrdersOperation(selected: any[], dialogPresenter: OrdersDialogPresenterService) {
        return new ActionBarOperation({
            title: 'ROWOPERATION.FINISH',
            icon: 'done',
            action: () => finishOrders(selected, dialogPresenter),
            disabled: TableOperationsHelper.notSingleSelection(selected)
        });
    }

    export function makeRemoveOrderOperation(selected: any[], dialogPresenter: OrdersDialogPresenterService, force: boolean = false) {
        return TableOperationsHelper.makeRemoveOperation(() => askRemoveOrders(selected, dialogPresenter, force), selected);
    }

} 