
export const locale = {
  'DIALOG': {
    'CREDENTIALS': {
      'TITLE': 'Учетные данные',
      'BUTTON': {
        'REMOVE': 'Удалить',
        'CREATE': 'Создать',
        'CHANGE_PASSWORD': 'Изменить пароль',
      },
      'NAME': 'Логин',
      'PASSWORD': 'Пароль',
      'NEW_PASSWORD': 'Новый пароль',
      'ERROR': {
        'Forbidden': 'Операция запрещена.',
        'ObjectNotFound': 'Объект не найден.',
        'AlreadyHasUser': 'У этого пользователя уже есть учетные данные.',
        'UserAlreadyExists': 'Пользователь с таким именем уже существует. Выберите другое имя.',
        'ObjectIsReferenced': 'Невозможно удалить объект. Т.к. на него ссылаются другие объекты.',
        'UNDEFINED': 'Произошла неизвестная ошибка. Попробуйте еще раз.',
      },
    },
  },
};
