
import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { LocaleService } from 'app/components/locale/locale.service';
import { CloudExecutor } from 'app/components/data/cloud-executors/cloud-executor';

import { pass } from './locale/locale';

import * as FormHelper from 'app/components/form-helper/form-helper';
import { VehiclePass, VehiclePassFactory } from 'app/components/data/core/models/transport/vehicle-pass';
import { DateRange } from '@angular/material/datepicker';

import { VehiclePassZoneItemFactory } from './factory/zone.factory';
import { VehiclePassTimeOfActionItemFactory } from './factory/time.factory';
import { Moment } from 'moment';
import * as moment from 'moment';

@Component({
  selector: 'pass-dialog',
  templateUrl: './pass-dialog.component.html',
  styleUrls: ['./pass-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class PassDialog {

  public form: UntypedFormGroup;

  private initialPass: VehiclePass;
  private pass: VehiclePass;

  public zones = VehiclePassZoneItemFactory.makeDefault()
  public compareZones = VehiclePassZoneItemFactory.compare

  public times = VehiclePassTimeOfActionItemFactory.makeDefault()
  public compareTimeOfAction = VehiclePassTimeOfActionItemFactory.compare

  public title: string;
  public buttonName: string;

  public datesRange: DateRange<Moment>

  public dataSaver = new CloudExecutor;

  constructor(
    private localeService: LocaleService,
    public dialogRef: MatDialogRef<PassDialog>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
  ) {
    this.localeService.loadLocale(pass);
    this.initialPass = this._data.object;
    if (this.initialPass) {
      this.pass = VehiclePassFactory.copy(this.initialPass)
    } else {
      this.pass = VehiclePassFactory.make()
    }

    this.datesRange = new DateRange(moment(this.pass.beginDate), moment(this.pass.endDate));

    if (this._data.action === 'create') {
      this.title = 'DIALOG.PASS.TITLE.CREATE';
      this.buttonName = 'DIALOG.PASS.CONFIRMBUTTON.CREATE';
    } else if (this._data.action === 'edit') {
      this.title = 'DIALOG.PASS.TITLE.EDIT';
      this.buttonName = 'DIALOG.PASS.CONFIRMBUTTON.SAVE';
    }

    if (this._data.customTitle) {
      this.title = this._data.customTitle;
    }

    this.form = this.createContactForm();
  }

  createContactForm(): UntypedFormGroup {

    return new UntypedFormGroup({
      zone: new UntypedFormControl(
        VehiclePassZoneItemFactory.makeWithZone(this.pass.zone)
      ),
      timeOfAction: new UntypedFormControl(
        VehiclePassTimeOfActionItemFactory.makeWithActionTime(this.pass.timeOfAction)
      ),
      number: new UntypedFormControl(
        this.pass.number
      ),
      datePicker: new UntypedFormControl(
        this.datesRange
      ),
    });
  }

  checkAndSave() {
    let verified = this.checkForm();
    if (verified) {
      this.pass.number = this.form.controls.number.value;
      this.pass.zone = this.form.controls.zone.value.zone;
      this.pass.timeOfAction = this.form.controls.timeOfAction.value.time;
      this.pass.beginDate = this.datesRange.start.toDate();
      this.pass.endDate = this.datesRange.end.toDate();
      this.dialogRef.close({
        old: this.initialPass,
        new: this.pass,
      });
    }
  }

  checkForm(): boolean {
    return FormHelper.checkForm(this.form);
  }
}
