
export const locale = {
  "TRIP": {
    "STAGE": {
      "None": "Заказ принят",
      "0": "Выехал под загрузку",
      "1": "Прибыл в пункт загрузки",
      "2": "Загрузился",
      "3": "Выехал в пункт разгрузки",
      "4": "Прибыл в пункт разгрузки",
      "5": "Разгрузился",
    },
  },
};
