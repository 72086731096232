export const locale = {
  'CREATEBRAND': {
    'TITLE': {
      'CREATE': 'Создать марку',
      'EDIT': 'Изменить марку',
    },
    'CONFIRMBUTTON': {
      'CREATE': 'Создать',
      'SAVE': 'Сохранить',
    },
    'NAME': 'Название марки',
    'TYPE': 'Выберите вид',
    'TONNAGE_PER_TRUCK': 'Тонн в машине',
  },
};
