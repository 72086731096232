<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="primary" 
                 title="{{'DIALOG.DECLINE.TITLE' | translate}}"
                 (clickAction)="close()">
  </dialog-header>
  
  <div class="pt-8 px-16">
    <p>{{'DIALOG.DECLINE.MESSAGE' | translate}}</p>
    <div *ngFor="let order of orders">
      <p>{{'DIALOG.DECLINE.ORDERNUMBER' | translate}} {{order.number()}}</p>
    </div>
    <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button
              color="warn"
              (click)="execute()"
              [disabled]="isExecuting()"
              cdkFocusInitial>{{'DIALOG.DECLINE.CONFIRM' | translate}}
      </button>
    </div>
  </div>
</div>
