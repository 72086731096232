import { DateHelper } from "app/components/helpers/date.helper"

export interface VehiclePass {
    city?: string
    zone: VehiclePassZone
    number: string
    timeOfAction: VehiclePassTimeOfAction
    canceled?: boolean
    endDate: Date
    beginDate: Date
}

export enum VehiclePassTimeOfAction {
    Day = "day",
    Night = "night"
}

export enum VehiclePassZone {
    CK = 0,
    TTK = 1,
    MKAD = 2,
}

export enum VehiclePassState {
    Invalid,
    Canceled,
    Valid,
    NotValidNow
}

export namespace VehiclePass {

    export function passState(pass: VehiclePass): VehiclePassState {
        if (pass.canceled) return VehiclePassState.Canceled;
        let now = new Date()
        if (now < pass.beginDate) return VehiclePassState.NotValidNow;
        if (now > pass.endDate || !pass.beginDate || !pass.endDate) return VehiclePassState.Invalid;
        return VehiclePassState.Valid;
    }

}

export namespace VehiclePassFactory {

    export function toJSON(pass: VehiclePass) {
        return {
            city: pass.city,
            zone: pass.zone,
            number: pass.number,
            timeOfAction: pass.timeOfAction,
            canceled: pass.canceled,
            beginDate: DateHelper.dateToFormattedString(pass.beginDate, "YYYY-MM-DD"),
            endDate: DateHelper.dateToFormattedString(pass.endDate, "YYYY-MM-DD"),
        }
    }

    export function copy(pass: VehiclePass): VehiclePass {
        return {
            city: pass.city,
            zone: pass.zone,
            number: pass.number,
            timeOfAction: pass.timeOfAction,
            canceled: pass.canceled,
            beginDate: pass.beginDate,
            endDate: pass.endDate
          }
    }

    export function make(): VehiclePass {
        return {
            city: "",
            zone: VehiclePassZone.MKAD,
            number: "",
            timeOfAction: VehiclePassTimeOfAction.Day,
            canceled: false,
            beginDate: new Date(),
            endDate: DateHelper.nextDay(new Date())
          }
    }

    export function fromJSON(json): VehiclePass {
        if (!json) return null;
        let timeOfAction = json.timeOfAction as VehiclePassTimeOfAction
        let beginDate = new Date(json.beginDate)
        let endDate = new Date(json.endDate)
        return {
            city: json.city,
            zone: json.zone,
            number: json.number,
            timeOfAction: timeOfAction,
            canceled: json.canceled,
            beginDate: DateHelper.isValid(beginDate) ? beginDate : null,
            endDate: DateHelper.isValid(endDate) ? endDate : null,
        }
    }

    export function fromJSONArray(array): VehiclePass[] {
        if (!array) return null;
        return array.map(json => VehiclePassFactory.fromJSON(json))
    }

}