
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Parse } from 'parse';

export const enum EventType {
  Update,
  Add,
  Remove
}

export class Event {
  constructor(
    public type: EventType,
    public object: Parse.Object) { }
}

export function makeObservable(query: Parse.Query, types: EventType[]): Observable<Event> {
  let observers = [];
  let subscription: Parse.LiveQuerySubscription;

  return new Observable((observer) => {
    observers.push(observer);

    if (observers.length === 1) {
      query.subscribe().then(subs => {
        subscription = subs;

        subscription.on('error', (error) => { });

        if (types.includes(EventType.Update)) {
          subscription.on('update', (object) => {
            let event = new Event(EventType.Update, object);
            observers.forEach(observer => observer.next(event));
          });
        }

        if (types.includes(EventType.Add)) {
          subscription.on('create', (object) => {
            let event = new Event(EventType.Add, object);
            observers.forEach(observer => observer.next(event));
          });

          subscription.on('enter', (object) => {
            let event = new Event(EventType.Add, object);
            observers.forEach(observer => observer.next(event));
          });
        }

        if (types.includes(EventType.Remove)) {
          subscription.on('leave', (object) => {
            let event = new Event(EventType.Remove, object);
            observers.forEach(observer => observer.next(event));
          });

          subscription.on('delete', (object) => {
            let event = new Event(EventType.Remove, object);
            observers.forEach(observer => observer.next(event));
          });
        }
      })
    }
    return () => {
      subscription.unsubscribe();
    };
  });
}

export function observeObject(Class: any, identifier: string): Observable<Parse.Object> {
  let query = new Parse.Query(Class);
  query.equalTo("objectId", identifier);
  return makeObservable(query, [EventType.Update]).pipe(map(event => event.object));
}
