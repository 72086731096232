import { RowParameters, TableData, TableRowData, TableSort } from 'app/components/table/tabledata';

import { Supplier } from 'app/components/data/core/models/persons/supplier';
import { PFQuery } from 'app/components/data/core/models/base/query';
import { ServerTableDataSource } from 'app/components/table/data_sources/server-data-source';
import { filterDeleted } from 'app/components/access/query';

export class SuppliersTableData extends TableData {

  constructor() {
    super();

    let columns = [
      new TableRowData({
        key: 'name',
        header: 'USERS.TABLEROW.NAME',
        cell: (supplier: Supplier) => `${supplier.getName()}`
      }),
      new TableRowData({
        key: 'ITN',
        header: 'USERS.TABLEROW.ITN',
        cell: (supplier: Supplier) => `${supplier.getITN() ? supplier.getITN() : ''}`
      }),
      new TableRowData({
        key: 'contactName',
        header: 'USERS.TABLEROW.CONTACTS',
        cell: (supplier: Supplier) => `${ this.listContacts(supplier)}`,
        params: new RowParameters('fill', true)
      }),
      
    ];
    this.setColumns(columns);

    let sort: TableSort = new TableSort('name','asc');
    let tableDataSource = new ServerTableDataSource('Supplier', ['loadingPoints.entrances', 'articleBrands']);
    tableDataSource.setDefaultSort(sort);
    tableDataSource.queryModify = function (query: PFQuery): PFQuery {
      query.equalTo('transfer', false);
      return filterDeleted(query);
    };
    tableDataSource.queryFilter = (filter, defaultQuery) => {
      defaultQuery.contains('name', filter);

      let itnQuery = new PFQuery('Supplier');
      itnQuery.contains('ITN', filter);

      return PFQuery.or(defaultQuery, itnQuery);
    };
    this.setProvider(tableDataSource);
  }

  private listContacts(supplier: Supplier) {
    let contacts = supplier.getContacts();
    let contactsString = "";
    if (contacts) {
      contacts.forEach(c => {
        contactsString += `${c.phoneNumber} (${c.name})<br>`
      })
    }
    return contactsString;
  }
}
