export const locale = {
  'TRANSFER': {
    'TITLE': 'Передать заказы',
    'TRANSFERBUTTON': 'Передать',
    'SEARCH': 'Поиск',
    'TAB': {
      'DRIVERS': {
        'SUBTAB': {
          'LIST': 'Список',
          'MAP': 'На карте',
        },
        'TITLE': 'Водители',
        'CREATE_UNIT': 'Новый экипаж',
        'CREATE_DRIVER': 'Новый водитель',
        'TUFILTERPLASEHOLDER': 'Группа ТС',
        'READYTOWORK': "Только \"Готовые к работе\"",
      },
      'CARRIERS': {
        'TITLE': 'Перевозчики',
        'TABLEROW': {
          'NAME': 'Название',
        }
      },
    },

    'ERROR': {
      'NOTSELECTED': 'Не выбрано транспортное средство.',
      'LOW_TONNAGE': 'У экипажа недостаточная вместимость.',
      'DRIVER_NOT_APPROVED': 'Невозможно передать заказ водителю, которого еще не утвердили.',
      'UNDEFINED_ERROR': 'Не удалось выполнить операцию. Попробуйте еще раз.',
    }
  },
};
