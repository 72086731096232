import { NgModule } from '@angular/core';

import { OrderDialogComponent } from './order-dialog.component';

import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/material.module';

import { CustomerStepComponent } from './steps/customer-step/customer-step.component'
import { SupplierStepComponent } from './steps/supplier-step/supplier-step.component'
import { DialogHeaderModule } from 'app/components/dialogs/dialog-header/dialog-header.module';

@NgModule({
    declarations: [
        OrderDialogComponent,
        CustomerStepComponent,
        SupplierStepComponent,
    ],
    imports: [
        TranslateModule,
        FuseSharedModule,
        MaterialModule,
        DialogHeaderModule,
    ],
    exports: [
        OrderDialogComponent,
        CustomerStepComponent,
        SupplierStepComponent,
    ]
})

export class OrderDialogModule
{
}
