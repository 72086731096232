
export const locale = {
  'USERS': {
    'ERROR': {
      'UNDEFINED': 'Произошла неизвестная ошибка',
      'RELATION': "Не удалось удалить запись '{{value}}', так как от этой записи зависят другие записи",
      'RELATION_DRIVER': "Не удалось удалить запись '{{value}}', так как от этой записи зависит экипаж. Это может быть активный экипаж, тогда вы должны сначала его освободить в разделе 'Список водителей'. Если эта запись есть в экипаже, который завершил заказ, то необходимо сначала удалить все заказы с этим экипажем.",
      'RELATION_CUSTOMER': "Не удалось удалить запись '{{value}}', так как от этой записи зависит заказ. Вы должны сначала удалить заказ.",
    },
    'ROWOPERATION': {
      'UNLOADING_POINTS': 'Адреса разгрузки',
      'UNLOADING_ENTRANCES': 'Точки разгрузки',
      'LOADING_POINTS': 'Точки загрузки',
      'CREDENTIALS': 'Учетные данные',
      'BRANDS_LIST': 'Управление товарами',
      'CONTACTS': 'Контакты',
    },
    'TABLEROW': {
      'FULLNAME': 'ФИО',
      'PHONE': 'Телефон',
      'NAME': 'Название',
      'CONTACTS': 'Контакты',
      'LICENSE': 'Водительское удостоверение',
      'ITN': 'ИНН',
      'LOGIN': 'Пользователь',
      'CARRIER': 'Перевозчик',
      'STORAGE' : 'Склад',
      'MANAGER': 'Менеджер',
    },
    'TITLE': 'Люди и компании',
    'TABS': {
      'DRIVERS': {
        'TITLE': 'Водители',
        'ROWOPERATION': {
          'APPROVE': 'Утверждение',
        },
        'TABLEROW': {
          'APPROVE_STATUS': 'Статус',
        }
      },
      'CUSTOMERS': {
        'TITLE': 'Покупатели',
        'ROWOPERATION': {
          'CHECK': 'Проверка',
          'PERMISSIONS': 'Ограничения',
        },
      },
      'SUPPLIERS': {
        'TITLE': 'Поставщики',
        'ROWOPERATION': {
          'MANAGE_AGREEMENTS': 'Соглашения',
        },
      },
      'TRANSFER_POINT': {
        'TITLE': 'Перевалка',
      },
      'INTERMEDIARY': 'Дилеры',
      'DISPATCHERS': 'Диспетчеры перевозчиков',
      'LOGISTICIAN': 'Диспетчеры',
      'MANAGERS': 'Менеджеры',
      'CARRIERS': {
        'TITLE': 'Перевозчики',
        'STATUS' : {
          'MESSAGE': 'Создание: {{createOrdersStatusValue}}, Изменение: {{updateOrdersStatus}}, Бронирование: {{reserveOrderStatus}}',
          'ALLOWED' : 'Разрешено',
          'DENIED' : 'Запрещено',
        },
        'TABLEROW': {
          'ORDER_MANAGEMENT': 'Управление заказами',
        },
        'ROWOPERATION': {
          'LIMITATIONS': 'Ограничения',
        },
      },
    },
  }
};
