import { Component, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDatepicker } from '@angular/material/datepicker';

import { FinishedOrdersTabledata } from './finished-orders-list-tabledata';

import { TableComponent, FieldFilter, FilterCondition } from 'app/components/table/table.component';

import { DispatcherOrderWriteAccess } from 'app/components/access/dispatcher-order-write';

import { OrdersDialogPresenterService } from 'app/main/orders/dialog/ordersdialogspresenter/orders-dialog-presenter.service';

import { LocaleService } from 'app/components/locale/locale.service';
import { finished, orders_row_operations, order_fields } from '../../locale/locale';
import { default_row_operations, orders, common } from '../../../locale/locale';

import * as moment from 'moment';
import { ConfigurationService } from 'app/main/admin/settings/configuration.service';
import { TableData } from 'app/components/table/tabledata';
import { DateHelper } from 'app/components/helpers/date.helper';
import { TableOperationsController } from 'app/components/table-operations-controller/table-operations-controller';
import { OrdersFinishedTableOperationsFactory } from './orders-finished.operation-factory';

const locales = [
  finished,
  orders,
  common,
  default_row_operations,
  orders_row_operations,
  order_fields,
];

const DATE_FORMAT = "DDMMYYYY";

@Component({
  selector: 'finished-orders-list',
  templateUrl: './finished-orders.component.html',
  styleUrls: ['./finished-orders.component.scss']
})
export class FinishedOrdersListComponent implements DispatcherOrderWriteAccess.TableOperationUpdating {
  tableData: TableData;
  operationsController: TableOperationsController;
  
  headerButtons = [];
  @ViewChild('startDate') startDate: ElementRef;
  @ViewChild('endDate') endDate: ElementRef;
  @ViewChild('startDatePicker') startDatePicker: MatDatepicker<Date>;
  @ViewChild('endDatePicker') endDatePicker: MatDatepicker<Date>;

  @ViewChild(TableComponent) private table: TableComponent;

  constructor(
    private localeService: LocaleService,
    public dialog: MatDialog,
    private dialogPresenter: OrdersDialogPresenterService,
    private configService: ConfigurationService,
  ) {
    this.localeService.loadLocales(locales);
    this.tableData = new FinishedOrdersTabledata(this.configService);
    let operationsFactory = new OrdersFinishedTableOperationsFactory(
      this.dialogPresenter
    );
    this.operationsController = new TableOperationsController(operationsFactory);
    this.headerButtons = this.makeHeaderButtons();
  }

  ngAfterViewInit() {
    DispatcherOrderWriteAccess.checkTableOperations(this);
    this.configService.fetch().then(() => {
      this.table.refresh();
    });
  }

  search(searchString: string) {
    this.table.search(searchString);
  }

  updateOperations(): void {
    this.operationsController.updateOperations(this.table);
  }

  updateFilter() {
    let startDate = this.startDate.nativeElement.value;
    let endDate = this.endDate.nativeElement.value;

    let filters = [];

    if (startDate) {
      let m = moment(startDate, DATE_FORMAT);
      let date = new Date(m.format());
      filters.push(new FieldFilter('createdAt', date, FilterCondition.GreaterThanOrEqual));
    }

    if (endDate) {
      let m = moment(endDate, DATE_FORMAT);
      let date = DateHelper.setDayEnd(new Date(m.format()));
      filters.push(new FieldFilter('createdAt', date, FilterCondition.LessThanOrEqual));
    }
    this.table.setFilters(filters);
  }

  startDateChanged() {
    this.updateFilter();
  }

  resetFilter() {
    this.startDate.nativeElement.value = '';
    this.endDate.nativeElement.value = '';
    this.startDatePicker.select(undefined);
    this.endDatePicker.select(undefined);
    this.updateFilter();
  }

  isDateValid(): boolean {
    let startDateStr = this.startDate.nativeElement.value;
    let endDateStr = this.endDate.nativeElement.value;
    let startDate = new Date(moment(startDateStr, DATE_FORMAT).format());
    let endDate = new Date(moment(endDateStr, DATE_FORMAT).format());
    return startDate.getTime() > endDate.getTime();
  }

  private reloadData() {
    this.table.reloadData();
    DispatcherOrderWriteAccess.reloadTableOperations(this);
  }

  private filtersReset() {
    this.table.filtersReset();
  }


  private makeHeaderButtons() {
    return [
      {
        'title': 'COMMON.BUTTONS.RELOAD',
        'action': () => this.reloadData()
      },
      {
        'title': 'COMMON.BUTTONS.RESET_FILTERS',
        'action': () => this.filtersReset()
      }
    ];
  }
}
