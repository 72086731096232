import { TableData, TableRowData, TableSort } from 'app/components/table/tabledata';

import { ArticleBrand } from 'app/components/data/core/models/articles/article'
import { PFQuery } from 'app/components/data/core/models/base/query';
import { ServerTableDataSource } from 'app/components/table/data_sources/server-data-source';
import { filterDeleted } from 'app/components/access/query';

export class ArticleBrandsTableData extends TableData {

  constructor() {
    super();

    let columns = [
      new TableRowData({
        key: 'name', 
        header: 'ARTICLE.TABLEROW.BRANDNAME', 
        cell: (brand: ArticleBrand) => `${brand.name()}`
      }),
      new TableRowData({
        key: 'typename', 
        header:'ARTICLE.TABLEROW.TYPENAME', 
        cell:(brand: ArticleBrand) => `${brand.type() ? brand.type().name() : ''}`
      }),
    ];

    this.setColumns(columns);

    let sort: TableSort = new TableSort('name','asc');
    
    let tableDataSource = new ServerTableDataSource('ArticleBrand', ['type']);
    tableDataSource.setDefaultSort(sort);
    tableDataSource.queryModify = function (query: PFQuery): PFQuery {
      return filterDeleted(query);
    };
    tableDataSource.queryFilter = (filter, query) => {
      query.contains('name', filter);
      let otherQuery: PFQuery = new PFQuery('ArticleBrand');
      let typeQuery = new PFQuery('ArticleType');
      typeQuery.contains('name', filter);
      otherQuery.matchesQuery("type",typeQuery);
      return PFQuery.or(query, otherQuery);
    };
    this.setProvider(tableDataSource);
  }

  canSortColumn(column) {
    if (column.key === 'typename') {
      return false;
    }
    return super.canSortColumn(column);
  }
}
