
export const locale = {
  'DIALOG' : {
    'SUPPLIER' : {
      'TITLE' : {
        'CREATE' : 'Создать поставщика',
        'EDIT' : 'Изменить поставщика',
        'POINTS' : 'Точки загрузки',
        'AGREEMENTS' : 'Соглашения',
        'BRANDS_LIST' : 'Управление товарами',
        'BRANDS_LIST_ADD': 'Добавить марки',
      },
      'CONFIRMBUTTON' : {
        'CREATE' : 'Создать',
        'SAVE' : 'Сохранить',
        'CLOSE' : 'Закрыть',
        'ADD': 'Добавить',
      },
      'ERROR' : {
        'ITN_LENGTH' : 'Должно быть 12 цифр',
        'ITN_ONLY_NUMBERS': 'ИНН может содержать только цифры',
        'MUST_BE_FILLED' : 'Поле должно быть заполнено',
        'ZERO_RESULTS' : "Не удалось определить координаты для адреса '{{value}}'. Проверьте правильность ввода адреса.",
      },
      'HINT' : {
        'ITN' : '12 цифр',
      },
      'NAME' : 'Название',
      'ITN' : 'ИНН',
      'AGREEMENT' : 'Соглашение',
      'ADDRESS' : 'Адрес',
      'DEFAULT_POINT' : 'Точка загрузки 1',
      'BRAND_NAME': 'Название марки',
      'BRAND_TYPE': 'Вид товара',
      'TYPE_FILTER_ALL': 'Все',
    },
    'TRANSFER_POINT' : {
      'TITLE' : {
        'CREATE' : 'Создать перевалку',
        'EDIT' : 'Изменить перевалку',
      },
    },
  },
};
