
export const locale = {
  'REMOVEDIALOG' : {
    'TITLE' : 'Remove',
    'MESSAGE' : 'Do you really want to delete this item/items?',
    'BUTTON' : {
      'CONFIRM' : 'Confirm',
      'DECLINE' : 'Decline',
    },
  }
};
