export const locale = {
  'DIALOG' : {
    'CREATEBRAND' : {
      'TITLE' : {
        'CREATE' : 'Create brand',
        'EDIT' : 'Edit brand',
      },
      'CONFIRMBUTTON' : {
        'CREATE' : 'Create',
        'SAVE' : 'Save',
      },
      'NAME' : 'Brand name',
    },
  },
};
