import { Parse } from 'parse';

export interface Response {
  result: boolean
  error?: string
}

export class CloudExecutor {
  private processing;
  private canceled: boolean = false;

  public run(operation: String, params = null): Promise<any> {
    this.processing = true;
      this.canceled = false;
    return Parse.Cloud.run(operation, params)
    .then((response: Response) => {
      if (response.result) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response.error);
      }
    })
    .catch(error => {
      console.log(`CloudOperation '${operation}' failed with error ${error}`)
      return Promise.reject(error);
    })
    .finally(() => {
      this.processing = false;
    });
  }

  public saveObject(object: Parse.Object, options: any = null): Promise<any> {
    this.processing = true;
    return object.save(null, options)
    .then(obj => {
      return Promise.resolve(obj);
    })
    .catch(error => {
      console.log(error);
      return Promise.reject(error);
    })
    .finally(() => {
      this.processing = false;
    });
  }

  public isProcessing() {
    return this.processing;
  }

  public isCanceled() {
    return this.canceled;
  }

  public cancel() {
    this.canceled = true;
  }
}
