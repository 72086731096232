import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  ElementRef,
  Inject,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject } from 'rxjs';

@Component({
  selector: 'app-customer-select',
  templateUrl: './customer-select.component.html',
  styleUrls: ['./customer-select.component.scss']
})
export class CustomerSelectComponent implements OnInit {
  @ViewChild('inputElement') inputElement!: ElementRef;
  customers: any[] = [];
  @Input() selectedCustomersList: any[];
  @Output() selectionChange = new Subject<number[]>(); // Используем Subject для передачи данных в реальном времени
  isVisible: boolean = true;
  private isFocusedInside: boolean = false;

  searchTerm: string = '';
  filteredCustomers: any[] = [];
  selectedCustomers: number[] = [];

  constructor(
      public dialogRef: MatDialogRef<CustomerSelectComponent>,
      @Inject(MAT_DIALOG_DATA) public data?: {
        elementRef: HTMLElement;
        selectedCustomers: any[];
        filteredCustomers: any[];
      },
  ) {
    this.selectedCustomers = this.data?.selectedCustomers || [];
    this.customers = this.data?.filteredCustomers || [];

    // Обработка клика вне модального окна
    this.dialogRef.backdropClick().subscribe(() => {
      this.dialogRef.close(this.selectedCustomers); // Закрываем модальное окно и возвращаем текущие данные
    });
  }

  ngOnInit() {
    // Проверяем, что элемент был передан
    if (this.data?.elementRef) {
      const rect = (this.data.elementRef as HTMLElement).getBoundingClientRect();

      // Устанавливаем положение диалога
      const dialogElement = document.querySelector('.mat-dialog-container') as HTMLElement;
      if (dialogElement) {
        dialogElement.style.position = 'absolute';

        // Проверяем, находится ли элемент левее центра экрана
        let leftPosition;
        if (rect.left < window.innerWidth / 2) {
          // Если элемент находится левее центра, располагаем окно справа от него
          leftPosition = rect.left;
        } else {
          leftPosition = rect.left - 560;
        }

        dialogElement.style.top = `${rect.bottom}px`;
        dialogElement.style.left = `${leftPosition}px`;
        dialogElement.style.padding = `0px`;
        dialogElement.style.width = `auto`;
        dialogElement.style.height = `auto`;
        //dialogElement.style.maxHeight = '16vh';
        dialogElement.style.background = 'none';
        dialogElement.style.boxShadow = 'none';
      }
    }

    this.filteredCustomers = this.customers;
  }

  filterCustomers() {
    if (this.searchTerm) {
      this.filteredCustomers = this.customers.filter(customer =>
          customer.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    } else {
      this.filteredCustomers = this.customers;
    }
  }

  onCheckboxChange(customerId: number, checked: boolean) {
    if (checked) {
      this.selectedCustomers.push(customerId);
    } else {
      this.selectedCustomers = this.selectedCustomers.filter(id => id !== customerId);
    }

    // Emit обновленные данные в реальном времени
    this.selectionChange.next(this.selectedCustomers);
  }

  onFocusIn() {
    this.isFocusedInside = true;
  }

  onFocusOut(event: FocusEvent) {
    setTimeout(() => {
      if (!this.isFocusedInside) {
        this.isVisible = false;
      }
    }, 100);
    this.isFocusedInside = false;
  }
}