
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot, Data } from '@angular/router';

import { AuthenticationService } from 'app/authentication/authentication.service';

export { Role } from 'app/components/data/core/models/base/user';

@Injectable()
export class UserRoleGuard implements CanActivate, CanActivateChild {

  constructor(private authenticationService: AuthenticationService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkAccess(state.url, route.data);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkAccess(state.url, route.data);
  }

  private checkAccess(URL: string, data: Data): boolean {
    this.authenticationService.checkSession();
    let authenticated = this.authenticationService.isAuthenticated();
    if (!authenticated) {
      this.authenticationService.redirectURL = URL;
      this.router.navigate(["/login"]);
      return false;
    }

    if (!data.roles) {
      return true;
    }

    let role = this.authenticationService.currentRole();
    if (data.roles.indexOf(role) === -1) {
      this.router.navigate([this.authenticationService.defaultRoute()]);
      return false;
    }
    return true;
  }

}
