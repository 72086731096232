import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { MaterialModule } from 'app/main/material.module';

import { CarrierCreateDialog } from './create/carrier-create-dialog.component';
import { CarrierLimitationsDialog } from './limitations/carrier-limitations-dialog.component';

import { CarrierCustomerLimitationsDialog } from './limitations/lists/customers/carrier-customers-limitations-dialog.component';
import { CarrierSupplierLimitationsDialog } from './limitations/lists/suppliers/carrier-supplier-limitations-dialog.component';
import { TableModule } from 'app/components/table/table.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { DialogHeaderModule } from 'app/components/dialogs/dialog-header/dialog-header.module';

@NgModule({
    declarations: [
        CarrierCreateDialog,
        CarrierLimitationsDialog,
        CarrierCustomerLimitationsDialog,
        CarrierSupplierLimitationsDialog,
    ],
    imports: [
        MaterialModule,
        TranslateModule,
        FuseSharedModule,
        TableModule,
        ColorPickerModule,
        DialogHeaderModule,
    ],
    exports: [
        CarrierCreateDialog,
        CarrierLimitationsDialog,
        CarrierCustomerLimitationsDialog,
        CarrierSupplierLimitationsDialog,
    ]
})

export class CarrierDialogsModule
{
}
