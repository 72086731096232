import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocaleService } from 'app/components/locale/locale.service';
import { unit_transport_problem } from 'app/main/orders/locale/locale';

@Component({
    selector   : 'unit-tansport-problem-dialog',
    templateUrl: './unit-tansport-problem-dialog.component.html',
    styleUrls  : ['./unit-tansport-problem-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class UnitTransportProblemDialogComponent {
  problems: string[]
  constructor (
    public localeService: LocaleService,
    public dialogRef: MatDialogRef<UnitTransportProblemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) _data: any,
  ) {
    this.localeService.loadLocale(unit_transport_problem);
    let checks = _data.checks;
    let problems = [];

    let problem = 'DIALOG.TRANSPORT_PROBLEM.';
    if (checks.vehicleInspectionCheck == false) {
      problems.push(problem + 'VEHICLE_INSPECTION');
    }
    if (checks.vehicleInsuranceCheck == false) {
      problems.push(problem + 'VEHICLE_INSURANCE');
    }
    if (checks.trailerInspectionCheck == false) {
      problems.push(problem + 'TRAILER_INSPECTION');
    }
    this.problems = problems;
  }
}
