import { NgModule } from '@angular/core';
import { DeleteImageDialogComponent } from './delete-image-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import {DialogHeaderModule} from "../../../../components/dialogs/dialog-header/dialog-header.module";
import {FuseSharedModule} from "../../../../../@fuse/shared.module";
import {MaterialModule} from "../../../material.module";

@NgModule({
    declarations: [
        DeleteImageDialogComponent,
    ],
    imports: [
        TranslateModule,
        FuseSharedModule,
        MaterialModule,
        DialogHeaderModule,
    ],
    exports: [
        DeleteImageDialogComponent,
    ]
})

export class DeleteImageDialogModule {
}
