
<div class="dialog-content-wrapper">
  <dialog-header headerClass="primary" 
                 title="{{'PROPOSALS.DIALOG.RESERVATION.TITLE' | translate}}">
  </dialog-header>
  <div class="content py-8 px-16" fxLayout="column">
    <form>
      <mat-form-field fxFlex>
        <input  matInput
                [formControl]="carrierSelector"
                placeholder="{{'PROPOSALS.DIALOG.RESERVATION.CONTROLS.CARRIER_SELECTOR' | translate}}"
                [matAutocomplete]="carrierAutocomplete">
        <mat-autocomplete #carrierAutocomplete="matAutocomplete" 
                          [displayWith]="carrierDisplay">
          <mat-option *ngIf='carriersData.isLoading()'>
            <mat-spinner diameter="20" color="accent"></mat-spinner>
          </mat-option>
          <mat-option *ngIf="!carriersData.isLoading() && carriersData.getLoadError()">
            {{'PROPOSALS.DIALOG.RESERVATION.ERROR.LOAD_ERROR' | translate}}
          </mat-option>
          <mat-option *ngFor='let carrier of carriersData.loadedObjects | async' 
                      [value]="carrier">
                      {{ carrier.getName()}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>
    <div class="py-8" fxLayout="row" fxLayoutAlign="space-between center">
      <button mat-raised-button
              color="warn"
              [disabled] = "executor.isProcessing() || !isReserved()"
              (click)='removeReserve()'>{{'PROPOSALS.DIALOG.RESERVATION.REMOVE' | translate}}
      </button>

      <button mat-raised-button
              cdkFocusInitial
              color="accent"
              [disabled] = "executor.isProcessing()"
              (click)='reserve()'>{{'PROPOSALS.DIALOG.RESERVATION.CONFIRMBUTTON' | translate}}
      </button>
    </div>
  </div>

</div>
