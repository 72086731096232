import { Component, ViewEncapsulation } from '@angular/core';
import { LocaleService } from 'app/components/locale/locale.service';
import { CurrentUser, UserSettings } from 'app/components/data/core/models/base/user';
import { settings } from '../locale/locale';

@Component({
    selector     : 'user-settings-panel',
    templateUrl  : './user-settings.component.html',
    styleUrls    : ['./user-settings.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UserSettingsPanelComponent
{
    settings: UserSettings;
    constructor(
        private localeService: LocaleService,
    ) {
        this.localeService.loadLocale(settings);
    }

    save() {
        CurrentUser.setSettings(this.settings);
        CurrentUser.save();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.settings = CurrentUser.getSettings();
        });
    }
}
