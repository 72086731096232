import {locale as remove_user_dialog_en} from "./en/locale.remove_user_dialog";
import {locale as remove_user_dialog_ru} from "./ru/locale.remove_user_dialog";

export const removedialog = [
  {
    lang: "en",
    data: remove_user_dialog_en
  },
  {
    lang: "ru",
    data: remove_user_dialog_ru
  }
];
