import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { OrdersInProgressComponent } from './inprogress.component';
import { MaterialModule } from 'app/main/material.module';

import { TableHeaderModule} from 'app/components/tableheader/tableheader.module';
import { TableModule } from 'app/components/table/table.module';

import { OrderInfoModule } from 'app/main/orders/dialog/orderinfo/order-info-dialog.module';
import { CancelOrderDialogComponent } from 'app/main/orders/dialog/cancelconfirmation/cancel-order-dialog.component';
import { DeleteOrderDialogModule } from 'app/main/orders/dialog/deleteconfirmation/delete-order-dialog.module';

import { OrdersDialogPresenterService } from 'app/main/orders/dialog/ordersdialogspresenter/orders-dialog-presenter.service';

import { FinishOrderDialogModule } from 'app/main/orders/dialog/finishorder/finish-order-dialog.module'
import { AlertModule } from 'app/components/alert/alert.module';
import { DialogHeaderModule } from 'app/components/dialogs/dialog-header/dialog-header.module';
import { ActionBarModule } from 'app/components/action-bar/action-bar.module';

@NgModule({
    declarations: [
        OrdersInProgressComponent,
        CancelOrderDialogComponent,
    ],
    imports: [
        TranslateModule,
        FuseSharedModule,
        MaterialModule,
        TableHeaderModule,
        TableModule,
        OrderInfoModule,
        DeleteOrderDialogModule,
        FinishOrderDialogModule,
        AlertModule,
        DialogHeaderModule,
        ActionBarModule,
    ],
    exports: [
        OrdersInProgressComponent,
        CancelOrderDialogComponent,
    ],
    providers: [
        OrdersDialogPresenterService,
    ]
})

export class OrdersInProgressModule
{
}
