import { Component, ViewEncapsulation, ViewChild } from '@angular/core';

import { CarriersTableData } from './carriers-tabledata';
import { TableComponent, TableOptions } from 'app/components/table/table.component';

import { LocaleService } from 'app/components/locale/locale.service';
import { transfer, order_fields, } from '../../../locale/locale';
import { units } from 'app/main/locale/locale';
import { Carrier } from 'app/components/data/core/models/persons/carrier';

let locales = [
  transfer,
  order_fields,
  units
];

@Component({
  selector: 'carrier-select',
  templateUrl: './carrier-select.component.html',
  styleUrls: ['./carrier-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CarrierSelectComponent {
  tableData: CarriersTableData;
  tableOptions: TableOptions = {};
  @ViewChild(TableComponent) private table: TableComponent;
  @ViewChild('searchbar') searchBar;

  constructor(
    private localeService: LocaleService,
  ) {
    this.localeService.loadLocales(locales);
    this.tableData = new CarriersTableData();
    this.tableOptions = { hiddenHeader: true, selectable: true, singleSelection: true};
  }

  public getSelectedCarriers(): Carrier[] {
    return this.table.selectedValues();
  }

  searchStringChanged(str) {
    this.table.search(str)
  }

  reload() {
    this.table.reloadData();
  }
}
