<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="warn"
                 title="{{'REMOVE_USER_DIALOG.TITLE' | translate}}" >
  </dialog-header>
  <div class="pt-8 px-16">
    <p>{{'REMOVE_USER_DIALOG.MESSAGE' | translate}}</p>
    
    <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button
              color="warn"
              (click)="confirmDeletion()"
              cdkFocusInitial>{{'REMOVE_USER_DIALOG.BUTTON.CONFIRM' | translate}}
      </button>
    </div>
  </div>
</div>
