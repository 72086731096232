
import { TableData, TableRowData } from 'app/components/table/tabledata';
import { ServerTableDataSource } from 'app/components/table/data_sources/server-data-source';
import { LiveQuerySubscriber } from 'app/components/table/data_sources/live-query-subscriber';

import * as AccessQueries from 'app/components/access/query';
import { PFQuery } from 'app/components/data/core/models/base/query';
import { Carrier } from 'app/components/data/core/models/persons/carrier';

export class CarriersTableData extends TableData {

  constructor() {
    super();
    let columns = [
      new TableRowData({
        key: 'name',
        header: 'TRANSFER.TAB.CARRIERS.TABLEROW.NAME',
        cell: (carrier: Carrier) => `${carrier.getName()}`
      }),
    ];
    
    this.setColumns(columns);

    let tableDataSource = new ServerTableDataSource('Carrier');
    tableDataSource.queryModify = function (query: PFQuery): PFQuery {
      return AccessQueries.filterDeleted(query);
    };
    tableDataSource.queryFilter = (filter, query) => {
      query.contains("name", filter);
      return query;
    };
    this.setProvider(tableDataSource);
  }
}
