
import { TableDataSource } from './table.component';
import { Sort } from '@angular/material/sort';
import { PFObject } from '../data/core/models/base/object';
import {TreeNode} from "../hierarchical-checkbox/tree-node.model";

export class TableData {

  displayedColumns;

  public columns: TableRowData[];
  public provider: TableDataSource;

  public pagesSize: number[];

  constructor() { }

  setColumns(cols: TableRowData[]) {
    this.columns = cols;
    if (this.columns != undefined)
      this.displayedColumns = this.columns.map(x => x.key);
  }

  setProvider(dataProvider: TableDataSource) {
    this.provider = dataProvider;
  }

  public canSortColumn(column) {
    return true;
  }

  public canSortByDateColumn(column) {
    return true;
  }

  public getStatusFilterData() {
    let nodes: TreeNode[];
    return nodes;
  }

  getPageSizes(): number[] {
    if (this.pagesSize != null) {
      return this.pagesSize;
    }

    return [25, 50, 100];
  }

  isWarnRow(_) {
    return false;
  }

  isMarkDeleted(object): boolean {
    if (object instanceof PFObject)
      return object.get("deleted");
    return false;
  }
}

export class RowParameters {
  constructor(
    public flex: string = 'fill',
    public isHTML: boolean = false) { }
}

interface TableRow {
  key: string,
  header: string,
  cell: (any) => string,
  cellClass?: (any) => any,
  cellStyle?: (any) => any,
  headerCellStyle?: () => any,
  params?: RowParameters
}

export class TableRowData implements TableRow {
  get key(): string {
    return this.rowData.key;
  };
  get header(): string {
    return this.rowData.header;
  };
  get cell(): (any) => string {
    return this.rowData.cell;
  };
  get cellClass(): (any) => string {
    return this.rowData.cellClass;
  };

  get cellStyle(): (any) => Map<string, string> {
    return this.rowData.cellStyle;
  }

  get headerCellStyle(): () => Map<string, string> {
    return this.rowData.headerCellStyle;
  }

  get params(): RowParameters {
    return this.rowData.params;
  }

  constructor(
    private rowData: TableRow
  ) { }

  isHTML(): boolean {
    if (this.params) {
      return this.params.isHTML;
    }
    return false;
  }

  flex() {
    if (this.params) {
      return this.params.flex;
    }
    return 'fill';
  }
}

export class TableSort implements Sort {
  constructor(
    public active: string,
    public direction: "desc" | "asc",
  ) { }
}
