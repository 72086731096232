
export const locale = {
  'VEHICLES': {
    'ERROR': {
      'UNDEFINED': 'Произошла неизвестная ошибка',
      'RELATION': {
        'UNIT': "Не удалось удалить запись '{{value}}', так как от этой записи зависит экипаж. Это может быть активный экипаж, тогда вы должны сначала его освободить в разделе 'Список водителей'. Если эта запись есть в экипаже, который завершил заказ, то необходимо сначала удалить все заказы с этим экипажем.",
        'VEHICLE': "Не удалось удалить запись '{{value}}', так как от этой записи зависит Автомобиль. Вы должны сначала его удалить в разделе 'Автомобили и прицепы'.",
        'MODEL': "Не удалось удалить запись '{{value}}', так как от этой записи зависит Модель. Вы должны сначала его удалить в разделе 'Автомобили и прицепы'.",
      }
    },
    'ROWOPERATION': {
      'APPROVE': 'Утверждение',
      'EDIT_PASSES': 'Изменение пропусков',
    },
    'TABLEROW': {
      'GROUPNAME': 'Название группы',
      'MODELNAME': 'Название модели',
      'BRANDNAME': 'Название марки',
      'CARRIER': 'Перевозчик',
      'NUMBER': 'Номер',
      'TONNAGE': 'Тоннаж',
      'APPROVE_STATUS': 'Статус',
      'EQUIPMENT': 'Оборудование',
      'PASS': 'Пропуск',
      'INSPECTION': 'Срок действия тех. осмотра',
      'INSURANCE': 'Срок действия ОСАГО',
    },
    'TITLE': 'Автомобили и прицепы',
    'TABS': {
      'VEHICLES': 'Автомобили',
      'TRAILERS': 'Прицепы',
      'BRANDS': 'Марки',
      'MODELS': 'Модели',
      'TYPES': 'Группы',
    },
  }
};
