
<div class="page-layout carded fullwidth" fusePerfectScrollbar>

  <div class="top-bg primary"></div>
    <div class="center">
    <table-header headerTitle="{{'REPORTS.TITLE' | translate}}" 
                  iconName="rate_review"
                  noSearchBar="true"></table-header>

    <div class="mat-elevation-z8 fuse-white mb-16 px-16">

      <form [formGroup]="formGroup">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">

          <mat-form-field fxFlex="45">
            <input
              matInput
              [matDatepicker]="beginDatePicker"
              placeholder="{{'REPORTS.BEGINDATE' | translate}}"
              formControlName="beginDate"
              required>
            <mat-datepicker-toggle matSuffix [for]="beginDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #beginDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field fxFlex="45">
            <input
              matInput
              [matDatepicker]="endDatePicker"
              placeholder="{{'REPORTS.ENDDATE' | translate}}"
              formControlName="endDate"
              required>
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
          </mat-form-field>

        </div>
      </form>

      <div *ngIf='generating'>
        <mat-spinner diameter="20" color="accent"></mat-spinner>
      </div>

      <div class="mb-16" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button
                color="accent"
                (click)='createReport()'
                [disabled]='generating'>{{'REPORTS.CREATE' | translate}}
        </button>
      </div>

    </div>
  </div>
</div>
