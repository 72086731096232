<div class="m-0">
  <dialog-header headerClass="primary" 
                 title="{{'TRANSFER.TITLE' | translate}}">
  </dialog-header>
  
  <div class="dialog-content pt-8 px-16">
    <div>
      <form [formGroup]="form">
        <div *ngIf="orders.length === 1" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
          fxLayoutAlign.gt-xs="space-between center">
          <mat-form-field class="no-padding" fxFlex appearance="outline">
            <mat-label>{{'ORDER.FIELD_NAME.CUSTOMER' | translate}}</mat-label>
            <input matInput formControlName="customer">
          </mat-form-field>
  
          <mat-form-field class="no-padding ml-12" fxFlex="15" appearance="outline">
            <mat-label>{{'ORDER.FIELD_NAME.TONNAGE' | translate}}</mat-label>
            <input matInput formControlName="tonnage">
          </mat-form-field>
        </div>
  
        <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
          fxLayoutAlign.gt-xs="space-between center">
          <mat-form-field class="no-padding" fxFlex="49" appearance="outline">
            <mat-label>{{'ORDER.FIELD_NAME.LOADING_ENTRANCE' | translate}}</mat-label>
            <textarea class="address-area" matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="2"
              formControlName="loadingEntrance"></textarea>
          </mat-form-field>
  
          <mat-form-field class="no-padding" fxFlex="49" appearance="outline">
            <mat-label>{{'ORDER.FIELD_NAME.UNLOADING_ENTRANCE' | translate}}</mat-label>
            <textarea class="address-area" matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="2"
              formControlName="unloadingEntrance"></textarea>
          </mat-form-field>
        </div>
      </form>
    </div>
    
    <mat-tab-group class="transfer-selector-group">
      <mat-tab *ngIf="isSingleOrderSelected()" label="{{'TRANSFER.TAB.DRIVERS.TITLE' | translate}}">
        <div class="pt-16">
          <unit-select [order]="getFirstOrder()" [extended]=true></unit-select>
        </div>
      </mat-tab>
      <mat-tab *ngIf="isAllowTransferToCarrier()" label="{{'TRANSFER.TAB.CARRIERS.TITLE' | translate}}">
        <div class="pt-16">
          <carrier-select></carrier-select>
        </div>
      </mat-tab>
    </mat-tab-group>

    <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button 
              color="accent" 
              (click)='checkAndTransfer()'
              [disabled]="executor.isProcessing()">
        {{'TRANSFER.TRANSFERBUTTON' | translate}}
      </button>
    </div>
  </div>
</div>
