export const locale = {
  'ARTICLE' : {
    'ERROR' : {
      'UNDEFINED' : 'Произошла неизвестная ошибка',
      'RELATION' : "Не удалось удалить объект '{{value}}'. Т.к. от этого объекта зависят другие объекты",
    },
    'TABLEROW' : {
      'SHAPENAME' : 'Название формы',
      'BRANDNAME' : 'Название марки',
      'TYPENAME' : 'Название вида',
    },
    'TITLE' : 'Товары',
    'TABS' : {
      'TYPES' : 'Виды',
      'BRANDS': 'Марки',
      'SHAPES': 'Форма',
    },
  }
};
