import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'dialog-header',
    templateUrl: './dialog-header.component.html',
    styleUrls: ['./dialog-header.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class DialogHeaderComponent {

    @Input()
    title: string;
    @Input()
    subtitle: string;

    @Input()
    headerClass: string;

    @Input()
    hideCloseButton: boolean;
    @Output() clickAction: EventEmitter<any>;

    constructor() { 
        this.clickAction = new EventEmitter();
    }

    click(event) {
        if (this.clickAction) {
            this.clickAction.emit(event);
        }
    }

}