import {locale as attachment_en} from "./en/locale.attachment";
import {locale as attachment_ru} from "./ru/locale.attachment";

export const attachment = [
  {
    lang: "en",
    data: attachment_en
  },
  {
    lang: "ru",
    data: attachment_ru
  }
];
