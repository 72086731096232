
import { Carrier } from "./carrier";

import { PersonWithUser } from './person';

export class Dispatcher extends PersonWithUser {
  constructor() { super("Dispatcher"); }

  getCarrier(): Carrier {return super.get("carrier");}
  setCarrier(value: Carrier): void {super.set("carrier", value);}

  copy(): Dispatcher {
    let copy = new Dispatcher();
    copy.setName(this.getName());
    copy.setCarrier(this.getCarrier());

    return copy;
  }
}
