export const locale = {
  'ORDERINFO': {
    'TITLE' : 'Информация о заказе №',
    'MAIN': 'Основная информация',
    'PROGRESS': 'Прогресс',
    'DISTRIBUTED' : 'Распределено',
    'TABLEROW': {
      'TONNAGE': 'Тоннаж',
      'DRIVER': {
        'NAME': 'Водитель',
        'PHONE': 'Телефон'
      },
      'AUTO': 'Авто',
      'AUTONUMBER': 'Номер авто',
      'TRAILER_NUMBER': 'Номер прицепа',
      'STATE': 'Состояние',
      'PHONE_NUMBER': 'Контакт',
    },
    'NO_OFFERS': "Этот заказ еще не имеет прогресса",
    'COMMENT': "Комментарий",
    'PHOTONOTUPLOADED': 'Фотография еще не загружена',
    'SHOWONMAP' : 'Показать на карте',
    'DOWNLOADTRACK': 'Скачать трек',
    'ORDER_TRACK': 'Трек заказа №',
    'TONNAGE_INFO': 'Факт. тоннаж: {{actualTonnage}} тонн',
    'KILOMETERS_INFO': 'Километраж: {{distance}} км',
    'WAYBILL_INFO': 'Номер накладной: {{number}}',
    'DIALOG': {
      'FIELD_EDIT': {
        'TITLE': 'Изменить значение',
        'TONNAGE': 'Тоннаж',
        'KILOMETERS': 'Километраж',
        'WAYBILL_NUMBER': 'Номер накладной',
        'SAVE': 'Сохранить',
        'ERROR': {
          'LIMIT': 'Должно быть больше 0',
        },
      },
    },
    'ERROR' : {
      'WRONG_COORDINATE' : 'Координаты не соответствуют запланированным',
    },
  },
};
