
export const locale = {
  'ORDER': {
    'DIALOG': {
      'TITLE': {
        'CREATE': 'Create order',
        'EDIT': 'Edit order',
      },
      'CONFIRMBUTTON': {
        'SAVE': 'Save',
        'CREATE': 'Create',
      },
      'CUSTOMER': 'Customer',
      'SUPPLIER': 'Supplier',
      'NEXT': 'Next',
      'BACK': 'Back',
      'CLOSE': 'Close',
    },
    "ERROR": {
      'VERIFY_ERROR': "Can't verify customer",
      'LOAD_ERROR': 'Load error',
      'CUSTOMER_NOT_CHECKED': 'Customer not checked',
      'MUST_SELECT': 'You need to select item from list',
      'TONNAGE_LIMIT': 'Must be 1-99',
      'NOT_LESS_THEN': 'Not less then {{value}}',
      'NOT_GREATER_THEN': 'Not greater then {{value}}',
      'INVALID_INTERVAL': 'Invalid interval',
      'SAVE_ERROR': 'Failed to save object. Try again later.',
      'NO_DISTANCE': 'No distance to fill',
      'NO_LOADING_POINT': 'Loading point not selected',
    },
  },
};
