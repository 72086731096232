import {Component, ElementRef, Input, ViewChild} from '@angular/core';

import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

import {Supplier} from 'app/components/data/core/models/persons/supplier';
import {Entrance, LoadingPoint} from 'app/components/data/core/models/points/points';
import {ArticleBrand, ArticlesFilter, ArticleType} from 'app/components/data/core/models/articles/article';
import {Order, PriceType, Type} from 'app/components/data/core/models/order/order';
import {PFQuery} from 'app/components/data/core/models/base/query';
import {PFObject} from 'app/components/data/core/models/base/object';
import {step_order_create_dialog} from '../locale/locale';

import {SupplierCreateDialog} from 'app/main/admin/users/dialogs/supplier/create/supplier-create-dialog.component';
import {
    SupplierLoadingPointDialog
} from 'app/main/admin/users/dialogs/supplier/loading_points/create/loading-point-create-dialog.component';

import {EntranceDialog} from 'app/main/admin/users/dialogs/entrance/entrance-dialog.component';
import {DialogPresenterService} from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import {DateHelper} from 'app/components/helpers/date.helper';

import * as FormHelper from 'app/components/form-helper/form-helper';
import * as CustomValidators from 'app/components/validators/validators';

import {CurrentUser} from 'app/components/data/core/models/base/user';
import {Customer} from 'app/components/data/core/models/persons/customer';
import {DataLoader} from 'app/components/data/data-loader';
import {ConfigurationService} from 'app/main/admin/settings/configuration.service';
import {filterDeleted} from 'app/components/access/query';
import {QueryResultFilter} from 'app/components/access/queryResultFilter';
import {OrderHelper} from 'app/components/helpers/order.helper';
import {Distance} from 'app/components/data/core/models/distance';
import {LocalNotificationService} from 'app/components/local-notifications-service/local-notifications-service';
import {TranslateService} from '@ngx-translate/core';
import {LocaleService} from '../../../../../../components/locale/locale.service';

enum LoadType {
    Supplier,
    Transshipment,
};

@Component({
    selector: 'supplier-step',
    templateUrl: './supplier-step.component.html',
    styleUrls: ['./supplier-step.component.scss'],
})

export class SupplierStepComponent {
    compareFunction = PFObject.compareFn;

    @Input() order: Order;
    @Input() editing: boolean;

    public form: UntypedFormGroup;

    public suppliersData: DataLoader;
    public priceTypes;

    availableLoadTypes = [
        LoadType.Supplier,
        LoadType.Transshipment,
    ];

    availableOrderTypes = [
        Type.Default,
        Type.Carriage,
    ];

    availableSuppliers: Supplier[];
    availablePoints: LoadingPoint[];
    availableEntrances: Entrance[];
    availableTypes: ArticleType[];
    availableBrands: ArticleBrand[];

    get loadType(): LoadType {
        return this.form.controls.loadType.value;
    }

    set loadType(value: LoadType) {
        this.form.controls.loadType.setValue(value);
    }

    get orderType(): Type {
        return this.form.controls.orderType.value;
    }

    set orderType(value: Type) {
        this.form.controls.orderType.setValue(value);
    }

    get supplier(): Supplier {
        return this.form.controls.supplier.value;
    };

    set supplier(value: Supplier) {
        this.form.controls.supplier.setValue(value);
        this.supplierChanged(value);
    };

    get loadingPoint(): LoadingPoint {
        return this.form.controls.loadingPoint.value;
    };

    set loadingPoint(value: LoadingPoint) {
        this.form.controls.loadingPoint.setValue(value);
        this.pointChanged(value);
    };

    get loadingDate(): Date {
        let date = DateHelper.setDayBegin(new Date(this.form.controls.loadingDate.value));
        date.setHours(this.loadingTime);
        return date;
    }

    set loadingDate(value: Date) {
        this.form.controls.loadingDate.setValue(value);
        if (value) {
            this.loadingTime = value.getHours();
        }
    }

    private get loadingTime(): number {
        return this.form.controls.loadingTime.value;
    }

    private set loadingTime(value: number) {
        this.form.controls.loadingTime.setValue(value);
    }

    get entrance(): Entrance {
        return this.form.controls.loadingEntrance.value;
    };

    set entrance(value: Entrance) {
        this.form.controls.loadingEntrance.setValue(value);
    };

    get brandType(): ArticleType {
        return this.form.controls.articleType.value;
    };

    set brandType(value: ArticleType) {
        this.form.controls.articleType.setValue(value);
        this.articleTypeChanged(value);
    };

    get brand(): ArticleBrand {
        return this.form.controls.articleBrand.value;
    };

    set brand(value: ArticleBrand) {
        this.form.controls.articleBrand.setValue(value);
    };

    get salePriceType(): number {
        return this.form.controls.salePriceType.value;
    };

    set salePriceType(value: number) {
        this.form.controls.salePriceType.setValue(value);
    };

    get saleTariff(): number {
        return this.form.controls.saleTariff.value;
    };

    set saleTariff(value: number) {
        this.form.controls.saleTariff.setValue(value);
    };

    get deliveryPriceType(): number {
        return this.form.controls.deliveryPriceType.value;
    };

    set deliveryPriceType(value: number) {
        this.form.controls.deliveryPriceType.setValue(value);
    };

    get deliveryTariff(): number {
        return this.form.controls.deliveryTariff.value;
    };

    set deliveryTariff(value: number) {
        this.form.controls.deliveryTariff.setValue(value);
    };

    get timeout(): number {
        return this.form.controls.timeout.value;
    };

    set timeout(value: number) {
        this.form.controls.timeout.setValue(value);
    };

    get tonnage(): number {
        return this.form.controls.tonnage.value;
    }

    set tonnage(value: number) {
        this.form.controls.tonnage.setValue(value);
    }

    get comment(): string {
        return this.form.controls.comment.value;
    }

    set comment(value: string) {
        this.form.controls.comment.setValue(value);
    }

    get distance(): number {
        return this.form.controls.distance.value;
    }

    set distance(value: number) {
        this.form.controls.distance.setValue(value);
    }


    @ViewChild('supplierInput') supplierInput: ElementRef;

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private dialogPresenterService: DialogPresenterService,
        private configService: ConfigurationService,
        private notifications: LocalNotificationService,
        private localeService: LocaleService,
        private translate: TranslateService
    ) {
        this.priceTypes = Order.priceTypesList;


        this.suppliersData = this.makeSuppliersData();

        localeService.loadLocale(step_order_create_dialog);

        this.form = this._formBuilder.group({
            loadType: [null, Validators.required],
            orderType: [null, Validators.required],
            supplier: [null, [CustomValidators.isObjectValidator, Validators.required]],
            loadingPoint: null,
            loadingEntrance: [null, Validators.required],
            loadingDate: [null, Validators.required],
            loadingTime: [null, [Validators.min(0), Validators.max(23)]],

            articleBrand: [null, Validators.required],
            articleType: [null, Validators.required],
            tonnage: [0, [Validators.min(1), Validators.max(99)]],
            distance: [0, Validators.min(0)],
            salePriceType: [null, Validators.required],
            saleTariff: [0, Validators.min(0)],
            deliveryPriceType: [null, Validators.required],
            deliveryTariff: [0, Validators.min(0)],
            timeout: [0, Validators.min(0)],
            comment: [null],
        });
    }

    ngOnInit() {
        this.fillData();
        this.loadData();
    }

    ngAfterViewInit() {
        this.form.controls.supplier.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe((value) => {
            let filter = value instanceof Supplier ? null : value;
            this.updateAvailableSuppliers(this.loadType, filter);
        });
    }

    //****************** OrderType ******************
    nameForOrderType(type: Type) {
        return OrderHelper.nameForOrderType(type);
    }

    //****************** LoadType *******************
    nameForLoadType(type: LoadType) {
        var name = '';
        switch (type) {
            case LoadType.Supplier:
                name = 'ORDER.LOAD_TYPE.SUPPLIER';
                break;
            case LoadType.Transshipment:
                name = 'ORDER.LOAD_TYPE.TRANSSHIPMENT';
                break;
            default:
                name = 'ORDER.LOAD_TYPE.UNDEFINED';
        }
        return name;
    }

    loadTypeChanged(toType: LoadType) {
        this.updateAvailableSuppliers(toType, null);
        this.updateSelectedSupplier();
    }

    //****************** Supplier *******************
    addSupplier() {
        this.dialogPresenterService.showDialog({
            dialog: SupplierCreateDialog,
            panel: 'supplier-create-dialog-container',
            data: {action: 'create'},
        }, function (supplier) {
            if (supplier) {
                this.supplier = supplier;
                this.availableSuppliers.push(supplier);
            }
        });
    }

    supplierDisplay(supplier?: Supplier) {
        return supplier ? supplier.getName() : undefined;
    }

    supplierChanged(supplier: Supplier) {
        this.updateAvailablePointsFor(supplier);
        this.updateSelectedPointFor(supplier);
        this.updateAvailableTypes();
        this.updateSelectedType();
    }

    private makeSuppliersData() {
        let loader = new DataLoader('Supplier');
        loader.setQueryModificationBlock((query: PFQuery) => {
            query.includes([
                'articleBrands.type',
                'loadingPoints.entrances',
            ]);
            return filterDeleted(query);
        });
        return loader;
    }

    private updateAvailableSuppliers(loadType: LoadType, filter) {
        let suppliers = this.suppliersData.getLoadedItems();
        switch (loadType) {
            case LoadType.Supplier:
                suppliers = suppliers.filter((s: Supplier) => s.isTransferPoint() == false);
                break;
            case LoadType.Transshipment:
                suppliers = suppliers.filter((s: Supplier) => s.isTransferPoint() == true);
                break;
        }

        if (CurrentUser.isDispatcher()) {
            let carrier = CurrentUser.carrier();
            if (!carrier.getShowAllSuppliers()) {
                let ids = carrier.getSuppliersList().map(s => s.id);
                suppliers = suppliers.filter((s: Supplier) => ids.indexOf(s.id) != -1);
            }
        }
        if (filter && filter.length) {
            suppliers = suppliers.filter(s => s.getName().toLowerCase().indexOf(filter.toLowerCase()) != -1);
        }
        let suppliersOrder = this.configService.getConfiguration().getSupplierOrder();
        this.availableSuppliers = PFObject.sort(suppliers, suppliersOrder);
    }

    private updateSelectedSupplier() {
        let defaultSupplier = this.order.supplier();
        this.supplier = PFObject.priorValue(this.availableSuppliers, defaultSupplier);
    }

    // **************** LoadingPoint ****************
    addLoadingPoint() {
        let supplier = this.supplier;
        this.dialogPresenterService.showDialog({
            dialog: SupplierLoadingPointDialog,
            panel: 'supplier-loading-point-dialog-container',
            data: {action: 'create'},
        }, function (point) {
            if (point) {
                supplier.addLoadingPoint(point);
                this.availablePoints.push(point);
                this.loadingPoint = point;
            }
        });
    }

    pointChanged(point: LoadingPoint) {
        this.updateAvailableEntrancesFor(point);
        this.updateSelectedEntranceFor(point);
    }

    private updateAvailablePointsFor(supplier: Supplier) {
        if (!supplier) {
            return;
        }
        this.availablePoints = supplier.getLoadingPoints();

    }

    private updateSelectedPointFor(supplier) {
        let defaultPoint;
        if (supplier) {
            defaultPoint = this.order.loadingPoint();
        }
        this.loadingPoint = PFObject.priorValue(this.availablePoints, defaultPoint);
    }

    //*************** LoadingEntrance ***************

    addLoadingEntrance() {
        let point = this.loadingPoint;
        this.dialogPresenterService.showDialog({
            dialog: EntranceDialog,
            panel: 'entrance-dialog-container',
            data: {action: 'create', address: point.getAddress()},
        }, function (entrance) {
            if (entrance) {
                point.addEntrance(entrance);
                this.availableEntrances.push(entrance);
                this.entrance = entrance;
            }
        });
    }

    private updateAvailableEntrancesFor(point: LoadingPoint) {
        if (!point) {
            return;
        }
        this.availableEntrances = point.getEntrances();

    }

    private updateSelectedEntranceFor(point: LoadingPoint) {
        let defaultEntrance = this.order.loadingEntrance();
        let entrances;
        if (point) {
            entrances = point.getEntrances();
        }
        this.entrance = PFObject.priorValue(entrances, defaultEntrance);
    }

    //***************** SaleTariff ******************
    reloadSuppliers() {
        this.suppliersData.loadAll().then(() => {
            this.updateAvailableSuppliers(this.loadType, null);
            this.updateSelectedSupplier();
        });
    }

    // *************** ArticleType ******************
    articleTypeChanged(type: ArticleType) {
        this.updateAvailableBrands(type);
        this.updateSelectedBrand();
    }

    private updateSelectedType() {
        let brand = this.order.articleBrand();
        let defaultType;
        if (brand) {
            defaultType = brand.type();
        }
        this.brandType = PFObject.priorValue(this.availableTypes, defaultType);
    }

    private updateAvailableTypes() {
        if (!this.supplier) {
            return;
        }
        let brands = QueryResultFilter.filterDeleted(this.supplier.getBrands());
        this.availableTypes = ArticlesFilter.collectBrandsTypes(brands);
    }

    // *************** ArticleType ******************

    private updateSelectedBrand() {
        let brand = this.order.articleBrand();
        this.brand = PFObject.priorValue(this.availableBrands, brand);
    }

    private updateAvailableBrands(type: ArticleType) {
        if (!this.supplier) {
            return;
        }
        let brands = QueryResultFilter.filterDeleted(this.supplier.getBrands());
        this.availableBrands = ArticlesFilter.filterBrandsWithType(brands, type);
    }

    // **********************************************

    private async loadData() {
        await this.configService.fetch();
        this.reloadSuppliers();
    }

    private fillData() {
        let supplier = this.order.supplier();
        let loadingPoint = this.order.loadingPoint();
        let entrance = this.order.loadingEntrance();

        let brand = this.order.articleBrand();
        let type;
        if (brand) {
            type = brand.type();
        }

        let timeout = this.order.inactivityTimeInterval();
        if (timeout === undefined) {
            timeout = Order.defaultInactivityTimeInterval;
        }

        let loadingDate = this.order.loadingDate();
        if (!loadingDate) {
            loadingDate = new Date();
        }

        let salePriceType = this.order.salePriceType();
        if (salePriceType === undefined) {
            salePriceType = PriceType.MULTIPLE;
        }

        let deliveryPriceType = this.order.deliveryPriceType();
        if (deliveryPriceType === undefined) {
            deliveryPriceType = PriceType.MULTIPLE;
        }

        var loadType = LoadType.Supplier;
        if (supplier && supplier.isTransferPoint()) {
            loadType = LoadType.Transshipment;
        }

        var orderType = this.order.getType();
        if (orderType == undefined) {
            orderType = Type.Default;
        }

        this.orderType = orderType;
        this.loadType = loadType;
        this.supplier = supplier;
        this.loadingPoint = loadingPoint;
        this.entrance = entrance;
        this.loadingDate = loadingDate;
        this.brandType = type;
        this.brand = brand;
        this.tonnage = this.order.tonnage();
        this.salePriceType = salePriceType;
        this.saleTariff = this.order.saleTariff();
        this.deliveryPriceType = deliveryPriceType;
        this.deliveryTariff = this.order.deliveryTariff();
        this.timeout = timeout;
        this.comment = this.order.comment();
        this.distance = this.order.distance();
    }

    setInitialFocus() {
        if (!this.editing) {
            setTimeout(() => {
                this.supplierInput.nativeElement.focus();
            }, 1000);
        }
    }

    canAdd(): boolean {
        return CurrentUser.isAdministrator() || CurrentUser.isLogistician() || CurrentUser.isManager();
    }

    canShowSalePrice() {
        if (CurrentUser.isDispatcher()) {
            return CurrentUser.carrier().getOrderPermissions().salePrice;
        }
        return true;
    }

    priceTypeName(value: PriceType) {
        return Order.nameForPriceType(value);
    }

    updateSalePriceForCustomer(customer: Customer) {
        if (!customer) {
            return;
        }
        this.salePriceType = customer.getDefaultPriceType();
    }

    checkForm(): boolean {
        return FormHelper.checkForm(this.form);
    }

    getDistance() {
        let loadingPoint = this.loadingPoint;
        if (loadingPoint) {
            let query = new PFQuery('Distance');
            query.equalTo('loadingPoint', loadingPoint);
            query.equalTo('unloadingPoint', this.order.unloadingPoint());
            query.first().then((d: Distance) => {
                if (d) {
                    this.distance = d.distance();
                } else {
                    this.notifications.showErrorNotification(this.translate.instant('ORDER.DIALOG.ERROR.NO_DISTANCE'));
                }
            });
        } else {
            this.notifications.showErrorNotification(this.translate.instant('ORDER.DIALOG.ERROR.NO_LOADING_POINT'));
        }
    }

    protected readonly CurrentUser = CurrentUser;
}
