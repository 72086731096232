import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { PFObject } from 'app/components/data/core/models/base/object';

import { LocaleService } from 'app/components/locale/locale.service';
import { create_logistician } from '../../locale/locale';

import { CloudExecutor } from 'app/components/data/cloud-executors/cloud-executor';

import * as FormHelper from 'app/components/form-helper/form-helper';
import { Logistician } from 'app/components/data/core/models/persons/logistician';

@Component({
    selector   : 'logistician-dialog',
    templateUrl: './logistician-dialog.component.html',
    styleUrls  : ['./logistician-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class LogisticianDialog {
  compareFunction = PFObject.compareFn;

  public form: UntypedFormGroup;
  private object: Logistician;

  public title: string;
  public buttonName: string;

  public dataSaver = new CloudExecutor;

  constructor (
    public dialogRef: MatDialogRef<LogisticianDialog>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _formBuilder: UntypedFormBuilder,
    private localeService: LocaleService
  ) {
    this.localeService.loadLocale(create_logistician);
    this.object = this._data.object;

    if (this._data.action === 'create') {
        this.title = 'DIALOG.LOGISTICIAN.TITLE.CREATE';
        this.buttonName = 'DIALOG.LOGISTICIAN.CONFIRMBUTTON.CREATE';
    } else if (this._data.action === 'edit') {
        this.title = 'DIALOG.LOGISTICIAN.TITLE.EDIT';
        this.buttonName = 'DIALOG.LOGISTICIAN.CONFIRMBUTTON.SAVE';
    }

    if (this.object == null) {
      this.object = new Logistician();
    }
    this.form = this.createContactForm();
  }

  createContactForm(): UntypedFormGroup {
    let formGroup = {
      name: this.object.getName(),
    };
    return this._formBuilder.group(formGroup);
  }

  checkAndSave() {
    if (this.checkForm()) {
      this.object.setName(this.form.get('name').value);

      this.dataSaver.saveObject(this.object).then(obj => {
        this.dialogRef.close(obj);
      });
    }
  }

  checkForm(): boolean {
    return FormHelper.checkForm(this.form);
  }
}
