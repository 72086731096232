<div class="page-layout carded fullwidth" fusePerfectScrollbar>
  <div class="top-bg primary"></div>
    <div class="center">
      <table-header headerTitle="{{'ONMAP.TITLE' | translate}}" 
                    iconName="location_on"
                    noSearchBar="true"></table-header>
      <div class="mat-elevation-z8 fuse-white mb-16" fxLayout="column" fxFlex>
        <div class="h-100" fxLayout="row" fxLayoutAlign="space-between center">
          <div class="p-16" fxFlex="30">
            <div class="pb-8">
              <mat-label>{{'ONMAP.LABEL.STATUS' | translate}}</mat-label>
            </div>
            <div class="filter-box">
              <mat-select #statusFilter [value]="statuses[0]" (selectionChange)='applyFilter($event.value)'>
                <mat-option *ngFor='let status of statuses' [value]="status">{{('UNITS.STATUS.' + status) | translate}}</mat-option>
              </mat-select>
            </div>
          </div>
        </div>

        <div fxFlex class="m-8">
          <agm-map #map 
                   fxFill
                   [zoom]='5' 
                   [minZoom]='3' 
                   [latitude]="55.4507" 
                   [longitude]="37.3656" 
                   (mapClick)="map.lastOpen?.close();">
            <agm-marker-cluster imagePath="assets/images/markers/clusters/m">
              <agm-marker *ngFor="let tu of units | async" class="unit-marker"
                            [iconUrl] = "'assets/images/markers/marker_truck.png'"
                            [latitude]="tu.coordinate().latitude"
                            [longitude]="tu.coordinate().longitude"
                            (markerClick)="map.lastOpen?.close(); map.lastOpen = info">
                            <agm-info-window #info>
                              <strong>{{tu.driver().getName()}}</strong>
                              <p>{{tu.vehicleName()}}</p>
                              <p>{{tu.vehicle().number()}}</p>
                              <p *ngIf="tu.trailer()">{{tu.trailer().number()}}</p>
                              <p>{{tu.roundedSpeed()}} {{'COMMON.SPEEDUNIT' | translate}}</p>
                              <p>{{tu.lastVisitStr()}}</p>
                            </agm-info-window>
              </agm-marker>
            </agm-marker-cluster>

            <agm-marker-cluster imagePath="assets/images/markers/clusters/p">
              <ng-container *ngFor="let supplier of suppliers | async">
                <ng-container *ngFor="let point of supplier.getLoadingPoints()">
                    <agm-marker *ngFor="let entrance of point.getEntrances()" 
                                  [iconUrl] = "'assets/images/markers/marker_supplier.png'"
                                  [latitude]="entrance.getCoordinate().latitude"
                                  [longitude]="entrance.getCoordinate().longitude"
                                  (markerClick)="map.lastOpen?.close(); map.lastOpen = entranceInfo">
                                  <agm-info-window #entranceInfo>
                                    <strong>{{supplier.getName()}}</strong>
                                    <p>{{entrance.getAddress()}}</p>
                                  </agm-info-window>
                    </agm-marker>
                </ng-container>
              </ng-container>
            </agm-marker-cluster>
          </agm-map>
        </div>
      </div>
      
      
  </div>
</div>
