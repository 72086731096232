
export const locale = {
  'DIALOG' : {
    'DISPATCHER' : {
      'TITLE' : {
        'CREATE' : 'Создать диспетчера',
        'EDIT' : 'Редактировать диспетчера',
      },
      'CONFIRMBUTTON' : {
        'CREATE' : 'Создать',
        'SAVE' : 'Сохранить',
      },
      'FULLNAME' : 'ФИО',
      'CARRIER' : 'Перевозчик',
    },
    'ERROR' : {
      'ALREADYEXISTS' : 'Невозможно создать аккаунт, т.к. уже существует аккаунт с таким логином',
    }
  },
};
