export const locale = {
  'TRANSFER': {
    'TITLE': 'Transfer orders',
    'TRANSFERBUTTON': 'Transfer',
    'SEARCH': 'Search',
    'TAB': {
      'DRIVERS': {
        'SUBTAB': {
          'LIST': 'List',
          'MAP': 'Map',
        },
        'TITLE': 'Drivers',
        'CREATE_UNIT': 'Create unit',
        'CREATE_DRIVER': 'Create driver',
        'TUFILTERPLASEHOLDER': 'Transport unit group',
        'READYTOWORK': "Only 'Ready to work'",
      },
      'CARRIERS': {
        'TITLE': 'Carriers',
        'TABLEROW': {
          'NAME': 'Name',
        }
      },
    },

    'ERROR': {
      'LOW_TONNAGE': 'Unit has not enough capacity.',
      'NOTSELECTED': 'No selected units.',
      'DRIVER_NOT_APPROVED': 'It is not possible to transfer an order to a driver who has not yet been approved.',
      'UNDEFINED_ERROR': 'Something was wrong. Try again later...',
    }
  },
};
