import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ClickData } from 'app/components/table/table.component';
import { UnloadingPoint } from 'app/components/data/core/models/points/points';
import { Customer } from 'app/components/data/core/models/persons/customer';

import { DialogPresenterService } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';

import { LocaleService } from 'app/components/locale/locale.service';
import { customer, points } from 'app/main/admin/users/locale/locale';
import { equipment } from "app/main/locale/locale";
import { UnloadingPointsTableData } from './unloading-points/unloading-points.tabledata';
import { UnloadingPointDialog } from './unloading-points/unloading-points-dialog.component';
import { CloudExecutor } from 'app/components/data/cloud-executors/cloud-executor';
import { ActionBarOperation } from 'app/components/action-bar/action-bar.component';
import { TableOperationsHelper } from 'app/components/helpers/table-operations.helper';
import { TranslateService } from '@ngx-translate/core';
let locales = [
  customer,
  points,
  equipment,
];

@Component({
  selector: 'customer-edit-address-dialog',
  templateUrl: './customer-edit-address-dialog.component.html',
  styleUrls: ['./customer-edit-address-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CustomerEditAddressDialogComponent {
  public object: Customer;
  private dataSaver = new CloudExecutor;

  tableData: UnloadingPointsTableData;
  tableOperations: ActionBarOperation[] = [];

  constructor(
    private localeService: LocaleService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<CustomerEditAddressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private dialogPresenterService: DialogPresenterService
  ) {
    this.localeService.loadLocales(locales);
    this.object = this._data.object;
    this.tableOperations = this.makeTableOperations([]);
    this.updateTableData();
  }

  doubleClickHandler(click: ClickData) {
    this.showUnloadingPointsDialog('edit', click.data);
  }

  updateOperations(value: ClickData) {
    let selected = value.table.selectedValues();
    this.tableOperations = this.makeTableOperations(selected);
  }

  private makeTableOperations(selected): ActionBarOperation[] {
    return [
      TableOperationsHelper.makeCreateOperation(() => this.addAddress()),
      TableOperationsHelper.makeEditOperation(() => this.editAddress(selected), selected),
      TableOperationsHelper.makeRemoveOperation(() => this.removeAddress(selected), selected),
    ];
  }

  private addAddress() {
    this.showUnloadingPointsDialog('create', null);
  }

  private editAddress(selected) {
    this.showUnloadingPointsDialog('edit', selected[0]);
  }

  private removeAddress(selected) {
    this.showRemoveDialog(selected);
  }

  private showRemoveDialog(selected) {
    let handler = this;
    this.dialogPresenterService.askRemoveItems(selected, (error) => {
      if (!error) {
        selected.forEach(point => {
          handler.object.removeUnloadingPoint(point);
        })
        handler.dataSaver.saveObject(handler.object).then(success => {
          handler.updateTableData();
        });
      }
    });
  }

  private showUnloadingPointsDialog(actionName, point) {
    let data = new Map<string, any>();
    data['action'] = actionName;
    if (point) {
      data['object'] = point;
      data['address'] = point.getAddress();
    }
    data['contactName'] = this.object.getContactName();
    data['contactPhone'] = this.object.getContactPhone();

    let handler = this;
    this.dialogPresenterService.showDialog({
      dialog: UnloadingPointDialog,
      panel: 'unloading-points-dialog',
      data: data,
    }, function (point) {
      if (point) {
        handler.save(point, actionName).then(success => {
          if (success) {
            handler.updateTableData();
          }
        });
      }
    });
  }

  private updateTableData() {
    this.tableData = new UnloadingPointsTableData(this.object, this.translate);
  }

  private save(point: UnloadingPoint, actionName: string): Promise<any> {
    if (actionName == 'create') {
      this.object.addUnloadingPoint(point);
    }
    return this.dataSaver.saveObject(this.object);
  }
}
