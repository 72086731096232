import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { PFObject } from 'app/components/data/core/models/base/object';

import { LocaleService } from 'app/components/locale/locale.service';
import { create_kilometers } from '../../locale/locale';

import * as FormHelper from 'app/components/form-helper/form-helper';
import { DispatcherCloudExecutor } from 'app/components/data/cloud-executors/dispatcher-cloud-executor';
import { TranslateService } from '@ngx-translate/core';
import { LocalNotificationService } from 'app/components/local-notifications-service/local-notifications-service';

import { Customer } from 'app/components/data/core/models/persons/customer';
import { DataLoader } from 'app/components/data/data-loader';
import { Distance } from 'app/components/data/core/models/distance';
import { Supplier } from 'app/components/data/core/models/persons/supplier';
import { LoadingPoint, UnloadingPoint } from 'app/components/data/core/models/points/points';
import { Person } from 'app/components/data/core/models/persons/person';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PFQuery } from 'app/components/data/core/models/base/query';
import {AdditionalDataExecutor} from "../../../../../components/data/cloud-executors/additional-data-executor";

@Component({
  selector: 'create-kilometers-dialog',
  templateUrl: './create-kilometers-dialog.component.html',
  styleUrls: ['./create-kilometers-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CreateKilometersDialog {
  compareFunction = PFObject.compareFn;
  private additionalExecutor = new AdditionalDataExecutor();
  public form: UntypedFormGroup;

  public availableSuppliers;
  public availableLoadingPoints: LoadingPoint[];
  public availableCustomers;
  public availableUnloadingPoints: UnloadingPoint[];

  public suppliersData = new DataLoader(Supplier);
  public customersData = new DataLoader(Customer);
  public executor = new DispatcherCloudExecutor();

  constructor(
    private localeService: LocaleService,
    public dialogRef: MatDialogRef<CreateKilometersDialog>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private notifications: LocalNotificationService,
  ) {
    this.localeService.loadLocale(create_kilometers);
    this.form = this.createContactForm();

    this.suppliersData.setQueryModificationBlock(q => {
      q.include('loadingPoints');
    })
    this.customersData.setQueryModificationBlock(q => {
      q.include('unloadingPoints');
    })
    this.loadData();
  }

  createContactForm(): UntypedFormGroup {
    return this._formBuilder.group({
      customer: null,
      unloadingPoint: null,
      supplier: null,
      loadingPoint: null,
      distance: 0,
    });
  }

  ngOnInit() {
    this.availableSuppliers = this.form.controls.supplier.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : ''),
      map(name => this.filter(this.suppliersData, name))
    )

    this.availableCustomers = this.form.controls.customer.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' && value !== 'stock' ? value : ''),
      map(name => this.filter(this.customersData, name))
    )
  }

  private filterUnloadingPoints(name: string): UnloadingPoint[] {
    if (!name) return this.availableUnloadingPoints || [];
    const filterValue = name.toLowerCase();
    return this.availableUnloadingPoints.filter(point =>
        point.getAddress().toLowerCase().includes(filterValue)
    );
  }

  private filter(loader: DataLoader, name) {
    let objects = loader.getLoadedItems();
    if (!name) return objects;
    let filterValue = name.toLowerCase();
    return objects.filter(o => o.getName().toLowerCase().indexOf(filterValue) != -1);
  }

  private async loadData() {
    await this.suppliersData.loadAll();
    await this.customersData.loadAll();

    let supplier = this.suppliersData.getLoadedItems()[0];
    this.form.controls.supplier.setValue(supplier);
    this.updateWithSupplier(supplier);

    let customer = this.customersData.getLoadedItems()[0];
    this.form.controls.customer.setValue(customer);
    this.updateWithCustomer(customer);
  }

  checkAndSave() {
    let verified = this.checkForm();

    if (verified) {
      let distance = new Distance();
      let unloadingPoint = this.form.controls.unloadingPoint.value;
      let loadingPoint = this.form.controls.loadingPoint.value;

      distance.setUnloadingPoint(unloadingPoint);
      distance.setLoadingPoint(loadingPoint);
      distance.setDistance(this.form.controls.distance.value);
      this.checkIsExists(loadingPoint, unloadingPoint).then((result) => {
        if (!result) {
          this.createDistance(distance);
        } else {
          let localizedMSG = this.translate.instant('DIALOG.KILOMETERS.ERROR.EXISTS');
          this.notifications.showErrorNotification(localizedMSG);
        }
      }).catch(() => {
        this.showUndefinedError();
      });
    }
  }

  personDisplay(value) {
    if (value instanceof Person) {
      return value.getName();
    }
    return value;
  }

  pointDisplay(point: any): string {
    return point ? point.getAddress() : '';
  }

  async updateWithSupplier(supplier: Supplier) {
    try {
      const response = await this.additionalExecutor.supplier_getLoadPoints({ supplier });
      this.availableLoadingPoints = response.objects;
    } catch (error) {
      console.error('Failed to load loading points:', error);
      this.availableLoadingPoints = [];
    }
  }

  async updateWithCustomer(customer: Customer) {
    try {
      const response = await this.additionalExecutor.customer_getUnLoadPoints({ customer });
      this.availableUnloadingPoints = response.objects;
    } catch (error) {
      console.error('Failed to load loading points:', error);
      this.availableUnloadingPoints = [];
    }
  }

  private createDistance(distance) {
    this.executor
      .saveObject(distance)
      .then(() => {
        this.dialogRef.close(true);
      })
      .catch(() => {
        this.showUndefinedError();
      });
  }

  private showUndefinedError() {
    let localizedMSG = this.translate.instant('DIALOG.KILOMETERS.ERROR.UNDEFINED');
    this.notifications.showErrorNotification(localizedMSG);
  }

  private checkIsExists(loadingPoint, unloadingPoint) {
    let query = new PFQuery('Distance');
    query.equalTo('unloadingPoint', unloadingPoint);
    query.equalTo('loadingPoint', loadingPoint);
    return query.first();
  }

  private checkForm(): boolean {
    return FormHelper.checkForm(this.form);
  }
}

