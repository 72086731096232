import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { Supplier } from 'app/components/data/core/models/persons/supplier';

import { LocaleService } from 'app/components/locale/locale.service';
import { supplier } from 'app/main/admin/users/locale/locale';

let locales = [supplier];

import * as FormHelper from 'app/components/form-helper/form-helper';
import { CloudExecutor } from 'app/components/data/cloud-executors/cloud-executor';

import * as CustomValidators from 'app/components/validators/validators';

import { debounceTime } from 'rxjs/operators';
import * as Location from "app/components/location/coordinate";
import * as Geocode from 'app/components/geocode/geocode';
import * as Places from 'app/components/geocode/places';

import { CurrentUser } from 'app/components/data/core/models/base/user';
import { LoadingPoint, Entrance } from 'app/components/data/core/models/points/points';
import { PFGeoPoint } from 'app/components/data/core/models/base/point';

import { TranslateService } from '@ngx-translate/core';
import { LocalNotificationService } from 'app/components/local-notifications-service/local-notifications-service';

@Component({
    selector   : 'supplier-create-dialog',
    templateUrl: './supplier-create-dialog.component.html',
    styleUrls  : ['./supplier-create-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class SupplierCreateDialog {

  public form: UntypedFormGroup;
  public title: string;
  public confirmButtonTitle: string;

  private object: Supplier;

  public processing: boolean = false;

  private dataSaver = new CloudExecutor;

  public addressSearchResults: Places.SearchResult[];
  private addressSearchService: Places.SearchService;
  private lastGeocodedAddress: string;
  private lastGeocodedCoordinate: Location.Coordinate;
  private geocoder: Geocode.Geocoder;

  isTransferPoint: boolean;

  constructor (
    private localeService: LocaleService,
    public dialogRef: MatDialogRef<SupplierCreateDialog>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: UntypedFormBuilder,
    private notifications: LocalNotificationService,
    private translate: TranslateService
  ) {
    this.localeService.loadLocales(locales);

    if (this._data.additionalData) {
      this.isTransferPoint = this._data.additionalData.isTransferPoint;
    }

    let dialogName = this.isTransferPoint ? 'TRANSFER_POINT' : 'SUPPLIER';

    if (this.isCreationForm()) {
      this.object = new Supplier();
      
      this.title = 'DIALOG.' + dialogName +'.TITLE.CREATE';
      this.confirmButtonTitle = 'DIALOG.SUPPLIER.CONFIRMBUTTON.CREATE';

    } else {
      this.object = this._data.object;
      this.title = 'DIALOG.' + dialogName +'.TITLE.EDIT';
      this.confirmButtonTitle = 'DIALOG.SUPPLIER.CONFIRMBUTTON.SAVE';
    }
    this.form = this.createContactForm();
  }

  ngOnInit() {
    if (this.isCreationForm()) {
      this.form
      .get('address')
      .valueChanges
      .pipe(debounceTime(500))
      .subscribe( value => this.addressChanged(value));
    }
  }

  isCreationForm(): boolean {
    return this._data.action === 'create';
  }

  mapReady(map) {
    this.loadAddressService(map);
  }

  createContactForm(): UntypedFormGroup {
    return this._formBuilder.group({
                name            : this.object.getName(),
                itn             : [this.object.getITN() , [CustomValidators.regExValidator(/^[0-9]+$/)]],
                address         : null,
              });
  }

  checkAndSave() {
    let verified = this.checkForm();

    if (verified) {
      this.processing = true;
      this.object.setName(this.form.controls.name.value);

      let itn = this.form.controls.itn.value;
      this.object.setITN(itn ? itn.toString() : '');
      this.object.setTransfer(this.isTransferPoint);

      let address = this.form.controls.address.value;

      if (this.isCreationForm()) {
        this.getCoordinateForAddress(address).then((coordinate) => {
          return this.createEntrance(address, coordinate);
        }).then(entrance => {
          return this.cresteLoadingPointWithEntrance(entrance);
        }).then(point => {
          this.object.addLoadingPoint(point);
          return this.dataSaver.saveObject(this.object);
        }).then((c) => {
          this.close(c);
        }).catch(error => {
          this.handleError(error, address);
          this.processing = false;
        });
      } else {
        this.dataSaver.saveObject(this.object).then((c) => {
          this.close(c);
        });
      }
    }
  }

  getCoordinateForAddress(address: string) {
    if (address === this.lastGeocodedAddress && this.lastGeocodedCoordinate) {
      return Promise.resolve(this.lastGeocodedCoordinate);
    } else {
      return this.geocoder.codeAddress(address);
    }
  }

  createEntrance(address, coordinate) {
    let entrance = new Entrance();
    entrance.setName(this.localeService.translateService.instant('DIALOG.SUPPLIER.DEFAULT_POINT'));
    entrance.setAddress(address);

    if (coordinate) {
      entrance.setCoordinate(new PFGeoPoint({
        latitude: coordinate.latitude,
        longitude: coordinate.longitude
      }));
    } else {
      console.log('No coords for address ' + address);
    }
    return this.dataSaver.saveObject(entrance);
  }

  private cresteLoadingPointWithEntrance(entrance: Entrance) {
    let loadingPoint = new LoadingPoint();
    loadingPoint.setAddress(entrance.getAddress());
    loadingPoint.addEntrance(entrance);

    if (CurrentUser.isManager()) {
      loadingPoint.setManager(CurrentUser.manager())
    }

    return this.dataSaver.saveObject(loadingPoint);
  }

  private close(supplier) {
    this.dialogRef.close(supplier);
  }

  private checkForm() : boolean {
    return FormHelper.checkForm(this.form);
  }

  private loadAddressService(map) {
    this.geocoder = new Geocode.Geocoder();
    this.addressSearchService = Places.SearchService.all(map);

    this.addressSearchService.results.subscribe((results) => {
      this.addressSearchResults = results;
    });
  }

  public addressChanged(text: string): void {
    if (text.length > 2) {
      this.addressSearchService.search(text);
    } else {
      this.addressSearchResults = [];
    }
  }

  public addressSearchResultSelected(searchResult: Places.SearchResult) {
    this.form.controls.address.setValue("");
    this.addressSearchService.getDetails(searchResult).then((details) => {
      this.setAddress(details.address);
      this.lastGeocodedCoordinate = details.coordinate;
    });
  }

  private setAddress(address: string): void {
    this.lastGeocodedAddress = address;
    this.form.controls.address.setValue(address);
    this.form.controls.address.setErrors(null);
  }

  private handleError(error: string, address: string) {
    var errorMsg;
    var errorObject;
    if (error == 'ZERO_RESULTS') {
      errorMsg = 'DIALOG.SUPPLIER.ERROR.ZERO_RESULTS';
      errorObject = address;
    }

    let localizedMessage = this.translate.instant(errorMsg, {value: errorObject});
    this.notifications.showErrorNotification(localizedMessage);
  }
}
