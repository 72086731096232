import { DialogPresenterService, DialogData } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import { ManagerDialog } from 'app/main/admin/users/dialogs/manager/manager-dialog.component';
import { TableOperationActionType, TableOperationsHelper } from 'app/components/helpers/table-operations.helper';
import { TableOperationsFactory } from 'app/components/table-operations-controller/table-operations-controller';
import { UsersTableHelper } from 'app/main/admin/users/helpers/users-table-operations.helper';
import { Role } from 'app/components/data/core/models/base/user';

export class ManagersTableOperationsFactory implements TableOperationsFactory {

    constructor(
        private dialogPresenter: DialogPresenterService,
        private completion
    ) { }

    makeOperations(selected) {
        return this.makeOperationsWithSelected(selected);
    }

    makeDefaultOperation(selected) {
        return TableOperationsHelper.makeOperationWithType(
            TableOperationActionType.Edit,
            this.dialogPresenter,
            () => this.makeDialogData(),
            selected);
    }

    private makeOperationsWithSelected(selected) {
        let types = [
            TableOperationActionType.Add,
            TableOperationActionType.Edit,
        ];
        let operations = TableOperationsHelper.makeOperationsWithTypes(
            types,
            this.dialogPresenter,
            () => this.makeDialogData(),
            selected,
            this.completion
        );
        operations.push(
            UsersTableHelper.makeCreateUserCredentialsOperation(
                this.dialogPresenter,
                selected,
                Role.Manager
            )
        );
        operations.push(
            TableOperationsHelper.makeOperationWithType(
                TableOperationActionType.Remove,
                this.dialogPresenter,
                () => UsersTableHelper.makeRemoveUserDialodData(Role.Manager),
                selected,
                this.completion
            )
        );
        return operations;
    }

    private makeDialogData(): DialogData {
        return {
            dialog: ManagerDialog,
            panel: 'manager-dialog-container',
            data: {}
        }
    }
}