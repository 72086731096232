import { TableData, TableRowData, TableSort } from 'app/components/table/tabledata';

import { ArticleType } from 'app/components/data/core/models/articles/article'
import { ServerTableDataSource } from 'app/components/table/data_sources/server-data-source';
import { PFQuery } from 'app/components/data/core/models/base/query';
import { filterDeleted } from 'app/components/access/query';

export class ArticleTypesTableData extends TableData {

  constructor() {
    super();

    let columns = [
      new TableRowData({
        key: 'name', 
        header: 'ARTICLE.TABLEROW.TYPENAME', 
        cell: (type: ArticleType) => `${type.name()}`
      }),
    ];
    this.setColumns(columns);

    let sort: TableSort = new TableSort('name','asc');
    
    let tableDataSource = new ServerTableDataSource('ArticleType');
    tableDataSource.setDefaultSort(sort);
    tableDataSource.queryModify = function (query: PFQuery): PFQuery {
      return filterDeleted(query);
    };
    tableDataSource.queryFilter = (filter, query) => {
      query.contains("name", filter);
      return query;
    };
    this.setProvider(tableDataSource);
    
  }
}
