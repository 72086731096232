
<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="primary" 
                 title="{{title | translate}}">
  </dialog-header>
  
  <div class="pt-8 px-16">
    <form [formGroup]="form">
      <div>
        <mat-form-field fxFlex>
          <input matInput
                 placeholder="{{'DIALOG.CUSTOMER.NAME' | translate}}"
                 formControlName="name"
                 required
                 cdkFocusInitial>
          <mat-error *ngIf="form.get('name').hasError('required')">{{'DIALOG.CUSTOMER.ERROR.MUST_BE_FILLED' | translate}}</mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field fxFlex>
          <input matInput
                 placeholder="{{'DIALOG.CUSTOMER.ITN' | translate}}"
                 formControlName="itn"
                 cdkFocusInitial>                 
          <mat-error *ngIf="form.get('itn').hasError('noMatchToRegEx')">{{'DIALOG.CUSTOMER.ERROR.ITN_ONLY_NUMBERS' | translate}}</mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field fxFlex>
          <mat-select placeholder="{{'DIALOG.CUSTOMER.DEFAULT_PRICE_TYPE' | translate}}"
                    formControlName="defaultPriceType"
                    required>
                    <mat-option *ngFor='let type of priceTypes' [value]="type">{{priceTypeName(type) | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="column" fxLayoutAlign="center center"
      fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">
        <mat-form-field class="no-padding" fxFlex='49'>
          <mat-label>{{'DIALOG.CUSTOMER.CONTACT_NAME' | translate}}</mat-label>
          <input class="info-input" matInput formControlName="contactName">
        </mat-form-field>

        <mat-form-field class="no-padding" fxFlex='49'>
          <mat-label>{{'DIALOG.CUSTOMER.CONTACT_PHONE' | translate}}</mat-label>
          <input class="info-input" matInput formControlName="contactPhone">
        </mat-form-field>
      </div>

      <div>
        <mat-form-field fxFlex>
          <input matInput
                 placeholder="{{'DIALOG.CUSTOMER.ADDRESS' | translate}}"
                 formControlName="address"
                 required
                 [matAutocomplete]="addressAutocomplete">
          <mat-error *ngIf="form.get('address').hasError('required')">{{'DIALOG.CUSTOMER.ERROR.MUST_BE_FILLED' | translate}}</mat-error>
          <mat-autocomplete #addressAutocomplete="matAutocomplete" (optionSelected)="addressSearchResultSelected($event.option.value)">
            <mat-option *ngFor="let searchResult of addressSearchResults" [value]="searchResult">
              {{searchResult.description}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <agm-map (mapReady)="mapReady($event)"></agm-map>
      <attachment #attachment (onRemoveCallback)="onAttachmentRemove($event.value)"></attachment>

      <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button
                color="accent"
                [disabled] = "loading"
                (click)='checkAndSave()'>{{confirmButtonTitle | translate}}
        </button>
      </div>
    </form>
  </div>
</div>
