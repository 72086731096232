<div class="page-layout carded fullwidth" fusePerfectScrollbar>

    <div class="top-bg primary"></div>
    <div class="center">
        <table-header headerTitle="{{'ALLORDERSLIST.TITLE' | translate}}"
                      iconName="assignment_turned_in"
                      searchBarTitle="COMMON.SEARCHTITLE"
                      [buttons]="headerButtons"
                      (onSearch)="search($event)">
        </table-header>

        <div class="mat-elevation-z8 fuse-white mb-16" fxLayout="column">

            <div fxLayout="column" fxFlex>
                <action-bar [operations]="operationsController.operations" class="sticky fuse-white"></action-bar>

                <cd-table [tabledata]="tableData"
                          (rowDoubleClick)="operationsController.doubleClickHandler($event)"
                          (onSelectionChange)="operationsController.updateOperations($event.table)">
                </cd-table>
            </div>
        </div>
    </div>
</div>
