import { CloudExecutor } from './cloud-executor';
import {Supplier} from "../core/models/persons/supplier";
import {Customer} from "../core/models/persons/customer";

export class AdditionalDataExecutor extends CloudExecutor {
    supplier_getLoadPoints(params: { supplier: Supplier} | any = null) {
        const serializedParams = {
            supplier: params.supplier.id,
        };

        return this.run('Supplier_getLoadingPoints', serializedParams)
            .catch((error) => {
                var errorDescription;
                if (error == 'NoSupplierForArticle') {
                    errorDescription = 'NoSupplierForArticle';
                } else {
                    errorDescription = 'Undefined'
                }
                return Promise.reject(errorDescription);
            })
    }

    customer_getUnLoadPoints(params: { customer: Customer} | any = null) {
        const serializedParams = {
            customer: params.customer.id,
        };

        return this.run('Customer_getUnLoadingPoints', serializedParams)
            .catch((error) => {
                var errorDescription;
                if (error == 'NoSuppliorArticle') {
                    errorDescription = 'NoSupplierForArticle';
                } else {
                    errorDescription = 'Undefined'
                }
                return Promise.reject(errorDescription);
            })
    }
}