
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";

import { TripStage } from "app/components/data/core/models/trip/trip";
import { ConfigurationService } from "./configuration.service";

import { TranslateService } from "@ngx-translate/core";
import { LocaleService } from 'app/components/locale/locale.service';
import { settings } from './locale/locale'
import { tripStage } from "app/main/locale/locale";
import { DialogData, DialogPresenterService } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import { SupplierPriorityDialog } from './dialog/supplier-priority/supplier-priority-dialog.component';

const locales = [
  settings,
  tripStage
];

@Component({
  selector: "settings",
  templateUrl: "./settings.component.html",
  styleUrls  : ["./settings.component.scss"]
})
export class SettingsComponent implements OnInit {

  public tripStages = [
    TripStage.DrivingForLoading,
    TripStage.InLoadingPoint,
    TripStage.Loaded,
    TripStage.DrivingForUnloading,
    TripStage.InUnloadingPoint,
    TripStage.Unloaded
  ];

  public formGroup: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private localeService: LocaleService,
    private configurationService: ConfigurationService,
    private dialogPresenter: DialogPresenterService,
  ) {
    this.localeService.loadLocales(locales);
    this.formGroup = this.makeFormGroup();
  }

  ngOnInit() {
    if (this.configurationService.getConfiguration()) {
      this.update();
    }
    else {
      this.configurationService.fetch().then(() => {
        this.update();
      });
    }
  }

  public changeSuppliersOrder() {
    let dialogData: DialogData = {
      dialog: SupplierPriorityDialog,
      panel: 'supplier-priority-dialog-container',
      data: {},
    }
    this.dialogPresenter.showDialog(dialogData);
  }

  public saveConfiguration(): void {
    if (!this.formGroup.valid) {
      Object.keys(this.formGroup.controls).forEach(name => {
        let control = this.formGroup.controls[name];
        control.markAsTouched({onlySelf: true});
      });
      return;
    }

    let configuration = this.configurationService.getConfiguration();

    configuration.setOrderTimeout(this.formGroup.controls["orderTimeout"].value * 60 * 60 * 1000);
    configuration.setTransportUnitInactivityTimeInterval(this.formGroup.controls["transportUnitInactivityTimeInterval"].value * 60 * 60 * 1000);
    this.tripStages.forEach(stage => configuration.setTripDelayTimeInterval(this.formGroup.controls["tripDelayTimeInterval." + stage].value * 60 * 1000, stage));
    configuration.setTripStoppageTimeInterval(this.formGroup.controls["tripStoppageTimeInterval"].value * 60 * 1000);
    configuration.setMaxDistanceDifference(this.formGroup.controls["maxDistance"].value * 1000);
    configuration.save().then(() => {
      this.formGroup.markAsPristine();
    });
  }

  private update(): void {
    let configuration = this.configurationService.getConfiguration();

    this.formGroup.controls["orderTimeout"].setValue(configuration.getOrderTimeout() / 1000 / 60 / 60);
    this.formGroup.controls["transportUnitInactivityTimeInterval"].setValue(configuration.getTransportUnitInactivityTimeInterval() / 1000 / 60 / 60);
    this.tripStages.forEach(stage => this.formGroup.controls["tripDelayTimeInterval." + stage].setValue(configuration.getTripDelayTimeInterval(stage) / 1000 / 60));
    this.formGroup.controls["tripStoppageTimeInterval"].setValue(configuration.getTripStoppageTimeInterval() / 1000 / 60);
    this.formGroup.controls["maxDistance"].setValue(configuration.getMaxDistanceDifference() / 1000);
  }

  private makeFormGroup(): UntypedFormGroup {
    let controls = {};

    controls["orderTimeout"] = [];
    controls["transportUnitInactivityTimeInterval"] = [];
    this.tripStages.forEach(stage => controls["tripDelayTimeInterval." + stage] = []);
    controls["tripStoppageTimeInterval"] = [];
    controls["maxDistance"] = [];

    return this.formBuilder.group(controls);
  }

  private titleForStage(stage: number): string {
    return this.translateService.instant("TRIP.STAGE." + stage);
  }

}
