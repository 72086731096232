import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { MaterialModule } from 'app/main/material.module';
import { VehicleBrandDialog } from './vehicle-brand-dialog.component';
import { DialogHeaderModule } from 'app/components/dialogs/dialog-header/dialog-header.module';

@NgModule({
    declarations: [
        VehicleBrandDialog,
    ],
    imports: [
        MaterialModule,
        TranslateModule,
        FuseSharedModule,
        DialogHeaderModule,
    ],
    exports: [
        VehicleBrandDialog,
        DialogHeaderModule,
    ]
})

export class CreateVehicleBrandModule
{
}
