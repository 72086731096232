import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ClickData } from 'app/components/table/table.component';
import { VehiclePass } from 'app/components/data/core/models/transport/vehicle-pass';

import { DialogPresenterService } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';

import { LocaleService } from 'app/components/locale/locale.service';
import { edit_passes } from '../../../locale/locale';

import { PassesTableData } from './pass/passes.tabledata';
import { PassDialog } from './pass/pass-dialog.component';
import { CloudExecutor } from 'app/components/data/cloud-executors/cloud-executor';
import { ActionBarOperation } from 'app/components/action-bar/action-bar.component';
import { TableOperationsHelper } from 'app/components/helpers/table-operations.helper';
import { TranslateService } from '@ngx-translate/core';
import { Vehicle } from 'app/components/data/core/models/transport/transport';
let locales = [
  edit_passes,
];

@Component({
  selector: 'vehicle-edit-passes-dialog',
  templateUrl: './vehicle-edit-passes-dialog.component.html',
  styleUrls: ['./vehicle-edit-passes-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class VehicleEditPassDialog {
  public object: Vehicle;
  private dataSaver = new CloudExecutor;

  tableData: PassesTableData;
  tableOperations: ActionBarOperation[] = [];

  constructor(
    private localeService: LocaleService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<VehicleEditPassDialog>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private dialogPresenterService: DialogPresenterService
  ) {
    this.localeService.loadLocales(locales);
    this.object = this._data.object;
    this.tableOperations = this.makeTableOperations([]);
    this.updateTableData();
  }

  doubleClickHandler(click: ClickData) {
    this.showPassesDialog('edit', click.data);
  }

  updateOperations(value: ClickData) {
    let selected = value.table.selectedValues();
    this.tableOperations = this.makeTableOperations(selected);
  }

  private makeTableOperations(selected): ActionBarOperation[] {
    return [
      TableOperationsHelper.makeCreateOperation(() => this.addAddress()),
      TableOperationsHelper.makeEditOperation(() => this.editAddress(selected), selected),
      TableOperationsHelper.makeRemoveOperation(() => this.removeAddress(selected), selected),
    ];
  }

  private addAddress() {
    this.showPassesDialog('create', null);
  }

  private editAddress(selected) {
    this.showPassesDialog('edit', selected[0]);
  }

  private removeAddress(selected) {
    this.showRemoveDialog(selected);
  }

  private showRemoveDialog(selected) {
    let handler = this;
    let completionHandled =  (result) => {
      if (result) {
        selected.forEach(pass => {
          handler.object.removePass(pass);
        })
        handler.saveAndRefresh();
      }
    }
    this.dialogPresenterService.askRemoveItems(selected, completionHandled, false, true);
  }

  private showPassesDialog(actionName, pass: VehiclePass) {
    let data = new Map<string, any>();
    data['action'] = actionName;
    if (pass) {
      data['object'] = pass;
    }

    let handler = this;
    this.dialogPresenterService.showDialog({
      dialog: PassDialog,
      panel: 'pass-dialog',
      data: data,
    }, function (result) {
      if (result) {
        handler.handleChanges(result.old, result.new);
      }
    });
  }

  private updateTableData() {
    this.tableData = new PassesTableData(this.object, this.translate);
  }

  private async handleChanges(oldPass: VehiclePass, newPass: VehiclePass) {
    if (oldPass) {
      this.object.removePass(oldPass);
    }
    this.object.addPass(newPass);
    this.saveAndRefresh();
  }

  private async saveAndRefresh() {
    await this.dataSaver.saveObject(this.object);
    this.updateTableData()
  }
}
