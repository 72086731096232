import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { TableModule } from 'app/components/table/table.module';
import { MaterialModule } from 'app/main/material.module';

import { UnitTransferModule } from './unit/unit-transfer.module';

import { NoProcuratoryDialogComponent } from './no_procuratory/no_procuratory_dialog.component';
import { RetransferDialogComponent } from './retransfer/retransfer_dialog.component';
import { UnitTransportProblemDialogComponent } from './unit-tansport-problem/unit-tansport-problem-dialog.component';

import { TransferOrderDialogComponent } from './transfer-order-dialog.component'

import { CarrierSelectComponent } from './carrier/carrier-select.component';
import { DialogHeaderModule } from 'app/components/dialogs/dialog-header/dialog-header.module';
import {ConsistencyDialogComponent} from "./consistency/consistency_dialog.component";

@NgModule({
    declarations: [
        TransferOrderDialogComponent,
        NoProcuratoryDialogComponent,
        CarrierSelectComponent,
        RetransferDialogComponent,
        ConsistencyDialogComponent,
        UnitTransportProblemDialogComponent,
    ],
    imports: [
        MaterialModule,
        TranslateModule,
        FuseSharedModule,
        TableModule,
        UnitTransferModule,
        DialogHeaderModule,
    ],
    exports: [
        TransferOrderDialogComponent,
        NoProcuratoryDialogComponent,
        CarrierSelectComponent,
        RetransferDialogComponent,
        ConsistencyDialogComponent,
        UnitTransportProblemDialogComponent,
    ]
})

export class TransferOrderModule {
}
