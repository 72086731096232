<div class="dialog-content-wrapper">
  <dialog-header headerClass="primary" 
                 title="{{title | translate}}">
  </dialog-header>
  
  <mat-horizontal-stepper linear 
                          #stepper 
                          (selectionChange)="stepChanged($event.selectedIndex)">
    <mat-step [stepControl]="step1.form"
              label="{{'ORDER.DIALOG.CUSTOMER'| translate}}">
      <customer-step [order]="order" 
                     [editing]="isEditing()"
                     #step1>
      </customer-step>
      <div class="py-8" 
           fxLayout="row" 
           fxLayoutAlign="end end">
        <button mat-raised-button 
                class="form-button accent" 
                (click)="next()">
                {{ (customerAdmited() ? 'ORDER.DIALOG.NEXT' : 'ORDER.DIALOG.CLOSE')| translate}}
        </button>
      </div>
    </mat-step>

    <mat-step [stepControl]="step2.form"
              label="{{'ORDER.DIALOG.SUPPLIER'| translate}}">
      <supplier-step [order]="order" 
                     [editing]="isEditing()" 
                     #step2>
      </supplier-step>
      <div class="py-8" 
           fxLayout="row" 
           fxLayoutAlign="space-between end">
        <button mat-raised-button 
                class="form-button accent" 
                matStepperPrevious>
                {{'ORDER.DIALOG.BACK'| translate}}
        </button>
        <button mat-raised-button 
                class="form-button accent" 
                (click)="finish()" 
                [disabled]="executor.isProcessing()">
                {{buttonName | translate}}
        </button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>

</div>
