
<div class="dialog-content-wrapper">
  <dialog-header headerClass="primary" 
                 title="{{'DIALOG.KILOMETERS.TITLE.EDIT' | translate}}">
  </dialog-header>
  <div class="pt-8 px-16" fxFlex fxLayout="column">
    <form [formGroup]="form" fxLayout="column" fxFlex>
      
      <div>
        <mat-form-field fxFlex>
          <input matInput
                 type="number"
                 min=0
                 placeholder="{{'DIALOG.KILOMETERS.DISTANCE' | translate}}"
                 formControlName="distance"
                 cdkFocusInitial>                 
        </mat-form-field>
      </div>

      <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button
                color="accent"
                [disabled] = "executor.isProcessing()"
                (click)='checkAndSave()'>{{'DIALOG.KILOMETERS.CONFIRMBUTTON.SAVE' | translate}}
        </button>
      </div>

    </form>
  </div>
</div>
