import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import { UserRoleGuard, Role } from 'app/guards/user-role-guard.service';

import { ProposalsListComponent } from './subcat/list/proposals-list.component';
import { ProposalsListModule } from './subcat/list/proposals-list.module';
import { ProposalsListByCarrierModule } from './subcat/by-carriers/proposals-list-by-carrier.module';
import { ProposalsListByCarrierComponent} from './subcat/by-carriers/proposals-list-by-carrier.component';
import { ProposalsListBySupplierModule } from './subcat/by-suppliers/proposals-list-by-supplier.module';
import { ProposalsListBySupplierComponent} from './subcat/by-suppliers/proposals-list-by-supplier.component';
import { ProposalsListForCarrierModule } from './subcat/for-carriers/proposals-list-for-carrier.module';
import { ProposalsListForCarrierComponent} from './subcat/for-carriers/proposals-list-for-carrier.component';
import { ProposalsListFromCustomersComponent} from './subcat/from-customers/proposals-list-from-customers.component';
import { ProposalsListFromCustomersModule } from './subcat/from-customers/proposals-list-from-customers.module';

const routes: Routes = [
  {
    path     : 'proposals',
    children: [
      {
        path     : 'proposals-for-carrier',
        canActivate: [UserRoleGuard],
        data: {roles: [Role.Dispatcher]},
        component: ProposalsListForCarrierComponent
      },
      {
        path     : 'proposals-by-carrier',
        canActivate: [UserRoleGuard],
        data: {roles: [Role.Administrator, Role.Logistician, Role.Manager]},
        component: ProposalsListByCarrierComponent
      },
      {
        path     : 'proposals-by-suppliers',
        canActivate: [UserRoleGuard],
        data: {roles: [Role.Administrator, Role.Logistician, Role.Manager]},
        component: ProposalsListBySupplierComponent
      },
      {
        path     : 'proposals-list',
        canActivate: [UserRoleGuard],
        data: {roles: [Role.Administrator, Role.Logistician, Role.Manager]},
        component: ProposalsListComponent
      },
      {
        path     : 'proposals-from-customers',
        canActivate: [UserRoleGuard],
        data: {roles: [Role.Administrator, Role.Logistician, Role.Manager]},
        component: ProposalsListFromCustomersComponent
      },
      {
        path     : '',
        redirectTo: 'proposals-list',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  providers: [
    UserRoleGuard
  ],
  imports     : [
    RouterModule.forChild(routes),

    FuseSharedModule,

    ProposalsListModule,
    ProposalsListByCarrierModule,
    ProposalsListBySupplierModule,
    ProposalsListForCarrierModule,
    ProposalsListFromCustomersModule,
  ],
})

export class ProposalsModule
{
}
