
import { TableData, TableRowData } from 'app/components/table/tabledata';

import { CurrentUser } from 'app/components/data/core/models/base/user';
import { PFQuery } from 'app/components/data/core/models/base/query';
import * as AccessQueries from 'app/components/access/query';
import { QueryResultFilter } from 'app/components/access/queryResultFilter';

import { TranslateService } from '@ngx-translate/core';
import { TransportUnit } from 'app/components/data/core/models/transport/transport';
import { ServerTableDataSource } from 'app/components/table/data_sources/server-data-source';
import { LiveQuerySubscriber, LiveQueryModifier } from 'app/components/table/data_sources/live-query-subscriber';

export class TransportUnitsTableData extends TableData {

  constructor(
    private translate: TranslateService
  ) {
    super();
    let handler = this;
    let columns = [
      new TableRowData({
        key: 'auto',
        header: 'UNITS.TABLEROW.UNIT', 
        cell: (unit: TransportUnit) => `${unit.vehicleName()}`
      }),
      new TableRowData({
        key: 'driver',
        header: 'UNITS.TABLEROW.DRIVER', 
        cell: (unit: TransportUnit) => `${unit.driver().getName()}`
      }),
      new TableRowData({
        key: 'status',
        header: 'UNITS.TABLEROW.STATUS', 
        cell: (unit: TransportUnit) => `${handler.statusForUnit(unit)}`
      }),
      new TableRowData({
        key: 'autos_number',
        header: 'UNITS.TABLEROW.UNITNUMBER', 
        cell: (unit: TransportUnit) => `${unit.vehicle() ? unit.vehicle().number() : ''}`
      }),
      new TableRowData({
        key: 'trailer_number',
        header: 'UNITS.TABLEROW.TRAILERNUMBER', 
        cell: (unit: TransportUnit) => `${unit.trailer() ? unit.trailer().number() : ''}`
      }),
      new TableRowData({
        key: 'last_seen',
        header: 'UNITS.TABLEROW.LASTSEEN', 
        cell: (unit: TransportUnit) => `${unit.lastVisitStr()}`
      }),
      new TableRowData({
        key: 'position',
        header: 'UNITS.TABLEROW.SPEED', 
        cell: (unit: TransportUnit) => `${handler.speedFor(unit)}`
      }),
    ];

    this.setColumns(columns);

    let includeFields = [
      'driver',
      'vehicle',
      'trailer',
      'vehicle.model',
      'vehicle.model.brand',
      'trip.waypoints',
      'trip.order.loadingEntrance',
      'trip.order.unloadingEntrance'
    ];

    let modify = (query) => {
      query.equalTo("disbanded", false);
      if (CurrentUser.isDispatcher()) {
        AccessQueries.driverMatchesCurrentCarrier(query);
      }
    }

    let tableDataSource = new ServerTableDataSource('TransportUnit', includeFields);
    tableDataSource.queryModify = function (query: PFQuery): PFQuery {
      modify(query);
      return query;
    };

    tableDataSource.queryFilter = (filter, query) => {
      let driverQuery = new PFQuery('Driver');
      driverQuery.contains('name', filter);
      query.matchesQuery('driver', driverQuery);
      
      let vehicleQuery = new PFQuery('Vehicle');
      vehicleQuery.contains('number', filter);
      let uQuery1 = new PFQuery('TransportUnit');
      uQuery1.matchesQuery('vehicle', vehicleQuery);

      let trailerQuery = new PFQuery('Trailer');
      trailerQuery.contains('number', filter);
      let uQuery2 = new PFQuery('TransportUnit');
      uQuery2.matchesQuery('trailer', trailerQuery);

      var orQuery = PFQuery.or(query, uQuery1);
      orQuery = PFQuery.or(orQuery, uQuery2);
      return orQuery;
    };

    let subscriber = new LiveQuerySubscriber('TransportUnit', includeFields);
    subscriber.liveQueryModifier = new LiveQueryModifier(
      function (query: PFQuery) {
        query.equalTo("disbanded", false);
        return query;
      },
      function (object: TransportUnit) {
        if (CurrentUser.isDispatcher() && !QueryResultFilter.transportUnitMatchesCurrentCarrier(object)) {
          return null;
        }
        return object;
      });
    tableDataSource.setLiveQuerySubscriber(subscriber);
    this.setProvider(tableDataSource);
  }

  canSortColumn(column) {
    return false;
  }

  statusForUnit(unit: TransportUnit) {
    return this.translate.instant(unit.statusStringKey());
  }

  speedFor(unit: TransportUnit) {
    return unit.roundedSpeed() + ' ' + this.translate.instant('COMMON.SPEEDUNIT');
  }
}
