import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { PFQuery } from 'app/components/data/core/models/base/query';
import { PFObject } from 'app/components/data/core/models/base/object';
import { proposals_create_dialog } from './locale/locale';

import { DataLoader } from 'app/components/data/data-loader';
import { CloudExecutor } from 'app/components/data/cloud-executors/cloud-executor';
import * as FormHelper from 'app/components/form-helper/form-helper';
import { Order, OrderStatus } from 'app/components/data/core/models/order/order';
import { Supplier } from 'app/components/data/core/models/persons/supplier';
import { Customer } from 'app/components/data/core/models/persons/customer';
import { ArticleBrand, ArticlesFilter, ArticleType } from 'app/components/data/core/models/articles/article';
import { LoadingPoint, UnloadingPoint } from 'app/components/data/core/models/points/points';
import { DateHelper } from 'app/components/helpers/date.helper';
import { map, startWith } from 'rxjs/operators';
import { LocaleService } from 'app/components/locale/locale.service';
import * as CustomValidators from 'app/components/validators/validators';
import { ConfigurationService } from 'app/main/admin/settings/configuration.service';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { filterDeleted } from 'app/components/access/query';
import { QueryResultFilter } from 'app/components/access/queryResultFilter';
import { Contact } from 'app/components/data/core/models/persons/contact';
import { DialogPresenterService } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';

import { CreateContactDialog } from 'app/main/admin/contacts/create/create-contact-dialog.component';

interface StockValue {
  value: string,
  name: string,
}

@Component({
  selector: 'create-proposal-dialog',
  templateUrl: './create-proposal-dialog.component.html',
  styleUrls: ['./create-proposal-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CreateProposalDialog {
  compareFunction = PFObject.compareFn;

  public form: UntypedFormGroup;
  public title: string;
  public buttonName: string;

  public customersData: DataLoader;
  public suppliersData: DataLoader;

  public dataSaver = new CloudExecutor;

  availableSuppliers: Observable<Supplier[]>;
  availableLoadingPoints: LoadingPoint[];
  
  availableBrands: ArticleBrand[];
  availableTypes: ArticleType[];

  availableCustomers: Observable<Customer[]>;
  availableUnloadingPoints: UnloadingPoint[];

  stockValue: StockValue;

  get contacts(): Contact[] {
    if (this.selectedUnloadingPoint) {
      return this.selectedUnloadingPoint.getContacts();
    }
    return [];
  }

  private order: Order;

  private get selectedSupplier(): Supplier {
    return this.form.controls.supplier.value;
  };
  private set selectedSupplier(value: Supplier) {
    this.form.controls.supplier.setValue(value);
    this.updateWithSupplier(value);
  };

  private get selectedCustomer(): Customer | StockValue {
    return this.form.controls.customer.value;
  };

  private set selectedCustomer(value: Customer | StockValue) {
    this.form.controls.customer.setValue(value);
  };

  private get selectedBrand(): ArticleBrand {
    return this.form.controls.articleBrand.value;
  }
  private set selectedBrand(value: ArticleBrand) {
    this.form.controls.articleBrand.setValue(value);
    this.brandChanged();
  }

  private get selectedType(): ArticleType {
    return this.form.controls.articleType.value;
  }
  private set selectedType(value: ArticleType) {
    this.form.controls.articleType.setValue(value);
  }

  private get selectedLoadingPoint(): LoadingPoint {
    return this.form.controls.loadingPoint.value;
  }
  private set selectedLoadingPoint(value: LoadingPoint) {
    this.form.controls.loadingPoint.setValue(value);
  }

  private get selectedUnloadingPoint(): UnloadingPoint {
    return this.form.controls.unloadingPoint.value;
  }
  private set selectedUnloadingPoint(value: UnloadingPoint) {
    this.form.controls.unloadingPoint.setValue(value);
  }

  private get selectedUnloadingContact(): Contact {
    return this.form.controls.contacts.value;
  }
  private set selectedUnloadingContact(value: Contact) {
    this.form.controls.contacts.setValue(value);
  }

  private get unloadingDate(): Date {
    return this.form.controls.unloadingDate.value;
  }

  private get unloadingTime(): number {
    return this.form.controls.unloadingTime.value;
  }

  private get comment(): string {
    return this.form.controls.comment.value;
  };

  private get tonnage(): number {
    return this.form.controls.tonnage.value;
  };

  private set tonnage(value: number) {
    this.form.controls.tonnage.setValue(value);
  };

  private get count(): number {
    return this.form.controls.count.value;
  };

  private set count(value: number) {
    this.form.controls.count.setValue(value);
  };

  constructor(
    public dialogRef: MatDialogRef<CreateProposalDialog>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private localeService: LocaleService,
    private configService: ConfigurationService,
    private translate: TranslateService,
    private _formBuilder: UntypedFormBuilder,
    private dialogPresenter: DialogPresenterService

  ) {
    this.localeService.loadLocale(proposals_create_dialog);
    this.customersData = this.makeCustomerData();
    this.suppliersData = this.makeSuppliersData();
    if (this._data.action === 'create') {
      if (this._data.object) {
        this.order = this._data.object;
      } else {
        this.order = this.makeNewOrder();
      }
      this.order.setStatus(OrderStatus.SUPPLY_RESERVED);
      this.title = 'PROPOSALS.DIALOG.CREATE.TITLE.CREATE';
      this.buttonName = 'PROPOSALS.DIALOG.CREATE.CONFIRMBUTTON.CREATE';
    } else if (this._data.action === 'edit') {
      this.order = this._data.object;
      this.title = 'PROPOSALS.DIALOG.CREATE.TITLE.EDIT';
      this.buttonName = 'PROPOSALS.DIALOG.CREATE.CONFIRMBUTTON.SAVE';
    }

    this.stockValue = {
      value: 'stock',
      name: this.translate.instant('PROPOSALS.STOCK'),
    }

    this.form = this.createContactForm();
    this.loadData();
  }

  ngOnInit() {
    this.availableSuppliers = this.form.controls.supplier.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : ''),
      map(name => this.filter(this.suppliersData, name))
    )

    this.availableCustomers = this.form.controls.customer.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' && value !== 'stock' ? value : ''),
      map(name => this.filter(this.customersData, name))
    )
  }

  supplierDisplay(supplier?: Supplier) {
    return supplier ? supplier.getName() : undefined;
  }

  updateWithSupplier(supplier) {
    this.updateAvailableLoadingPointsFor(supplier);
    this.updateSelectedLoadingPoint();
    this.updateAvailableTypesFor(supplier);
    this.updateSelectedType();
    this.updateAvailableBrandsFor(supplier, this.selectedType);
    this.updateSelectedBrand();
  }

  typeChanged() {
    this.updateAvailableBrandsFor(this.selectedSupplier, this.selectedType);
    this.updateSelectedBrand();
  }

  brandChanged() {
    this.count = this.convertTonnageToCount(this.tonnage, this.selectedBrand);
  }

  countChanged(count: number) {
    this.tonnage = this.convertCountToTonnage(count, this.selectedBrand);
  }

  tonnageChanged(tonnage: number) {
    this.count = this.convertTonnageToCount(tonnage, this.selectedBrand);
  }

  customerDisplay(customer: any) {
    if (!customer) return '';
    if (customer instanceof Customer) return customer.getName();
    if (typeof customer !== 'string') return customer.name;
    return customer;
  }

  updateWithCustomer(customer: Customer) {
    this.updateAvailableUnloadingPointsFor(customer);
    this.updateSelectedUnloadingPoint();
  }

  checkAndSave() {
    if (this.checkForm()) {
      this.order.setSupplier(this.selectedSupplier);
      this.order.setLoadingPoint(this.selectedLoadingPoint);
      this.order.setArticleBrand(this.selectedBrand);

      let date = DateHelper.setDayBegin(new Date(this.unloadingDate));
      date.setHours(this.unloadingTime);

      this.order.setUnloadingBeginDate(date);
      this.order.setTonnage(this.tonnage);
      if (this.selectedCustomer instanceof Customer) {
        this.order.setCustomer(this.selectedCustomer);
        this.order.setUnloadingPoint(this.selectedUnloadingPoint);
        this.order.setUnloadingContact(this.selectedUnloadingContact)
      } else {
        this.order.setCustomer(null);
        this.order.setUnloadingPoint(null);
      }
      this.order.setComment(this.comment);
      this.dataSaver.saveObject(this.order).then(obj => {
        this.dialogRef.close(obj);
      });
    }
  }

  isContactsSelectable(): boolean {
    return this.selectedCustomer instanceof Customer;
  }

  addUnloadingContact() {
    let dialodData = {
      dialog: CreateContactDialog,
      panel: 'create-contact-dialog-container',
      data: {
        action: 'create',
        object: this.selectedUnloadingPoint
      }
    };

    this.dialogPresenter.showDialog(dialodData, (res) => {
      if (res) {
        this.selectedUnloadingContact = res;
      }
    });
  }

  contactCompare(contact1: Contact, contact2: Contact) {
    if (!contact1 && !contact2) {
      return true;
    } else if (!contact1 || !contact2) {
      return false;
    }
    return contact1.name === contact2.name &&
    contact1.phoneNumber === contact2.phoneNumber;
  }

  private updateSelectedContact() {
    let defaultValue = this.order.unloadingContact();
    let contacts = this.contacts;
    let value;
    if (contacts && contacts.length) {
      let index = contacts.findIndex(o => this.contactCompare(o, defaultValue));
      if (index != -1) {
        value = defaultValue;
      } else {
        value = contacts[0];
      }
    }
    this.selectedUnloadingContact = value;
  }

  private filter(loader: DataLoader, name) {
    let objects = loader.getLoadedItems();
    if (!name) return objects;
    let filterValue = name.toLowerCase();
    return objects.filter(o => o.getName().toLowerCase().indexOf(filterValue) != -1);
  }

  private async loadData() {
    await this.configService.fetch();
    await this.suppliersData.loadAll();
    await this.customersData.loadAll();
    this.updateSelectedSupplier();
    this.updateSelectedCustomer();
  }

  private makeNewOrder() {
    let order = new Order();
    order.setSupplier(this._data.supplier);
    order.setArticleBrand(this._data.brand);
    let date = this._data.date;
    if (!date) {
      date = new Date();
    }
    date.setHours(9);
    order.setUnloadingBeginDate(date);
    return order;
  }

  private makeSuppliersData() {
    let loader = new DataLoader('Supplier');
    loader.setQueryModificationBlock((query: PFQuery) => {
      query.includes([
        'articleBrands.type',
        'loadingPoints.entrances',
      ]);
      return filterDeleted(query);
    });
    loader.postProcess = (suppliers: Supplier[]) => {
      let config = this.configService.getConfiguration();
      if (config) {
        let orderedSuppliers = config.getSupplierOrder();
        suppliers = PFObject.sort(suppliers, orderedSuppliers);
      }
      return suppliers;
    };
    return loader;
  }

  private makeCustomerData() {
    let loader = new DataLoader('Customer');
    loader.setQueryModificationBlock((query: PFQuery) => {
      query.include('unloadingPoints');
    });
    return loader;
  }

  private updateSelectedSupplier() {
    let defaultSupplier = this.order.supplier();
    this.selectedSupplier = PFObject.priorValue(this.suppliersData.getLoadedItems(), defaultSupplier);
  }

  private updateSelectedCustomer() {
    let defaultCustomer = this.order.customer();
    if (defaultCustomer) {
      this.selectedCustomer = PFObject.priorValue(this.customersData.getLoadedItems(), defaultCustomer);
    } else {
      this.selectedCustomer = this.stockValue;
    }
  }

  private createContactForm(): UntypedFormGroup {
    let supplier = this.order.supplier();
    let customer = this.order.customer();
    let brand = this.order.articleBrand();
    let type;
    if (brand) {
      type = brand.type();
    }

    let unloadingDate = this.order.unloadingBeginDate();
    let unloadingTime;
    if (unloadingDate) {
      unloadingTime = unloadingDate.getHours();
    }
    let tonnage = this.order.tonnage();
    let count;
    if (tonnage && brand) {
      count = this.convertTonnageToCount(tonnage, brand);
    } else {
      count = 1;
    }

    this.updateAvailableLoadingPointsFor(supplier);
    this.updateAvailableTypesFor(supplier);
    this.updateAvailableBrandsFor(supplier, type);
    this.updateAvailableUnloadingPointsFor(customer);

    return this._formBuilder.group({
      supplier: [supplier, [Validators.required, CustomValidators.isObjectValidator]],
      loadingPoint: [this.order.loadingPoint(), Validators.required],
      articleType: [type, Validators.required],
      articleBrand: [brand, Validators.required],
      unloadingDate: [unloadingDate, Validators.required],
      unloadingTime: [unloadingTime, [Validators.min(0), Validators.max(23)]],
      count: [count, Validators.min(0)],
      tonnage: [tonnage, Validators.min(0)],
      customer: [customer, Validators.required],
      unloadingPoint: this.order.unloadingPoint(),
      contacts: this.order.unloadingContact(),
      comment: this.order.comment(),
    });
  }

  private convertTonnageToCount(tonnage, brand: ArticleBrand): number {
    if (!brand) return 0;
    return tonnage / brand.tonnagePerTruck();
  }

  private convertCountToTonnage(count, brand: ArticleBrand) {
    if (!brand) return 0;
    return count * brand.tonnagePerTruck();
  }

  private updateSelectedUnloadingPoint() {
    let defaultValue = this.order.unloadingPoint();
    this.selectedUnloadingPoint = PFObject.priorValue(this.availableUnloadingPoints, defaultValue);

    this.updateSelectedContact();
  }

  private updateAvailableLoadingPointsFor(supplier: Supplier) {
    if (!supplier) return;
    this.availableLoadingPoints = supplier.getLoadingPoints();
  }

  private updateAvailableTypesFor(supplier: Supplier) {
    if (!supplier) return;
    let brands = QueryResultFilter.filterDeleted(supplier.getBrands());
    this.availableTypes = ArticlesFilter.collectBrandsTypes(brands);
  }

  private updateSelectedType() {
    let defaultValue;
    let brand = this.order.articleBrand();
    if (brand) {
      defaultValue = brand.type();
    }
    defaultValue = PFObject.priorValue(this.availableTypes, defaultValue);
    if (this.selectedType != defaultValue) {
      this.selectedType = defaultValue;
      this.typeChanged();
    }
  }

  private updateAvailableBrandsFor(supplier: Supplier, type: ArticleType) {
    if (!supplier) return;
    if (!type) return;
    let brands = QueryResultFilter.filterDeleted(supplier.getBrands());
    this.availableBrands = ArticlesFilter.filterBrandsWithType(brands, type);
  }

  private updateSelectedBrand() {
    let brands = this.availableBrands;
    let defaultValue = this.order.articleBrand();
    if (!PFObject.objectsContainsObject(brands, defaultValue)) {
      if (brands && brands.length > 0) {
        defaultValue = brands[0];
      }
    }
    this.selectedBrand = defaultValue;
  }

  private updateAvailableUnloadingPointsFor(customer) {
    let points = [];
    if (customer instanceof Customer) {
      points = customer.getUnloadingPoints();
    }
    this.availableUnloadingPoints = points;
  }

  private updateSelectedLoadingPoint() {
    let points = this.availableLoadingPoints;
    let defaultValue = this.order.loadingPoint();
    if (!PFObject.objectsContainsObject(points, defaultValue)) {
      if (points && points.length > 0) {
        defaultValue = points[0];
      }
    }
    this.selectedLoadingPoint = defaultValue;
  }

  private checkForm(): boolean {
    return FormHelper.checkForm(this.form);
  }
}
