
export const locale = {
  "Settings": {
    "Title": "Settings",
    "TimeIntervalInMinutes": "Time interval, min",
    "TimeIntervalInHours": "Time interval, h",
    "Order": {
      "Label": "Orders",
      "TimeoutDescription": "Timeout for driver to accept order",
    },
    "TransportUnit": {
      "Label": "Crews",
      "InactivityTimeIntervalDescription": "Automatic disbanding after connection loss:",
    },
    "TripDelay": {
      "Label": "Delays on trip stages",
      "TimeIntervalDescription": "Transition to \"{{stage}}\":",
    },
    "TripStoppage": {
      "Label": "Trip stoppage",
      "TimeIntervalDescription": "Allowed stoppage interval:",
    },
    "MaxDistance": {
      "Label": "Max distance",
      "Description": "Max distance between setted loading/unloading points and real loading/unloading",
      "DistanceInKilometers": "Distance, km",
    },
    "SuppliersOrder": {
      "Label": "Supplier Order",
      "Description": "The order in which suppliers are listed",
      "ChangeOrder": "Change order",
    },
    "Apply": "Apply",
  },
};
