import { DialogPresenterService, DialogData } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import { TrailerDialog } from 'app/main/admin/vehicles/dialogs/trailer/create/trailer-dialog.component';
import { TrailerApproveDialog } from 'app/main/admin/vehicles/dialogs/trailer/approve/trailer-approve-dialog.component';
import { TableOperationActionType, TableOperationsHelper } from 'app/components/helpers/table-operations.helper';
import { TableOperationsFactory } from 'app/components/table-operations-controller/table-operations-controller';
import { ActionBarOperation } from 'app/components/action-bar/action-bar.component';
import { CurrentUser } from 'app/components/data/core/models/base/user';

export class TrailersTableTypeOperationsFactory implements TableOperationsFactory {

    constructor(
        private dialogPresenter: DialogPresenterService,
        private completion
    ) { }

    makeOperations(selected) {
        return this.makeOperationsWithSelected(selected);
    }

    makeDefaultOperation(selected) {
        return TableOperationsHelper.makeOperationWithType(
            TableOperationActionType.Edit,
            this.dialogPresenter,
            () => this.makeDialogData(),
            selected);
    }

    private makeOperationsWithSelected(selected) {
        let types = [
            TableOperationActionType.Add,
            TableOperationActionType.Edit,
            TableOperationActionType.Copy,
        ];
        let operations = TableOperationsHelper.makeOperationsWithTypes(
            types,
            this.dialogPresenter,
            () => this.makeDialogData(),
            selected,
            this.completion
        );
        if (CurrentUser.isAdministrator() || CurrentUser.isLogistician()) {
            operations.push(
                this.makeApproveOperation(selected)
            )
        }
        operations.push(
            TableOperationsHelper.makeOperationWithType(
                TableOperationActionType.Remove,
                this.dialogPresenter,
                null,
                selected,
                this.completion
            )
        )
        return operations;
    }

    private makeDialogData(): DialogData {
        return {
            dialog: TrailerDialog,
            panel: 'trailer-dialog-container',
            data: {}
        }
    }

    private makeApproveOperation(selected) {
        let dialogData: DialogData = {
            dialog: TrailerApproveDialog,
            panel: 'trailer-approve-dialog-container',
            data: { object: selected[0], disableClose: false }
        };
        return new ActionBarOperation({
            title: 'VEHICLES.ROWOPERATION.APPROVE',
            icon: 'check_circle',
            action: () => { this.dialogPresenter.showDialog(dialogData) },
            disabled: TableOperationsHelper.notSingleSelection(selected)
        });
    }
}
