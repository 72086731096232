import { Parse } from 'parse';

export class PFQuery extends Parse.Query {
    constructor (objName: string) {
      super(objName);
    }

    addAscending(...keys) { super.addAscending(...keys); }
    addDescending(...keys) { super.addDescending(...keys); }
  
    include(field: string) { super.include(field); }
    skip( toSkip: number ) { super.skip(toSkip); }
    limit( toLimit: number) { super.limit(toLimit); }
  
    exists( field: string) { super.exists(field); }
    doesNotExist( field: string) { super.doesNotExist(field); }
  
    matchesQuery ( field: string, query: PFQuery) { return super.matchesQuery(field,query); }
  
    find() { return super.find(); }
    first() { return super.first(); }
  
    equalTo( field: string, obj: any) { return super.equalTo(field, obj);}
    notEqualTo( field: string, obj: any) { return super.notEqualTo(field, obj); }
  
    containedIn( field: string, values: any[]) { return super.containedIn(field, values); }
    notContainedIn( field: string, values: any[]) { return super.notContainedIn(field, values); }
  
    greaterThan( field: string, obj: any) { return super.greaterThan(field, obj); }
    greaterThanOrEqualTo(field: string, obj: any) { return super.greaterThanOrEqualTo(field, obj); }
  
    lessThan(field: string, obj: any) { return super.lessThan(field, obj); }
    lessThanOrEqualTo(field: string, obj: any) { return super.lessThanOrEqualTo(field, obj); }
  
    withJSON(json) { super.withJSON(json); }
  
    static or(...queries: PFQuery[]) { return PFQuery.queryWithParseQueryObject(super.or(...queries)); }
  
    static and(...queries: PFQuery[]) { return PFQuery.queryWithParseQueryObject(super.and(...queries)); }
  
    private static queryWithParseQueryObject(object: Parse.Query) {
      let query = new PFQuery(object.className);
      query.withJSON(object.toJSON());
      return query;
    }
  
    subscribe() { return super.subscribe(); }
  
    count() { return super.count(); }
  
    descending(value: string) { super.descending(value); }
    ascending(value: string) { super.ascending(value); }
  
    contains(field: string, val: string) { super.matches(field, val, "i"); }
    matches(field: string, regExp: string) { super.matches(field, regExp); }
  
    includes(includes) {
      if (includes.length) {
        includes.forEach(field => {
          super.include(field);
        });
      }
    }
  
    withCount(value: boolean) { super.withCount(value);}
  
    findAll(): Promise<Parse.Object[]> {
      this.limit(1000);
      return this.findNextChunk(0, []);
    }
    
    private findNextChunk(offset: number, previousObjects: Parse.Object[]): Promise<Parse.Object[]> {
      this.skip(offset);
      this.withCount(true);
      return this.find().then((data) => {
        let objects = previousObjects.concat(data.results);
        if (objects.length < data.count) return this.findNextChunk(objects.length, objects);
        return Promise.resolve(objects);
      });
    }
  }
  