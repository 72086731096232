import { Component, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Carrier } from 'app/components/data/core/models/persons/carrier';

import { LocaleService } from 'app/components/locale/locale.service';
import { carrier } from '../../../../../locale/locale';
import { CloudExecutor } from 'app/components/data/cloud-executors/cloud-executor';
import { CustomersTableData } from 'app/main/admin/users/table-data/customers-tabledata';
import { TableComponent, TableSelection } from 'app/components/table/table.component';
import { Customer } from 'app/components/data/core/models/persons/customer';

enum LimitationStyle {
  AllowAll = 'all',
  AllowList = 'list',
  AllowNone = 'none',
}

@Component({
  selector: 'carrier-customers-limitations-dialog.component',
  templateUrl: './carrier-customers-limitations-dialog.component.html',
  styleUrls: ['./carrier-customers-limitations-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CarrierCustomerLimitationsDialog implements TableSelection {

  public choosenStyle;
  private object: Carrier;
  public dataSaver = new CloudExecutor();

  public styles;

  private selectedList: Customer[] = [];

  tableData: CustomersTableData;
  @ViewChild(TableComponent) private table: TableComponent;

  constructor(
    public dialogRef: MatDialogRef<CarrierCustomerLimitationsDialog>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private localeService: LocaleService
  ) {
    this.localeService.loadLocale(carrier);
    this.object = this._data.object;
    this.selectedList = this.object.getCustomersList();
    this.tableData = new CustomersTableData();
    if (!this.selectedList) {
      this.selectedList = [];
    }
    this.styles = [
      { value: LimitationStyle.AllowAll, name: 'DIALOG.CARRIER.LIMITATIONS.LIST.STYLE.ALL' },
      { value: LimitationStyle.AllowList, name: 'DIALOG.CARRIER.LIMITATIONS.LIST.STYLE.LIST' },
    ];

    if (this.object.getShowAllCustomers()) {
      this.choosenStyle = this.styles[0];
    } else {
      this.choosenStyle = this.styles[1];
    }
  }

  ngAfterViewInit() {
    this.table.selection = this;
  }

  select(value: Customer) {
    this.selectedList.push(value);
  }

  deselect(value: Customer) {
    let index = this.selectedList.map(c => c.id).indexOf(value.id);
    if (index != -1) {
      this.selectedList.splice(index, 1);
    }
  }

  getSelected(): any[] {
    return this.selectedList;
  }

  toggle(value) {
    if (this.isSelected(value)) {
      this.deselect(value);
    } else {
      this.select(value);
    }
  }

  isSelected(value): boolean {
    let selected = this.selectedList.some(customer => customer.id === value.id);
    return selected;
  }

  clear() { }

  isTableVisible(): boolean {
    return this.choosenStyle.value === LimitationStyle.AllowList;
  }

  confirm() {
    switch (this.choosenStyle.value) {
      case LimitationStyle.AllowAll:
        this.object.setShowAllCustomers(true);
        break;
      default:
        this.object.setShowAllCustomers(false);
        break;
    }

    this.object.setCustomersList(this.selectedList);
    this.dataSaver.saveObject(this.object).then(obj => {
      this.dialogRef.close(obj);
    });
  }
}
