
<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="primary" 
                 title="{{'DIALOG.SUPPLIER_PRIORITY.TITLE' | translate}}">
  </dialog-header>
  <div class="pt-8 px-16" fxFlex fxLayout="column">
    <div class="mat-elevation-z1 table-wrapper" fxFlex cdkScrollable>
      <cd-table [tabledata]="tableData" [options]="tableOptions">
      </cd-table>
    </div>
    <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button
              color="accent"
              (click)="save()">{{'DIALOG.SUPPLIER_PRIORITY.CONFIRMBUTTON' | translate}}
      </button>
    </div>
  </div>
</div>
