import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/material.module';

import { DriverDialog } from './create/driver-dialog.component';
import { DriverApproveDialog } from './approve/driver-approve-dialog.component';
import { AttachmentModule } from 'app/components/attachment/attachment.module';
import { DialogHeaderModule } from 'app/components/dialogs/dialog-header/dialog-header.module';

@NgModule({
    declarations: [
        DriverDialog,
        DriverApproveDialog,
    ],
    imports: [
        TranslateModule,
        FuseSharedModule,
        MaterialModule,
        AttachmentModule,
        DialogHeaderModule,
    ],
    exports: [
        DriverDialog,
        DriverApproveDialog,
    ]
})

export class DriverDialogModule
{
}
