
<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="primary" 
                 title="{{title | translate}}"></dialog-header>

  <div class="pt-8 px-16">
    <form [formGroup]="form">
      <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">
        <mat-form-field fxFlex>
          <input matInput
                 placeholder="{{'DIALOG.CARRIER.FULLNAME' | translate}}"
                 formControlName="name"
                 required
                 cdkFocusInitial>
        </mat-form-field>
      </div>
    </form>
    <div class="h-64" fxLayout="row" fxLayoutAlign="space-between center"> 
      <div fxFlex>{{'DIALOG.CARRIER.PICK_COLOR' | translate}}</div>
      <div class="carrier-color-picker"
           [(colorPicker)]="selectedColor" 
           [style.background]="selectedColor">
      </div>
    </div>
    <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button
              color="accent"
              [disabled] = "dataSaver.isProcessing()"
              (click)='checkAndSave()'>{{buttonName | translate}}
      </button>
    </div>
  </div>
</div>
