
import { Component, Input } from '@angular/core'
import { MatDialog } from '@angular/material/dialog';

import { Parse } from 'parse';

import { Offer } from 'app/components/data/core/models/offer'
import { TripHistoryRecord, TripStage } from 'app/components/data/core/models/trip/trip'

import { ShowOnMapDialogComponent } from 'app/main/auto/dialog/showonmap/show-on-map-dialog.component';
import { TrackDownloadDialog } from 'app/components/track-download/track-download.dialog';

import { LocaleService } from 'app/components/locale/locale.service';
import { orderinfo } from '../../../locale/locale';
import { tripStage, offers } from 'app/main/locale/locale';
import { EditFieldDialog } from './dialog/edit-field/edit-field-dialog.component';
import { CloudExecutor } from 'app/components/data/cloud-executors/cloud-executor';
import { ConfigurationService } from 'app/main/admin/settings/configuration.service';
import { CurrentUser } from 'app/components/data/core/models/base/user';

let locales = [
  orderinfo,
  tripStage,
  offers,
];

@Component({
  selector: 'progress-info',
  templateUrl: './progress-info.component.html',
  styleUrls: ['./progress-info.component.scss'],
})

export class ProgressInfoComponent {

  @Input() offer: Offer;
  historyRecords: TripHistoryRecord[];

  constructor(
    private configService: ConfigurationService,
    private localeService: LocaleService,
    private trackDownloadDialog: TrackDownloadDialog,
    private dialog: MatDialog) {
    this.localeService.loadLocales(locales);
  }

  ngOnInit() {
    this.configService.fetch().then(() => {
      let trip = this.offer.trip();
      if (trip) {
        this.prepareHistoryRecords();
        if (this.historyRecords) {
          Parse.Object.fetchAll(this.historyRecords).then((historyRecords) => {
            this.historyRecords = historyRecords;
          });
        }
      }
    });
  }

  isExtended() {
    return CurrentUser.isAdministrator() || CurrentUser.isManager() || CurrentUser.isLogistician();
  }

  getTripStageName(record: TripHistoryRecord) {
    return 'TRIP.STAGE.' + record.stage();
  }

  shouldShowPhoto(record: TripHistoryRecord): boolean {
    let hasPhoto = record.photo();
    let canShowOnLoadedStage = record.stage() === TripStage.Loaded;
    let canShowOnUnloadedStage = record.stage() === TripStage.Unloaded;
    return hasPhoto && (canShowOnLoadedStage || canShowOnUnloadedStage);
  }

  shouldShowAdditionalData(record: TripHistoryRecord) {
    return record.additionalData();
  }

  thumbnail(record: TripHistoryRecord): Parse.File {
    let thumbnail = record.thumbnail();
    if (thumbnail !== undefined) {
      return thumbnail;
    }
    return record.photo();
  }

  downloadTrack(): void {
    this.trackDownloadDialog.open(this.offer.trip());
  }

  showOnMap(): void {
    this.dialog.open(ShowOnMapDialogComponent, {
      panelClass: 'show-on-map-dialog-container',
      data: { trip: this.offer.trip(), title: this.localeService.translateService.instant('ORDERINFO.ORDER_TRACK') + this.offer.order().number() }
    });
  }

  private prepareHistoryRecords(): void {
    let historyRecords = this.offer.trip().historyRecords();
    if (historyRecords) {
      historyRecords.sort((one, other) => one.stage() - other.stage());
    }
    this.historyRecords = historyRecords;
  }

  editTonnage(record: TripHistoryRecord): void {
    this.dialog.open(EditFieldDialog, {
      panelClass: 'edit-field-dialog-container',
      data: { 
        fieldType: 'number',
        fieldName: 'ORDERINFO.DIALOG.FIELD_EDIT.TONNAGE',
        fieldValue: record.tonnage(),
        changeValueHandler: (value) => { 
          record.setTonnage(parseFloat(value)); 
          return record;
        }
      }
    });
  }

  editKilometers(record: TripHistoryRecord): void {
    let additionalData = record.additionalData();
    this.dialog.open(EditFieldDialog, {
      panelClass: 'edit-field-dialog-container',
      data: { 
        fieldType: 'number',
        fieldName: 'ORDERINFO.DIALOG.FIELD_EDIT.KILOMETERS',
        fieldValue: additionalData.kilometers,
        changeValueHandler: (value) => { 
          additionalData.kilometers = parseInt(value);
          record.setAdditionalData(additionalData); 
          return record;
        }
      }
    });
  }

  editWaybill(record: TripHistoryRecord): void {
    let additionalData = record.additionalData();
    this.dialog.open(EditFieldDialog, {
      panelClass: 'edit-field-dialog-container',
      data: { 
        fieldType: 'text',
        fieldName: 'ORDERINFO.DIALOG.FIELD_EDIT.WAYBILL_NUMBER',
        fieldValue: additionalData.waybillNumber,
        changeValueHandler: (value) => { 
          additionalData.waybillNumber = value;
          record.setAdditionalData(additionalData); 
          return record;
        }
      }
    });
  }

  hasWrongCoordinate(record: TripHistoryRecord) {
    if (!record.coordinate()) {
      return false;
    }
    if (record.ignoresWrongCoordinate()) {
      return false;
    }
    let expectedCoordinate = this.getExpectedCoordinate(record);
    if (!expectedCoordinate) {
      return false;
    }
    let maxDistance = this.configService.getConfiguration().getMaxDistanceDifference() / 1000;
    return expectedCoordinate.kilometersTo(record.coordinate()) > maxDistance;
  }

  setWrongState(record: TripHistoryRecord) {
    record.setIgnoreWrongCoordinate(true);
    let dataSaver = new CloudExecutor;
    dataSaver.saveObject(record, { cascadeSave: false }).catch(error => {
      console.log(error);
      record.setIgnoreWrongCoordinate(false);
    });
  }

  private getExpectedCoordinate(record: TripHistoryRecord): Parse.GeoPoint {
    let order = this.offer.order();
    switch (record.stage()) {
      case TripStage.Loaded: return order.loadingEntrance() ? order.loadingEntrance().getCoordinate() : null;
      case TripStage.Unloaded: return order.unloadingEntrance() ? order.unloadingEntrance().getCoordinate() : null;
      default: return null;
    }
  }
}
