<div class='component-container'>
  <div class="cd-table" *ngIf="getTableData()">
    <table mat-table 
           class='transparent'
           [dataSource]="getDataSource()" 
           matSort 
           cdkDropList 
           [cdkDropListData]="getDataSource()"
           (cdkDropListDropped)="drop($event)">
      <ng-container matColumnDef="select" *ngIf="options.selectable" matColumnDef="select">
          <mat-header-cell *matHeaderCellDef class="checkbox-column">
              <mat-checkbox *ngIf="!options.singleSelection"
                            (change)="$event ? masterToggle() : null"
                            [checked]="selectedValues()?.length > 0"
                            [indeterminate]="isSelectedSameAsDisplayed()"
                            [disabled]="!hasData() || disabled">
              </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let item"
                      class="checkbox-column">
                      <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? toggle(item) : null"
                                    [checked]="isSelected(item)"
                                    [disabled]="isDisabled(item)">
                      </mat-checkbox>
            </mat-cell>
      </ng-container>
      <ng-container *ngFor="let column of getTableData().columns" [matColumnDef]="column.key">
          <mat-header-cell *matHeaderCellDef #headerCells
                           [ngStyle]="column.headerCellStyle ? column.headerCellStyle() : null"
                           [fxFlex]="column.flex()">
              <div class="header-text" mat-sort-header [disabled]="isSortDisabled(column)">
                  {{ column.header | translate }}
              </div>
              <button *ngIf="!isSortByDateDisabled(column)"
                      mat-icon-button
                      (click)="onColumnClick(column, $event)">
                  <mat-icon>sort</mat-icon>
              </button>
          </mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index" 
                     class="tablecell" 
                     [ngClass]="column.cellClass ? column.cellClass(row, i) : null" 
                     [ngStyle]="column.cellStyle ? column.cellStyle(row, i) : null" 
                     [fxFlex]="column.flex()">
              <div *ngIf="column.isHTML()"
                   [innerHTML]="column.cell(row, i)">
              </div>
              <div *ngIf="!column.isHTML()">
                   {{column.cell(row, i)}}
              </div>
            </mat-cell>
      </ng-container>
      <ng-container matColumnDef="drag" *ngIf="options.dragable">
        <mat-header-cell *matHeaderCellDef class="w-50"></mat-header-cell>
        <mat-cell *matCellDef="let item" class="w-50">
            <mat-icon cdkDragHandle>reorder</mat-icon>
        </mat-cell>
      </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns()">
        </mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns();"
                [class.selected-row]="selection && options.selectable && selection.isSelected(row)"
                [class.warn-row]="isWarnRow(row)"
                [class.deleted-row]="isDisabled(row)"
                [class.hover-row]="!isDisabled(row)"
                (click)="singleClick(row)"
                (dblclick)="doubleClick(row)"
                cdkDrag
                [cdkDragData]="row"
                [cdkDragDisabled]="!options.dragable">
      </mat-row>
    </table>
  </div>
  <div *ngIf='!hasData() && !isLoading() && !getLastError()' 
       class="h-150" 
       fxLayout="column" 
       fxLayoutAlign="center center">
    <mat-label class="no-items h3 py-40">{{ noItemsTitle | translate}}</mat-label>
  </div>
  <div *ngIf="isLoading()"
       class="loading-shade py-40 h-100"  
       fxLayout="column" 
       fxLayoutAlign="center center">
      <mat-spinner diameter="30" color="accent"></mat-spinner>
  </div>
  <div *ngIf="!isLoading() && getLastError()" 
       class="py-20"  
       fxLayout="column" 
       fxLayoutAlign="center center">
      <mat-label>{{'COMMON.ERROR.LOAD_ERROR' | translate}}</mat-label>
  </div>
  <mat-paginator *ngIf="!options.hiddenPaginator"
                 [fxShow]="hasData()"
                 class="transparent"
                 [length]="dataSource?.totalDataCount()"
                 [pageSizeOptions]='getTableData()?.getPageSizes()'
                 [disabled]="disabled">
  </mat-paginator>

  <div #hiddenFormField>
      <mat-form-field class="hidden-input"
                      style="visibility: hidden; position: absolute;">
        <mat-date-range-input [formGroup]="dateRange" [rangePicker]="dateRangePicker">
          <input matStartDate formControlName="start" placeholder="Start date">
          <input matEndDate formControlName="end" placeholder="End date">
        </mat-date-range-input>
        <mat-date-range-picker #dateRangePicker (closed)="onDateRangeSelected()"></mat-date-range-picker>
      </mat-form-field>
  </div>
</div>
