export const locale = {
  'NAV': {
    'MAIN': {
      'TITLE': 'Home',
    },
    'AUTO': {
      'TITLE': 'Auto',
      'ONMAP': 'On map',
      'TRANSPORTUNITS': 'Transport units',
    },
    'ORDERS': {
      'TITLE': 'Orders',
      'ALL': 'All',
      'LIST': 'New',
      'INPROGRESS': 'In progress',
      'FINISHED': 'Finished',
    },
    'PROPOSALS': {
      'TITLE': 'Proposals',
      'LIST': 'Proposals list',
      'CARRIER_PROPOSALS': 'Carrier proposals',
      'PROPOSALS_BY_CARRIERS': 'By carriers',
      'PROPOSALS_BY_SUPPLIERS': 'By suppliers',
      'PROPOSALS_FROM_CUSTOMERS': 'From customers',
    },
    'REPORTS': {
      'TITLE': 'Reports',
      'CREATE': 'Create report',
    },
    'ADMIN': {
      'TITLE': 'Administration',
      'USERS': 'Users',
      'VEHICLES': 'Vehicles and Trailers',
      'ARTICLES': 'Articles',
      'SETTINGS': 'Settings',
      'EXPORT_QUEUE': 'Export queue',
      'MESSAGES_LIST': 'Messages',
      'KILOMETERS': 'Kilometers',
    },
    'CUSTOMER_INFO': {
      'TITLE': 'Info',
    }
  }
};
