import { ArticleBrand } from '../articles/article';
import { PFObject } from '../base/object';
import { Supplier } from '../persons/supplier';

export class Tariff extends PFObject {
    constructor(name) { super(name); }

    price(): number { return super.get('tariff'); }
    supplier(): Supplier { return super.get('supplier'); }
    brand(): ArticleBrand { return super.get('articleBrand'); }
    loadingPoint() { return super.get('loadingPoint') };
}

export class SaleTariff extends Tariff {
    constructor() { super('SaleTariff'); }
}

export class PurchaseTariff extends Tariff {
    constructor() { super('PurchaseTariff'); }
}

export namespace TariffFilter {
    export function filterTariffWithSupplier(tariffs: Tariff[], supplier: Supplier) {
        if (!supplier) return [];
        return tariffs.filter(t => {
          return PFObject.compareFn(t.supplier(), supplier);
        });
      }

    export function collectSuppliers(tariffs: Tariff[]) {
        let suppliers: Supplier[] = [];
        tariffs.forEach(t => {
            let supplier = t.supplier();
            let index = suppliers.findIndex(s => {
                return PFObject.compareFn(s, supplier);
            });
            if (index == -1) {
                suppliers.push(supplier);
            }
        });
        return suppliers;
    }

    export function collectBrands(tariffs: Tariff[]) {
        let brands: ArticleBrand[] = [];
        tariffs.forEach(t => {
            let brand = t.brand();
            let index = brands.findIndex(b => {
                return PFObject.compareFn(b, brand);
            });
            if (index == -1) {
                brands.push(brand);
            }
        });
        return brands;
    }
}
