<mat-form-field class="no-padding no-underline" [appearance]="appearance" fxFlex>
    <mat-label>Период</mat-label>
    <mat-date-range-input [formGroup]="rangeForm" [rangePicker]="picker" (click)="picker.open()">
      <input matStartDate formControlName="start" placeholder="Начало периода"> 
      <input matEndDate formControlName="end" placeholder="Конец периода">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix *ngIf="!disabled" [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker [disabled]="disabled">
      <mat-date-range-picker-actions>
        <button mat-raised-button 
                *ngIf="!hideReset"
                color="primary" 
                matDateRangePickerCancel
                (click)="reset()">
                Сбросить
        </button>
        <button mat-raised-button color="accent" matDateRangePickerApply>Применить</button>
      </mat-date-range-picker-actions>
    </mat-date-range-picker>
</mat-form-field>