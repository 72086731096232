
<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="primary" 
                 title="{{'DIALOG.UNIT.CREATE.TITLE' | translate}}">
  </dialog-header>
  
  <div class="pt-8 px-16">
    <form class="mb-10" [formGroup]="form">
      <div fxLayout="column" 
           fxLayoutAlign="space-between none"
           [style.height]="isCarrierVisible() ? '350px' : '300px'">

        <div *ngIf="isCarrierVisible()">
          <mat-form-field fxFlex>
            <input 
            placeholder="{{'DIALOG.UNIT.CARRIER_SELECT' | translate}}"
            matInput
            formControlName="carrier"
            [matAutocomplete]="carrierAutocomplete"
            cdkFocusInitial
            required>
            <mat-autocomplete  #carrierAutocomplete="matAutocomplete" (optionSelected)="carrierChanged()" [displayWith]="nameFn">
              <mat-option *ngIf='carriersLoader.isLoading()'>
                <mat-spinner diameter="20" color="accent"></mat-spinner>
              </mat-option>
              <mat-option *ngFor="let carrier of carriers | async" [value]="carrier">
                {{carrier.getName()}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="form.get('carrier').invalid">{{'DIALOG.UNIT.ERROR.NO_CARRIER' | translate}}</mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="isInitialized && form.controls.carrier.valid" fxLayout="column" fxLayoutAlign="space-between none" fxFlex>
          <div>
            <mat-form-field fxFlex>
              <input 
              placeholder="{{'DIALOG.UNIT.DRIVER_SELECT' | translate}}"
              matInput
              formControlName="driver"
              [matAutocomplete]="driverAutocomplete"
              required>
              <mat-autocomplete #driverAutocomplete="matAutocomplete" [displayWith]="nameFn">
                <mat-option *ngIf='driversLoader.isLoading()'>
                  <mat-spinner diameter="20" color="accent"></mat-spinner>
                </mat-option>
                <mat-option *ngFor="let driver of drivers | async" [value]="driver">
                  {{driver.getName()}}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="form.get('driver').invalid">{{'DIALOG.UNIT.ERROR.NO_DRIVER' | translate}}</mat-error>
            </mat-form-field>
          </div>
    
          <div>
            <mat-form-field fxFlex>
              <input 
              placeholder="{{'DIALOG.UNIT.VEHICLE_SELECT' | translate}}"
              matInput
              formControlName="vehicle"
              [matAutocomplete]="vehicleAutocomplete"
              required>
              <mat-autocomplete #vehicleAutocomplete="matAutocomplete" [displayWith]="numberFn">
                <mat-option *ngIf='vehiclesLoader.isLoading()'>
                  <mat-spinner diameter="20" color="accent"></mat-spinner>
                </mat-option>
                <mat-option *ngFor="let vehicle of vehicles | async" [value]="vehicle">
                  {{vehicle.number()}}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="form.get('vehicle').invalid">{{'DIALOG.UNIT.ERROR.NO_VEHICLE' | translate}}</mat-error>
            </mat-form-field>
          </div>
    
          <div>
            <mat-form-field fxFlex>
              <input 
              placeholder="{{'DIALOG.UNIT.TRAILER_SELECT' | translate}}"
              matInput
              formControlName="trailer"
              [matAutocomplete]="trailerAutocomplete"
              required>
              <mat-autocomplete #trailerAutocomplete="matAutocomplete" [displayWith]="numberFn">
                <mat-option *ngIf='trailersLoader.isLoading()'>
                  <mat-spinner diameter="20" color="accent"></mat-spinner>
                </mat-option>
                <mat-option *ngFor="let trailer of trailers | async" [value]="trailer">
                  {{trailer.number()}}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="form.get('trailer').invalid">{{'DIALOG.UNIT.ERROR.NO_TRAILER' | translate}}</mat-error>
            </mat-form-field>
          </div>

          <div class="py-8" fxLayout="row" fxLayoutAlign="end end" fxFlex>
            <button mat-raised-button
                    color="accent"
                    class="submit-button"
                    [disabled] = "executor.isProcessing()"
                    (click)='checkAndSave()'>{{'DIALOG.UNIT.CREATE.CONFIRMBUTTON' | translate}}
            </button>
          </div>
        </div>
      </div>
      
    </form>
  </div>
</div>
