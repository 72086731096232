
import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './locale/en';
import { locale as russian } from './locale/ru';

@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
})
export class AlertComponent {
  public title: string;
  public content: string;

  constructor (
    @Inject(MAT_DIALOG_DATA) private data: any,
    private translationLoaderService: FuseTranslationLoaderService
  ) {
    this.title = this.data.title;
    this.content = this.data.content;
    this.translationLoaderService.loadTranslations(english, russian);
  }
}
