
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { VehiclesComponent } from './vehicles.component';

import { MaterialModule } from 'app/main/material.module';

import { TableHeaderModule} from 'app/components/tableheader/tableheader.module';
import { TableModule } from 'app/components/table/table.module';
import { RemoveDialogModule } from 'app/components/dialogs/removedialog/remove-dialog.module';

import { CreateVehicleBrandModule } from 'app/main/admin/vehicles/dialogs/vehicle_brand/vehicle-brand-dialog.module';
import { CreateVehicleModelModule } from 'app/main/admin/vehicles/dialogs/vehicle_model/vehicle-model-dialog.module';

import { VehicleDialogModule } from 'app/main/admin/vehicles/dialogs/vehicle/vehicle-dialog.module';
import { TrailerDialogModule } from 'app/main/admin/vehicles/dialogs/trailer/trailer-dialog.module';
import { ActionBarModule } from 'app/components/action-bar/action-bar.module';

@NgModule({
  declarations: [
    VehiclesComponent,
  ],
  imports     : [
    TranslateModule,
    FuseSharedModule,

    MaterialModule,
    TableHeaderModule,
    TableModule,
    RemoveDialogModule,
    CreateVehicleBrandModule,
    CreateVehicleModelModule,
    TrailerDialogModule,
    VehicleDialogModule,
    ActionBarModule,
  ],
  exports     : [
    VehiclesComponent,
  ],
})
export class VehiclesModule {
}
