export const locale = {
    'DIALOG': {
        'KILOMETERS': {
          'TITLE': {
            'CREATE': 'Create kilometers',
            'EDIT': 'Edit kilometers',
          },
          'CONFIRMBUTTON': {
            'CREATE': 'Create',
            'SAVE': 'Save',
          },
          'CUSTOMER': 'Customer',
          'UNLOADING_POINT': 'Unloading point',
          'SUPPLIER': 'Supplier',
          'LOADING_POINT': 'Loading point',
          'DISTANCE': 'Distance',
          'ERROR': {
            'UNDEFINED': 'Undefined error. Try again later.',
            'EXISTS': 'This pair is already exists',
          },
        },
      },
  };
  