import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { TableModule } from 'app/components/table/table.module';
import { MaterialModule } from 'app/main/material.module';

import { ShowOnMapDialogComponent } from './show-on-map-dialog.component'
import { AgmCoreModule } from '@agm/core';
import { DownloadModule } from 'app/components/download/download.module';
import { TrackDownloadDialog } from 'app/components/track-download/track-download.dialog';
import { DialogHeaderModule } from 'app/components/dialogs/dialog-header/dialog-header.module';

@NgModule({
    declarations: [
        ShowOnMapDialogComponent,
    ],
    providers: [
        TrackDownloadDialog
    ],
    imports: [
        MaterialModule,
        TranslateModule,
        FuseSharedModule,
        TableModule,
        AgmCoreModule,
        DownloadModule,
        DialogHeaderModule,
    ],
    exports: [
        ShowOnMapDialogComponent,
    ]
})

export class ShowOnMapModule
{
}
