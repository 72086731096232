export const locale = {
    'DIALOG': {
        'FINISH_ORDER': {
            'TITLE': 'Завершение заказа',
            'CONFIRM_BUTTON': 'Завершить',
            'DATE': 'Дата',
            'TONNAGE': 'Тоннаж',
            'DISTANCE': 'Километраж',
            'TIME': 'Время',
            'ADD_PHOTO': 'Добавить фото',
            'LOAD': 'Загрузка',
            'UNLOAD': 'Разгрузка',
            'FINISHING': 'Заказ завершается. Пожалуйста ожидайте...',
        },
    },
};
