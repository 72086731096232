
<div class="dialog-content-wrapper">
  <dialog-header headerClass="primary" 
                 title="{{title | translate}}"></dialog-header>
  

  <div class="pt-8 px-16">
    <form [formGroup]="form">

      <div>
        <mat-form-field fxFlex>
          <input matInput
                 placeholder="{{'CREATEBRAND.NAME' | translate}}"
                 formControlName="name"
                 required
                 cdkFocusInitial>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field fxFlex>
          <mat-select placeholder="{{'CREATEBRAND.TYPE' | translate}}"
                      formControlName="type"
                      [compareWith]="compareFunction"
                      required>
            <mat-option *ngFor='let type of dataLoader.getLoadedItems()' [value]="type">{{type.get('name')}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field fxFlex>
          <input matInput
                 type="number"
                 placeholder="{{'CREATEBRAND.TONNAGE_PER_TRUCK' | translate}}"
                 formControlName="tonnagePerTruck"
                 required
                 min='0'>
        </mat-form-field>
      </div>

      <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button
                color="accent"
                [disabled] = "dataSaver.isProcessing()"
                (click)='checkAndSave()'>{{buttonName | translate}}
        </button>
      </div>

    </form>
  </div>

</div>
