export const locale = {
    'DIALOG': {
      'UNLOADING_POINT': {
        'TITLE': {
          'CREATE': 'Create point',
          'EDIT': 'Edit point',
        },
        'CONFIRMBUTTON': {
          'CREATE': 'Create',
          'SAVE': 'Save',
        },
        'CONTACT_NAME': 'Contact name',
        'CONTACT_PHONE': 'Contact phone',
        'ADDRESS': 'Address',
      },
    },
  };
  