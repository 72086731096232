import { Component, Inject, ViewEncapsulation } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Order, Type } from 'app/components/data/core/models/order/order';

import { LocaleService } from 'app/components/locale/locale.service';
import { finish_order } from 'app/main/orders/locale/locale';
import { common } from 'app/main/locale/locale';
let locales = [finish_order, common];

import * as FormHelper from 'app/components/form-helper/form-helper';
import { DispatcherCloudExecutor } from 'app/components/data/cloud-executors/dispatcher-cloud-executor';
import { DialogPresenterService } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import { DisbandUnitDialogComponent } from 'app/main/auto/dialog/disbandconfirmation/disband-unit-dialog.component';
import { TransportUnit } from 'app/components/data/core/models/transport/transport';
import { Distance } from 'app/components/data/core/models/distance';
import { PFQuery } from 'app/components/data/core/models/base/query';
import { TripStage } from 'app/components/data/core/models/trip/trip';

@Component({
  selector: 'finish-order-dialog',
  templateUrl: './finish-order-dialog.component.html',
  styleUrls: ['./finish-order-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class FinishOrderComponent {
  private order: Order;
  public form: UntypedFormGroup;
  public executor = new DispatcherCloudExecutor;
  
  disabledLoadSection: boolean;
  loadPhoto;
  unloadPhoto;

  constructor(
    private localeService: LocaleService,
    private dialogPresenter: DialogPresenterService,
    private _formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<FinishOrderComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) {
    this.localeService.loadLocales(locales);
    this.order = this._data.object;

    let loadDate = this.order.loadingDate();
    let loadTime = loadDate ? loadDate.getHours() : null;

    let unloadDate = this.order.unloadingBeginDate();
    let unloadTime = unloadDate ? unloadDate.getHours() : null;

    var loadedTonnage = null;

    let offers = this.order.offers();
    if (offers) {
      let offer = offers[0];
      let trip = offer.trip();
      if (trip && trip.historyRecords()) {
        let record = trip.historyRecords().find(historyRecord => historyRecord.stage() === TripStage.Loaded)
        if (record) {
          loadedTonnage = record.tonnage();
          loadDate = record.date();
          loadTime = loadDate.getHours();
        }
      }
    }

    let disabledLoadSection = loadedTonnage != null;
    this.disabledLoadSection = disabledLoadSection;

    this.form = this._formBuilder.group({
      loaded_date: [{value: loadDate, disabled:disabledLoadSection}],
      load_time: [{value: loadTime, disabled:disabledLoadSection}, [Validators.min(0), Validators.max(23)]],
      loaded_tonnage: [{value: loadedTonnage, disabled:disabledLoadSection}, Validators.min(0)],
      distance: [0, Validators.min(0)],
      unloaded_date: [unloadDate],
      unload_time: [unloadTime, [Validators.min(0), Validators.max(23)]],
      unloaded_tonnage: [null, Validators.min(0)],
    });
  }

  ngOnInit() {
    this.fetchDistance();
  }
  
  dateWithDateAndTime(date, hours): Date {
    date.setHours(hours);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
  }

  finishOrder() {
    if (this.checkForm()) {
      let loadDate = this.dateWithDateAndTime(new Date(this.form.controls.loaded_date.value), this.form.controls.load_time.value);
      let unloadDate = this.dateWithDateAndTime(new Date(this.form.controls.unloaded_date.value), this.form.controls.unload_time.value);
      let loadedTonnage = this.form.controls.loaded_tonnage.value;
      let unloadedTonnage = this.form.controls.unloaded_tonnage.value;      
      let distance: number = this.form.controls.distance.value;
      let loadedPhoto;
      let unloadedPhoto;

      if (this.loadPhoto) {
        let base64 = this.loadPhoto.content.split(',').pop();
        loadedPhoto = base64;
      }

      if (this.unloadPhoto) {
        let base64 = this.unloadPhoto.content.split(',').pop();
        unloadedPhoto = base64;
      }

      let units = this.order.offers().map(offer => offer.transportUnit());

      this.executor.finishOrder(
        this.order,
        loadDate,
        loadedTonnage,
        distance,
        unloadDate,
        unloadedTonnage,
        loadedPhoto,
        unloadedPhoto)
        .then(() => {
          this.askDisbandUnits(units);
        });
    }
  }

  askDisbandUnits(units: TransportUnit[]) {
    this.dialogPresenter.showDialog({
      dialog: DisbandUnitDialogComponent,
      panel: 'disband-unit-dialog-container',
      data: {
        units: units,
      }
    });
    this.dialogRef.close(true);
  }

  checkForm(): boolean {
    return FormHelper.checkForm(this.form);
  }

  fetchDistance(): void {
    let query = new PFQuery('Distance');
    query.equalTo('loadingPoint', this.order.loadingPoint());
    query.equalTo('unloadingPoint', this.order.unloadingPoint());
    query.first().then((distance: Distance) => {
      if (distance) {
        this.form.controls.distance.setValue(distance.distance());
      }
    });
  }

  openImage(imageData) {
    let w = window.open('about:blank');
    let image = new Image();
    image.src = imageData.content;
    setTimeout(() => {
      w.document.getElementsByTagName('body')[0].innerHTML = image.outerHTML;
    }, 0);
  }
}
