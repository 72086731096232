
export interface Coordinate {
  readonly latitude: number;
  readonly longitude: number;
}

export function isCoordinatesEqual(one: Coordinate, other: Coordinate): boolean {
  return one.latitude === other.latitude && one.longitude === other.longitude;
}

export function getDistance(one: Coordinate, other: Coordinate): number {
  if (isCoordinatesEqual(one, other)) {
    return 0;
  }

  let theta = one.longitude - other.longitude;

  let distance =
    Math.sin(radiansFromDegrees(one.latitude)) * Math.sin(radiansFromDegrees(other.latitude)) +
    Math.cos(radiansFromDegrees(one.latitude)) * Math.cos(radiansFromDegrees(other.latitude)) * Math.cos(radiansFromDegrees(theta));

  distance = Math.acos(distance);
  distance = degreesFromRadians(distance);
  return distance * 60 * 1.1515 * 1.609344 * 1000;
}

function radiansFromDegrees(degrees: number): number { return degrees * Math.PI / 180.0; }
function degreesFromRadians(radians: number): number { return radians * 180.0 / Math.PI; }
