
import { TableData, TableSort } from 'app/components/table/tabledata';

import { CurrentUser } from 'app/components/data/core/models/base/user';
import { OrderStatus } from 'app/components/data/core/models/order/order';
import { PFQuery } from 'app/components/data/core/models/base/query';
import * as AccessQueries from 'app/components/access/query';

import { TranslateService } from '@ngx-translate/core';
import { QueryResultFilter } from 'app/components/access/queryResultFilter';
import { ServerTableDataSource } from 'app/components/table/data_sources/server-data-source';
import { LiveQuerySubscriber, LiveQueryModifier, LiveQueryInclusion } from 'app/components/table/data_sources/live-query-subscriber';
import { OrderQuerySearchFilter } from '../../helpers/order-query-search-filter';
import { OrderColumns } from '../../helpers/order-columns-factory';
import {TreeNode} from "../../../../components/hierarchical-checkbox/tree-node.model";

export class OrdersInProgressListTableData extends TableData {

  constructor(
    private translate:TranslateService
  ) {
    super();

    let columns = [
      OrderColumns.makeColumnNumber()
    ];

    let userColumns;
    if (CurrentUser.isCustomer()) {
      userColumns = [
        OrderColumns.makeColumnUnloadPoint(),
        OrderColumns.makeColumnBrand(),
        OrderColumns.makeColumnTonnage(),
        OrderColumns.makeColumnUnloadDate(this.translate.currentLang),
        OrderColumns.makeColumnCustomerStatus(this.translate),
      ]
    } else {
      userColumns = [
        OrderColumns.makeColumnCustomer(),
        OrderColumns.makeColumnUnloadDate(this.translate.currentLang),
        OrderColumns.makeColumnUnloadPoint(),
        OrderColumns.makeColumnCreatedAt(this.translate.currentLang),
        OrderColumns.makeColumnDriverStatus(this.translate),
      ]
      
      if (CurrentUser.isAdministrator() || CurrentUser.isLogistician()) {
        userColumns.push(
          OrderColumns.makeColumnAuthorName()
        );
      }
    }

    userColumns.push(OrderColumns.makeColumnComment())

    columns = columns.concat(userColumns);
    
    this.setColumns(columns);

    let includeFields = [
      'unloadingPoint',
      'offers.trip.historyRecords',
      'offers.trip.transportUnit.driver',
      'offers.transportUnit.driver',
      'offers.transportUnit.user',
      'offers.transportUnit.vehicle.model.brand',
      'offers.transportUnit.trailer',
      'articleBrand.type',
      'customer',
      'unloadingEntrance',
      'carriers',
      'loadingEntrance',
      'carrierOffers.driver',
    ];

    if (CurrentUser.isCustomer()) {
      includeFields.push('unloadingPoint.manager');
    } else {
      includeFields = includeFields.concat([
        'loadingPoint',
        'intermediary',
        'supplier',
        
        'loadingAgreement',
      ]);
    }

    if (CurrentUser.isAdministrator() || CurrentUser.isLogistician()) {
      includeFields = includeFields.concat([
        'author',
        'author.manager',
        'author.dispatcher',
        'author.customer',
        'author.logistician',
      ]);
    }
    
    let sort: TableSort = new TableSort('number','desc');
    let tableDataSource = new ServerTableDataSource('Order', includeFields);
    if (!CurrentUser.isCustomer()) {
      tableDataSource.loadAllCustomers();
    }
    if (CurrentUser.isAdministrator() || CurrentUser.isLogistician()) {
      tableDataSource.loadUniqueAuthors();
    }
    tableDataSource.setDefaultSort(sort);
    tableDataSource.loadAllCustomers();
    tableDataSource.loadUniqueAuthors();
    tableDataSource.queryFilter = (filter, query) => {
      let searchQuery;
      let unloadingEntranceAddressFilter = OrderQuerySearchFilter.unloadingEntranceAddress(filter);
      if (CurrentUser.isCustomer()) {
        let brandNameFilter = OrderQuerySearchFilter.brandName(filter);
        searchQuery = PFQuery.or(
          brandNameFilter,
          unloadingEntranceAddressFilter,
        );
        let tonnage = parseFloat(filter);
        if (!isNaN(tonnage)) {
          let tonnageFilter = OrderQuerySearchFilter.expectedTonnage(tonnage);
          searchQuery = PFQuery.or(searchQuery, tonnageFilter);
        }
      } else {
        let customerNameFilter = OrderQuerySearchFilter.customerName(filter);
        let driverNameFilter = OrderQuerySearchFilter.driverName(filter);
        searchQuery = PFQuery.or(
          customerNameFilter,
          unloadingEntranceAddressFilter,
          driverNameFilter
        );
      }

      let number = parseFloat(filter);
      if (!isNaN(number)) {
        let numberFilter = OrderQuerySearchFilter.orderNumber(number);
        searchQuery = PFQuery.or(searchQuery, numberFilter);
      }
      return searchQuery;
    };

    function applyQueryModification(query: PFQuery) {
      if (CurrentUser.isCustomer()) {
        query = AccessQueries.containsCurrentCustomer(query);
      } else if (CurrentUser.isDispatcher()) {
        query = AccessQueries.containsCurrentCarrier(query);
      } else if (!CurrentUser.isAdministrator() && !CurrentUser.isLogistician()) {
        query = AccessQueries.authorMatchesCurrentUser(query);
        let managerAssignedOrders = new PFQuery('Order');
        managerAssignedOrders = AccessQueries.managerMatchesCurrentUser(managerAssignedOrders);
        query = PFQuery.or(query, managerAssignedOrders);
      }
      query.greaterThan('distributedTonnage', 0);
      query.greaterThan('unfinishedTonnage', 0);
      query.equalTo('status', OrderStatus.READY);
      query = AccessQueries.filterDeleted(query);
      return query;
    }

    tableDataSource.queryModify = function (query: PFQuery): PFQuery {
      return applyQueryModification(query);
    };

    let subscriber = new LiveQuerySubscriber('Order', includeFields);

    subscriber.liveQueryModifier = new LiveQueryModifier(
      function (query) {
        return applyQueryModification(query);
      }
    );

    let filter = function (object) {
      if (CurrentUser.isDispatcher()) {
        if (!QueryResultFilter.transportUnitMatchesCurrentCarrier(object.transportUnit())) { return null; }
      } else if (!CurrentUser.isAdministrator() && !CurrentUser.isLogistician()) {
        if (!QueryResultFilter.orderAuthorMatchesCurrentUser(object.transportUnit())) { return null; }
      }
      return object;
    };

    subscriber.liveQueryInclusions = [
      new LiveQueryInclusion("Offer", new LiveQueryModifier(null, filter)),
      new LiveQueryInclusion("Trip", new LiveQueryModifier(null, filter), ["historyRecords"]),
    ];

    tableDataSource.setLiveQuerySubscriber(subscriber);
    this.setProvider(tableDataSource);
  }

  canSortColumn(column) {
    switch (column.key) {
      case 'number':
      case 'createdAt':
        return true;
      default:
        return false;
    }
  }

  canSortByDateColumn(column) {
    switch (column.key) {
      case 'customer':
      case 'unloadDate':
      case 'createdAt':
      case 'driver_status':
      case 'author':
        return false;
      default:
        return true;
    }
  }

  getStatusFilterData()
  {
    let treeData: TreeNode[] = [
      { name: "Выехал под загрузку", checked: false },
      { name: "Прибыл в пункт загрузки", checked: false },
      { name: "Загрузился", checked: false },
      { name: "Выехал в пункт разгрузки", checked: false },
      { name: "Прибыл в пункт разгрузки", checked: false },
    ];
    return treeData;
  }
}
