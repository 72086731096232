
export const locale = {
  'DIALOG': {
    'DRIVER': {
      'CREATE': {
        'TITLE': 'Создать водителя',
        'CONFIRMBUTTON': 'Создать',
      },
      'EDIT': {
        'TITLE': 'Редактировать водителя',
        'CONFIRMBUTTON': 'Сохранить',
      },
      'APPROVE': {
        'TITLE': 'Утверждение водителя',
        'CONFIRMBUTTON': 'Утвердить',
        'DECLINEBUTTON': 'Снять утверждение',
      },
      'FULLNAME': 'ФИО водителя',
      'PHONE_NUMBER': 'Контакт',
      'APPROVED_LOCK': 'Редактирование заблокировано для утвержденных водителей',
      'SHOW_TARIFF_OPTION': 'Отображать цену доставки',
      'CARRIER_SELECT': 'Выберите перевозчика',
      'ERROR': {
        'NO_CARRIER': 'Перевозчик не выбран',
      },
    }
  },
};
