export const locale = {
    'STEP_CUSTOMER_ORDER': {
        'DIALOG': {
            'AGREE_FIELD': {
                'TITLE': 'Order status',
                'AGREE': 'Agree',
                'NOT_AGREE': 'Not agree',
            },
        },
    },
};
