
<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="primary" 
                 title="{{'DIALOG.SUPPLIER.TITLE.POINTS' | translate}}"
                 subtitle="{{object.getName()}}">
  </dialog-header>
  <div class="pt-8 px-16" fxFlex fxLayout="column">
    <form [formGroup]="form">
      <mat-form-field fxFlex>
          <mat-select placeholder="{{'DIALOG.SUPPLIER.ADDRESS' | translate}}"
                      formControlName="loadingPoint"
                      (selectionChange)='pointChanged($event.value)'
                      [compareWith]="compareFunction"
                      cdkFocusInitial>
            <mat-option *ngFor='let point of getPoints()' [value]="point">{{point.getAddress()}}</mat-option>
          </mat-select>
      </mat-form-field>
    </form>
    <div *ngIf="form.value.loadingPoint" class="mat-elevation-z1 table-wrapper" fxFlex>
      <div fxLayout="column" fxFlex>
        <action-bar [operations]="tableOperations"></action-bar>
        <cd-table [tabledata]="tableData"
                  (onSelectionChange)="updateOperations($event)">
        </cd-table>
      </div>
    </div>
    <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button
              color="accent"
              (click)="dialogRef.close()">{{'DIALOG.SUPPLIER.CONFIRMBUTTON.CLOSE' | translate}}
      </button>
    </div>
  </div>
</div>
