import { CloudExecutor } from './cloud-executor';
import { Order } from 'app/components/data/core/models/order/order';

export interface CustomerStatus {
    allowed?: boolean
    accountsReceivable?: string
    accountsPayable?: string
    lastPaymentDate?: Date
    stopFactor?: string
}

export class CustomerCloudExecutor extends CloudExecutor {
    makeOrder(order: Order) {
        let params = {
            unloadingPointId: order.unloadingPoint().id,
            unloadingEntranceId: order.unloadingEntrance().id,
            unloadingBeginDate: order.unloadingBeginDate().getTime(),
            unloadingEndDate: order.unloadingEndDate().getTime(),
            articleBrandId: order.articleBrand().id,
            articleShapeId: order.articleShape().id,
            tonnage: order.tonnage(),
            comment: order.comment(),
        };
        return this.run('Customer_makeOrder', params)
            .catch((error) => {
                var errorDescription;
                if (error == 'NoSupplierForArticle') {
                    errorDescription = 'NoSupplierForArticle';
                } else {
                    errorDescription = 'Undefined'
                }
                return Promise.reject(errorDescription);
            })
    }

    cancelOrder(order: Order) {
        let params = {
            orderNumber: order.number()
        };
        return this.run('Customer_cancelOrder', params)
            .catch((error) => {
                var errorDescription;
                if (error == 'NoSupplierForArticle') {
                    errorDescription = 'InvalidTripStageForCancel';
                } else {
                    errorDescription = 'Undefined'
                }
                return Promise.reject(errorDescription);
            })
    }

    getStatus() {
        return this.run('Customer_getStatus')
            .then(response => {
                let status: CustomerStatus = {
                    allowed: response.allowed,
                    accountsReceivable: response.accountsReceivable === undefined ? "" : `${response.accountsReceivable}`,
                    accountsPayable: response.accountsPayable === undefined ? "" : `${response.accountsPayable}`,
                    lastPaymentDate: response.lastPaymentDate ? new Date(response.lastPaymentDate) : null,
                    stopFactor: response.stopFactor
                }
                return Promise.resolve(status);
            });
    }

}