import { PFObject } from './object';
export class PFDeletableObject extends PFObject {
    markDeleted() {
        this.set("deleted", true);
        return this.save();
    }

    isDeleted() { return super.get("deleted"); }
    static markObjectDeleted(object: PFDeletableObject) {
        return object.markDeleted();
    }

    static markObjectsDeleted(objects: PFDeletableObject[]): Promise<any> {
        const promises = [];
        objects.forEach((object) => {
            promises.push(object.markDeleted());
        });
        return Promise.all(promises);
    }

    static filterDeleted(objects: any[]) {
        return objects.filter(o => {
            let deleted = o.isDeleted();
            return deleted == undefined || deleted == false;
        });
    }
}