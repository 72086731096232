
import { PFObject } from "./base/object";

export interface ErrorData {
  className: string,
  objectId: string,
}

export interface ExportError {
  name: string | null,
  externalMessage: string | null,
  data: ErrorData | null,
}

export interface ExportAdditionalData {
  address: string | null,
  name: string | null,
  number: string | null,
}

export class ExportChangeFlag extends PFObject {
  constructor() { super("ExportChangeFlag"); }

  getChangedClassName(): string { return super.get("changedClassName"); }
  getType(): string {return super.get("type");}
  getReason(): ExportError | null {return super.get("lastError");}
  getAdditionalData(): ExportAdditionalData | null {return super.get("additionalData");}
  getDate(): Date {return super.get("updatedAt");}
}
