export const locale = {
    'ALLORDERSLIST': {
        'TITLE': 'Все заказы',
        'DATE_FROM': 'с',
        'DATE_TO': 'по',
        'RESET': 'Сбросить',
        'ERROR': {
            'DATE_TO_LOW': 'Дата конца периода меньше даты начала',
        },
        'TABLEROW': {
            'UNLOADED_TONNAGE': 'Тоннаж фактический',
            'LOAD_DATE': 'Дата загрузки',
            'UNLOAD_DATE': 'Дата разгрузки',
            'SUPPLIER': 'Поставщик',
            'DRIVER': 'Водитель',
        },
    },
    'VEHICLE.GROUP.ALL': 'Все',
};
