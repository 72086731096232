import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LocaleService } from 'app/components/locale/locale.service';
import { retransfer } from 'app/main/orders/locale/locale';

@Component({
    selector   : 'retransfer_dialog',
    templateUrl: './retransfer_dialog.component.html',
    styleUrls  : ['./retransfer_dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class RetransferDialogComponent {
  constructor (
    public localeService: LocaleService,
    public dialogRef: MatDialogRef<RetransferDialogComponent>,
  ) {
    this.localeService.loadLocale(retransfer);
  }
}
