import { VehiclePassZone } from 'app/components/data/core/models/transport/vehicle-pass';
import { PassesHelper } from 'app/components/helpers/passes.helper';
import { VehiclePassZoneItem } from '../model/zone.model';

export namespace VehiclePassZoneItemFactory {

    export function compare(o1: VehiclePassZoneItem, o2: VehiclePassZoneItem) {
        return o1.zone == o2.zone;
    }

    export function makeDefault(): VehiclePassZoneItem[] {
        return [
            makeWithZone(VehiclePassZone.MKAD),
            makeWithZone(VehiclePassZone.CK),
            makeWithZone(VehiclePassZone.TTK),
        ]
    }

    export function makeWithZone(zone: VehiclePassZone) {
        return { 
            zone: zone,
            name: PassesHelper.passTypeNameFor(zone)
        }
    }

}