import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { TableModule } from 'app/components/table/table.module';
import { MaterialModule } from 'app/main/material.module';

import { DriverCreateDialog } from './create-driver/driver-create-dialog.component';
import { UnitCreateDialog } from './create-unit/unit-create-dialog.component';

import { CreateVehicleModelModule } from 'app/main/admin/vehicles/dialogs/vehicle_model/vehicle-model-dialog.module';
import { UnitSelectComponent } from './unit-select.component';

import { AttachmentModule } from 'app/components/attachment/attachment.module';

import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { DialogHeaderModule } from 'app/components/dialogs/dialog-header/dialog-header.module';

@NgModule({
    declarations: [
        DriverCreateDialog,
        UnitSelectComponent,
        UnitCreateDialog,
    ],
    imports: [
        MaterialModule,
        TranslateModule,
        FuseSharedModule,
        TableModule,
        AttachmentModule,
        CreateVehicleModelModule,
        AgmCoreModule,
        AgmJsMarkerClustererModule,
        DialogHeaderModule,
    ],
    exports: [
        DriverCreateDialog,
        UnitSelectComponent,
        UnitCreateDialog,
    ]
})

export class UnitTransferModule {
}
