
import { TableData, TableRowData } from 'app/components/table/tabledata';

import { ServerTableDataSource } from 'app/components/table/data_sources/server-data-source';
import { Distance } from '../../../components/data/core/models/distance';
import { PFQuery } from 'app/components/data/core/models/base/query';
import { Query } from '@angular/core';

export class KilometersTableData extends TableData {

  constructor() {
    super();
    
    let columns = [
      new TableRowData({
        key: 'loadingPoint', 
        header: 'KILOMETERS.TABLEROW.LOADING_POINT', 
        cell: (distance: Distance) => distance.loadingPoint().getAddress(),
      }),
      new TableRowData({
        key: 'unloadingPoint', 
        header: 'KILOMETERS.TABLEROW.UNLOADING_POINT', 
        cell: (distance: Distance) => distance.unloadingPoint().getAddress(), 
      }),
      new TableRowData({
        key: 'distance', 
        header: 'KILOMETERS.TABLEROW.DISTANCE', 
        cell: (distance: Distance) => `${distance.distance()}`, 
      }),
    ];

    this.setColumns(columns);
    let includes = [
      'loadingPoint',
      'unloadingPoint',
    ];
    let tableDataSource = new ServerTableDataSource('Distance', includes);
    tableDataSource.queryFilter = (filter, query) => {
      let loadingAddressFilter = new PFQuery('LoadingPoint');
      loadingAddressFilter.contains('address', filter);

      let distanceQuery1 = new PFQuery('Distance');
      distanceQuery1.matchesQuery('loadingPoint', loadingAddressFilter);

      let unloadingAddressFilter = new PFQuery('UnloadingPoint');
      unloadingAddressFilter.contains('address', filter);

      let distanceQuery2 = new PFQuery('Distance');
      distanceQuery2.matchesQuery('unloadingPoint', unloadingAddressFilter);

      return PFQuery.or(distanceQuery1, distanceQuery2);
    };
    this.setProvider(tableDataSource);
  }

}
