import { DispatcherOrderWriteAccess } from 'app/components/access/dispatcher-order-write';
import { CurrentUser } from 'app/components/data/core/models/base/user';
import { TableOperationsFactory } from 'app/components/table-operations-controller/table-operations-controller';
import { OrdersDialogPresenterService } from '../../dialog/ordersdialogspresenter/orders-dialog-presenter.service';
import { OrderTableHelper } from '../../helpers/order-table-operations.helper';

export class AllOrdersTableOperationsFactory implements TableOperationsFactory {

    constructor(private dialogPresenter: OrdersDialogPresenterService) { }

    makeOperations(selected) {
        return this.makeOperationsWith(selected);
    }

    makeDefaultOperation(selected) {
        if (CurrentUser.isAdministrator() || CurrentUser.isLogistician() || CurrentUser.isManager()) {
            return OrderTableHelper.makeEditOrderOperation(selected, this.dialogPresenter);
        } else {
            return OrderTableHelper.makeShowOrderInfoOperation(selected, this.dialogPresenter);
        }
    }

    private makeOperationsWith(selected) {
        let addOperation = OrderTableHelper.makeCreateOrderOperation(this.dialogPresenter);
        let copyOperation = OrderTableHelper.makeCopyOrderOperation(selected, this.dialogPresenter);
        let infoOperation = OrderTableHelper.makeShowOrderInfoOperation(selected, this.dialogPresenter);
        let editOperation = OrderTableHelper.makeEditOrderOperation(selected, this.dialogPresenter);
        let removeOperation = OrderTableHelper.makeRemoveOrderOperation(selected, this.dialogPresenter);

        let cancelOperation = OrderTableHelper.makeCancelOrdersOperation(selected, this.dialogPresenter, CurrentUser.user().role());
        let transferOperation = OrderTableHelper.makeTransferOrdersOperation(selected, this.dialogPresenter);

        let declineOrder = OrderTableHelper.makeDeclineOrdersOperation(selected, this.dialogPresenter);

        let operations = [];
        let permissions = DispatcherOrderWriteAccess.orderPermissions();

        if (permissions.create) {
            operations.push(addOperation);
            operations.push(copyOperation);
        }
        operations.push(infoOperation);

        if (permissions.update) {
            operations.push(editOperation);
            operations.push(cancelOperation);
        }

        if (!CurrentUser.isCustomer()) {
            operations.push(transferOperation);
        }

        if (CurrentUser.isAdministrator() || CurrentUser.isManager() || CurrentUser.isLogistician()) {
            operations.push(removeOperation);
        } else if (CurrentUser.isDispatcher()) {
            operations.push(declineOrder);
        }
        return operations;
    }
}
