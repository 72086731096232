import { DialogPresenterService, DialogData } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import { SupplierPointsDialog } from 'app/main/admin/users/dialogs/supplier/loading_points/edit-points/supplier-points-dialog.component';
import { SupplierCreateDialog } from 'app/main/admin/users/dialogs/supplier/create/supplier-create-dialog.component';
import { TableOperationActionType, TableOperationsHelper } from 'app/components/helpers/table-operations.helper';
import { TableOperationsFactory } from 'app/components/table-operations-controller/table-operations-controller';
import { ActionBarOperation } from 'app/components/action-bar/action-bar.component';
import { SupplierBrandPriorityDialog } from '../dialogs/supplier/brands/supplier-brands-dialog.component';
import { CurrentUser } from 'app/components/data/core/models/base/user';

export class SuppliersTableOperationsFactory implements TableOperationsFactory {

    constructor(
        private dialogPresenter: DialogPresenterService,
        private isTransferPoint,
        private completion
    ) { }

    makeOperations(selected) {
        return this.makeOperationsWithSelected(selected);
    }

    makeDefaultOperation(selected) {
        return TableOperationsHelper.makeOperationWithType(
            TableOperationActionType.Edit,
            this.dialogPresenter,
            () => this.makeDialogData(),
            selected);
    }

    private makeOperationsWithSelected(selected) {
        let types = [
            TableOperationActionType.Add,
            TableOperationActionType.Edit,
        ];
        let operations = TableOperationsHelper.makeOperationsWithTypes(
            types,
            this.dialogPresenter,
            () => this.makeDialogData(),
            selected,
            this.completion
        );
        operations.push(
            this.makeEditLoadingPointsOperation(selected)
        );
        if (CurrentUser.isAdministrator()) {
            operations.push(
                this.makeChangeBrandOrderOperation(selected)
            );
        }
        operations.push(
            TableOperationsHelper.makeOperationWithType(
                TableOperationActionType.Remove,
                this.dialogPresenter,
                null,
                selected,
                this.completion
            )
        );
        return operations;
    }

    private makeDialogData(): DialogData {
        return {
            dialog: SupplierCreateDialog,
            panel: 'supplier-create-dialog-container',
            data: { isTransferPoint: this.isTransferPoint }
        }
    }

    private makeEditLoadingPointsOperation(selected) {
        let dialogData: DialogData = {
            dialog: SupplierPointsDialog,
            panel: 'supplier-points-dialog-container',
            data: { object: selected[0], disableClose: false }
        };
        return new ActionBarOperation({
            title: 'USERS.ROWOPERATION.LOADING_POINTS',
            icon: 'edit_location',
            action: () => { this.dialogPresenter.showDialog(dialogData) },
            disabled: TableOperationsHelper.notSingleSelection(selected)
        });
    }

    private makeChangeBrandOrderOperation(selected) {
        let dialogData: DialogData = {
            dialog: SupplierBrandPriorityDialog,
            panel: 'supplier-brands-dialog-container',
            data: { object: selected[0], disableClose: false }
        };
        return new ActionBarOperation({
            title: 'USERS.ROWOPERATION.BRANDS_LIST',
            icon: 'reorder',
            action: () => { this.dialogPresenter.showDialog(dialogData) },
            disabled: TableOperationsHelper.notSingleSelection(selected)
        });
    }
}
