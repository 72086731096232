
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { MaterialModule } from 'app/main/material.module';

import { TableHeaderModule} from 'app/components/tableheader/tableheader.module'
import { TableModule } from 'app/components/table/table.module';

import { KilometersComponent } from './kilometers.component';

import { KilometersDialogsModule } from './dialog/kilometers.dialog.module';
import { ActionBarModule } from 'app/components/action-bar/action-bar.module';

@NgModule({
  declarations: [
    KilometersComponent,
  ],
  imports     : [
    TranslateModule,
    FuseSharedModule,

    MaterialModule,
    TableHeaderModule,
    TableModule,

    ActionBarModule,
    KilometersDialogsModule,
  ],
  exports     : [
    KilometersComponent,
  ],
})
export class KilometersModule {
}
