export const locale = {
  'ROWOPERATION': {
    'INFO': 'Информация',
    'TRANSFER': 'Передать',
    'CANCEL': 'Отменить',
    'DECLINE': 'Отказаться от заказа',
    'FINISH': 'Завершить',
    'WARNING': 'Внимание',
    'ERROR': {
      'NOT_OWNED_REMOVE': "Вы не можете удалить заказ, который не создавали.",
      'TRANSFERRED': 'Заказ уже передан. Чтобы изменить заказ отмените передачу.',
      'NOT_OWNED_TRANSFER': "Нельзя изменить переданный вам заказ. Можно изменять только собственные заказы.",
      'OWNED_DECLINE': "Нельзя отказаться от заказа, который вы создали.",
      'CANCEL_ORDER': 'Вы можете отменить только заказы, которые еще не были загружены',
      'CANCEL_CUSTOMER_ORDER': 'Заказ принят в работу и может быть отменен менеджером. Свяжитесь с менеджером для отмены заказа.',
    },
  }
};
