export const locale = {
  'MESSAGES': {
    'TABLEROW': {
      'TITLE': 'Title',
      'BODY': 'Message',
      'TARGET': 'Target',
      'DATE': 'Date',
    },
    'TITLE': 'Messages',
    'TARGET': {
      'ALL': 'All',
      'CUSTOMERS': 'All customers',
      'LOGISTICIANS': 'All logisticians',
      'MANAGERS': 'All managers',
      'DRIVERS': 'All drivers',
      'DISPATCHERS': 'All dispatchers',
      'SUPPLIERS': 'All suppliers',
      'UNDEFINED': 'Undefined role',
    },

  }
};
