import { Component, ViewChild } from '@angular/core';

import { QueueTableData } from './queue.tabledata';

import { TableHeaderComponent } from 'app/components/tableheader/tableheader.component';
import { TableComponent, TableOptions } from 'app/components/table/table.component';

import { TranslateService } from '@ngx-translate/core';

import { LocaleService } from 'app/components/locale/locale.service';
import { queue } from './locale/locale';
import { TableData } from 'app/components/table/tabledata';

const locales = [
  queue,
];

@Component({
  selector: 'export-queue',
  templateUrl: './queue.component.html',
  styleUrls: ['./queue.component.scss']
})

export class ExportQueueComponent {
  tableData: TableData;
  tableOptions: TableOptions;
  @ViewChild(TableComponent) private table: TableComponent;
  
  headerButtons = [];

  constructor(
    private localeService: LocaleService,
    private translate: TranslateService,
  ) {
    this.localeService.loadLocales(locales);
    this.tableData = new QueueTableData(this.translate);
    this.tableOptions = {selectable: false};

    this.headerButtons = this.makeHeaderButtons();
  }

  private makeHeaderButtons() {
    return [
      {
        'title': 'COMMON.BUTTONS.RELOAD',
        'action': () => this.reloadData()
      },
    ];
  }
  
  private reloadData() {
    this.table.reloadData();
  }
}
