import { DispatcherOrderWriteAccess } from 'app/components/access/dispatcher-order-write';
import { CurrentUser } from 'app/components/data/core/models/base/user';
import { TableOperationsFactory } from 'app/components/table-operations-controller/table-operations-controller';
import { OrdersDialogPresenterService } from '../../dialog/ordersdialogspresenter/orders-dialog-presenter.service';
import { OrderTableHelper } from '../../helpers/order-table-operations.helper';

export class OrdersFinishedTableOperationsFactory implements TableOperationsFactory {

    constructor(
        private dialogPresenter: OrdersDialogPresenterService
    ) { }

    makeOperations(selected) {
        return this.makeOperationsWith(selected);
    }

    makeDefaultOperation(selected) {
        return OrderTableHelper.makeShowOrderInfoOperation(selected, this.dialogPresenter);
    }

    private makeOperationsWith(selected) {
        let copyOperation = OrderTableHelper.makeCopyOrderOperation(selected, this.dialogPresenter);
        let infoOperation = OrderTableHelper.makeShowOrderInfoOperation(selected, this.dialogPresenter);
        let editOperation = OrderTableHelper.makeEditOrderOperation(selected, this.dialogPresenter);
        let removeOperation = OrderTableHelper.makeRemoveOrderOperation(selected, this.dialogPresenter, true);

        let permissions = DispatcherOrderWriteAccess.orderPermissions();

        let operations = [];
        if (permissions.create) {
            operations.push(copyOperation);
        }
        operations.push(infoOperation);

        if (permissions.update) {
            operations.push(editOperation);
        }
        if (CurrentUser.isAdministrator() || CurrentUser.isManager() || CurrentUser.isLogistician()) {
            operations.push(removeOperation);
        }
        return operations;
    }
}