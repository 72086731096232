
export const locale = {
  lang: "en",
  data: {
    "Username": "Username",
    "Password": "Password",
    "Title": "LOGIN TO YOUR ACCOUNT",
    "LogIn": "LOGIN",
    "UsernameError": "Username is required",
    "PasswordError": "Password is required",
    "LogInError": "Error is encountered. Please check your username and password.",
  }
};
