export const locale = {
    'DIALOG': {
        'PASS': {
            'TITLE': {
                'CREATE': 'Create pass',
                'EDIT': 'Edit pass',
            },
            'CONFIRMBUTTON': {
                'CREATE': 'Create',
                'SAVE': 'Save',
            },
            'ZONE': 'Pass type',
            'TIME_OF_ACTION': 'Time of action',
            'DATES': 'Dates',
            'NUMBER': 'Number',
        },
    },
};
