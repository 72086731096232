<div class="dialog-content-wrapper m-0">
  <dialog-header headerClass="primary" title="{{title | translate}}">
  </dialog-header>

  <div class="pt-8 px-16">
    <form [formGroup]="form">
      <div fxLayout="column" fxLayoutAlign="center stretch">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
          fxLayoutAlign.gt-xs="space-between center">
          <mat-form-field fxFlex.gt-xs="49" fxFlex>
            <mat-select placeholder="{{'DIALOG.PASS.ZONE' | translate}}"
                        formControlName="zone"
                        [compareWith]="compareZones"
                        required>
              <mat-option *ngFor='let zone of zones' [value]="zone">{{zone.name | translate}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex.gt-xs="49" fxFlex>
            <mat-select placeholder="{{'DIALOG.PASS.TIME_OF_ACTION' | translate}}"
                        formControlName="timeOfAction"
                        [compareWith]="compareTimeOfAction"
                        required>
              <mat-option *ngFor='let time of times' [value]="time">{{time.name | translate}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <date-range-selector class="date-selector-box" 
                             disableInput="true" 
                             hideReset="true"
                             [(range)]="datesRange">
        </date-range-selector>

        <mat-form-field fxFlex>
          <mat-label>{{'DIALOG.PASS.NUMBER' | translate}}</mat-label>
          <input matInput formControlName="number" required>
        </mat-form-field>

        <div class='py-8' fxLayout="row" fxLayoutAlign="end center">
          <button mat-raised-button color="accent" [disabled]="dataSaver.isProcessing()"
            (click)='checkAndSave()'>{{buttonName | translate}}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>