
export const locale = {
  "TransportUnitBreakage": {
    "Title": "Notification",
    "Message": "Driver changed status to \"Breakage\".",
    "Details": {
      "Order": "Order #{{number}}",
      "Vehicle": "Vehicle: {{number}}",
      "Trailer": "Trailer: {{number}}",
    },
  },
  "OrderProblem": {
    "Title": "Notification",
    "Message": "There is problem has occured",
    "Details": {
      "Driver": "Driver: {{name}}",
      "Problem": "Problem: {{problem}}",
      "Order": "Order #{{number}}",
      "Vehicle": "Vehicle: {{number}}",
      "Trailer": "Trailer: {{number}}",
    },
  },
};
