import { ReportBuilder } from './report-builder';

export class ReportGenerator {
    constructor(private title, private reportBuilder: ReportBuilder) { }

    generateReport(beginDate: Date, endDate: Date): Promise<any> {
        return this.reportBuilder.requestData(beginDate, endDate)
            .then((data) => {
                console.log("End collecting data for report");
                let buildedData = this.reportBuilder.build(this.title, data);
                return Promise.resolve(buildedData);
            })
            .catch((error) => {
                console.log("Failed collecting data for report. " + error);
                return Promise.reject(error);
            })
    }
}