
import { Parse } from "parse";
import { PersonWithUser } from './person';
import { UnloadingPoint } from '../points/points';

export interface CustomerPermissions {
  driverPhoneNumber: boolean
}

export class Customer extends PersonWithUser {
  constructor() { super("Customer"); }

  getITN(): string {return super.get("ITN");}
  setITN(value: string): void {super.set("ITN", value);}
  getContactName(): string {return super.get("contactName");}
  setContactName(value: string): void {super.set("contactName", value);}
  getContactPhone(): string {return super.get("contactPhone");}
  setContactPhone(value: string): void {super.set("contactPhone", value);}

  getAttachedDocuments(): Parse. File[] {return super.get("attachedDocuments");}
  attachDocument(value: Parse. File): void {
    let docs = this.getAttachedDocuments();
    if (!docs) {
      docs = [];
    }
    docs.push(value);
    super.set("attachedDocuments", docs);
  }
  removeDocument(value: Parse.File): void {
    let docs = this.getAttachedDocuments();
    if (!docs) {
      return;
    }
    let index = docs.indexOf(value);
    if (index >= 0) {
      docs.splice(index, 1);
    }
    super.set("attachedDocuments", docs);
  }

  getDefaultPriceType(): number {return super.get('priceType');}
  setDefaultPriceType(value: number) :void {super.set('priceType', value);};

  getUnloadingPoints(): UnloadingPoint[] { return super.get('unloadingPoints');}
  addUnloadingPoint(unloadingPoint: UnloadingPoint) {super.add('unloadingPoints', unloadingPoint);}
  removeUnloadingPoint(unloadingPoint: UnloadingPoint) { super.remove('unloadingPoints', unloadingPoint);}

  isInternal(): boolean {return super.get("internal");}

  fetchUnloadingPoints() {
    return super.fetchWithInclude(['unloadingPoints']);
  }

  setPermissions(permissions: CustomerPermissions) {
    super.set('permissions', permissions);
  }

  permissions(): CustomerPermissions {
    let permissions = super.get('permissions');
    if (!permissions) {
      permissions = {
        driverPhoneNumber: false
      }
    }
    return permissions;
  }

  copy(): Customer {
    let copy = new Customer();
    copy.setName(this.getName());
    copy.setITN(this.getITN());
    copy.setContactName(this.getContactName());
    copy.setContactPhone(this.getContactPhone());
    return copy;
  }
}
