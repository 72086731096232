
export const locale = {
  'DIALOG' : {
    'CHANGEPASSWORD': {
      'TITLE': 'Сменить пароль',
      'CONFIRMBUTTON': 'Сменить',
      'PASSWORD' : 'Пароль',
    },
  },
};
