<h1 matDialogTitle>{{title | translate}}</h1>

<div [innerHTML]="content"></div>

<mat-dialog-actions class="pt-16" align="end">
  <button mat-raised-button
          color="accent"
          cdkFocusInitial
          [matDialogClose]="true"
          cdkFocusInitial>{{'ButtonTitle' | translate}}
  </button>
</mat-dialog-actions>
