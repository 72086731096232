
import { Driver } from '../persons/driver';
import { Carrier } from '../persons/carrier';
import { PFObject } from "../base/object";
import { PFFile } from "../base/file";
import { PFDeletableObject } from "../base/deletableObject";
import { User } from '../base/user';
import { VehiclePass, VehiclePassFactory } from './vehicle-pass';

import { DateHelper } from 'app/components/helpers/date.helper';

export enum TransportUnitStatus {
  Undefined = -1,
  NotReady = 0,
  Ready = 1,
  Breakage = 2,
  Invisible = 3,
  Working = 4,
  Resting = 5,
  UnderRepair = 6,
}

export class TransportUnit extends PFObject {
  constructor() { super('TransportUnit'); }

  driver(): Driver { return super.get('driver'); }
  setDriver(driver: Driver) { super.set('driver', driver); }
  trailer(): Trailer { return super.get('trailer'); }
  setTrailer(trailer: Trailer) { super.set('trailer', trailer); }
  vehicle(): Vehicle { return super.get('vehicle'); }
  setVehicle(vehicle: Vehicle) { super.set('vehicle', vehicle); }
  coordinate() { return super.get('coordinate'); }
  speed() { return super.get('speed'); }
  trip() { return super.get('trip'); }
  user(): User { return super.get('user') }
  status(): number {
    let status = super.get('status');
    if (status == null) {
      status = '90';
    }
    return status;
  }

  hasApp() { return super.get('application'); }

  vehicleName(): string {
    let vehicleName = '';
    let vehicle = this.vehicle();
    if (vehicle) {
      vehicleName = vehicle.fullName();
    }
    return vehicleName;
  }

  statusStringKey() {
    let statusStr = 'UNITS.STATUS.' + this.status();
    return statusStr;
  }

  totalTonnage(): number {
    let tonnage = 0;
    let vehicle = this.vehicle();
    if (vehicle) {
      tonnage += vehicle.tonnage();
    }
    let trailer = this.trailer();
    if (trailer) {
      tonnage += trailer.tonnage();
    }
    return tonnage;
  }

  roundedSpeed(): number {
    let speed = this.speed();
    if (speed) {
      return Math.round(speed);
    }
    return 0;
  }

  lastVisitStr() {
    let date = super.get('lastVisitDate');
    return date ? date.toLocaleString() : '';
  }
}

export class Transport extends PFDeletableObject {
  number(): string { return super.get('number'); }
  setNumber(number: string) { super.set('number', number); }
  tonnage(): number { return super.get('tonnage'); }
  setTonnage(tonnage: number) { super.set('tonnage', tonnage); }
  approved(): boolean { return super.get("approved"); }
  setApproved(value: boolean): void { super.set("approved", value); }

  transportUnit(): TransportUnit { return super.get('transportUnit') }

  protected admissionData() { return super.get('admissionData'); }
  protected setAdmissionData(data) { super.set('admissionData', data) }

  inspectionDate(): Date {
    let data = this.admissionData();
    if (!data) return null;
    let dateStr = data.inspectionEndDate;
    if (!dateStr) return null;
    let date = new Date(dateStr)
    return date;
  }
  setInspectionDate(date: Date) {
    let data = this.admissionData();
    if (!data) {
      data = {};
    }
    let dateStr = date ? DateHelper.dateToFormattedString(date, 'YYYY-MM-DD') : null;
    data.inspectionEndDate = dateStr;
    this.setAdmissionData(data);
  }

  carrier(): Carrier { return super.get('carrier'); }
  setCarrier(value: Carrier): void { return super.set('carrier', value); }

  getAttachedDocuments(): PFFile[] {return super.get("attachedDocuments");}
  attachDocument(value: PFFile): void {
    let docs = this.getAttachedDocuments();
    if (!docs) {
      docs = [];
    }
    docs.push(value);
    super.set("attachedDocuments", docs);
  }

  removeDocument(value: PFFile): void {
    let docs = this.getAttachedDocuments();
    if (!docs) {
      return;
    }
    let index = docs.indexOf(value);
    if (index >= 0) {
      docs.splice(index, 1);
    }
    super.set("attachedDocuments", docs);
  }
}

export class Vehicle extends Transport {
  constructor() { super('Vehicle'); }

  model(): VehicleModel { return super.get('model'); }
  setModel(model: VehicleModel) { super.set('model', model); }

  insuranceDate(): Date {
    let data = this.admissionData();
    if (!data) return null;
    let dateStr = data.mtplEndDate;
    if (!dateStr) return null;
    let date = new Date(dateStr)
    return date;
  }

  setInsuranceDate(date: Date) {
    let data = this.admissionData();
    if (!data) {
      data = {};
    }
    let dateStr = date ? DateHelper.dateToFormattedString(date, 'YYYY-MM-DD') : null;
    data.mtplEndDate = dateStr;
    this.setAdmissionData(data);
  }

  addPass(pass: VehiclePass) {
    let passes = super.get('passes') ?? [];
    let jsonPass = VehiclePassFactory.toJSON(pass);
    passes.push(jsonPass);
    super.set('passes', passes);
  }

  removePass(pass: VehiclePass) {
    let passes = super.get('passes') ?? [];
    let index = passes.findIndex(obj => {
      return obj.number == pass.number && 
      obj.zone == pass.zone && 
      obj.timeOfAction == pass.timeOfAction
    });
    if (index >=0) {
      passes.splice(index, 1)
    }
    super.set('passes', passes);
  }

  passes(): VehiclePass[] { 
    return VehiclePassFactory.fromJSONArray(super.get('passes'));
  }
  
  equipment() { return super.get('equipment'); }

  fullName(): string {
    let name = 'NoTransportName';
    let model = this.model();
    if (model) {
      name = model.fullName();
    }
    return name;
  }

  copy() {
    let copy = new Vehicle();
    copy.setModel(this.model());
    copy.setNumber(this.number());
    copy.setTonnage(this.tonnage());
    return copy;
  }
}

export class VehicleModel extends PFDeletableObject {
  constructor() { super('VehicleModel'); }

  brand(): VehicleBrand { return super.get('brand'); }
  setBrand(brand: VehicleBrand) { super.set('brand', brand); }
  name(): string { return super.get('name'); }
  setName(name: string) { super.set('name', name); }

  fullName(): string {
    let brand = this.brand();
    var brandName = 'NoBrand';
    if (brand) {
      brandName = brand.name();
    }
    return brandName + ' ' + this.name();
  }

  copy() {
    let copy = new VehicleModel();
    copy.setName(this.name());
    copy.setBrand(this.brand());
    return copy;
  }
}

export class VehicleBrand extends PFDeletableObject {
  constructor() { super('VehicleBrand'); }

  name(): string { return super.get('name'); }
  setName(name: string) { super.set('name', name); }

  copy() {
    let copy = new VehicleBrand();
    copy.setName(this.name());
    return copy;
  }
}

export class Trailer extends Transport {
  constructor() { super('Trailer'); }

  copy() {
    let copy = new Trailer();
    copy.setNumber(this.number());
    copy.setTonnage(this.tonnage());
    return copy;
  }
}
