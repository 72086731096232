
import { FuseNavigation } from '@fuse/types';

import * as auto from './auto';
import * as orders from './orders';
import * as reports from './reports';
import * as admin from './admin';
import * as proposals from './proposals';

const main: FuseNavigation = {
  id       : 'main',
  title    : 'Main',
  translate: 'NAV.MAIN.TITLE',
  type     : 'item',
  icon     : 'home',
  url      : '/main',
};

const customerInfo: FuseNavigation = {
  id       : 'customer-info',
  title    : 'Info',
  translate: 'NAV.CUSTOMER_INFO.TITLE',
  type     : 'item',
  icon     : 'info',
  url      : 'customer-info',
};

function autoGroup(children: FuseNavigation[]): FuseNavigation {
  return {
    id       : 'auto',
    title    : 'Auto',
    translate: 'NAV.AUTO.TITLE',
    type     : 'group',
    children : children
  };
}

function proposalsGroup(children: FuseNavigation[]): FuseNavigation {
  return {
    id       : 'proposals',
    title    : 'Proposals',
    translate: 'NAV.PROPOSALS.TITLE',
    type     : 'group',
    children : children
  };
}

function ordersGroup(children: FuseNavigation[]): FuseNavigation {
  return {
    id       : 'orders',
    title    : 'Orders',
    translate: 'NAV.ORDERS.TITLE',
    type     : 'group',
    children : children
  };
}

function reportsGroup(children: FuseNavigation[]): FuseNavigation {
  return {
    id       : 'reports',
    title    : 'Reports',
    translate: 'NAV.REPORTS.TITLE',
    type     : 'group',
    children : children
  };
}

function adminGroup(children: FuseNavigation[]): FuseNavigation {
  return {
    id       : 'admin',
    title    : 'Administration',
    translate: 'NAV.ADMIN.TITLE',
    type     : 'group',
    children : children
  };
}

export const customer: FuseNavigation[] = [
  customerInfo,
  ordersGroup([
    orders.all,
    orders.list,
    orders.progress,
    orders.finished,
  ]),
  reportsGroup([
    reports.create
  ]),
];

export const dispatcher: FuseNavigation[] = [
  main,
  autoGroup([
    auto.map,
    auto.list
  ]),
  proposalsGroup([
    proposals.carrierProposals,
  ]),
  ordersGroup([
    orders.all,
    orders.list,
    orders.progress,
    orders.finished
  ]),
  reportsGroup([
    reports.create
  ]),
  adminGroup([
    admin.users,
    admin.vehicles
  ])
];

export const manager: FuseNavigation[] = [
  main,
  autoGroup([
    auto.map,
    auto.list
  ]),
  proposalsGroup([
    proposals.proposalsFromCustomers,
    proposals.proposalsList,
    proposals.proposalsBySupplier,
    proposals.proposalsByCarrier,
  ]),
  ordersGroup([
    orders.all,
    orders.list,
    orders.progress,
    orders.finished
  ]),
  adminGroup([
    admin.users,
    admin.vehicles,
    admin.articles,
  ])
];

export const administrator: FuseNavigation[] = [
  main,
  autoGroup([
    auto.map,
    auto.list
  ]),
  proposalsGroup([
    proposals.proposalsFromCustomers,
    proposals.proposalsList,
    proposals.proposalsBySupplier,
    proposals.proposalsByCarrier,
  ]),
  ordersGroup([
    orders.all,
    orders.list,
    orders.progress,
    orders.finished
  ]),
  reportsGroup([
    reports.create
  ]),
  adminGroup([
    admin.users,
    admin.vehicles,
    admin.articles,
    admin.kilometers,
    admin.exportQueue,
    admin.messagesList,
    admin.settings,
  ])
];
