import {locale as proposals_en} from "./en/locale.proposals";
import {locale as proposals_ru} from "./ru/locale.proposals";

export const proposals = [
  {
    lang: 'en',
    data: proposals_en
  },
  {
    lang: 'ru',
    data: proposals_ru
  },
];

