import { TableData } from 'app/components/table/tabledata';
import { ActionBarOperation } from 'app/components/action-bar/action-bar.component';
import { TableComponent, ClickData } from 'app/components/table/table.component';
import { TableOperationsFactory, TableOperationsController } from 'app/components/table-operations-controller/table-operations-controller';

interface TabTableInfo {
    title: string
    tableData: TableData,
    operationsFactory: TableOperationsFactory,
}

export class TabInfo {
    get title(): string {
        return this.info.title;
    };
    get tableData(): TableData {
        return this.info.tableData;
    };
    get tableOperations(): ActionBarOperation[] {
        return this.tableOperationsController.operations;
    };

    private tableOperationsController: TableOperationsController;

    constructor(private info: TabTableInfo) {
        this.tableOperationsController = new TableOperationsController(this.info.operationsFactory);
    }

    doubleClickHandler(value: ClickData) {
        this.tableOperationsController.doubleClickHandler(value);
    };

    updateOperations(table: TableComponent) {
        this.tableOperationsController.updateOperations(table);
    };
}