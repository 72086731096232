<div class="dialog-content-wrapper info-content">
  <dialog-header [headerClass]="order.isDeleted() ? 'gray' : 'primary'" 
                 title="{{'ORDERINFO.TITLE' | translate}} {{order.number()}} {{order.isDeleted() ? ('COMMON.DELETED' | translate) : ''}}">
  </dialog-header>
  <mat-tab-group fxFill>
    <mat-tab label="{{'ORDERINFO.MAIN' | translate}}">
      <info-form [order]='order'></info-form>
    </mat-tab>
    <mat-tab label="{{'ORDERINFO.PROGRESS' | translate}}">
        <div class="table-wrapper mat-elevation-z8">
          <table *ngIf="hasOffers()" class='progress-table' mat-table  [dataSource]="dataSource" multiTemplateDataRows>
            <ng-container *ngFor="let column of columns" [matColumnDef]="column.key" >
                <mat-header-cell *matHeaderCellDef [fxFlex]="column.flex()">{{column.header | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let row" [fxFlex]="column.flex()">{{column.cell(row)}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let offer" [attr.colspan]="displayedColumns.length" fxFlex>
                <div class="progress-row-detail"
                     [@detailExpand]="offer == expandedElement ? 'expanded' : 'collapsed'">
                   <progress-info [offer]="offer" fxFlex></progress-info>
                 </div>
               </td>
             </ng-container>

             <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
             <mat-row *matRowDef="let row; columns: displayedColumns;"
                      class="offer-row"
                      [class.progress-expanded-row]="expandedElement === row"
                      (click)="clickOnRow(row)">
             </mat-row>
             <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="progress-detail-row"></tr>
          </table>
          <div  *ngIf="!hasOffers()" class="placeholder" fxLayout="colemn" fxLayoutAlign="center center">
            <mat-label class="font-size-20">{{'ORDERINFO.NO_OFFERS' | translate}}</mat-label>
          </div>
        </div>
    </mat-tab>
  </mat-tab-group>
</div>
