import {locale as messages_en} from "./en/locale.messages";
import {locale as messages_ru} from "./ru/locale.messages";

import {locale as create_message_en} from "./en/locale.create_message";
import {locale as create_message_ru} from "./ru/locale.create_message";

export const messages = [
  {
    lang: "en",
    data: messages_en
  },
  {
    lang: "ru",
    data: messages_ru
  }
];

export const create_message = [
  {
    lang: "en",
    data: create_message_en
  },
  {
    lang: "ru",
    data: create_message_ru
  }
];
