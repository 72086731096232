import Parse from 'parse';
import { TableDataSource } from '../table.component';
import { Observable, BehaviorSubject } from 'rxjs';
import { Sort } from '@angular/material/sort';
import { PFObject } from 'app/components/data/core/models/base/object';

export class LocalDataSource implements TableDataSource {
    data = new BehaviorSubject<Parse.Object[]>([]);
    dataCustomers = new BehaviorSubject<Parse.Object[]>([]);
    dataAuthors = new BehaviorSubject<Parse.Object[]>([]);
    dataSuppliers = new BehaviorSubject<Parse.Object[]>([]);

    private localData: Parse.Object[]; 
    private filteredData: Parse.Object[];

    private filter: string;

    private defaultSort: Sort = null;
    private sort: Sort = null;

    constructor(data: Parse.Object[]) {
        this.localData = data;
        this.filteredData = data;
    }

    connect(): Observable<Parse.Object[]> {
        return this.data.asObservable();
    }
    disconnect(): void {
        this.data.complete();
    }
    hasData(): boolean {
        let value = this.getData();
        return value && value.length > 0;
    }

    totalDataCount(): number {
        if (this.hasData()) {
            return this.filteredData.length;
        }
        return 0;
    }

    loadAllCustomers(): void {}
    loadUniqueAuthors(): void {}
    loadAllSuppliers(): void {}

    loadData(offset: number, count: number) {
        let data = [];
        if (count > 0) {
            if (offset < this.filteredData.length) {
                data = this.filteredData.slice(offset, offset + count);
            }
        } else {
            data = this.filteredData;
        }

        let currentSort;
        if (this.sort && this.sort.active !== undefined && this.sort.direction != '') {
            currentSort = this.sort;
        } else if (this.defaultSort) {
            currentSort = this.defaultSort;
        }
        
        if (data) {
            if (currentSort) {
                data = this.sortData(data, currentSort);
            }
        } 
        this.data.next(data);
    }

    getData() {
        return this.data.getValue();
    }

    refresh() {
        this.data.next(this.getData());
    }

    setSearchFilter(value: string) {
        this.filter = value;
        this.filterData();
    }

    setOtherFilters(filters){}

    setNodeTreeFilters(filters){}

    isLoading() : boolean {
        return false;
    }

    getLastLoadError(): string {
        return null;
    }

    setDefaultSort(sort: Sort) {
        this.defaultSort = sort;
    }

    setSort(sort: Sort) {
        this.sort = sort;
    }

    // Private

    private filterData() {
        this.filteredData = this.localData;
    }

    private sortData(data: any[], sort: Sort) {
        let directionModifier;
        switch (sort.direction) {
            case 'asc': directionModifier = 1; break;
            case 'desc': directionModifier = -1; break;
            default: directionModifier = 0; break;
        }
        
        return data.sort((o1, o2) => {
            let val1 = PFObject.valueAtPath(o1, sort.active);
            let val2 = PFObject.valueAtPath(o2, sort.active);
            var res = 0;
            if (val1 == val2) {
                res = 0;
            } else if (val1 && val2) {
                if (val1 < val2) {
                    res = -1;
                }
                if (val2 < val1) {
                    res = 1;
                }
            } else  {
                if (!val1) {
                    res = -1;
                } else {
                    res = 1;
                }
            }
            return res * directionModifier;
        })
    }
}