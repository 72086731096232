
export const locale = {
  'DIALOG': {
    'UNIT': {
      'CREATE': {
        'TITLE': 'Создать экипаж',
        'CONFIRMBUTTON': 'Создать',
      },
      'CARRIER_SELECT': 'Выберите перевозчика',
      'DRIVER_SELECT': 'Выберите водителя',
      'VEHICLE_SELECT': 'Выберите транспорт',
      'TRAILER_SELECT': 'Выберите прицеп',

      'ERROR': {
        'NO_CARRIER': 'Перевозчик не выбран',
        'NO_DRIVER': 'Водитель не выбран',
        'NO_VEHICLE': 'Транспорт не выбран',
        'NO_TRAILER': 'Прицеп не выбран',
      },
    }
  },
};
