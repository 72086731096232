import { TableData, TableRowData, TableSort } from 'app/components/table/tabledata';

import { Intermediary } from 'app/components/data/core/models/persons/intermediary';
import { ServerTableDataSource } from 'app/components/table/data_sources/server-data-source';
import { PFQuery } from 'app/components/data/core/models/base/query';
import { filterDeleted } from 'app/components/access/query';

export class IntermediaryTableData extends TableData {

  constructor() {
    super();

    let columns = [
      new TableRowData({
        key: 'name',
        header: 'USERS.TABLEROW.NAME',
        cell: (intermediary: Intermediary) => `${intermediary.getName()}`
      }),
    ];
    this.setColumns(columns);

    let sort: TableSort = new TableSort('name','asc');
    let tableDataSource = new ServerTableDataSource('Intermediary');
    tableDataSource.setDefaultSort(sort);
    tableDataSource.queryModify = function (query: PFQuery): PFQuery {
      return filterDeleted(query);
    };
    tableDataSource.queryFilter = (filter, query) => {
      query.contains("name", filter);
      return query;
    };
    this.setProvider(tableDataSource);
  }
}
