import { NgModule } from '@angular/core';

import { ProgressInfoComponent } from './progress-info.component'

import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/material.module';
import { DownloadModule } from 'app/components/download/download.module';
import { ShowOnMapModule } from 'app/main/auto/dialog/showonmap/show-on-map-dialog.module';
import { TrackDownloadDialog } from 'app/components/track-download/track-download.dialog';

import { EditFieldDialog } from './dialog/edit-field/edit-field-dialog.component';
import { DialogHeaderModule } from 'app/components/dialogs/dialog-header/dialog-header.module';

@NgModule({
    declarations: [
        ProgressInfoComponent,
        EditFieldDialog,
    ],
    providers: [
        TrackDownloadDialog
    ],
    imports: [
        TranslateModule,
        FuseSharedModule,
        MaterialModule,
        DownloadModule,
        ShowOnMapModule,
        DialogHeaderModule,
    ],
    exports: [
        EditFieldDialog,
        ProgressInfoComponent
    ]
})

export class ProgressInfoModule {}
