import {Component, ViewEncapsulation, Inject, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatStepper} from '@angular/material/stepper';

import {CurrentUser} from 'app/components/data/core/models/base/user';
import {Order, OrderStatus} from 'app/components/data/core/models/order/order';

import {CustomerStepComponent, FieldChangeHandler} from './steps/customer-step/customer-step.component';
import {SupplierStepComponent} from './steps/supplier-step/supplier-step.component';

import {LocaleService} from 'app/components/locale/locale.service';
import {order_dialog, order_fields} from 'app/main/orders/locale/locale';
import {customer} from 'app/main/admin/users/locale/locale';
import {TranslateService} from '@ngx-translate/core';
import {DispatcherCloudExecutor} from 'app/components/data/cloud-executors/dispatcher-cloud-executor';
import {LocalNotificationService} from 'app/components/local-notifications-service/local-notifications-service';

let locales = [
    order_dialog,
    order_fields,
    customer
];

@Component({
    selector: 'order-dialog',
    templateUrl: './order-dialog.component.html',
    styleUrls: ['./order-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class OrderDialogComponent implements FieldChangeHandler {
    private resultOrder: Order;
    public order: Order;
    public title: string;
    public buttonName: string;
    public executor = new DispatcherCloudExecutor;

    @ViewChild('stepper') stepper: MatStepper;
    @ViewChild(CustomerStepComponent) customerStepComponent: CustomerStepComponent;
    @ViewChild(SupplierStepComponent) supplierStepComponent: SupplierStepComponent;

    constructor(
        private translate: TranslateService,
        private localeService: LocaleService,
        private dialogRef: MatDialogRef<OrderDialogComponent>,
        private notifications: LocalNotificationService,
        @Inject(MAT_DIALOG_DATA) private _data: any
    ) {
        this.localeService.loadLocales(locales);
        this.resultOrder = this._data.object;

        if (this._data.action === 'create') {
            this.title = 'ORDER.DIALOG.TITLE.CREATE';
            this.buttonName = 'ORDER.DIALOG.CONFIRMBUTTON.CREATE';
        } else if (this._data.action === 'edit') {
            this.title = 'ORDER.DIALOG.TITLE.EDIT';
            this.buttonName = 'ORDER.DIALOG.CONFIRMBUTTON.SAVE';
        }

        if (this.resultOrder == null) {
            this.resultOrder = new Order();
        }
        this.order = this.resultOrder.copy();
    }

    ngAfterViewInit() {
        this.customerStepComponent.fieldChangeHandler = this;
    }

    isEditing() {
        return this._data.action === 'edit' || (this._data.object != undefined || this._data.object != null);
    }

    next() {
        if (this.customerStepCompleted()) {
            this.order.setIntermediary(this.customerStepComponent.getIntermediary());
            this.order.setCustomer(this.customerStepComponent.getCustomer());
            this.order.setUnloadingPoint(this.customerStepComponent.getUnloadingPoint());
            this.order.setUnloadingContact(this.customerStepComponent.getUnloadingContact());
            this.order.setUnloadingEntrance(this.customerStepComponent.getUnloadingEntrance());
            this.order.setUnloadingBeginDate(this.customerStepComponent.getUnloadingStartDate());
            this.order.setUnloadingEndDate(this.customerStepComponent.getUnloadingEndDate());
            this.order.setAgreeStatus(this.customerStepComponent.agreeStatus);
            this.stepper.next();
        }
    }

    finish() {
        if (this.supplierStepCompleted()) {
            this.order.setType(this.supplierStepComponent.orderType);
            this.order.setSupplier(this.supplierStepComponent.supplier);
            this.order.setLoadingPoint(this.supplierStepComponent.loadingPoint);
            this.order.setLoadingEntrance(this.supplierStepComponent.entrance);
            this.order.setLoadingDate(this.supplierStepComponent.loadingDate);

            this.order.setArticleBrand(this.supplierStepComponent.brand);
            this.order.setTonnage(this.supplierStepComponent.tonnage);
            this.order.setSalePriceType(this.supplierStepComponent.salePriceType);
            this.order.setDeliveryPriceType(this.supplierStepComponent.deliveryPriceType);
            this.order.setSaleTariff(this.supplierStepComponent.saleTariff);
            this.order.setDeliveryTariff(this.supplierStepComponent.deliveryTariff);
            this.order.setInactivityTimeInterval(this.supplierStepComponent.timeout);
            this.order.setComment(this.supplierStepComponent.comment);
            this.order.setDistance(this.supplierStepComponent.distance);

            this.checkAndSave();
        }
    }

    fieldChanged(fieldName: String, value: any) {
        if (fieldName === "customer" && value) {
            this.supplierStepComponent.updateSalePriceForCustomer(value);
        }
    }

    customerAdmited() {
        return !(
            this.customerStepComponent &&
            this.customerStepComponent.customerCheckResult &&
            this.customerStepComponent.customerCheckResult.block
        );
    }

    stepChanged(selectedIndex) {
        if (selectedIndex == 0) {
            this.customerStepComponent.setInitialFocus();
        } else {
            this.supplierStepComponent.setInitialFocus();
        }
    }

    private customerStepCompleted() {
        return this.customerStepComponent.checkForm();
    }

    private supplierStepCompleted() {
        return this.supplierStepComponent.checkForm();
    }

    private checkAndSave() {
        this.resultOrder.setIntermediary(this.order.intermediary());
        this.resultOrder.setCustomer(this.order.customer());
        this.resultOrder.setUnloadingPoint(this.order.unloadingPoint());
        this.resultOrder.setUnloadingEntrance(this.order.unloadingEntrance());
        this.resultOrder.setUnloadingContact(this.order.unloadingContact());
        this.resultOrder.setUnloadingBeginDate(this.order.unloadingBeginDate());
        this.resultOrder.setUnloadingEndDate(this.order.unloadingEndDate());

        this.resultOrder.setType(this.order.getType());
        this.resultOrder.setSupplier(this.order.supplier());
        this.resultOrder.setLoadingPoint(this.order.loadingPoint());
        this.resultOrder.setLoadingEntrance(this.order.loadingEntrance());
        this.resultOrder.setLoadingDate(this.order.loadingDate());

        this.resultOrder.setArticleBrand(this.order.articleBrand());
        this.resultOrder.setTonnage(this.order.tonnage());
        this.resultOrder.setSalePriceType(this.order.salePriceType());
        this.resultOrder.setDeliveryPriceType(this.order.deliveryPriceType());
        this.resultOrder.setSaleTariff(this.order.saleTariff());
        this.resultOrder.setDeliveryTariff(this.order.deliveryTariff());
        this.resultOrder.setInactivityTimeInterval(this.order.inactivityTimeInterval());
        this.resultOrder.setComment(this.order.comment());
        this.resultOrder.setDistance(this.order.distance());

        this.resultOrder.setAgreeStatus(this.order.getAgreeStatus());

        if (this._data.action === 'create') {
            if (CurrentUser.isDispatcher()) {
                this.resultOrder.addCarrier(CurrentUser.carrier());
            }
        }
        this.executor.saveObject(this.resultOrder, {context: {distributeTonnage: true}})
            .then((obj: Order) => {
                if (obj.status() != OrderStatus.READY) {
                    this.executor.setOrderStatus(obj, OrderStatus.READY)
                        .then(() => {
                            this.dialogRef.close(true);
                        });
                } else {
                    this.dialogRef.close(true);
                }
            })
            .catch(() => {
                let localizedMessage = this.translate.instant('ORDER.DIALOG.ERROR.SAVE_ERROR');
                this.notifications.showErrorNotification(localizedMessage);
            });
    }

}
