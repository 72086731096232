<div class="info-container pt-8 px-16 pb-20">
  <form [formGroup]="form">
    <div
    fxLayout="column"
    fxLayoutAlign="center stretch"
    fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="space-between center">
       <mat-form-field class='no-padding info-input' fxFlex="49" appearance="outline">
         <mat-label>{{'ORDER.FIELD_NAME.INTERMEDIARY' | translate}}</mat-label>
         <input matInput formControlName="intermediary">
       </mat-form-field>

       <mat-form-field class='no-padding info-input' fxFlex="49" appearance="outline">
        <mat-label>{{'ORDER.FIELD_NAME.TYPE' | translate}}</mat-label>
        <input matInput formControlName="orderType">
      </mat-form-field>
    </div>

    <div
    fxLayout="column"
    fxLayoutAlign="center stretch"
    fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="space-between center">
       <mat-form-field class='no-padding info-input' fxFlex="49" appearance="outline">
         <mat-label>{{'ORDER.FIELD_NAME.SUPPLIER' | translate}}</mat-label>
         <input matInput formControlName="supplier">
       </mat-form-field>

       <mat-form-field class='no-padding info-input' fxFlex="49" appearance="outline">
        <mat-label>{{'ORDER.FIELD_NAME.CUSTOMER' | translate}}</mat-label>
        <input class="info-input" matInput formControlName="customer">
      </mat-form-field>
    </div>

    <div
    fxLayout="column"
    fxLayoutAlign="center stretch"
    fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="space-between center">
       <mat-form-field class='no-padding' 
       [class.info-input]="!isDifferentLoadingPoint()" 
       [class.warn-info-input]="isDifferentLoadingPoint()" 
       fxFlex="49" 
       appearance="outline">
         <mat-label>{{'ORDER.FIELD_NAME.LOADING_ENTRANCE' | translate}}</mat-label>
         <textarea
             matInput
             class="address-area"
             cdkTextareaAutosize
             cdkAutosizeMinRows="1"
             cdkAutosizeMaxRows="2"
             formControlName="loadingEntrance">
          </textarea>
       </mat-form-field>

       <mat-form-field class='no-padding' 
       [class.info-input]="!isDifferentUnloadingPoint()"
       [class.warn-info-input]="isDifferentUnloadingPoint()" 
       fxFlex="49"
       appearance="outline">
         <mat-label>{{'ORDER.FIELD_NAME.UNLOADING_ENTRANCE' | translate}}</mat-label>
         <textarea
             class="address-area"
             matInput
             cdkTextareaAutosize
             cdkAutosizeMinRows="1"
             cdkAutosizeMaxRows="2"
             formControlName="unloadingEntrance">
          </textarea>
       </mat-form-field>
    </div>

    <div
    fxLayout="column"
    fxLayoutAlign="center stretch"
    fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="space-between center">
       <mat-form-field class='no-padding info-input' fxFlex="49" appearance="outline">
         <mat-label>{{'ORDER.FIELD_NAME.LOADING_DATE' | translate}}</mat-label>
         <input matInput formControlName="loadingDate">
       </mat-form-field>

       <mat-form-field class='no-padding info-input' fxFlex="49" appearance="outline">
         <mat-label>{{'ORDER.FIELD_NAME.UNLOADING_DATE' | translate}}</mat-label>
         <input matInput formControlName="unloadingDate">
       </mat-form-field>
    </div>

    <div
    fxLayout="column"
    fxLayoutAlign="center stretch"
    fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="space-between center">
        <mat-form-field class='no-padding info-input' fxFlex="49" appearance="outline">
          <mat-label>{{'ORDER.FIELD_NAME.ARTICLE_TYPE' | translate}}</mat-label>
          <input matInput formControlName="articleType">
        </mat-form-field>

        <mat-form-field class='no-padding info-input' fxFlex="49" appearance="outline">
          <mat-label>{{'ORDER.FIELD_NAME.ARTICLE_BRAND' | translate}}</mat-label>
          <input matInput formControlName="articleBrand">
        </mat-form-field>
    </div>

    <div
    fxLayout="column"
    fxLayoutAlign="center stretch"
    fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="space-between center">
      <div 
      fxLayout="column"
      fxLayoutAlign="center stretch"
      fxLayout.gt-xs="row"
      fxLayoutAlign.gt-xs="space-between center"
      fxFlex="32">
        <mat-form-field class='no-padding info-input' appearance="outline" fxFlex="49">
          <mat-label>{{'ORDER.FIELD_NAME.TONNAGE' | translate}}</mat-label>
          <input matInput formControlName="tonnage">
        </mat-form-field>

        <mat-form-field class='no-padding info-input' appearance="outline" fxFlex="49">
          <mat-label>{{'ORDER.FIELD_NAME.TIMEOUT' | translate}}</mat-label>
          <input matInput type="number" formControlName="timeout">
        </mat-form-field>
      </div>
      <div 
      fxLayout="column"
      fxLayoutAlign="center stretch"
      fxLayout.gt-xs="row"
      fxLayoutAlign.gt-xs="space-between center"
      fxFlex="66">
        <mat-form-field class="no-padding info-input" appearance="outline" fxFlex="64">
          <mat-label>{{'ORDER.FIELD_NAME.CONTACT_NAME' | translate}}</mat-label>
          <input matInput formControlName="contactName">
        </mat-form-field>
      
        <mat-form-field class="no-padding info-input" appearance="outline" fxFlex="34">
          <mat-label>{{'ORDER.FIELD_NAME.CONTACT_PHONE' | translate}}</mat-label>
          <input matInput formControlName="contactPhone">
        </mat-form-field>
      </div>
    </div>

    <div 
      fxLayout="column"
      fxLayoutAlign="center stretch"
      fxLayout.gt-xs="row"
      fxLayoutAlign.gt-xs="space-between center" 
      style="width: 100%;">
        <mat-form-field *ngIf="canShowSalePrice()" class='no-padding info-input' fxFlex="24" appearance="outline">
          <mat-label>{{'ORDER.FIELD_NAME.SALE_PRICE_TYPE' | translate}}</mat-label>
          <input matInput formControlName="salePriceType">
        </mat-form-field>
  
        <mat-form-field *ngIf="canShowSalePrice()" class='no-padding info-input' fxFlex="24" appearance="outline">
          <mat-label>{{'ORDER.FIELD_NAME.SALE_PRICE' | translate}}</mat-label>
          <input matInput formControlName="saleTariff">
        </mat-form-field>
      
        <mat-form-field class='no-padding info-input' [fxFlex]="canShowSalePrice() ? '24' : '49'" appearance="outline">
          <mat-label>{{'ORDER.FIELD_NAME.DELIVERY_PRICE_TYPE' | translate}}</mat-label>
          <input matInput formControlName="deliveryPriceType">
        </mat-form-field>
  
        <mat-form-field class='no-padding info-input' [fxFlex]="canShowSalePrice() ? '24' : '49'" appearance="outline">
          <mat-label>{{'ORDER.FIELD_NAME.DELIVERY_PRICE' | translate}}</mat-label>
          <input matInput formControlName="deliveryTariff">
        </mat-form-field>
    </div>


    <div class="comment" *ngIf="order.comment()">
      <mat-form-field fxFlex>
        <textarea matInput
                  formControlName="comment"
                  placeholder="{{'ORDER.FIELD_NAME.COMMENT' | translate}}"></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
