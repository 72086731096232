export const locale = {
  'REPORTS': {
    'TITLE': 'Создать отчет',
    'BEGINDATE': 'Начальная дата',
    'ENDDATE': 'Конечная дата',
    'CREATE': 'Создать',
    'ERROR': {
      'FAILED': 'Не удалось создать отчет',
    },
  },
};
