import {Parse} from 'parse';

import {Manager} from '../persons/manager';
import {Supplier} from '../persons/supplier';
import {Customer} from '../persons/customer';
import {Carrier} from '../persons/carrier';
import {Driver} from '../persons/driver';
import {Dispatcher} from '../persons/dispatcher';
import {Logistician} from '../persons/logistician';

export enum Role {
    Administrator = "Administrator",
    Logistician = "Logistician",
    Manager = "Manager",
    Dispatcher = "Dispatcher",
    Customer = "Customer",
    Driver = "Driver",
    Supplier = "Supplier",
}

let validRoles = [
    Role.Administrator,
    Role.Logistician,
    Role.Manager,
    Role.Dispatcher,
    Role.Customer,
    Role.Supplier,
]

export interface UserSettings {
    notifications: boolean,
    displayDeleted: boolean,
}

export class User extends Parse.User {
    constructor() {
        super('_User');
    }

    get id() {
        return super._getId()
    }

    manager(): Manager {
        return super.get("manager");
    }

    supplier(): Supplier {
        return super.get("supplier");
    }

    customer(): Customer {
        return super.get("customer");
    }

    carrier(): Carrier {
        return super.get("carrier");
    }

    dispatcher(): Dispatcher {
        return super.get("dispatcher");
    }

    logistician(): Logistician {
        return super.get("logistician");
    }

    driver(): Driver {
        return super.get("driver");
    }

    name(): string {
        switch (this.role()) {
            case Role.Manager:
                return this.manager().getName();
            case Role.Dispatcher:
                return this.dispatcher().getName();
            case Role.Customer:
                return this.customer().getName();
            case Role.Driver:
                return this.driver().getName();
            case Role.Supplier:
                return this.supplier().getName();
            case Role.Logistician:
                return this.logistician().getName();
            default:
                return this.getUsername();
        }
    }

    getUsername(): string {
        return super.getUsername();
    }

    role(): Role {
        return super.get('role');
    }

    getSettings(): UserSettings {
        return super.get("settings");
    }

    setSettings(value: UserSettings) {
        super.set("settings", value);
    }

    isAdministrator(): boolean {
        return this.role() == Role.Administrator;
    }

    isLogistician(): boolean {
        return this.role() == Role.Logistician;
    }

    isManager(): boolean {
        return this.role() == Role.Manager;
    }

    isDispatcher(): boolean {
        return this.role() == Role.Dispatcher;
    }

    isCustomer(): boolean {
        return this.role() == Role.Customer;
    }

    isDriver(): boolean {
        return this.role() == Role.Driver;
    }

    authenticated() {
        return super.authenticated();
    }

    isValid() {
        return super.isValid();
    }

    save() {
        return super.save();
    }

    static logOut() {
        return super.logOut();
    }

    static logIn(name, password) {
        return super.logIn(name, password);
    }

    roleLocaleString() {
        return 'USERS.ROLE.' + this.role();
    }
}


export namespace CurrentUser {
    export function user(): User {
        return Parse.User.current();
    }

    export function manager(): Manager {
        return user().manager();
    }

    export function supplier(): Supplier {
        return user().supplier();
    }

    export function customer(): Customer {
        return user().customer();
    }

    export function carrier(): Carrier {
        return user().carrier();
    }

    export function dispatcher(): Dispatcher {
        return user().dispatcher();
    }

    export function localizedRole(): string {
        if (!isAuthenticated()) return '';
        return user().roleLocaleString();
    }

    export function isAuthenticated(): boolean {
        return user() != null;
    }

    export function accountName(): string {
        return user().getUsername();
    }

    export function userName(): string {
        if (!isAuthenticated()) return '';
        return user().name();
    }

    export function logOut() {
        return User.logOut();
    }

    export function logIn(name, password) {
        return User.logIn(name, password).then((user) => {
            if (isValidRole(user)) {
                return user.fetchWithInclude(['dispatcher', 'manager', 'customer', 'driver', 'logistician']);
            } else {
                User.logOut();
                return Promise.reject("Invalid role")
            }
        });
    }

    export function isValidRole(user) {
        let idx = validRoles.indexOf(user.role());
        return idx != -1;
    }

    export function getSettings(): UserSettings {
        let settings = user().getSettings();
        if (settings === undefined) {
            settings = getDefaultUserSettings();
        }
        return settings;
    }

    export function getDefaultUserSettings(): UserSettings {
        return {
            notifications: false,
            displayDeleted: false,
        };
    }

    export function setSettings(value: UserSettings) {
        user().setSettings(value);
    }

    export function save(): Promise<any> {
        return user().save();
    }

    export function isAdministrator(): boolean {
        return isAuthenticated() && user().isAdministrator();
    }

    export function isLogistician(): boolean {
        return isAuthenticated() && user().isLogistician();
    }

    export function isManager(): boolean {
        return isAuthenticated() && user().isManager();
    }

    export function isDispatcher(): boolean {
        return isAuthenticated() && user().isDispatcher();
    }

    export function isCustomer(): boolean {
        return isAuthenticated() && user().isCustomer();
    }

    export function canChangeAgreeStatus(): boolean {
        return isAuthenticated() && (user().isLogistician() || user().isManager() || user().isAdministrator());
    }

    export function currentSession() {
        return Parse.Session.current();
    }
}


