
export const data = {
  "ExcelExport": {
    "WorksheetTitle": "Report",
    "Fields": {
      "Number": "#",
      "Supplier": "Supplier",
      "LoadingDate": "Loading Date",
      "LoadingPoint": "Loading Point",
      "LoadingBrand": "Loading Brand",
      "LoadingTonnage": "Loading Tonnage",
      "UnloadingDate": "Unloading Date",
      "Customer": "Customer",
      "UnloadingPoint": "Unloading Point",
      "UnloadingBrand": "Unloading Brand",
      "UnloadingTonnage": "Unloading Tonnage",
      "Intermediary": "Intermediary",
      "Author": "Author",
      "Carrier": "Carrier",
      "Driver": "Driver",
      "VehicleNumber": "Vehicle Number",
      "TrailerNumber": "Trailer Number",
      "Kilometers": "Kilometers",
      "WaybillNumber": "Waybill Number",
      "SalePriceType": "Sale Price Type",
      "SaleTariff": "Sale Tariff",
      "DeliveryPriceType": "Delivery Price Type",
      "DeliveryTariff": "Delivery Tariff",
      "Sum": "Sum",
    },
    "PriceType": {
      "OneTime": "One time",
      "NotOneTime": "Not one time",
    },
  },
};
