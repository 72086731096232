import {locale as proposals_en} from "./en/locale.proposals-create";
import {locale as proposals_ru} from "./ru/locale.proposals-create";

export const proposals_create_dialog = [
  {
    lang: 'en',
    data: proposals_en
  },
  {
    lang: 'ru',
    data: proposals_ru
  },
];

