import { PFObject } from './base/object';
import { Carrier } from './persons/carrier';
import { Order } from './order/order';

export class CarrierOffer extends PFObject {
  constructor() { super('CarrierOffer'); }

  order(): Order { return super.get("order"); }
  carrier(): Carrier { return super.get("carrier"); }

  isAccepted() { return super.get("accepted"); }
}