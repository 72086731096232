import { Component, ViewEncapsulation, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { VehicleModel } from 'app/components/data/core/models/transport/transport';
import { PFObject } from 'app/components/data/core/models/base/object';

import { LocaleService } from 'app/components/locale/locale.service';
import { model } from '../../locale/locale';

import * as FormHelper from 'app/components/form-helper/form-helper';
import { VehicleBrandDialog } from '../vehicle_brand/vehicle-brand-dialog.component';
import { DialogPresenterService } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import { CloudExecutor } from 'app/components/data/cloud-executors/cloud-executor';
import { DataLoader } from 'app/components/data/data-loader';

@Component({
    selector   : 'vehicle-model-dialog',
    templateUrl: './vehicle-model-dialog.component.html',
    styleUrls  : ['./vehicle-model-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class VehicleModelDialog {
  compareFunction = PFObject.compareFn;
  
  public form: UntypedFormGroup;
  private object: VehicleModel;

  public title: string;
  public buttonName: string;

  public dataSaver = new CloudExecutor;
  public dataLoader: DataLoader;

  constructor (
    private localeService: LocaleService,
    public dialogRef: MatDialogRef<VehicleModelDialog>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: UntypedFormBuilder,
    private dialogPresenterService: DialogPresenterService
  ) {
    this.dataLoader = new DataLoader('VehicleBrand');
    this.localeService.loadLocale(model);
    this.object = this._data.object;

    if (this._data.action === 'create') {
        this.title = 'DIALOG.CREATEMODEL.TITLE.CREATE';
        this.buttonName = 'DIALOG.CREATEMODEL.CONFIRMBUTTON.CREATE';
    } else if (this._data.action === 'edit') {
        this.title = 'DIALOG.CREATEMODEL.TITLE.EDIT';
        this.buttonName = 'DIALOG.CREATEMODEL.CONFIRMBUTTON.SAVE';
    }

    if (!this.object) {
      this.object = new VehicleModel();
    }

    this.form = this.createContactForm();
  }

  ngOnInit() {
    this.load();
  }

  load() {
    this.dataLoader.loadAll();
  }

  createContactForm(): UntypedFormGroup
  {
    return this._formBuilder.group({
      brand: [this.object.brand()],
      name: [this.object.name()],
    });
  }

  checkAndSave() {
    let verified = this.checkForm();

    if (verified) {
      this.object.setName(this.form.controls.name.value);
      this.object.setBrand(this.form.controls.brand.value);

      this.dataSaver.saveObject(this.object).then(obj => {
        this.dialogRef.close(obj);
      });
    }
  }

  showDialog() {
    let handler = this;
    let data = new Map<string, any>();
    data['action'] = 'create';
    this.dialogPresenterService.showDialog({
      dialog : VehicleBrandDialog,
      panel : 'vehicle-brand-dialog-container',
      data: data,
    }, function(brand){
      if (brand) {
        handler.load();
        FormHelper.setControlValue(handler.form, "brand",brand)
      }
    });
  }

  checkForm() : boolean {
    return FormHelper.checkForm(this.form);
  }
}
