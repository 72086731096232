import { TranslateService } from '@ngx-translate/core';
import { DefaultReportBuilder } from './default.report-builder';

import * as AccessQueries from 'app/components/access/query';

export class ManagerReportBuilder extends DefaultReportBuilder {
    constructor(translate: TranslateService) {
        super(translate, query => {
            return AccessQueries.orderAuthorMatchesCurrentUser(query);
        })
    }
}