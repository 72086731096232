import {PFQuery} from '../data/core/models/base/query';
import {CurrentUser} from '../data/core/models/base/user';

export function matchesCurrentCarrier(query: PFQuery): PFQuery {
    query.equalTo('carrier', CurrentUser.carrier());
    return query;
}

export function driverMatchesCurrentCarrier(query: PFQuery): PFQuery {
    let driverQuery = new PFQuery('Driver');
    matchesCurrentCarrier(driverQuery);
    query.matchesQuery('driver', driverQuery);
    return query;
}

export function transportUnitMatchesCurrentCarrier(query: PFQuery): PFQuery {
    let transportUnitQuery = new PFQuery('TransportUnit');
    driverMatchesCurrentCarrier(transportUnitQuery);
    query.matchesQuery('transportUnit', transportUnitQuery);
    return query;
}

export function containsCurrentCustomer(query: PFQuery): PFQuery {
    query.equalTo('customer', CurrentUser.customer());
    return query;
}

export function containsCurrentCarrier(query: PFQuery): PFQuery {
    query.equalTo('carriers', CurrentUser.carrier());
    return query;
}

export function authorMatchesCurrentUser(query: PFQuery): PFQuery {
    query.equalTo('author', CurrentUser.user());
    return query;
}

export function managerMatchesCurrentUser(query: PFQuery): PFQuery {
    query.equalTo('manager', CurrentUser.user());
    return query;
}

export function orderAuthorMatchesCurrentUser(query: PFQuery): PFQuery {
    let orderQuery = new PFQuery('Order');
    authorMatchesCurrentUser(orderQuery);
    query.matchesQuery('order', orderQuery);
    return query;
}

export function tripOrderAuthorMatchesCurrentUser(query: PFQuery): PFQuery {
    let tripQuery = new PFQuery('Trip');
    orderAuthorMatchesCurrentUser(tripQuery);
    query.matchesQuery('trip', tripQuery);
    return query;
}

export function filterDeleted(query: PFQuery): PFQuery {
    let settings = CurrentUser.getSettings();
    if (!settings.displayDeleted) {
        query.equalTo('deleted', false);
    }
    return query;
}

export function anyStatus(query: PFQuery): PFQuery {
    query.greaterThan('number', 0);
    return query;
}
