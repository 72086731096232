import { NgModule } from '@angular/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material.module';

import { HomeComponent } from './home.component';

@NgModule({
    declarations: [
        HomeComponent,
    ],
    imports: [
        TranslateModule,
        MaterialModule,
        FuseSharedModule,
    ],
    exports: [
        HomeComponent,
    ],
})

export class HomeModule {
}
