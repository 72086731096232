
import { Injectable } from "@angular/core";
import { Parse } from "parse";
import { Configuration } from "app/components/data/core/models/config/configuration";

@Injectable()
export class ConfigurationService {

  private configuration: Configuration;

  public getConfiguration(): Configuration {return this.configuration;}

  public fetch(): Promise<Configuration> {
    let query = new Parse.Query(Configuration);
    return query.first().then((configuration) => {
      this.configuration = configuration;
      return Promise.resolve(this.configuration);
    });
  }

}
