export const locale = {
  'EXPORT_QUEUE' : {
    'TABLEROW' : {
      'TYPE' : 'Type',
      'DESCRIPTION' : 'Description',
      'REASON' : 'Reason',
      'DATE' : 'Date',
    },
    'TITLE' : 'Export queue',
    'ERROR': {
      'UNDEFINED': 'Undefined error',
      'InvalidReponse': 'Invalid response',
      'ExternalAPIError': 'External API error',
      'NotSavedOnExternal': 'Not saved on external',
      'FileDownloadFailed': 'File download failed',
      'NOT_SAVED_DESCRIPTION': "'{{value}}' not present in 1с",
    },
    'NO_DESCRIPTION': 'No description',
    'CLASS_NAME': {
      'Order': 'Order',
      'Driver': 'Driver',
      'Vehicle': 'Vehicle',
      'Trailer': 'Trailer',
      'Customer': 'Customer',
      'UnloadingPoint': 'Unloading point',
    },
  }
};
