import { TableData, TableRowData, TableSort } from 'app/components/table/tabledata';

import { ArticleBrand, ArticleType } from 'app/components/data/core/models/articles/article'
import { PFQuery } from 'app/components/data/core/models/base/query';
import { ServerTableDataSource } from 'app/components/table/data_sources/server-data-source';
import { filterDeleted } from 'app/components/access/query';
import { brand } from 'app/main/admin/vehicles/locale/locale';

export class AvailableArticleBrandsTableData extends TableData {

  constructor(
    articleType: ArticleType,
    notInclude: ArticleBrand[]
  ) {
    super();

    let columns = [
      new TableRowData({
        key: 'name',
        header: 'DIALOG.SUPPLIER.BRAND_NAME',
        cell: (brand: ArticleBrand) => `${brand.name()}`
      }),
      new TableRowData({
        key: 'type',
        header: 'DIALOG.SUPPLIER.BRAND_TYPE',
        cell: (brand: ArticleBrand) => `${brand.type() ? brand.type().name() : ''}`
      }),
    ];

    this.setColumns(columns);

    let sort: TableSort = new TableSort('name', 'asc');
    console.log('AvailableArticleBrandsTableData')
    let tableDataSource = new ServerTableDataSource('ArticleBrand', ['type']);
    tableDataSource.setDefaultSort(sort);
    tableDataSource.queryModify = function (query: PFQuery): PFQuery {
      if (articleType) {
        query.equalTo('type', articleType);
      }
      query.notContainedIn('objectId', notInclude.map(brand => brand.id))
      return filterDeleted(query);
    };
    tableDataSource.queryFilter = (filter, query) => {
      query.contains('name', filter);
      let otherQuery: PFQuery = new PFQuery('ArticleBrand');
      let typeQuery = new PFQuery('ArticleType');
      typeQuery.contains('name', filter);
      otherQuery.matchesQuery("type", typeQuery);
      return PFQuery.or(query, otherQuery);
    };
    this.setProvider(tableDataSource);
  }

  canSortColumn(column) {
    return super.canSortColumn(column);
  }
}
