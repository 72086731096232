import { Component, Input, ViewChild, ElementRef } from '@angular/core';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { Customer } from 'app/components/data/core/models/persons/customer';
import { UnloadingPoint, Entrance } from 'app/components/data/core/models/points/points';
import {Order, OrderAgreeStatus} from 'app/components/data/core/models/order/order';
import { PFObject } from 'app/components/data/core/models/base/object';

import { IntermediaryCreateDialog } from 'app/main/admin/users/dialogs/intermediary/create/intermediary-create-dialog.component';
import { CustomerCreateDialog } from 'app/main/admin/users/dialogs/customer/create/customer-create-dialog.component';
import { EntranceDialog } from 'app/main/admin/users/dialogs/entrance/entrance-dialog.component';
import { DialogPresenterService } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import { DateHelper } from 'app/components/helpers/date.helper';

import * as FormHelper from 'app/components/form-helper/form-helper';
import * as CustomValidators from 'app/components/validators/validators';

import { DataLoader } from 'app/components/data/data-loader';
import { CurrentUser } from 'app/components/data/core/models/base/user';
import { UnloadingPointDialog } from 'app/main/admin/users/dialogs/customer/edit-address/unloading-points/unloading-points-dialog.component';
import { DispatcherCloudExecutor } from 'app/components/data/cloud-executors/dispatcher-cloud-executor';
import { Intermediary } from 'app/components/data/core/models/persons/intermediary';
import { NumberHelper } from 'app/components/helpers/number.helper';
import { Contact } from 'app/components/data/core/models/persons/contact';
import { CreateContactDialog } from 'app/main/admin/contacts/create/create-contact-dialog.component';

export interface FieldChangeHandler {
  fieldChanged(fieldName: String, value: any);
}

@Component({
    selector   : 'customer-step',
    templateUrl: './customer-step.component.html',
    styleUrls  : ['./customer-step.component.scss'],
})

export class CustomerStepComponent {
  compareFunction = PFObject.compareFn;

  @Input()
  order: Order;

  @Input()
  editing: boolean;

  @Input()
  fieldChangeHandler;

  public form: UntypedFormGroup;
  public intermediarysDataLoader: DataLoader;
  public agreeOrderStatuses;

  public filteredCustomers: Observable<Customer[]>;
  public customersDataLoader: DataLoader;

  public customerCheckResult;
  public checkError;

  public get contacts() {
    let point = this.getUnloadingPoint();
    return  point ? point.getContacts() : null;
  } 

  public executor = new DispatcherCloudExecutor();

  @ViewChild('customerInput') customerInput: ElementRef;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private dialogPresenterService: DialogPresenterService
  ) {
    this.intermediarysDataLoader = new DataLoader('Intermediary');
    this.intermediarysDataLoader.setQueryModificationBlock((query) => {
      query.descending("createdAt");
    });
    this.agreeOrderStatuses = Order.orderAgreeStatusList;
    this.customersDataLoader = new DataLoader('Customer');
    this.customersDataLoader.setQueryModificationBlock((query) => {
      query.include('unloadingPoints.entrances');

      if (CurrentUser.isDispatcher()) {
        let carrier = CurrentUser.carrier();
        if (!carrier.getShowAllCustomers()) {
          let ids = carrier.getCustomersList().map(c => c.id);
          query.containedIn('objectId', ids);
        }
      }
    });



    this.form = this._formBuilder.group({
      intermediary      : [null, Validators.required],
      customer          : [null, CustomValidators.isObjectValidator],
      unloadingPoint    : [null, Validators.required],
      unloadingEntrance : [null, Validators.required],
      unloadingDate     : [null, Validators.required],
      unload_time_from  : [null, [Validators.min(0), Validators.max(23)]],
      unload_time_to    : [null, [Validators.min(0), Validators.max(23)]],
      contacts: null,
      agreeOrderStatus: [null, Validators.required],
    });
  }

  ngOnInit() {
    this.loadData();
  }

  setInitialFocus() {
    if (!this.editing) {
      setTimeout(() => {
        this.customerInput.nativeElement.focus();
      },1000);
    }
  }

  canAddIntermediary(): boolean {
    return CurrentUser.isAdministrator() || CurrentUser.isLogistician();
  }

  canAdd(): boolean {
    return CurrentUser.isAdministrator() || CurrentUser.isLogistician() || CurrentUser.isManager();
  }

  rank(value) {
    return NumberHelper.rank(value);
  }

  set agreeStatus(value: OrderAgreeStatus) {
    console.log(`set status ${value}`);
    this.form.controls.agreeOrderStatus.setValue(value);

  }

  get agreeStatus(): OrderAgreeStatus {
    const value = this.form.controls.agreeOrderStatus.value;
    console.log(`Get stastus ${value}`);
    return value;
  }

  private loadData() {
    this.form.controls.intermediary.setValue(this.order.intermediary());

    this.reloadIntermediarys();
    this.reloadCustomers();
    const orderAgreeStatus = this.order.getAgreeStatus();

    this.agreeStatus = orderAgreeStatus;
    let unloadingBeginDate = this.order.unloadingBeginDate();
    let needsTime = true;
    if (!unloadingBeginDate) {
      needsTime = false;
      unloadingBeginDate = new Date();
    }

    this.setUnloadingBeginDate(unloadingBeginDate);
    if (needsTime) {
      this.setUnloadingTimeFrom(unloadingBeginDate.getHours());
    }

    let unloadingEndDate = this.order.unloadingEndDate();
    let unloadingTimeTo;
    if (unloadingEndDate) {
      unloadingTimeTo = unloadingEndDate.getHours();
    } else if (needsTime) {
      unloadingTimeTo = unloadingBeginDate.getHours();
    }

    this.setUnloadingTimeTo(unloadingTimeTo);
    this.setUnloadingContact(this.order.unloadingContact())
  }

  updateDate() {
    this.order.setUnloadingBeginDate(this.getUnloadingStartDate());
    this.checkInterval();
  }

// ******* Intermediary ********

  addIntermediary() {
    let handler = this;
    let data = new Map<string, any>();
    data['action'] = 'create';
    this.dialogPresenterService.showDialog({
      dialog : IntermediaryCreateDialog,
      panel : 'intermediary-create-dialog-container',
      data: data,
    }, function(intermediary){
      if (intermediary) {
        handler.setIntermediary(intermediary);
        handler.intermediarysDataLoader.addItemToLoaded(intermediary);
      }
    });
  }

  reloadIntermediarys() {
    let intermediary = this.getIntermediary();
    this.intermediarysDataLoader.loadAll().then( objs => {
      if (!intermediary) {
        objs = objs.filter((o: Intermediary) => !o.isDeleted())
        if (objs.length > 0) {
          intermediary = objs[0];
        }
      }
      this.setIntermediary(intermediary);
    });
  }

  getIntermediary() {
    return this.form.controls.intermediary.value;
  }

  private setIntermediary(intermediary) {
    this.form.controls.intermediary.setValue(intermediary);
  }
// ******* Customer ********

  reloadCustomers() {
    let customer = this.order.customer();
    this.customersDataLoader.loadAll().then( objs => {
      this.filteredCustomers = this.form.controls.customer.valueChanges.pipe(
          startWith<string | Customer>(''),
          map(name => this._filterCustomers(name))
      );
      this.setCustomer(customer);
    })
  }

  getCustomer() {
    let value = this.form.controls.customer.value;
    return typeof value === 'string' ? null : value;
  }

  private setCustomer(customer) {
    this.form.controls.customer.setValue(customer);
    this.customerChanged(customer);
  }

  addCustomer() {
    let handler = this;
    let data = new Map<string, any>();
    data['action'] = 'create';
    this.dialogPresenterService.showDialog({
      dialog : CustomerCreateDialog,
      panel : 'customer-create-dialog-container',
      data: data,
    }, function(customer){
      if (customer) {
        handler.setCustomer(customer);
        handler.customersDataLoader.addItemToLoaded(customer);
      }
    });
  }

  checkCustomer() {
    this.checkError = null;
    this.executor.verifyCustomer(this.getCustomer())
    .then((response) => {
      this.customerCheckResult = {
        receivables: response.accountsReceivable,
        credit: response.accountsPayable,
        lastPayment: new Date(response.lastPaymentDate),
        block: response.stopFactor,
      };
      this.setCustomerErrors(null);
    })
    .catch(error => {
      this.setCustomerErrors(null);
      this.checkError = error;
    });
  }

  customerChanged(customer: Customer) {
    this.setUnloadingPoint(null);
    if (this.fieldChangeHandler) {
      this.fieldChangeHandler.fieldChanged("customer", customer);
    }
    this.customerCheckResult = null;

    if (!customer) return;

    this.order.setSalePriceType(customer.getDefaultPriceType());
    if (!customer.isInternal() && !this.compareFunction(customer, this.order.customer())) {
      this.setCustomerErrors({'notChecked': true});
    } else {
      this.setCustomerErrors(null);
    }

    let points = customer.getUnloadingPoints();
    if (points && points.length) {

      let currentPoint = this.order.unloadingPoint();
      let index = 0;
      if (currentPoint) {
        let curIndex = PFObject.indexOfObject(points, currentPoint);
        if (curIndex != -1) {
          index = curIndex;
        }
      }
      currentPoint = points[index];
      this.setUnloadingPoint(currentPoint);
    }
    this.order.setSalePriceType(customer.getDefaultPriceType());
  }

  private setCustomerErrors(errors) {
    this.form.controls.customer.setErrors(errors);
  }

  private _filterCustomers(value): Customer[] {
    let customers = this.customersDataLoader.getLoadedItems();
    if (!customers) {
      return [];
    }

    if (!value || typeof value !== 'string') {
      return customers.slice();
    }

    let filterValue = value.toLowerCase();
    return customers.filter( function(customer) {
      let name = customer.getName();
      let itn = customer.getITN();
      if (!itn)
      {
        itn = '';
      }
      return name.toLowerCase().indexOf(filterValue) !== -1 ||
             itn.toLowerCase().indexOf(filterValue) !== -1;
    });
  };

  public customerDisplay(customer?: Customer) {
    return customer ? customer.getName() : undefined;
  }



  public canShowCustomer() {
    let customer = this.getCustomer()
    return customer.isInternal() || 
    this.checkError ||
    (this.customerCheckResult && !this.customerCheckResult.block) || 
    (this.order.customer() == customer)
  }

// ******* Unloading point ********
  getUnloadingPoint() : UnloadingPoint {
    return this.form.controls.unloadingPoint.value;
  }

  private setUnloadingPoint(unloadingPoint: UnloadingPoint) {
    this.form.controls.unloadingPoint.setValue(unloadingPoint);
    this.pointChanged(unloadingPoint);
  }

  getUnloadingPoints() {
    let points = this.getCustomer().getUnloadingPoints();
    return points;
  }

  pointChanged(point: UnloadingPoint) {
    let currentEntrance = null;
    if (point) {
      let entrances = point.getEntrances();
      if (entrances && entrances.length > 0) {
        currentEntrance = this.order.unloadingEntrance();
        let index = 0;
        if (currentEntrance) {
          let curIndex = PFObject.indexOfObject(entrances, currentEntrance);
          if (curIndex != -1) {
            index = curIndex;
          }
        }
        currentEntrance = entrances[index];
      }
    }
    this.setUnloadingEntrance(currentEntrance);
    this.updateUnloadingContact(point);
  }

  addUnloadingPoint() {
    let handler = this;
    let customer = this.getCustomer();
    let data = new Map<string, any>();
    data['action'] = 'create';
    this.dialogPresenterService.showDialog({
      dialog : UnloadingPointDialog,
      panel : 'unloading-points-dialog',
      data: data,
    }, function(point){
      if (point) {
        handler.setUnloadingPoint(point);
        customer.addUnloadingPoint(point);
        customer.save();
      }
    });
  }

// ******* Unloading entrance ********
  getUnloadingEntrance() : Entrance {
    return this.form.controls.unloadingEntrance.value;
  }

  private setUnloadingEntrance(entrance: Entrance) {
    this.form.controls.unloadingEntrance.setValue(entrance);
  }

  getEntrances() : Entrance[] {
    let point = this.getUnloadingPoint();
    if (point) {
      return point.getEntrances();
    }
    return null;
  }

  addUnloadingEntrance() {
    let handler = this;
    let point = this.getUnloadingPoint();

    let data = new Map<string, any>();
    data['action'] = 'create';
    data['address'] = point.getAddress();
    this.dialogPresenterService.showDialog({
      dialog : EntranceDialog,
      panel : 'entrance-dialog-container',
      data: data,
    }, function(entrance){
      if (entrance) {
        handler.setUnloadingEntrance(entrance);
        point.addEntrance(entrance);
      }
    });
  }

// ******* Unloading date and time ********
  getUnloadingStartDate(): Date {
    let date = DateHelper.setDayBegin(new Date(this.form.controls.unloadingDate.value));
    let time = this.getUnloadingTimeFrom();
    date.setHours(time);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
  }

  getUnloadingEndDate(): Date {
    let date = DateHelper.setDayBegin(new Date(this.form.controls.unloadingDate.value));
    let time = this.getUnloadingTimeTo();
    date.setHours(time);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
  }

  private setUnloadingBeginDate(date: Date) {
    this.form.controls.unloadingDate.setValue(date);
  }

  private getUnloadingTimeFrom() {
    return this.form.controls.unload_time_from.value;
  }

  private setUnloadingTimeFrom(time) {
    this.form.controls.unload_time_from.setValue(time);
  }

  private getUnloadingTimeTo() {
    return this.form.controls.unload_time_to.value;
  }

  private setUnloadingTimeTo(time) {
    return this.form.controls.unload_time_to.setValue(time);
  }

  getUnloadingInterval() {
    let from = this.getUnloadingTimeFrom() | 0;
    let to = this.getUnloadingTimeTo() | 0;
    return to - from;
  }

  private checkInterval() {
    let error = null;
    if (this.getUnloadingInterval() < 0) {
      error = { invalidInterval: true }
    }
    this.form.controls.unload_time_to.setErrors(error);
  }

  public checkForm(): boolean {
    this.checkInterval();
    return FormHelper.checkForm(this.form);
  }

  public getUnloadingContact() {
    return this.form.controls.contacts.value;
  }

  private setUnloadingContact(value) {
    this.form.controls.contacts.setValue(value);
  }

  private updateUnloadingContact(point: UnloadingPoint) {
    let defaultValue = this.order.unloadingContact();
    let contacts = this.contacts;
    let value;
    if (contacts && contacts.length) {
      let index = contacts.findIndex(o => this.contactCompare(o, defaultValue));
      if (index != -1) {
        value = defaultValue;
      } else {
        value = contacts[0];
      }
    }
    this.setUnloadingContact(value)
  }

  contactCompare(contact1: Contact, contact2: Contact) {
    if (!contact1 && !contact2) {
      return true;
    } else if (!contact1 || !contact2) {
      return false;
    }
    return contact1.name === contact2.name &&
    contact1.phoneNumber === contact2.phoneNumber;
  }

  isContactsSelectable(): boolean {
    return this.getUnloadingPoint() instanceof UnloadingPoint;
  }

  addUnloadingContact() {
    let point = this.getUnloadingPoint();
    let dialodData = {
      dialog: CreateContactDialog,
      panel: 'create-contact-dialog-container',
      data: {
        action: 'create',
        object: point
      }
    };

    let handler = this;

    this.dialogPresenterService.showDialog(dialodData, (res) => {
      if (res) {
        handler.setUnloadingContact(res);
      }
    });
  }

  agreeStatusName = (type: OrderAgreeStatus) => {
    switch (type) {
      case OrderAgreeStatus.AGREE:
        return 'STEP_CUSTOMER_ORDER.DIALOG.AGREE_FIELD.AGREE';
      case OrderAgreeStatus.NOT_AGREE:
        return 'STEP_CUSTOMER_ORDER.DIALOG.AGREE_FIELD.NOT_AGREE';
      default:
        return 'STEP_CUSTOMER_ORDER.DIALOG.AGREE_FIELD.NOT_AGREE';
    }

  };
  protected readonly CurrentUser = CurrentUser;
}
