import { TranslateService } from '@ngx-translate/core';
import { ActionBarOperation } from 'app/components/action-bar/action-bar.component';
import { DispatcherCloudExecutor } from 'app/components/data/cloud-executors/dispatcher-cloud-executor';
import { DialogPresenterService, DialogData } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';
import { OrderHelper } from 'app/components/helpers/order.helper';
import { TableOperationActionType, TableOperationsHelper } from 'app/components/helpers/table-operations.helper';
import { ArticleBrand } from 'app/components/data/core/models/articles/article';
import { Order, OrderStatus } from 'app/components/data/core/models/order/order';
import { Supplier } from 'app/components/data/core/models/persons/supplier';
import { TableOperationsFactory } from 'app/components/table-operations-controller/table-operations-controller';
import { OrderDialogComponent } from 'app/main/orders/dialog/order/order-dialog.component';
import { CreateProposalDialog } from '../dialog/create/create-proposal-dialog.component';
import { ProposalReservationByManagerDialog } from '../dialog/reservation/by-manager/proposal-reservation-by-manager-dialog.component';

export class ProposalTableOperationsFactory implements TableOperationsFactory {
    private executor = new DispatcherCloudExecutor();

    constructor(
        private dialogPresenter: DialogPresenterService,
        private translate: TranslateService,
        private dataCollector: () => any) { }

    makeOperations(selected) {
        return this.makeOperationsWith(selected);
    }

    makeDefaultOperation(selected) {
        return TableOperationsHelper.makeOperationWithType(
            TableOperationActionType.Edit,
            this.dialogPresenter,
            () => this.makeDialogData(),
            selected);
    }

    addProposal(supplier: Supplier, brand: ArticleBrand, date: Date) {
        let dialogData: DialogData = this.makeDialogData();
        dialogData.data.action = "create";
        dialogData.data.supplier = supplier;
        dialogData.data.brand = brand;
        dialogData.data.date = date;
        this.dialogPresenter.showDialog(dialogData);
    }

    private makeOperationsWith(selected) {
        let operationTypes = [
            TableOperationActionType.Add,
            TableOperationActionType.Copy,
        ]
        let operations = TableOperationsHelper.makeOperationsWithTypes(
            operationTypes,
            this.dialogPresenter,
            () => this.makeDialogData(),
            selected
        );
        operations.push(this.makeEditProposalOperation(selected));
        operations.push(this.makeRemoveProposalOperation(selected));
        operations.push(this.takeToWorkOperation(selected));
        operations.push(this.makeReservationForCarrierOperation(selected));
        operations.push(this.makeOrderOperation(selected));
        return operations;
    }

    private makeDialogData(): DialogData {
        return {
            dialog: CreateProposalDialog,
            panel: 'create-proposal-dialog-container',
            data: this.dataCollector ? this.dataCollector() : {}
        }
    }

    private takeToWorkOperation(selected) {
        return new ActionBarOperation({
            title: 'PROPOSALS.ROWOPERATION.TO_WORK',
            icon: 'work',
            action: () => { this.takeToWork(selected[0]) },
            disabled: TableOperationsHelper.notSingleSelection(selected)
        });
    }

    private takeToWork(order: Order) {
        let status = order.status();
        let carriers = order.getCarriers();
        if (status === OrderStatus.READY || 
            status === OrderStatus.IN_WORK ||
            (carriers && carriers.length > 0)) {
            this.dialogPresenter.showAlert({
                title: this.translate.instant("PROPOSALS.ALERT.TITLE.WARNING"),
                content: this.translate.instant("PROPOSALS.ALERT.MESSAGE.CANT_CHANGE_STATUS"),
            });
        } else {
            this.executor.setOrderStatus(order, OrderStatus.IN_WORK);
        }
    }

    private makeOrderOperation(selected) {
        return new ActionBarOperation({
            title: 'PROPOSALS.ROWOPERATION.MAKE_ORDER',
            icon: 'assignment',
            action: () => { this.makeOrderFromProposal(selected[0]) },
            disabled: TableOperationsHelper.notSingleSelection(selected)
        });
    }

    private makeOrderFromProposal(order: Order) {
        if (order.status() === OrderStatus.READY) {
            this.dialogPresenter.showAlert({
                title: this.translate.instant("PROPOSALS.ALERT.TITLE.WARNING"),
                content: this.translate.instant("PROPOSALS.ALERT.MESSAGE.ORDER_ALREADY_CREATED"),
            });
        } else {
            let dialogData: DialogData = {
                dialog: OrderDialogComponent,
                panel: 'order-dialog-container',
                data: { object: order, action: 'create' }
            };
            this.dialogPresenter.showDialog(dialogData)
        }
    }

    private makeEditProposalOperation(selected) {
        return TableOperationsHelper.makeEditOperation(() => this.editProposal(selected[0]), selected);
    }

    private editProposal(order: Order) {
        var canEdit = order.status() != OrderStatus.READY;
        if (canEdit) {
            let dialogData = this.makeDialogData();
            dialogData.data.object = order;
            dialogData.data.action = "edit";
            this.dialogPresenter.showDialog(dialogData);
        } else {
            this.dialogPresenter.showAlert({
                title: this.translate.instant("PROPOSALS.ALERT.TITLE.WARNING"),
                content: this.translate.instant("PROPOSALS.ALERT.MESSAGE.ORDER_READY"),
            });
        }
    }

    private makeRemoveProposalOperation(selected) {
        return TableOperationsHelper.makeRemoveOperation(() => this.askRemoveProposals(selected), selected);
    }

    private askRemoveProposals(selected) {
        let canRemove = true;
        for (let proposal of selected) {
            if (OrderHelper.isLoaded(proposal)) {
                canRemove = false;
                break;
            }
        }
        if (canRemove) {
            this.dialogPresenter.askRemoveItems(selected)
        } else {
            this.dialogPresenter.showAlert({
                title: this.translate.instant("PROPOSALS.ALERT.TITLE.WARNING"),
                content: this.translate.instant("PROPOSALS.ALERT.MESSAGE.ORDER_ALREADY_LOADED.REMOVE"),
            });
        }
    }

    private makeReservationForCarrierOperation(selected) {
        return new ActionBarOperation({
            title: 'PROPOSALS.ROWOPERATION.RESERVATION',
            icon: 'assignment_turned_in',
            action: () => { this.makeReservation(selected) },
            disabled: TableOperationsHelper.notSingleSelection(selected)
        });
    }

    private makeReservation(selected) {
        let first = selected[0];
        if (OrderHelper.isLoaded(first)) {
            this.dialogPresenter.showAlert({
                title: this.translate.instant("PROPOSALS.ALERT.TITLE.WARNING"),
                content: this.translate.instant("PROPOSALS.ALERT.MESSAGE.ORDER_ALREADY_LOADED.RESERVE"),
            });
        } else {
            this.showReservationDialog(selected);
        }
    }

    private showReservationDialog(orders) {
        let dialogData: DialogData = {
            dialog: ProposalReservationByManagerDialog,
            panel: 'proposal-reservation-by-manager-dialog-container',
            data: { object: orders[0] }
        };
        this.dialogPresenter.showDialog(dialogData);
        
    }
}