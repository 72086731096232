
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CustomerCloudExecutor, CustomerStatus } from 'app/components/data/cloud-executors/customer-cloud-executor';
import { LocaleService } from 'app/components/locale/locale.service';
import { CurrentUser } from 'app/components/data/core/models/base/user';

import { customer_info } from '../locale/locale'
import { NumberHelper } from 'app/components/helpers/number.helper';

@Component({
    selector: 'customer-info',
    templateUrl: './customer-info.component.html',
    styleUrls: ['./customer-info.component.scss']
})
export class CustomerInfoComponent {
    form: UntypedFormGroup;

    customerStatus: CustomerStatus;
    checkError;

    executor = new CustomerCloudExecutor();

    constructor(
        formBuilder: UntypedFormBuilder,
        localeService: LocaleService,
        private translate: TranslateService,
    ) {
        localeService.loadLocale(customer_info);
        this.form = this.makeForm(formBuilder);
    }

    private makeForm(formBuilder) {
        let customer = CurrentUser.customer();
        let contactName = customer.getContactName();
        let contactPhone = customer.getContactPhone();
        let itn = customer.getITN();
        let undef_val = this.translate.instant('CUSTOMER_INFO.UNDEFINED');
        return formBuilder.group({
            contactName: [{ value: contactName || undef_val, disabled: true }],
            contactPhone: [{ value: contactPhone || undef_val, disabled: true }],
            itn: [{ value: itn || undef_val, disabled: true }],
        });
    }

    refreshDebt() {
        this.checkError = null;
        this.executor
            .getStatus()
            .then((status: CustomerStatus) => {
                this.customerStatus = status;
            })
            .catch(error => {
                this.checkError = error;
            });
    }

    rank(value) {
        return NumberHelper.rank(value);
    }

}