import { NgModule } from '@angular/core';

import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';

@NgModule({
    declarations: [
        ToolbarComponent
    ],
    imports     : [
        
    ],
    exports     : [
        ToolbarComponent
    ]
})
export class ToolbarModule
{
}
