import { TableData, TableRowData } from 'app/components/table/tabledata';

import { LocalDataSource } from 'app/components/table/data_sources/local-data-source';
import { Supplier } from 'app/components/data/core/models/persons/supplier';
import { ArticleBrand } from 'app/components/data/core/models/articles/article';
import { QueryResultFilter } from 'app/components/access/queryResultFilter';

export class SupplierBrandsTableData extends TableData{

  constructor(
    private brands: ArticleBrand[]
  ) {
    super();
    let columns = [
      new TableRowData({
        key: 'name', 
        header: 'DIALOG.SUPPLIER.BRAND_NAME', 
        cell: (brand: ArticleBrand) => `${brand.name()}`
      }),
    ];

    this.setColumns(columns);

    let tableDataSource = new LocalDataSource(this.brands);
    this.setProvider(tableDataSource);
  }

}
