export const locale = {
    'DIALOG': {
        'CONTACTS': {
          'HEADER_TITLE': 'Контакты',
          'CONFIRMBUTTON': {
            'CLOSE': 'Закрыть',
          },
          'ADDRESS': 'Адрес',
          'TABLEROW': {
            'NAME': 'Имя',
            'PHONE': 'Телефон',
          },
        },
      },
  };
  