<mat-table class="group-table" 
           [dataSource]="groupDataSource"
           multiTemplateDataRows 
           fxFlex>

  <ng-container matColumnDef="groupHeader">
    <mat-cell *matCellDef="let group" 
              fxFlex
              [ngStyle]="group.headerStyle"
              [ngClass]="group.headerClass">
      <mat-icon class="mx-16">{{ isExpanded(group) ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}</mat-icon>
      <ng-container [ngTemplateOutlet]="headerInfo" 
                    [ngTemplateOutletContext]="{$implicit:group}">
      </ng-container>
     </mat-cell>
  </ng-container>  

  <ng-container matColumnDef="groupData" >
    <mat-cell *matCellDef="let group"
              [ngClass]="group.contentClass"
              fxFlex>
      <ng-container [ngTemplateOutlet]="groupContent" 
                    [ngTemplateOutletContext]="{$implicit:group}">
      </ng-container>
    </mat-cell>
  </ng-container>

  <mat-row *matRowDef="let group; columns: ['groupHeader'];"
           class="group-header"
           (click)="toggleExpanded(group)">
  </mat-row>
  <tr mat-row 
      *matRowDef="let group; columns: ['groupData']" 
      [class.hidden-row]="!isExpanded(group)">
  </tr>
  
</mat-table>