export const locale = {
  'DIALOG': {
    'DECLINE': {
      'TITLE': 'Отказаться от заказа',
      'MESSAGE': 'Вы уверены, что хотите отказаться от этих заказов?',
      'CONFIRM': 'Да, отказаться',
      'DECLINE': 'Закрыть',
      'ORDERNUMBER': 'Заказ №',
      'ERROR' : 'Не удалось отказаться от заказов',
    },
  },
};
