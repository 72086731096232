export const locale = {
    'SHOWONMAP': {
      'TITLE' : 'Unit position',
      'DOWNLOAD' : 'Download track',
      'LOADING_TRACK' : 'Track is loading...',
      'SPEEDUNIT' : 'km/h',
      'ERROR' : {
        'NO_UNIT_COORDINATES' : 'The drive has not provided his coordinates yet',
      },
      'INFO' : {
        'DATE' : 'Date',
        'STAGE' : 'Stage',
      },
      'STOPINFO' : {
        'VEHICLE_NUMBER' : 'Vehicle number',
        'UNLOAD_TIME' : 'Unload time',
        'ADDRESS' : 'Address',
      }
    }
};
