
<div class="dialog-content-wrapper">
  <dialog-header headerClass="primary" 
                 title="{{'CREATE_MESSAGE.TITLE' | translate}}">
  </dialog-header>
  <div class="pt-8 px-16" fxFlex fxLayout="column">
    <form [formGroup]="form" fxLayout="column" fxFlex>
      <div>
        <mat-form-field fxFlex>
          <mat-select placeholder="{{'CREATE_MESSAGE.FIELD.TARGET' | translate}}"
                      formControlName="target"
                      required>
            <mat-option *ngFor='let target of targets' [value]="target">{{target.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field fxFlex>
          <input matInput
                 placeholder="{{'CREATE_MESSAGE.FIELD.TITLE' | translate}}"
                 formControlName="title"
                 cdkFocusInitial>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field fxFlex>
          <textarea matInput
                    class="h-300"
                    placeholder="{{'CREATE_MESSAGE.FIELD.BODY' | translate}}"
                    (keydown.enter)="$event.preventDefault()"
                    formControlName="body"
                    required>
          </textarea>
        </mat-form-field>
      </div>

      <div class="py-8" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button
                color="accent"
                [disabled] = "executor.isProcessing()"
                (click)='checkAndSave()'>{{'CREATE_MESSAGE.CONFIRMBUTTON' | translate}}
        </button>
      </div>

    </form>
  </div>
</div>
