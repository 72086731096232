import { Transport, Vehicle } from 'app/components/data/core/models/transport/transport';

export namespace TransportHelper {
    export function isInsuranceExpired(vehicle: Vehicle) {
        if (!vehicle) return false;
        return isExpired(vehicle.insuranceDate());
    }

    export function isInspectionExpired(transport: Transport) {
        if (!transport) return false;
        return isExpired(transport.inspectionDate());
    }

    function isExpired(date: Date) {
        if (!date) return false;
        return date.getTime() < (new Date()).getTime();
    }
}