import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateModule } from '@ngx-translate/core';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';

import { LayoutModule } from 'app/layout/layout.module';
import { LoginModule } from 'app/authentication/login/login.module';
import { AlertModule } from 'app/components/alert/alert.module';

import { MainModule } from 'app/main/main/main.module';
import { MainComponent } from 'app/main/main/main.component';

import { AdminModule } from 'app/main/admin/admin.module';

import { AutoModule } from 'app/main/auto/auto.module';

import { ProposalsModule } from 'app/main/proposal/proposals.module';

import { OrdersModule } from 'app/main/orders/orders.module';

import { ReportsModule } from 'app/main/reports/reports.module';

import { getPaginatorIntl } from './providers/paginator-ru';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';

import { environment } from 'environments/environment';
import { AgmCoreModule } from '@agm/core';

import { UserRoleGuard, Role } from 'app/guards/user-role-guard.service';

import { TransportUnitBreakageService } from 'app/notification/transport-unit-breakage.service';
import { OrdersProblemsService } from 'app/notification/order-problems.service';

import { DateAdapter } from '@angular/material/core';
import { CustomeDateAdapter, CUSTOM_DATE_FORMATS } from './providers/custom-date-adapter';

import { LocalNotificationService } from 'app/components/local-notifications-service/local-notifications-service';

import { HomeComponent } from 'app/main/home/home.component';
import { HomeModule } from 'app/main/home/home.module';
import { CustomerInfoModule } from 'app/main/customer/info/customer-info.module';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { HierarchicalCheckboxModule } from 'app/components/hierarchical-checkbox/hierarchical-checkbox.module';
import { CustomerSelectModule } from 'app/main/customer/customer-select/customer-select.module';
import {DeleteImageDialogModule} from "./main/orders/dialog/deleteimageconfirmation/delete-image-dialog.module";

const appRoutes: Routes = [
  {
    path: 'main',
    canActivate: [UserRoleGuard],
    data: { roles: [Role.Administrator, Role.Logistician, Role.Manager, Role.Dispatcher] },
    component: MainComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'ru-RU' },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: DateAdapter, useClass: CustomeDateAdapter },
    { provide: MatPaginatorIntl, useValue: getPaginatorIntl() },
    UserRoleGuard,
    TransportUnitBreakageService,
    OrdersProblemsService,
    LocalNotificationService,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),

    TranslateModule.forRoot(),

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,
    MatDatepickerModule, //date picker
    MatInputModule,
    MatNativeDateModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    // App modules
    LayoutModule,
    LoginModule,
    AlertModule,
    MainModule,
    HomeModule,
    AutoModule,
    OrdersModule,
    AdminModule,
    ReportsModule,
    ProposalsModule,
    CustomerInfoModule,
    HierarchicalCheckboxModule,
    CustomerSelectModule,
    DeleteImageDialogModule,

    AgmCoreModule.forRoot({
      apiKey: environment.googleMapApiKey,
      libraries: ["places"]
    }),
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
