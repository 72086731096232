import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { LocaleService } from 'app/components/locale/locale.service';
import { decline_order } from '../../locale/locale';

import { TranslateService } from '@ngx-translate/core';
import { Order } from 'app/components/data/core/models/order/order';
import { DispatcherCloudExecutor } from 'app/components/data/cloud-executors/dispatcher-cloud-executor';
import { LocalNotificationService } from 'app/components/local-notifications-service/local-notifications-service';

@Component({
  selector: 'decline-order-dialog',
  templateUrl: './decline-order-dialog.component.html',
  styleUrls: ['./decline-order-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DeclineOrderDialogComponent {
  orders: Order[];
  private executor = new DispatcherCloudExecutor;

  constructor(
    private translate: TranslateService,
    private localeService: LocaleService,
    private dialogRef: MatDialogRef<DeclineOrderDialogComponent>,
    private notifications: LocalNotificationService,
    @Inject(MAT_DIALOG_DATA) _data: any,
  ) {
    this.orders = _data.object;
    this.localeService.loadLocale(decline_order);
  }

  close() {
    this.executor.cancel();
  }

  isExecuting() {
    return this.executor.isProcessing();
  }
  execute() {
    this.executor.cancelAndDeclineOrders(this.orders).then(() => {
      this.dialogRef.close(true);
    }, error => {
      if (error != 'canceled') {
        let localizedMessage = this.translate.instant('DIALOG.DECLINE.ERROR');
        this.notifications.showErrorNotification(localizedMessage);
      }
    });
  }
}
