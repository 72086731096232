import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { Customer } from 'app/components/data/core/models/persons/customer';

import { DispatcherCloudExecutor } from 'app/components/data/cloud-executors/dispatcher-cloud-executor';
import { LocaleService } from 'app/components/locale/locale.service';
import { customer } from 'app/main/admin/users/locale/locale';
import { order_fields } from 'app/main/orders/locale/locale';

let locales = [customer, order_fields];

@Component({
    selector   : 'customer-check-dialog',
    templateUrl: './customer-check-dialog.component.html',
    styleUrls  : ['./customer-check-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CustomerCheckDialog {

  public form: UntypedFormGroup;
  public customerCheckResult;
  public subtitle;
  private object: Customer;
  public executor = new DispatcherCloudExecutor();

  constructor (
    private localeService: LocaleService,
    public dialogRef: MatDialogRef<CustomerCheckDialog>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: UntypedFormBuilder,
  ) {
    
    this.object = this._data.object;
    this.subtitle = this.object.getName();
    this.localeService.loadLocales(locales);
    this.form = this.createForm();
  }

  createForm(): UntypedFormGroup {
    return this._formBuilder.group({
                contactName     : [{value: this.object.getContactName(), disabled:true}],
                contactPhone    : [{value: this.object.getContactPhone(), disabled:true}],
                receivables     : [{value: null, disabled:true}],
                credit          : [{value: null, disabled:true}],
                lastPayment     : [{value: null, disabled:true}],
              });
  }

  ngOnInit() {
    this.checkCustomer();
  }

  checkCustomer() {
    this.executor.verifyCustomer(this.object).then((response) => {
      if (response.result) {
        this.setCheckResult({
          receivables: response.accountsReceivable,
          credit: response.accountsPayable,
          lastPayment: response.lastPaymentDate ? new Date(response.lastPaymentDate) : '-',
          block: response.stopFactor,
        });
      }
    });
  }

  setCheckResult(result) {
    this.customerCheckResult = result;

    this.form.controls.receivables.setValue(result.receivables);
    this.form.controls.credit.setValue(result.credit);
    this.form.controls.lastPayment.setValue(result.lastPayment.toLocaleString());
  }
}
