import { NgModule } from '@angular/core';

import { DateSelectorComponent } from './date-selector.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/material.module';

@NgModule({
    declarations: [
        DateSelectorComponent,
    ],
    imports: [
        FuseSharedModule,
        MaterialModule,
    ],
    exports: [
        DateSelectorComponent,
    ]
})

export class DateSelectorModule
{
}
