import Parse from 'parse';
import { PFQuery } from 'app/components/data/core/models/base/query';
import { AccessQueue, AccessQueueFinishCallback, AccessQueueAction } from 'app/components/access-queue/access-queue';

export type SubscriptionHandler = (oldObject: any, newObject: any) => void;
export interface TableDataSubscription {
  subscribe(handler: SubscriptionHandler): void;
  subscribeUpdate(handler: SubscriptionHandler): void;
  subscribeAddition(handler: SubscriptionHandler): void;
  subscribeRemoval(handler: SubscriptionHandler): void;
  subscribeIncludedUpdates(handler: SubscriptionHandler): void;
  unsubscribe(): void;
}

export type LiveQueryModify = (query: PFQuery) => PFQuery;
export type LiveQueryResultFilter = (object: any) => any;

export class LiveQueryInclusion {
    constructor(
        public className: string,
        public queryModifier: LiveQueryModifier = undefined,
        public subincludes = []
    ) { }
}

export class LiveQueryModifier {
    constructor(
        public modify: LiveQueryModify = undefined,
        public resultFilter: LiveQueryResultFilter = undefined,
    ) { }
}

export class LiveQuerySubscriber {
    private liveQueryAccessQueue: AccessQueue;
    public liveQueryModifier: LiveQueryModifier;
    public liveQueryInclusions: LiveQueryInclusion[];

    private subscription: Parse.LiveQuerySubscription;
    private includeSubscriptions: Parse.LiveQuerySubscription[] = [];

    constructor(
        private object,
        private includedFields: string[] = []
    ) { }

    public subscribe(handler: SubscriptionHandler): void {
        this.unsubscribe();
        this.liveQueryAccessQueue = new AccessQueue();

        let query = new PFQuery(this.object);

        let modifier = this.getLiveQueryModifier();
        if (modifier) {
            query = modifier(query);
        }

        query.subscribe().then(subscription => {
            this.subscription = subscription;
            this.subscription.on('error', (error) => {
                console.log("Object subscription error:");
                console.log(error);
            });
    
            if (handler) {
                this.subscribeUpdate(handler);
                this.subscribeAddition(handler);
                this.subscribeRemoval(handler);
            }
        })
    }

    subscribeUpdate(handler: SubscriptionHandler): void {
        let filter = this.getLiveQueryResultFilter();
        this.subscription.on('update', (object) => {
            this.liveQueryAccessQueue.enqueue(this.makeUpdateAction(object, handler, filter));
        });

        if (this.liveQueryInclusions) {
            this.subscribeIncludedUpdates(handler);
        }
    }

    subscribeAddition(handler: SubscriptionHandler): void {
        let filter = this.getLiveQueryResultFilter();
        this.subscription.on('create', (object) => {
            let promise = this.makeAddAction(object, handler, filter);
            this.liveQueryAccessQueue.enqueue(promise);
        });
        this.subscription.on('enter', (object) => {
            this.liveQueryAccessQueue.enqueue(this.makeAddAction(object, handler, filter));
        });
    }

    subscribeRemoval(handler: SubscriptionHandler): void {
        let filter = this.getLiveQueryResultFilter();
        this.subscription.on('leave', (object) => {
            this.liveQueryAccessQueue.enqueue(this.makeRemoveAction(object, handler, filter));
        });
        this.subscription.on('delete', (object) => {
            this.liveQueryAccessQueue.enqueue(this.makeRemoveAction(object, handler, filter));
        });
    }

    subscribeIncludedUpdates(handler: SubscriptionHandler): void {
        this.liveQueryInclusions.forEach((inclusion) => {
            let query = new PFQuery(inclusion.className);
            if (inclusion.queryModifier && inclusion.queryModifier.modify) {
                inclusion.queryModifier.modify(query);
            }

            query.subscribe().then(subscription => {
                subscription.on('error', (error) => {
                    console.log("Included object subscription error:");
                    console.log(error);
                });
    
                let filter = null;
                if (inclusion.queryModifier && inclusion.queryModifier.resultFilter) {
                    filter = inclusion.queryModifier.resultFilter;
                }
                subscription.on('update', (object) => {
                    this.liveQueryAccessQueue.enqueue(this.makeUpdateAction(object, handler, filter));
                });
    
                this.includeSubscriptions.push(subscription);
            })
        });
    }

    public unsubscribe(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.includeSubscriptions.forEach(subscription => subscription.unsubscribe());
            this.subscription = undefined;
            this.includeSubscriptions = [];
            this.liveQueryAccessQueue.cancelAll();
            this.liveQueryAccessQueue = undefined;
        }
    }

    private getLiveQueryModifier(): LiveQueryModify {
        if (this.liveQueryModifier && this.liveQueryModifier.modify) {
            return this.liveQueryModifier.modify;
        }
        return null;
    }

    private getLiveQueryResultFilter(): LiveQueryResultFilter {
        if (this.liveQueryModifier && this.liveQueryModifier.resultFilter) {
            return this.liveQueryModifier.resultFilter;
        }
        return null;
    }

    private makeUpdateAction(object, updateHandler, filter): AccessQueueAction {
        return (callback: AccessQueueFinishCallback) => {
            let oldObject = object;
            if (filter) {
                oldObject = filter(oldObject);
            }
            this.refetchObject(this.object, object, this.includedFields).then((newObject) => {
                if (filter) {
                    newObject = filter(newObject);
                }
                if (oldObject == null && newObject == null) return;

                updateHandler(oldObject, newObject);
                callback()
            }).catch(() => {
                callback();
            });
        }
    }

    private makeAddAction(object, updateHandler, filter) {
        return (callback: AccessQueueFinishCallback) => {
            this.refetchObject(this.object, object, this.includedFields).then((newObject) => {
                if (filter) {
                    newObject = filter(newObject);
                }
                updateHandler(null, newObject);
                callback();
            }).catch(() => {
                callback();
            });
        }
    }

    private makeRemoveAction(object, updateHandler, filter) {
        return (callback: AccessQueueFinishCallback) => {
            let oldObject = object;
            if (filter) {
                oldObject = filter(oldObject);
                if (!oldObject) {
                    callback();
                    return;
                }
            }
            updateHandler(oldObject, null);
            callback();
        }
    }

    private refetchObject(className, object, includes): Promise<any> {
        let query = new PFQuery(className);
        query.includes(includes);
        query.equalTo("objectId", object.id);
        return query.first();
    }
}