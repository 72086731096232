export const locale = {
  'MESSAGES': {
    'TABLEROW': {
      'TITLE': 'Заголовок',
      'BODY': 'Тело сообщения',
      'TARGET': 'Получатели',
      'DATE': 'Дата',
    },
    'TITLE': 'Сообщения',
    'TARGET': {
      'ALL': 'Все',
      'CUSTOMERS': 'Покупатели',
      'LOGISTICIANS': 'Диспетчер',
      'MANAGERS': 'Менеджеры',
      'DRIVERS': 'Водители',
      'DISPATCHERS': 'Диспетчеры перевозчиков',
      'SUPPLIERS': 'Поставщики',
      'UNDEFINED': 'Неопределенная роль',
    }
  }
};
