import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { LocaleService } from 'app/components/locale/locale.service';
import { cancel_customer_order_dialog } from './locale/locale';

import { TranslateService } from '@ngx-translate/core';
import { Order } from 'app/components/data/core/models/order/order';
import { CustomerCloudExecutor } from 'app/components/data/cloud-executors/customer-cloud-executor';
import { LocalNotificationService } from 'app/components/local-notifications-service/local-notifications-service';

@Component({
  selector: 'cancel-customer-order-dialog',
  templateUrl: './cancel-customer-order-dialog.component.html',
  styleUrls: ['./cancel-customer-order-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CancelCustomerOrderDialogComponent {
  order: Order;
  private executor = new CustomerCloudExecutor();

  constructor(
    private translate: TranslateService,
    private localeService: LocaleService,
    private dialogRef: MatDialogRef<CancelCustomerOrderDialogComponent>,
    private notifications: LocalNotificationService,
    @Inject(MAT_DIALOG_DATA) _data: any,
  ) {
    let orders = _data.object;
    this.order = orders[0];
    this.localeService.loadLocale(cancel_customer_order_dialog);
  }

  close() {
    this.executor.cancel();
  }

  isExecuting() {
    return this.executor.isProcessing();
  }
  execute() {
    this.executor.cancelOrder(this.order)
      .then(() => {
        this.dialogRef.close(true);
      })
      .catch((error) => {
        let errorMSG = 'CUSTOMER_ORDER.DIALOG.CANCEL.' + error;
        let localizedMessage = this.translate.instant(errorMSG);
        this.notifications.showErrorNotification(localizedMessage);
      });
  }
}
